
/* Libraries */
import React from "react";
//import { Worker } from '@react-pdf-viewer/core';
//import { Viewer } from '@react-pdf-viewer/core';
import PropTypes from "prop-types";
//import '@react-pdf-viewer/core/lib/styles/index.css';
import { useDispatch } from 'react-redux';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';
/* Material UI */

const PdfViewer = (props) => {
  const { classes, pdfData } = props;
  const fonts = fontStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(modalProvider(false, null, null, null, null, null));
  }

  return(
    <div style={{width: "70vw", height: "95vh", padding: "20px 30px 40px 40px", borderRadius: "13px", backgroundColor: "white"}}>
      <div style={{width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "flex-start", cursor: "pointer", marginBottom: "15px"}} onClick={handleClose}>
        <div className={classes.viewButton} onClick={(handleClose)}>
          <span style={fonts.buttonDefault} className={classes.text}>VOLTAR</span>
        </div>
      </div>
      {/*<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js" style={{height: "80vh"}}>
          <Viewer fileUrl={pdfData}/>
      </Worker>*/}
    </div>
  );

}
  PdfViewer.propTypes = {
    classes: PropTypes.object.isRequired,
    pdfData: PropTypes.string.isRequired
   
  };
  export default withStyles(styles)(PdfViewer);