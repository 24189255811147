// Components
import {Button, OpenTopMenu, TopMenuStyled} from './styled';
import RadarPanel from './RadarPanel';
import CameraPanel from './CameraPanel';
import SatellitePanel from './SatellitePanel';
import './ControlPanel.css';
import TooltipComponent from 'components/Modal/Tooltip.js';
// Icons
import { MdInfoOutline, MdSettings } from 'react-icons/md';
import { FaSatellite, FaSatelliteDish } from 'react-icons/fa'; 
import { BsCameraFill } from 'react-icons/bs';
// Hooks
import {useRadar} from 'Context/RadarContext';
import {useSatellite} from 'Context/SatelliteContext.js';
import useToggle from 'hooks/useToggle';
import {useControlPanel} from 'Context/ControlPanelContext';
import InfosPanel from './InfosPanel';
import {useMapContext} from 'Context/MapContext';
import { useState } from 'react';


const TopMenu = () => {
  const {showSatellite} = useSatellite();
  const {showRadar} = useRadar();
  const [open, toggleOpen] = useToggle(false);
  const { showRadarPanel, toggleRadarPanel, showSatellitePanel, toggleSatellitePanel, toggleInfosPanel, showInfosPanel, showCameraPanel, toggleCameraPanel, showAlerts } = useControlPanel();
  const {mapRef} = useMapContext();

  return (
    <TopMenuStyled className={`${!open && 'closed'} ${showAlerts && 'notifOpen'}`}>
      {showSatellite &&
        <TooltipComponent text={"Opções avançadas de satélite"} xAxis={"-50%"} yAxis={"140%"}> 
          <Button
            selected={showSatellitePanel}
            disabled={!showSatellite}
            onClick={toggleSatellitePanel}
          >
            <FaSatellite />
          </Button>
        </TooltipComponent>}
      {!showSatellite &&
        <TooltipComponent text={"Habilite as imagens de satélite no menu inferior antes de continuar!"} xAxis={"-50%"} yAxis={"140%"}>
          <Button
            selected={showSatellitePanel}
            disabled={!showSatellite}
            onClick={toggleSatellitePanel}
          >
            <FaSatellite />
          </Button>
        </TooltipComponent>
      }
      {showRadar &&
        <TooltipComponent text={"Opções avançadas de radar"} xAxis={"-50%"} yAxis={"140%"}>
          <Button
            selected={showRadarPanel}
            disabled={!showRadar}
            onClick={toggleRadarPanel}
          >
            <FaSatelliteDish />
          </Button>
        </TooltipComponent>
      }
      {!showRadar &&
        <TooltipComponent text={"Habilite as imagens de radar no menu inferior antes de continuar!"} xAxis={"-50%"} yAxis={"140%"}>
          <Button
            selected={showRadarPanel}
            disabled={!showRadar}
            onClick={toggleRadarPanel}
          >
            <FaSatelliteDish />
          </Button>
        </TooltipComponent>
      }
      <TooltipComponent text={"Adicione novas câmeras ao mapa"} xAxis={"-50%"} yAxis={"140%"}>
        <Button
          selected={showCameraPanel}
          onClick={toggleCameraPanel}
        >
          <BsCameraFill />
        </Button>
      </TooltipComponent>
      <TooltipComponent text={"Informações sobre as descargas, imagens de satélite e de radar"} xAxis={"-50%"} yAxis={"140%"}>
        <Button
          selected={showInfosPanel}
          onClick={toggleInfosPanel}>
            <MdInfoOutline />
        </Button>
      </TooltipComponent>
      {showSatellite && <SatellitePanel />}
      {showRadar && <RadarPanel />}
      {showCameraPanel && <CameraPanel />}
      {showInfosPanel && <InfosPanel />}
      <OpenTopMenu onClick={toggleOpen}><MdSettings /></OpenTopMenu>
    </TopMenuStyled>
  )
}

export default TopMenu
