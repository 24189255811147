import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleAction, playTransitionAction } from 'redux/actions/uiActions';
import DashboardComponent from 'containers/Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
  Dashboard,
  AllPlans,
  RegisterPage,
  EditCompany,
  EditPlan,
  EditTarget,
  EditService,
  EditEmployee,
  EditContact,
  Plan,
  Company,
  Services,
  Target,
  Overtime,
  NucleoEmployees,
  Employees,
  CompanyInfo,
  PlanInfo,
  EmployeeInfo,
  TargetInfo,
  NucleoEmployeesTab,
  NucleoEmployeesInfo,
  NucleoEmployeesEdit,
  NotFound,
  ViewClient,
} from './pageListAsync';
import styles from 'containers/Templates/appStyles-jss';


function Application(props) {
  const changeMode = useContext(ThemeContext);
  const {
    history, gradient, mode, loadTransition, sidebarOpen, toggleDrawer
  } = props;


  return (
    <DashboardComponent history={history} changeMode={changeMode}>
      <Switch>
        <Route exact path="/administracao" component={Dashboard} />
        <Route exact path="/administracao/planos" component={AllPlans} />
        <Route exact path="/administracao/registrar/todos" component={RegisterPage} />
        <Route exact path="/administracao/registro/horaextra" component={Overtime} />
        <Route exact path='/administracao/empresas/:company' component={CompanyInfo} />
        <Route exact path='/administracao/empresas/:company/planos/:plan' component={PlanInfo} />
        <Route exact path="/administracao/usuariosnucleo" component={NucleoEmployeesTab} />
        <Route exact path="/administracao/usuariosnucleo/:usuarionucleo" component={NucleoEmployeesInfo} />
        <Route exact path="/administracao/editar/usuariosnucleo/:usuarionucleo" component={NucleoEmployeesEdit} />
        <Route exact path='/administracao/empresas/:company/usuarios/:employee' component={EmployeeInfo} />
        <Route exact path='/administracao/empresas/:company/planos/:plan/alvos/:target' component={TargetInfo} />
        <Route exact path="/administracao/editar/empresa/:company" component={EditCompany} />
        <Route exact path="/administracao/editar/empresa/:company/plano/:plan" component={EditPlan} />
        <Route exact path="/administracao/editar/empresa/:company/plano/:plan/alvo/:target" component={EditTarget} />
        <Route exact path="/administracao/editar/empresa/:company/plano/:plan/alvo/:target/servico/:service" component={EditService} />
        <Route exact path="/administracao/editar/empresa/:company/usuario/:employee" component={EditEmployee} />
        <Route exact path="/administracao/editar/empresa/:company/usuario/:employee/contato" component={EditContact} />
      </Switch>
    </DashboardComponent>

  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  gradient: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  sidebarOpen: state.ui.sidebarOpen,
  mode: state.ui.type,
  gradient: state.ui.gradient,
});

const mapDispatchToProps = dispatch => ({
  toggleDrawer: () => dispatch(toggleAction),
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const ApplicationMaped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);

export default (withStyles(styles)(ApplicationMaped));
