export default function (state = false, action){
    switch(action.type){
        case "MAPS_BUFFERS_STATE":
            return action.payload
            
    default: return state
    }
}




