// Components
import {Button, OpenTopMenu, TopMenuStyled} from './styled';
import RadarPanel from './RadarPanel';
import SatellitePanel from './SatellitePanel';
import './ControlPanel.css';
import TooltipComponent from 'components/Modal/Tooltip.js';
// Icons
import {MdInfoOutline, MdSettings, MdOpacity} from 'react-icons/md';
import {FaSatellite, FaSatelliteDish} from 'react-icons/fa';
// Hooks
import {useRadar} from 'Context/RadarContext';
import {useSatellite} from 'Context/SatelliteContext.js';
import useToggle from 'hooks/useToggle';
import {useControlPanel} from 'Context/ClientControlPanelContext';
import InfosPanel from './InfosPanel';
import useBound from 'hooks/useBound';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';

const TopMenu = () => {
  const {showSatellite} = useSatellite();
  const [open, toggleOpen] = useToggle(true);
  const { showSatellitePanel, toggleSatellitePanel, toggleInfosPanel, showInfosPanel, showAlerts } = useControlPanel();
  const [maxBoundsState, centerCoordinate] = useBound();
  const { alertedTargets } = useClientTargetsState();

  if(maxBoundsState && centerCoordinate && alertedTargets != null)
  return (
    <TopMenuStyled className={`${!open && 'closed'} ${(alertedTargets && showAlerts) && 'notifOpen'}`}>
       <TooltipComponent text={showSatellite? "Opções avançadas de satélite" : "Habilite as imagens de satélite no menu inferior antes de continuar!"} xAxis={"-50%"} yAxis={"140%"}>
        <Button
          selected={showSatellitePanel}
          disabled={!showSatellite}
          onClick={toggleSatellitePanel}
        >
          <FaSatellite />
        </Button>
      </TooltipComponent>
      <Button
        selected={showInfosPanel}
        onClick={toggleInfosPanel}>
          <MdInfoOutline />
      </Button>
      {showSatellite && <SatellitePanel />}
      {showInfosPanel && <InfosPanel />}
      <OpenTopMenu onClick={toggleOpen}><MdSettings /></OpenTopMenu>
    </TopMenuStyled>
  )
}

export default TopMenu
