export default function (state = true, action){
    switch(action.type){
        case "HISTORY_DISCHARGES_TARGETS_CONTROL_PROVIDER":
            return action.payload
            
    default: return state
    }
}



