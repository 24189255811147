import { useState, useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/pt-br';
// Components
import { Container, Section, SectionTitle, SectionItem, SectionConteinerRow, SectionConteinerColumn, Button, Divider, BufferTag} from './styled';
import RadarPanel from './RadarPanel';
import SatellitePanel from './SatellitePanel';
import './ControlPanel.css';
import TooltipComponent from 'components/Modal/Tooltip.js';
/* Material */
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField"
// Icons
import { FaHistory } from 'react-icons/fa';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Hooks
import {useHistoryTarget} from 'Context/HistoryTargetContext';
import useToggle from 'hooks/useToggle';
import {useControlPanel} from 'Context/ControlPanelContext';
import { printScreenProvider } from 'redux/actions/printScreenAction';
import { useDischarges } from 'Context/DischargesContext';
import { historyDischargesControlProvider } from 'redux/actions/historyDischargesControlAction';
import { historyDischargesBuffersProvider } from 'redux/actions/historyDischargesBuffersAction';
import { historyDischargesBuffersControlProvider } from 'redux/actions/historyDischargesBuffersControlAction';


const FiltersPage = (props) => {
  const { showRadarPanel, toggleRadarPanel, showSatellitePanel, toggleSatellitePanel, showAlerts } = useControlPanel();
  const { width, open, setStatistics, toggleChangeTabs } = props
  const moment = require('moment');
  moment.locale('pt-br');
  const dispatch = useDispatch();
  const { historyTargets } = useHistoryTarget();
  //const [open, toggleOpen] = useToggle(false);
  const [satelliteNetwork, setSatelliteNetwork] = useState(true);
  const [soloNetwork, setSoloNetwork] = useState(false);
  const [flashes, setFlashes] = useState(true);
  const [groups, setGroups] = useState(false);
  const [intraNuvem, setIntraNuvem] = useState(false);
  const [nuvemSolo, setNuvemSolo] = useState(false);
  const [searchTime, setSearchTime] = useState("Real Time");
  const [interval, setInterval] = useState([true, true, true, false, false, false]);
  const [buffer, setBuffer] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [buffers, setBuffers] = useState([]);
  const [tempColor, setTempColor] = useState(true);
  const [resetBuffers, setResetBuffers] = useState(true);
  const data = useSelector(state => state.historyDischargesDataProvider);
  const dataControl = useSelector(state => state.historyDischargesControlProvider);
  const activedBuffer = useSelector(state => state.historyDischargesBuffersControlProvider);
  const buffersData = useSelector(state => state.historyDischargesBuffersProvider);
  const [reload, setReload] = useState(false);

  const {
    dischargesToShow,
    dischargesTypeToShow,
    toggleDischargeToShow,
    toggleDischargeTypeToShow,
  } = useDischarges();

  const toggleSearchTime = (value) => {
    setSearchTime(value)
  }

  const handleStatistics = (e) => {
    setStatistics(true);
    toggleChangeTabs(e, 1);
  }

  const handlePrintScreen = () => {
    dispatch(printScreenProvider(imageName? imageName : "Histórico de descargas"));
    setImageName("");
  }

  const toggleTargetsSelected = (index) => {
    var temp = dataControl[0];
    temp[index] = !temp[index];
    dispatch(historyDischargesControlProvider(temp, !dataControl[1]));
    setReload(true);
  }     

  const handleAddBuffer = () => {
    if(buffer && buffer != "" && tempColor){
      let tempBuffers = buffers;
      tempBuffers?.map((target, index) => dataControl[0][index] && target?.length < 5? target.push({id: target?.length, km: parseInt(buffer), color: tempColor}) : target);
      setBuffers(tempBuffers);
      let tempBuffersData = buffersData;
      tempBuffersData?.map((target, index) => {
        dataControl[0][index]? target.buffers.push(parseInt(buffer)) : target;
        dataControl[0][index]? target.colors.push(tempColor) : target;
      });
      let tempBuffersControl = activedBuffer[0];
      tempBuffersControl?.map((buffer, index) => {
        dataControl[0][index]? buffer.push(true) : buffer;
      });
      dispatch(historyDischargesBuffersProvider(tempBuffersData));
      dispatch(historyDischargesControlProvider(tempBuffersControl, !dataControl[1]));
      setBuffer("");
      setTempColor("#000000");
    }
  }
  
  const handleClearAllBuffers = () => {
    let temp = [];
    setBuffers([]);
    historyTargets?.features?.map((target) => {temp.push([false, false, false])});
    dispatch(historyDischargesBuffersControlProvider(temp, !activedBuffer[1]));
  }

  const handleClearBuffer = (targetIndex, bufferIndex) => {
    let tempControlBuffer = activedBuffer[0];
    activedBuffer[0].map((target, index) => index === targetIndex? target.filter((buffer, bIndex) => bIndex === bufferIndex? tempControlBuffer[index][bIndex] = false : '') : target);
    dispatch(historyDischargesBuffersControlProvider(tempControlBuffer, !activedBuffer[1]));
    let tempBuffer = buffers?.map((target, index) => index === targetIndex? target.filter((buffer, bIndex) => buffer.id != bufferIndex? buffer : null) : target);
    setBuffers(tempBuffer)
  }

  useEffect(() => {
    satelliteNetwork? dischargesTypeToShow[0]? "" : toggleDischargeTypeToShow(0) : !dischargesTypeToShow[0]? "" : toggleDischargeTypeToShow(0);
    satelliteNetwork? !dischargesTypeToShow[1]? "" : toggleDischargeTypeToShow(1) : "";
    satelliteNetwork? setFlashes(true) : "";
    satelliteNetwork? setGroups(false) : "";
  }, [satelliteNetwork])

  useEffect(() => {
    flashes? dischargesTypeToShow[0]? "" : toggleDischargeTypeToShow(0) : !dischargesTypeToShow[0]? "" : toggleDischargeTypeToShow(0);
    !flashes && !groups? setSatelliteNetwork(false) : setSatelliteNetwork(true);
  }, [flashes])

  useEffect(() => {
    groups? dischargesTypeToShow[1]? "" : toggleDischargeTypeToShow(1) : !dischargesTypeToShow[1]? "" : toggleDischargeTypeToShow(1);
    !flashes && !groups? setSatelliteNetwork(false) : setSatelliteNetwork(true);
  }, [groups])

  useEffect(() => {
    if(activedBuffer[0]){
      let temp = [];
      let defaultBuffers = [{id: 0, km: 4, color: '#FB1C29'}, {id: 1, km: 10, color: '#FDA731'}, {id: 2, km: 20, color: '#118014'}]
      activedBuffer[0].map((target, index) => temp.push([]));
      activedBuffer[0].map((target, index1) => target?.map((buffer, index2) => temp[index1].push({id: defaultBuffers[index2]?.id, km: defaultBuffers[index2]?.km, color: defaultBuffers[index2]?.color})));
      setBuffers(temp);
      setResetBuffers(false);
    }
  }, [historyTargets])

  useEffect(() => {
    if(activedBuffer[0]){
      let temp1 = [];
      let temp2 = [];
      let defaultBuffers = [{id: 0, km: 4, color: '#FB1C29'}, {id: 1, km: 10, color: '#FDA731'}, {id: 2, km: 20, color: '#118014'}]
      activedBuffer[0].map((target, index) => temp1.push([]));
      activedBuffer[0].map((target, index1) => target?.map((buffer, index2) => temp1[index1].push({id: defaultBuffers[index2]?.id, km: defaultBuffers[index2]?.km, color: defaultBuffers[index2]?.color})));
      setBuffers(temp1);
      historyTargets?.features?.map((target) => {temp2.push([true, true, true])});
      dispatch(historyDischargesBuffersControlProvider(temp2, !activedBuffer[1]));
      setResetBuffers(false);
    }
  }, [resetBuffers])

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload])
  
  if(open){
    return (
      <Container open={open}>
        <Section>
          <SectionTitle>Redes de detecção</SectionTitle>
          <Divider />
          <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={soloNetwork}
                  onChange={() => setSoloNetwork(!soloNetwork)}
                  value={soloNetwork}
                  color="primary"
                /> )}
                label="Rede Solo"
                //style={fonts.formsField}
              /> 
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={satelliteNetwork}
                    onChange={() => setSatelliteNetwork(!satelliteNetwork)}
                    value={satelliteNetwork}
                    color="primary"
                  /> )}
                  label="Rede Satélite"
                  //style={fonts.formsField}
              />
          </SectionConteinerRow>
          {(satelliteNetwork || soloNetwork) &&
            <>
              <SectionTitle>Tipos de descargas</SectionTitle>
                <Divider />
                <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24} gap={"1px"}>
                  {soloNetwork &&
                    <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24} gap={"1px"}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={intraNuvem}
                            onChange={() => setIntraNuvem(!intraNuvem)}
                            value={intraNuvem}
                            color="primary"
                          /> )}
                          label="Intra Nuvem"
                          //style={fonts.formsField}
                        /> 
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={nuvemSolo}
                              onChange={() => setNuvemSolo(!nuvemSolo)}
                              value={nuvemSolo}
                              color="primary"
                            /> )}
                            label="Nuvem Solo"
                            //style={fonts.formsField}
                        />
                    </SectionConteinerRow>
                  }
                  {satelliteNetwork &&
                    <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24} gap={"1px"}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={groups}
                            onChange={() => setGroups(!groups)}
                            value={groups}
                            color="primary"
                          /> )}
                          label="Groups"
                          //style={fonts.formsField}
                        /> 
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={flashes}
                              onChange={() => setFlashes(!flashes)}
                              value={flashes}
                              color="primary"
                            /> )}
                            label="Flashes"
                            //style={fonts.formsField}
                        />
                    </SectionConteinerRow>
                  }
                </SectionConteinerRow>
              </>
          }

          {(satelliteNetwork || soloNetwork) &&
            <>
              <SectionTitle>Dados</SectionTitle>
              <Divider />
              <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={"Real Time" === searchTime}
                      onChange={(e) => toggleSearchTime(e.target.value)}
                      value={"Real Time"}
                      color="primary"
                    /> )}
                  label={'Tempo real'}
                  //style={fonts.formsField}
                /> 
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={"Period" === searchTime}
                      onChange={(e) => toggleSearchTime(e.target.value)}
                      value={"Period"}
                      color="primary"
                    /> )}
                  label='Período'
                  //style={fonts.formsField}
                />
              </SectionConteinerRow>

              {searchTime === "Period" && 
                <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      label="Data/Hora inicial"
                      //value={selectedDate}
                      //onChange={handleDateChange}
                      format="DD/MM/YYYY hh:mm A"
                      mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                    />

                    <KeyboardDateTimePicker
                      label="Data/Hora final"
                      //value={selectedDate}
                      //onChange={handleDateChange}
                      format="DD/MM/YYYY hh:mm A"
                      mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                    />
                  </MuiPickersUtilsProvider>
                </SectionConteinerRow>
              } 
            </>
          }

          {searchTime === "Real Time" && (satelliteNetwork || soloNetwork) &&
            <>
              <SectionTitle>Intervalo</SectionTitle>
                <Divider />
                <SectionConteinerColumn>
                  {dischargesToShow.map((showBool, index) => (
                    <div key={index}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={showBool}
                          onChange={() => toggleDischargeToShow(index)}
                          value={showBool}
                          
                          color="primary"
                        /> )}
                      label={`${(index + 1) * 10} min`}
                      style={{color: `${index === 0? "#A8050E" : index === 1? "#ED5A35" :  index === 2? "#18A91C" : index === 3? "#0FF" : index === 3? "#0FF" : index === 4? "#00F" : "#AAA"}`}}
                    />
                    <span>- {moment().format('DD/MM/YYYY')} às {moment().subtract(((index + 1) * 10), 'minutes').format('LT')} até {moment().format('DD/MM/YYYY')} às {moment().subtract(((index) * 10), 'minutes').format('LT')}</span></div>
              ))}
                </SectionConteinerColumn>
            </>
          }
        </Section>
        
        {open &&
          <>
            <Section style={{marginTop: "15px"}}>
              <SectionTitle>Alvos</SectionTitle>
              <Divider />
              <SectionConteinerColumn>
                {data[0] && data[0].map((target, index) => {
                  return(
                    <SectionConteinerRow key={index} gap={"24px"}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={dataControl[0][index]}
                            onChange={() => toggleTargetsSelected(index)}
                            value={dataControl[0][index]}
                            color="primary"
                          /> )}
                        label={target?.properties.nome}
                        //style={fonts.formsField}
                      />
                      <SectionConteinerRow gap={"8px"}>
                        {buffers?.length > 0 && buffers[index]?.map((buffer, bIndex) => {
                          return(
                            <BufferTag key={bIndex} color={buffer.color} onClick={() => handleClearBuffer(index, buffer.id)}>{buffer.km}</BufferTag>
                          )
                        })}
                      </SectionConteinerRow>
                    </SectionConteinerRow>
                )})}
              </SectionConteinerColumn>

              <SectionTitle>Buffer</SectionTitle>
              <Divider />
              <SectionConteinerRow width={width? width * 0.35 * 0.044 : 24}>
                <TextField
                  id="buffer"
                  label="Buffer"
                  //className={classNames(classes.textFieldDefault, colors.textField)}
                  style={{width: "60px"}}
                  type="number"
                  value={buffer}
                  onChange={(e) => setBuffer(e.target.value)}
                  margin="normal"
                  shrink={true}
                  placeholder="Buffer"
                  variant="outlined"
                  //inputProps={{style: fonts.formsField, maxLength: 50}}
                  //InputLabelProps={{style: fonts.formsLabel}}
                />

                <input type="color" value={tempColor} onChange={(e) => setTempColor(e.target.value)}></input>
                <Button onClick={handleAddBuffer} style={{marginTop: "8px"}}>Adicionar</Button>
                <Button onClick={() => setResetBuffers(true)} style={{marginTop: "8px"}}>Resetar</Button>
                <Button onClick={handleClearAllBuffers} style={{marginTop: "8px"}}>Limpar</Button>
              </SectionConteinerRow>

              <SectionTitle>Estatísticas</SectionTitle>
              <Divider />
              <SectionConteinerRow style={{marginTop: "14px"}}>
                <Button onClick={handleStatistics}>Ver</Button>
              </SectionConteinerRow>

              <SectionTitle style={{marginTop: "8px"}}>Exportar imagem</SectionTitle>
              <Divider />
              <SectionConteinerRow style={{marginTop: "14px"}} width={width? width * 0.35 * 0.044 : 24}>
                <TextField
                  id="imageName"
                  label="Nome da imagem"
                  //className={classNames(classes.textFieldDefault, colors.textField)}
                  style={{width: "200px"}}
                  value={imageName}
                  onChange={(e) => setImageName(e.target.value)}
                  margin="normal"
                  shrink={true}
                  placeholder="Nome da imagem"
                  variant="outlined"
                  //inputProps={{style: fonts.formsField, maxLength: 50}}
                  //InputLabelProps={{style: fonts.formsLabel}}
                />
                <Button onClick={handlePrintScreen} style={{marginTop: "8px"}}>Exportar</Button>
              </SectionConteinerRow>
            </Section>
          </>
        }
         {/*<TooltipComponent text={showSatellite? "Opções avançadas de satélite" : "Habilite as imagens de satélite no menu inferior antes de continuar!"} xAxis={"-50%"} yAxis={"140%"}>
        <Button
            selected={showSatellitePanel}
            disabled={!showSatellite}
            onClick={toggleSatellitePanel}
          >
            <FaSatellite />
          </Button>
        </TooltipComponent>
        <TooltipComponent text={showRadar? "Opções avançadas de radar" : "Habilite as imagens de radar no menu inferior antes de continuar!"} xAxis={"-50%"} yAxis={"140%"}>
          <Button
            selected={showRadarPanel}
            disabled={!showRadar}
            onClick={toggleRadarPanel}
          >
            <FaSatelliteDish />
          </Button>
        </TooltipComponent>
        <Button><MdInfoOutline /></Button>
        {showSatellite && <SatellitePanel />}
        {showRadar && <RadarPanel />}*/}
      </Container> 
    )
  }
}

FiltersPage.propTypes = {
  width: PropTypes.any,
  open: PropTypes.any,
  setStatistics: PropTypes.any,
  toggleChangeTabs: PropTypes.any,
};

export default FiltersPage;
