import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import FileUpload from '@material-ui/icons/CloudUpload';
import fontStyles from 'styles/fonts-jss';
import { withStyles } from '@material-ui/core/styles';
import styles from './button-jss';
import file_present_icon from 'images/file_present_icon.png'

const Input = styled.input`
    display: none;
`;

export const ButtonForFile = styled(Button)`
    cursor: "pointer",
    border: "2px solid",
    borderColor: "#FFB703",
    backgroundColor: "#FFB703",
    borderRadius: "5px",
    padding: "6px 14px 6px 14px",
        '& $text': {
            color: theme.palette.menu.strongYellow,
        },
        '&:hover': {
            background: theme.palette.menu.strongYellow,
        '& $text': {
            color: theme.palette.menu.strongBlue,
        }
    },
`;

const UploadButton = (props) => {
    const inputRef = useRef();
    const fonts = fontStyles();
    const { classes } = props;
    const handleClick = () => {
        inputRef.current.click();
    };

    const handleChange = (e) => {
        const fileReader = new FileReader();
        var type = e.target.files[0].name.split('.');
        var len = type.length;

        if (props.fileType === 'geojson') {
            props.setFileName(e.target.files[0].name);
            if (type[len - 1] === 'geojson') {
                fileReader.readAsText(e.target.files[0], 'UTF-8');
                fileReader.onload = (e) => {
                    props.onChange(JSON.parse(e.target.result), 'geojson');
                    props.setErrorMessage('');
                };
            } else {
                props.setErrorMessage(
                    'Verifique o formato do arquivo e tente novamente!',
                );
                setTimeout(() => {
                    props.setErrorMessage('');
                }, 500);
            }
        } else if (props.fileType === 'image') {
            if (e.target.files[0].size > 512000) {
                props.setErrorMessage(
                    `Essa imagem possui ${Math.round(
                        e.target.files[0].size / 1000,
                    )}Kb, o limite máximo é de 500 Kb!`,
                );
            } else if (
                type[len - 1] === 'jpg' ||
                type[len - 1] === 'png' ||
                type[len - 1] === 'jpeg' ||
                type[len - 1] === 'JPG' ||
                type[len - 1] === 'PNG' ||
                type[len - 1] === 'JPEG'
            ) {
                if (props.buttonName === 'Anexar Assinatura') {
                    const fileReader = new FileReader();
                    fileReader.onload = (event) => {
                        const img = new Image();
                        img.onload = () => {
                            if (img.width <= 300 && img.height <= 80) {
                                props.onChange(e.target.files[0]);
                                props.setErrorMessage('');
                            } else {
                                props.setErrorMessage(
                                    `Essa imagem possui dimensões de ${img.width} x ${img.height} px . O limite é de 300x80px!`,
                                );
                                props.onChange(null);
                            }
                        };
                        img.src = event.target.result;
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                } else {
                    props.onChange(e.target.files[0]);
                    props.setErrorMessage('');
                }
            } else {
                props.setErrorMessage(
                    'Verifique o formato do arquivo e tente novamente!',
                );
                setTimeout(() => {
                    props.setErrorMessage('');
                }, 5000);
            }
        } else if (props.fileType === 'active') {
            const file = e.target.files[0];
            props.setActiveFileName(e.target.files[0].name);
            if (file) {
                const fileType = file.name.split('.').pop().toLowerCase();
                if (fileType === 'geojson') {
                    fileReader.readAsText(file, 'UTF-8');
                    fileReader.onload = (e) => {
                        props.onChange(e.target.result, 'active'); // Passando as coordenadas para o componente pai
                    };
                } else {
                    props.setErrorMessage('O arquivo precisa ser do tipo geojson!');
                    setTimeout(() => {
                        props.setErrorMessage('');
                    }, 500);
                }
            }
        }
            else if (props.fileType === 'pdf') {
            if (e.target.files[0].size > 5242880) {
                props.setErrorMessage(
                    `Esse arquivo possui ${Math.round(
                        e.target.files[0].size / 1000,
                    )}Kb, o limite máximo é de 5.242.880 Kb!`,
                );
            } else if (type[len - 1] === 'pdf') {
                props.onChange(e.target.files[0]);
                props.setErrorMessage('');
            } else {
                props.setErrorMessage(
                    'Verifique o formato do arquivo e tente novamente!',
                );
                setTimeout(() => {
                    props.setErrorMessage('');
                }, 500);
            }
        } else {
            props.setErrorMessage(
                'Verifique o formato do arquivo e tente novamente!',
            );
            setTimeout(() => {
                props.setErrorMessage('');
            }, 500);
        }
    };


    return (
        !props.edit ? (
            <>
                <div className={classes.uploadButton} onClick={handleClick}>
                    <span style={fonts.buttonDefault} className={classes.text}>
                        {props.buttonName.toUpperCase()}
                    </span>
                    <FileUpload
                        style={{ marginLeft: '6px' }}
                        className={classes.icon}
                    />
                </div>
                <Input
                    ref={inputRef}
                    type="file"
                    onChange={handleChange}
                    id="fileInput"
                    style={{ display: 'none' }}
                />
            </>
        ) : (
            <div className={classes.filePresentContainer}>
                <div
                    className={classes.fileRemoveContainer}
                    onClick={handleClick}
                >
                    <img
                        src={file_present_icon}
                        alt="File Icon"
                        style={{ width: '40px', height: '40px' }}
                    />
                    {props.activeFileName && (
                        <span className={classes.fileNameText}>
                            {props.activeFileName}
                        </span>
                    )}
                </div>
                <Input
                    ref={inputRef}
                    type="file"
                    onChange={handleChange}
                    id="fileInput"
                    style={{ display: 'none' }}
                />
            </div>
        )
    );
    
    
};

UploadButton.propTypes = {
    onChange: PropTypes.func,
    setFileName: PropTypes.string,
    setActiveFileName: PropTypes.string,
    edit: PropTypes.bool,
    setErrorMessage: PropTypes.string,
    buttonName: PropTypes.string,
    fileType: PropTypes.string,
    classes: PropTypes.object,
    activeFileName: PropTypes.string
};

export default withStyles(styles)(UploadButton);