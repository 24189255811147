import { useTarget } from 'Context/TargetContext';
import {useMapContext} from 'Context/MapContext';
import {bbox} from '@turf/turf';
import {useModal} from 'Context/ModalContext';

export default function useTargetHelper() {
  const { planos: companies } = useTarget();
  const { setGoTo } = useMapContext();
  const { openMonitoringModal } = useModal();

  const getIds = (searchTargetId) => {
    let companyId, planId, targetId;
    companies?.every(company => {
      return company.planos.every((plan) => {
        return plan?.alvos.every((target)=> {
          if(target.id_alvo !== searchTargetId) return true
          companyId = company.id;
          planId = plan.id;
          targetId = target.id;
          return false
        })
      })
    })
    return {companyId, planId, targetId};
  }

  const getNames = (searchTargetId) => {
    try{
      const {companyId, planId, targetId} = getIds(searchTargetId)
      return {
        company: companies[companyId]?.nome,
        plan: companies[companyId].planos[planId].nome,
        target: companies[companyId].planos[planId].alvos[targetId].nome
      }
    }catch{
      return {
        company: 'Não foi possível encontrar',
        plan: 'Não foi possível encontrar',
        target: 'Não foi possível encontrar'
      }
    }
  }

  const getCompany = (searchTargetId) => {
    try{
      const {companyId} = getIds(searchTargetId)
      return companies[companyId]?.nome;
    }catch{
      return 'Não foi possível encontrar'
    }
  }

  const getPlan = (searchTargetId) => {
    try{
      const {companyId, planId} = getIds(searchTargetId)
      return companies[companyId].planos[planId].nome;
    }catch{
      return 'Não foi possível encontrar'
    }
  }

  const getTarget = (searchTargetId) => {
    try{
      const {companyId, planId, targetId} = getIds(searchTargetId)
      return companies[companyId].planos[planId].alvos[targetId].nome;
    }catch{
      return 'Não foi possível encontrar'
    }
  }

  const sendToModal = (searchTargetId, tab = 0) => {
    try{
      const {companyId, planId, targetId} = getIds(searchTargetId)
      const company = {...companies[companyId], id: 0}
      company.planos = [{...company.planos[planId], id: 0}]
      company.planos[0].alvos = [{...company.planos[0].alvos[targetId], id: 0, id_plano: company.planos[0].id_plano}]
      openMonitoringModal([company], tab);
    }catch{
      return 'Não foi possível encontrar'
    }
  }

  const goToTarget = (searchTargetId) => {
    try{
      const {companyId, planId, targetId} = getIds(searchTargetId)
      const mapFeature = companies[companyId].planos[planId].alvos[targetId].coordenadas;
      const [minLng, minLat, maxLng, maxLat] = bbox(mapFeature);
      setGoTo([
        [minLng, minLat],
        [maxLng, maxLat]
      ]);
    }catch{
      return 'Não foi possível encontrar'
    }
  }


  return {
    getIds,
    getNames,
    getCompany,
    getPlan,
    getTarget,
    sendToModal,
    goToTarget,
  }
}
