import React, { useState, useEffect, useRef } from 'react';

// Libs
import PropTypes from 'prop-types';
// Components
import PlansTab from './PlansTab';
import { Column, ColumnOverFlow } from 'components/Flex';
import {
  Logo,
  SidebarStyled,
  Head,
  Title,
  OpenButton,
  Tab, TabBar,
  TabButton,
  MapStyleOption
} from './styled';
import TooltipComponent from 'components/Modal/Tooltip.js';
import { useDispatch, useSelector } from 'react-redux';

// Icons
import {BiMapAlt} from 'react-icons/bi';
import {ImExit} from 'react-icons/im';
import { BsGrid3X3GapFill } from 'react-icons/bs';
import {
  MdOutlinePalette,
  MdOutlineSettingsSuggest,
  MdOutlineHistoryToggleOff,
  MdWest,
  MdClose
} from 'react-icons/md';
import { FaNewspaper,FaMapMarkedAlt } from 'react-icons/fa';
import { BsFileBarGraphFill, BsTools } from 'react-icons/bs';
// Hooks
import {useControlPanel} from 'Context/ControlPanelContext';
import {useHistory} from 'react-router-dom';
import {useMapContext} from 'Context/MapContext';
import PreferencesTab from './PreferencesTab';
import HistoryTab from './HistoryTab';
import TicketsTab from './ReportCard';
import ReportsTab from './ReportsTab/index';
import ProductsTab from './ProductsTab';
import {useAuth} from 'Context/AuthContext';
import {useToast} from 'Context/ToastContext';
import { mapSidebarMenuProvider } from 'redux/actions/mapSidebarMenuAction';
/* CSS */
import styles from './sidebar-jss';
import fontStyles from 'styles/fonts-jss';
import { withStyles } from '@material-ui/core/styles';
/* Redux */
import { tabMenuProvider } from 'redux/actions/tabMenuAction';

const LeftButton = ({onClick, icon, text, selected, disabled, tip,  ...rest}) => {
  return (
    tip !== '' ? 
    (
      <TooltipComponent text={tip} position='right'>
        <TabButton onClick={onClick} selected={selected} disabled={disabled}>
          <Column style={{alignItems:"center"}}>
            {icon}
          </Column>
          <span>{text}</span>
        </TabButton>
      </TooltipComponent>
    ) : 
    (
      <TabButton {...rest} onClick={onClick} selected={selected} disabled={disabled}>
        <Column>
          {icon}
        </Column>
        <span>{text}</span>
      </TabButton>
    ) 
  )
}

LeftButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.any,
  text: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  tip: PropTypes.string,
  ...TabButton.propTypes
};

LeftButton.defaultProps = {
  onClick: () => undefined,
  icon: undefined,
  text: '',
  selected: false,
  disabled: false,
  tip: '',
}

const Sidebar = (props) => {
    const Auth = useAuth();
    const OpenToast = useToast();
    const [planTabWidth, setPlanTabWidth] = useState(null);
    const [sidebarWidth, setSidebarWidth] = useState(null);
    const {showSidebar, toggleShowSidebar} = useControlPanel();
    //const [tab, ChangeTab] = UseTabs(7, planTabWidth, styleTabWidth, sidebarWidth);
    const history = useHistory();
    const fonts = fontStyles();
    const { setMapStyle} = useMapContext();
    const { classes } = props;
    const handleStyleChange = (e) => setMapStyle(e.target.value);
    const ref = useRef(null);
    const sideBarRef = useRef(null);
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const openSidebar = useSelector((state) => state.openMapSidebarProvider);
    const {mapRef} = useMapContext();

    const handleLogout = async () => {
      await Auth.logout();
      OpenToast('success', 'Logout feito com sucesso!')
    }

    const ChangeTab = (num) => {
        setTab(num);
        dispatch(tabMenuProvider(0));
    }

    useEffect(() => {
      tab === 1? dispatch(mapSidebarMenuProvider(planTabWidth? [planTabWidth, sidebarWidth] : sidebarWidth? [null, sidebarWidth] : [null, 116])) : "";
      tab === 6? dispatch(mapSidebarMenuProvider(sidebarWidth? [128, sidebarWidth] : [128, 116])) : "";
      tab != 1 && tab != 6? dispatch(mapSidebarMenuProvider(null)) : " ";
      setReload(true);
    }, [planTabWidth, sidebarWidth, sideBarRef, tab])

    useEffect(() => {
      if(reload){
        setReload(false);
      }
    }, [reload])

    useEffect(() => {
      if(sideBarRef){
        setSidebarWidth(sideBarRef?.current? sideBarRef.current.getBoundingClientRect().width : null)
      }
    }, [sideBarRef])

    useEffect(() => {
      if(openSidebar){
        toggleShowSidebar(true);
        ChangeTab(2);
      }
    }, [openSidebar])

    return (
        <SidebarStyled className={!showSidebar && 'closed'} ref={ref}>
          <OpenButton onClick={toggleShowSidebar}>
            <BiMapAlt />
          </OpenButton>
          <TabBar  ref={sideBarRef}>
            <Logo />
            <ColumnOverFlow style={{textAlign:"center", height: ref.current? (ref.current.getBoundingClientRect().height * 0.6) + "px" : "400px"}} gap='10px'>
              <LeftButton icon={<BiMapAlt />} text='Planos' selected={tab === 1} onClick={() => ChangeTab(1)} />
              <LeftButton icon={<FaNewspaper />} text='Boletins' selected={tab === 2} onClick={() => ChangeTab(2)} />
              <LeftButton icon={<FaMapMarkedAlt />} text='Descargas' selected={tab === 3} onClick={() => history.push("/historico_de_descargas")} />
              <LeftButton icon={ <BsFileBarGraphFill />} text='Relatórios' selected={tab === 4} onClick={() => ChangeTab(4)} />
              <LeftButton icon={ <BsTools />} text='Produtos' selected={tab === 5} onClick={() => ChangeTab(5)} />
              <LeftButton icon={<MdOutlineHistoryToggleOff />} text='Histórico' selected={tab === 6} onClick={() => ChangeTab(6)} />
              <LeftButton icon={<MdOutlinePalette />} text="Estilo" selected={tab === 7} onClick={() => ChangeTab(7)} />
              <LeftButton icon={<MdOutlineSettingsSuggest />} text='Preferências' selected={tab === 8} onClick={() => ChangeTab(8)} />
            </ColumnOverFlow>
            <Column style={{marginBottom: "25px" }}>
              <LeftButton icon={<BsGrid3X3GapFill />} onClick={() => history.push('/portais')} tip='Voltar para a tela de seleção de portal.' />
              <LeftButton icon={<ImExit />} onClick={handleLogout} tip='Realizar logout' />
            </Column>
          </TabBar>
          {tab > 0 && tab < 2 && (
            <Tab>
              <Head>
                <h1>MONITORAMENTO</h1>
                <button onClick={() => ChangeTab(0)}><MdClose /></button>
              </Head>
              {tab === 1 && (
                <>
                  <Title>PLANOS</Title>
                  <PlansTab setPlanTabWidth={setPlanTabWidth}/>
                </>
              )}
            </Tab>
            )}
            {tab === 2 && <TicketsTab onClose = {() => ChangeTab(0)}/>}
            {tab === 4 && <ReportsTab onClose = {() => ChangeTab(0)}/>}
            {tab === 5 && <ProductsTab onClose = {() => ChangeTab(0)}/>}
            {tab === 6 && <HistoryTab onClose = {() => ChangeTab(0)}/>}
            {tab === 7 && (
                <Column gap='56px'>
                  <Title>ESTILOS</Title>
                  <Column gap="48px">
                    <MapStyleOption >
                        <input
                            type="radio"
                            value="satellite-streets-v11"
                            name="style"
                            id="satellite-streets"
                            onChange={handleStyleChange}
                        />
                        <label htmlFor="satellite-streets">
                            Street
                        </label>
                    </MapStyleOption>
                    <MapStyleOption>
                        <input
                            type="radio"
                            value="streets-v11"
                            name="style"
                            id="light"
                            defaultChecked
                            onChange={handleStyleChange}
                        />
                        <label htmlFor="light">Light</label>
                    </MapStyleOption>
                    <MapStyleOption>
                        <input
                            type="radio"
                            value="dark-v10"
                            name="style"
                            id="dark"
                            onChange={handleStyleChange}
                        />
                        <label htmlFor="dark">Dark</label>
                    </MapStyleOption>
                  </Column>
                </Column>
              )}
              {tab === 8 && <PreferencesTab onClose = {() => ChangeTab(0)} />}
        </SidebarStyled>
    );
};

Sidebar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
};


export default withStyles(styles)(Sidebar);
