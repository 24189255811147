/* Libraries */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
/* Material UI */
import Info_icon from "@material-ui/icons/Info";
import IconButton from '@material-ui/core/IconButton';
import { withStyles, useTheme } from "@material-ui/core/styles";
/* CSS */
import styles from "./modal-jss"
import fontStyles from 'styles/fonts-jss';

const ModalInfo = (props) => {
  
  const { classes, linha1, linha2, linha3, linha4, linha5, linha6, marginLeft, width, height, marginTop, top, divMgBt, divMgLf, divMgTp} = props;
  const fonts = fontStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [pageX, setPageX] = useState(false);
  const [pageY, setPageY] = useState(false);

  const handleClickOpen = (cordinates) => {
      setPageX(cordinates.pageX);
      setPageY(cordinates.pageY - marginTop);
      setOpen(true);
  };

  const handleClickClose = (mouseOut) => {
      setOpen(false);
  };


  return (
    <div style={{height: '15px',  width: '15px',marginLeft: divMgLf +'px', marginBottom: divMgBt +'px',  marginTop: divMgTp +'px'}}>
      {open &&

        <div className = {classes.balloonContainer} style = {{marginLeft: marginLeft - 70 +'px', top: pageY, width: width +'px' , height: height +'px'}}>
          <div className={classes.textContainer}>
            { linha1 &&
              <h5 style={fonts.infoText}>{linha1}</h5>
            }
            { linha2 &&
              <h5 style={fonts.infoText}>{linha2}</h5>
            }
            { linha3 &&
              <h5 style={fonts.infoText}>{linha3}</h5>
            }
            { linha4 &&
              <h5 style={fonts.infoText}>{linha4}</h5>
            }
            { linha5 &&
              <h5 style={fonts.infoText}>{linha5}</h5>
            }
            { linha6 &&
              <h5 style={fonts.infoText}>{linha6}</h5>
            }
          </div>
          <div className = {classes.ballonDetail}  style = {{top: top +'px'}}>
          </div>
        </div>
      }

      <IconButton  style = {{marginLeft: marginLeft +'px'}} color={theme.palette.menu.mediumGrey} aria-label="Info" onMouseEnter={(e) => handleClickOpen(e.nativeEvent)} onMouseLeave={(e) => handleClickClose(e)}>
        <Info_icon fontSize="medium" />
      </IconButton>
    </div>
  );
}

ModalInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  linha1: PropTypes.string.isRequired,
  linha2: PropTypes.string.isRequired,
  linha3: PropTypes.string.isRequired,
  linha4: PropTypes.string.isRequired,
  linha5: PropTypes.string.isRequired,
  linha6: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  marginTop: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
  divMgLf: PropTypes.string.isRequired,
  divMgBt: PropTypes.string.isRequired,
  divMgTp: PropTypes.string.isRequired,
};

export default withStyles(styles)(ModalInfo);