import { djangoApi } from '../api';

export const getCompanies = async () => {
    try {
        const response = await djangoApi.get('empresas/');
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getCompany = async (id) => {
    try {
        const response = await djangoApi.get(`empresas/geral/${id}/`);
        return response.data;
    } catch (error) {
        return [];
    }
};

export const postCompany = (companyData) => {
    return djangoApi.post(`empresas/`, companyData);
}

export const updateCompany = (id_company, companyData) => {


    return djangoApi.put(`empresas/${id_company}/`, companyData);
}

export const deleteCompany = async (id_company) => {
    try {
        const response = await djangoApi.delete(`empresas/${id_company}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
};

export const patchCompany = (id_company, companyData) => {
    return djangoApi.put(`empresas/${id_company}/`, companyData);
};
