import React, { memo, useContext, useState } from 'react';
import ReactMapGl, { Layer, Source } from 'react-map-gl';
import { useTools } from 'Context/ToolsContext';
import turfLength from '@turf/length';

const PolygonLayer = (props) => {
    const { toolInUse, polygon, setDischargesHistory } = useTools();
    const polygonLine = polygon.polygonLine;
    const pointHelper = polygon.pointHelper;
    setDischargesHistory(false);

    if (toolInUse !== 'selection' && !polygon.finishPolygon) return null
    let coordinatesArray;
    if (toolInUse === 'selection' && polygonLine.length > 0 && pointHelper.length > 0) {
        coordinatesArray = [...polygonLine, pointHelper];
    } else {
        coordinatesArray = [...polygonLine];
    }
    const points = coordinatesArray.map((point) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: point,
        },
    }));
    const measurePointsGeo = { type: 'FeatureCollection', features: points };
    const measureLineGeo = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordinatesArray,
        },
    };
    return (
        <>
            <Source
                id="polygonPoint"
                type="geojson"
                data={measurePointsGeo}
                generateId={true}
            >
                <Layer
                    id="polygonPoint"
                    source="polygon-points"
                    type="circle"
                    paint={{
                        'circle-color': '#4fb9c4',
                        'circle-radius': 6,
                    }}
                />
            </Source>
            <Source
                id="toolPolygon"
                type="geojson"
                data={measureLineGeo}
                generateId={true}
            >
                <Layer
                    id="toolPolygon"
                    source="polygon-points"
                    type="line"
                    paint={{
                        'line-color': '#4fb9c4',
                        'line-width': 1,
                    }}
                />
            </Source>
        </>
    );
};

export default memo(PolygonLayer);
