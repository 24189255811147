import React, { useContext, memo } from 'react';
import ReactMapGl, { Layer, Source } from 'react-map-gl';
import { buffer, featureCollection, union } from '@turf/turf';
import {useTarget} from 'Context/TargetContext';
import { useSelector } from 'react-redux';


const IntersectionLayerComponent = () => {
  const { planos, toShow, mergeTargetsOption, showTargets} = useTarget();
  const buffersState = useSelector(state => state.mapBuffersStateProvider);

  const mergeTargets = (feature, location2) => {
    const a = {...feature}
    const x =union(a, location2)
    x.properties = {
      nome: `${feature.properties.nome}/${location2.properties.nome}`,
      buffer: feature.properties.buffer,
      targetId: feature.properties.targetId,
      planId: feature.properties.planId,
    }
    return x
  }

  const processTargets = () => {
    const alvos = [];
    planos.forEach((company) => {
      company.planos.forEach(plan => {
        plan.alvos?.forEach((target) => {
          if(toShow.get(company.id, plan.id, target.id))
            alvos.push(target)
        })
      })
    })

    const mergedTargets = []
    let i = 0;
    let j
    let merged = {};
    let actualMerge;
    let mergingIndexes = {};
    for(i; i < alvos.length; i++){
      if (merged[i]) continue
      j = i + 1
      mergingIndexes = {}
      actualMerge = alvos[i].coordenadas.features
      for(j; j < alvos.length; j++){
        if (merged[j]) continue
        actualMerge = actualMerge.map((layer, index) => {
          if (index === 0 || !mergeTargetsOption) return layer;
          const x = mergeTargets(layer, alvos[j].coordenadas.features[index])
          if(x){
            mergingIndexes[i] = true
            mergingIndexes[j] = true
            return x
          }
          return layer
        })
      }
      mergedTargets.push(actualMerge)
      Object.keys(mergingIndexes).forEach(a =>
        merged[a] = true
        )
      }
      const result = mergedTargets.reduce((sum, target, key) => {
      return [...sum, ...target]
    }, [])


    return { type: 'FeatureCollection', features: result }
  }


  if (planos === undefined || !planos || planos.length === 0) return <></>;

  const processedTargets = processTargets()

  return (
    <Source
      key={`nonInteractiveLayer`}
      id={`nonInteractiveLayer`}
      type="geojson"
      data={processedTargets}
      generateId={true}
      >
      <Layer
          id={`nonInteractiveLayer`}
          source={`nonInteractiveLayer`}
          type="line"
          layout={{
              visibility: !showTargets
                ? 'none'
                : 'visible',
          }}
          paint={{
            'line-color': [
              'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#FF0',
                !buffersState?
                  [
                    
                      'match',
                      ['get', 'buffer'],
                      '0',
                      '#808080',
                      '1',
                      '#223b53',
                      '4',
                      '#FB1C29',
                      '10',
                      '#FDA731',
                      '20',
                      '#118014',
                      'rgba(255, 0, 0, 0)',
                  //] 
                  ] :
                  [
                      'match',
                      ['get', 'buffer'],
                      '0',
                      '#808080',
                      '1',
                      '#223b53',
                      '4',
                      '#FB1C29',
                      '10',
                      '#FDA731',
                      '20',
                      '#118014',
                      ['get', 'color'],
                  ] 
            ],
            'line-width': 1,
        }}
      />
    </Source>
  )
}

export const IntersectionLayer = memo(IntersectionLayerComponent);


/*'match',
                    ['get', 'bufferCheck'],
                    'checked',
                    'rgba(255, 0, 0, 0)',
                    [*/