import styled, {keyframes} from 'styled-components';

export const colors = {
  white: '#FFF',
  dark: '#1D2026',

  background: '#1D2026',
  blue: {
    light: '#1B75BF',
    medium: '#1F76BC',
    dark: '#032A4A',
    divider: '#104673'
  },
  list: {
    light: '#C8C8C8',
    dark: '#ACACAC',
    target: '#797D81'
  },
  selected: '#42369A',
  button: {
    selected: '#A185F8A0',
  },
}

const rotateAnimation = keyframes`
  0% { transform: rotateZ(0deg); }
  100% { transform: rotateZ(360deg);}
`

export const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  background-color: rgba(16, 70, 115, 0.87);
  &:hover{
    background-color: ${colors.blue.light};
    &::before{
      transform: scale(1);
    }
  }
  &::before{
    content: '';
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border: 2px solid white;
    transform-origin: center;
    transform: scale(0);
    transition: transform 0.3s ease;
  }
`

export const OpenButton = styled(BaseButton)`
  z-index: 1;
  position: fixed;
  top: 16px;
  left: 16px;
  height: 48px;
  width: 48px;
  animation: ${rotateAnimation} 4s linear infinite;
  animation-play-state: paused;
  &:hover{
    animation-play-state: running;
  }

`

export const ZoomsDiv = styled.div`
  position: fixed;
  z-index: 1;
  top: 65%;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const ZoomDiv = styled.div`
  background-color: ${colors.background};
  border-radius: 999px;
  width: 36px;
  height: 75px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &::before{
    content: '';
    position: absolute;
    background-color: white;
    height: 1px;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const ZoomButton = styled.div`
  background-color: ${colors.background};
  border-radius: 999px;
  width: 36px;
  height: 36px;
  border: none;
  transition: color 0.2s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  cursor: pointer;

  &:hover{
    color: yellow;
  }

`
