const styles = theme => ({
  iconContainer: {
    marginLeft: '8px',
    width: "22px",
    height: "22px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  },

  viewButton:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    padding: "6px 14px 6px 14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    marginBottom: "15px",
    position: "absolute",
    '& $text': {
      color: theme.palette.menu.strongBlue,
    },
    '& $icon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: theme.palette.menu.strongBlue,
      '& $text': {
        color: theme.palette.menu.white,
      },
      '& $icon': {
        color: theme.palette.menu.white,
      },
    },
  },

  text: {
    color: theme.palette.text.strongBlue,
  },

  icon: {
    color: theme.palette.text.strongBlue,
  },
});

export default styles;