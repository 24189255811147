/* Libraries */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
/* Material UI */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
/* Components */
import FieldsComponent from './fieldsComponent';
import { LineContainer, ItemContainer } from '../styled';
/* CSS */
import styles from '../../forecast_forms-jss';
import fontStyles from 'styles/fonts-jss';
/* Context */
import { useModal } from 'Context/ModalContext';

const useStyles = makeStyles({
    divider: {
        background: '#848484',
        border: '1px solid',
    },
});

const ReportCard = (props) => {
    const fonts = fontStyles();
    const dividerStyle = useStyles();
    const {
        classes,
        alertsData,
        setAlertsData,
        loadingLeftSide,
        setReloadPostData,
        setSelected,
        setReloadSelected,
        setCheckboxSelected,
        checkboxSelected,
        setReloadSwitchSelected,
        allSelected,
        setAllSelected,
        setReloadErrorMessage,
        setStatusTargetSelected,
        statusTargetSelected,
        setReloadStatusTargetSelected,
        reloadTargets,
        setReloadTargets,
        setIntensityErrorReport,
        setConditionErrorReport,
        setAcumulatedErrorReport,
        setProb_lightningErrorReport,
        setWindErrorReport,
        setMaxTempErrorReport,
        setMinTempErrorReport,
        setLevelErrorReport,
        setWindVelErrorReport,
        setWindSprintErrorReport,
        intensityErrorReport,
        conditionErrorReport,
        acumulatedErrorReport,
        prob_lightningErrorReport,
        windErrorReport,
        maxTempErrorReport,
        minTempErrorReport,
        levelErrorReport,
        windVelErrorReport,
        windSprintErrorReport,
    } = props;
    const [targets, setTargets] = useState();
    const [reloadModal, setReloadModal] = useState(false);
    const [targetReportList, setTargetReportList] = useState();
    const [reloadData, setReloadData] = useState(false);
    const [reloadCheckbox, setReloadCheckbox] = useState(false);
    const [caractersRemaning, setCaractersRemaning] = useState();
    const { plans: planos, toShow } = useModal();
    const [allSelectedAux, setAllSelectedAux] = useState(false);
    const ref = useRef(null);

    var gridClasses = [
        classes.gridColumnsIndexRain,
        classes.gridMarginLeftRain,
    ];

    const [preset, setPreset] = useState({
        preset: false,
        acumulado: '',
        intensidade: '',
        condicao_tempo: '',
        probabilidade: '',
        nivel: '',
        rajada_vento: '',
        direcao_vento: '',
        velocidade_vento: '',
        temperatura_min: '',
        temperatura_max: '',
        observacao: null,
    });

    useEffect(() => {
        setReloadModal(false);
    }, [reloadModal]);

    useEffect(() => {
        if (
            preset.observacao &&
            preset.observacao.length > 1 &&
            preset.observacao.length < 50
        ) {
            setCaractersRemaning(50 - preset.observacao.length);
        } else {
            setCaractersRemaning('');
        }
    }, [preset]);

    return (
        <div className={classes.formsContainer} ref={ref}>
            <LineContainer
                containerWidth={
                    ref.current
                        ? ref.current.getBoundingClientRect().width
                        : 700
                }
            >
                <ItemContainer
                    containerWidth={
                        ref.current
                            ? ref.current.getBoundingClientRect().width * 0.3
                            : 200
                    }
                >
                    <Autocomplete
                        id="reportCard"
                        freeSolo={false}
                        value={preset?.intensidade}
                        inputValue={preset?.intensidade}
                        disableClearable
                        onChange={(_, newValue) =>
                            setPreset({ ...preset, intensidade: newValue })
                        }
                        style={fonts.formsField}
                        size="small"
                        options={[
                            'Boletim 12 horas',
                            'Boletim 24 horas',
                            'Boletim 48 horas',
                            'Boletim 72 horas',
                        ]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Horizonte"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                </ItemContainer>
            </LineContainer>

            <LineContainer
                containerWidth={
                    ref.current
                        ? ref.current.getBoundingClientRect().width
                        : 700
                }
            >
                <ItemContainer
                    containerWidth={
                        ref.current
                            ? ref.current.getBoundingClientRect().width
                            : 700
                    }
                >
                    <Autocomplete
                        id="intensity"
                        freeSolo={false}
                        value={preset?.intensidade}
                        inputValue={preset?.intensidade}
                        disableClearable
                        onChange={(_, newValue) =>
                            setPreset({ ...preset, intensidade: newValue })
                        }
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={[
                            'Fraca',
                            'Fraca a moderada',
                            'Moderada',
                            'Moderada a forte',
                            'Forte',
                            'Muito forte',
                            'Extrema',
                        ]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Intensidade da chuva"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                </ItemContainer>
            </LineContainer>

            <LineContainer
                containerWidth={
                    ref.current
                        ? ref.current.getBoundingClientRect().width
                        : 700
                }
            >
                <ItemContainer
                    containerWidth={
                        ref.current
                            ? ref.current.getBoundingClientRect().width * 0.18
                            : 200
                    }
                >
                    <InputMask
                        mask="999 - 999 mm"
                        value={preset?.acumulado}
                        onChange={(e) =>
                            setPreset({ ...preset, acumulado: e.target.value })
                        }
                        disabled={false}
                        maskChar=" "
                    >
                        {() => (
                            <TextField
                                id="acumuladoInit"
                                className={classes.textFieldAcum}
                                variant="outlined"
                                label="Acumulado"
                                inputProps={{ style: fonts.formsFieldHeight1 }}
                                InputLabelProps={{
                                    style: fonts.formsLabel,
                                    shrink: true,
                                }}
                            />
                        )}
                    </InputMask>
                </ItemContainer>

                <ItemContainer
                    containerWidth={
                        ref.current
                            ? ref.current.getBoundingClientRect().width * 0.18
                            : 200
                    }
                >
                    <Autocomplete
                        id="lightning_prob"
                        freeSolo={false}
                        value={preset?.probabilidade}
                        inputValue={preset?.probabilidade}
                        disableClearable
                        onChange={(_, newValue) =>
                            setPreset({ ...preset, probabilidade: newValue })
                        }
                        className={classes.selectFieldLightProb}
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={['Baixa', 'Média', 'Alta']}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Prob. Raios"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                </ItemContainer>
                <ItemContainer
                    containerWidth={
                        ref.current
                            ? ref.current.getBoundingClientRect().width * 0.18
                            : 200
                    }
                >
                    <Autocomplete
                        id="wind_sprint"
                        freeSolo={false}
                        value={preset?.rajada_vento}
                        inputValue={preset?.rajada_vento}
                        disableClearable
                        onChange={(_, newValue) =>
                            setPreset({ ...preset, rajada_vento: newValue })
                        }
                        className={classes.selectFieldWindS}
                        style={fonts.formsFieldHeight1}
                        size="small"
                        options={[
                            'Brisa Fraca, rajadas entre 12.01 e 20.00 km/h',
                        ]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Rajada de vento"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                </ItemContainer>
            </LineContainer>

            <div className={classes.selectContainer}></div>

            <div className={classes.selectContainer}>
                <Autocomplete
                    id="level"
                    freeSolo={false}
                    value={preset?.nivel}
                    inputValue={preset?.nivel}
                    disableClearable
                    onChange={(_, newValue) =>
                        setPreset({ ...preset, nivel: newValue })
                    }
                    className={classes.selectFieldLevel}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={['T1', 'T2', 'T3', 'T4']}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nível"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                }
                            }}
                        />
                    )}
                />
            </div>

            <InputMask
                mask="99 km/h"
                value={preset?.velocidade_vento}
                onChange={(e) =>
                    setPreset({ ...preset, velocidade_vento: e.target.value })
                }
                disabled={false}
                maskChar=" "
            >
                {() => (
                    <TextField
                        id="velocidade_vento"
                        className={classes.textFieldWindVel}
                        variant="outlined"
                        label="Vel. do vento"
                        inputProps={{ style: fonts.formsFieldHeight1 }}
                        InputLabelProps={{
                            style: fonts.formsLabel,
                            shrink: true,
                        }}
                    />
                )}
            </InputMask>

            <div className={classes.selectContainer}>
                <Autocomplete
                    id="wind"
                    freeSolo={false}
                    value={preset?.direcao_vento}
                    inputValue={preset?.direcao_vento}
                    disableClearable
                    onChange={(_, newValue) =>
                        setPreset({ ...preset, direcao_vento: newValue })
                    }
                    className={classes.textFieldWind}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={[
                        'Sul',
                        'Leste',
                        'Norte',
                        'Oeste',
                        'Sudeste',
                        'Sudoeste',
                        'Nordeste',
                        'Noroeste',
                    ]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Vento"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                }
                            }}
                        />
                    )}
                />
            </div>

            <TextField
                id="temp_min"
                className={classes.textFieldMinTemp}
                value={preset?.temperatura_min}
                onChange={(e) =>
                    setPreset({ ...preset, temperatura_min: e.target.value })
                }
                variant="outlined"
                type="tel"
                label="Temp. Mínima"
                inputProps={{ style: fonts.formsFieldHeight1 }}
                InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{ alignSelf: 'end' }}
                        >
                            °C
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                id="temperatura_max"
                className={classes.textFieldMaxTemp}
                variant="outlined"
                value={preset?.temperatura_max}
                onChange={(e) =>
                    setPreset({ ...preset, temperatura_max: e.target.value })
                }
                label="Temp. Máxima"
                type="tel"
                inputProps={{ style: fonts.formsFieldHeight1 }}
                InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{ alignSelf: 'end' }}
                        >
                            °C
                        </InputAdornment>
                    ),
                }}
            />

            <TextField
                id="comments"
                label="Observações"
                multiline
                rows={2}
                value={preset?.observacao}
                onChange={(e) =>
                    setPreset({ ...preset, observacao: e.target.value })
                }
                className={classes.textFieldComm}
                shrink={true}
                variant="outlined"
                color="primary"
                inputProps={{ style: fonts.formsFieldHeight2, maxLength: 50 }}
                InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
            />

            <Button
                style={fonts.buttonDefault}
                variant="contained"
                className={classes.reportButton}
                onClick={() =>
                    setPreset({ ...preset, preset: !preset?.preset })
                }
            >
                Alterar todos
            </Button>

            <Divider classes={{ root: dividerStyle.divider }} />

            {planos?.map((company) =>
                company.planos.map((plan) => {
                    return plan.alvos.map((target) => {
                        if (!toShow.get(company.id, plan.id, target.id))
                            return null;
                        return (
                            <div key={target.id}>
                                <FieldsComponent
                                    target={target}
                                    preset={preset}
                                    alertsData={alertsData}
                                    setAlertsData={setAlertsData}
                                    planoId={plan.id_plano}
                                    empresaId={company.id_empresa_id}
                                    setReloadModal={setReloadModal}
                                    setReloadSwitchSelected={
                                        setReloadSwitchSelected
                                    }
                                    allSelected={allSelected}
                                    setAllSelected={setAllSelected}
                                    allSelectedAux={allSelectedAux}
                                    setAllSelectedAux={setAllSelectedAux}
                                    setReloadErrorMessage={
                                        setReloadErrorMessage
                                    }
                                    setStatusTargetSelected={
                                        setStatusTargetSelected
                                    }
                                    statusTargetSelected={statusTargetSelected}
                                    setReloadStatusTargetSelected={
                                        setReloadStatusTargetSelected
                                    }
                                    reloadTargets={reloadTargets}
                                    setReloadTargets={setReloadTargets}
                                    setIntensityErrorReport={
                                        setIntensityErrorReport
                                    }
                                    setConditionErrorReport={
                                        setConditionErrorReport
                                    }
                                    setAcumulatedErrorReport={
                                        setAcumulatedErrorReport
                                    }
                                    setProb_lightningErrorReport={
                                        setProb_lightningErrorReport
                                    }
                                    setWindErrorReport={setWindErrorReport}
                                    setMaxTempErrorReport={
                                        setMaxTempErrorReport
                                    }
                                    setMinTempErrorReport={
                                        setMinTempErrorReport
                                    }
                                    setLevelErrorReport={setLevelErrorReport}
                                    setWindVelErrorReport={
                                        setWindVelErrorReport
                                    }
                                    setWindSprintErrorReport={
                                        setWindSprintErrorReport
                                    }
                                    intensityErrorReport={intensityErrorReport}
                                    conditionErrorReport={conditionErrorReport}
                                    acumulatedErrorReport={
                                        acumulatedErrorReport
                                    }
                                    prob_lightningErrorReport={
                                        prob_lightningErrorReport
                                    }
                                    windErrorReport={windErrorReport}
                                    maxTempErrorReport={maxTempErrorReport}
                                    minTempErrorReport={minTempErrorReport}
                                    levelErrorReport={levelErrorReport}
                                    windVelErrorReport={windVelErrorReport}
                                    windSprintErrorReport={
                                        windSprintErrorReport
                                    }
                                />
                            </div>
                        );
                    });
                }),
            )}
        </div>
    );
};
ReportCard.propTypes = {
    classes: PropTypes.object.isRequired,
    alertsData: PropTypes.any,
    setAlertsData: PropTypes.func.isRequired,
    setReloadPostData: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    setReloadSelected: PropTypes.func.isRequired,
    setCheckboxSelected: PropTypes.func.isRequired,
    checkboxSelected: PropTypes.object.isRequired,
    setReloadSwitchSelected: PropTypes.func.isRequired,
    allSelected: PropTypes.any,
    setAllSelected: PropTypes.func.isRequired,
    statusTargetSelected: PropTypes.any.isRequired,
    setStatusTargetSelected: PropTypes.func.isRequired,
    setReloadStatusTargetSelected: PropTypes.func.isRequired,
    loadingLeftSide: PropTypes.any.isRequired,
    reloadTargets: PropTypes.any.isRequired,
    setReloadTargets: PropTypes.func.isRequired,
    setIntensityErrorReport: PropTypes.any.isRequired,
    setReloadErrorMessage: PropTypes.any.isRequired,
    setConditionErrorReport: PropTypes.any.isRequired,
    setAcumulatedErrorReport: PropTypes.any.isRequired,
    setProb_lightningErrorReport: PropTypes.any.isRequired,
    setWindErrorReport: PropTypes.any.isRequired,
    setMaxTempErrorReport: PropTypes.any.isRequired,
    setMinTempErrorReport: PropTypes.any.isRequired,
    setLevelErrorReport: PropTypes.any.isRequired,
    setWindVelErrorReport: PropTypes.any.isRequired,
    setWindSprintErrorReport: PropTypes.any.isRequired,
    intensityErrorReport: PropTypes.any.isRequired,
    conditionErrorReport: PropTypes.any.isRequired,
    acumulatedErrorReport: PropTypes.any.isRequired,
    prob_lightningErrorReport: PropTypes.any.isRequired,
    windErrorReport: PropTypes.any.isRequired,
    maxTempErrorReport: PropTypes.any.isRequired,
    minTempErrorReport: PropTypes.any.isRequired,
    levelErrorReport: PropTypes.any.isRequired,
    windVelErrorReport: PropTypes.any.isRequired,
    windSprintErrorReport: PropTypes.any.isRequired,
};

export default withStyles(styles)(ReportCard);
