import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getSatelliteImages } from '../services/mapsPortal/satellite';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';
import { union } from '@turf/turf';
import useToggle from 'hooks/useToggle';

export const CameraContext = createContext();

export const CameraContextProvider = (props) => {
    const [showCamera, toggleCamera] = useToggle(false);
    const [reloadCamera, toggleReloadCamera] = useToggle(false);
    
    const toggleReloadCameraPanel = () => {
        toggleReloadCamera(!reloadCamera);
    }

    return (
        <CameraContext.Provider
            value={{
                showCamera,
                toggleCamera,
                reloadCamera,
                toggleReloadCameraPanel,
            }}
        >
            {props.children}
        </CameraContext.Provider>
    );
};

CameraContextProvider.propTypes = {
    children: PropTypes.node,
};

export default CameraContextProvider;

export const useCamera = () => {
  const context = useContext(CameraContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
