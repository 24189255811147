
/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* CSS */
import styles from "./tableContent-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";



const DaysOfWeek = (props) => {
  const { classes, content} = props;

  return(
    <>
      {
        content.node.map((mon, index) => [(
          <div key={index}>
            {mon.datafunc}
          </div>
        )]) 
      }
    </>
  );

}
DaysOfWeek.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.any.isRequired,
};

export default withStyles(styles)(DaysOfWeek);