/* Libraries */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'
import { FaNewspaper, FaCloudSun } from 'react-icons/fa'
import {FaSlidersH} from "react-icons/fa"
import Icon from '@material-ui/core/Icon';
import { useModal } from 'Context/ModalContext';
import { useState, useRef, useEffect } from 'react';
import ExportExcelFile from 'components/Tables/ExportExcelFile';
import styles from './tablestyle-jss';
import fontStyles from 'styles/fonts-jss';
import { BiTrash } from 'react-icons/bi';
import Modal from '@material-ui/core/Modal';
import * as React from 'react';
import { useDispatch } from 'react-redux';
//MODAL
import TargetTab from '../../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import LightningAlert from '../../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../../services/mapsPortal/alerts"
import { useToast } from 'Context/ToastContext';
import ModalE from "components/Modal/ModalE";
import ModalResend from "components/Modal/ModalResend";
import ModalRetification from "components/Modal/ModalRetification";
import PrevisionRetification from "components/Modal/PrevisionRetification";
import ModalDeleteTicket from "components/Modal/ModalDeleteTicket";
import ModalApproveTicket from "components/Modal/ModalApproveTicket";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
/* Utils */
import persistRegister from '../../../utils/persistRegisterFlow';
import { persistViewPageNavigation } from '../../../utils/persistViewPageNavigation';
import { tabMenuProvider } from 'redux/actions/tabMenuAction';
import { modalProvider } from 'redux/actions/modalAction';
import { sidebarMenuProvider } from 'redux/actions/sidebarMenuAction';
import { getTargetByID, getTargetInfosByTargetID } from 'services/adminDashboard/target';
import { BiCheck } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import { reportCardContextProvider } from 'redux/actions/reportCardContext';
import {useTarget} from 'Context/TargetContext';
import ForecastPanel from '../../../pages/MapsPortal/components/ForecastPanel';


const TableHeader = (props) => {
    const {
        classes,
        search,
        buttonsWarnings,
        buttonsPrevision,
        csvButton,
        createButton,
        createButtonName,
        dateTimePicker,
        setDateSearch,
        reloadHistory,
        previsionInfos,
        previsionButtons,
        historySelectButtons,
        historyClientSelectButtons,
        reportCard,
        noOperation,
        ticketButtons,
        searchValue,
        setSearchValue,
        setFilterButton,
        dataSet,
        type,
        data,
        checkboxSelected,
        checkboxSelectedRowIndex,
        errataInfos,
        resendExtraInfos,
        retificationInfos,
        ticketInfos,
        checkboxSelectedNames,
        csvButtonName,
        otherCsvButton,
        otherCsvButtonName,
        otherDataSet,
        selectedTargets,
    } = props;
    const { openPredictionModal, open, modalType, tab } = useModal();
    const { planos } = useTarget();

  const useStyles = makeStyles({
    lightningAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#B067E9",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#B067E9'
      }
    },
    rainAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#046DCA",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#046DCA'
      }
    },
    meteorologicalAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    },
    releases: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#27B624",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#27B624'
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
  })

    const [loadingLeftSide, setLoadingLeftSide] = useState([false]);
    const [reloadLeftSide, setReloadLeftSide] = useState(false);
    const [loadingRightSide, setLoadingRightSide] = useState([false]);
    const [reloadRightSide, setReloadRightSide] = useState(false);
    const [rightSideTabs, setRightSideTabs] = useState("");
    const [reloadPostData, setReloadPostData] = useState(false);
    const [reloadSelected, setReloadSelected] = useState(false);
    const [reloadSelectAll, setReloadSelectAll] = useState(false);
    const [reloadTargets, setReloadTargets] = useState(false);
    const [confirmTargetsList, setConfirmTargetsList] = useState();
    const [selected, setSelected] = useState("");
    const [reloadErrorMessage, setReloadErrorMessage] = useState(false);
    const [reloadLoading, setReloadLoading] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [lightningAlertErrors, setLightningAlertErrors] = useState([]);
    const [releaseAlertErrors, setReleaseAlertErrors] = useState([]);
    const [rainAlertErrors, setRainAlertErrors] = useState([]);
    const [servicesLightningAlert, setServicesLightningAlert] = useState();
    const [alertsData, setAlertsData] = useState([]);
    const [reloadStatusTargetSelected, setReloadStatusTargetSelected] = useState(false);
    const [reloadSwitchSelected, setReloadSwitchSelected] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [hiddenDates, setHiddenDates] = useState(false);
    const [statusTargetSelected, setStatusTargetSelected] = useState([]);
    const tabStyle = useStyles();
    const theme = useTheme();
    const openToast = useToast();
    const [buttonAnchorEl, setButtonAnchorEl] = useState(null);

    const openButton = Boolean(buttonAnchorEl);

    const handleChangeIndexRightTabs = (index) => {
        setRightSideTabs(index);
        setReloadTargets(true);
      };

    const toggleAllSelected = () => {
    setAllSelected(!allSelected);
    }
    
    const handleClick = (event) => {
      setButtonAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setButtonAnchorEl(null);
    };

    const postAlert = async () => {
        if (rightSideTabs === 0) {
          let aux = false;
          if(lightningAlertErrors && lightningAlertErrors.length > 0){
            let errorIndex = lightningAlertErrors.map((item, index) => item.level != null || item.risk != null || item.contact != null || item.duration != null || item.tool != null?  index : -1)
            for(let i = 0; i < errorIndex.length; i++){
              if (errorIndex[i] != -1) {
                aux = true;
                openToast("error", lightningAlertErrors[errorIndex[i]].level != null? lightningAlertErrors[errorIndex[i]].level : lightningAlertErrors[errorIndex[i]].risk != null? lightningAlertErrors[errorIndex[i]].risk : lightningAlertErrors[errorIndex[i]].tool != null? lightningAlertErrors[errorIndex[i]].tool : lightningAlertErrors[errorIndex[i]].contact != null? lightningAlertErrors[errorIndex[i]].contact : null);
              }
            }
          }
          if(!aux){
            if (alertsData && alertsData.length === 0) {
              openToast("warning", "Nenhum alvo selecionado!");
            }
            else {
              if ((statusTargetSelected && statusTargetSelected.length > 0)) {
                setOpenConfirmationModal(true);
              }
              else {
                openToast("warning", "Enviando Alerta Raio...!");
                let targetNames = [];
                for (let i = 0; i < alertsData.length; i++) {
                  let targetName = alertsData[i].nome;
                  if (alertsData[i]) {
                    if (alertsData[i].nivel != "") {
                      delete alertsData[i].nome;
                      await postLightningAlert(alertsData[i]).then((response) => {
                      }).catch((error) => { targetNames.push(targetName) })
                    }
                  }
                }
                if (!targetNames.length) {
                  openToast("success", "Alerta Raio enviado com sucesso!");
                  setReloadTargets(true);
                }
                else {
                  openToast("error", `Problema ao enviar Alerta Raio no(s) alvo(s) ${targetNames}`);
                  setReloadTargets(true);
                }
              }
            }
          }
        }
        else if (rightSideTabs === 1) {
          let aux = false;
          if(releaseAlertErrors && releaseAlertErrors.length > 0){
            let errorIndex = releaseAlertErrors.map((item, index) => item.level != null || item.risk != null || item.contact != null?  index : -1)
    
            for(let i = 0; i < errorIndex.length; i++){
              if (errorIndex[i] != -1) {
                aux = true;
                openToast("error", releaseAlertErrors[errorIndex[i]].level != null? releaseAlertErrors[errorIndex[i]].level : releaseAlertErrors[errorIndex[i]].risk != null? releaseAlertErrors[errorIndex[i]].risk : releaseAlertErrors[errorIndex[i]].contact != null? releaseAlertErrors[errorIndex[i]].contact : null);
              }
            }
          }
          if(!aux){
            if (alertsData && alertsData.length === 0) {
              openToast("warning", "Nenhum alvo selecionado!");
            }
            else {
              openToast("warning", "Liberando alvo...!");
              let targetNames = [];
              for (let i = 0; i < alertsData.length; i++) {
                let targetName = alertsData[i].nome;
                alertsData[i].datahora_criacao = null;
    
                if (alertsData[i]) {
                  if (alertsData[i].nivel != "" || alertsData[i].nivel != null) {
                    delete alertsData[i].nome;
                    await postLightningRelease(alertsData[i]).then((response) => {
                    }).catch((error) => {
                      targetNames.push(targetName)
                    })
                  }
                }
              }
              if (!targetNames.length) {
                openToast("success", "Liberação realizada com sucesso");
                setReloadTargets(true);
              }
              else {
                openToast("error", `Problema ao liberar o(s) alvo(s) ${targetNames}`);
                setReloadTargets(true);
              }
            }
          }
        }
        else if (rightSideTabs === 2) {
          let aux = false;
          if(rainAlertErrors && rainAlertErrors.length > 0){
            let errorIndex = rainAlertErrors.map((item, index) => item.acumulated != null || item.intensity != null || item.contact != null || item.duration != null? index : -1)
    
            for(let i = 0; i < errorIndex.length; i++){
              if (errorIndex[i] != -1) {
                aux = true;
                openToast("error", rainAlertErrors[errorIndex[i]].acumulated != null? rainAlertErrors[errorIndex[i]].acumulated : rainAlertErrors[errorIndex[i]].intensity != null? rainAlertErrors[errorIndex[i]].intensity : rainAlertErrors[errorIndex[i]].contact != null? rainAlertErrors[errorIndex[i]].contact : rainAlertErrors[errorIndex[i]].duration != null? rainAlertErrors[errorIndex[i]].duration : null);
              }
            }
          }
          if(!aux){
            if (alertsData && alertsData.length === 0) {
              openToast("warning", "Nenhum alvo selecionado!");
            }
            else {
              if ((statusTargetSelected && statusTargetSelected.length > 0)) {
                setOpenConfirmationModal(true);
              }
              else {
                openToast("warning", "Enviando Aviso de chuva...!");
                let targetNames = [];
                for (let i = 0; i < alertsData.length; i++) {
                  let targetName = alertsData[i].nome;
                  if (alertsData[i]) {
                    if (alertsData[i].contato != "" || alertsData[i].contato != null) {
                      delete alertsData[i].nome;
                      await postRainAlert(alertsData[i]).then((response) => {
                      }).catch((error) => { targetNames.push(targetName) })
                    }
                  }
                }
                if (!targetNames.length) {
                  openToast("success", "Aviso de Chuva enviado com sucesso");
                  setReloadTargets(true);
                }
                else {
                  openToast("error", `Problema ao enviar Aviso de Chuva no(s) alvo(s) ${targetNames}`);
                  setReloadTargets(true);
                }
              }
            }
          }
        }
      }
    
    const fonts = fontStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const navigationContext = JSON.parse(sessionStorage.getItem('cLFfX97S5j'));
    const handleCreate = () => {
        if (type === 'Empresas') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            );
            dispatch(tabMenuProvider(0));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'Planos') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                navigationContext[0],
                navigationContext[1],
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            );
            dispatch(tabMenuProvider(1));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'Serviços') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                navigationContext[0],
                navigationContext[1],
                navigationContext[2],
                navigationContext[3],
                null,
                null,
                null,
                null,
                null,
                null,
            );
            dispatch(tabMenuProvider(3));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'Alvos') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                navigationContext[0],
                navigationContext[1],
                navigationContext[2],
                navigationContext[3],
                navigationContext[4],
                navigationContext[5],
                null,
                null,
                null,
                null,
            );
            dispatch(tabMenuProvider(2));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'Usuários') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                navigationContext[0],
                navigationContext[1],
                navigationContext[2],
                navigationContext[3],
                navigationContext[4],
                navigationContext[5],
                navigationContext[6],
                navigationContext[7],
                null,
                null,
            );
            dispatch(tabMenuProvider(4));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'TodosPlanos') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                navigationContext[0],
                navigationContext[1],
                navigationContext[2],
                navigationContext[3],
                null,
                null,
                null,
                null,
                null,
                null,
            );
            dispatch(tabMenuProvider(1));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'Contatos') {
            history.push('/administracao/registrar/todos');
            persistRegister([0], true);
            persistViewPageNavigation(
                navigationContext[0],
                navigationContext[1],
                navigationContext[2],
                navigationContext[3],
                navigationContext[4],
                navigationContext[5],
                navigationContext[6],
                navigationContext[7],
                navigationContext[8],
                navigationContext[9],
            );
            dispatch(tabMenuProvider(5));
            dispatch(sidebarMenuProvider('registerAll'));
        } else if (type === 'Noticias') {
            history.push('/site/noticias/registrar');
            /*persistRegister([0], true);
            persistViewPageNavigation(navigationContext[0], navigationContext[1], navigationContext[2], navigationContext[3], navigationContext[4], navigationContext[5], navigationContext[6], navigationContext[7], navigationContext[8], navigationContext[9]);
            dispatch(tabMenuProvider(5));
            dispatch(sidebarMenuProvider("registerAll"));*/
        } else if (type === 'Previsoes') {
            history.push('/site/previsoes/registrar');
            /*persistRegister([0], true);
            persistViewPageNavigation(navigationContext[0], navigationContext[1], navigationContext[2], navigationContext[3], navigationContext[4], navigationContext[5], navigationContext[6], navigationContext[7], navigationContext[8], navigationContext[9]);
            dispatch(tabMenuProvider(5));
            dispatch(sidebarMenuProvider("registerAll"));*/
        }
    };

    
    const [selectedDate2, setSelectedDate2] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date(selectedDate2 - 1000 * 60 * 60 * 24 * 7));

    const handleDate1Change = (date) => {
        setSelectedDate1(date);
    };

    const handleDate2Change = (date) => {
      setSelectedDate2(date);
    };

    const adjustDate = () => {
      let aux1 = ""
      let aux2 = ""
      aux1 = new Date(selectedDate1)
      aux1 = String(aux1.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }))

      aux2 = new Date(selectedDate2)
      aux2 = String(aux2.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }))
      let date1 = aux1.split(",")
      let date2 = aux2.split(",")
      var data = {
        data_inicio: date1[0].split("/")[2]+"-"+date1[0].split("/")[1]+"-"+date1[0].split("/")[0],
        data_final: date2[0].split("/")[2]+"-"+date2[0].split("/")[1]+"-"+date2[0].split("/")[0],
        hora_inicio: date1[1].replace(/ /g, ''),
        hora_final: date2[1].replace(/ /g, '')
      }
      if(type=="Monitoramento" || type=="Previsão"){
        setDateSearch(data);
      }
        
    };

    useEffect(() => {
      adjustDate()
    }, [selectedDate1, selectedDate2])

    useEffect(() => {
      adjustDate()
    }, [])


    const handleDelete = async () => {
        dispatch(
            modalProvider(
                true,
                checkboxSelected,
                checkboxSelectedNames,
                type,
                'Delete',
                null,
            ),
        );
    };

    const ref = useRef(null);
    const [reloadMod, setReloadMod] = useState(false);

    const [modalRetification, setModalRetification] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalData, setModalData] = useState([""]);

    const [reloadModResend, setReloadModResend] = useState(false);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [modalDataResend, setModalDataResend] = useState([""]);

    const [reloadModRetification, setReloadModRetification] = useState(false);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [modalDataRetification, setModalDataRetification] = useState([""]);

    const [reloadModDeleteTicket, setReloadModDeleteTicket] = useState(false);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [modalDataDeleteTicket, setModalDataDeleteTicket] = useState([""]);

    const [reloadModApproveTicket, setReloadModApproveTicket] = useState(false);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [modalDataApproveTicket, setModalDataApproveTicket] = useState([""]);

    const [reloadModPrevisionRetification, setReloadModPrevisionRetification] = useState(false);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [modalDataPrevisionRetification, setModalDataPrevisionRetification] = useState([""]);

    const handleErrata = async (event) => {
        setAnchorEl(event.currentTarget);
        setModalData({selected: checkboxSelected, errataInfos: errataInfos? errataInfos : "", reloadHistory:reloadHistory})
    };
 
    const handleErrataPrevision = async (event) => {
      setAnchorEl(event.currentTarget);
      setModalData({selected: checkboxSelected, values: previsionInfos? previsionInfos : "", reloadHistory:reloadHistory})
    };

    const handleReenvio = async (event) => {
      setAnchorEl2(event.currentTarget);
      setModalDataResend({resendInfos: errataInfos? errataInfos : ""})
    };

    const handleReenvioPrevision = async (event) => {
      setAnchorEl2(event.currentTarget);
      setModalDataResend({values: previsionInfos? previsionInfos : ""})
    };

    const handleRetification = async (event) => {
      setAnchorEl3(event.currentTarget);
      setModalDataRetification({values: retificationInfos})
    };

    const handleRetificationPrevision = async (event) => {
      setAnchorEl6(event.currentTarget);
      setModalDataPrevisionRetification({values: previsionInfos})
    };

    const handleDeleteTicket = async (event) => {
      setAnchorEl4(event.currentTarget);
      setModalDataDeleteTicket({values: ticketInfos})
    };

    const handleApproveTicket = async (event) => {
      setAnchorEl5(event.currentTarget);
      setModalDataApproveTicket({values: ticketInfos})
    };

    const setTargetsIds = () => {
      const filteredCompanies = planos.map((company) => {
        const filteredPlanos = company.planos.map((plan) => ({
          ...plan,
          alvos: plan.alvos.filter((target) =>
            selectedTargets.includes(target.id_alvo)
          ),
        }));

        return {
          ...company,
          planos: filteredPlanos.filter((plan) => plan.alvos.length > 0),
        };
      }).filter((company) => company.planos.length > 0)
      .map((company, indexCompany) => ({
        ...company,
        id: indexCompany,
        planos: company.planos.map((plan, indexPlan) => ({
          ...plan,
          id: indexPlan, 
        })),
      }));

      let reportCardHorizons = [...new Set(dataSet.filter((report) => 
        checkboxSelected?.includes(report.id_boletim)).map((report) => 
          report.horizontes == 3 && !report.especial? report.vigencia : report.horizontes))].sort(sortData);
      let tab = reportCardHorizons.filter((horizon) => [24, 48, 78].includes(horizon))?.length > 0? 0 : reportCardHorizons.includes(3)? 1 : reportCardHorizons.length > 0? 2 : 0;
      let status = dataSet.filter((report) => checkboxSelected?.includes(report.id_boletim)).map((report) => report.status)

      dispatch(reportCardContextProvider([checkboxSelected, reportCardHorizons, [status[0]]]));
      openPredictionModal(filteredCompanies, tab);
  }    

  function sortData( a, b ) {
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }

    const formatPlans = (companyData) => {
      let formatedCompanyData = [];
      let aux = [];
      
      companyData?.map(company => {
        if(aux.indexOf(company.id_empresa_id) === -1){
          aux.push(company.id_empresa_id);
          formatedCompanyData.push(company);
        }
        else{
          company.planos.map((plan1, index) => {
            if(formatedCompanyData[aux.indexOf(company.id_empresa_id)].planos.filter((plan2) => plan1.id_plano === plan2.id_plano)?.length === 0){
              formatedCompanyData[aux.indexOf(company.id_empresa_id)].planos.push(plan1);
            }
            else{
              plan1.alvos.map((target1) => {
                if(formatedCompanyData[aux.indexOf(company.id_empresa_id)].planos[index].alvos.filter((target2) => target1.id_alvo === target2.id_alvo)?.length === 0){
                  formatedCompanyData[aux.indexOf(company.id_empresa_id)].planos[index].alvos.push(target1);
                }
              })
            }
          })
        }
      })

      formatedCompanyData.sort((a, b) => a.nome.localeCompare(b.nome));
      formatedCompanyData.map((company) => company.planos.sort((a, b) => a.nome.localeCompare(b.nome)));
      formatedCompanyData.map((company) => company.planos.map((plan) => plan.alvos.sort((a, b) => a.nome.localeCompare(b.nome))));
      formatedCompanyData.map((company, index1) => company.planos.map((plan, index2) => plan.alvos.map((target, index3) => {return company.id = index1, plan.id = index2, target.id = index3})));
      
      return formatedCompanyData;
    }

    const allTimeDates = () => {
      if(!hiddenDates){
        setHiddenDates(true);
        setSelectedDate1(new Date(selectedDate2 - 1000 * 60 * 60 * 24 * 365 * 123));
      }else{
        setHiddenDates(false);
        setSelectedDate1(new Date(selectedDate2 - 1000 * 60 * 60 * 24 * 7));
        setSelectedDate2(new Date());
      }

    };

    const [activeBolt, setActiveBolt] = useState(true);
    const [activeLiberados, setActiveLiberados] = useState(true);
    const [activeCloudShower, setActiveCloudShower] = useState(true);
    const [activeCloudSun, setActiveCloudSun] = useState(true);
    const [activeNewspaper, setActiveNewspaper] = useState(true);
    
    const buttonEffects = (button) => {
        if(button=="Alerta Raios"){
            setActiveBolt(!activeBolt); 
            setFilterButton("Alerta Raios");
        }else if(button=="Alertas Liberados"){
            setActiveLiberados(!activeLiberados); 
            setFilterButton("Alertas Liberados");
        }else if(button=="Avisos Chuva"){
            setActiveCloudShower(!activeCloudShower); 
            setFilterButton("Avisos Chuva");
        }else if(button=="Avisos Meteorologicos"){
            setActiveCloudSun(!activeCloudSun); 
            setFilterButton("Avisos Meteorologicos");
        }else if(button=="Boletins"){
            setActiveNewspaper(!activeNewspaper);
            setFilterButton("Boletins");
        }

    }   

    useEffect(() => {
        if(reloadMod){
          setReloadMod(false);
          setAnchorEl(null);
        }
      }, [reloadMod])

    useEffect(() => {
        if(reloadModResend){
            setReloadModResend(false);
            setAnchorEl2(null);
        }
    }, [reloadModResend])



    if (csvButton ? dataSet && type: true) {
        return (
            <div
                className={
                    search
                        ? classes.tableHeaderContainerSearch
                        : classes.tableHeaderContainerNotSearch
                }
            >
                {anchorEl6 != null && <PrevisionRetification anchorEl={anchorEl6} setAnchorEl={setAnchorEl6} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadModPrevisionRetification} data={modalDataPrevisionRetification} putData={modalDataPrevisionRetification} idAlvo={6} />}
                {anchorEl5 != null && <ModalApproveTicket anchorEl={anchorEl5} setAnchorEl={setAnchorEl5} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadModApproveTicket} data={modalDataApproveTicket} putData={modalDataApproveTicket} idAlvo={5} />}
                {anchorEl4 != null && <ModalDeleteTicket anchorEl={anchorEl4} setAnchorEl={setAnchorEl4} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadModDeleteTicket} data={modalDataDeleteTicket} putData={modalDataDeleteTicket} idAlvo={4} />}
                {anchorEl3 != null && <ModalRetification anchorEl={anchorEl3} setAnchorEl={setAnchorEl3} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadModRetification} data={modalDataRetification} putData={modalDataRetification} idAlvo={3} />}
                {anchorEl2 != null && <ModalResend anchorEl={anchorEl2} setAnchorEl={setAnchorEl2} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadModResend} data={modalDataResend} putData={modalDataResend} idAlvo={2} />}
                {anchorEl != null && <ModalE anchorEl={anchorEl} setAnchorEl={setAnchorEl} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadMod} data={modalData} putData={modalData} idAlvo={1} />}
                {search && (
                    <div className={classes.leftSideHeadContainer}>
                        <Input
                            id="searchCompany"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={'Buscar'}
                            style={fonts.footerText}
                            className={classes.searchHead}
                            startAdornment={
                                <InputAdornment
                                    position="start"
                                    style={{
                                        marginLeft: '8px',
                                        marginRight: '5px',
                                    }}
                                >
                                    <Icon color={'#848484'}>search</Icon>
                                </InputAdornment>
                            }
                        />
                    </div>
                )}
                <div className={classes.rightSideHeadContainer}>
                    {(!checkboxSelected || checkboxSelected?.length === 0) &&
                        createButton && (
                            <div className={classes.createButtonContainer} onClick={() => handleCreate()}>
                                <span style={fonts.buttonText} className={classes.createButtonText}>
                                    {createButtonName}
                                </span>
                            </div>
                        )}

                    {(!checkboxSelected || checkboxSelected?.length === 0) &&
                        csvButton && (
                            <div className={classes.csvButtonContainer}>
                                <ExportExcelFile
                                    dataSet={dataSet}
                                    type={type}
                                    csvButtonName={csvButtonName}
                                />
                            </div>
                        )
                    }

                    {(!checkboxSelected || checkboxSelected?.length === 0) &&
                        dateTimePicker && !hiddenDates &&(
                            <div className={classes.dateTimePicker}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDateTimePicker
                                    label="Data/Hora inicial"
                                    value={selectedDate1}
                                    onChange={handleDate1Change}
                                    format="DD/MM/YYYY hh:mm A"
                                    mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        )}
                        {(!checkboxSelected || checkboxSelected?.length === 0) &&
                        dateTimePicker && !hiddenDates &&(
                            <div className={classes.dateTimePicker}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDateTimePicker
                                    label="Data/Hora final"
                                    value={selectedDate2}
                                    onChange={handleDate2Change}
                                    format="DD/MM/YYYY hh:mm A"
                                    mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        )}
                        {(!checkboxSelected || checkboxSelected?.length === 0) &&
                        dateTimePicker && buttonsWarnings && (
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                              <button title="Alerta Raios" onClick={() => buttonEffects("Alerta Raios")} className={activeBolt? classes.filterbuttons_bolt_a : classes.filterbuttons_bolt} style={{borderTopRightRadius:"0px", borderBottomRightRadius:"0px", backgroundColor:"transparent"}}>
                                <i><FaBolt style={{width: "18px", height: "18px", zIndex: "1" }} /></i>
                              </button>

                              <button title="Alertas Liberados" onClick={() => buttonEffects("Alertas Liberados")} className={activeLiberados? classes.filterbuttons_signout_a : classes.filterbuttons_signout} style={{borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", borderTopRightRadius:"0px", borderBottomRightRadius:"0px", backgroundColor:"transparent"}}>
                                <i><FaSignOutAlt style={{width: "18px", height: "18px"}} /></i>
                              </button>

                              <button title="Avisos Chuva" onClick={() => buttonEffects("Avisos Chuva")} className={activeCloudShower? classes.filterbuttons_cloudshowers_a : classes.filterbuttons_cloudshowers} style={{borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", backgroundColor:"transparent"}}>
                                <i><FaCloudShowersHeavy style={{width: "18px", height: "18px"}} /></i>
                              </button>
                          </div>
                        )}
                        {(!checkboxSelected || checkboxSelected?.length === 0) &&
                          dateTimePicker && buttonsPrevision && (
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                <button title="Avisos Meteorologicos" onClick={() => buttonEffects("Avisos Meteorologicos")} className={activeCloudSun? classes.filterbuttons_cloudsun_a : classes.filterbuttons_cloudsun} style={{borderTopRightRadius:"0px", borderBottomRightRadius:"0px", backgroundColor:"transparent"}}>
                                  <i><FaCloudSun style={{width: "18px", height: "18px", zIndex: "1"}} /></i>
                                </button>

                                <button title="Boletins" onClick={() => buttonEffects("Boletins")} className={activeNewspaper? classes.filterbuttons_newspaper_a : classes.filterbuttons_newspaper} style={{borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", backgroundColor:"transparent"}}>
                                  <i><FaNewspaper style={{width: "18px", height: "18px"}} /></i>
                                </button>
                            </div>
                          )}
                         {(!checkboxSelected || checkboxSelected?.length === 0)&& dateTimePicker &&(
                          <div style={{marginLeft:"20px"}}>
                            <Button
                              id="fade-button"
                              aria-controls={openButton ? 'fade-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openButton ? 'true' : undefined}
                              onClick={handleClick}
                            >
                              <h2 style={{display: "contents"}}><FaSlidersH/></h2>
                            </Button>
                            <Menu
                              id="fade-menu"
                              MenuListProps={{
                                'aria-labelledby': 'fade-button',
                              }}
                              anchorEl={buttonAnchorEl}
                              open={openButton}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                              <MenuItem onClick={allTimeDates} >{ hiddenDates? "Intervalo de Datas" :  "Desde o Início"}</MenuItem>
                            </Menu>
                          </div>
                        )}
                    {(!checkboxSelected || checkboxSelected?.length === 0) &&
                        otherCsvButton && (
                            <div className={classes.csvButtonContainer}>
                                <ExportExcelFile
                                    otherDataSet={otherDataSet}
                                    type={type}
                                    otherCsvButtonName={otherCsvButtonName}
                                />
                            </div>
                        )}
                    {checkboxSelected && checkboxSelected?.length > 0 && (
                        historySelectButtons? 
                            <div style={{display: "flex", flexDirection: "row",  alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px"}}>
                                <div onClick={(e) => handleErrata(e)} style={{marginLeft:"450px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                                  <span>ERRATAS</span>
                                </div>
                                <div onClick={(e) => handleRetification(e)} style={{marginLeft:"40px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                                  <span>RETIFICAÇÕES</span>
                                </div>
                                <div onClick={(e) => handleReenvio(e)} style={{marginLeft:"40px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                                  <span>REENVIO</span>
                                </div>
                            </div>
                        
                        : historyClientSelectButtons?
                          <div onClick={(e) => handleReenvio(e)} style={{marginLeft:"40px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                            <span>REENVIO</span>
                          </div>
                        :



                        previsionButtons? 
                        <div style={{display: "flex", flexDirection: "row",  alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px"}}>
                            <div onClick={(e) => handleErrataPrevision(e)} style={{marginLeft:"450px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                            <span>ERRATAS</span>
                            </div>
                            <div onClick={(e) => handleRetificationPrevision(e)} style={{marginLeft:"40px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                            <span>RETIFICAÇÕES</span>
                            </div>
                            <div onClick={(e) => handleReenvioPrevision(e)} style={{marginLeft:"40px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1}>
                            <span>REENVIO</span>
                            </div>
                        </div>
                    
                        : 

                        ticketButtons? 
                        <div style={{display: "flex", flexDirection: "row",  alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px"}}>
                            <div style={{marginLeft:"450px"}} className={classes.iconButton3}>
                              <span  style={{fontSize:"20px"}}><Edit_icon/></span>
                            </div>
                            <div onClick={(e) => handleDeleteTicket(e)} style={{marginLeft:"40px"}} className={classes.iconButton1}>
                              <span  style={{fontSize:"20px"}}><BiTrash/></span>
                            </div>
                            <div onClick={(e) => handleApproveTicket(e)} style={{marginLeft:"40px"}} className={classes.iconButton2}>
                            <span  style={{fontSize:"20px"}}><BiCheck/></span>
                            </div>
                        </div>
                        :

                        reportCard? 
                        <div style={{marginLeft:"450px"}} className={classes.iconButton4} onClick={(e) => setTargetsIds()}>
                          <span span style={{fontSize:"20px"}}><Edit_icon /></span>
                        </div>
                        
                        : 

                        noOperation?
                        <div className={classes.deleteContainer}>
                            <span style={{ color: '#023047' }}>{`${
                                checkboxSelected.length
                            } ${
                                checkboxSelected.length > 1
                                    ? 'selecionados'
                                    : 'selecionado'
                            }`}</span>
                        </div>
                        :
                        <div className={classes.deleteContainer}>
                            <span style={{ color: '#023047' }}>{`${
                                checkboxSelected.length
                            } ${
                                checkboxSelected.length > 1
                                    ? 'selecionados'
                                    : 'selecionado'
                            }`}</span>
                            <div
                                style={{
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                                onClick={() => handleDelete()}
                            >
                                <Edit_icon
                                    style={{
                                        color: '#023047',
                                        width: '17px',
                                        height: '17px',
                                    }}
                                />
                            </div>
                        </div>

                    )}
                </div>
                {modalType === 'prediction' && open && <ForecastPanel tab={tab} />}
            </div>
        );
    }
};

TableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    search: PropTypes.bool.isRequired,
    buttonsWarnings: PropTypes.bool.isRequired,
    buttonsPrevision: PropTypes.bool.isRequired,
    csvButton: PropTypes.bool.isRequired,
    reloadHistory: PropTypes.func,
    errataInfos: PropTypes.array,
    resendExtraInfos: PropTypes.array,
    retificationInfos: PropTypes.array,
    previsionInfos: PropTypes.array,
    previsionButtons: PropTypes.array,
    ticketInfos: PropTypes.array,
    historySelectButtons: PropTypes.bool.isRequired,
    historyClientSelectButtons: PropTypes.bool.isRequired,
    reportCard:  PropTypes.bool.isRequired,
    noOperation:  PropTypes.bool,
    ticketButtons: PropTypes.bool.isRequired,
    dateTimePicker: PropTypes.bool.isRequired,
    createButton: PropTypes.bool.isRequired,
    dataSet: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
    searchValue: PropTypes.any.isRequired,
    setSearchValue: PropTypes.func.isRequired,
    setFilterButton: PropTypes.func.isRequired,
    createButtonName: PropTypes.string.isRequired,
    checkboxSelected: PropTypes.array,
    setOpenModal: PropTypes.func,
    setDateSearch: PropTypes.func,
    checkboxSelectedNames: PropTypes.array,
    csvButtonName: PropTypes.string,
    otherCsvButton: PropTypes.bool,
    otherCsvButtonName: PropTypes.string,
    otherDataSet: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.any,
    checkboxSelectedRowIndex: PropTypes.array,
    selectedTargets: PropTypes.array,
};

export default withStyles(styles)(TableHeader);
