import styled from 'styled-components';
import { colors } from 'pages/MapsPortal/styled';

export const PopupSelectTab = styled.button`
  background-color: ${(props) => props.selected ? "white" : '#d4d3d3'}; 
  /* border: 1px solid black; */
  color: black;
  font-weight: ${(props) => props.selected ? '700' : '400'};
  /* color: ${(props) => props.selected ? 'white' : 'black'}; */
  border-radius: 12px 12px 0 0;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 10px;
  transition: 0.3s ease;
  overflow: hidden;
  &:hover{
    &::before{
    transform: translateY(${(props) => props.selected ? '60%' : '75%'});
    }
  }
  &::before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 12px;
    border-radius: 9999px;
    background-color: white;
    transition: 0.3s transform ease;
    transform: translateY(${(props) => props.selected ? '60%' : '100%'});
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: -6px;
  top: -6px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: white;
  padding: 0;
  background-color: #EE0000;
  transition: background-color 0.2 ease;
  &:hover{
    background-color: #A00;
  }
`

export const PopupTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 12px 12px 0 0; 
`

export const PopupItem = styled.div`
  
`

export const PopupItemIndvHeader = styled.div`
  width: 100%;
  height: 20%;
  background-color: #000000;
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 25px 0 25px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  > * {
    &:first-child {
      justify-self: flex-start;
    }
  }
  & h2:nth-child(2){
    justify-self: center;
  }
`

export const PopupItemMultHeader = styled.div`
  width: 100%;
  height: 20%;
  background-color: #000000;
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  > * {
    &:first-child {
      justify-self: flex-start;
    }
  }
  & h2:nth-child(2){
    justify-self: center;
  }
`

export const PopupItemHeaderColumnsLarge = styled.div`
  width: 23.333%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopupItemHeaderColumnsSmall = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
` 

export const PopupItemContent = styled.div`
  width: 100%;
  height: 80%;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #808080;
        border: 1px solid #808080;
      }
    }
`

export const PopupItemContentDischarges = styled.div`
  width: 100%;
  height: 80%;
  background-color: white;
  display: flex;
  flex-direction: row;
  border-radius: 0 0 12px 12px;
  padding: 12px 24px 12px 24px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #808080;
        border: 1px solid #808080;
      }
    }
`
export const PopupItemContentColumnsLarge = styled.div`
  padding 12px 0 0 0;
  width: 23.333%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid;
`

export const PopupItemContentColumnsSmall = styled.div`
  width: 15%;
  padding 12px 0 0 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid;
`

export const PopupItemFooter = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  bottom: 1%;
  align-items: center;
  padding: 0 10% 0 60px;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.4);
`

export const PopupItemIndvContentColumn = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px 20px 30px;
  @media (min-width: 0px) and (max-width: 768px){
    padding: 20px 0px 20px 5px;
  };
  @media (min-width: 769px) and (max-width: 1024px){
    padding: 20px 15px 20px 15px;
  };
`

export const SendButton = styled.div`
  background-color: #000;
  text-align: center;
  border-radius: 5px;
  margin-top: 8px;
  padding: 6px 10px 6px 10px;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover{
    background-color: #dedede;
  }
`