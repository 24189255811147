/* eslint-disable react/no-unknown-property */
import React from 'react'
import PropTypes from 'prop-types';
const ArrowLeft = (props) => {
  const width = props.width ? props.width : 36;
  const height = props.height ? props.height : 36;
  const color = props.color ? props.color : 'black';
  const fill = props.fill ? props.fill : '#EBEBEB'
  const margin = props.margin ? props.margin : 'none'
  return (
    <svg width={width} height={height} padding={margin} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g mask="url(#mask0_472_2955)">
            <path d="M21.375 42.75L7.125 28.5L21.375 14.25L24.7 17.575L16.15 26.125H49.875V30.875H16.15L24.7 39.425L21.375 42.75Z" fill={color}/>
        </g>
    </svg>
    

  )
}

ArrowLeft.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  fill: PropTypes.string,
  margin: PropTypes.string,
};

export default ArrowLeft
