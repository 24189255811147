import P from 'prop-types';

import { useAuth } from 'Context/AuthContext';
import {Redirect, Route} from 'react-router-dom';


const PrivateRoute = ({enable, component: Component, ...rest}) => {
  const Auth = useAuth();
  return (
    enable.includes(Auth.userGroup) ? (
      <Route {...rest} component={Component} />
    ) : (
      <Route
        {...rest}
        render={() =>
          <Redirect to={{pathname: Auth.userGroup !== -1
            ? '/portais'
            : '/' }}
          />
        }
      />
    )
  )
}

PrivateRoute.propTypes = {
  enable: P.arrayOf(P.number).isRequired,
  ...Route.propTypes
}

export default PrivateRoute
