import React, { useState, useEffect, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt-BR';
import 'moment/locale/pt-br';
import {
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import P from 'prop-types';
import { getWrfByDate } from 'services/mapsPortal/wrf';
import { useToast } from 'Context/ToastContext';
import fontStyles from 'styles/fonts-jss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styles from './register-jss';
import Loading from 'components/Loading';
import AutoplaySlider from './Slider/AutoplaySlider';
import Popover from '@material-ui/core/Popover';
import {MdClose} from 'react-icons/md';

const useStyles = makeStyles (theme => ({
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

const Wrf = (props) => {
    const { classes } = props;
    const fonts = fontStyles();
    const moment = require('moment');
    moment.locale('pt-br');
    const openToast = useToast();
    const zoomStyle =  useStyles();
    const [allData, setAllData] = useState();
    const [types, setTypes] = useState([{nome: "Refletividade", indice: "refletividade"}, {nome: "Precipitação Hora", indice: "precipitacao_hora"}, {nome: "Água Precipitável", indice: "agua_precipitavel"}, {nome: "Cisalhamento", indice: "cisalhamento"}, {nome: "Índice Total", indice: "indice_total"}, {nome: "Precipitação Acumulada", indice: "precipitacao_acumulada"}, {nome: "Orvalho", indice: "orvalho"}, {nome: "Índice Kelvin", indice: "indice_kelvin",}, {nome: "Umidade", indice: "umidade"}, {nome: "Temperatura Ar", indice: "temperatura_ar"}, {nome: "Jato", indice: "jato"}]);
    const [allDates, setAllDates] = useState();
    const [data, setData] = useState();
    const [selectedDate, setSelectedDate] = useState(moment());
    const [previousDays, setPreviousDays] = useState(false);
    const [imgIndex, setImgIndex] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [currentDate, setCurrentDate] = useState();
    const [openZoom, setOpenZoom] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [keyboardKey, setKeyboardKey] = useState([null, null]);
    const currentHour = moment().hours();
    var aux = 0;
    const ref = useRef(null);

    //Verificando o horario de hoje
    if (!currentDate) {
        if (currentHour < 20) {
            setCurrentDate(moment().subtract(1, 'day').format('YYYYMMDD'));
        } else {
            setCurrentDate(moment().format('YYYYMMDD'));
        }
    }

    //Pegando os dados da API
    const loadData = async (date) => {
        try {
            const response = await getWrfByDate(date);
            if (response) {
                setAllData({...allData, agua_precipitavel: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "agua_precipitada"),
                                        cisalhamento: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "cisalhamento"),
                                        indice_kelvin: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "indice_kelvin"),
                                        indice_total: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "indice_total"),
                                        jato: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "jato"),
                                        orvalho: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "orvalho"),
                                        precipitacao_acumulada: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "precipitacao_acumulada"),
                                        precipitacao_hora: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "precipitacao_hora"),
                                        refletividade: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "refletividade"),
                                        temperatura_ar: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "temperatura_ar"),
                                        umidade: response[0]?.wrf_imagens.wrf_imagens.filter((item) => item.tipo === "umidade")
                });     
            }
        } catch (error) {
            openToast(
                'error',
                'Problema ao carregar conteúdo, recarregue a página e tente novamente!',
            );
            setLoadingData(false);
        }
    };

    //Chamando a função loadData
    useEffect(() => {
        if (currentDate !== undefined) {
            loadData(currentDate);
        }
    }, [currentDate]);

    //Passando os dados da Api para os states
    useEffect(() => {
        if (allData) {
            setLoadingData(true);
        }
    }, [allData]);

    //Formatando os dados do Select Validade
    useEffect(() => {
        if(allData){
            const date = moment(allData.agua_precipitavel[0]?.data_imagem, 'YYYY-MM-DD');
            const arrayDatas = [];

            for (let i = 6; i <= 78; i++) {
                const data =
                    moment(date)
                        .add(i * 1, 'hours')
                        .format('DD/MM/YYYY - HH:mm') + ' horas';
                arrayDatas.push(data);

            }
            setAllDates(arrayDatas);
        }
    }, [allData]);


    //Pegando os dados dos selects e passando o caminho do gif
    useEffect(() => {
        if (allDates && allDates.length > 0 && types && types.length > 0 && allDates && allDates.length > 0) {
            const initialTipo = types.length > 0 ? "agua_precipitavel" : '';
            const initialValidade = allDates.length > 0 ? allDates[0] : '';

            setData({
                tipo: initialTipo,
                validade: initialValidade,
            });

            setLoadingData(false);
        }
    }, [allDates, types, allDates]);

    //Pegando o tipo(variavel) selecionado e pegando o gif da variavel
    const handleTypeChange = (e) => {
        setData((prevState) => ({
            ...prevState,
            tipo: e.target.value,
        }));
    };

    //Pegando a data selecionada
    const handleDateChange = (e) => {

        setData((prevState) => ({
            ...prevState,
            validade: e.target.value,
        }));
    };

    //Mudar as variáveis e a validade com o teclado
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                setKeyboardKey([event.key, aux = aux + 1]);
                const currentIndex = allDates.findIndex(
                    (item) => item === data.validade,
                );

                let newIndex =
                    event.key === 'ArrowRight'
                        ? currentIndex + 1
                        : currentIndex - 1;

                if (newIndex < 0) {
                    newIndex = allDates.length - 1;
                } else if (newIndex >= allDates.length) {
                    newIndex = 0;
                }

                const newValidity = allDates[newIndex];
                setData((prevState) => ({
                    ...prevState,
                    validade: newValidity,
                }));
            }
            else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                const currentIndex = types.findIndex(
                    (item) => item.indice === data.tipo,
                );
                
                let newIndex =
                    event.key === 'ArrowDown'
                        ? currentIndex + 1
                        : currentIndex - 1;

                if (newIndex < 0) {
                    newIndex = types.length - 1;
                } else if (newIndex >= types.length) {
                    newIndex = 0;
                }

                const newType = types[newIndex].indice;
                setData((prevState) => ({
                    ...prevState,
                    tipo: newType,
                    validade: allDates[0]
                }));
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    }, [allDates, data]);


    //Função quando altero a data default da seleção"
    const handleHistoricalData = (date) => {
        setLoadingData(true);

        const data1 = new Date(date);
        let month1 = '' + (data1.getMonth() + 1);
        month1 = month1.padStart(2, '0');
        let day1 = '' + data1.getDate();
        day1 = day1.padStart(2, '0');
        let year1 = '' + data1.getFullYear();
        const formattedDate = year1 + month1 + day1;

        setSelectedDate(date);

        if(moment(formattedDate, "YYYYMMDD").isAfter(moment(currentDate).add(1, 'day').format('YYYYMMDD'))){
            openToast('error', 'Não é possível recuperar os dados para um dia posterior ao dia atual!');
            setLoadingData(false);
        }
        else if(moment(formattedDate, "YYYYMMDD").isSame(moment(currentDate).add(1, 'day').format('YYYYMMDD'))){
            loadData(moment(formattedDate).subtract(1, 'day').format('YYYYMMDD'));
            setPreviousDays(false);
        }
        else{
            loadData(moment(formattedDate).format('YYYYMMDD'));
            moment(formattedDate).format('YYYYMMDD') === currentDate? 
            setPreviousDays(false) : setPreviousDays(true);
        }   
    };

    function sortData(a, b) {
        if (a?.indice < b?.indice) {
            return -1;
        }
        if (a?.indice > b?.indice) {
            return 1;
        }
        return 0;
    }

    window.addEventListener("keydown", function(e) {
        if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
        }
    }, false);

    if (loadingData || !data?.tipo) {
        return <Loading />;
    } else {
        return (
                <div className={classes.registerContainer}>
                    {anchorEl != null && 
                        <Popover
                            open={openZoom}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorReference={"none"}
                            classes={{
                              root: zoomStyle.popoverRoot,
                             }}
                            > 
                   
                            <div className={classes.zoomContainer} id="sliderContainer">
                                <button onClick={() => setOpenZoom(false)} style={{backgroundColor: "white", cursor: "pointer"}}> <MdClose style={{width: "24px", height: "24px"}}/></button>
                                <img src={allData[data.tipo][imgIndex].caminho} alt={allData[data.tipo][imgIndex].data_imagem} className={classes.zoomImg}/>
                            </div>
                       </Popover>

                    }
                    <Typography
                        variant="subtitle1"
                        color="primary"
                        style={fonts.formsSubTitle}
                    >
                        Selecionar Dados
                    </Typography>
                    <div className={classes.registerContentContainer}>
                        <div className={classes.selectDataContainer}>
                            <TextField
                                select
                                value={data?.tipo}
                                onChange={handleTypeChange}
                                name="variable"
                                style={{ ...fonts.formsField, width: '250px', marginTop: "15px"}}
                                label="Variáveis"
                                variant="outlined"
                            >
                                {types.sort(sortData).map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.indice}
                                        style={fonts.formsField}
                                    >
                                        {item.nome}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                select
                                value={data.validade}
                                onChange={handleDateChange}
                                name="validity"
                                style={{ ...fonts.formsField, width: '250px', marginTop: "15px" }}
                                label="Validade"
                                variant="outlined"
                            >
                                {allDates.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item}
                                        style={fonts.formsField}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                            
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={deLocale}
                            >
                                <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    value={selectedDate}
                                    style={{ marginTop: '0px' }}
                                    onChange={(date) => handleHistoricalData(date)}
                                    animateYearScrolling={false}
                                    label="Data"
                                    className={classes.dateField}
                                    inputProps={{
                                        style: fonts.formsField,
                                    }}
                                    InputLabelProps={{
                                        style: fonts.formsLabel,
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={classes.sliderContainer}> 
                            <AutoplaySlider data={allData[data.tipo]} previousDays={previousDays} allDates={allDates} date={data.validade} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setOpenZoom={setOpenZoom} setImgIndex={setImgIndex} keyboardKey={keyboardKey} />
                        </div>
                    </div>
                </div>

        );
    }
};

Wrf.propTypes = {
    classes: P.object.isRequired,
};

export default withStyles(styles)(Wrf);
