import {useCallback} from 'react';
import { useState } from 'react';

export default function useToggle(defaultValue = false) {
  const [value, setValue] = useState(defaultValue)

  const toggle = useCallback((newValue) => {
    setValue(prev =>
      typeof newValue === 'boolean' ? newValue : !prev
    )
  }, [])

  return [value, toggle]
}
