import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { getTargetAlertState } from 'services/mapsPortal/alerts';
import useInterval from 'hooks/useInterval';
import {useToast} from './ToastContext';
import {useHistory} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { setApiHeaders } from 'services/api';
import * as Auth from 'services/login/login';

export const AuthContext = createContext();

const userTypes = {
  'administrador': 0,
  'meteorologista': 1,
  'usuario': 2
}

const KEYS = {
  email: 'tqWT3aWFQe',
  emailEncrypt: 'm41lNucleo-s3cr37',
  password: '7qWT34wFQp',
  passwordEncrypt: 'p4ssNucleo-s3cr37',
  token: 'reeWAL57qe',
  stayConnected: 'YYb4ue7pmM',
  sessionGroup: 'UPKNFbYEHJ',
  user: 'CagAWG24AE',
  userEncrypt: 'Us3rsEcr37-a4Bl'
}


export const AuthContextProvider = (props) => {
    const history = useHistory();
    const [ user, setUser ] = useState(() => {
      const userEncrypted = localStorage.getItem(KEYS.user);
      try{
        if (userEncrypted){
          const decryptedUser = CryptoJS.AES
          .decrypt(userEncrypted, KEYS.userEncrypt)
          .toString(CryptoJS.enc.Utf8)
        return JSON.parse(decryptedUser)
      }
      const sessionEncrypted = sessionStorage.getItem(KEYS.user);
      if (sessionEncrypted){
        const decryptedUser = CryptoJS.AES
          .decrypt(sessionEncrypted, KEYS.userEncrypt)
          .toString(CryptoJS.enc.Utf8)
          return JSON.parse(decryptedUser)
        }
        return undefined
      }catch(e){ return undefined}
    });

    const [loading, setLoading] = useState(JSON.parse(localStorage.getItem('YYb4ue7pmM')));
    const [token, setToken] = useState(() => {
      const token = JSON.parse(localStorage.getItem('reeWAL57qe'))
      if (token){
        setApiHeaders(token)
        return token
      }
      return undefined;
    })
    const [stayConnected, setStayConnected] = useState(JSON.parse(localStorage.getItem('YYb4ue7pmM')))
    const tokenHash = () => token.replace('Token ', '');

    useEffect(() => {
      setApiHeaders(token)
    }, [token])

    useEffect(() => {
      if (stayConnected){
        testAuth()
      }
    }, [])

    useEffect(() => {
      sessionStorage.setItem(
        KEYS.user,
        CryptoJS.AES.encrypt(
          JSON.stringify(user),
          KEYS.userEncrypt
        )
      )
      if(stayConnected){
        localStorage.setItem(
          KEYS.user,
          CryptoJS.AES.encrypt(
            JSON.stringify(user),
            KEYS.userEncrypt
          )
        )
      }else{
        localStorage.removeItem(KEYS.user)
      }
    }, [stayConnected, user])

    const userGroup = user?.grupo[0] ? userTypes[user?.grupo[0]] : -1;

    const login = async (credentials, stay = undefined) => {
      const response = await Auth.login(credentials);
      const token = 'Token ' + response.data.key;
      setApiHeaders(token)
      const newUser = await Auth.getUserAuthenticated();

      sessionStorage.setItem(KEYS.sessionGroup, JSON.stringify(userTypes[newUser.data.grupo[0]]))
      localStorage.setItem(KEYS.token, JSON.stringify(token));
      localStorage.setItem(KEYS.email, CryptoJS.AES.encrypt(credentials.email, KEYS.emailEncrypt))
      localStorage.setItem(KEYS.password, CryptoJS.AES.encrypt(credentials.password, KEYS.passwordEncrypt))

      setUser(newUser.data)
      setToken(token)
      if (stay !== undefined) {
        localStorage.setItem(KEYS.stayConnected, JSON.stringify(stay));
        setStayConnected(stay) // Falso não deve atualizar, apenas no logout;
      }
      return response
    }

    const logout = async () => {
      sessionStorage.clear()
      localStorage.setItem(KEYS.stayConnected, JSON.stringify(false));
      localStorage.removeItem(KEYS.token)
      localStorage.removeItem(KEYS.email)
      localStorage.removeItem(KEYS.password)
      try{
        await Auth.logout();
      }catch{
        return
      }
      setUser(undefined);
      setToken(undefined);
      setStayConnected(false);
      history.push('/');
      return
    }

    const refresh = async () => {
      const response = await Auth.refreshToken(tokenHash())
      const newToken = 'Token ' + response.data.Key
      localStorage.setItem(KEYS.token, JSON.stringify( newToken));
      setApiHeaders(newToken)
      setToken(newToken);
    }

    const getUserInfo = async () => {
      const user = await Auth.getUserAuthenticated();
      setUser(user.data);
      return user.data;
    }

    const testAuth = async () => {
      try{
        // await refresh()
        await getUserInfo();
      }catch(e){
        try{
          const user = {}
          user.email = CryptoJS.AES.decrypt(
              localStorage.getItem(KEYS.email),
              KEYS.emailEncrypt
            ).toString(CryptoJS.enc.Utf8)

          user.password = CryptoJS.AES.decrypt(
            localStorage.getItem(KEYS.password),
              KEYS.passwordEncrypt
            ).toString(CryptoJS.enc.Utf8)
          await login(user)
        }catch(error){
          await logout();
        }
      }
      setLoading(false);
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                userGroup,
                stayConnected,
                loading,
                login,
                logout,
                refresh,
                getUserInfo,
                testAuth
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

AuthContextProvider.propTypes = {
    children: PropTypes.node,
};

export default AuthContextProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if(typeof context === 'undefined'){
    throw new Error('useAuth need to be used inside a <AuthContextProvider />')
  }

  return context;
}
