import { useState, createContext, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useSelect from 'hooks/Monitoring/useSelect';
import {useMapContext} from './MapContext';

export const ToolsContext = createContext();

export const ToolsContextProvider = (props) => {
    const { mapRef } = useMapContext();
    const [toolInUse, setToolInUse] = useState('default');
    const [measureLine, setMeasureLine] = useState([]);
    const [pointHelper, setPointHelper] = useState([]);
    const [dischargesHistory, setDischargesHistory] = useState(false);

    const polygon = useSelect(mapRef, toolInUse, setToolInUse)

    useEffect(() => {
      setPointHelper([]);
    }, [toolInUse])

    return (
        <ToolsContext.Provider
            value={{
                mapRef,
                toolInUse,
                setToolInUse,
                measureLine,
                setMeasureLine,
                pointHelper,
                setPointHelper,
                polygon,
                dischargesHistory,
                setDischargesHistory
                // showPoligono,
                // setShowPoligono,
                // polygonLine,
                // setPolygonLine,
                // finishPolygon,
                // setFinishPolygon
            }}
        >
            {props.children}
        </ToolsContext.Provider>
    );
};

ToolsContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ToolsContextProvider;

export const useTools = () => {
  const context = useContext(ToolsContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
