export default function (state = null, action){
    switch(action.type){
        case "ACCESS_GROUP_PROVIDER":
            return action.payload
            
    default: return state
    }
}




