import {useEffect} from 'react';
import {useState} from 'react';
import {getEmployeesInfosByEmployeeID} from 'services/adminDashboard/employees';
import * as turf from '@turf/turf';
import { useAuth } from 'Context/AuthContext';

const useBound = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const [maxBoundsState, setMaxBoundsState] = useState(null);
    const [centerCoordinate, setCenterCoordinate] = useState(null);
    const [dischargeBounds, setDischargeBounds] = useState([]);
    let maxX = 0;
    let maxY = 0;
    let minX = 0;
    let minY = 0;
    let center = null;

    const loadUserInfos = async () => {
        await getEmployeesInfosByEmployeeID(user.id_usuario_empresa).then((response) => {
            setUserData(response);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(user.id_usuario_empresa){
            loadUserInfos();
        }
    }, [user]);

    useEffect(() => {
        if(!loading){
            userData.alvos.map(alvo => {
                  const bbox = turf.bbox(alvo.coordenadas);
                  if (maxX == 0 && maxY == 0 && minX == 0 && minY == 0) {
                    maxX = bbox[2];
                    maxY = bbox[3];
                    minX = bbox[0];
                    minY = bbox[1];
                  } else if (maxX != 0 && maxY != 0 && minX != 0 && minY != 0) {
                    maxX = bbox[2] > maxX ? bbox[2] : maxX;
                    maxY = bbox[3] > maxY ? bbox[3] : maxY;
                    minX = bbox[0] < minX ? bbox[0] : minX;
                    minY = bbox[1] < minY ? bbox[1] : minY;
                  }
            });
            var features = turf.points([
              [minX, minY],
              [maxX, maxY],
              [maxX, minY],
            ]);
            
            center = turf.center(features)
            setMaxBoundsState([center.geometry.coordinates[0] + 5, center.geometry.coordinates[1] + 3, center.geometry.coordinates[0] - 5, center.geometry.coordinates[1] - 3]);
            setCenterCoordinate([center.geometry.coordinates[0], center.geometry.coordinates[1]]) 
        }
    }, [loading]);

    /*useEffect(() => {
      setDischargeBounds([
        [maxBoundsState[2], maxBoundsState[3]],
        [maxBoundsState[0], maxBoundsState[3]],
        [maxBoundsState[0], maxBoundsState[1]],
        [maxBoundsState[2], maxBoundsState[1]],
        [maxBoundsState[2], maxBoundsState[3]],
      ]);
    }, [maxBoundsState]);*/

    return [maxBoundsState, centerCoordinate];
};  

export default useBound;