import { useState, useEffect, useRef, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    getRadarMax,
    getRadar3,
    getRadar5,
    getRadar7,
    getRadar10
} from '../services/mapsPortal/radar';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';
import useToggle from 'hooks/useToggle';

export const RadarContext = createContext();

export const RadarContextProvider = (props) => {
  const [radarInUse, setRadarInUse] = useState(0);
  const [time, setTime] = useState(0);
  const [opacidadeControl, setOpacidadeControl] = useState(0.5);
    const getRadar = useCallback(() => ([]), [])
    const{ data: radar, loading: loadingRadar, update: updateRadar, set: setRadar} = useLoad(getRadar, []);
    // useInterval(updateDescargas, 30000);
    const [showRadar, toggleRadar] = useToggle(false);
    const [radarRequestTime, setRadarRequestTime] = useState('Não atualizado nessa sessão');
    const moment = require('moment');
    moment.locale('pt-br');

    useEffect(()=> {
      switch (radarInUse){
        case 0:
          setRadar(getRadarMax);
          break;
        case 3:
          setRadar(getRadar3);
          break;
        case 5:
          setRadar(getRadar5);
          break;
        case 7:
          setRadar(getRadar7);
          break;
        case 10:
          setRadar(getRadar10);
          break;
        default:
          return
      }
    }, [radarInUse])

    useEffect(() => {
      if(showRadar){
        setRadarRequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setRadarRequestTime(null);
      }
  }, [showRadar, updateRadar])

    return (
        <RadarContext.Provider
            value={{
                radar,
                loadingRadar,
                updateRadar,
                setRadar,
                showRadar,
                toggleRadar,
                radarInUse,
                setRadarInUse,
                time,
                setTime,
                opacidadeControl,
                setOpacidadeControl,
                radarRequestTime
            }}
        >
            {props.children}
        </RadarContext.Provider>
    );
};

RadarContextProvider.propTypes = {
    children: PropTypes.node,
};

export default RadarContextProvider;

export const useRadar = () => {
  const context = useContext(RadarContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
