import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useToast } from 'Context/ToastContext';
import { useSelector } from 'react-redux';
import { useTarget } from 'Context/TargetContext';

const useLoad = (request, initState = undefined) => {
    const [data, setData] = useState(initState);
    const [loading, setLoading] = useState(true);
    const savedFunction = useRef(request);
    const openToast = useToast();

    useEffect(() => {
        savedFunction.current = request;
    }, [request]);

    const fetch = async () => {
        setLoading(true);
        try {
            const response = await savedFunction.current();
            setData(response);
        } catch (error) {
            setData((p) => (p !== undefined ? p : undefined));
            openToast('error', error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    const update = () => {
        fetch();
    };

    const set = (func) => {
        savedFunction.current = func;
        fetch();
    };
    return { data, loading, update, set };
};

useLoad.propTypes = {
    request: PropTypes.func.isRequired,
};
export default useLoad;