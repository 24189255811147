import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getSatelliteImages } from '../services/mapsPortal/satellite';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';
import { union } from '@turf/turf';
import useToggle from 'hooks/useToggle';

export const SatelliteContext = createContext();

export const SatelliteContextProvider = (props) => {
    const getImages = useCallback(()=> (getSatelliteImages()), [])
    const { data: satelliteInfo, loading: loadingSatellite, update: updateSatellite } = useLoad(getImages, {});
    const [showSatellite, toggleSatellite] = useToggle(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [opacidadeControl, setOpacidadeControl] = useState(0.5);
    const [satelliteRequestTime, setSatelliteRequestTime] = useState('Não atualizado nessa sessão');
    const moment = require('moment');
    moment.locale('pt-br');

    useEffect(() => {
        if(showSatellite){
            setSatelliteRequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
        }
        else{
            setSatelliteRequestTime(null);
          }
    }, [showSatellite, updateSatellite])


    return (
        <SatelliteContext.Provider
            value={{
                satelliteInfo,
                loadingSatellite,
                updateSatellite,
                showSatellite,
                toggleSatellite,
                currentImage,
                setCurrentImage,
                opacidadeControl,
                setOpacidadeControl,
                satelliteRequestTime
            }}
        >
            {props.children}
        </SatelliteContext.Provider>
    );
};

SatelliteContextProvider.propTypes = {
    children: PropTypes.node,
};

export default SatelliteContextProvider;

export const useSatellite = () => {
  const context = useContext(SatelliteContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
