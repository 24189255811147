import {colors} from 'pages/MapsPortal/styled';
import styled from 'styled-components';

import {withStyles} from '@material-ui/core/styles';
import {Tabs, Tab, Switch} from '@material-ui/core';

export const StyledTabs = withStyles((theme) => ({
  root: {
    borderRadius: '0',
    boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.4)',
    backgroundColor: colors.dark,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.dark,
    height: '8px',
    '& > span': {
      width: '100%',
      transformOrigin: 'center',
      transform: 'translateY(50%)',
      backgroundColor: colors.white,
      borderRadius: '9999px',
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    borderRadius: 'none',
    width: '120px',
    padding: '0',
    fontWeight: '400',
    backgroundColor: colors.dark,
    fontFamily: '"Roboto","sans-serif"',
    color: colors.white,
    '& > span': {
      width: '120ox',
    },
    '&:hover': {
      color: colors.white,
      opacity: 1,
    },
    '&$selected': {
      color: colors.white,
      fontWeight: '700',
    },
    '&:focus': {
      color: colors.white,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


export const Grid = styled.div`
  margin: auto;
  margin: 40px 0;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  grid-gap: 32px;
`

export const Key = styled.span`
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  padding: 4px;
  min-width: 35px;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  align-items: center;
  justify-items: center;
`
