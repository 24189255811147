import {colors} from 'pages/MapsPortal/styled';
import styled, {keyframes} from 'styled-components';
import {Row} from 'components/Flex';

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5;}
  100%{ opacity: 1;}
`

export const TopMenuStyled = styled.div`
    position: fixed;
    top: 0px;
    right: 20px;
    padding: 13px;
    border-radius: 0 0 13px 13px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.4);
    color: black;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    transition: transform 0.3s cubic-bezier(.22,0,.2,1);
    &.closed{
      transform: translateY(-100%);
    }
    &.notifOpen{
      transform: translateX(-280px);
    }
    &.closed.notifOpen{
      transform: translateX(-280px) translateY(-100%);
    }

`;

export const OpenTopMenu = styled.button`
  position: absolute;
  top: 100%;
  right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 75px;
  background: #FFFFFF;
  box-shadow: 0px 6px 5px 3px rgba(0, 0, 0, 0.4);
  border-radius: 0 0 13px 13px;
  transition: 0.3s ease;
  z-index: 1;
  &:hover{
    /* transform: translateY(-50%) scaleX(1.1); */
    background-color: #AAA;
  }
`

export const Button = styled.button`
    min-width: 40px;
    height: 40px;
    background-color: ${props => props.selected ? colors.button.selected : 'transparent'};
    padding: 0;
    cursor: pointer;
    color: black;
    transition: background-color 0.4s ease;
    &:hover{
      background-color: ${colors.button.selected};
    }
    animation: ${blinkAnimation} 0.8s linear ${(props) => (
      typeof props.loading === 'undefined'
        ? '0'
        : props.loading ? 'infinite' : '1')};
    animation-play-state: running;

    &:disabled{
      background-color: transparent;

      &:hover{
        background-color: transparent;
      }
    }
`;

export const ControlPanel = styled.div`
  position: absolute;
  top: 100%;
  right: 90%;
  font-size: 12px;
  width: 300px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  background-color: ${colors.white};
  border-radius: 13px;
  font-family: 'Roboto';


  /* & > div > div {
    border-bottom: 1px solid ${colors.dark};
    padding-bottom: 12px;
  } */
`

export const InfosPanelDiv = styled.div`
  position: absolute;
  top: 100%;
  right: 1%;
  margin-top: 50px;
  width: 240px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 13px;
  font-family: 'Roboto';

  /* & > div > div {
    border-bottom: 1px solid ${colors.dark};
    padding-bottom: 12px;
  } */
`

export const ControlHeader = styled(Row)`
  background-color: rgba(29,32,38, 0.7);
  border-radius: 13px 13px 0 0;
  padding: 2px 0;
  width: 100%;
  span{
    font-family: 'Roboto';
    color: ${colors.white};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
  }
`

export const StyledRow = styled(Row)`
  gap: 3px;
`

export const CloseButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  transition: 0.2s ease;
    svg {
      font-size: 20px;
      path{
        transition: fill 0.2s ease;
        fill: white;
      }
    }

    &:hover{
      svg {
        path{
          fill: red;
        }
      }
    }
`

const loopAnimation = keyframes`
  0% { transform: rotateZ(0deg); }
  100% { transform: rotateZ(360deg);}
`

export const ReloadButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: scale 0.2s ease;
  padding: 4px;
  animation: ${loopAnimation} 0.6s linear ${(props) => (
      typeof props.loading === 'undefined'
        ? '0'
        : props.loading ? 'infinite' : '0')};
  animation-play-state: running;
  &:hover{
    transform: scale(1.1);
  }
`

export const PlayButton = styled.button`
  position: relative;
  margin: auto;
  background-color: transparent;
  width: 16px;
  height: 16px;
  border: none;
  margin: 4px;
  padding: 0;

  &:hover{
    &::before{
      border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid ${(props) => (props.isPlaying ? "red" : 'blue')};
    }
    &::after{
      border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid ${(props) => (props.isPlaying ? "red" : 'blue')};
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    transition: 0.3s ease;
    background-color: transparent;
    top: 0;
  }

  &::before{
    left: 0;
    border-top: ${(props) => (props.isPlaying ? "0" : "4px")} solid transparent;
    border-bottom: ${(props) => (props.isPlaying ? "0" : "4px")} solid transparent;
    border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid black;
  }


  &::after{
    right: 0;
    border-top: ${(props) => (props.isPlaying ? "0" : "8px")} solid transparent;
    border-bottom: ${(props) => (props.isPlaying ? "0" : "8px")} solid transparent;
    border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid black;
    transform: ${(props) => (props.isPlaying ? "scaleX(0.5, 1)" : 'scale(1, 0.5)')};
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.dark};
  padding: 0;
  margin: 0;
`
