import styled from 'styled-components';

export const PlayButton = styled.button`
  position: relative;
  margin: auto;
  background-color: #dedede;
  width: 15px;
  height: 15px;
  border: none;
  margin: 4px;
  padding: 0;

  &:hover{
    &::before{
      border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid ${(props) => (props.isPlaying ? "red" : 'blue')};
    }
    &::after{
      border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid ${(props) => (props.isPlaying ? "red" : 'blue')};
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    transition: 0.3s ease;
    background-color: transparent;
    top: 0;
  }

  &::before{
    left: 0;
    border-top: ${(props) => (props.isPlaying ? "0" : "4px")} solid transparent;
    border-bottom: ${(props) => (props.isPlaying ? "0" : "4px")} solid transparent;
    border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid black;
  }


  &::after{
    right: 0;
    border-top: ${(props) => (props.isPlaying ? "0" : "8px")} solid transparent;
    border-bottom: ${(props) => (props.isPlaying ? "0" : "8px")} solid transparent;
    border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid black;
    transform: ${(props) => (props.isPlaying ? "scaleX(0.5, 1)" : 'scale(1, 0.5)')};
  }
`

