const styles = theme => ({

/* General */
disabledOutlineButton: {
  cursor: "not-allowed",
  border: '2px solid',
  borderColor: theme.palette.menu.strongBlue,
  borderRadius: '5px',
  padding: '6px 14px 6px 14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '48px',
  '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
  },
  '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
  },
},

defaultButtonText: {
  color: theme.palette.text.strongBlue,
  textTransform: 'uppercase',
},

errorMessage: {
    height: "12px"
},

outlineButtonText: {
    color: theme.palette.text.strongBlue,
    textTransform: "uppercase",
},

outlineButtonIcon: {
    color: theme.palette.text.strongBlue,
},

outlineButton: {
  cursor: "pointer",
  border: "2px solid",
  borderColor: theme.palette.menu.strongBlue,
  borderRadius: "5px",
  padding: "6px 14px 6px 14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "48px",
  '& $outlineButtonText': {
    color: theme.palette.menu.strongBlue,
  },
  '& $outlineButtonIcon': {
    color: theme.palette.menu.strongBlue,
  },
  '&:hover': {
    background: theme.palette.menu.strongBlue,
    '& $outlineButtonText': {
      color: theme.palette.menu.white,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.white,
    },
  },
},
});

export default styles;