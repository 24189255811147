const darkPalette = {
  greyTheme: {
    palette: {
      primary: {
        light: '#ECEFF1',
        main: '#90A4AE',
        dark: '#455A64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#18328F',
        main: '#757575',
        dark: '#424242',
        contrastText: '#fff',
      },
    },
  },
  blueCyanTheme: {
    palette: {
      menu: {
        light: '#18328F',
        main: '#18328F',
        dark: '#12266E',
        darker: '#0C1A4A',
        contrastText: '#fff',
      },
      primary: {
        // light: '#18328F',
        light: '#12266E',
        // light: '#1B75BF',
        main: '#18328F',
        // main: '#2196F3',
        dark: '#6CB7F4',
        darker: '#0C1A4A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#E1F5FE',
        main: '#039BE5',
        dark: '#01579B',
        contrastText: '#fff',
      },
      background: {
        selected: '#555',
        light: '#333',
        main: '#262626',
        dark: '#000'
      }
    },
  }
};

export default darkPalette;
