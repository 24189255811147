const styles = theme => ({

  text: {
    color: theme.palette.text.strongBlue,
  },
  icon: {
    color: theme.palette.text.strongBlue,
  },
  uploadButton:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    padding: "6px 14px 6px 14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    '& $text': {
      color: theme.palette.menu.strongBlue,
    },
    '& $icon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: theme.palette.menu.strongBlue,
      '& $text': {
        color: theme.palette.menu.white,
      },
      '& $icon': {
        color: theme.palette.menu.white,
      },
    },
  },

});

export default styles;