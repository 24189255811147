// Libs
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// Components
import {Close} from '@material-ui/icons';
import {Column} from 'components/Flex';
import {CloseButton, ControlHeader, InfosPanelDiv, StyledRow} from './styled';
import styles from "./camera-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import './ControlPanel.css';
// Hooks
import {useControlPanel} from 'Context/ControlPanelContext';
import { useToast } from 'Context/ToastContext';
import { postCameras, patchCamera, deleteCameras } from 'services/mapsPortal/cameras';
import {useSatellite} from 'Context/SatelliteContext.js';
import {useRadar} from 'Context/RadarContext';
import {useDischarges} from 'Context/DischargesContext';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    height: "46px",
    width: "100%"
  },
  smallTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    height: "46px",
    width: "80px"
  }
}));

const InfosPanel = (props) => {
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [linkError, setLinkError] = useState();
  const [coordinatesError, setCoordinatesError] = useState();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { showInfosPanel, toggleInfosPanel} = useControlPanel();
  const {satelliteRequestTime} = useSatellite();
  const {radarRequestTime} = useRadar();
  const {
    groups10RequestTime,
    groups20RequestTime,
    groups30RequestTime,
    groups40RequestTime,
    groups50RequestTime,
    groups60RequestTime,
    flashes10RequestTime,
    flashes20RequestTime,
    flashes30RequestTime,
    flashes40RequestTime,
    flashes50RequestTime,
    flashes60RequestTime
  } = useDischarges();
  
  return !showInfosPanel ? undefined : (
    <InfosPanelDiv>
      <ControlHeader>
        <div style={{width: "85%", justifyContent: "center", display: "flex"}}>
          <span>Requisições do mapa</span>
        </div>
        <CloseButton onClick={toggleInfosPanel}><Close /></CloseButton>
      </ControlHeader>
      <Column w='90%' gap='1px' margin='0 0 8px 0'>
        {satelliteRequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Imagens de satélite:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${satelliteRequestTime}`} 
            </span>
          </StyledRow>
        }

        {radarRequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Imagens de radar:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${radarRequestTime}`} 
            </span>
          </StyledRow>
        }

        {flashes10RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Flashes 10 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${flashes10RequestTime}`} 
            </span>
          </StyledRow>
        }

        {flashes20RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Flashes 20 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${flashes20RequestTime}`} 
            </span>
          </StyledRow>
        }

        {flashes30RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Flashes 30 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${flashes30RequestTime}`} 
            </span>
          </StyledRow>
        }

        {flashes40RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Flashes 40 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${flashes40RequestTime}`} 
            </span>
          </StyledRow>
        }

        {flashes50RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Flashes 50 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${flashes50RequestTime}`} 
            </span>
          </StyledRow>
        }

        {flashes60RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Flashes 60 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${flashes60RequestTime}`} 
            </span>
          </StyledRow>
        }

        {groups10RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Groups 10 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${groups10RequestTime}`} 
            </span>
          </StyledRow>
        }

        {groups20RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Groups 20 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${groups20RequestTime}`} 
            </span>
          </StyledRow>
        }

        {groups30RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Groups 30 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${groups30RequestTime}`} 
            </span>
          </StyledRow>
        }

        {groups40RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Groups 40 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${groups40RequestTime}`} 
            </span>
          </StyledRow>
        }

        {groups50RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Groups 50 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${groups50RequestTime}`} 
            </span>
          </StyledRow>
        }

        {groups60RequestTime &&
          <StyledRow w='100%' gap='16px' justify='flex-start'>
            <span style={fonts.popUpContentTopic}>
              {`Groups 60 minutos:`} 
            </span>
            <span style={fonts.popUpContentItem}>
              {` ${groups60RequestTime}`} 
            </span>
          </StyledRow>
        }


      </Column>
    </InfosPanelDiv>
  )
}

InfosPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(InfosPanel);
