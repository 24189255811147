import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { getTargetCustomAlertState } from 'services/mapsPortal/alerts';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';

export const TargetCustomAlertStateContext = createContext();

export const TargetCustomAlertStateContextProvider = (props) => {

    const { data: targetCustomAlertState, update } = useLoad(getTargetCustomAlertState, []);

    useInterval(update, 10000);

    return (
        <TargetCustomAlertStateContext.Provider
            value={{
                targetCustomAlertState
            }}
        >
            {props.children}
        </TargetCustomAlertStateContext.Provider>
    );
};

TargetCustomAlertStateContextProvider.propTypes = {
    children: PropTypes.node,
};

export default TargetCustomAlertStateContextProvider;

export const useTargetCustomAlertState = () => {
  const context = useContext(TargetCustomAlertStateContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
