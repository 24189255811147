const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({
/* Template */

    titleContainer: {
       marginBottom: '10px'
    },

    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        height: '45px',
     },

     levelField: {
        width: '10%',
        marginBottom: '27px',
     },

     riskField: {
        width: '20%',
        marginBottom: '27px',
     },

     contactField: {
        width: '55%',
        marginBottom: '27px',
     },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '20px',
    },

    releaseButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "11px 11px",
        gap: "10px",
        backgroundColor: "#27B624",
        borderRadius: "5px",
        color: "#FFFFFF",
    },

    commentsField: {
        width: "100%",
        marginBottom: '37px',
    },
    
    commentsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
     },

    errorMessage: {
        fontSize: `11px`,
        color: "red",
        margin: "0 0 0 5px",
        fontFamily: fontFamily
    },
     
});

export default styles;