import { javaApi } from "services/api";

export const getGroupDischarges10 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/groups/10');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getGroupDischarges20 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/groups/20');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getGroupDischarges30 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/groups/30');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getGroupDischarges40 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/groups/40');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getGroupDischarges50 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/groups/50');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getGroupDischarges60 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/groups/60');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};
export const getFlashesDischarges10 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/flashes/10');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getFlashesDischarges20 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/flashes/20');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getFlashesDischarges30 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/flashes/30');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getFlashesDischarges40 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/flashes/40');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getFlashesDischarges50 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/flashes/50');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};

export const getFlashesDischarges60 = async () => {
  try {
      const response = await javaApi.get('descargas/geojson/flashes/60');
      return { type: 'FeatureCollection', features: response.data};
  } catch (error) {
    throw new Error('Não foi possível obter as descargas.');
  }
};
