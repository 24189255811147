const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({

button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 20px",
    gap: "5px",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    border: "1px solid #023047",
    marginRight: "8%",
    color: "#023047"
},

buttonLightning: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 20px",
    gap: "5px",
    backgroundColor: "#B067E9",
    borderRadius: "5px",
    color: "white"
},

buttonRain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "5px",
    backgroundColor: "#046DCA",
    borderRadius: "5px",
    color: "#023047"
},

gridButton: {
    ['@media (min-width:768px)']: { 
        marginLeft: '9.3%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '13%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '33%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '32.3%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '34.5%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '35.5%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '32.8%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '34.6%',
    },
},

gridContainer: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.2%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '9.7%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '4.8%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '6.4%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '6.3%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '9%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '11%',
    },
},

iconContainer: {
    marginLeft: '8px',
    width: "22px",
    height: "22px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
},
});

export default styles;