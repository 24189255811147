export default function (state = 0, action){
    switch(action.type){
        case "MODAL_TABMENU_PROVIDER":
            return action.payload
            
    default: return state
    }
}




