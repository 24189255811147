import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useTogglePlans from 'hooks/useTogglePlans';
import { useDispatch } from 'react-redux';
import { reportCardContextProvider } from 'redux/actions/reportCardContext';

export const ModalContext = createContext();

export const ModalContextProvider = (props) => {

  const [open, toggleOpen] = useToggle();
  const [plans, setPlans ] = useState([]);
  const [modalType, setModalType] = useState('');
  const [tab, setTab] = useState(0);
  const dispatch =  useDispatch();

  const toShow = useTogglePlans(plans, false);

  const openMonitoringModal = (plans, tab = 0) => {
    setModalType('monitoring');
    setPlans(plans);
    toShow.newData(plans, true)
    setTab(tab)
    toggleOpen(true);
  }

  const openRetificationModal = (plans, tab = 0) => {
    setModalType('retification');
    setPlans(plans);
    toShow.newData(plans, true)
    setTab(tab)
    toggleOpen(true);
  }

  const openPredictionModal = (plans, tab = 0) => {
    setModalType('prediction');
    setPlans(plans);
    toShow.newData(plans, true)
    setTab(tab)
    toggleOpen(true);
  }

  const closeModal = () => {
    toggleOpen(false)
    setModalType('');
    dispatch(reportCardContextProvider(null));
  }

  return (
      <ModalContext.Provider
          value={{
              modalType,
              open,
              plans,
              tab,
              openMonitoringModal,
              openRetificationModal,
              openPredictionModal,
              closeModal,
              toShow
          }}
      >
          {props.children}
      </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ModalContextProvider;

export const useModal = () => {
  const context = useContext(ModalContext);

  if(typeof context === 'undefined'){
    throw new Error('useModal need to be used inside a <ModalContextProvider />')
  }

  return context;
}
