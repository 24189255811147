
/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* CSS */
import styles from "./tableContent-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material */
import { TbArrowsDownUp } from 'react-icons/tb';
import { CgArrowDown, CgArrowUp } from 'react-icons/cg';


const Arrows = (props) => {
  const { classes, column, sortBy } = props;
  const fonts = fontStyles();

  return(
    <>
      {column != "Checkbox" && sortBy.column != column && <TbArrowsDownUp style={{width: "13px", height: "13px", marginTop: "4px", marginLeft: "4px"}} />}
      {column != "Checkbox" && sortBy.column === column?
        sortBy.growing?
          <CgArrowDown style={{width: "15px", height: "15px", marginTop: "4px", marginLeft: "3px", alignItems: "center"}} /> :
          <CgArrowUp style={{width: "15px", height: "15px", marginTop: "4px", marginLeft: "4px", alignItems: "center"}} />
        : null
      }
    </>
  );

}
Arrows.propTypes = {
    classes: PropTypes.object.isRequired,
    column: PropTypes.string.isRequired,
    sortBy: PropTypes.any.isRequired,
};

export default withStyles(styles)(Arrows);