/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import InputMask from "react-input-mask";
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import ComplexTable from 'containers/Pages/Table';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import { postModifiedReportCard, postModifiedWeatherWarning } from 'services/mapsPortal/alerts'

//MODAL
import Modal from '@material-ui/core/Modal';
import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import LightningAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import FieldsComponent from "../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert/fieldsComponent";
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../services/mapsPortal/alerts"
import { FaNewspaper, FaCloudSun } from 'react-icons/fa'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AlertsRetificationPrevision from './AlertsRetificationPrevision';

import {
  PlansTabStyled,
  SectionTitle,
  PlanItem,
  TargetList,
  TargetItem, 
  Span,
  ItemContent,
  CompanyItem,
} from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab/styled';

import { Column, Row } from 'components/Flex';
import Collapsible from 'components/Collapsible';
import TargetInfo from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab/TargetInfo';
// Icons
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
// Hooks
import { useModal } from 'Context/ModalContext';
import { useTargetState } from 'Context/TargetStateContext';

import Company from 'pages/AdminDashboard/Edit/Company';
      
const useStyles = makeStyles({
  newspaperSelect: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#F0D138",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#F0D138'
    }
  },
  meteorologicAlertsSelect: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#B6888F",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#B6888F'
    }
  },
  meteorologicalAlert: {
    "& .MuiTabs-indicator": {
      backgroundColor: "black",
    },
    "& .MuiTab-root.Mui-selected": {
      color: 'black'
    }
  },
  releases: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#27B624",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#27B624'
    }
  },
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
})



const Body = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 90%;
height: 90%;
background-color: white;
border-radius: 13px;
display: flex;
justify-content: space-between;
align-items: stretch;
`
const LeftSide = styled.div`
background-color: white;
border-radius: 13px 0 0 13px;
display: flex;
flex-direction: column;
@media (min-width: 768px) {
    width: 45%
};
@media (min-width: 1024px) {
    width: 40%
};
@media (min-width: 1280px) {
    width: 40%
};
@media (min-width: 1366px) {
    width: 40%
};
@media (min-width: 1440px) {
    width: 40%
};
`
const LeftSide_TitleDiv = styled.div`
height: 13%;
width: 100%;
background-color: #1B1B1B;
border-radius: 13px 0 0 0;
text-align: center;
justify-content: center;
display: flex;
`
const LeftSide_ContentDiv = styled.div`
height: 78%;
width: 100%;
background-color: white;
border-radius: 0 0 0 13px;
position: relative;
box-shadow: 4px 0px 10px 3px rgba(0, 0, 0, 0.1);
overflow-y: auto;
`

const LeftSide_FooterDiv = styled.div`
height: 9%;
width: 100%;
background-color: white;
border-radius: 0 0 0 13px;
align-items: center;
justify-content: flex-start;
display: flex;
position: relative;
`

const RightSide = styled.div`
background-color: white;
border-radius: 0 13px 13px 0;
display: flex;
flex-direction: column;
background-color: white;
@media (min-width: 768px) {
    width: 55%
};
@media (min-width: 1024px) {
    width: 60%
};
@media (min-width: 1280px) {
    width: 60%
};
@media (min-width: 1366px) {
    width: 60%
};
@media (min-width: 1440px) {
    width: 60%
};
`

const RightSide_TabsDiv = styled.div`
height: 13%;
width: 100%;
background-color: white;
border-radius: 0 13px 0 0;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
position: relative;
`
const RightSide_ContentDiv = styled.div`
height: 78%;
width: 100%;
background-color: white;
border-radius: 0 0 0 13px;
overflow-y: auto;
`

const RightSide_FooterDiv = styled.div`
height: 9%;
width: 100%;
background-color: white;
border-radius: 0 0 13px 0;
align-items: center;
justify-content: flex-start;
display: flex;
box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.1);
position: relative;
`


const PrevisionRetification = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo } = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const tabStyle = useStyles();
  const theme = useTheme();
  const openToast = useToast();
  const fonts = fontStyles();
  const colors = useStyles();
  const ref = useRef();
  const refEdit = useRef();
  const refAdd = useRef();
  var aux = 0;
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [loadingLeftSide, setLoadingLeftSide] = useState(true);
  const [reloadLeftSide, setReloadLeftSide] = useState(false);
  const [loadingRightSide, setLoadingRightSide] = useState([false]);
  const [reloadRightSide, setReloadRightSide] = useState(false);
  const [allValues, setAllValues] = useState([]);
  const [rightSideTabs, setRightSideTabs] = useState(0);
  const [reloadPostData, setReloadPostData] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState();
  const [reloadSelected, setReloadSelected] = useState(false);
  const [reloadSelectAll, setReloadSelectAll] = useState(false);
  const [reloadTargets, setReloadTargets] = useState(false);
  const [confirmTargetsList, setConfirmTargetsList] = useState();
  const [selected, setSelected] = useState("");
  const [reloadErrorMessage, setReloadErrorMessage] = useState(false);
  const [reloadLoading, setReloadLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [lightningAlertErrors, setLightningAlertErrors] = useState([]);
  const [releaseAlertErrors, setReleaseAlertErrors] = useState([]);
  const [valuesBoletins, setValuesBoletins] = useState([]);
  const [valuesAvisos, setValuesAvisos] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [rainAlertErrors, setRainAlertErrors] = useState([]);
  const [servicesLightningAlert, setServicesLightningAlert] = useState();
  const [alertsData, setAlertsData] = useState([]);
  const [valIndex, setValIndex] = useState(null);
  const [companyElements, setCompanyElements] = useState(null);
  const [reloadStatusTargetSelected, setReloadStatusTargetSelected] = useState(false);
  const [reloadSwitchSelected, setReloadSwitchSelected] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [confirmSeparatedVals, setConfirmSeparatedVals] = useState(false);
  const [statusTargetSelected, setStatusTargetSelected] = useState([]);
  const tab = props;
  const [sortAscending, setSortAscending] = useState(true);
  const [reloadAlerts, setReloadAlerts] = useState(false);
  const [collapse, setCollapse] = useState([]);
  var gridClasses = [
    classes.gridColumnsComponent,
    classes.gridMarginLeft,
  ]

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setReload(true);
  }, [checkboxes]);

  const handleClose = () => {
    setOpen(null);
    setReloadMod(true);
    setAnchorEl(null)
  }

  const handleChangeIndexRightTabs = (index) => {
      setRightSideTabs(index);
      setReloadTargets(true);
      setAllValues([]);
      setReload(true);
  };

  const handleChangeRightTabs = (event, newValue) => {
    setRightSideTabs(newValue);
    setAllValues([]);
    setReloadTargets(true);
  };

  const toggleAllSelected = () => {
  setAllSelected(!allSelected);
  }

  const handleAllSelected = () => {
    if(!allSelected){
      let aux = [];
      tableData.map((cam, index) => aux.push(index));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
    else{
      let aux = [];
      tableData.map((cam1, index1) => selected.some((id) => index1 === id)? aux : aux.push(index1));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
  } 

  const handleAddSwitch = (element) => {
    let aux = [];
    let idt = 0

    if(allValues.length > 0){
      allValues.map((actVal) => {
        if(actVal.id_alerta != element.id_alerta){
          aux.push(actVal)
        }
      })
        aux.push(element)
  
      setAllValues(aux);
      setReload(true);
    }else{
      aux.push(element)
      setAllValues(aux);
      setReload(true);
    }


  };

  useEffect(() => {
    setReloadLeftSide(false);
  }, [reloadLeftSide])

  useEffect(() => {
    setReloadRightSide(false);
  }, [reloadRightSide])

  useEffect(() => {
    if (checkboxSelected && checkboxSelected.length > 0) {
      for (let i = 0; i < checkboxSelected.length; i++) {
        if (checkboxSelected[i] === false) {
          setAllSelected(false);
        }
      }
    }
    setReloadSelectAll(true);
    setReloadSelected(false);
  }, [reloadSelected])

  const receivedValues = tableData.values!= null && tableData.values.map((element, index) => {
    let aux = element.split("_")
    console.log(aux)
    return(
      {id: aux[0], intensidade: aux[1], condicao_tempo: aux[2], planos: [aux[3]], alvo: aux[4], empresa: aux[5], acumulado: aux[6], probabilidade: aux[7], nivel: aux[8], rajada_vento: aux[9], direcao_vento: aux[10], velocidade_vento: aux[11], temperatura_min: aux[12], temperatura_max: aux[13], obs: aux[14], tipo: aux[15], id_alvo: aux[16], nome_meteorologista:aux[17], meteorologista: aux[18], horizontes: aux[19], vigencia: aux[20], especial: aux[21], status: aux [22], datahora_criacao:aux[23], caminho_pdf: aux[24], datahora_modificacao: aux[25], titulo: aux[26], texto: aux[27], modificacao: aux[28], errata: aux[29], index: index}
      
    )  
  });

 
  useEffect(() => {
    const p = () => receivedValues.map(element => ({
        id_company: element.id,
        value: true,
        plans: element.planos.map(plans => true)
    }))
    setCollapse(p())
  }, [JSON.stringify(receivedValues)])


  const collapseCompany = (id) => {

    setCollapse(p => {
        const a = [...p];
        console.log(a)
        a[id].value = !a[id].value;
        return a;
    })

  }

  const collapsePlan = (cId, pId) => {
      setCollapse(p => {
          const a = [...p];
          a[cId].plans[pId] = !a[cId].plans[pId]
          return a;
      })
  }

  const separateValues = () => {
    let aux1=[]
    let aux2=[]
  
    receivedValues!=null && receivedValues.map((element) =>{
      let finder = element.empresa+"_company_Alvo_"+element.alvo+"_"+element.id
      if(element.tipo=="Boletins" && checkboxes.includes(finder)){
        aux1.push(element)
      }
      if(element.tipo=="Avisos Meteorológicos" && checkboxes.includes(finder)){
        aux2.push(element)
      }
    })
    
    setValuesBoletins(aux1);
    setValuesAvisos(aux2);
    setConfirmSeparatedVals(true);
    setReload(true);
  };

  const organizeValues = () =>{
    let aux = {}
    let aux1=[]
    let aux2=[]

    receivedValues.map((element) => {
      if(aux[element.empresa] == undefined){
        aux[element.empresa] = []
        aux[element.empresa].push(element)
      }else{
        aux[element.empresa].push(element)
      }
      if(element.tipo=="Boletins"){
        aux1.push(element)
      }else if(element.tipo=="Avisos Meteorológicos"){
        aux2.push(element)
      }

    });

    setValuesBoletins(aux1)
    setValuesAvisos(aux2)
    setCompanyElements(aux);
    setReload(true);
    separateValues();
    
  } ;

  const addCheckboxEspecific = (element) => {
    setConfirmSeparatedVals(false);
    let aux = [];
    if(element.split("_")[0]!=undefined && element.split("_")[0]!=null && element.split("_")[2]=="All"){
      
        const finderAux2 = checkboxes.find((filteredButton) => filteredButton == element.split("_")[0]+"_All");
        
        if(finderAux2 === undefined || finderAux2 == ""){
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            console.log(companyVals)
            if(!checkboxes.includes(companyVals.empresa+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id)){
              aux = checkboxes
              aux.push(companyVals.empresa+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id)
            }

            if(!checkboxes.includes(companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id)){
              aux = checkboxes
              aux.push(companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id)
            }
          })
          aux.push(element.split("_")[0]+"_All")
        
        }else{
          aux = checkboxes
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            let act_val1 = companyVals.empresa+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id
            let act_val2 = companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id
            if(aux.includes(act_val1)){
              aux = aux.filter((val)=>{
                return val != act_val1
                
              }); 
            }
            if(aux.includes(act_val2)){
              aux = aux.filter((val)=>{
                return val != act_val2
                
              }); 
            }
          })

          aux = aux.filter((val)=>{
            return val != finderAux2
            
          }); 
        }


    }else{
      const finder = checkboxes.find((filteredButton) => filteredButton == element);
      if(finder === undefined || finder == ""){
        aux = checkboxes;
        aux.push(element);
        let auxN = 1;
        if(element.split("_")[2] == "Plan"){
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            let act_val = companyVals.empresa+"_company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id
            if(checkboxes.includes(act_val)){
              auxN = auxN*1
            }else{
              auxN = auxN*0
            }

          })

          if(auxN == 1){
            aux.push(element.split("_")[0]+"_All")
            setReload(true);
          }

          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            if(companyVals.id == element.split("_")[4]){
              aux.push(companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id)
            }
          })

        }else if(element.split("_")[2] == "Alvo"){
          let countN = 1;
          let auxN = 1;
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            let act_val1 = companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id

            if(companyVals.id == element.split("_")[4]){
              countN = 0
              if(checkboxes.includes(act_val1)){
                auxN = auxN*1
              }else{
                auxN = auxN*0
              }

            }

          })

          if(auxN == 1 && countN!=1){
            let tempVal = ""
            let alvoVal = ""
            companyElements[element.split("_")[0]].map((companyVals, index2)=> {
              if(companyVals.id == element.split("_")[4]){
                if(tempVal==""){
                  alvoVal = companyVals.empresa+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id
                }
              }
            })
  
            aux.push(alvoVal)
            setReload(true);
          }

          let auxM = 1
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            let act_val1 = companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id
            let act_val2 = companyVals.empresa+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id
            if(checkboxes.includes(act_val2) && checkboxes.includes(act_val1)){
              auxM = auxM*1
            }else{
              auxM = auxM*0
            }
          })
          if(auxM == 1){
            aux.push(element.split("_")[0]+"_All")
            setReload(true);
          }
          
        }
      }else{
        aux = checkboxes.filter((val)=>{
          return val != finder
          
        });
        const finderAux1 = aux.find((filteredButton) => filteredButton == element.split("_")[0]+"_All");

        if(finder != undefined || finder != ""){
          let auxT = aux.filter((val)=>{
            return val != finderAux1
          })
          aux = auxT
        }
        if(element.split("_")[2] == "Plan"){
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            if(companyVals.id == element.split("_")[4]){
              let auxD = aux.filter((val)=>{
                return val != companyVals.empresa+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id
              })
              aux = auxD
            }
          })
        }else if(element.split("_")[2] == "Alvo"){
          companyElements[element.split("_")[0]].map((companyVals, index2)=> {
            if(companyVals.id == element.split("_")[4]){
              let auxD = aux.filter((val)=>{
                return val != companyVals.empresa+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id
              })
              aux = auxD
            }
            
          })
        }
      }
    }
    setCheckboxes(aux);
    separateValues();
    setReloadRightSide(true);
  };


  useEffect(() => {
    organizeValues()
  }, [])

  useEffect(() => {
    separateValues()
  }, [])

  const postSwitchs = (tab) => {
    if(tab ==0 && allValues.length > 0){
      let error = ""
      allValues.map((element,index) => {
        let aux = element
        aux.id_boletim_modificado = null;
        aux.nome_empresa = element.empresa
        aux.nome_alvo = element.alvo
        aux.nome_plano = element.plano[0]
        aux.observacao = aux.observacao==""? null : aux.observacao
        aux.nome_meteorologista = element.nome_meteorologista==""? null : element.nome_meteorologista
        aux.meteorologista = element.meteorologista!="null"&&element.meteorologista!="undefined"&&element.meteorologista!=""? parseInt(element.meteorologista) : null
        aux.datahora_modificacao = null;
        aux.horizontes = parseInt(aux.horizontes);
        aux.status = parseInt(aux.status);
        aux.id_boletim_original = aux.id_og
        if(aux.especial == "false" || aux.especial == "null" || aux.especial == "undefined" || aux.especial == ""){
          aux.especial = false
        }else{
          aux.especial = true
        }  
        if(aux.errata=="undefined" || aux.errata=="false" || aux.errata == "null"){
          aux.errata = false
        }else if(aux.errata=="true"){
          aux.errata = true
        }
        delete aux.empresa
        delete aux.alvo
        delete aux.plano
        delete aux.meteorologista
        delete aux.shipment
        delete aux.startDate
        delete aux.endDate
        
        console.log(aux)
        postModifiedReportCard(aux).then((response)=>{
          console.log(response)
          if(response.status != 201){
            error+= index+" "
          }
        }).catch(() => {
          error+= index+" "
        })
        if(error.length != 0){
          openToast('error', `Problema ao executar a retificação ${error}`)
        }else{
          openToast('success', 'Retificação realizada com sucesso!')
          handleClose()
        }

      })
      
    }else if(tab == 1 && allValues.length > 0){
      let error = ""
      allValues.map((element,index) => {
        let aux = element
        aux.id_aviso_modificado = null;
        aux.datahora_modificacao = null;
        aux.id_aviso_original = aux.id_og
        if(aux.errata=="undefined" || aux.errata=="false" || aux.errata == "null"){
          aux.errata = false
        }else if(aux.errata=="true"){
          aux.errata = true
        }
        delete aux.empresa
        delete aux.alvo
        delete aux.intensidade
        delete aux.nivel
        delete aux.probabilidade
        delete aux.temperatura_min
        delete aux.temperatura_max
        delete aux.condicao_tempo
        delete aux.acumulado
        delete aux.rajada_vento
        delete aux.direcao_vento
        delete aux.velocidade_vento
        delete aux.observacao
        delete aux.horizontes
        delete aux.vigencia
        delete aux.status
        delete aux.especial
        delete aux.plano
        delete aux.nome_meteorologista
        delete aux.meteorologista
        delete aux.shipment
        delete aux.startDate
        delete aux.endDate
        
        console.log(aux)
        postModifiedWeatherWarning(aux).then((response)=>{
          if(response.status != 201){
            error+= index+" "
          }
        }).catch(() => {
          error+= index+" "
        })

      })
      if(error.length != 0){
        openToast('error', `Problema ao executar a retificação ${error}`)
      }else{
        openToast('success', 'Retificação realizada com sucesso!')
        handleClose()
      }

    }
  };

  const startChecks = () =>{
    var count = 0
    if(companyElements!=null && count==0){
      count+=1
      let aux = []
      tableData.values.map((element) =>{
        let splitted = element.split("_")
        if(!aux.includes(splitted[5])){
          addCheckboxEspecific(splitted[5]+"_"+"company"+"_All")
          aux.push(splitted[5])
        }
      })
    }
    setReload(true)
  }

  console.log(checkboxes)

  useEffect(() => {
    startChecks()
  }, [companyElements])

  return(
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Body>
          <LeftSide>
              <LeftSide_TitleDiv>
                  <AppBar position="static" color="transparent" style={{ alignSelf: "center" }}>
                      <span span style={fonts.panelTitle}>RETIFICAÇÃO</span>
                  </AppBar>
              </LeftSide_TitleDiv>
              <LeftSide_ContentDiv>
                 <PlansTabStyled>
                  <TargetList>

                      {companyElements==null
                          ? <SectionTitle>Nenhum Plano ou Alvo encontrado.</SectionTitle>
                          : (
                              <>
                                  {Object.keys(companyElements).sort().map((company, index) => {
                                      console.log(Object.keys(company))
                                        return(
                                        <Collapsible
                                          key={`sidebarempresa-${index}`}
                                          isOpen={collapse.length ? collapse[index].value : false}
                                          main={(
                                              <CompanyItem
                                                  key={company + '-sidebarEmpresa-check'}
                                                  isOpen={collapse.length ? collapse[index].value : false}
                                              >
                                                  <Checkbox
                                                      label={company}
                                                      checked={checkboxes.includes(company+"_All")}
                                                      onChange={() => {addCheckboxEspecific(company+"_"+"company"+"_All")}}
                                                      value={"Company_"+company+"_All"}
                                                  />
                                                  <ItemContent onClick={() => collapseCompany(company)}>
                                                      <Span style={{ fontSize: '17px' }}>
                                                          {company}
                                                      </Span>
                                                      <MdExpandMore style={{ fontSize: '22px' }} />
                                                  </ItemContent>
                                              </CompanyItem>
                                                )}
                                        >
                                          {companyElements[company].map((companyVals, index2)=> {
                                            console.log(companyVals)
                                          return (
                                              <>
                                              <Collapsible
                                                key={`sidebarplano-${companyVals.index}`}
                                                isOpen={collapse.length ? collapse[companyVals.index].plans[companyVals.index]? collapse[companyVals.index].plans[companyVals.index] : false : false}
                                                main={(
                                                    !loadingLeftSide.length &&

                                                    <PlanItem
                                                        ml='16px'
                                                        key={companyVals.index + '-sidebarPlano-check'}
                                                        isOpen={collapse.length ? collapse[companyVals.index].plans[companyVals.index] : false}
                                                    >
                                                        <Checkbox
                                                            label={companyVals.planos[0]}
                                                            checked={checkboxes.includes(company+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id)}
                                                            onChange={() => {addCheckboxEspecific(company+"_"+"company"+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id)}}
                                                            value={"Company_"+company+"_Plan_"+companyVals.planos[0]+"_"+companyVals.id}
                                                        />
                                                        <ItemContent onClick={() => collapsePlan(companyVals.index, companyVals.index)}>
                                                            <Span style={{ fontSize: '17px'}} ml='16px'>
                                                                {companyVals.planos[0]}
                                                            </Span>
                                                            <MdExpandMore style={{ fontSize: '22px' }} />
                                                        </ItemContent>
                                                    </PlanItem>
                                                )}
                                              >
                                              </Collapsible>
                                              <TargetItem ml='32px'>
                                                {
                                                    <Checkbox
                                                      label={companyVals.alvo}
                                                      checked={checkboxes.includes(company+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id)}
                                                      onChange={() => {addCheckboxEspecific(company+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id)}}
                                                      value={company+"_"+"company"+"_Alvo_"+companyVals.alvo+"_"+companyVals.id}
                                                    />
                                                }
                                                <ItemContent>
                                                  {
                                                    <>
                                                      <Span ml='32px'>{companyVals.alvo}</Span>
                                                    </>
                                                  }
                                                </ItemContent>
                                              </TargetItem>
                                            </>
                                          )})}
                                        </Collapsible>
                                      )
                                      }
                                      
                                      )}
                              </>
                          )}
                  </TargetList>
                </PlansTabStyled>
              </LeftSide_ContentDiv>
              <LeftSide_FooterDiv>
                  <Button onClick={() => handleClose()} style={{ marginLeft: "25px" }}>
                  <ArrowBackIcon style={{ width: "30px", height: "30px ", color: "#1B1B1B" }} />
                  </Button>
              </LeftSide_FooterDiv>
          </LeftSide>
          <RightSide>
            <RightSide_TabsDiv>
                <Tabs
                value={rightSideTabs}
                onChange={handleChangeRightTabs}
                className={rightSideTabs === 0 ? tabStyle.newspaperSelect : rightSideTabs === 1 ? tabStyle.meteorologicAlertsSelect : rightSideTabs === 2 ? "" : ""}  
                variant="fullWidth"
                aria-label="full width tabs example"
                style={{ height: "100%", display: "flex", alignItems: "center" }}
                centered={true}
                >
                <Tab icon={<FaNewspaper style={{ width: "22px", height: "22px" }} />} label="Boletim" />
                <Tab icon={<FaCloudSun style={{ width: "22px", height: "22px" }} />} label="Aviso Meteorológico" />
                </Tabs>
            </RightSide_TabsDiv>
          <RightSide_ContentDiv>
            {confirmSeparatedVals?
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={rightSideTabs}
              onChangeIndex={handleChangeIndexRightTabs}
            >   
                  <TabPanel value={rightSideTabs} index={0}  dir={theme.direction}>
                    {rightSideTabs==0? 
                      <AlertsRetificationPrevision checkboxes={checkboxes} rightSideTabs={rightSideTabs} setReloadRightSide={setReloadRightSide} allValues={allValues} setAllValues={handleAddSwitch} value={valuesBoletins} typeVal={0}/>
                    :
                      "SEM DADOS"
                    }
                  </TabPanel>   
                  <TabPanel value={rightSideTabs} index={1}  dir={theme.direction}>
                    {rightSideTabs==1? 
                      <AlertsRetificationPrevision checkboxes={checkboxes} rightSideTabs={rightSideTabs} setReloadRightSide={setReloadRightSide} allValues={allValues} setAllValues={handleAddSwitch} value={valuesAvisos} typeVal={1} />
                    :
                      "SEM DADOS"
                    }
                  </TabPanel>

            </SwipeableViews>
            :
              <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" />
            }
          </RightSide_ContentDiv>
          <RightSide_FooterDiv>
              <Grid item xs={0.5} className={classes.gridContainer} >
              <Switch
                  checked={allSelected}
                  onChange={() => toggleAllSelected()}
                  value={allSelected}
                  size="small"
                  classes={{
                  colorPrimary: tabStyle.switch_primary
                  }}
                  color={"primary"} />
              </Grid>

              <Grid item xs={2} style={{ marginLeft: "8px" }}>
              <Typography variant="subtitle1" style={fonts.planName}>
                  Selecionar todos
              </Typography>
              </Grid>

              <Grid item xs={3} className={classes.gridButton}>
              <Button  onClick={() => {postSwitchs(rightSideTabs)}} style={fonts.buttonDefault} variant="contained" className={rightSideTabs === 0 ? classes.buttonNewspaper : rightSideTabs === 1 ? classes.buttonMeteorologicAlert : classes.buttonRain}>
                  RETIFICAR
                  <div className={classes.iconContainer}>
                  {rightSideTabs === 0 ? <FaNewspaper style={{ width: "16px", height: "16px", color: "#F0D138" }} /> : <FaCloudSun style={{ width: "16px", height: "16px", color: "#B6888F" }} /> }
                  </div>
              </Button>
              </Grid>
          </RightSide_FooterDiv>
          </RightSide>
      </Body>
    </Modal>
  )
} 

PrevisionRetification.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.bool,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
  tab: PropTypes.object.isRequired,
  
};
  
export default withStyles(styles)(PrevisionRetification);