/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
/* CSS */
import styles from "./releases_forms-jss";
import fontStyles from "styles/fonts-jss";
import FieldsComponent from "./fieldsComponent";
/* Context */
import { useModal } from 'Context/ModalContext';

const useStyles = makeStyles({
  divider: {
    background: "#848484",
    border: "1px solid",
    width: "100%",
  },
});


const Releases = (props) => {
  const { classes, alertsData, setAlertsData, setReloadSwitchSelected, allSelected, setAllSelected, setReloadErrorMessage, setStatusTargetSelected, statusTargetSelected, setReloadStatusTargetSelected, reloadTargets, setReloadTargets, releaseAlertErrors, setReleaseAlertErrors, servicesLightningAlert } = props;
  const fonts = fontStyles();
  const dividerStyle = useStyles();
  const [reloadModal, setReloadModal] = useState(false);
  const { plans: planos, toShow } = useModal();
  const [caractersRemaning, setCaractersRemaning] = useState();
  const [allSelectedAux, setAllSelectedAux] = useState(false);
  const [loadingRightSide, setLoadingRightSide] = useState([false]);
  const [reloadRightSide, setReloadRightSide] = useState(false);

  var gridClasses = [
    classes.gridColumnsIndex,
    classes.gridMarginLeftRelease,
  ]

  const [preset, setPreset] = useState({
    preset: false,
    nivel: "",
    modificacao: null,
    risco: "",
    contato: "",
    observacao: "",
    meteorologista: "",
    campos_modificados: null,
    conteudo_modificacao: null,
    id_alvo: "",
  });


  useEffect(() => {
    setReloadModal(false);
  }, [reloadModal])

  useEffect(() => {
    if (preset.observacao && preset.observacao.length > 1 && preset.observacao.length < 50) {
      setCaractersRemaning(50 - preset.observacao.length);
    }
    else {
      setCaractersRemaning("");
    }
  }, [preset])

  useEffect(() => {
    setReloadRightSide(false);
  }, [reloadRightSide])
  
  return (
    <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="center" style={{ marginTop: "5px", padding:'10px' }}>
      <Grid container className={classes.gridContainer}>
        <Autocomplete
          id="level"
          freeSolo={false}
          value={preset?.nivel}
          inputValue={preset?.nivel}
          disableClearable
          onChange={(_, newValue) => setPreset({ ...preset, nivel: newValue })}
          className={classes.levelField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["T1", "T2", "T3", "T4"]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Nível"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }} />} />

        <Autocomplete
          id="risk"
          freeSolo={false}
          value={preset?.risco}
          inputValue={preset?.risco}
          disableClearable
          onChange={(_, newValue) => setPreset({ ...preset, risco: newValue })}
          className={classes.riskField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["Médio", "Alto", "Muito alto"]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Risco"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }} />} />

        <Autocomplete
          id="contacts"
          freeSolo
          value={preset?.contato}
          inputValue={preset?.contato}
          disableClearable
          onInputChange={(_, newValue) => setPreset({ ...preset, contato: newValue })}
          onChange={(_, newValue) => setPreset({ ...preset, contato: newValue })}
          className={classes.contactField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["Não foi possível o contato telefônico.",  "Não foi realizado o contato telefônico.", "Não há contato telefônico das xx:xx até às xx:xx." ]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Contato"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }} />} />
      </Grid>

      <Grid container className={classes.gridContainer} style={{marginTop: '10px'}}>
        <Autocomplete
          id="comments"
          freeSolo
          value={preset?.observacao}
          inputValue={preset?.observacao}
          disableClearable
          onInputChange={(_, newValue) => setPreset({ ...preset, observacao: newValue })}
          onChange={(_, newValue) => setPreset({ ...preset, observacao: newValue })}
          className={classes.commentsField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["Com possibilidade de um novo alerta nas próximas horas."]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Observações"
              margin="normal"
              variant="outlined"
              color="primary"
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }} 
            />
          }
        />
      </Grid>

      <Grid container className={classes.commentsContainer}>
        {caractersRemaning && <p className={classes.errorMessage}>{caractersRemaning + " caracteres restantes"}</p>}
      </Grid>

      <Grid container className={classes.buttonContainer}>
        <Button style={fonts.buttonDefault} variant="contained" className={classes.releaseButton} onClick={() => setPreset({ ...preset, preset: !preset?.preset })}>
          Alterar todos
        </Button>
      </Grid>

      <Grid container className={classes.gridContainer} style={{height: '2px', marginTop: '10px'}}>
        <Divider classes={{ root: dividerStyle.divider }} />
      </Grid>

      {loadingRightSide.length != 0 &&
        <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" />
      }
      {planos?.map(company => company.planos.map((plan) => {
        return plan.alvos.map((target) => {
          if (!toShow.get(company.id, plan.id, target.id)) return null
          return (
            <div key={target.id}>
              <FieldsComponent target={target} preset={preset} alertsData={alertsData} setAlertsData={setAlertsData} planoId={plan.id_plano} setReloadModal={setReloadModal} setReloadSwitchSelected={setReloadSwitchSelected} allSelected={allSelected} setAllSelected={setAllSelected} allSelectedAux={allSelectedAux} setAllSelectedAux={setAllSelectedAux} setReloadErrorMessage={setReloadErrorMessage} setStatusTargetSelected={setStatusTargetSelected} statusTargetSelected={statusTargetSelected} setReloadStatusTargetSelected={setReloadStatusTargetSelected} reloadTargets={reloadTargets} setReloadTargets={setReloadTargets} setLoadingRightSide={setLoadingRightSide} loadingRightSide={loadingRightSide} setReloadRightSide={setReloadRightSide} setReleaseAlertErrors={setReleaseAlertErrors} releaseAlertErrors={releaseAlertErrors} servicesLightningAlert={servicesLightningAlert} />
            </div>
          )
        })
      }))}
    </Grid>
  )
  //}
}

Releases.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func.isRequired,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  loadingLeftSide: PropTypes.any.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
  releaseAlertErrors: PropTypes.any.isRequired,
  setReleaseAlertErrors: PropTypes.func.isRequired,
  servicesLightningAlert: PropTypes.any.isRequired,
};

export default withStyles(styles)(Releases);