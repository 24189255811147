import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import './slick-carousel/slick-carousel.css';
import './slick-carousel/slick.css';
import './slick-carousel/slick-theme.css';
import { IoMdSkipBackward, IoMdSkipForward} from 'react-icons/io';
import {PlayButton} from './styled';
import { Typography, Icon, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import 'moment/locale/pt-br';

const styles = ({
  item: {
    textAlign: 'center',
    margin: '10px 0 200px 0',
  },
  img: {
    height: "824px",
    width: "900px",
  },
});


function SampleNextArrow(props) {
  return (
    <div />
   
  );
}

function SamplePrevArrow(props) {
  return (
    <div />
  );
}


function AutoplaySlider(props) {
  const { classes, data, previousDays, allDates, date, setImgIndex, setOpenZoom, setAnchorEl, keyboardKey } = props;
  const moment = require('moment');
  moment.locale('pt-br');
  const [playing, setPlaying] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [clicked, setCicked] = useState(false);
  const anchorRef = useRef(null);
  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    centerMode: true,
    vertical: true,
    fade: true,
    verticalSwiping: false,
    speed: 0.5,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

  };

  const autoPlay = () => {
    if(playing){
      sliderRef.slickPause();
      setPlaying(false);
    }
    else{
      sliderRef.slickPlay();
      setPlaying(true);
    }
  };


  const next = () => {
    sliderRef.slickNext();
  };

  const prev = () => {
    sliderRef.slickPrev();
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openMenu);

  const handleOpenZoom = (e, index) => {
    setAnchorEl(e.currentTarget);
    setOpenZoom(true);
    setImgIndex(index)
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  window.addEventListener("keydown", function(e) {
      if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
          e.preventDefault();
      }
  }, false);

  const handleDownloadImage = (event, url) => {
    const a = document.createElement('a')
    a.href = url
    a.download = url.split('/').pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    handleClose(event);
  };

  const handleDownloadAllImages = (event) => {
    const allImages = data.map((item) => item.caminho);
    
    downloadAll(allImages);
    handleClose(event);
  };

  const blobFiles = (images) => {
    let fetchFile = async function(url) {
      return fetch(url).then(res => res.blob());
    }

    let exportFile = function(file) {
      let a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.setAttribute('download', '');
      a.click();
    }

    for(const url of images) {
      fetchFile(url).then(file => exportFile(file));
    }
  };

  function downloadAll(urls) {
    for (var i = 0; i < urls.length; i++) {
      forceDownload(urls[i], urls[i].substring(urls[i].lastIndexOf('/')+1,urls[i].length))
    }
  }

  function forceDownload(url, fileName){
      var xhr = new XMLHttpRequest();
      /* "Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
        "Access-Control-Max-Age": "86400",*/
      xhr.open("GET", `https://cors-anywhere.herokuapp.com/${url}`, true);
      xhr.responseType = "blob";
      xhr.onload = function(){
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          var tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
      }
      xhr.send();
  }

  useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }
  
    prevOpen.current = openMenu;
  }, [openMenu]);

  useEffect(() => {
    keyboardKey[0] === 'ArrowLeft'? prev() : keyboardKey[0] === 'ArrowRight'? next() : null;
  }, [keyboardKey]);
  
  useEffect(() => {
    let selectedDate = date.split(' - ')[0] + " " + date.split(' - ')[1].slice(0, 5);
    let auxIndex = data?.map((img, index) => {if(moment(img.data_imagem).format("DD/MM/YYYY HH:mm") === selectedDate) return index})?.filter((id) => id !== undefined)[0];
    sliderRef.slickGoTo(auxIndex? auxIndex : 0);
  }, [date]);

  return (
    <div className="container">
      {previousDays && (
        <Typography
            variant="h6"
            align="center"
            style={{
                fontSize: '17px',
                color: 'red',
                margin: '20px 0 0',
            }}
        >
            Data de rodagem anterior ao dia atual!
        </Typography>
      )}
  
      <Slider {...settings} ref={slider => (sliderRef = slider)} style={{display: "flex", height: "80%"}}>
        {data.map((item, index) => (
          <>
            <div key={index.toString()} className={classes.item} id="sliderContainer">
              <img src={item.caminho} alt={item.data_imagem} className={classes.img} onClick={(e) => handleOpenZoom(e, index)} />
            </div>
          </>
        ))}
      </Slider>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "30px", marginTop: "60px"}}>
        <div className="button">
          <PlayButton isPlaying={playing} onClick={autoPlay}/>
        </div>
        <div className="button" onClick={prev}>
          <IoMdSkipBackward style={{width: "16px", height: "16px"}} />
        </div>
        <div className="button">
          <IoMdSkipForward style={{width: "16px", height: "16px"}}/>
        </div>
        <div className="exportButton">  
          <Button
            ref={anchorRef}
            aria-controls={openMenu ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            endIcon={<Icon>download</Icon>}
          >
            Exportar imagem
          </Button>
          <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={(e) => handleDownloadImage(e, data[allDates.indexOf(date)].caminho)}>Imagem atual</MenuItem>
                      <MenuItem onClick={(e) => handleDownloadAllImages(e)} disabled={true}>Todas imagens</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </div>
  );
}

AutoplaySlider.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  previousDays: PropTypes.bool.isRequired,
  allDates: PropTypes.array.isRequired,
  date: PropTypes.any.isRequired,
  openZoom: PropTypes.any.isRequired,
  setOpenZoom: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  setImgIndex: PropTypes.func.isRequired,
  keyboardKey: PropTypes.any.isRequired,
};

export default withStyles(styles)(AutoplaySlider);
