import React from 'react'
import p from 'prop-types';

import { Row, Column } from 'components/Flex';
import { DividerStyled } from '../PlansTab/styled';
import { Key } from './styled'

const HotkeyDescription = ({title, hotkey, description}) => {
  return (
    <Row w='100%'>
      <Column align='flex-start' w='420px' gap='20px'>
        <Row justify='space-between' w='100%'>
          <span style={{fontSize: '18px'}}><strong>{title}</strong></span>
          <Key>{hotkey}</Key>
        </Row>
        <span style={{fontFamily: 'Roboto', fontSize: '14px'}}>Pressione &quot;{hotkey}&quot; {description}</span>
        <DividerStyled />
      </Column>
    </Row>
  )
}

HotkeyDescription.propTypes = {
  title: p.string.isRequired,
  hotkey: p.string.isRequired,
  description: p.string.isRequired,
}

export default HotkeyDescription
