import post from "../../images/custom_icons/post_icon.png";
import thunderstorm_add from "../../images/custom_icons/thunderstorm_add_icon.png";
import gps_fixed_add from "../../images/custom_icons/gps_fixed_add_icon.png";
import register_all from "../../images/custom_icons/register_all_icon.png";
import register_all_active from "../../images/custom_icons/register_all_active_icon.png";
import view_plans from "../../images/custom_icons/view_plans_icon.png";
import view_plans_active from "../../images/custom_icons/view_plans_active_icon.png";


const menuAdminContext = [
  {
    key: 'home',
    name: 'Início',
    icon: 'home',
    link: '/administracao',
    child: []
  },
  {
    key: 'company',
    name: 'Empresas',
    icon: 'domain',
    link: '/administracao',
    child: []
  },
  {
    key: 'plan',
    name: 'Planos',
    customIcon: view_plans,
    customIconActive: view_plans_active,
    link: '/administracao/planos',
    child: []
  },
  {
    key: 'nucleoUser',
    name: 'Usuário Núcleo',
    icon: 'person',
    link: '/administracao/usuariosnucleo',
    child: []
  },
  {
    key: 'registerAll',
    name: 'Cadastrar',
    customIcon: register_all,
    customIconActive: register_all_active,
    link: '/administracao/registrar/todos',
    child: []
  },
 /* {
    key: 'company',
    name: 'Empresa',
    icon: 'domain_add',
    link: '/administracao/registro/empresa',
    child: []
  },
  {
    key: 'plan',
    name: 'Plano',
    icon: 'post_add',
    link: '/administracao/registro/planos',
    child: []
  },
  {
    key: 'services',
    name: 'Serviços',
    customIcon: thunderstorm_add,
    link: '/administracao/registro/serviço',
    child: []
  },
  {
    key: 'target',
    name: 'Alvos',
    customIcon: gps_fixed_add,
    link: '/administracao/registro/alvos',
    child: []
  },
  {
    key: 'user',
    name: 'Usuário Cliente',
    icon: 'person_add',
    link: '/administracao/registro/usuário',
    child: []
  },
  // {
  //   key: 'alerts',
  //   name: 'Alertas',
  //   icon: 'report_problem',
  //   child: [
  //     {
  //       key: 'alerts_page',
  //       name: 'ALERTAS',
  //       title: true,
  //     },
  //     {
  //       key: 'alerts_list',
  //       name: 'Listar',
  //       link: '/error',
  //       icon: 'ion-ios-document-outline',
  //     },
  //   ]
  // },
//   {
//     key: 'settings',
//     name: 'Settings',
//     icon: 'settings',
//     linkParent: '/administracao/blank-page',
//   }*/
];

export default menuAdminContext;

