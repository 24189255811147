import { alpha } from '@material-ui/core/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

const categoryWidth = 130;
const listWidth = 180;
const styles = theme => ({
  bgMain: {
    backgroundColor: theme.palette.menu.strongBlue,
    width: categoryWidth,
  },
  bgDark: {
    backgroundColor: theme.palette.menu.mediumBlue,
    display: 'flex',
    flexDirection: 'column',
  },
  pdTop: {
    paddingTop: theme.spacing(1),
  },
  bigSidebar: {
    color: theme.palette.text.white,
    padding: 0,
    position: 'relative',
    zIndex: 10,
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.menu.strongBlue,
    '&:before': {
      content: '',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.menu.strongBlue,
      boxShadow: theme.shade.light,
      backdropFilter: 'saturate(180%) blur(20px)',
      position: 'absolute',
      top: 0,
      left: 0
    }
  },
  /*nucleoTitle: {
    fontSize: '15px',
    fontFamily: '"Segoe UI","Roboto","sans-serif"',
    textTransform: 'uppercase',
    display: 'block',
    lineHeight: '28px',
    fontWeight: '700',
    color: "white",
    marginTop: '5px',
    paddingLeft: '6px',
  },*/
  category: {
    width: categoryWidth,
    '& $fixedWrap': {
      paddingBottom: theme.spacing(8),
    }
  },
  icon: {
    color: theme.palette.menu.strongYellow,
  },
  text: {
    color: theme.palette.menu.strongYellow,
  },
  active: {
  },
  portalsButton:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: "#FFB703",
    borderRadius: "5px",
    padding: "6px 14px 6px 14px",
    color: theme.palette.menu.strongYellow,
    '& $text': {
      color: theme.palette.menu.strongYellow,
    },
    '&:hover': {
      background: theme.palette.menu.strongYellow,
      '& $text': {
        color: theme.palette.menu.strongBlue,
      },
    },
  },
  selected: {},
  fixedWrap: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 12,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
        border: '1px solid rgba(255,255,255,0.3)',
      }
    }
  },
  avatarHead: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  statusMenu: {
    '& ul': {
      paddingTop: 0,
    },
    '& li': {
      width: 180
    }
  },
  swipeDrawerPaper: {
    width: categoryWidth + listWidth,
  },
  profile: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  name: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    fontSize: 14,
    '& h5': {
      marginBottom: 0
    }
  },
  pinned: {},
  dotStatus: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    display: 'inline-block',
    borderRadius: '50%',
    border: '1px solid #fff',
    marginRight: theme.spacing(1),
    '&$pinned': {
      position: 'absolute',
      bottom: 3,
      left: 60,
    }
  },
  online: {
    backgroundColor: lightGreen[500]
  },
  bussy: {
    backgroundColor: red[500]
  },
  idle: {
    backgroundColor: amber[500]
  },
  offline: {
    backgroundColor: grey[500]
  },
  menuHeadText: {
    textAlign: 'center',
    fontFamily: '"Segoe UI","Roboto","sans-serif"',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(0.3),
    marginBottom: "8px",
    '&:hover': {
      '& $text': {
        color: theme.palette.menu.strongYellow,
      },
    },
    '&$active': {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 5,
        height: 68,
        borderRadius: 5,
        top: 0,
        left: 0,
      },
      '& $text': {
        color: theme.palette.menu.strongYellow,
      },
    },
    '& $text': {
      width: "90%",
      overflow: 'visible',
      whiteSpace: 'pre-wrap',
      fontSize: 17,
      color: theme.palette.text.white
    }
  },
  menuHeadIcon:{
    borderRadius: 5,
    width: "55px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '&:hover': {
      background: theme.palette.menu.strongYellow,
      '& $icon': {
        color: theme.palette.menu.strongBlue,
      },
      
    },
    '&$active': {
      backgroundColor: theme.palette.menu.strongYellow,
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 5,
        height: 68,
        borderRadius: 5,
        top: 0,
        left: 0,
      },
      '& $icon': {
        color: theme.palette.menu.strongBlue,
      },
    },
    '& $icon': {
      color: theme.palette.text.white,
      display: 'block',
      fontSize: 28,
      marginBottom: theme.spacing(1),
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
  listMenu: {
    width: listWidth,
    marginTop: -10,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.menu.mediumBlue,
    '& > ul': {
      width: listWidth,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(8),
    },
    '& $icon': {
      color: theme.palette.text.white,
      fontSize: 22,
    },
    '& $text': {
      paddingLeft: 0,
      paddingRight: 0,
      '& span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: theme.palette.text.white,
        fontSize: 12,
        display: 'block'
      }
    },
    '& $item': {
      borderRadius: 5,
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
      border: "2px solid",
      borderColor: theme.palette.menu.white,
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "6px",
      margin: '10px 0',
      '& > div:first-child': {
        margin: `0 ${theme.spacing(1)}px`,
        minWidth: 'auto'
      },
      '&:hover': {
        backgroundColor: theme.palette.menu.strongYellow,
        border: "2px solid",
        borderColor: theme.palette.menu.strongYellow,
      },
      '&$active': {
        backgroundColor: 'rgb(255, 183, 3, 0.3)',
        border: "2px solid",
        borderColor: theme.palette.menu.strongYellow,
        '& $icon': {
          color: theme.palette.text.white,
        },
        '& $text': {
          '& span': {
            color: theme.palette.text.white,
          }
        }
      }
    }
  },
  drawerPaperClose: {
    width: 0,
    padding: 0,
    overflowX: 'hidden',
    '& ul': {
      position: 'relative'
    },
  },
  userShifted: {
    Top: theme.spacing(7),
    '& $avatarHead': {
      marginTop: theme.spacing(2)
    }
  },
  childMenuWrap: {
    '& $title, & $item': {
      opacity: 0,
      transform: 'translateX(-5px)'
    }
  },
  menuLoaded: {
    '& $title, & $item': {
      opacity: 1,
      transform: 'translateX(0px)',
      transition: 'all 0.2s ease-out'
    }
  },

  customIcon: {
    width: "26px",
    height: "26px",
  },

  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "4px",

    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "12px"
    },
    "&:hover":{
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.menu.strongYellow,
        border: "1px solid",
        borderColor: theme.palette.menu.strongYellow
      }
    }
   
  }
});

export default styles;
