import { lighten } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shade.light,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '&$noMargin': {
      margin: 0
    },
  },
  descBlock: {
    display: "flex",
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    }
  },
  titleContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    left: 0,
  },
  titleSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1
  },
  progressMenuContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    right: 0,
  },
  title: {
    textTransform: 'uppercase',
    fontFamily: '"Segoe UI","Roboto","sans-serif"',
    fontSize: 28,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.menu.strongBlue,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
  description: {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'italic',
    marginLeft: "2%",
    textTransform: 'uppercase',
    fontFamily: '"Segoe UI","Roboto","sans-serif"',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.menu.strongBlue,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  content: {
    padding: theme.spacing(2.5),
    borderRadius: "8px",
    backgroundColor: theme.palette.menu.white,
    boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3.5)
    }
  },
  tabsMainContainer: {
    width: "90%",
    height: "70px",
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: "flex-start",
    marginLeft: "5%"
  },
  tabsContainer: {
    height: "80%",
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: "flex-end",
    backgroundColor: theme.palette.menu.strongBlue,
    borderRadius: "8px 8px 0px 0px",
  },
  tab: {
    minWidth: "10%",
    padding: "0px",
  },
  arrowBackIcon: {
    width: "22px",
    height: "22px",
    fontWeight: 700,
    marginTop: "5px"
  },
  whiteBg: {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
  },
  noMargin: {},
  colorMode: {
    backgroundColor: theme.palette.primary.main,
    '& $title': {
      color: theme.palette.grey[100],
      '&:after': {
        borderBottom: `5px solid ${theme.palette.primary.light}`
      }
    },
    '& $description': {
      color: theme.palette.grey[100],
    }
  },
  overflowX: {
    width: '100%',
    overflowX: 'auto',
  },
  iconTitle: {
    borderRadius: theme.rounded.small,
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: `0 2px 15px -5px ${theme.palette.primary.main}`,
    background: theme.palette.type === 'dark' ? theme.palette.primary.main : lighten(theme.palette.primary.light, 0.5),
    width: 48,
    height: 48,
    textAlign: 'center',
    lineHeight: '44px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '& i': {
      fontSize: 28,
      verticalAlign: 'baseline',
      color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.primary.main
    }
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },

  progressMenuText: {
    color: "#DF7600"
  },

  progressMenuButton:{
    cursor: "pointer",
    color: "#DF7600",
  },

});

export default styles;
