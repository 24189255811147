/* Libraries */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import 'moment/locale/pt-br';
/* Material UI */
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ReportProblem from '@material-ui/icons/ReportProblem';
import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
/* CSS */
import styles from '../../forecast_forms-jss';
import fontStyles from 'styles/fonts-jss';
/* Services */
import { getUserAuthenticated } from 'services/login/login';
import { getEmployees_TargetByTargetID } from 'services/adminDashboard/employeesTarget';
import { getEmployees } from 'services/adminDashboard/employees';

const useStyles = makeStyles({
    switch_primary: {
        '&.Mui-checked': {
            color: '#1B1B1B',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#1B1B1B',
        },
    },
    switch_secondary: {
        '&.Mui-checked': {
            color: 'red',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'red',
        },
    },
    divider: {
        background: '#A5A5A5',
    },
});

const FieldsComponent = (props) => {
    const moment = require('moment');
    moment.locale('pt-br');
    const switchStyle = useStyles();
    const fonts = fontStyles();
    const {
        classes,
        target,
        preset,
        alertsData,
        setAlertsData,
        planoId,
        empresaId,
        setReloadSwitchSelected,
        setReloadModal,
        allSelected,
        setAllSelected,
        allSelectedAux,
        setAllSelectedAux,
        statusTargetSelected,
        setStatusTargetSelected,
        setReloadStatusTargetSelected,
        reloadTargets,
        setReloadTargets,
        reportCardErrors,
        setReportCardErrors,
        reportCardData,
        setReloadErrorMessage,
        empresaNome,
        planoNome,
        alvoNome,
        selectedHorizon,
        loadedReportCard
    } = props;
    //const [servicesStatus, setServicesStatus] = useState(true);
    const [reload, setReload] = useState(false);
    const [contacts, setContacts] = useState();
    const [loadTarget, setLoadTarget] = useState(false);
    const [caractersRemaning, setCaractersRemaning] = useState(0);
    const [switchSelected, setSwitchSelected] = useState(false);
    const today = moment().format("DD/MM/YYYY");
    //const [loading, setLoading] = useState(true);
    //const [loadingInfos, setLoadingInfos] = useState(false);
    const [weatherCondition, setWeatherCondition] = useState([
        "Em branco",
        "Sem previsão de chuva",
        "Chuva no período da manhã",
        "Chuva no período da tarde",
        "Chuva no período da noite",
        "Chuva ao longo do dia",
        "Pancadas de chuva ao longo do dia"
    ]);
    const [windDirection, setWindDirection] = useState([
        'Em branco',
        'De Sul',
        'De Leste',
        'De Norte',
        'De Oeste',
        'De Sudeste',
        'De Sudoeste',
        'De Nordeste',
        'De Noroeste',
    ]);


    const [windSprint, setWindSprint] = useState([
        `Em branco`,
        `Sem informação`,
        `Calmo, rajadas entre 0.01 km/h e 1.0 km/h`,
        `Aragem, rajadas entre 1.01 km/h e 6.0 km/h`,
        `Brisa leve, rajadas entre 6.01 km/h e 12.0 km/h`,
        `Brisa fraca, rajadas entre 12.01 km/h e 20.0 km/h`,
        `Brisa moderada, rajadas entre 20.01 km/h e 29.0 km/h`,
        `Brisa forte, rajadas entre 29.01 km/h e 39.0 km/h`,
        `Vento fresco, rajadas entre 39.01 km/h e 50.0 km/h`,
        `Vento forte, rajadas entre 50.01 km/h e 62.0 km/h`,
        `Ventania, rajadas entre 62.01 km/h e 75.0 km/h`,
        `Ventania forte, rajadas entre 75.01 km/h e 89.0 km/h`,
        `Tempestade, rajadas entre 89.01 km/h e 103.0 km/h`,
        `Tempestade violenta, rajadas entre 103.01 km/h e 117.0 km/h`,
        `Tornado/Furacão, rajadas entre 117.01 km/h e 999.9 km/h`,
    ]);

    const [reportCardAlert, setReportCardAlert] = useState({
        acumulado: '',
        intensidade: '',
        condicao_tempo: '',
        probabilidade: '',
        nivel: '',
        rajada_vento: '',
        direcao_vento: '',
        velocidade_vento: '',
        temperatura_min: '',
        temperatura_max: '',
        observacao: null,
        meteorologista: '',
        id_alvo: '',
        id_boletim: null,
        datahora_criacao: null,
        datahora_modificacao: null,
        campos_modificados: null,
        modificacao: null,
        conteudo_modificacao: null,
        nome_empresa: null,
        nome_plano: null,
        nome_alvo: null,
        nome_meteorologista: null,
        horizontes: null,
        status: null,
        rajada_vento_original: '',
        velocidade_vento_original: '',
        assinatura: '',
    });

    const [reportCardErrorsAux, setReportCardErrorsAux] = useState({
        acumulado: null,
        intensidade: null,
        condicao_tempo: null,
        probabilidade: null,
        nivel: null,
        rajada_vento: null,
        direcao_vento: null,
        velocidade_vento: null,
        temperatura_min: null,
        temperatura_max: null,
        observacao: null,
        id_alvo: null,
    });

    var alertsDataAux = alertsData;
    const toggleSwitchSelected = () => {
        if (!switchSelected) {
            let index = alertsDataAux
                .map((item) => item.id_alvo)
                .indexOf(target.id_alvo);
            if (index != -1) {
                setAllSelectedAux(false);
                setSwitchSelected(!switchSelected);
                alertsDataAux.splice(index, 1);
                alertsDataAux.push(reportCardAlert);
                setAlertsData(alertsDataAux);
            } else {
                alertsDataAux.push(reportCardAlert);
                setAllSelectedAux(false);
                setAlertsData(alertsDataAux);
                setSwitchSelected(!switchSelected);
            }

            let aux = reportCardErrors;
            let indexError = aux.map((item) => item.id_alvo).indexOf(target.id_alvo);
            if (indexError != -1) {
                aux.splice(indexError, 1);
                aux.push(reportCardErrors);
                setReportCardErrors(aux);
            }
            else {
                aux.push(reportCardErrors);
                setReportCardErrors(aux);
            }
        } else {
            setAllSelected(false);
            setAllSelectedAux(true);
            alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
            let aux = reportCardErrors;
            aux = aux.filter((item) => target.id_alvo != item.id_alvo);
            setReportCardErrors(aux);
            setAlertsData(alertsDataAux);
            setSwitchSelected(!switchSelected);
        }
        setReloadSwitchSelected(true);
    };

    /*const loadServices = async () => {
        if (planoId) {
            await getServicesByPlanID(planoId).then((response) => {
        if (response && response.length > 0) {
          servicesTypeValidate(response);
        }
      })
        }
    };*/

    /*const servicesTypeValidate = (services) => {
        if (services && services.length > 0) {
            let servicesList = [];
            for (let i = 0; i < services.length; i++) {
                if (services[i].tipo === 'Boletim Meteorológico') {
                    servicesList.push(services[i]);
                }
            }
            servicesMonitoringValidate(servicesList);
        }
    };

    const servicesMonitoringValidate = (servicesList) => {
        if (servicesList && servicesList.length > 0) {
            let servicesValidate = [];
            var today = moment().format('dddd');
            var now = moment().format('LT');
            var days = false;
            var hours = false;

            for (let i = 0; i < servicesList.length; i++) {
                let outputDays = servicesList[i].dias_monitoramento;

                if (today === 'segunda-feira' && outputDays.includes('Seg,')) {
                    days = true;
                    today = 'Seg';
                } else if (
                    today === 'terça-feira' &&
                    outputDays.includes('Ter,')
                ) {
                    days = true;
                    today = 'Ter';
                } else if (
                    today === 'quarta-feira' &&
                    outputDays.includes('Qua,')
                ) {
                    days = true;
                    today = 'Qua';
                } else if (
                    today === 'quinta-feira' &&
                    outputDays.includes('Qui,')
                ) {
                    days = true;
                    today = 'Qui';
                } else if (
                    today === 'sexta-feira' &&
                    outputDays.includes('Sex,')
                ) {
                    days = true;
                    today = 'Sex';
                } else if (today === 'sábado' && outputDays.includes('Sab,')) {
                    days = true;
                    today = 'Sab';
                } else if (today === 'domingo' && outputDays.includes('Dom,')) {
                    days = true;
                    today = 'Dom';
                }
                if (days === true) {
                    let tempInit = servicesList[i].datafunc_hora_inicio;
                    let tempFinal = servicesList[i].datafunc_hora_fim;
                    let auxInit =
                        tempInit[0] +
                        tempInit[1] +
                        tempInit[2] +
                        tempInit[3] +
                        tempInit[4];
                    let auxFinal =
                        tempFinal[0] +
                        tempFinal[1] +
                        tempFinal[2] +
                        tempFinal[3] +
                        tempFinal[4];
                    now >= auxInit && now <= auxFinal
                        ? (hours = true)
                        : (hours = false);

                    if (days && hours) {
                        servicesValidate.push(servicesList[i]);
                    }
                    setLoadTarget(true);
                }
            }
            if (servicesValidate && servicesValidate.length === 0) {
                setServicesStatus(false);
            }
        }
    };*/

    const loadUser = async () => {
        var loginToken = JSON.parse(localStorage.getItem('reeWAL57qe'));

        await getUserAuthenticated(loginToken).then((response) => {
            if (response.status === 200) {
                reportCardAlert.meteorologista = response.data.usuario.id;
                reportCardAlert.nome_meteorologista = response.data.usuario.first_name + ' ' + response.data.usuario.last_name;
                reportCardAlert.assinatura = response.data.usuario.assinatura;
            }
        });
    };

    /*const loadEmployeesTargets = async () => {
        await getEmployees_TargetByTargetID(target.id_alvo).then((response) => {
            if (response.length > 0) {
                var employeesList = [];
                for (let i = 0; i < response.length; i++) {
                    employeesList.push([
                        response[i].id_usuario,
                        response[i].prioridade,
                    ]);
                }
                loadContacts(employeesList);
            }
        });
    };

    const loadContacts = async (employeesData) => {
        for (let i = 0; i < employeesData.length; i++) {
            var aux = employeesData[i];
            var employeesList = [
                { nome: 'Contato impossível', prioridade: [''] },
                { nome: 'Contato não realizado', prioridade: [''] },
                {
                    nome: 'Não há contato telefônico no horário deste alerta',
                    prioridade: [''],
                },
            ];
            await getEmployees(aux[0]).then((response) => {
                if (response) {
                    employeesList.push({
                        nome: response.nome,
                        prioridade: aux[1],
                    });
                }
            });
        }
        setContacts(employeesList);
        setLoading(false);
    };*/

    const formatAccumulated = (accumulated, type) => {
        let div = parseInt(accumulated.split(' ')[0])/5;
        let fat1 = (Math.floor(div, -10)) * 5;
        let fat2 = div === 0? 5 : (Math.ceil(div, -10)) * 5;
        let formattedFat1 = fat1 < 10? "00" + fat1 : fat1 < 100? "0" + fat1 : fat1;
        let formattedFat2 = fat2 < 10? "00" + fat2 : fat2 < 100? "0" + fat2 : fat2;
        let output;

        if (type === 'accumulated') {
            formattedFat2 = formattedFat1 === '000'? '000mm' : formattedFat2 + 'mm'
            output = formattedFat1 + ' - ' + formattedFat2;
        }
        else{
            output = formattedFat1 < 0.4? "Em branco" :
                formattedFat1 >= 0.5 && formattedFat2 < 4.99? "Fraca" :
                formattedFat1 >= 5 && formattedFat2 < 14.99? "Moderada" :
                formattedFat1 >= 15 && formattedFat2 < 24.99? "Moderada - forte" :
                formattedFat1 >= 25 && formattedFat2 < 49.99? "Forte" :
                formattedFat1 >= 50 && formattedFat2 < 99.99? "Muito forte" :
                formattedFat1 >= 100? "Extrema" : "Em branco"
                formattedFat1 < 0.4? reportCardAlert.condicao_tempo = "Sem previsão de chuva" : reportCardAlert.condicao_tempo;
                formattedFat1 < 0.4? reportCardAlert.probabilidade = "Sem tempestades" : reportCardAlert.probabilidade; 
        }
        return output;
    }

    const formatWindSprint = (wind_sprint) => {
        let value;
        let sendValue;
        let formatedWind_sprint = wind_sprint?.includes("m/s")? parseFloat(wind_sprint?.split(' ')[0]) * 3.6 : parseFloat(wind_sprint?.split(' ')[0]);

        windSprint.map((option, index) => {
            if(index != 0 && index != 1){
                let windSprintAux1 = option.split('entre ');
                let windSprintAux2 = windSprintAux1[1].split('e');
                let windSprintFat1 = parseFloat(windSprintAux2[0].slice(0, 5))
                let windSprintFat2 = parseFloat(windSprintAux2[1].slice(0, 5))
                
                if(formatedWind_sprint >= windSprintFat1 && formatedWind_sprint <= windSprintFat2){
                    value = option;
                }
            }
            else{
                value = windSprint[0]
            }
        })

        if(wind_sprint?.includes("m/s") && value != windSprint[0]){
            let windSprintAux1 = value.split('entre ');
            let windSprintAux2 = windSprintAux1[1].split('e');
            let windSprintFat1 = parseFloat(windSprintAux2[0].slice(0, 5)) / 3.6;
            let windSprintFat2 = parseFloat(windSprintAux2[1].slice(0, 5)) / 3.6;
            sendValue = windSprintAux1[0] + "entre " + windSprintFat1.toFixed(2) + " m/s e " + windSprintFat2.toFixed(2) + " m/s";
            reportCardAlert.rajada_vento_original = sendValue;
        }

        return value;
    }

    const formatWindSpeed = (wind_speed) => {
        let formatedWind_sprint = wind_speed?.includes("m/s")? parseFloat(wind_speed?.split(' ')[0]) * 3.6 : parseFloat(wind_speed?.split(' ')[0]);

        return formatedWind_sprint;
    }

    useEffect(() => {
        reportCardAlert.acumulado = preset.acumulado != ''? preset.acumulado : reportCardAlert.acumulado;
        reportCardAlert.intensidade = preset.intensidade != ''? preset.intensidade : reportCardAlert.intensidade;
        reportCardAlert.condicao_tempo = preset.condicao_tempo != ''? preset.condicao_tempo : reportCardAlert.condicao_tempo;
        reportCardAlert.probabilidade = preset.probabilidade != ''? preset.probabilidade : reportCardAlert.probabilidade;
        reportCardAlert.direcao_vento = preset.direcao_vento != ''? preset.direcao_vento : reportCardAlert.direcao_vento;
        reportCardAlert.temperatura_min = preset.temperatura_min != ''? preset.temperatura_min : reportCardAlert.temperatura_min;
        reportCardAlert.temperatura_max = preset.temperatura_max != ''? preset.temperatura_max : reportCardAlert.temperatura_max;
        reportCardAlert.nivel = preset.nivel != ''? preset.nivel : reportCardAlert.nivel;
        reportCardAlert.velocidade_vento = preset.velocidade_vento != ''? preset.velocidade_vento : reportCardAlert.velocidade_vento;
        reportCardAlert.rajada_vento = preset.rajada_vento != ''? preset.rajada_vento : reportCardAlert.rajada_vento;
        reportCardAlert.observacao = preset.observacao != ''? preset.observacao : reportCardAlert.observacao;
        setReloadModal(true);
    }, [preset.preset]);

    useEffect(() => {
        if (target) {
            if(loadedReportCard){
                reportCardAlert.nivel = reportCardData.nivel;
                reportCardAlert.condicao_tempo = reportCardData.condicao_tempo;
                reportCardAlert.meteorologista = reportCardData.meteorologista;
                reportCardAlert.nome_meteorologista = reportCardData.nome_meteorologista;
                reportCardAlert.assinatura = reportCardData.assinatura;
                reportCardAlert.observacao = reportCardData.observacao;
                reportCardAlert.probabilidade = reportCardData.probabilidade;
                reportCardAlert.acumulado =  reportCardData.acumulado;
                reportCardAlert.intensidade =  reportCardData.intensidade;
            }
            else{
                reportCardAlert.acumulado = formatAccumulated(reportCardData.acumulado, "accumulated");
                reportCardAlert.intensidade = formatAccumulated(reportCardData.acumulado, "intensity");
            }

            reportCardAlert.id_alvo = target.id_alvo;
            reportCardAlert.id_boletim = reportCardData.id_boletim;
            reportCardAlert.campos_modificados = reportCardData.campos_modificados;
            reportCardAlert.modificacao = reportCardData.modificacao;
            reportCardAlert.errata = reportCardData.errata;
            reportCardAlert.especial = reportCardData.especial;
            reportCardAlert.conteudo_modificacao = reportCardData.conteudo_modificacao;
            reportCardAlert.nome_empresa = empresaNome;
            reportCardAlert.vigencia = reportCardData.vigencia;
            reportCardAlert.nome_plano = planoNome;
            reportCardAlert.nome_alvo = alvoNome;
            reportCardAlert.horizontes = reportCardData.horizontes;
            reportCardAlert.datahora_criacao = reportCardData.datahora_criacao;
            reportCardAlert.datahora_modificacao = reportCardData.datahora_modificacao;
            reportCardAlert.temperatura_min = reportCardData.temperatura_min[0] + reportCardData.temperatura_min[1];
            reportCardAlert.temperatura_max = reportCardData.temperatura_max[0] + reportCardData.temperatura_max[1];
            reportCardAlert.direcao_vento = "De " + reportCardData.direcao_vento;
            reportCardAlert.velocidade_vento_original = reportCardData.velocidade_vento; 
            reportCardAlert.rajada_vento_original = reportCardData.rajada_vento;
            reportCardAlert.velocidade_vento = formatWindSpeed(reportCardData.velocidade_vento);
            reportCardAlert.rajada_vento = formatWindSprint(reportCardData.rajada_vento);

            //loadServices();
            loadUser();
            //loadEmployeesTargets();
        }
    }, []);

    useEffect(() => {
        if (allSelected) {
          let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
          if (index != -1) {
            setAllSelectedAux(false);
            setSwitchSelected(allSelected);
          }
          else {
            alertsDataAux.push(reportCardAlert);
            setAllSelectedAux(false);
            setAlertsData(alertsDataAux);
            setSwitchSelected(allSelected);
            let aux = reportCardErrors;
            aux.push(reportCardErrorsAux);
            setReportCardErrors(aux);
          }
        }
        else if (!allSelected && !allSelectedAux) {
          alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
          setAlertsData(alertsDataAux);
          setSwitchSelected(allSelected);
          let aux = reportCardErrors;
          aux = aux.filter((item) => target.id_alvo!= item.id_alvo);
          setReportCardErrors(aux);
        }
      }, [allSelected])

    function isHundred(n) {
        return n >= 100;
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    /*useEffect(() => {
        if (!servicesStatus) {
            let aux = statusTargetSelected;
            if (!aux.includes((nome) => nome === target.nome)) {
                aux.push(target.nome);
                setStatusTargetSelected(aux);
                setReloadStatusTargetSelected(true);
            }
        }
    }, [switchSelected]);*/

    useEffect(() => {
            if (switchSelected) {
                if (reportCardAlert.acumulado === '') {
                    reportCardErrorsAux.acumulado = 'Campo Acumulado é obrigatório!';
                } else {
                    reportCardErrorsAux.acumulado = null;
                }
                if (reportCardAlert.condicao_tempo === '') {
                    reportCardErrorsAux.condicao_tempo = 'Campo Condição do tempo é obrigatório!';
                 } else {
                    reportCardErrorsAux.condicao_tempo = null;
                }
                if (reportCardAlert.probabilidade === '') {
                    reportCardErrorsAux.probabilidade = 'Campo Probabilidade de raios é obrigatório!';
                 } else {
                    reportCardErrorsAux.probabilidade = null;
                }
                if (reportCardAlert.intensidade === '') {
                    reportCardErrorsAux.intensidade = 'Campo Intensidade é obrigatório!';
                 } else {
                    reportCardErrorsAux.intensidade = null;
                }
                if (reportCardAlert.nivel === '') {
                    reportCardErrorsAux.nivel = 'Campo Nível da tempestade é obrigatório!';
                 } else {
                    reportCardErrorsAux.nivel = null;
                }
                if (reportCardAlert.rajada_vento === '') {
                    reportCardErrorsAux.rajada_vento = 'Campo Rajadas de vento é obrigatório!';
                 } else {
                    reportCardErrorsAux.rajada_vento = null;
                }
                if (reportCardAlert.direcao_vento === '') {
                    reportCardErrorsAux.direcao_vento = 'Campo Vento é obrigatório!';
                } else {
                    reportCardErrorsAux.direcao_vento = null;
                }
                if (reportCardAlert.velocidade_vento === '') {
                    reportCardErrorsAux.velocidade_vento = 'Campo Velocidade do vento é obrigatório!';
                 } else {
                    reportCardErrorsAux.velocidade_vento = null;
                }
                if (reportCardAlert.temperatura_min === '') {
                    reportCardErrorsAux.temperatura_min = 'Campo Temperatura mínima é obrigatório!';
                 } else {
                    reportCardErrorsAux.temperatura_min = null;
                }
                if (reportCardAlert.temperatura_max === '') {
                    reportCardErrorsAux.temperatura_max = 'Campo Temperatura máxima é obrigatório!';
                 } else {
                    reportCardErrorsAux.temperatura_max = null;
                }
                  
                var index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
                
                if (index != -1) {
                  let aux = alertsDataAux;
                  aux.splice(index, 1);
                  aux.push(reportCardAlert);
                  setAlertsData(aux);
                  let aux2 = reportCardErrors;
                  aux2.splice(index, 1);
                  aux2.push(reportCardErrorsAux);
                  setReportCardErrors(aux2);
                }
                else {
                  let aux = alertsDataAux;
                  aux.push(reportCardAlert);
                  setAlertsData(aux);
                  let aux2 = reportCardErrors;
                  aux2.push(reportCardErrorsAux);
                  setReportCardErrors(aux2);
        
                }
        } 
        setReloadErrorMessage(false);
        setCaractersRemaning(reportCardAlert.observacao != null? reportCardAlert.observacao.length : 0);

    }, [
        reportCardAlert?.intensidade,
        reportCardAlert?.nivel,
        reportCardAlert?.probabilidade,
        reportCardAlert?.temperatura_min,
        reportCardAlert?.temperatura_max,
        reportCardAlert?.condicao_tempo,
        reportCardAlert?.acumulado,
        reportCardAlert?.rajada_vento,
        reportCardAlert?.velocidade_vento,
        reportCardAlert?.observacao,
        preset.preset,
        switchSelected
    ]);

    return (
        <div style={{ backgroundColor: 'white' }}>
            <div className={classes.switchComponent}>
                <Switch
                    checked={switchSelected}
                    onChange={() => toggleSwitchSelected()}
                    value={switchSelected}
                    size="small"
                    classes={{
                        colorPrimary: switchStyle.switch_primary
                    }}
                    color={'primary'}
                />

                <div style={{ marginLeft: '15px' }}>
                    <Typography
                        variant="subtitle1"
                        style={fonts.targetTitle}                        
                    >
                        {selectedHorizon == "12 horas"?
                            moment().format("DD/MM/YYYY") + " - 18:00 LT - " + target.nome :
                            selectedHorizon == "24 horas"?
                                moment().format("DD/MM/YYYY") + " - 06:00 LT - " + target.nome :
                                selectedHorizon == "48 horas"?
                                    moment(today, "DD/MM/YYYY").add(1, 'days').format("DD/MM/YYYY") + " - 18:00 LT - " + target.nome :
                                    selectedHorizon == "72 horas"?
                                        moment(today, "DD/MM/YYYY").add(2, 'days').format("DD/MM/YYYY") + " - 18:00 LT - " + target.nome :
                                        target.nome
                        }
                    </Typography>
                </div>
            </div>

            
            <div className={classes.inputLine}>
                <Autocomplete
                    id="condition"
                    freeSolo
                    value={reportCardAlert?.condicao_tempo == null? '' : reportCardAlert?.condicao_tempo}
                    inputValue={reportCardAlert?.condicao_tempo == null? '' : reportCardAlert?.condicao_tempo}
                    disableClearable
                    onInputChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, condicao_tempo: newValue })}
                    onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, condicao_tempo: newValue })}
                    className={classes.inputOne}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={weatherCondition}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Condição do tempo"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                            event.stopPropagation();
                          }
                        }} />}
                />

                <Autocomplete
                    id="intensity"
                    freeSolo={false}
                    value={reportCardAlert?.intensidade == null? '' : reportCardAlert?.intensidade}
                    inputValue={reportCardAlert?.intensidade == null? '' : reportCardAlert?.intensidade}
                    disableClearable
                    onChange={(_, newValue) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            intensidade: newValue,
                        })
                    }
                    className={classes.inputTwo}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={[
                        'Em branco',
                        'Fraca',
                        'Moderada',
                        'Moderada a forte',
                        'Forte',
                        'Muito forte',
                        'Extrema',
                    ]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Intensidade da chuva"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                }
                            }}
                        />
                    )}
                />
            </div>

            <div className={classes.inputLine}>
                <InputMask
                    mask="999 - 999 mm"
                    value={reportCardAlert?.acumulado == null? '' : reportCardAlert?.acumulado}
                    onChange={(e) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            acumulado: e.target.value,
                        })
                    }
                    disabled={false}
                    maskChar=" "
                >
                    {() => (
                        <TextField
                            id="acumuladoInit"
                            className={classes.inputThree}
                            variant="outlined"
                            label="Acumulado"
                            inputProps={{
                                style: fonts.formsFieldHeight1,
                            }}
                            InputLabelProps={{
                                style: fonts.formsLabel,
                                shrink: true,
                            }}
                        />
                    )}
                </InputMask>

                <Autocomplete
                    id="lightning_prob"
                    freeSolo={false}
                    value={reportCardAlert?.probabilidade == null? '' : reportCardAlert?.probabilidade }
                    inputValue={reportCardAlert?.probabilidade == null? '' : reportCardAlert?.probabilidade }
                    disableClearable
                    onChange={(_, newValue) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            probabilidade: newValue,
                        })
                    }
                    className={classes.inputFour}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={['Em branco', 'Sem tempestades', 'Baixa', 'Média', 'Alta']}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Prob. Tempestade"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                }
                            }}
                        />
                    )}
                />

                <Autocomplete
                    id="level"
                    freeSolo={false}
                    value={reportCardAlert?.nivel == null? '' : reportCardAlert?.nivel}
                    inputValue={reportCardAlert?.nivel == null? '' : reportCardAlert?.nivel}
                    disableClearable
                    onChange={(_, newValue) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            nivel: newValue,
                        })
                    }
                    className={classes.inputFive}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={['T1', 'T2', 'T3', 'T4', 'Em branco']}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Nível"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                    event.stopPropagation();
                                }
                            }}
                        />
                    )}
                />

                <TextField
                    id="temp_min"
                    className={classes.inputSix}
                    value={reportCardAlert?.temperatura_min == null? '' : reportCardAlert?.temperatura_min}
                    onChange={(e) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            temperatura_min: e.target.value,
                        })
                    }
                    variant="outlined"
                    type="tel"
                    label="Temp. Mínima"
                    inputProps={{ style: fonts.formsFieldHeight1 }}
                    InputLabelProps={{
                        style: fonts.formsLabel,
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{ alignSelf: 'end' }}
                            >
                                °C
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    id="temperatura_max"
                    className={classes.inputSeven}
                    variant="outlined"
                    value={reportCardAlert?.temperatura_max == null? '' : reportCardAlert?.temperatura_max}
                    onChange={(e) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            temperatura_max: e.target.value,
                        })
                    }
                    label="Temp. Máxima"
                    type="tel"
                    inputProps={{ style: fonts.formsFieldHeight1 }}
                    InputLabelProps={{
                        style: fonts.formsLabel,
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{ alignSelf: 'end' }}
                            >
                                °C
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <div className={classes.inputLine}>
                <Autocomplete
                    id="wind_sprint"
                    freeSolo
                    value={reportCardAlert?.rajada_vento == null? '' : reportCardAlert?.rajada_vento}
                    inputValue={reportCardAlert?.rajada_vento == null? '' : reportCardAlert?.rajada_vento}
                    disableClearable
                    onInputChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, rajada_vento: newValue })}
                    onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, rajada_vento: newValue })}
                    className={classes.inputEight}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={windSprint}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Rajadas de vento"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                            event.stopPropagation();
                          }
                        }} />}
                />

                <InputMask
                    mask={isInt(reportCardAlert?.velocidade_vento)? 
                        isHundred(reportCardAlert?.velocidade_vento)? 
                            "999 km/h" :
                            "99 km/h" :
                                isHundred(reportCardAlert?.velocidade_vento)?
                                    "999.9 km/h" :
                                    "99.9 km/h"
                    }
                    value={reportCardAlert?.velocidade_vento == null? '' : reportCardAlert?.velocidade_vento}
                    onChange={(e) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            velocidade_vento: e.target.value,
                        })
                    }
                    disabled={false}
                    maskChar=" "
                >
                    {() => (
                        <TextField
                            id="velocidade_vento"
                            className={classes.inputNine}
                            variant="outlined"
                            label="Vel. do vento"
                            inputProps={{
                                style: fonts.formsFieldHeight1,
                            }}
                            InputLabelProps={{
                                style: fonts.formsLabel,
                                shrink: true,
                            }}
                        />
                    )}
                </InputMask>


                <Autocomplete
                    id="wind"
                    freeSolo
                    value={reportCardAlert?.direcao_vento == null? '' : reportCardAlert?.direcao_vento }
                    inputValue={reportCardAlert?.direcao_vento == null? '' : reportCardAlert?.direcao_vento }
                    disableClearable
                    onInputChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, direcao_vento: newValue })}
                    onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, direcao_vento: newValue })}
                    className={classes.inputTen}
                    style={fonts.formsFieldHeight1}
                    size="small"
                    options={windDirection}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Direção do vento"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onKeyDown={(event) => {
                          if (event.key === 'Backspace') {
                            event.stopPropagation();
                          }
                        }} />}
                />
            </div>

            <div className={classes.inputLine}>
                <TextField
                    id="comments"
                    label="Observações"
                    multiline
                    rows={2}
                    value={reportCardAlert?.observacao == null? '' : reportCardAlert?.observacao}
                    onChange={(e) =>
                        setReportCardAlert({
                            ...reportCardAlert,
                            observacao: e.target.value,
                        })
                    }
                    className={classes.inputEleven}
                    shrink={true}
                    variant="outlined"
                    color="primary"
                    inputProps={{
                        style: fonts.formsFieldHeight2,
                        maxLength: 50,
                    }}
                    InputLabelProps={{
                        style: fonts.formsLabel,
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment:
                          <span className={classes.endAdornment}>{caractersRemaning + "/200"}</span>
                    }}
                />
            </div>
            <Divider classes={{ root: switchStyle.divider }} />
        </div>
    );

    //}

    /*else if (loading && loadTarget) {
    return (
      <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" />
    )
  }*/
};

FieldsComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    preset: PropTypes.any,
    target: PropTypes.any,
    planoId: PropTypes.any,
    alertsData: PropTypes.any,
    setAlertsData: PropTypes.func,
    setReloadSwitchSelected: PropTypes.func.isRequired,
    setReloadErrorMessage: PropTypes.func.isRequired,
    setReloadModal: PropTypes.string.isRequired,
    allSelected: PropTypes.any,
    setAllSelected: PropTypes.func.isRequired,
    allSelectedAux: PropTypes.any,
    setAllSelectedAux: PropTypes.func.isRequired,
    reportCardErrors: PropTypes.any.isRequired,
    setReportCardErrors: PropTypes.func.isRequired,
    reportCardData: PropTypes.any.isRequired,
    statusTargetSelected: PropTypes.any.isRequired,
    setStatusTargetSelected: PropTypes.func.isRequired,
    setReloadStatusTargetSelected: PropTypes.func.isRequired,
    reloadTargets: PropTypes.any.isRequired,
    setReloadTargets: PropTypes.func.isRequired,
    empresaId: PropTypes.any.isRequired,
    empresaNome: PropTypes.string.isRequired,
    planoNome: PropTypes.string.isRequired,
    alvoNome: PropTypes.string.isRequired,
    selectedHorizon: PropTypes.string.isRequired,
    loadedReportCard: PropTypes.bool,
};

export default withStyles(styles)(FieldsComponent);
