import axios from 'axios';

// Uso geral na aplicação, para todas as requisições de CRUD.
// Exceto quando há uma grande quantidade de dados, neste caso
// a api java é utilizada.
export const djangoApi = axios.create({
    baseURL: 'https://api.monitoramentonucleo.com.br/',
});

export const djangoApiHist = axios.create({
  baseURL: 'https://dadosdescargas.monitoramentonucleo.com.br/',
});

// Api utilizada para realizar as requisiçoes em ./monitoring/initialLoad.js
// Busca todas as descargas e imagens de satélite.
export const javaApi = axios.create({
    baseURL: 'https://dadosdescargas.monitoramentonucleo.com.br/',
    //baseURL: 'https://us-east-1.aws.data.mongodb-api.com/app/application-0-sjrzk/endpoint/',
});

// Api com os dados de radar e satélite.
export const lambdaApi = axios.create({
  //baseURL: 'https://lambda.monitoramentonucleo.com.br/'
  baseURL: 'https://mapas.monitoramentonucleo.com.br/'
});

// Api com os dados de todos os feriados do ano pesquisado.
export const brasilApi = axios.create({
  baseURL: 'https://brasilapi.com.br/api/feriados/v1/',
});

export const setApiHeaders = (token) => {
  djangoApi.defaults.headers.common['Authorization'] = token;
  djangoApi.defaults.headers['Authorization'] = token;
  djangoApiHist.defaults.headers.common['Authorization'] = token;
  djangoApiHist.defaults.headers['Authorization'] = token;
  javaApi.defaults.headers.common['Authorization'] = token;
  javaApi.defaults.headers['Authorization'] = token;
  lambdaApi.defaults.headers.common['Authorization'] = token;
  lambdaApi.defaults.headers['Authorization'] = token;
}
