const styles = theme => ({
  servicesCard: {
    width: '35px',
    height: '35px',
    borderRadius: '5px',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    padding: "8px, 15px, 8px, 15px",
    border: "2px solid",
  },
});

export default styles;