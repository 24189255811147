/* Libraries */
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { useState } from "react";
import styles from "./tablestyle-jss";
import fontStyles from 'styles/fonts-jss';
/* Utils */
import persistRegister from '../../../utils/persistRegisterFlow';
import { persistViewPageNavigation } from '../../../utils/persistViewPageNavigation';
import {StyledIconButtonDefault } from './Table-jss';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';


const TableFooter = (props) => {
    const { classes, filteredData, pageSizeInput, setPageSizeInput, setPagination, setLoadPagination, changePage, verifyChangePage, pagination } = props;
    const fonts = fontStyles();
    const history = useHistory();

    const handleChangePageSize = (event) => {
        setPageSizeInput(event);
        const value = Math.max(5, Math.min(25, Number(event)));
        setPagination({
          actualPage: 1,
          pageSize: value,
          maxPages: Math.ceil(filteredData.length/value)
        })
        setLoadPagination(true);
      }
   
    
    return(
        <div className={classes.tableFooterContainer}>
            <div className={classes.leftSideFooterContainer}>
                <span style={fonts.footerText}>Itens por página:</span>
                <Input
                    id="searchTargets"
                    value={pageSizeInput}
                    onChange={(e) => handleChangePageSize(e.target.value)}
                    type='number'
                    style={fonts.footerText}
                    className={classes.searchFooter}
                />
            </div>
            
            <div className={classes.centerFooterContainer}>
                <span style={fonts.footerText}>{filteredData.length} itens no total</span>
            </div>
            <div className={classes.rightSideFooterContainer}>                
                 <StyledIconButtonDefault onClick={() => changePage(-1)} disabled={!verifyChangePage(-1)}>
                    <IoMdArrowDropleft style={{width: "22px", height: "22px"}} />
                </StyledIconButtonDefault>

                <span style={fonts.footerText} className={classes.pagination}>Página: {pagination.actualPage}/{pagination.maxPages}</span>
                
                <StyledIconButtonDefault onClick={() => changePage(1)} disabled={!verifyChangePage(1)}>
                    <IoMdArrowDropright style={{width: "22px", height: "22px"}} />
              </StyledIconButtonDefault>
            </div>
        </div>
    )
}


TableFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    pageSizeInput: PropTypes.any.isRequired,
    setPageSizeInput: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    verifyChangePage: PropTypes.func.isRequired,
    pagination: PropTypes.any.isRequired,
    filteredData: PropTypes.any.isRequired,
    setLoadPagination: PropTypes.func.isRequired,
  };
  
  export default withStyles(styles)(TableFooter);