const styles = theme => ({

    /* General */
    textFieldSmall: {
        width: `60px`,
    },
    
    textFieldMedium: {
        width: `80px`,
    },
    
    textFieldDefault: {
        width: `316px`,
        fontSize: '25px',
        '@media (max-width: 400px)': {
            width: '230px',
        },
    },
    
    modalComponent: {
        width: "100vw",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    
    dateHourContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent:"center"
    },
    dialogContentTextContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent:"center",
        flexDirection: "column"
    },
    dateHourContainerStart: {
        display: "flex",
        alignItems: "center",
    },
    
    hourText: {
        marginLeft: `7px`,
        marginRight: `7px`,
    },
    
    errorMessage: {
        fontSize: `12px`,
        color: "red",
    },
    
    errorMessageMgLf: {
        fontSize: `12px`,
        color: "red",
        marginLeft: `10px`,
    },
    
    errorContainer: {
        display: "flex",
        justifyContent:"center"
    },
    
    toggleGroup: {
        marginTop: `10px`,
    },
    
    buttonContainer: {
        marginRight: `18px`,
        marginBottom: `18px`,
    },
    
    balloonContainer: {
        padding: `20px`,
        border: `1px solid`,
        borderColor: theme.palette.menu.mediumGrey,
        backgroundColor: "white",
        borderRadius: `45px`,
        position: 'absolute',
        width: '220px',
        height: '165px',
        display: "flex",
        flexDirection: "column",
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
    
    ballonDetail: {
        content: '""',
        width: `25px`,
        height: `25px`,
        display: "block",
        position: 'absolute',
        left: `80px`,
        border: `1px solid`,
        borderColor: `transparent ${theme.palette.menu.mediumGrey} ${theme.palette.menu.mediumGrey} transparent`,
        backgroundColor: 'white',
        transform: `rotate(45deg)`,
    },
    
    textContainer: {
        alignSelf: "center",
    },
    
    tableIcon: {
        color: "black",
        padding: "2px",
    },
    
    servicesTitleDiv:{
        width: "100%",
        height: "14%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        borderBottom: "1px solid #848484",
        boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.15)",
    },

    registerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      marginTop: '15px',
      borderBottom: '1px solid black',
    },

    dataContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'end',
      width: '70%',
    },

    registerContentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      width: '100%',
    },

    registerItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '1%',
      minHeight: '100px',
      '@media (max-width: 400px)': {
          minHeight: '50px',
      },
    },

    selectFieldExtraLarge: {
      width: `200px`,
      marginTop: '8px',
    },

    showChartButton: {
      padding: '11px',
      borderRadius: '5px',
      backgroundColor: '#DF7600',
      '&:hover': {
          backgroundColor: '#FFB703',
      },
      marginTop: '19px',
      marginLeft: '40px',
    },

    showChartButtonContainer: {
      marginBottom: '14px',
    },
    
    servicesBottomDiv:{
        width: "100%",
        height: "14%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",
        borderTop: "1px solid #848484",
        boxShadow: "4px 0px 6px 3px rgba(0, 0, 0, 0.15)",
    },
    
    servicesTitleDivBigger:{
        width: "100%",
        height: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    
    servicesTitle:{
       marginLeft: "5%",
    },
    
    outlineButtonText: {
        color: theme.palette.text.strongBlue,
        textTransform: "uppercase",
    },
    
    outlineButtonIcon: {
        color: theme.palette.text.strongBlue,
    },
    
    outlineButton:{
      cursor: "pointer",
      border: "2px solid",
      borderColor: theme.palette.menu.strongBlue,
      borderRadius: "5px",
      padding: "6px 14px 6px 14px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      '& $outlineButtonText': {
        color: theme.palette.menu.strongBlue,
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.strongBlue,
      },
      '&:hover': {
        background: theme.palette.menu.strongBlue,
        
        '& $outlineButtonText': {
          color: theme.palette.menu.white,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.white,
        },
      },
    },
    
    outlineButton1:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "5px",
        backgroundColor:"transparent",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        fontFamily: "Segoe UI",
        fontWeight: "700",
        fontSize: "14px",
        alignItems: "center",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: theme.palette.menu.strongBlue,
          color: "white",
          '& $outlineButtonText': {
            color: theme.palette.menu.white,
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
      },
      
      outlineButton2:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: "#008000",
        borderRadius: "5px",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: theme.palette.menu.strongBlue,
          '& $outlineButtonText': {
            color: theme.palette.menu.white,
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
      },
    
      noBorderButton:{
        cursor: "pointer",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: "gray",
          borderRadius:"40px",
          '& $outlineButtonText': {
            color: theme.palette.menu.white,
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
      },
    
    greenChip: {
        backgroundColor: '#67E16480',
        color: '#023047',
        width: '65px',
        height: '45px',
        borderRadius: '3px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        fontSize: "12px",
    },
    
    redChip: {
        backgroundColor: '#FF000080',
        color: '#023047',
        width: '65px',
        height: '45px',
        borderRadius: '3px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        fontSize: "12px",
    },
    
    yellowChip: {
        backgroundColor: '#FFB703B2',
        color: '#023047',
        width: '65px',
        height: '45px',
        borderRadius: '3px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        fontSize: "12px",
    },
    
    greyChip: {
        backgroundColor: '#E3E3E3',
        color: '#023047',
        width: '65px',
        height: '45px',
        borderRadius: '3px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        fontSize: "12px",
    },
    servicesCard: {
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "10px"
    },
    textButtonCancel:{
      fontFamily: "Segoe UI",
      color: "black",
      fontWeight: "700",
      fontSize: "14px",
      '&:hover': {
        color: "white",
      },
    },

    });
    
    export default styles;