import React, { memo } from 'react';

// Libs
import moment from 'moment';

// Components
import { Source, Layer } from 'react-map-gl';

// Hooks
import {useRadar} from 'Context/RadarContext';

const RadarLayerComponent = () => {
    const {
      radar,
      showRadar,
      time,
      opacidadeControl
    } = useRadar();

    if (radar === undefined) return <></>

    const sources = radar?.map((radars, index) => {
      const p = radars.filter(radar => {
        return moment().diff(moment(new Date(radar.data)), 'minutes') >= time
      })
      if (!p.length) return undefined;
      const rad = p[0];

      return(
          <Source
              id={`radar-${rad.nome}`}
              type="image"
              key={JSON.stringify(radar)+rad.nome}
              // Substituir essa URL pela URL contida no array ImagePath
              // junto com o indice vindo do componente pai (App.js)
              url={rad.path}
              coordinates={[
                [Number(rad.lon_min), Number(rad.lat_max)],
                [Number(rad.lon_max), Number(rad.lat_max)],
                [Number(rad.lon_max), Number(rad.lat_min)],
                [Number(rad.lon_min), Number(rad.lat_min)],
            ]}
          >
              <Layer
                  id={`radar-${rad.nome}`}
                  source={'radar'}
                  type="raster"
                  paint={{
                    'raster-fade-duration': 0,
                    'raster-opacity': opacidadeControl,
                }}
                  layout={{
                      visibility: showRadar ? 'visible' : 'none',
                  }}
              />
          </Source>
    )})

    return (
      <>
        {sources}
      </>
    )
};

const RadarLayer = memo(RadarLayerComponent);
export default RadarLayer;
