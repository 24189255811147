const fontFamily = ['Philosopher', 'Roboto', 'sans-serif'];
900;

const styles = (theme) => ({
    /* Template */
    root: {
        flexGrow: 1,
    },

    /* General */
    textFieldDefault: {
        width: `316px`,
        fontSize: '25px',
        '@media (max-width: 400px)': {
            width: '230px',
        },
    },

    textFieldComments: {
        width: `100%`,
        fontSize: '25px',
        marginTop: '0px',
    },

    textFieldObservations: {
        width: `100%`,
        fontSize: '25px',
        marginTop: '0px',
    },
  greenChip: {
    backgroundColor: "#67E164",
    width: "60px",
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
  },

  redChip: {
    backgroundColor: "#FF0000",
    width: "60px",
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px"
  },


    textFieldMedium: {
        width: `180px`,
        marginBottom: '10px',
    },

    switch: {
        marginRight: '5px',
        marginTop: '2px',
    },

    textFieldSmall1: {
        width: `125px`,
        marginRight: `10px`,
    },

    addButton: {
        fontSize: `10px`,
        marginLeft: `18%`,
    },

    addButton1: {
        fontSize: `10px`,
        marginLeft: `41%`,
    },

    addButton2: {
        fontSize: `10px`,
        width: '14%',
        marginLeft: `20%`,
        marginTop: '3px',
        marginBottom: '10px',
    },

    textFieldSmall: {
        width: `62px`,
    },

    selectFieldLarge1: {
        width: `140px`,
        marginTop: `10px`,
        marginLeft: `80px`,
    },

    selectFieldLarge: {
        width: `185px`,
        marginTop: `20px`,
    },

    selectFieldExtraLarge: {
        width: `200px`,
        marginTop: `10px`,
    },

    selectFieldExtraLarge1: {
        width: `300px`,
        '@media (max-width: 400px)': {
            width: '230px',
        },
    },

    selectFieldMedium: {
        width: `100px`,
        marginTop: `10px`,
    },

    servicesCard: {
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
      },

    selectFieldSmall: {
        width: `62px`,
        marginTop: `0px`,
    },

    titleContainer: {
        marginLeft: '5%',
    },

    gridContainer: {
        marginLeft: '8%',
    },

    buttonContainer: {
        marginTop: `7%`,
        marginBottom: `5%`,
        marginRight: `8%`,
        display: 'flex',
        justifyContent: 'flex-end',
    },

    selectContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    dateHourTextMgTp: {
        fontSize: `14px`,
        marginTop: `10px`,
    },

    hourText: {
        fontSize: `14px`,
        marginLeft: `7px`,
        marginRight: `7px`,
    },

    marginLeft: {
        marginLeft: `20px`,
    },

    activeText: {
        color: '#18328F',
    },

    inactiveText: {
        color: '#4a4a4a',
    },

    toggleGroup: {
        marginTop: `10px`,
    },

    marginTop: {
        marginTop: `10px`,
    },

    checkboxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: `20px`,
    },

    otherContainerInactive: {
        display: 'none',
    },

    otherContainerActive: {
        display: 'flex',
        flexDirection: 'column',
    },

    errorMessage: {
        fontSize: `12px`,
        color: 'red',
    },

    errorMessageMgLf: {
        fontSize: `12px`,
        color: 'red',
        marginLeft: `10px`,
    },

    errorMessage1: {
        fontSize: `12px`,
        color: 'red',
        marginLeft: `78px`,
    },

    activeTable: {
        maxWidth: `350px`,
        marginLeft: '8%',
    },

    inactiveTable: {
        display: 'none',
    },

    tableHead: {
        backgroundColor: '#18328F',
    },

    tableHeadText: {
        color: 'white',
        padding: '10px',
    },

    tableRowWhite: {
        backgroundColor: '#efefef',
        padding: '6px',
    },

    tableRowGrey: {
        backgroundColor: '#d6d6d6',
        padding: '6px',
    },

    filePresentContainer: {
        marginTop: '10px',
        marginBottom: '50px',
    },

    filePresentClose: {
        width: '16px',
        height: '16px',
        marginLeft: '80%',
        position: 'static',
    },

    fileRemoveContainer: {
        cursor: 'pointer',
        width: '100px',
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
    },

    uploadButtonContainer: {
        marginTop: '10px',
    },

    fileNameText: {
        fontSize: '12px',
    },

    viewServicesButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: '15px',
        color: '#848484',
    },

    viewServicesText: {
        fontSize: '14px',
        color: '#18328F',
    },

    registerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        marginTop: '15px',
    },
    buttonSelected: {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.white,
        '&:hover': {
            backgroundColor: theme.palette.menu.strongBlue,
            color: theme.palette.menu.white,
        },
    },

    registerContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
    },

    dateField: {
        width: '70%',
    },

    registerItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginRight: '5%',
        minHeight: '100px',
        marginTop: '1%',
        '@media (max-width: 400px)': {
            minHeight: '50px',
        },
    },

    registerItemContainer1: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginRight: '5%',
        minHeight: '80px',
        marginTop: '1%',
        '@media (max-width: 400px)': {
            minHeight: '50px',
        },
    },

    registerButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '5%',
        flexWrap: 'wrap',
    },

    navigationButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 515px)': {
            marginTop: '20px',
        },
    },

    dateHourContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '60px',
        width: '200px',
    },
    MuiInput: { formControl: { 'label + &': { marginTop: '0px' } } },
    outlineButtonText: {
        color: theme.palette.text.strongBlue,
        textTransform: 'uppercase',
    },

    outlineButtonIcon: {
        color: theme.palette.text.strongBlue,
    },

    outlineButton: {
        cursor: 'pointer',
        border: '2px solid',
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: '5px',
        padding: '6px 14px 6px 14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        '& $outlineButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            '& $outlineButtonText': {
                color: theme.palette.menu.white,
            },
            '& $outlineButtonIcon': {
                color: theme.palette.menu.white,
            },
        },
    },

    disabledOutlineButton: {
        cursor: "not-allowed",
        border: '2px solid',
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: '5px',
        padding: '6px 14px 6px 14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        '& $outlineButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
            color: theme.palette.menu.strongBlue,
        },
    },

    defaultButtonText: {
        color: theme.palette.text.strongBlue,
        textTransform: 'uppercase',
    },

    defaultButtonIcon: {
        color: theme.palette.text.strongBlue,
    },

    endAdornment: {
        color: theme.palette.menu.mediumGrey,
    },

    defaultButton: {
        cursor: 'pointer',
        backgroundColor: theme.palette.menu.strongYellow,
        borderRadius: '5px',
        padding: '6px 14px 6px 14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        '& $defaultButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '& $defaultButtonIcon': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            '& $defaultButtonText': {
                color: theme.palette.menu.strongYellow,
            },
            '& $defaultButtonIcon': {
                color: theme.palette.menu.strongYellow,
            },
        },
    },

    disabledDefaultButton: {
        cursor: 'not-allowed',
        backgroundColor: theme.palette.menu.strongYellow,
        borderRadius: '5px',
        padding: '6px 14px 6px 14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        '& $defaultButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '& $defaultButtonIcon': {
            color: theme.palette.menu.strongBlue,
        },
    },
});

export default styles;
