import { useEffect, useRef} from 'react'

function useKey(key, func) {
  const functionRef = useRef(func)

  useEffect(() => {
    functionRef.current = func
  }, [])

  useEffect(() => {
    function handle(event){
      // Habilitar linha abaixo caso queira descobrir o código da tecla.
      if(event.code === key){
        functionRef.current(event);
      }
    }

    // Keydown é utilizado, porquê keypress não lê o ESC.
    document.addEventListener('keydown', handle);
    return () => document.removeEventListener('keydown', handle);
  }, [key])
}

export default useKey;
