import { S3, PutObjectCommand } from '@aws-sdk/client-s3';

const S3_BUCKET = 'aviso-meteorologico';
const REGION = 'us-east-1';

const myBucket = new S3({
	// The transformation for params is not implemented.
	// Refer to UPGRADING.md on aws-sdk-js-v3 for changes needed.
	// Please create/upvote feature request on aws-sdk-js-codemod for params.
	params: { Bucket: S3_BUCKET },

	region: REGION,

	credentials: {
		accessKeyId: "AKIA2UC266X2Z5HTAQUP",
		secretAccessKey: "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"
	},
});

//data são os dados a serem filtrados e params é o parâmetro que será filtrado, por exemplo, 'nome' no caso de pacientes
export const uploadOnBucket = async (file, nome, directory) => {
	let response;
	try {
		const params = new PutObjectCommand({
			Body: file,
			Bucket: S3_BUCKET,
			Key: nome,
			//ContentDisposition: `attachment; filename=\"${data.name? data.name + '.' + file?.name?.split('.')[1]: data.first_name + '' + data.last_name + '.' + file?.name?.split('.')[1]}\"`,
		});

		if (file) {
			response = await myBucket.send(params);
		}

		return response.$metadata?.httpStatusCode == 200
			? 'https://aviso-meteorologico/' + nome :
				false
	} catch (e) {
		return false;
	}
}