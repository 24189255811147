import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { useModal } from 'Context/ModalContext';
import { useTarget } from 'Context/TargetContext';
import { PopupItem, PopupSelectTab, PopupTab, SendButton, PopupItemIndvHeader, PopupItemMultHeader, PopupItemContentDischarges, PopupItemContentColumnsLarge, PopupItemContentColumnsSmall, PopupItemHeaderColumnsLarge, PopupItemHeaderColumnsSmall, PopupItemContent, PopupItemIndvContentColumn } from './styled';
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import fontStyles from "styles/fonts-jss";
import { makeStyles } from "@material-ui/core/styles";
import 'moment/locale/pt-br'
import Modal from 'components/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useToast } from 'Context/ToastContext';

const MapPopup = (props) => {
  const [tab, setTab] = useState(0);
  const { planos, toShow: checkboxes} = useTarget();
  const openToast = useToast();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [summaryPopUp, setSummaryPopUp] = useState(false);
  const [targetsData, setTargetsData] = useState();
  const [data, setData] = useState();
  const fonts = fontStyles();

  const moment = require('moment');
  moment.locale('pt-br');

  const identifyTarget = (searchTargetId) => {
    let companyId, planId, targetId;
    planos?.every(company => {
      return company.planos.every((plan) => {
        return plan?.alvos.every((target) => {
          if (target.id_alvo !== searchTargetId) return true
          companyId = company.id;
          planId = plan.id;
          targetId = target.id;
          return false
        })
      })
    })
    return { companyId, planId, targetId };
  }

  const formatPlans = () => {
    const targets = [...props.dataTargets]
    targets.sort((a, b) => a.id_plano.localeCompare(b.id_plano))
    const groupTargets = targets.reduce((arr, target, index) => {
      if (!arr.length || arr[arr.length - 1][0]?.id_plano !== target.id_plano)
        arr.push([{...target, id: 0}]);
      else arr[arr.length - 1].push({...target, id: arr[arr.length - 1].length});
      return arr
    }, [])
    const plans = groupTargets.map((targets, index) => {
      const { companyId, planId } = identifyTarget(targets[0]?.id_alvo)
      return {
        ...planos[companyId].planos[planId],
        alvos: targets
      }
    }).sort((a, b) => a.id_empresa_id.localeCompare(b.id_empresa_id))
    const groupPlans = plans.reduce((arr, plan) => {
      if (!arr.length || arr[arr.length - 1][0]?.id_empresa_id !== plan.id_empresa_id){
        arr.push([{...plan, id: 0}]);
      }
      else {
        arr[arr.length - 1].push({...plan, id: arr[arr.length-1].length});
      }
        return arr
    }, [])
    const companies = groupPlans.map((plans, index) => {
      const { companyId } = identifyTarget(plans[0]?.alvos[0]?.id_alvo)
      return {
        ...planos[companyId],
        id: index,
        planos: plans
      }
    })
    // Troque aqui plans.
    return companies;
  }

  const loadTargetInfos = async () => {
    if(props.dataTargets.length === 1) {
      await getTargetInfosByTargetID(props.dataTargets[0]?.id_alvo).then((response) => {
        if(response[0]?.length > 0){
          setData(response[0][0][0]);
          setLoadingData(false);
        }
        setLoadingData(false);
      }).catch((error) => {
        openToast("error", "Problema ao carregar informações, recarregue a página e tente novamente!");
        setLoadingData(false);
      });
    }
    else if(props.dataTargets.length > 1){
      var aux = [];

      for(let i = 0; i < props.dataTargets.length; i++){
        await getTargetInfosByTargetID(props.dataTargets[i].id_alvo).then((response) => {
          if(response[0]?.length > 0){
            aux.push(response[0][0][0]);
          }
        }).catch((error) => {
          openToast("error", `Problema ao carregar informações do alvo ${props.dataTargets[i].nome}, recarregue a página e tente novamente!`);
          setLoadingData(false);
        });
      }
      if(aux.length > 0) {
        setData(aux);
        setSummaryPopUp(true);
        setLoading(false);
        setLoadingData(false);
      }
      else{
        setLoading(false);
        setLoadingData(false);
      }
    }
  }

  const setPlanName = (data) => {
    if(data  && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = output + ", " + data[i].nome;
        }
        else{
          output = data[i].nome;
        }
      }
    }
    return output;
  }

  const setInitMon = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = output + ", " + data[i].inicio_contrato.split('-').reverse().join('/');
        }
        else{
          output = data[i].inicio_contrato.split('-').reverse().join('/');
        }
      }
    }
    return output;
  }

  const setHolidays = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = data[i].feriados? output + ", " + "Sim" : "Não";
        }
        else{
          output = data[i].feriados? "Sim" : "Não";
        }
      }
    }
    return output;
  }

  const setServices = (data, type) => {
    var stringfyAlert = data? data?.filter((alert) => alert.status === true) : [];
    var parsedAlert = [];
    stringfyAlert?.length > 0? stringfyAlert?.map((alert) => parsedAlert.push({tipo: alert.tipo, monitoramento: alert.monitoramento})) : "";
    parsedAlert?.length > 0?  parsedAlert?.map((alert) => {
      if(alert.tipo === "Alerta Raio" || alert.tipo === "Aviso de Chuva" || alert.tipo === "Alerta Personalizado" || alert.tipo === "Boletim Diário"){
        alert?.monitoramento.map((days, index) => {
          var aux = [];
          if(days?.length > 0 && typeof days[0] === "string"){
            aux.push(JSON.parse(days))
            alert.monitoramento[index] = aux;
          }
        });
      }
    }) : "";

    const setDays = (data) => {
      var output = "";

      for(let i = 0; i < data?.length; i++) {
        if(i === data?.length - 1 && i != 0){
          output = output + data[i].slice(0, 3);
        }
        else if(i != data?.length - 1){
          output = output + data[i];
        }
        else{
          output = data[i].slice(0, 3);
        }
      }
      return output;
    }

    const setAllDays = (data) => {
      var output = "";

      for(let i = 0; i < data?.length; i++) {
        for(let j = 0; j < data[i]?.length; j++){
          if(j === data[i]?.length - j && i != 0){
            output = output + data[i][j].slice(0, 3);
          }
          else if(j != data[i]?.length - 1){
            output = output + data[i][j];
          }
          else{
            output = data[i][j].slice(0, 3);
          }
        }
      }
      return output;
    }

    if(data && data[0]){
      const output = 
        <div style={{display: "flex", flexDirection: "column"}}>
          {parsedAlert.map((service, index1) => service.tipo === type && (service.tipo === "Alerta Raio" || service.tipo === "Aviso de Chuva" || service.tipo === "Alerta Personalizado")?
            service.monitoramento.map((days, index2) =>  
              <div key={index2} style={{display: "flex", flexDirection: "column"}}>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                  {`${setDays(days[0]?.datafunc_dias)} - ${days[0]?.datafunc_hora_inicio} às ${days[0]?.datafunc_hora_fim}`}
                </span>
              </div> 
            ) :
            service.tipo === type && service.tipo === "Boletim Diário"?
              <div key={index1} style={{display: "flex", flexDirection: "column"}}>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                  {`${setAllDays(service.monitoramento)}`}
                </span>
              </div> : ""
          )}
        </div>
      return output;
    }
    else{
      return "";
    }
  }

  useEffect(() => {
    if(!loadingData && !data?.length) {
      loadData();
    }
  }, [data])

  useEffect(() => {
    if(props.dataTargets.length){
      loadTargetInfos();
    }
    else {
      setLoading(false)
      setLoadingData(false)
    }
  }, [])


  const loadData = () => {
    const targets = props.dataTargets.map((target, index) => {
      return (
        <PopupItem key={index} style={{width: "55vw", height: "45vh"}}>
          <PopupItemIndvHeader>
              <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
              <div style={{position: "fixed", transform: "translateX(-50%)", left: "50%"}}>
                <h2 style={screen.width < 1025? fonts.popUpHeaderSmall : fonts.popUpHeader}>{target.nome.toUpperCase() + " BUFFER " + target.buffer + "KM"}</h2>
              </div>
          </PopupItemIndvHeader>
          <PopupItemContent>
            <PopupItemIndvContentColumn width={"70%"} style={{borderRight: "1px dashed #9b9b9b"}}>
              <span style={screen.width < 769? fonts.popUpContentTopicMedium : fonts.popUpContentTitle}>Plano</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Nome:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setPlanName(data?.plano)}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Empresa:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{data.empresa.nome}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Início Monitoramento:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setInitMon(data?.plano)}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Feriados:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setHolidays(data?.plano)}</span>
              </div>
            </PopupItemIndvContentColumn>
            <PopupItemIndvContentColumn>
              <span style={screen.width < 769? fonts.popUpContentTopicMedium : fonts.popUpContentTitle}>Serviços</span>
              {data.servicos?.filter((servico) => servico.tipo === "Alerta Raio")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Alerta Raio:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Alerta Raio")}
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Aviso de Chuva")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Aviso de Chuva:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Aviso de Chuva")}
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Boletim Nowcasting")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Boletim Nowcasting:&nbsp;</p> 
                  <div>
                    <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                      Sim
                    </span>
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Boletim Diário")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Boletim Diário:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Boletim Diário")}
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Aviso Meteorológico")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Aviso Meteorológico:&nbsp;</p> 
                  <div>
                    <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                      Sim
                    </span>
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Alerta Personalizado")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Alerta Personalizado:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Alerta Personalizado")}
                  </div>
                </div>
              }
            </PopupItemIndvContentColumn>
          </PopupItemContent>
        </PopupItem>
      );
    });

    setTargetsData(targets);
    setLoading(false);
    setSummaryPopUp(false);
  }

  const handleTab = (tab) => {
    if(tab === 0) {
      setTab(0);
    }
    if(tab === 1) {
      setTab(1);
    }
    if(tab === 2) {
      setTab(2);
    }
  }

  if (!loading && !loadingData) {
    var tabs = 0;
    props.dataTargets.length > 0 ? tabs = tabs + 1 : tabs;
    props.dataDischarges.length > 0 ? tabs = tabs + 1 : tabs;
    props.dataActives.length > 0 ? tabs = tabs + 1 : tabs;

      return (
      <Modal onClose={props.onClose} height={"100vh"} center={true}>
        <div className="popup">
          {tabs > 1 && !summaryPopUp? (
            <>
              <div className="popup-tabs">
                {props.dataTargets.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 0}
                    onClick={() => handleTab(0)}
                  >
                    Alvos
                  </PopupSelectTab>
                }
                {props.dataDischarges.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 1}
                    onClick={() => handleTab(1)}
                  >
                    Descargas
                  </PopupSelectTab>
                }
                {props.dataActives.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 2}
                    onClick={() => handleTab(2)}
                  >
                    Ativos
                  </PopupSelectTab>
                }
              </div>
              <PopupTab>
                {tab === 0?
                  targetsData 
                :
                  tab === 1?
                    <PopupItem style={{width: "35vw", height: "50vh"}}>
                      <PopupItemIndvHeader>
                        <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer" }} onClick={props.onClose} />
                      </PopupItemIndvHeader>
                      <PopupItemContentDischarges>
                        <PopupItemIndvContentColumn>
                          {props.dataDischarges.map((discharge, index) => [(
                            <div key={index} style={{width: "100%"}}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Rede:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {discharge.fonte === 'Solo(BrasilDat)' ? 'BrasilDat' : 'GLM'}</span>
                                  {discharge.fonte === 'Solo(BrasilDat)' &&
                                  <div>
                                    <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Tipo: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.tipo === 1 ? 'Intra Nuvem' : 'Nuvem Solo'}</span>
                                  </div>
                                  }
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Detecção:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.datahora_descargas).format('HH:mm:ss')}</span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Aquisição:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.created).format('HH:mm:ss')}</span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Latitude:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[1].toFixed(4)}º</span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%",borderBottom:"1.5px solid", marginBottom: "10px"}}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Longitude: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[0]?.toFixed(4)}º</span>
                              </div>
                            </div>
                          )])}
                        </PopupItemIndvContentColumn>
                      </PopupItemContentDischarges>
                    </PopupItem> :
                      <PopupItem style={{width: "35vw", height: "50vh"}}>
                        <PopupItemIndvHeader>
                          <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
                            <div style={{position: "fixed", transform: "translateX(-50%)", left: "50%"}}>
                              <h2 style={screen.width < 1025? fonts.popUpHeaderSmall : fonts.popUpHeader}>{props.dataActives[0].nome? props.dataActives[0].nome.toUpperCase() : ""}</h2>
                            </div>
                        </PopupItemIndvHeader>
                        <PopupItemContentDischarges>
                          <PopupItemIndvContentColumn>
                              <div style={{width: "100%"}}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                  <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Alvo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {props.dataActives[0].alvo? props.dataActives[0].alvo : ""}</span>
                                </div>
                            </div>
                          </PopupItemIndvContentColumn>
                        </PopupItemContentDischarges>
                      </PopupItem>
              }
              </PopupTab>
            </>
          ) : (
              props.dataActives.length === 1 ?
                <PopupItem style={{width: "35vw", height: "50vh"}}>
                  <PopupItemIndvHeader>
                    <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
                    <div style={{position: "fixed", transform: "translateX(-50%)", left: "50%"}}>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderSmall : fonts.popUpHeader}>{props.dataActives[0].nome? props.dataActives[0].nome.toUpperCase() : ""}</h2>
                    </div>
                  </PopupItemIndvHeader>
                  <PopupItemContentDischarges>
                    <PopupItemIndvContentColumn>
                        <div style={{width: "100%"}}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Alvo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {props.dataActives[0].alvo? props.dataActives[0].alvo : ""}</span>
                          </div>
                      </div>
                    </PopupItemIndvContentColumn>
                  </PopupItemContentDischarges>
                </PopupItem> :
            <PopupTab>
              {!summaryPopUp && targetsData}
              {summaryPopUp?
                <PopupItem style={{width: "80vw", height: "40vh"}}>
                  <PopupItemMultHeader>
                    <PopupItemHeaderColumnsLarge>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Empresa</h2>
                    </PopupItemHeaderColumnsLarge>
                    <PopupItemHeaderColumnsLarge>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Plano</h2>
                    </PopupItemHeaderColumnsLarge>
                    <PopupItemHeaderColumnsSmall>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Status plano</h2>
                    </PopupItemHeaderColumnsSmall>
                    <PopupItemHeaderColumnsLarge>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Alvo</h2>
                    </PopupItemHeaderColumnsLarge>
                    <PopupItemHeaderColumnsSmall>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Alerta Raio</h2>
                    </PopupItemHeaderColumnsSmall>
                  </PopupItemMultHeader>
                  <PopupItemContent>
                    <PopupItemContentColumnsLarge>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className={data.empresa.nome.length > 30? "popup-mult-item-div-overflow" : "popup-mult-item-div"}>{data.empresa.nome}</span>
                      )])}
                    </PopupItemContentColumnsLarge>
                    <PopupItemContentColumnsLarge>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{data.plano[0]?.nome}</span>
                      )])}
                    </PopupItemContentColumnsLarge>
                    <PopupItemContentColumnsSmall>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{data.plano[0]?.status === 0? "Inativo" : data.plano[0]?.status === 1? "Ativo" : "Suspenso"}</span>
                      )])}
                    </PopupItemContentColumnsSmall>
                    <PopupItemContentColumnsLarge>
                      {props.dataTargets.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{data.nome}</span>
                      )])}
                    </PopupItemContentColumnsLarge>
                    <PopupItemContentColumnsSmall>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{setServices(data.servicos) != "Não contemplado"? "Ativo" : "Inativo"}</span>
                      )])}
                    </PopupItemContentColumnsSmall>
                  </PopupItemContent>
                </PopupItem> : ""
              }
              {!summaryPopUp && !targetsData && 
                <PopupItem style={{width: "35vw", height: "50vh"}}>
                  <PopupItemIndvHeader>
                    <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer" }} onClick={props.onClose} />
                  </PopupItemIndvHeader>
                  <PopupItemContentDischarges>
                    <PopupItemIndvContentColumn>
                      {props.dataDischarges.map((discharge, index) => [(
                        <div key={index} style={{width: "100%"}}>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Rede:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {discharge.fonte === 'Solo(BrasilDat)' ? 'BrasilDat' : 'GLM'}</span>
                              {discharge.fonte === 'Solo(BrasilDat)' &&
                              <div>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Tipo: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.tipo === 1 ? 'Intra Nuvem' : 'Nuvem Solo'}</span>
                              </div>
                              }
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Detecção:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.datahora_descargas).format('HH:mm:ss')}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Aquisição:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.created).format('HH:mm:ss')}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Latitude:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[1].toFixed(4)}º</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%", borderBottom:"1.5px solid", marginBottom: "10px"}}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Longitude: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[0]?.toFixed(4)}º</span>
                          </div>
                        </div>
                      )])}
                    </PopupItemIndvContentColumn>
                  </PopupItemContentDischarges>
                </PopupItem>}
            </PopupTab>
          )}
        </div>
      </Modal>
    );
  }
  else if(loading){
    return(
      <CircularProgress style={{marginLeft: "calc(45%)", marginTop: "calc(20%)"}} size={90} thickness={4} color="primary"/>
    )
  }
};

MapPopup.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onClose: PropTypes.func,
  dataDischarges: PropTypes.array,
  dataTargets: PropTypes.array,
  dataActives: PropTypes.array,
};

export default MapPopup;