export default function (state = false, action){

    switch(action.type){
        case "MAP_SIDEBAR_MENU_PROVIDER":
            return action.payload[0]
    default: return state
    }
}




