import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleAction, playTransitionAction } from 'redux/actions/uiActions';
import DashboardComponent from 'containers/Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
  Dashboard,
  Plan,
  Company,
  Services,
  Target,
  Overtime,
  NucleoEmployees,
  Employees,
  CompanyInfo,
  PlanInfo,
  EmployeeInfo,
  NucleoEmployeesTab,
  NucleoEmployeesInfo,
  CompanyEdit,
  PlanEdit,
  TargetEdit,
  ServiceEdit,
  EmployeeEdit,
  EmployeeTargetEdit,
  NucleoEmployeesEdit,
  NotFound,
  WeathermanPlans,
  WeathermanServices
} from './pageListAsync';
import styles from 'containers/Templates/appStyles-jss';

function Application(props) {
  const changeMode = useContext(ThemeContext);
  const {
    history, gradient, mode, loadTransition, sidebarOpen, toggleDrawer
  } = props;
  
  return (
    <DashboardComponent history={history} changeMode={changeMode}>
      <Switch>
        <Route exact path="/administracao" component={Dashboard} />
        <Route exact path='/administracao/empresas/:company' component={CompanyInfo} />
        <Route exact path='/administracao/empresas/:company/planos' component={WeathermanPlans} />
        <Route exact path='/administracao/empresas/:company/planos:plan/serviços' component={WeathermanServices} />
        <Route exact path="/administracao/registro/horaextra" component={Overtime} />
        <Route component={ NotFound } />
      </Switch>
    </DashboardComponent>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  gradient: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  sidebarOpen: state.ui.sidebarOpen,
  mode: state.ui.type,
  gradient: state.ui.gradient,
});

const mapDispatchToProps = dispatch => ({
  toggleDrawer: () => dispatch(toggleAction),
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const ApplicationMaped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);

export default (withStyles(styles)(ApplicationMaped));
