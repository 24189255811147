/* eslint-disable */

import React from 'react';
import Loading from 'components/Loading';
import loadable from '../utils/loadable';

export const NotFound = loadable(
    () => import('pages/Others/NotFound/NotFound'),
    {
        fallback: <Loading />,
    },
);

export const RegisterPassword = loadable(
    () => import('pages/Others/RegisterPassword'),
    {
        fallback: <Loading />,
    },
);

export const Dashboard = loadable(
    () => import('pages/AdminDashboard/View/Dashboard'),
    {
        fallback: <Loading />,
    },
);

export const ClientDashboard = loadable(
    () => import('pages/ClientDashboard/View/Dashboard'),
    {
        fallback: <Loading />,
    },
);

export const AllPlans = loadable(
    () => import('pages/AdminDashboard/View/AdminPages/AllPlans'),
    {
        fallback: <Loading />,
    },
);

export const PortalsDashboard = loadable(
    () => import('pages/PortalsDashboard/index'),
    {
        fallback: <Loading />,
    },
);

export const NucleoEmployeesInfo = loadable(
    () =>
        import(
            'pages/AdminDashboard/View/AdminPages/NucleoEmployees/NucleoEmployeesInfoTab.jsx'
        ),
    {
        fallback: <Loading />,
    },
);

export const NucleoEmployeesEdit = loadable(
    () =>
        import(
            'pages/AdminDashboard/Edit/NucleoEmployees/NucleoEmployeesInfo'
        ),
    {
        fallback: <Loading />,
    },
);

export const RegisterPage = loadable(
    () => import('pages/AdminDashboard/Register/index'),
    {
        fallback: <Loading />,
    },
);

export const EditCompany = loadable(
    () => import('pages/AdminDashboard/Edit/Company/index'),
    {
        fallback: <Loading />,
    },
);

export const EditPlan = loadable(
    () => import('pages/AdminDashboard/Edit/Plan/index'),
    {
        fallback: <Loading />,
    },
);

export const EditTarget = loadable(
    () => import('pages/AdminDashboard/Edit/Target/index'),
    {
        fallback: <Loading />,
    },
);

export const EditService = loadable(
    () => import('pages/AdminDashboard/Edit/Services/index'),
    {
        fallback: <Loading />,
    },
);

export const EditContact = loadable(
    () => import('pages/AdminDashboard/Edit/Contacts/index'),
    {
        fallback: <Loading />,
    },
);

export const EditEmployee = loadable(
    () => import('pages/AdminDashboard/Edit/Employees/index'),
    {
        fallback: <Loading />,
    },
);

export const Plan = loadable(
    () => import('pages/AdminDashboard/Register/Plan'),
    {
        fallback: <Loading />,
    },
);

export const Company = loadable(
    () => import('pages/AdminDashboard/Register/Company'),
    {
        fallback: <Loading />,
    },
);

export const Services = loadable(
    () => import('pages/AdminDashboard/Register/Services'),
    {
        fallback: <Loading />,
    },
);

export const Target = loadable(
    () => import('pages/AdminDashboard/Register/Target'),
    {
        fallback: <Loading />,
    },
);

export const Overtime = loadable(
    () => import('../pages/AdminDashboard/Register/Overtime/index'),
    {
        fallback: <Loading />,
    },
);

export const Employees = loadable(
    () => import('../pages/AdminDashboard/Register/Employees/index'),
    {
        fallback: <Loading />,
    },
);

export const CompanyInfo = loadable(
    () => import('pages/AdminDashboard/View/AdminPages/CompanyInfo'),
    {
        fallback: <Loading />,
    },
);

export const PlanInfo = loadable(
    () => import('pages/AdminDashboard/View/AdminPages/PlanInfo'),
    {
        fallback: <Loading />,
    },
);

export const EmployeeInfo = loadable(
    () => import('pages/AdminDashboard/View/AdminPages/EmployeeInfo'),
    {
        fallback: <Loading />,
    },
);

export const TargetInfo = loadable(
    () => import('pages/AdminDashboard/View/AdminPages/TargetInfo'),
    {
        fallback: <Loading />,
    },
);

export const ClientTargetInfo = loadable(
    () => import('pages/ClientDashboard/View/ClientPages/TargetInfo'),
    {
        fallback: <Loading />,
    },
)

export const AllEmployees = loadable(
    () => import('pages/ClientDashboard/View/ClientPages/AllEmployees'),
    {
        fallback: <Loading />,
    },
)

export const ClientEmployeeInfo = loadable(
    () => import('pages/ClientDashboard/View/ClientPages/EmployeeInfo'),
    {
        fallback: <Loading />,
    },
)

export const ClientEmployeeRegister = loadable(
    () => import('pages/ClientDashboard/Register/'),
    {
        fallback: <Loading />,
    },
)

export const ClientEditEmployee = loadable(
    () => import('pages/ClientDashboard/Edit/Employees'),
    {
        fallback: <Loading />,
    },
)

export const ClientEditContact = loadable(
    () => import('pages/ClientDashboard/Edit/Contacts'),
    {
        fallback: <Loading />,
    },
)

export const NucleoEmployeesTab = loadable(
    () => import('pages/AdminDashboard/View/AdminPages/NucleoEmployees/NucleoEmployeesTab'),
    {
        fallback: <Loading />,
    },
);

export const WeathermanPlans = loadable(
    () => import('pages/AdminDashboard/View/WeathermanPages/Plans'),
    {
        fallback: <Loading />,
    },
);

export const WeathermanServices = loadable(
    () => import('pages/AdminDashboard/View/WeathermanPages/Services'),
    {
        fallback: <Loading />,
    },
);

export const NewsRegister = loadable(
    () => import('pages/AdminDashboard/Site/Register/News'),
    {
        fallback: <Loading />,
    },
);

export const NewsTable = loadable(
    () => import('pages/AdminDashboard/Site/Table/News'),
    {
        fallback: <Loading />,
    },
);

export const NewsInfo = loadable(
    () => import('pages/AdminDashboard/Site/View/News/newsInfo'),
    {
        fallback: <Loading />,
    },
);

export const NewsEdit = loadable(
    () => import('pages/AdminDashboard/Site/Edit/News/newsEdit'),
    {
        fallback: <Loading />,
    },
);

export const WeatherForecastRegister = loadable(
    () => import('pages/AdminDashboard/Site/Register/WeatherForecast'),
    {
        fallback: <Loading />,
    },
);

export const WeatherForecastTable = loadable(
    () => import('pages/AdminDashboard/Site/Table/WeatherForecast'),
    {
        fallback: <Loading />,
    },
);

export const WeatherForecastInfo = loadable(
    () => import('pages/AdminDashboard/Site/View/Weather/weatherInfo'),
    {
        fallback: <Loading />,
    },
);

export const WeatherForecastEdit = loadable(
    () => import('pages/AdminDashboard/Site/Edit/Weather/weatherEdit'),
    {
        fallback: <Loading />,
    },
);
