import { djangoApi } from '../api';

export const postNucleoEmployee = (accountData) => {


    return djangoApi.post(`usuario/cadastro/`, accountData);
}

export const getNucleoEmployeesList= async () => {
    try {
        const response = await djangoApi.get(`usuarios_nucleo/`);
        return response.data;
    } catch (error) {
        return [];
    }
};

export const updateNucleoEmployee = (id_nucleoEmployee, nucleoEmployeeData) => {
    return djangoApi.put(`usuarios_nucleo/${id_nucleoEmployee}/`, nucleoEmployeeData);
}

export const deleteNucleoEmployee = async (id_employee) => {
    try {
        const response = await djangoApi.delete(`usuarios_nucleo/${id_employee}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
};
