import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useTogglePlans from 'hooks/useTogglePlans';
import { useDispatch } from 'react-redux';
import { reportCardContextProvider } from 'redux/actions/reportCardContext';
import useToggle from 'hooks/useToggle';

export const ActiveStatusContext = createContext();

export const ActiveStatusContextProvider = (props) => {

  const [state, setState] = useState(true);

 
  const enableStatus = () => {
    setState(true);
  }

  const disableStatus = () => {
    setState(false);
  }

  return (
      <ActiveStatusContext.Provider
          value={{
            state,
            enableStatus,
            disableStatus
          }}
      >
          {props.children}
      </ActiveStatusContext.Provider>
  );
};

ActiveStatusContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ActiveStatusContextProvider;

export const useActiveStatus = () => {
  const context = useContext(ActiveStatusContext);

  if(typeof context === 'undefined'){
    throw new Error('useActiveStatus need to be used inside a <ActiveStatusContextProvider />')
  }

  return context;
}
