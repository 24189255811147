/* Libraries */
import React, {useEffect, useState} from 'react'
import P from 'prop-types';
import {Head, Title, BigTab} from '../styled'
/* Components */
import { PapperBlock } from 'components';
import {MdClose} from 'react-icons/md';
import Meteogram from './Meteogram';


const ProductsTab = (props) => {
  const {classes} = props;
  const [tabsList, setTabsList] = useState();

  useEffect(() => {
        setTabsList([
            {
                label: 'METEOGRAMA',
                component: <Meteogram />,
            },
        ]);
    
}, []);


  return (
    <BigTab style={{maxHeight: '100%', overflow: 'auto'}} >
      <Head>
        <h1>MONITORAMENTO</h1>
        <button ><MdClose /></button>
      </Head>
      <Title>PRODUTOS</Title>
      <PapperBlock  tabsList={tabsList} icon="ion-ios-paper-outline">
          
      </PapperBlock>
    </BigTab>
  )
}

ProductsTab.propTypes = {
  onClose: P.func.isRequired,
  classes: P.object.isRequired
}

export default ProductsTab;
