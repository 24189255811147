import { useState, useRef, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useBound from 'hooks/useBound';

export const MapContext = createContext();

export const MapContextProvider = (props) => {

  const [mapStyle, setMapStyle] = useState('streets-v11');
  const [goTo, setGoTo] = useState([]);
  const [maxBoundsState] = useBound();
  const [popup, setPopup] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [hotkeys, toggleHotkeys] = useToggle(true);

  const mapRef = useRef();

  const resetMapPosition = () => {
    setGoTo([
      [-110, -40],
      [-0, 13]
    ]);
  }

  const resetClientMapPosition = () => {
    setGoTo([
      [maxBoundsState[2], maxBoundsState[3]],
      [maxBoundsState[0], maxBoundsState[1]]
    ]);
  }

  return (
        <MapContext.Provider
            value={{
                mapStyle,
                setMapStyle,
                goTo,
                setGoTo,
                resetMapPosition,
                resetClientMapPosition,
                mapRef,
                popup,
                setPopup,
                showPopup,
                setShowPopup,
                hotkeys,
                toggleHotkeys
            }}
        >
            {props.children}
        </MapContext.Provider>
    );
};

MapContextProvider.propTypes = {
    children: PropTypes.node,
};

export default MapContextProvider;

export const useMapContext = () => {
  const context = useContext(MapContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
