/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import 'moment/locale/pt-br';
/* Material UI */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ReportProblem from "@material-ui/icons/ReportProblem";
import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
/* Hooks */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "../alerts_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Services */
import { getUserAuthenticated } from "services/login/login";
import { getHolidays } from "services/adminDashboard/holidays";
import { getTargetInfosByTargetID } from "services/adminDashboard/target";


const useStyles = makeStyles({
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
  switch_secondary: {
    "&.Mui-checked": {
      color: "red",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "red",
    },
  },
  divider: {
    background: "#A5A5A5",
  },
})


const FieldsComponent = (props) => {
  const moment = require('moment');
  moment.locale('pt-br');
  const switchStyle = useStyles();
  const fonts = fontStyles();
  const { classes, alertsData, target, setAlertsData, preset, setReloadModal, plano, setReloadSwitchSelected, allSelected, setAllSelected, setReloadErrorMessage, setStatusTargetSelected, statusTargetSelected, setReloadStatusTargetSelected, setAllSelectedAux, allSelectedAux, setLoadingRightSide, loadingRightSide, setReloadRightSide, rainAlertErrors ,setRainAlertErrors } = props;
  const [servicesStatus, setServicesStatus] = useState(true);
  const [contacts, setContacts] = useState();
  const [loadingContacts, setLoadingContacts] = useState(true);;
  const [caractersRemaning, setCaractersRemaning] = useState();
  const [switchSelected, setSwitchSelected] = useState(false);
  const [hasRainAlert, setHasRainAlert] = useState(false);
  const [loadingTargetData, setLoadingTargetData] = useState(0);
  const [targetData, setTargetData] = useState([]);
  var alertsDataAux = alertsData;
  const openToast = useToast();

  const [rainAlertErrorsAux, setRainAlertErrorsAux] = useState({
    acumulated: null,
    intensity: null,
    duration: null,
    contact: null,
    id_alvo: null,
  });

  const [targetRainAlert, setTargetRainAlert] = useState({
    acumulado: "",
    intensidade: "",
    duracao: "",
    contato: "",
    observacao: null,
    meteorologista: "",
    conteudo_modificacao: null,
    modificacao: null,
    campos_modificados: null,
    id_alvo: "",
  });

  var gridClasses = [
    classes.gridColumnsComponentRain,
    classes.gridMarginLeftRain,
  ]

  var gridClasses2 = [
    classes.gridColumnsComponentRain,
    classes.gridMarginLeftRain3,
  ]

  const toggleSwitchSelected = () => {
    if (hasRainAlert) {
      if (!switchSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(!switchSelected);
          alertsDataAux.splice(index, 1);
          alertsDataAux.push(targetRainAlert);
          setAlertsData(alertsDataAux);
        }
        else {
          alertsDataAux.push(targetRainAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(!switchSelected);
        }
        let aux = rainAlertErrors;
        let indexError = aux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (indexError != -1) {
          aux.splice(indexError, 1);
          aux.push(rainAlertErrorsAux);
          setRainAlertErrors(aux);
        }
        else {
          aux.push(rainAlertErrorsAux);
          setRainAlertErrors(aux);
        }
      }
      else {
        setAllSelected(false);
        setAllSelectedAux(true);
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(!switchSelected);
        let aux = rainAlertErrors;
        aux = aux.filter((item) => target.id_alvo != item.id_alvo);
        setRainAlertErrors(aux);
      }
    }
    setReloadSwitchSelected(true);
  }

  const loadTargetData = async () => {
    if(target.id_alvo){
      await getTargetInfosByTargetID(target.id_alvo).then((response) => {
        if(response[0].length > 0){
          setTargetData(response[0][0][0]);
          setLoadingTargetData(1);
          loadServices(response[0][0][0].servicos, response[0][0][0].usuarios);
        }
        else{
          setLoadingTargetData(2);
        }
      }).catch((error) => {
        setLoadingTargetData(2);
      });
    }
  }

  const loadServices = (servicesData, employeesData) => {
    var today = moment().format('dddd');
    today = today === "sábado"? "sabado" : today;
    var now = moment();
    var stringfyLightningAlert = servicesData.filter((alert) => alert.tipo === "Aviso de Chuva" && alert.status === true);
    var parsedLightningAlert = [];
    stringfyLightningAlert?.length > 0? stringfyLightningAlert.map((alert) => parsedLightningAlert.push({id_service : alert.id_servico, monitoring: alert.monitoramento})) : "";
    parsedLightningAlert?.length > 0?  parsedLightningAlert.map((alert) => {
      alert.monitoring.map((days, index) => {
        var aux = [];
        if(typeof days[0] === "string"){
          aux.push(JSON.parse(days))
          alert.monitoring[index] = aux;
        }
      });
    }) : "";

    var test = parsedLightningAlert?.length > 0? parsedLightningAlert.map((alert) => 
      alert.monitoring.some((days) => 
        days[0].datafunc_dias.includes((today[0].toUpperCase() + today.slice(1, 3) + ", ")) && 
        now.isBetween(
          moment(days[0].datafunc_hora_inicio, 'hh:mm:ss'),
          moment(days[0].datafunc_hora_fim, 'hh:mm:ss')
        )
      )
    ) : [];

    var lightningAlert = test.length > 0 && test.includes(true)? 1 : parsedLightningAlert.length > 0? 2 : 0;

    if(servicesData && servicesData[0]){
      if(lightningAlert === 1){
        loadHolidays(1, employeesData)
      }
      else if(lightningAlert === 2){
        loadHolidays(2, employeesData)
      }
      else{
        setHasRainAlert(false);
      }
    }
    else{
      setHasRainAlert(false)
    }
  }

  const loadHolidays = async (serviceStatusAux, employeesData) => {
    var holidays = false;
    serviceStatusAux === 1? setServicesStatus(true) : setServicesStatus(false);
    
    await getHolidays(moment().format("YYYY")).then((response) => {
      if(response){
        for (let j = 0; j < response.length; j++) {
          if(moment(response[j].date).isSame(moment().format('YYYY-MM-DD'))){
            holidays = true;
          }
        }
        if(holidays && !targetData.plano[0].feriados){
          setServicesStatus(false);  
        }
        setHasRainAlert(true);
        loadContacts(employeesData);
        loadUserAuthenticated();
      }
    }).catch((error) => {
      openToast('error', 'Problema ao buscar feriados nacionais, recarregue a página e tente novamente!')
    });
  }

  const loadUserAuthenticated = async () => {
    var loginToken = JSON.parse(localStorage.getItem("reeWAL57qe"));

    await getUserAuthenticated(loginToken).then((response) => {
      if (response.status === 200) {
        targetRainAlert.meteorologista = response.data.usuario.id;
      }
    }).catch((error) => {
      openToast('error', 'Problema ao buscar usuário autenticado, recarregue a página e tente novamente!')
    });
  }

  const loadContacts = (data) => {
    var contactsList = [{ nome: "Não foi possível o contato telefônico.", prioridade: [""] }, { nome: "Não foi realizado o contato telefônico.", prioridade: [""] }, { nome: "Não há contato telefônico das xx:xx até às xx:xx.", prioridade: [""] }];

    if(data && data[0]){
      var contactData = [];
      data.map((user) => user.prioridade? contactData.push(user) : contactData);

      const sortData = ( a, b ) => {
        if ( a.prioridade.prioridade < b.prioridade.prioridade ){
          return -1;
        }
        if ( a.prioridade.prioridade > b.prioridade.prioridade ){
          return 1;
        }
        return 0;
      }

      contactData.sort(sortData);

      for (let i = 0; i < contactData.length; i++) {
        contactsList.push({ nome: contactData[i].nome, prioridade: contactData[i].prioridade.prioridade})
      } 
      setContacts(contactsList);
      setLoadingContacts(false); 
    }
    else{
      setContacts(contactsList);
      setLoadingContacts(false);
    }
  }

  useEffect(() => {
    targetRainAlert.acumulado = preset.acumulado;
    targetRainAlert.intensidade = preset.intensidade;
    targetRainAlert.duracao = preset.duracao;
    targetRainAlert.contato = preset.contato;
    targetRainAlert.observacao = preset.observacao;

    setReloadModal(true);
  }, [preset.preset])

  useEffect(() => {
    if (target) {
      targetRainAlert.id_alvo = target.id_alvo;
      targetRainAlert.nome = target.nome;
      rainAlertErrorsAux.id_alvo = target.id_alvo;
      loadTargetData();
    }
  }, []);

  useEffect(() => {
    if (!servicesStatus) {
      let aux = statusTargetSelected;
      if (!aux.includes(target.nome)) {
        aux.push(target.nome);
        setStatusTargetSelected(aux);
        setReloadStatusTargetSelected(true);
      }
      else{
        if(!switchSelected){
          aux.pop(target.nome);
        }
      }
    }
  }, [switchSelected])

  useEffect(() => {
    if (hasRainAlert) {
      if (allSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(allSelected);
        }
        else {
          alertsDataAux.push(targetRainAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(allSelected);
          let aux = rainAlertErrors;
          aux.push(rainAlertErrorsAux);
          setRainAlertErrors(aux);
        }
      }
      else if (!allSelected && !allSelectedAux) {
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(allSelected);
        let aux = rainAlertErrors;
        aux = aux.filter((item) => target.id_alvo!= item.id_alvo);
        setRainAlertErrors(aux);
      }
    }
  }, [allSelected])

  useEffect(() => {
    if (hasRainAlert) {
      if (switchSelected) {
        if (targetRainAlert.acumulado === "") {
          rainAlertErrorsAux.acumulated = "Campo Acumulado é obrigatório!";
        }
        else {
          rainAlertErrorsAux.acumulated = null;
        }
        if (targetRainAlert.duracao === "") {
          rainAlertErrorsAux.duration = "Campo Duração é obrigatório!";
        }
        else {
          rainAlertErrorsAux.duration = null;
        }
        if (targetRainAlert.contato === "") {
          rainAlertErrorsAux.contact = "Campo Contato é obrigatório!";
        }
        else {
          if (targetRainAlert.contato && targetRainAlert.contato.length < 10) {
            rainAlertErrorsAux.contact = "Campo Contato deve ter mais que 10 caracteres!";
          }
          else if (targetRainAlert.contato && targetRainAlert.contato.length > 50) {
            rainAlertErrorsAux.contact = "Campo Contato deve ter menos que 50 caracteres!";
          }
          else {
            rainAlertErrorsAux.contact = null;
          }
        }
        if (targetRainAlert.intensidade === "") {
          rainAlertErrorsAux.intensity = "Campo Intensidade é obrigatório!";
        }
        else {
          rainAlertErrorsAux.intensity = null;
        }
        if (targetRainAlert.observacao && targetRainAlert.observacao.length > 1 && targetRainAlert.observacao.length < 50) {
          setCaractersRemaning(50 - targetRainAlert.observacao.length);
        }
        else {
          setCaractersRemaning("");
        }

        var index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          let aux = alertsDataAux;
          aux.splice(index, 1);
          aux.push(targetRainAlert);
          setAlertsData(aux);
          let aux2 = rainAlertErrors;
          aux2.splice(index, 1);
          aux2.push(rainAlertErrorsAux);
          setRainAlertErrors(aux2);
        }
        else {
          let aux = alertsDataAux;
          aux.push(targetRainAlert);
          setAlertsData(aux);
          let aux2 = rainAlertErrors;
          aux2.push(rainAlertErrorsAux);
          setRainAlertErrors(aux2);
        }
      }
      setReloadErrorMessage(false);
    }
  }, [targetRainAlert?.acumulado, targetRainAlert?.duracao, targetRainAlert?.contato, targetRainAlert?.intensidade, targetRainAlert?.observacao, preset.preset, switchSelected])

  useEffect(() => {
    if(hasRainAlert) {
      if(loadingContacts){
        let aux = loadingRightSide;
        aux.push(true);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
      else{
        let aux = loadingRightSide;
        aux.pop(true);
        aux.pop(false);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
    }
    else{
      let aux = loadingRightSide;
      aux.pop(false);
      setLoadingRightSide(aux);
      setReloadRightSide(true);
    }
  }, [loadingContacts])

  if (target && !loadingContacts && loadingTargetData === 1 && hasRainAlert && loadingRightSide.length === 0) {
    return (
      <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "10px" }}>
        <Grid item xs={12} className={classes.gridContainerComponentRain}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={switchSelected}
              onChange={() => toggleSwitchSelected()}
              value={switchSelected}
              size="small"
              classes={{
                colorPrimary: servicesStatus ? switchStyle.switch_primary : switchStyle.switch_secondary
              }}
              color={"primary"} />

            <div style={{ marginLeft: "15px" }}>
              <Typography variant="subtitle1" style={!servicesStatus ? fonts.targetTitleWarning : fonts.targetTitle}>
                {target.nome}
              </Typography>
            </div>

            {!servicesStatus && <ReportProblem style={{ marginLeft: "8px", width: "20px", height: "20px", color: "red" }} />}
          </div>
        </Grid>

        <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start">
          < Grid item xs={2.5} className={classes.gridContainerComponentRain}>
            <div className={classes.dateHourContainer}>
              <InputMask
                mask="999 - 999 mm"
                value={targetRainAlert?.acumulado}
                onChange={(e) => setTargetRainAlert({ ...targetRainAlert, acumulado: e.target.value })}
                disabled={false}
                maskChar=" "
              >{() =>
                <TextField
                  id="acumladoInit"
                  className={classes.textFieldSmallRainAlert}
                  margin="normal"
                  variant="outlined"
                  label="Acumulado"
                  inputProps={{ style: fonts.formsFieldHeight1 }}
                  InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                />}
              </InputMask>
            </div>
          </Grid>

          <Grid item xs={1.5} className={gridClasses}>
            <div className={classes.dateHourContainer}>
              <InputMask
                mask="99:99"
                value={targetRainAlert?.duracao}
                onChange={(e) => setTargetRainAlert({ ...targetRainAlert, duracao: e.target.value })}
                disabled={false}
                maskChar=" "
              >{() =>
                <TextField
                  id="duration"
                  className={classes.textFieldSmallLightAlert}
                  margin="normal"
                  variant="outlined"
                  label="Duração"
                  inputProps={{ style: fonts.formsFieldHeight1 }}
                  InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                />}
              </InputMask>
            </div>
          </Grid>

          <Grid item xs={6} className={gridClasses2} style={{ marginTop: "18px" }}>
            <div className={classes.selectContainer}>
              <Autocomplete
                id="contacts"
                freeSolo
                value={targetRainAlert?.contato}
                inputValue={targetRainAlert?.contato}
                disableClearable
                onInputChange={(_, newValue) => setTargetRainAlert({ ...targetRainAlert, contato: newValue })}
                onChange={(_, newValue) => setTargetRainAlert({ ...targetRainAlert, contato: newValue })}
                className={classes.selectFieldRainAlert3}
                style={fonts.formsFieldHeight1}
                size="small"
                options={contacts.map((option) => option.prioridade != "" ? option.prioridade + " - " + option.nome : option.nome)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Contato"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={3.5} className={classes.gridContainerComponentRain}>
            <div className={classes.selectContainer} style={{ marginTop: "5px" }}>
              <Autocomplete
                id="intensity"
                freeSolo={false}
                value={targetRainAlert?.intensidade}
                inputValue={targetRainAlert?.intensidade}
                disableClearable
                onChange={(_, newValue) => setTargetRainAlert({ ...targetRainAlert, intensidade: newValue })}
                className={classes.selectFieldRainAlert2}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Fraca", "Fraca a moderada", "Moderada", "Moderada a forte", "Forte", "Muito forte", "Extrema"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Intensidade"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={6} className={classes.gridColumnsComponentRain} style={{ marginTop: "22px" }}>
            <TextField
              id="comments"
              label="Observações"
              multiline
              shrink={true}
              rows={2}
              value={targetRainAlert?.observacao}
              onChange={(e) => setTargetRainAlert({ ...targetRainAlert, observacao: e.target.value })}
              className={classes.textFieldRainAlert}
              variant="outlined"
              color="primary"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: fonts.formsFieldHeight2, maxLength: 50 }}
            />

            {caractersRemaning && <p className={classes.errorMessage}>{caractersRemaning + " caracteres restantes"}</p>}
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" justifyContent="center" style={{ marginTop: "14px", width: "52vw" }}>
          <Grid item xs={12}>
            <Divider classes={{ root: switchStyle.divider }} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
  /*else if(loading && loadTarget){
    return(
      <CircularProgress style={{marginLeft: "calc(45%)", marginTop: "calc(20%)"}} size={50} thickness={1} color="primary"/>
    )
  }*/
}

FieldsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  alertsData: PropTypes.any,
  preset: PropTypes.any,
  plano: PropTypes.any,
  target: PropTypes.any,
  setAlertsData: PropTypes.func,
  setAllSelectedAux: PropTypes.func,
  allSelectedAux: PropTypes.any,
  setTargetRainAlert: PropTypes.func.isRequired,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  setAcumulatedErrorRainAlert: PropTypes.any.isRequired,
  setDurationErrorRainAlert: PropTypes.any.isRequired,
  setIntensityErrorRainAlert: PropTypes.any.isRequired,
  setContactErrorRainAlert: PropTypes.any.isRequired,
  acumulatedErrorRainAlert: PropTypes.any.isRequired,
  durationErrorRainAlert: PropTypes.any.isRequired,
  intensityErrorRainAlert: PropTypes.any.isRequired,
  contactErrorRainAlert: PropTypes.any.isRequired,
  setReloadModal: PropTypes.string.isRequired,
  setLoadingRightSide: PropTypes.func.isRequired,
  setReloadRightSide: PropTypes.func.isRequired,
  loadingRightSide: PropTypes.any.isRequired,
  rainAlertErrors: PropTypes.any.isRequired,
  setRainAlertErrors: PropTypes.func.isRequired,
};

export default withStyles(styles)(FieldsComponent);