/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import ModalContextProvider from 'Context/ModalContext';


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const ModalPrevision = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo, handleErrataPrevision, handleResendPrevision, handleRetificationPrevision, clientPage} = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();

  const refEdit = useRef();
  const refAdd = useRef();
  var aux = 0;
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);

  const handleClose = () => {
    setOpen(null);
    setReloadMod(true);
  }

  console.log(tableData)
  const openErrata = (event) => {
    handleClose();
    setAnchorEl(null);
    handleErrataPrevision(event, tableData.id+"_"+tableData.intensidade+"_"+tableData.condicao_tempo+"_"+tableData.plano+"_"+tableData.alvo+"_"+tableData.empresa+"_"+tableData.acumulado+"_"+tableData.probabilidade+"_"+tableData.nivel+"_"+tableData.rajada_vento+"_"+tableData.direcao_vento+"_"+tableData.velocidade_vento+"_"+tableData.temperatura_min+"_"+tableData.temperatura_max+"_"+tableData.obs+"_"+tableData.tipo+"_"+tableData.id_alvo_id+"_"+tableData.nome_meteorologista+"_"+tableData.meteorologista+"_"+tableData.horizontes+"_"+tableData.vigencia+"_"+tableData.especial+"_"+tableData.status+"_"+tableData.datahora_criacao+"_"+tableData.caminho_pdf+"_"+tableData.datahora_modificacao+"_"+tableData.titulo+"_"+tableData.texto+"_"+tableData.modificacao+"_"+tableData.errata+"_"+tableData.id_ogG);

  }

  const openRetification = (event) => {
    handleClose();
    setAnchorEl(null);
    handleRetificationPrevision(event, tableData.id+"_"+tableData.intensidade+"_"+tableData.condicao_tempo+"_"+tableData.plano+"_"+tableData.alvo+"_"+tableData.empresa+"_"+tableData.acumulado+"_"+tableData.probabilidade+"_"+tableData.nivel+"_"+tableData.rajada_vento+"_"+tableData.direcao_vento+"_"+tableData.velocidade_vento+"_"+tableData.temperatura_min+"_"+tableData.temperatura_max+"_"+tableData.obs+"_"+tableData.tipo+"_"+tableData.id_alvo_id+"_"+tableData.nome_meteorologista+"_"+tableData.meteorologista+"_"+tableData.horizontes+"_"+tableData.vigencia+"_"+tableData.especial+"_"+tableData.status+"_"+tableData.datahora_criacao+"_"+tableData.caminho_pdf+"_"+tableData.datahora_modificacao+"_"+tableData.titulo+"_"+tableData.texto+"_"+tableData.modificacao+"_"+tableData.errata+"_"+tableData.id_ogG);

  }

  const openResend = (event) => {
    handleClose();
    setAnchorEl(null);
    handleResendPrevision(event, tableData.id+"_"+tableData.intensidade+"_"+tableData.condicao_tempo+"_"+tableData.plano+"_"+tableData.alvo+"_"+tableData.empresa+"_"+tableData.acumulado+"_"+tableData.probabilidade+"_"+tableData.nivel+"_"+tableData.rajada_vento+"_"+tableData.direcao_vento+"_"+tableData.velocidade_vento+"_"+tableData.temperatura_min+"_"+tableData.temperatura_max+"_"+tableData.obs+"_"+tableData.tipo+"_"+tableData.id_alvo_id+"_"+tableData.nome_meteorologista+"_"+tableData.meteorologista+"_"+tableData.horizontes+"_"+tableData.vigencia+"_"+tableData.especial+"_"+tableData.status+"_"+tableData.datahora_criacao+"_"+tableData.caminho_pdf+"_"+tableData.datahora_modificacao+"_"+tableData.titulo+"_"+tableData.texto+"_"+tableData.modificacao+"_"+tableData.errata+"_"+tableData.id_ogG);

  }

  const splitMod = (modif) => {
    var aux = "";
    modif!=null? modif.map((value) => aux+= value+" ") : ""
    return aux

  }

  const constDate = (date) =>{
    if(date != null){
      const val_temp = date.split(':');
      const timedays = val_temp[0].split('T');
      const real_date = timedays[0].split('-').reverse().join('/');
      const hours = timedays[1]+":"+val_temp[1]

      return(real_date+" "+hours+"h")

    }else{
      return ""
    }
  };

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);
  
  console.log(anchorEl)
  return(
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      > 

      <Table style={{margin:"0px"}}>
        <TableHead>
          <div style={{width: "100%", height:"100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
            <div style={{color:"white", paddingTop:"10px", paddingLeft:"15px", fontSize:"20px"}}>
                <h2>{tableData.empresa} - {tableData.plano} - {tableData.alvo}</h2>
            </div>
          </div>
        </TableHead>
        <TableBody>

          <StyledTableRowDual>
            <StyledCellDual padding="normal" style={fonts.cellContent}><strong>Enviado em: </strong>{tableData.fullData.conteudo_modificacao!=null?  <div style={{display:"contents"}}>{constDate(tableData.fullData.conteudo_modificacao.datahora_modificacao)} - <s style={{color:"#6D3D06"}}>{tableData.fullData.datahora_criacao? constDate(tableData.fullData.datahora_criacao) : constDate(tableData.fullData.datahora_modificacao)}</s> </div> : tableData.fullData.datahora_criacao? constDate(tableData.fullData.datahora_criacao): constDate(tableData.fullData.datahora_modificacao) }</StyledCellDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Acumulado: </strong>{tableData.acumulado}</StyledCellDualBorder>
          </StyledTableRowDual>

          <StyledTableRowDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Modificações: </strong>{tableData.conteudo_modificacao!=null? "Sim" : "Não"}</StyledCellDualBorder>
          </StyledTableRowDual>

          <StyledTableRowDual>
            <StyledCellDual padding="normal" style={fonts.cellContent}><strong>Nível: </strong>{tableData.nivel? tableData.nivel : ""}</StyledCellDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Intensidade: </strong>{tableData.intensidade? tableData.intensidade : ""}</StyledCellDualBorder>
          </StyledTableRowDual>

          
          <StyledTableRowDual>
            <StyledCellDual padding="normal" style={fonts.cellContent}><strong>Rajada Vento: </strong>{tableData.rajada_vento? tableData.rajada_vento : ""}</StyledCellDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Velocidade Vento: </strong>{tableData.velocidade_vento? tableData.velocidade_vento : ""}</StyledCellDualBorder>
          </StyledTableRowDual>

        </TableBody>
      </Table>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
        <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px"}}>
          <div className={classes.noBorderButton} onClick={() => handleClose()}>
            <ArrowLeft color='black'></ArrowLeft>
          </div>
          <div style={{display:"flex", marginLeft: "auto"}}>
            {clientPage==undefined || !clientPage&&
              <>
              <div style={{marginLeft:"20px", padding:"5px 20px 5px 20px"}} className={classes.outlineButton1} onClick={(e) => openErrata(e)}>
                <span span>ERRATA</span>
              </div>
              <div style={{marginLeft:"20px", padding:"5px 20px 5px 20px"}} className={classes.outlineButton1} onClick={(e) => openRetification(e)}>
                <span span>RETIFICAÇÃO</span>
              </div>
              </>
            }
            <div style={{marginLeft:"20px",padding:"5px 20px 5px 20px", marginRight:"20px"}} className={classes.outlineButton1} onClick={(e) => openResend(e)}>
              <span span>REENVIO</span>
            </div>
          </div>
        </div>
      </div>
      </Popover>
  )
} 

ModalPrevision.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  clientPage: PropTypes.bool,
  handleErrataPrevision: PropTypes.func,
  handleResendPrevision: PropTypes.func, 
  handleRetificationPrevision: PropTypes.func,
  anchorEl: PropTypes.object,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalPrevision);