import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { SectionConteinerRow, SectionConteinerColumn, Button, Container, Divider, Section, StyledTable, StyledTableHead, StyledTableBody, StyledTableRow, StyledTableCell } from './styled';
import './ControlPanel.css';
/* Material */
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core/styles";
// Icons
import { FaHistory } from 'react-icons/fa';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Hooks
import {useRadar} from 'Context/RadarContext';
import {useSatellite} from 'Context/SatelliteContext.js';
import useToggle from 'hooks/useToggle';
import {useControlPanel} from 'Context/ControlPanelContext';

const useStyles = makeStyles({
  styledTab: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#023047",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#023047'
    }
  },
})

const StatisticsPage = (props) => {
  const {width, open} = props
  const {showSatellite} = useSatellite();
  const [tab, setTab] = useState(0);
  const theme = useTheme();
  const tabStyle = useStyles();
  const data = useSelector(state => state.historyDischargesDataProvider);
  const dataControl = useSelector(state => state.historyDischargesControlProvider);


  const handleChangeTabs = (index) => {
    setTab(index);
  };

  const toggleChangeTabs = (event, newValue) => {
    setTab(newValue);
  };

  const testeTable1_1 = [
    {
      network: "Rede Solo IN",
      min10: "",
      min20: "",
      min30: "",
      min40: "",
      min50: "",
      min60: "",
    },
    {
      network: "Rede Solo NS",
      min10: "",
      min20: "",
      min30: "",
      min40: "",
      min50: "",
      min60: "",
    },
    {
      network: "Rede Satélite Flashes",
      min10: 4,
      min20: 10,
      min30: 12,
      min40: 6,
      min50: 11,
      min60: 13,
    },
    {
      network: "Rede Satélite Groups",
      min10: 18,
      min20: 27,
      min30: 19,
      min40: 32,
      min50: 37,
      min60: 28,
    },
  ]

  const testeTable1_2 = [
    {
      network: "Rede Solo IN",
      min10: "",
      min20: "",
      min30: "",
      min40: "",
      min50: "",
      min60: "",
    },
    {
      network: "Rede Solo NS",
      min10: "",
      min20: "",
      min30: "",
      min40: "",
      min50: "",
      min60: "",
    },
    {
      network: "Rede Satélite Flashes",
      min10: 4,
      min20: 10,
      min30: 12,
      min40: 6,
      min50: 11,
      min60: 13,
    },
    {
      network: "Rede Satélite Groups",
      min10: 18,
      min20: 27,
      min30: 19,
      min40: 32,
      min50: 37,
      min60: 28,
    },
  ]

  const testeTable1_3 = [
    {
      network: "Rede Solo IN",
      min10: "",
      min20: "",
      min30: "",
      min40: "",
      min50: "",
      min60: "",
    },
    {
      network: "Rede Solo NS",
      min10: "",
      min20: "",
      min30: "",
      min40: "",
      min50: "",
      min60: "",
    },
    {
      network: "Rede Satélite Flashes",
      min10: 0,
      min20: 0,
      min30: 0,
      min40: 0,
      min50: 0,
      min60: 0,
    },
    {
      network: "Rede Satélite Groups",
      min10: 0,
      min20: 0,
      min30: 0,
      min40: 0,
      min50: 0,
      min60: 0,
    },
  ]

  const testeTable2 = [
    {
      network: "Rede Solo IN",
      first: "",
      last: "",
    },
    {
      network: "Rede Solo NS",
      first: "",
      last: "",
    },
    {
      network: "Rede Satélite Flashes",
      first: "",//"12/08/2023 09:32:22.410",
      last: "",//"24/08/2023 23:51:30.900",
    },
    {
      network: "Rede Satélite Groups",
      first: "",//"12/08/2023 12:50:03.500",
      last: "",//"24/08/2023 20:55:03.720",
    },
  ]
  
  if(open){
    return (
      <Container open={open}>
        <Section>
          <Tabs
            value={tab}
            onChange={toggleChangeTabs}
            className={tabStyle.styledTab}  
            variant="scrolable"
            aria-label="full width tabs example"
            style={{display: "flex", alignItems: "center" }}
            centered={true}
          >
            {data[0]?.length > 0 && data[0]?.map((target, index) => {
              if(dataControl[0][index]){
                return(
                  <Tab label={target.properties.nome} key={index}/>
                )
              }
            })}
          </Tabs>
          <Divider style={{height: "3px"}}/>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", marginTop: "15px"}}>
            <Button>Exportar</Button>
          </div>

          {tab === 0 && 
            <SectionConteinerColumn>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Rede</StyledTableCell>
                    <StyledTableCell>10 min</StyledTableCell>
                    <StyledTableCell>20 min</StyledTableCell>
                    <StyledTableCell>30 min</StyledTableCell>
                    <StyledTableCell>40 min</StyledTableCell>
                    <StyledTableCell>50 min</StyledTableCell>
                    <StyledTableCell>60 min</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {testeTable1_1.map((data, index) => {
                    return(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{data.network}</StyledTableCell>
                        <StyledTableCell>{data.min10}</StyledTableCell>
                        <StyledTableCell>{data.min20}</StyledTableCell>
                        <StyledTableCell>{data.min30}</StyledTableCell>
                        <StyledTableCell>{data.min40}</StyledTableCell>
                        <StyledTableCell>{data.min50}</StyledTableCell>
                        <StyledTableCell>{data.min60}</StyledTableCell>
                      </StyledTableRow>
                    )
                 })}
                </StyledTableBody>
              </StyledTable>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Rede</StyledTableCell>
                    <StyledTableCell>Primeira descarga</StyledTableCell>
                    <StyledTableCell>Última descarga</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {testeTable2.map((data, index) => {
                    return(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{data.network}</StyledTableCell>
                        <StyledTableCell>{data.first}</StyledTableCell>
                        <StyledTableCell>{data.last}</StyledTableCell>
                      </StyledTableRow>
                    )
                 })}
                </StyledTableBody>
              </StyledTable>
            </SectionConteinerColumn>
          }
           {tab === 1 && 
            <SectionConteinerColumn>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Rede</StyledTableCell>
                    <StyledTableCell>10 min</StyledTableCell>
                    <StyledTableCell>20 min</StyledTableCell>
                    <StyledTableCell>30 min</StyledTableCell>
                    <StyledTableCell>40 min</StyledTableCell>
                    <StyledTableCell>50 min</StyledTableCell>
                    <StyledTableCell>60 min</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {testeTable1_2.map((data, index) => {
                    return(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{data.network}</StyledTableCell>
                        <StyledTableCell>{data.min10}</StyledTableCell>
                        <StyledTableCell>{data.min20}</StyledTableCell>
                        <StyledTableCell>{data.min30}</StyledTableCell>
                        <StyledTableCell>{data.min40}</StyledTableCell>
                        <StyledTableCell>{data.min50}</StyledTableCell>
                        <StyledTableCell>{data.min60}</StyledTableCell>
                      </StyledTableRow>
                    )
                 })}
                </StyledTableBody>
              </StyledTable>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Rede</StyledTableCell>
                    <StyledTableCell>Primeira descarga</StyledTableCell>
                    <StyledTableCell>Última descarga</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {testeTable2.map((data, index) => {
                    return(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{data.network}</StyledTableCell>
                        <StyledTableCell>{data.first}</StyledTableCell>
                        <StyledTableCell>{data.last}</StyledTableCell>
                      </StyledTableRow>
                    )
                 })}
                </StyledTableBody>
              </StyledTable>
            </SectionConteinerColumn>
          }
           {tab === 2 && 
            <SectionConteinerColumn>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Rede</StyledTableCell>
                    <StyledTableCell>10 min</StyledTableCell>
                    <StyledTableCell>20 min</StyledTableCell>
                    <StyledTableCell>30 min</StyledTableCell>
                    <StyledTableCell>40 min</StyledTableCell>
                    <StyledTableCell>50 min</StyledTableCell>
                    <StyledTableCell>60 min</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {testeTable1_3.map((data, index) => {
                    return(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{data.network}</StyledTableCell>
                        <StyledTableCell>{data.min10}</StyledTableCell>
                        <StyledTableCell>{data.min20}</StyledTableCell>
                        <StyledTableCell>{data.min30}</StyledTableCell>
                        <StyledTableCell>{data.min40}</StyledTableCell>
                        <StyledTableCell>{data.min50}</StyledTableCell>
                        <StyledTableCell>{data.min60}</StyledTableCell>
                      </StyledTableRow>
                    )
                 })}
                </StyledTableBody>
              </StyledTable>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell>Rede</StyledTableCell>
                    <StyledTableCell>Primeira descarga</StyledTableCell>
                    <StyledTableCell>Última descarga</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {testeTable2.map((data, index) => {
                    return(
                      <StyledTableRow key={index}>
                        <StyledTableCell>{data.network}</StyledTableCell>
                        <StyledTableCell>{data.first}</StyledTableCell>
                        <StyledTableCell>{data.last}</StyledTableCell>
                      </StyledTableRow>
                    )
                 })}
                </StyledTableBody>
              </StyledTable>
            </SectionConteinerColumn>
          }
        </Section>
      </Container>
    )
  }
}

StatisticsPage.propTypes = {
  width: PropTypes.any,
  dataTargets: PropTypes.any,
  open: PropTypes.any
};

export default StatisticsPage;
