export default function (state = 0, action){
    switch(action.type){
        case "NAVIGATION_PROVIDER":
            return action.payload
            
    default: return state
    }
}




