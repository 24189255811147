import { brasilApi } from '../api';

export const getHolidays = async (year) => {
    try {
        const response = await brasilApi.get(`${year}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

