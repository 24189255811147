/* Libraries */
import React from 'react';
//import { useDispatch } from 'react-redux';
/* Services */
import { deleteCompany } from 'services/adminDashboard/company';
import { deletePlan } from 'services/adminDashboard/plan';
import { deleteEmployee } from 'services/adminDashboard/employees';
import { deleteTarget } from 'services/adminDashboard/target';
import { deleteService } from 'services/adminDashboard/services';
import { patchEmployees_Targets } from 'services/adminDashboard/employeesTarget';
import { deleteNew } from 'services/SitePortal/newsApi';
import { deleteWeather } from 'services/SitePortal/weatherApi';
import { deleteNucleoEmployee } from 'services/adminDashboard/nucleoEmployees';

export async function deleteData(data) {
    var errorsData = [];
    var successData = [];

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    if (data[3] === 'Empresas' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteCompany(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    } else if (data[3] === 'Planos' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deletePlan(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    /*DELETAR AQUIII */
                    unique = unique[0] === 'Planos_Alvos' ? 'Alvos' : unique;
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    } else if (data[3] === 'Usuários' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteEmployee(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    } else if (data[3] === 'Alvos' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteTarget(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    } else if (data[3] === 'Serviços' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteService(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    } else if (data[3] === 'Contatos' && data[1] && data[1].length > 0) {
        var employeeTarget_data = {
            id_usuario: data[1][1],
            id_alvo: data[1][2],
            prioridade: null,
        };

        await patchEmployees_Targets(data[1][0], employeeTarget_data).then(
            (response) => {
                if (response.status != 200) {
                    errorsData.push({
                        error: 'Problema ao deletar contato. Recarregue a página e tente novamente!',
                        dataName: data[2],
                    });
                } else {
                    successData.push(data[2]);
                }
            },
        );
    } else if (data[3] === 'Noticias' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteNew(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    } else if (data[3] === 'Previsoes' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteWeather(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    }
    else if (data[3] === 'UsuariosNucleo' && data[1] && data[1].length > 0) {
        for (let i = 0; i < data[1].length; i++) {
            await deleteNucleoEmployee(data[1][i]).then((response) => {
                if (response.status === 409) {
                    var unique =
                        response.data.registros_existentes.filter(onlyUnique);
                    errorsData.push({ error: unique, dataName: data[2][i] });
                } else {
                    successData.push(data[2][i]);
                }
            });
        }
    }
    

    if (errorsData.length > 0) {
        return ['error', errorsData];
    } else {
        return ['success', successData];
    }
}
