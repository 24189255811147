import styled from 'styled-components';

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  height: 100%;
`

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align ? props.align : 'center'};
  justify-content: ${props => props.justify ? props.justify : 'center'};
  margin: ${props => props.margin ? props.margin : 'none'};
  flex-direction: row;
  gap: ${props => props.gap ? props.gap : "0"};
  width: ${props => props.w ? props.w : "auto"};
  flex-wrap: ${props => props.wrap ? props.wrap : 'nowrap'};
`

export const ColumnOverFlow = styled(Row)`
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  padding: 140px 0 20px 0;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: white;
  },
  ::-webkit-scrollbar-thumb {
    background-color: #1D2026;
    border-radius: 12px;
  }
`

export const Column = styled(Row)`
  flex-direction: column;
`
