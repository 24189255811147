import { djangoApi } from '../api';

export const postEmployees = (employeesData) => {

    return djangoApi.post(`usuarios/`, employeesData);
}

export const getEmployeesByCompanyID = async (id_company) => {
    try {
        const response = await djangoApi.get(`empresas/geral/${id_company}/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const updateEmployees = (id_employees, employeesData) => {
    return djangoApi.put(`usuarios/${id_employees}/`, employeesData);
}

export const getEmployees = async (id_employees) => {
    try {
        const response = await djangoApi.get(`usuarios/${id_employees}/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getEmployeesInfosByCompanyID = async (id_company) => {
    try {
        const response = await djangoApi.get(`usuarios_infos/?id_empresa=${id_company}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getEmployeesInfosByEmployeeID = async (id_employee) => {
    try {
        const response = await djangoApi.get(`usuarios_infos/?id_user=${id_employee}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getEmployeesCompanyInfosByEmployeeID = async (id_employee) => {
    try {
        const response = await djangoApi.get(`usuariosempresa_infos/?id_usuario=${id_employee}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const deleteEmployee = async (id_employee) => {
    try {
        const response = await djangoApi.delete(`usuarios/${id_employee}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
};

export const patchEmployee = (id_employee, employeeData) => {
    return djangoApi.put(`usuarios/${id_employee}/`, employeeData);
};





