export default function (state = false, action){
    switch(action.type){
        case "OPEN_MAP_SIDEBAR":
            return action.payload
    default: return state
    }
}




