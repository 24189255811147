const styles = theme => ({
  portalsButton:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: "#FFB703",
    borderRadius: "5px",
    marginBottom: "25px",
    padding: "6px 14px 6px 14px",
    '& $text': {
      color: theme.palette.menu.strongYellow,
    },
    '&:hover': {
      background: theme.palette.menu.strongYellow,
      '& $text': {
        color: theme.palette.menu.strongBlue,
      },
    },
  },

  text: {
    color: theme.palette.text.white,
  },

  visibilityButton: {
    border: `1px solid ${theme.palette.menu.strongBlue}`,
    borderRadius: "8px",
    width: "22px",
    height: "22px"

  },
});

export default styles;