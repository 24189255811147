import styled from 'styled-components';

export const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
  height: 50px;
  width: ${props => props.containerWidth}px;
`

export const ItemContainer = styled.div`
  width: ${props => props.containerWidth}px;
`