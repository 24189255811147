const lightPalette = {
  greyTheme: {
    palette: {
      primary: {
        light: '#ECEFF1',
        main: '#607D8B',
        dark: '#455A64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#18328F',
        main: '#757575',
        dark: '#424242',
        contrastText: '#fff',
      },
    },
  },
  blueCyanTheme: {
    palette: {
      menu: {
        white: '#FFFFFF',
        strongBlue: '#023047',
        mediumBlue: '#0F4F6F',
        lightBlue: '#8ECAE6',
        strongYellow: '#FFB703',
        orange: "#DF7600",
        mediumGrey: "#848484",
        main: '#42369A',
        dark: '#12266E',
        darker: '#0C1A4A',
        contrastText: '#fff',
      },
      text:{
        white: '#FFFFFF',
      },
      primary: {
        light: '#42369A',
        main: '#023047',
        mediumGrey: "#848484",
        dark: '#12266E',
        darker: '#0C1A4A',
        contrastText: '#fff',
      },
      secondary: {
        light: '#023047',
        main: '#fff',
        dark: '#00838F',
        contrastText: '#fff',
      },
      background: {
        selected: '#B7B7B7',
        light: '#D6D6D6',
        main: '#EFEFEF',
        dark: '#FFF'
      }
    },
  }
};

export default lightPalette;
