import { djangoApi } from '../api';

export const getWeather = async (id) => {
    try {
        const response = await djangoApi.get(`previsoes/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching eather forecast:', error);
        return [];
    }
};

export const postWeather = (weatherData) => {
    return djangoApi.post(`previsoes/`, weatherData);
};

export const updateWeather = (id_weather, weatherData) => {
    return djangoApi.put(`previsoes/${id_weather}/`, weatherData);
};

export const deleteWeather = async (id_weather) => {
    try {
        const response = await djangoApi.delete(`previsoes/${id_weather}/`);
        return response.data;
    } catch (error) {
        return error.response;
    }
};

export const patchWeather = (id_weather, weatherData) => {
    return djangoApi.patch(`previsoes/${id_weather}/`, weatherData);
};
