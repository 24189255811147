export default function (state = false, action){
    switch(action.type){
        case "MODAL_PROVIDER":
            return [action.payload[0], action.payload[1], action.payload[2], action.payload[3], action.payload[4], action.payload[5]]
            
    default: return state
    }
}



