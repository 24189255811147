/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import 'moment/locale/pt-br';
/* Material UI */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ReportProblem from "@material-ui/icons/ReportProblem";
import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
/* CSS */
import styles from "../../forecast_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Services */
import { getUserAuthenticated } from "services/login/login";
import { getEmployees_TargetByTargetID } from "services/adminDashboard/employeesTarget";
import { getEmployees } from "services/adminDashboard/employees";


const useStyles = makeStyles({
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
  switch_secondary: {
    "&.Mui-checked": {
      color: "red",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "red",
    },
  },
  divider: {
    background: "#A5A5A5",
  },
})


const FieldsComponent = (props) => {

  const moment = require('moment');
  moment.locale('pt-br');
  const switchStyle = useStyles();
  const fonts = fontStyles();
  const { classes, target, preset, alertsData, setAlertsData, planoId, empresaId, setReloadSwitchSelected, setReloadModal, allSelected, setAllSelected, allSelectedAux, setAllSelectedAux, statusTargetSelected, setStatusTargetSelected, setReloadStatusTargetSelected, reloadTargets, setReloadTargets, setIntensityErrorReport, setConditionErrorReport, setAcumulatedErrorReport, setProb_lightningErrorReport, setWindErrorReport, setMaxTempErrorReport, setMinTempErrorReport, setLevelErrorReport, setWindVelErrorReport, setWindSprintErrorReport, intensityErrorReport, conditionErrorReport, acumulatedErrorReport, prob_lightningErrorReport, windErrorReport, maxTempErrorReport, minTempErrorReport, levelErrorReport, windVelErrorReport, windSprintErrorReport, setReloadErrorMessage } = props;
  const [servicesStatus, setServicesStatus] = useState(true);
  const [reload, setReload] = useState(false);
  const [contacts, setContacts] = useState();
  const [loadTarget, setLoadTarget] = useState(false);
  const [caractersRemaning, setCaractersRemaning] = useState();
  const [switchSelected, setSwitchSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingInfos, setLoadingInfos] = useState(false);
  const [reportCardAlert, setReportCardAlert] = useState(
    {
      acumulado: "",
      intensidade: "",
      condicao_tempo: "",
      probabilidade: "",
      nivel: "",
      rajada_vento: "",
      direcao_vento: "",
      velocidade_vento: "",
      temperatura_min: "",
      temperatura_max: "",
      observacao: null,
      meteorologista: "",
      id_alvo: "",
      datahora_boletim: null,
      empresa: null,
    }
  );

  var alertsDataAux = alertsData;

  var gridClasses = [
    classes.gridColumnsComponentRain,
    classes.gridMarginLeftRain,
  ]

  const toggleSwitchSelected = () => {
    if(loadTarget){
      if(!switchSelected){
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if(index != -1){
          setAllSelectedAux(false);
          setSwitchSelected(!switchSelected);
        }
        else{
          alertsDataAux.push(reportCardAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(!switchSelected);
        }
      }
      else{
        setAllSelected(false);
        setAllSelectedAux(true);
        alertsDataAux = alertsDataAux.filter((item) =>target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(!switchSelected);
      }
    }
    setReloadSwitchSelected(true);
  }

  const loadServices = async () => {
    if (planoId) {
      /*await getServicesByPlanID(planoId).then((response) => {
        if (response && response.length > 0) {
          servicesTypeValidate(response);
        }
      })*/
    }
  }

  const servicesTypeValidate = (services) => {
    if (services && services.length > 0) {
      let servicesList = [];
      for (let i = 0; i < services.length; i++) {
        if (services[i].tipo === "Boletim Meteorológico") {
          servicesList.push(services[i]);
        }
      }
      servicesMonitoringValidate(servicesList);
    }
  }

  const servicesMonitoringValidate = (servicesList) => {
    if (servicesList && servicesList.length > 0) {
      let servicesValidate = [];
      var today = moment().format('dddd');
      var now = moment().format('LT');
      var days = false;
      var hours = false;

      for (let i = 0; i < servicesList.length; i++) {
        let outputDays = servicesList[i].dias_monitoramento

        if (today === "segunda-feira" && outputDays.includes("Seg,")) {
          days = true;
          today = "Seg";
        }
        else if (today === "terça-feira" && outputDays.includes("Ter,")) {
          days = true;
          today = "Ter";
        }
        else if (today === "quarta-feira" && outputDays.includes("Qua,")) {
          days = true;
          today = "Qua";
        }
        else if (today === "quinta-feira" && outputDays.includes("Qui,")) {
          days = true;
          today = "Qui";
        }
        else if (today === "sexta-feira" && outputDays.includes("Sex,")) {
          days = true;
          today = "Sex";
        }
        else if (today === "sábado" && outputDays.includes("Sab,")) {
          days = true;
          today = "Sab";
        }
        else if (today === "domingo" && outputDays.includes("Dom,")) {
          days = true;
          today = "Dom";
        }
        if (days === true) {
          let tempInit = servicesList[i].datafunc_hora_inicio;
          let tempFinal = servicesList[i].datafunc_hora_fim;
          let auxInit = tempInit[0] + tempInit[1] + tempInit[2] + tempInit[3] + tempInit[4];
          let auxFinal = tempFinal[0] + tempFinal[1] + tempFinal[2] + tempFinal[3] + tempFinal[4];
          now >= auxInit && now <= auxFinal ? hours = true : hours = false;

          if (days && hours) {
            servicesValidate.push(servicesList[i])
          }
          setLoadTarget(true);
        }
      }
      if (servicesValidate && servicesValidate.length === 0) {
        setServicesStatus(false);
      }
    }
  }

  const loadUser = async () => {
    var loginToken = JSON.parse(localStorage.getItem("reeWAL57qe"));

    await getUserAuthenticated(loginToken).then((response) => {
      if (response.status === 200) {
        reportCardAlert.meteorologista = response.data.usuario.id;
      }
    })
  }

  const loadEmployeesTargets = async () => {
    await getEmployees_TargetByTargetID(target.id_alvo).then((response) => {
      if (response.length > 0) {
        var employeesList = [];
        for (let i = 0; i < response.length; i++) {
          employeesList.push([response[i].id_usuario, response[i].prioridade])
        }
        loadContacts(employeesList)
      }
    })
  }

  const loadContacts = async (employeesData) => {
    for (let i = 0; i < employeesData.length; i++) {
      var aux = employeesData[i];
      var employeesList = [{ nome: "Contato impossível", prioridade: [""] }, { nome: "Contato não realizado", prioridade: [""] }, { nome: "Não há contato telefônico no horário deste alerta", prioridade: [""] }];
      await getEmployees(aux[0]).then((response) => {
        if (response) {
          employeesList.push({ nome: response.nome, prioridade: aux[1] })
        }
      })
    }
    setContacts(employeesList);
    setLoading(false);
  }


  useEffect(() => {
    reportCardAlert.acumulado = preset.acumulado;
    reportCardAlert.intensidade = preset.intensidade;
    reportCardAlert.condicao_tempo = preset.condicao_tempo;
    reportCardAlert.probabilidade = preset.probabilidade;
    reportCardAlert.direcao_vento = preset.direcao_vento;
    reportCardAlert.temperatura_min = preset.temperatura_min;
    reportCardAlert.temperatura_max = preset.temperatura_max;
    reportCardAlert.nivel = preset.nivel;
    reportCardAlert.velocidade_vento = preset.velocidade_vento;
    reportCardAlert.rajada_vento = preset.rajada_vento;
    reportCardAlert.observacao = preset.observacao;
    setReloadModal(true);
  }, [preset.preset])

  useEffect(() => {
    if (target) {
      reportCardAlert.id_alvo = target.id_alvo;
      reportCardAlert.empresa = empresaId;
      loadServices();
      loadUser();
      loadEmployeesTargets();
    }
  }, []);

  useEffect(() => {
    if(loadTarget){
      if(allSelected){
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if(index != -1){
          setAllSelectedAux(false);
          setSwitchSelected(allSelected);
        }
        else{
          alertsDataAux.push(reportCardAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(allSelected);
        }  
      }
      else if(!allSelected && !allSelectedAux){
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(allSelected);
      }
    }
  }, [allSelected])

  useEffect(() => {
    if(!servicesStatus){
      let aux = statusTargetSelected;
      if(!aux.includes((nome) => nome === target.nome)){
        aux.push(target.nome);
        setStatusTargetSelected(aux);
        setReloadStatusTargetSelected(true);

      }
    }
  }, [switchSelected])

  useEffect(() => {
    if(loadTarget){
      if(switchSelected){
        if (reportCardAlert.acumulado === "") {
          let aux = acumulatedErrorReport;
          aux.push("Campo Acumulado é obrigatório!")
          setAcumulatedErrorReport(aux);
        }
        else {
          let aux = acumulatedErrorReport;
          aux.pop("Campo Acumulado é obrigatório!")
          setAcumulatedErrorReport(aux);
        }
        if (reportCardAlert.condicao_tempo === "") {
          let aux = conditionErrorReport;
          aux.push("Campo Condição do tempo é obrigatório!")
          setConditionErrorReport(aux);
        }
        else {
          let aux = conditionErrorReport;
          aux.pop("Campo Condição do tempo é obrigatório!")
          setConditionErrorReport(aux);
        }
        if (reportCardAlert.probabilidade === "") {
          let aux = prob_lightningErrorReport;
          aux.push("Campo Probabilidade de raios é obrigatório!")
          setProb_lightningErrorReport(aux);
        }
        else {
          let aux = prob_lightningErrorReport;
          aux.pop("Campo Probabilidade de raios é obrigatório!")
          setProb_lightningErrorReport(aux);
        }
        if (reportCardAlert.intensidade === "") {
          let aux = intensityErrorReport;
          aux.push("Campo Intensidade é obrigatório!")
          setIntensityErrorReport(aux);
        }
        else {
          let aux = intensityErrorReport;
          aux.pop("Campo Intensidade é obrigatório!")
          setIntensityErrorReport(aux);
        }
        if (reportCardAlert.nivel === "") {
          let aux = levelErrorReport;
          aux.push("Campo Nível da tempestade é obrigatório!")
          setLevelErrorReport(aux);
        }
        else {
          let aux = levelErrorReport;
          aux.pop("Campo Nível da tempestade é obrigatório!")
          setLevelErrorReport(aux);
        }
        if (reportCardAlert.rajada_vento === "") {
          let aux = windSprintErrorReport;
          aux.push("Campo Rajada de vento é obrigatório!")
          setWindSprintErrorReport(aux);
        }
        else {
          let aux = windSprintErrorReport;
          aux.pop("Campo Rajada de vento é obrigatório!")
          setWindSprintErrorReport(aux);
        }
        if (reportCardAlert.direcao_vento === "") {
          let aux = windErrorReport;
          aux.push("Campo Vento é obrigatório!")
          setWindErrorReport(aux);
        }
        else {
          let aux = windErrorReport;
          aux.pop("Campo Vento é obrigatório!")
          setWindErrorReport(aux);
        }
        if (reportCardAlert.velocidade_vento === "") {
          let aux = windVelErrorReport;
          aux.push("Campo Velocidade do vento é obrigatório!")
          setWindVelErrorReport(aux);
        }
        else {
          let aux = windVelErrorReport;
          aux.pop("Campo Velocidade do vento é obrigatório!")
          setWindVelErrorReport(aux);
        }
        if (reportCardAlert.temperatura_min === "") {
          let aux = minTempErrorReport;
          aux.push("Campo Temperatura mínima é obrigatório!")
          setMinTempErrorReport(aux);
        }
        else {
          let aux = minTempErrorReport;
          aux.pop("Campo Temperatura mínima é obrigatório!")
          setMinTempErrorReport(aux);
        }
        if (reportCardAlert.temperatura_min === "") {
          let aux = maxTempErrorReport;
          aux.push("Campo Temperatura máxima é obrigatório!")
          setMaxTempErrorReport(aux);
        }
        else {
          let aux = maxTempErrorReport;
          aux.pop("Campo Temperatura máxima é obrigatório!")
          setMaxTempErrorReport(aux);
        }
        if (reportCardAlert.observacao && reportCardAlert.observacao.length > 1 && reportCardAlert.observacao.length < 50) {
          setCaractersRemaning(50 - reportCardAlert.observacao.length);
        }
        else {
          setCaractersRemaning("");
        }
      }
      else{
        let aux1 =  acumulatedErrorReport;
        let aux2 =  conditionErrorReport;
        let aux3 =  windSprintErrorReport;
        let aux4 =  windVelErrorReport;
        let aux5 =  windErrorReport;
        let aux6 =  maxTempErrorReport;
        let aux7 =  minTempErrorReport;
        let aux8 =  prob_lightningErrorReport;
        let aux9 =  levelErrorReport;
        let aux10 =  intensityErrorReport;
        aux1.pop("Campo Acumulado é obrigatório!");
        aux2.pop("Campo Condição do tempo é obrigatório!");
        aux3.pop("Campo Rajada de vento é obrigatório!");
        aux4.pop("Campo Velocidade do vento é obrigatório!");
        aux5.pop("Campo Vento é obrigatório!");
        aux6.pop("Campo Temperatura máxima obrigatório!");
        aux7.pop("Campo Temperatura mínima obrigatório!");
        aux8.pop("Campo Probabilidade de raios obrigatório!");
        aux9.pop("Campo Nível da tempestade obrigatório!");
        aux10.pop("Campo Intensidade obrigatório!");
      }
      setReloadErrorMessage(true);
      var index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);    

      if(index != -1){
        let aux = alertsDataAux;
        aux.splice(index, 1);
        aux.push(reportCardAlert);
        setAlertsData(aux);
      }
      else{
        let aux = alertsDataAux;
        aux.push(reportCardAlert);
        setAlertsData(aux);
      }
  }
    setReloadErrorMessage(false);
  }, [reportCardAlert?.intensidade, reportCardAlert?.nivel, reportCardAlert?.probabilidade, reportCardAlert?.temperatura_min, reportCardAlert?.temperatura_max, reportCardAlert?.condicao_tempo, reportCardAlert?.acumulado, reportCardAlert?.rajada_vento, reportCardAlert?.velocidade_vento, reportCardAlert?.observacao, preset.preset])


  if (target && !loading && loadTarget) {
    return (
      <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "14px" }}>
        <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={12} className={classes.gridContainerReportComponent}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Switch
                checked={switchSelected}
                onChange={() => toggleSwitchSelected()}
                value={switchSelected}
                size="small"
                classes={{
                  colorPrimary: servicesStatus ? switchStyle.switch_primary : switchStyle.switch_secondary
                }}
                color={"primary"} />

              <div style={{ marginLeft: "15px" }}>
                <Typography variant="subtitle1" style={!servicesStatus ? fonts.targetTitleWarning : fonts.targetTitle}>
                  {target.nome}
                </Typography>
              </div>

              {!servicesStatus && <ReportProblem style={{ marginLeft: "8px", width: "20px", height: "20px", color: "red" }} />}
            </div>
          </Grid>

          <Grid item xs={2.5} className={classes.gridContainerReportComponent} style={{ marginTop: "5px" }}>
            <div className={classes.selectContainer} style={{ marginTop: "2px" }}>
              <Autocomplete
                id="intensity"
                freeSolo={false}
                value={reportCardAlert?.intensidade}
                inputValue={reportCardAlert?.intensidade}
                disableClearable
                onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, intensidade: newValue })}
                className={classes.selectFieldRainInt}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Fraca", "Fraca a moderada", "Moderada", "Moderada a forte", "Forte", "Muito forte", "Extrema"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Intensidade da chuva"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentCond} style={{ marginTop: "5px" }}>
            <div className={classes.selectContainer}>
              <Autocomplete
                id="condition"
                freeSolo={false}
                value={reportCardAlert?.condicao_tempo}
                inputValue={reportCardAlert?.condicao_tempo}
                disableClearable
                onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, condicao_tempo: newValue })}
                className={classes.selectFieldTempCond}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Chuva no período da tarde"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Condição do tempo"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentAcum} style={{ marginTop: "3px" }}>
            <InputMask
              mask="999 - 999 mm"
              value={reportCardAlert?.acumulado}
              onChange={(e) => setReportCardAlert({ ...reportCardAlert, acumulado: e.target.value })}
              disabled={false}
              maskChar=" "
            >{() =>
              <TextField
                id="acumuladoInit"
                className={classes.textFieldAcum}
                variant="outlined"
                label="Acumulado"
                inputProps={{ style: fonts.formsFieldHeight1 }}
                InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
              />}
            </InputMask>
          </Grid>

          <Grid item xs={3.5} className={classes.gridColumnsReportContentLightProb} style={{ marginTop: "5px" }}>
            <div className={classes.selectContainer}>
              <Autocomplete
                id="lightning_prob"
                freeSolo={false}
                value={reportCardAlert?.probabilidade}
                inputValue={reportCardAlert?.probabilidade}
                disableClearable
                onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, probabilidade: newValue })}
                className={classes.selectFieldLightProb}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Baixa", "Média", "Alta"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Prob. Raios"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentWindS} style={{ marginTop: "3px" }}>
            <div className={classes.selectContainer}>
              <Autocomplete
                id="wind_sprint"
                freeSolo={false}
                value={reportCardAlert?.rajada_vento}
                inputValue={reportCardAlert?.rajada_vento}
                disableClearable
                onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, rajada_vento: newValue })}
                className={classes.selectFieldWindS}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Brisa Fraca, rajadas entre 12.01 e 20.00 km/h"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Rajada de vento"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={3.5} className={classes.gridColumnsReportContentLevel} style={{ marginTop: "3px" }}>
            <div className={classes.selectContainer} style={{ marginLeft: "1px" }}>
              <Autocomplete
                id="level"
                freeSolo={false}
                value={reportCardAlert?.nivel}
                inputValue={reportCardAlert?.nivel}
                disableClearable
                onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, nivel: newValue })}
                className={classes.selectFieldLevelContent}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["T1", "T2", "T3", "T4"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Nível"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentWVel}>
            <InputMask
              mask="99 km/h"
              value={reportCardAlert?.velocidade_vento}
              onChange={(e) => setReportCardAlert({ ...reportCardAlert, velocidade_vento: e.target.value })}
              disabled={false}
              maskChar=" "
            >{() =>
              <TextField
                id="velocidade_vento"
                className={classes.textFieldWindVel}
                variant="outlined"
                label="Vel. do vento"
                inputProps={{ style: fonts.formsFieldHeight1 }}
                InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
              />}
            </InputMask>
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentWind} style={{ marginTop: "2px" }}>
            <div className={classes.selectContainer}>
              <Autocomplete
                id="wind"
                freeSolo={false}
                value={reportCardAlert?.direcao_vento}
                inputValue={reportCardAlert?.direcao_vento}
                disableClearable
                onChange={(_, newValue) => setReportCardAlert({ ...reportCardAlert, direcao_vento: newValue })}
                className={classes.textFieldWindContent}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Sul", "Leste", "Norte", "Oeste", "Sudeste", "Sudoeste", "Nordeste", "Noroeste"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Vento"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentMinTemp}>
            <TextField
              id="temp_min"
              className={classes.textFieldMinTemp}
              value={reportCardAlert?.temperatura_min}
              onChange={(e) => setReportCardAlert({ ...reportCardAlert, temperatura_min: e.target.value })}
              variant="outlined"
              type="tel"
              label="Temp. Mínima"
              inputProps={{ style: fonts.formsFieldHeight1 }}
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end" style={{alignSelf:"end"}}>°C</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={2.5} className={classes.gridColumnsReportContentMaxTemp}>
            <TextField
              id="temperatura_max"
              className={classes.textFieldMaxTemp}
              variant="outlined"
              value={reportCardAlert?.temperatura_max}
              onChange={(e) => setReportCardAlert({ ...reportCardAlert, temperatura_max: e.target.value })}
              label="Temp. Máxima"
              type="tel"
              inputProps={{ style: fonts.formsFieldHeight1 }}
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end" style={{alignSelf:"end"}}>°C</InputAdornment>,
              }}
            />
          </Grid>


          <Grid item xs={2.5} className={classes.gridColumnsReportContentComm}>
            <TextField
              id="comments"
              label="Observações"
              multiline
              rows={2}
              value={reportCardAlert?.observacao}
              onChange={(e) => setReportCardAlert({ ...reportCardAlert, observacao: e.target.value })}
              className={classes.textFieldComm}
              shrink={true}
              variant="outlined"
              color="primary"
              inputProps={{ style: fonts.formsFieldHeight2, maxLength: 50 }}
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }} />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" justifyContent="center" style={{ marginTop: "14px" }}>
          <Grid item xs={12}>
            <Divider classes={{ root: switchStyle.divider }} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
  /*else if (loading && loadTarget) {
    return (
      <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" />
    )
  }*/
}

FieldsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  preset: PropTypes.any,
  target: PropTypes.any,
  planoId: PropTypes.any,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  setReloadModal: PropTypes.string.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  allSelectedAux: PropTypes.any,
  setAllSelectedAux: PropTypes.func.isRequired,
  setIntensityErrorReport: PropTypes.any.isRequired,
  setConditionErrorReport: PropTypes.any.isRequired,
  setAcumulatedErrorReport: PropTypes.any.isRequired,
  setProb_lightningErrorReport: PropTypes.any.isRequired,
  setWindErrorReport: PropTypes.any.isRequired,
  setMaxTempErrorReport: PropTypes.any.isRequired,
  setMinTempErrorReport: PropTypes.any.isRequired,
  setLevelErrorReport: PropTypes.any.isRequired,
  setWindVelErrorReport: PropTypes.any.isRequired,
  setWindSprintErrorReport: PropTypes.any.isRequired,
  intensityErrorReport: PropTypes.any.isRequired,
  conditionErrorReport: PropTypes.any.isRequired,
  acumulatedErrorReport: PropTypes.any.isRequired,
  prob_lightningErrorReport: PropTypes.any.isRequired,
  windErrorReport: PropTypes.any.isRequired,
  maxTempErrorReport: PropTypes.any.isRequired,
  minTempErrorReport: PropTypes.any.isRequired,
  levelErrorReport: PropTypes.any.isRequired,
  windVelErrorReport: PropTypes.any.isRequired,
  windSprintErrorReport: PropTypes.any.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
  empresaId: PropTypes.any.isRequired,
};

export default withStyles(styles)(FieldsComponent);