export default function (state = "home", action){
    switch(action.type){
        case "SIDEBAR_MENU_PROVIDER":
            return action.payload
            
    default: return state
    }
}




