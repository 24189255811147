import { useRef, useEffect } from 'react';
import useKey from 'hooks/useKey';
import { useTools } from 'Context/ToolsContext';
import {useControlPanel} from 'Context/ControlPanelContext';
import {useRadar} from 'Context/RadarContext';
import {useSatellite} from 'Context/SatelliteContext';
import {useTarget} from 'Context/TargetContext';
import {useDischarges} from 'Context/DischargesContext.js';
import {useMapContext} from 'Context/MapContext';

function useHotkeys () {
  const { toggleShowSidebar } = useControlPanel();
  const { toggleRadar} = useRadar();
  const { toggleSatellite } = useSatellite();
  const { toggleTargets, toggleTargetMerge } = useTarget();
  const { toggleDischarges } = useDischarges();
  const { toolInUse, setToolInUse, setMeasureLine, setPointHelper } = useTools();
  const { resetMapPosition } = useMapContext();

  // Estrutura, caso seja necessária alteração complexa de função de acordo
  // como modo selecionado.

  // const escFunction = useRef(() => false);

    // useEffect(() => {
    //   function esc () {
    //     switch (toolInUse){
    //       case 'default':
    //         return () => false;
    //     case 'measure':
    //     case 'selection':
    //       return () => setToolInUse('default');
    //     default:
    //       return () => false;
    //     }
    //   }
    //   escFunction.current = esc();
    // }, [toolInUse])


    useKey('Escape', () => setToolInUse('default'));
    useKey('Backquote', toggleShowSidebar);
    useKey('KeyQ', toggleSatellite);
    useKey('KeyW', toggleRadar);
    useKey('KeyE', toggleDischarges);
    useKey('KeyR', toggleTargets);
   // useKey('KeyT', toggleTargetMerge);
    useKey('KeyA', () =>
      toolInUse !== 'selection' ? setToolInUse('selection') : setToolInUse('default'));
    useKey('KeyS', () =>
      toolInUse !== 'measure' ? setToolInUse('measure') : setToolInUse('default'));
    useKey('KeyD', () => {
      setMeasureLine([])
      setPointHelper([])
    });
    useKey('KeyF', resetMapPosition);
}

export default useHotkeys;
