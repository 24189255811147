/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
/* Material UI */
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
/* Components */
import { LoginForm } from 'components';
import LogoNucleo from 'components/LogoNucleo';
/* CSS */
import styles from 'components/Forms/user-jss';
import fontStyles from 'styles/fonts-jss';
/* Images */
import lightningBackground from 'images/lightningBg3.png';


function LoginBanner(props) {
  const fonts = fontStyles();
  const { classes } = props;


  return (
    <div className={classes.rootFull}>
      <div className={classes.containerSide}>
        <div className={classes.sideFormWrap}>
          <LoginForm />
        </div>
        <Hidden smDown>
          <div className={`${classes.gradient} ${classes.opening}`}>
            <img className={classes.loginImg} srcSet={lightningBackground} alt="Céu escuro com inúmeros raios caindo no solo." />
          </div>
        </Hidden>
      </div>
    </div>
  );
}

LoginBanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginBanner);
