import { useEffect, useRef, useState, useCallback } from 'react'
import useToggle from 'hooks/useToggle';

const useSelect = (mapRef, toolInUse, setToolInUse) => {
  const [showPoligono, toggleShowPoligono] = useToggle();
  const [finishPolygon, toggleFinishPolygon] = useToggle();
  const [polygonLine, setPolygonLine] = useState([]);
  const [pointHelper, setPointHelper] = useState([]);
  const size = useRef(0);

  const resetPolygon = () => {
    setPolygonLine([]);
    setPointHelper([]);
    toggleFinishPolygon(false);
  }

  useEffect(() => {
    if(toolInUse === 'selection'){
      resetPolygon();
    }else{
      if(polygonLine[0] !== polygonLine[polygonLine.length-1])
        resetPolygon();
    }
  }, [toolInUse])

  useEffect(()=>{
    size.current = polygonLine.length
  }, [polygonLine.length])


  const map = mapRef.current;

  const functions = () => {

    const handleMapMouseMove = (event) => {
        const opt = { layers: ['polygonPoint'] };
          const layers = map.queryRenderedFeatures(event.point, opt);
          map.getCanvas().style.cursor = layers.length > 1 ? 'pointer' : 'crosshair';
          setPointHelper([event.lngLat.lng, event.lngLat.lat]);
    }

    const handleMapMouseClick = (event) => {
        const opt = { layers: ['polygonPoint'] };
        const layers = map.queryRenderedFeatures(event.point, opt);
        if(layers.length > 1) {
          if (layers[layers.length-1].id === 0 && size.current >= 3){
            setPolygonLine((prevMeasureLine) => [
              ...prevMeasureLine,
              prevMeasureLine[0]
            ]);
            toggleFinishPolygon(true);
            setPointHelper([]);
            setTimeout(()=>{
              setToolInUse('default')
            }, 150)
          }else{
            setPolygonLine((prevMeasureLine) => {
              const newMeasureLine = [...prevMeasureLine]
              newMeasureLine.splice(layers[1].id, 1)
              return newMeasureLine
            });
          }
        }else{
          setPolygonLine((prevMeasureLine) => [
              ...prevMeasureLine,
              [event.lngLat.lng, event.lngLat.lat],
          ]);
        }
      }

    const handleMapMouseLeave = (event) => {
          return;
    }

    const handleDblClick = (event) => {
      setPolygonLine((prevMeasureLine) => [
        ...prevMeasureLine,
        prevMeasureLine[0]
      ]);
      toggleFinishPolygon(true);
      setPointHelper([]);
      setToolInUse('default')
    }
    return { handleMapMouseClick, handleMapMouseLeave, handleMapMouseMove, handleDblClick }
  }


  const data = {
    name: 'select',
    component: '',
    functions
  }

  return {
    showPoligono,
    toggleShowPoligono,
    finishPolygon,
    resetPolygon,
    polygonLine,
    pointHelper,
    data
  };
}

export default useSelect
