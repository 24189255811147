const styles = theme => ({
  createButtonText:{
    color: theme.palette.menu.strongBlue,
    textTransform: "uppercase",
  },
  contentButtonContainer:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    padding: "6px 14px 6px 14px",
    '& $createButtonText': {
        color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
        background: theme.palette.menu.strongBlue,
        '& $createButtonText': {
        color: theme.palette.menu.white,
        },
    },
  },
});

export default styles;