const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({
/* Template */


/* General */
textFieldDefault: {
    width: `316px`,
    fontSize: '25px'
},
textFieldLightAlertIndex: {
    fontSize: '25px',
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: "700px"
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: "700px"
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: "621px"
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: "630px"
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: "670px"
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: "670px"
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: "698px"
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: "800px"
    },
},

textFieldLightAlertContent: {
    fontSize: '25px',
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: "700px"
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: "700px"
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: "617px"
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: "630px"
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: "670px"
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: "669px"
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: "698px"
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: "800px"
    },
},

obsLightingAlertIndex:{
    ['@media (min-width:0px) and (max-width:768px)']: { 
        marginLeft: "7px",
        marginTop: "5px"
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        marginLeft: "7px",
        marginTop: "5px"
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        marginLeft: "7px",
        marginTop: "5px"
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        marginLeft: "19px",
        marginTop: "5px"
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        marginLeft: "15px",
        marginTop: "5px"
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        marginLeft: "40px",
        marginTop: "5px"
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        marginLeft: "33px",
        marginTop: "5px"
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        marginLeft: "48px",
        marginTop: "5px"
    },
},

obsLightingAlertContent:{
    ['@media (min-width:0px) and (max-width:768px)']: { 
        marginLeft: "7px",
        marginTop: "5px"
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        marginLeft: "7px",
        marginTop: "5px"
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        marginLeft: "14px",
        marginTop: "10px"
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        marginLeft: "23px",
        marginTop: "8px"
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        marginLeft: "21px",
        marginTop: "5px"
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        marginLeft: "43px",
        marginTop: "5px"
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        marginLeft: "37px",
        marginTop: "5px"
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        marginLeft: "50px",
        marginTop: "5px"
    },
},

textFieldMedium: {
    width: `180px`,
},

textFieldSmall1: {
    width: `125px`,
    marginRight: `10px`,
},

textFieldSmall: {
    width: `160px`,
    height: '42px'
},

textFieldSmallRainAlert: {
    width: `115px`,
    height: '42px'
},

textFieldSmallLightAlert: {
    height: '20px',
    ['@media (max-width:768px)']: { 
        width: '67px'
    },
    ['@media (max-width:1024px)']: { 
        width: '69px'
    },
    ['@media (max-width:1280px)']: { 
        width: '69px'
    },
    ['@media (min-width:1366px)']: { 
        width: '69px'
    },
},

selectFieldLarge1: {
    width: `140px`,
    marginTop: `10px`,
    marginLeft: `80px`,
},

selectFieldLarge: {
    ['@media (max-width:1400px)']: { 
        width: '110px'
    },
    ['@media (min-width:1401px)']: { 
        width: `140px`,
    }
},

selectFieldLargeLightiningIndex: {
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: '292px',
        marginTop: "30px",
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '421px',
        marginTop: "30px",
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: '380px',
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: '373px'
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: '376px'
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: '374px'
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: '378px'
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: '440px'
    },
},

selectFieldLargeLightiningContent: {
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: '292px',
        marginTop: "30px",
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '421px',
        marginTop: "30px",
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: '377px',
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: '373px'
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: '376px'
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: '374px'
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: '378px'
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: '440px'
    },
},

selectFieldLargeReleases: {
    width: `110px`,
},

selectFieldLargeReleases1: {
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: '292px',
        marginTop: "30px",
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '421px',
        marginTop: "30px",
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: '355px',
    },
    ['@media (min-width:1281px) and (max-width:1366px)']: { 
        width: '356px'
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: '374px'
    },
    ['@media (min-width:1441px)']: { 
        width: '379px'
    },
},

selectFieldLightAlert: {
    ['@media (min-width:0) and (max-width:768px)']: { 
        width: '290px',
        marginTop: '30px'
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '416px',
        marginTop: '30px'
    },
    ['@media (min-width:1280px)']: { 
        width: `360px`,
    },
    ['@media (min-width:1600px)']: { 
        width: `362px`,
    },
    ['@media (min-width:1900px)']: { 
        width: `430px`,
    },
},

selectFieldLightAlertIndex: {
    ['@media (min-width:0) and (max-width:768px)']: { 
        width: '290px',
        marginTop: '30px'
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '416px',
        marginTop: '30px'
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: `520px`,
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: `525px`,
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: `560px`,
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: `558px`,
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: `573px`,
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: `658px`,
    },
},

selectFieldLightAlertComponent: {
    ['@media (min-width:0) and (max-width:768px)']: { 
        width: '290px',
        marginTop: '30px'
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '416px',
        marginTop: '30px'
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: `520px`,
        marginTop: '20px'
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: `523px`,
        marginTop: '20px'
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: `560px`,
        marginTop: '20px'
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: `559px`,
        marginTop: '20px'
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: `573px`,
        marginTop: '20px'
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: `658px`,
        marginTop: '20px'
    },
},

selectFieldRainAlert3: {
    ['@media (min-width:768px)']: { 
        width: '290px'
    },
    ['@media (min-width:1024px)']: { 
        width: '396px'
    },
    ['@media (min-width:1280px)']: { 
        width: `366px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `402px`,
    },
    ['@media (min-width:1536px)']: { 
        width: `403px`,
    },
    ['@media (min-width:1600px)']: { 
        width: `405px`,
    },
    ['@media (min-width:1900px)']: { 
        width: `402px`,
    },
},

selectFieldSmall: {
    width: `62px`,
    marginTop: `10px`,
},

selectFieldSmallLightAlert: {
    width: `73px`,
},

titleContainer: {
    marginLeft: '5%',
},

gridContainerIndex: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '2.4%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '4.7%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '4.6%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '7.5%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '7.8%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '9.8%',
    },
},

gridContainerIndexRain: {
    ['@media (min-width:768px)']: { 
        marginLeft: '13%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '9.8%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1.8%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '1.5%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '2.7%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '5.7%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '5.7%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '10.4%',
    },
},

gridContainerIndexRelease: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '3%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '7.8%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '8%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '13.3%',
    },
},

gridContainerComponent: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.8%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8.6%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '3.1%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '5.3%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '5.17%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '7.8%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '8.1%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '10%',
    },
},

gridContainerComponentRelease: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.8%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8.6%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '3.7%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '5.2%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '5.5%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '8.3%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '8.3%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '13.6%',
    },
},

gridContainerComponentRain: {
    ['@media (min-width:768px)']: { 
        marginLeft: '13.7%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8.6%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '3.7%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '2.5%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '3.3%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '6.2%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '6.2%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '10.5%',
    },
},

gridColumnsIndex: {
    ['@media (min-width:768px)']: { 
        marginLeft: '1.6%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1.3%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '2%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '2.5%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '3.7%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '5%',
    },
},

gridColumnsIndexRain: {
    ['@media (min-width:768px)']: { 
        marginLeft: '13%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '9.7%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1.3%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '4%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '3.5%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '5.2%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '7.3%',
    },
},

gridColumnsIndexRelease: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.5%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '3%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '4%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '5.1%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '5.9%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '7%',
    },
},

gridColumnsComponent: {
    ['@media (min-width:768px)']: { 
        marginLeft: '2.2%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '5.7%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '2%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '2.4%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '3.7%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '5%',
    },
},

gridColumnsComponentRelease: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.3%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8.4%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '2.3%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '3.3%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '5%',
    },
},

gridColumnsComponentRain: {
    ['@media (min-width:768px)']: { 
        marginLeft: '13.2%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '8.4%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '3%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '3.3%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '5%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '7.3%',
    },
},

gridMarginLeft:{
    ['@media (min-width:768px)']: { 
        marginLeft: '104px',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '230px',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '21px',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '29px',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '33px',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '45px',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '65px',
    },
},

gridMarginLeftRain:{
    ['@media (min-width:768px)']: { 
        marginLeft: '88px',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '197px',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '32px',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '32px',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '50px',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '64px',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '97px',
    },
},

gridMarginLeftRain2:{
    ['@media (min-width:768px)']: { 
        marginLeft: '42px',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '47px',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '13px',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '31px',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '29px',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '32px',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '48px',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '77px',
    },
},

gridMarginLeftRain3:{
    ['@media (min-width:768px)']: { 
        marginLeft: '47px',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '43px',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '11px',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '26px',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '29px',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '45px',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '77px',
    },
},

gridMarginLeftRelease:{
    ['@media (min-width:768px)']: { 
        marginLeft: '99px',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '225px',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '15px',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '23px',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '33px',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '45px',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '65px',
    },
},

gridMarginLeftComponentRelease:{
    ['@media (min-width:768px)']: { 
        marginLeft: '99px',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '225px',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '1px',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '12px',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '18px',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '35px',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '65px',
    },
},

gridButton: {
    ['@media (min-width:768px)']: { 
        marginLeft: '53%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '64%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '75.7%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '74.7%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '76.2%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '74.3%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '74.7%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '76%',
    },
},

gridButtonRelease: {
    ['@media (min-width:768px)']: { 
        marginLeft: '52%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '64%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '75.2%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '74.4%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '75.7%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '74.4%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '74.7%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '72.5%',
    },
},

gridButtonRain: {
    ['@media (min-width:768px)']: { 
        marginLeft: '52%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '64%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '76.5%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '79%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '77.8%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '76.4%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '77%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '75.5%',
    },
},

buttonContainer: {
    marginTop: `7%`,
    marginBottom: `5%`,
    marginRight: `8%`,
    display: "flex",
    justifyContent: "flex-end",
},

lightningButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "10px",
    backgroundColor: "#B067E9",
    borderRadius: "5px",
    color: "#FFFFFF",
},

rainButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "10px",
    backgroundColor: "#046DCA",
    borderRadius: "5px",
    color: "#FFFFFF",
},

releaseButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "10px",
    backgroundColor: "#27B624",
    borderRadius: "5px",
    color: "#FFFFFF",
},

selectContainer: {
    display: "flex",
    flexDirection: "column",
},

hourText: {
    margin: `0 7px 0 7px`,
},

marginLeft: {
    marginLeft: `20px`,
},

checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: `20px`,
},

errorMessage: {
    fontSize: `11px`,
    color: "red",
    margin: "5px 0 0 5px"
},

errorMessage2: {
    fontSize: `11px`,
    color: "red",
    margin: "0 0 0 20px"
},

errorMessage3: {
    fontSize: `11px`,
    color: "red",
    margin: "0 0 0 5px"
},

selectFieldRain1: {
    marginTop: `16px`,
    ['@media (min-width:1080px)']: { 
        width: '160px'
    },
    ['@media (min-width:1200px)']: { 
        width: '240px'
    },
    ['@media (min-width:1401px)']: { 
        width: `318px`,
    },
    ['@media (min-width:1500px)']: { 
        width: `256px`,
    },
},

selectFieldRain2: {
    marginTop: `18px`,
    ['@media (min-width:768px)']: { 
        width: '294px'
    },
    ['@media (min-width:1024px)']: { 
        width: '396px'
    },
    ['@media (min-width:1280px)']: { 
        width: `367px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `403px`,
    },
},

selectFieldRain3: {
    marginTop: `18px`,
    ['@media (min-width:768px)']: { 
        width: '289px'
    },
    ['@media (min-width:1024px)']: { 
        width: '396px'
    },
    ['@media (min-width:1280px)']: { 
        width: `360px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `403px`,
    },
    ['@media (min-width:1440px)']: { 
        width: `408px`,
    },
},


textFieldInput: {
    width: `346px`,
    fontSize: '25px'
},

textFieldRainAlert: {
    fontSize: '25px',
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: '293px',
        marginTop: '40px'
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '396px',
        marginTop: '40px'
    },
    ['@media (min-width:1280px)']: { 
        width: `367px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `403px`,
    },
},

textFieldInputReleases: {
    fontSize: '25px',
    ['@media (min-width:768px)']: { 
        width: '290px'
    },
    ['@media (min-width:1024px)']: { 
        width: '422px'
    },
    ['@media (min-width:1280px)']: { 
        width: `597px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `611px`,
    },
    ['@media (min-width:1440px)']: { 
        width: `648px`,
    },
    ['@media (min-width:1536px)']: { 
        width: `658px`,
    },
    ['@media (min-width:1600px)']: { 
        width: `677px`,
    },
    ['@media (min-width:1920px)']: { 
        width: `717px`,
    },
},

textFieldInputReleasesComponent: {
    fontSize: '25px',
    ['@media (min-width:768px)']: { 
        width: '298px'
    },
    ['@media (min-width:1024px)']: { 
        width: '422px',
    },
    ['@media (min-width:1280px)']: { 
        width: `595px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `617px`,
    },
    ['@media (min-width:1440px)']: { 
        width: `648px`,
    },
    ['@media (min-width:1536px)']: { 
        width: `650px`,
    },
    ['@media (min-width:1600px)']: { 
        width: `678px`,
    },
    ['@media (min-width:1920px)']: { 
        width: `718px`,
    },
    
},

selectFieldReleases1: {
    marginTop: `10px`,
    ['@media (min-width:768px)']: { 
        width: '288px'
    },
    ['@media (min-width:1024px)']: { 
        width: '417px'
    },
    ['@media (min-width:1280px)']: { 
        width: `362px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `362px`,
    },
    ['@media (min-width:1440px)']: { 
        width: `378px`,
    },
    ['@media (min-width:1600px)']: { 
        width: `385px`,
    },
},

selectFieldReleases2: {
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: '291px',
        marginTop: "30px"
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '422px',
        marginTop: "30px"
    },
    ['@media (min-width:1025px) and (max-width:1280px)']: { 
        width: `385px`,
    },
    ['@media (min-width:1281px) and (max-width:1367px)']: { 
        width: `385px`,
    },
    ['@media (min-width:1367px) and (max-width:1440px)']: { 
        width: `402px`,
    },
    ['@media (min-width:1441px) and (max-width:1536px)']: { 
        width: `407px`,
    },
    ['@media (min-width:1537px) and (max-width:1600px)']: { 
        width: `397px`,
    },
    ['@media (min-width:1601px) and (max-width:1920px)']: { 
        width: `399px`,
    },
    
},

selectFieldRainAlert2: {
    marginTop: '18px',
    paddingTop: "0px",
    ['@media (min-width:0px) and (max-width:768px)']: { 
        width: '294px',
        marginTop: "50px"
    },
    ['@media (min-width:769px) and (max-width:1024px)']: { 
        width: '395px',
        marginTop: "50px"
    },
    ['@media (min-width:1280px)']: { 
        width: `229px`,
    },
    ['@media (min-width:1366px)']: { 
        width: `227px`,
    },
    ['@media (min-width:1440px)']: { 
        width: `245px`,
    },
    ['@media (min-width:1600px)']: { 
        width: `260px`,
    },
    ['@media (min-width:1920px)']: { 
        width: `292px`,
    },
},

dateHourContainer: {
    display: "flex",
    alignItems: "center",
},

selectFieldRainAlert: {
    marginTop: '18px',
    paddingTop: "0px",
    ['@media (min-width:1080px)']: { 
        width: '220px'
    },
    ['@media (min-width:1200px)']: { 
        width: '282px'
    },
    ['@media (min-width:1401px)']: { 
        width: `318px`,
    },
    ['@media (min-width:1500px)']: { 
        width: `496px`,
    },
},
});

export default styles;