import {useState, useCallback} from 'react';

// Libs
import moment from 'moment';

// Components
import {Column, Row} from 'components/Flex';
import {CloseButton, ControlHeader, ControlPanel, Divider, PlayButton, ReloadButton} from './styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// Icons
import radarImg from 'images/monitoring/Radar.png'
import {Close} from '@material-ui/icons';
import { MdOpacity } from 'react-icons/md';
import {faArrowRotateRight} from '@fortawesome/free-solid-svg-icons';

// Hooks
import {useControlPanel} from 'Context/ControlPanelContext';
import {useRadar} from 'Context/RadarContext';
import useInterval from 'hooks/useInterval';


const RadarPanel = () => {
    const {
      showRadarPanel,
      toggleRadarPanel,
    } = useControlPanel();
    const [playing, setPlaying] = useState(false);
    const [segundos, setSegundos] = useState(600);
    const {
      loadingRadar,
      updateRadar,
      time,
      setTime,
      opacidadeControl,
      setOpacidadeControl
    } = useRadar();

    const restart = useCallback(() => {
      setSegundos(600);
      updateRadar();
    }, [])

    useInterval(() => {
      if (segundos > 0){
        setSegundos(s => s-1);
      } else {
        restart()
      }
    }, 1000);

    useInterval(() => {
      setTime(prevTime => {
        const newTime = prevTime-10;
        return newTime < 0 ? 60 : newTime
      })
    }, playing ? 1000 : null)

  return !showRadarPanel ? undefined : (
    <ControlPanel>
      <ControlHeader>
        <div style={{width: "85%", justifyContent: "center", display: "flex"}}>
          <span>IMAGENS RADAR</span>
        </div>
        <CloseButton onClick={toggleRadarPanel}><Close /></CloseButton>
      </ControlHeader>
      <Column gap='12px' w='90%' margin='0 0 8px 0'>
        <Row w='100%' gap='16px' justify='space-between'>
          <ReloadButton onClick={restart} loading={loadingRadar}>
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </ReloadButton>
          <label>
              Tempo até a próxima atualização: {
                (Math.floor(segundos/60) < 10
                ? '0' + Math.floor(segundos/60)
                : '10')
                + ':' +
                ((segundos % 60) < 10 ? '0' + segundos%60 : segundos%60)}
          </label>
        </Row>
        <Divider />
        <Row w='100%' gap='16px' justify='space-between'>
          <PlayButton isPlaying={playing} onClick={()=>setPlaying(p=>!p)} />
          <input
              id="sliderImagens"
              type="range"
              value={time}
              min={0}
              max={60}
              style={{transform: 'rotateY(180deg)'}}
              step={10}
              onChange={(e) => {
                  setTime(Number(e.target.value));
                  setPlaying(false);
              }}
              />
          <span style={{width: '120px', textAlign: 'end'}}>
              {
                moment().subtract(time, 'minutes').format('hh:mm - DD/MM/YYYY')
              }
          </span>
        </Row>
        <Divider />
        <Row w='100%' gap='16px' justify='space-between'>
          <MdOpacity style={{fontSize: '24px'}} />
          <input
            type="range"
            min={0}
            max={10}
            step={0.25}
            defaultValue={10}
            onChange={(e) =>
              setOpacidadeControl(e.target.value / 10)
            }
          />
          <span style={{width: '120px', textAlign: 'end'}}>
            {Math.floor(opacidadeControl*1000)/10}%
          </span>
          </Row>
          <Divider />
          <Column>
            <span>Refletividade (dBZ)</span>
            <img src={radarImg} alt="Régua de reflexividade da camada de radar" />
            <span>Potencial de Precipitação (mm/h)</span>
          </Column>
      </Column>
      </ControlPanel>
  )
}

export default RadarPanel
