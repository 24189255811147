import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleAction, playTransitionAction } from 'redux/actions/uiActions';
import DashboardComponent from 'containers/Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
    NewsRegister,
    NotFound,
    NewsTable,
    WeatherForecastRegister,
    WeatherForecastTable,
    NewsInfo,
    NewsEdit,
    WeatherForecastEdit,
    WeatherForecastInfo,
} from './pageListAsync';
import styles from 'containers/Templates/appStyles-jss';

function Application(props) {
    const changeMode = useContext(ThemeContext);
    const {
        history,
        gradient,
        mode,
        loadTransition,
        sidebarOpen,
        toggleDrawer,
    } = props;

    return (
        <DashboardComponent history={history} changeMode={changeMode}>
            <Switch>
                <Route
                    exact
                    path="/site/noticias/registrar"
                    component={NewsRegister}
                />
                <Route exact path="/site/noticias" component={NewsTable} />
                <Route exact path="/site/noticias/:new/" component={NewsInfo} />
                <Route
                    exact
                    path="/site/noticias/editar/:new"
                    component={NewsEdit}
                />

                <Route
                    exact
                    path="/site/previsoes/registrar"
                    component={WeatherForecastRegister}
                />
                <Route
                    exact
                    path="/site/previsoes"
                    component={WeatherForecastTable}
                />
                <Route
                    exact
                    path="/site/previsoes/:weather/"
                    component={WeatherForecastInfo}
                />
                <Route
                    exact
                    path="/site/previsoes/editar/:weather"
                    component={WeatherForecastEdit}
                />

                <Route component={NotFound} />
            </Switch>
        </DashboardComponent>
    );
}

Application.propTypes = {
    history: PropTypes.object.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    loadTransition: PropTypes.func.isRequired,
    gradient: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    sidebarOpen: state.ui.sidebarOpen,
    mode: state.ui.type,
    gradient: state.ui.gradient,
});

const mapDispatchToProps = (dispatch) => ({
    toggleDrawer: () => dispatch(toggleAction),
    loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const ApplicationMaped = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Application);

export default withStyles(styles)(ApplicationMaped);
