import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Toast from 'components/Notification/Notification';

const ToastContext = createContext();

export const ToastContextProvider = (props) => {
    const [open, setOpen] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastMessage, setToastMessage] = useState('');
    const [duration, setDuration] = useState(5000);


    const openToast = (type, message, duration) => {
        setToastType(type);
        setToastMessage(message);
        setDuration(duration? duration : 5000);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <ToastContext.Provider value={openToast}>
            {props.children}
            <Toast
              open={open}
              handleClose={handleClose}
              type={toastType}
              message={toastMessage}
              duration={duration}
              />
            {/* <Snackbar
                open={open}
                autoHideDuration={5000}
                TransitionComponent={(props) => (
                    <Slide {...props} direction="down" />
                )}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={toastType}
                    elevation={6}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar> */}
        </ToastContext.Provider>
    );
};

ToastContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ToastContextProvider;

export const useToast = () => {
  const context = useContext(ToastContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
