// Libs
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// Components
import {Close} from '@material-ui/icons';
import {Column, Row} from 'components/Flex';
import {CloseButton, ControlHeader, ControlPanel} from './styled';
import styles from "./camera-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import './ControlPanel.css';
// Hooks
import {useControlPanel} from 'Context/ControlPanelContext';
import { useToast } from 'Context/ToastContext';
import { postCameras, patchCamera, deleteCameras } from 'services/mapsPortal/cameras';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    height: "46px",
    width: "100%"
  },
  smallTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    height: "46px",
    width: "80px"
  }
}));

const CameraPanel = (props) => {
  const { classes } = props;
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [linkError, setLinkError] = useState();
  const [coordinatesError, setCoordinatesError] = useState();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { showCameraPanel, toggleCameraPanel, toggleReloadCameraPanel } = useControlPanel();


  const submitButton = async () => {
    setDisableSubmitButton(true);
    if(linkError || coordinatesError){
      openToast("warning", "Um ou mais campos possuem erros, verifique antes de continuar!");
    }
    else{
      const coordinatesData = {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            parseFloat(longitude),
            parseFloat(latitude)
          ]
        },
        "properties": {nome: name.trim(), link: link.trim()}
      }
      const cameraData = {
        "nome": name.trim(),
        "link": link.trim(),
        "coordenadas": coordinatesData
      }
      cameraData.nome === ""? cameraData.nome = null : cameraData.nome

      await postCameras(cameraData).then((response) => {
        if(response.status === 201){
          persistCameraInfo(response.data)
        }
        else{
          openToast("error", "Problema ao adicionar camera. Recarregue a página e tente novamente!");
          setDisableSubmitButton(false);
        }
      })
    }
  }

  const persistCameraInfo = async (data) => {
    const updatedData = {
      nome: data.nome,
      link: data.link,
      coordenadas: data.coordenadas 
    }

    updatedData.coordenadas.properties.id = data.id;

    await patchCamera(data.id, updatedData).then((response) => {
      if(response.status === 200){
        openToast("success", "Camera adicionada com sucesso!");
        setName("");
        setLink("");
        setLatitude("");
        setLongitude("");
        toggleReloadCameraPanel();
        setDisableSubmitButton(false);
      }
      else{
        deleteCamera(data.id);
      }
    })
    .catch(() => {
      deleteCamera(data.id);
    })
  }

  const deleteCamera = async (cameraID) => {
    await deleteCameras(cameraID).then((response) => {
      if(response.status === 204){
        openToast("error", "Problema ao adicionar camera. Recarregue a página e tente novamente!");
      }
      else{
        openToast("error", "Problema ao adicionar camera. Recarregue a página e tente novamente!");
      }
    })
    .catch(() => {
      openToast("error", "Problema ao adicionar camera. Recarregue a página e tente novamente!");
    })
    setDisableSubmitButton(false);
  }

  useEffect(() => {
    if(!link){
      setLinkError("Campo obrigatório!");
    }
    else{
      setLinkError("");
    }

    if(latitude === "" || longitude === ""){
      setCoordinatesError("Campos obrigatórios!");
    }
    else{
      if(!longitude.match(/^[-.0-9]*$/)){
        setCoordinatesError("O campo deve conter apenas números, - ou .");
      }
      else if(!latitude.match(/^[-.0-9]*$/)){
        setCoordinatesError("O campo deve conter apenas números, - ou .");
      }
      else{
        setCoordinatesError("");
      }    
    }
   
  }, [link, latitude, longitude])


  return !showCameraPanel ? undefined : (
    <ControlPanel>
      <ControlHeader>
        <div style={{width: "85%", justifyContent: "center", display: "flex"}}>
          <span>CAMERAS</span>
        </div>
        <CloseButton onClick={toggleCameraPanel}><Close /></CloseButton>
      </ControlHeader>
      <Column w='90%' gap='1px' margin='0 0 8px 0'>
        <Row w='100%' gap='16px' justify='flex-start'>
          <TextField
            id="camName"
            label="Nome"
            className={colors.textField}
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            shrink={true}
            placeholder="Nome"
            variant="outlined"
            inputProps={{style: fonts.formsField, maxLength: 50}}
            InputLabelProps={{style: fonts.formsLabel}} />
        </Row>
        <Column w='100%' gap='1px' justify='flex-start' style={{alignItems: "flex-start"}}>
          <TextField
            id="camLink"
            label="Link"
            className={colors.textField}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            margin="normal"
            shrink={true}
            placeholder="Link"
            variant="outlined"
            inputProps={{style: fonts.formsField}}
            InputLabelProps={{style: fonts.formsLabel}} />
          {linkError&& <span style={fonts.formsErrorMessage2} className={classes.errorMessage}>{linkError}</span>}
        </Column>
        <Column w='100%' gap='1px' justify='flex-start' style={{alignItems: "flex-start"}}>
          <Row w='100%' gap='15px' justify='flex-start' style={{alignItems: "flex-start"}}>
            <TextField
              id="camLatitude"
              label="Latitude"
              className={colors.smallTextField}
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              margin="normal"
              shrink={true}
              placeholder="Latitude"
              variant="outlined"
              inputProps={{style: fonts.formsField}}
              InputLabelProps={{style: fonts.formsLabel}} />
            <TextField
              id="camLongitude"
              label="Longitude"
              className={colors.smallTextField}
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              margin="normal"
              shrink={true}
              placeholder="Longitude"
              variant="outlined"
              inputProps={{style: fonts.formsField}}
              InputLabelProps={{style: fonts.formsLabel}} />
          </Row>
          {coordinatesError && <span style={fonts.formsErrorMessage2} className={classes.errorMessage}>{coordinatesError}</span>}

        </Column>
        <Row>
          <div className={disableSubmitButton? classes.disabledOutlineButton : classes.outlineButton} style={{marginTop: "20px"}} onClick={submitButton}>
            <span style={fonts.buttonDefault} className={classes.outlineButtonText}>
                ADICIONAR
            </span>
          </div>
        </Row>
      </Column>
    </ControlPanel>
  )
}

CameraPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(CameraPanel);
