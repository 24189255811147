export default function (state = "", action){
    switch(action.type){
        case "MODAL_EMPLOYEES_PROVIDER":
            return [action.payload[0], action.payload[1], action.payload[2], action.payload[3], action.payload[4]]
            
    default: return state
    }
}




