import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { getTargetAlertState } from 'services/mapsPortal/alerts';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';

export const TargetStateContext = createContext();

export const TargetStateContextProvider = (props) => {

    const { data: targetState, update } = useLoad(getTargetAlertState, []);

    useInterval(update, 10000);

    return (
        <TargetStateContext.Provider
            value={{
                targetState
            }}
        >
            {props.children}
        </TargetStateContext.Provider>
    );
};

TargetStateContextProvider.propTypes = {
    children: PropTypes.node,
};

export default TargetStateContextProvider;

export const useTargetState = () => {
  const context = useContext(TargetStateContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
