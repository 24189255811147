import {colors} from 'pages/MapsPortal/styled';
import styled, {keyframes} from 'styled-components';
import {Row} from 'components/Flex';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5;}
  100%{ opacity: 1;}
`

export const TopMenuStyled = styled.div`
    position: fixed;
    top: 0px;
    right: 20px;
    padding: 13px;
    height: ${props => props.height + 'px'};
    width: ${props => props.width + 'px'};
    border-radius: 0 0 13px 13px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.4);
    color: black;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    transition: transform 0.3s cubic-bezier(.22,0,.2,1);
    &.closed{
      transform: translateY(-100%);
    }
    &.notifOpen{
      transform: translateX(-280px);
    }
    &.closed.notifOpen{
      transform: translateX(-280px) translateY(-100%);
    }

`;
/*&::-webkit-scrollbar {
      width: 8px;
      background-color: white;
    },
    ::-webkit-scrollbar-thumb {
      background-color: #1D2026;
      border-radius: 12px;
    }*/


export const Container = styled.div`
    ${props => props.open? "overflow-y: auto" : ""};
    width: 100%;
`;

export const Section = styled.div`
    width: 100%;
    border: 2px solid #B067E9;
    border-radius: 8px;
    padding: 8px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1px;
`;

export const SectionTitle = styled.span`
    color: #1B1B1B;
    font-family: 'Segoe UI', 'Roboto';
    font-weight: 700;
    font-size: '11px',
`

export const SectionItem = styled.span`
    color: #1B1B1B;
    font-family: 'Segoe UI', 'Roboto';
    font-weight: 400;
    font-size: '11px',
`

export const SectionConteinerRow = styled.div`
    padding: 2px;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: ${props => props.gap? props.gap : props.width + "px"};
`
export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: ${props => props.height + 'px'};
    box-shadow: 0px -6px 6px -7px rgba(0, 0, 0, 0.4);
`
//    background-color: red;

export const Button = styled.button`
    display: flex;
    justify-content: center;
    padding: 11px;
    background-color: #B067E9;
    border-radius: 5px;
    color: #1B1B1B;
`

export const BufferTag = styled.div`
    display: flex;
    justify-content: center;
    min-width: 22px;
    padding: 4px;
    background-color: ${props => props.color};
    border-radius: 3px;
    color: #1B1B1B;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
`

export const SectionConteinerColumn = styled.div`
    padding: 2px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;
`

export const OpenTopMenu = styled.button`
  position: absolute;
  top: 100%;
  right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 75px;
  background: #FFFFFF;
  box-shadow: 0px 6px 5px 3px rgba(0, 0, 0, 0.4);
  border-radius: 0 0 13px 13px;
  transition: 0.3s ease;
  z-index: 1;
  &:hover{
    /* transform: translateY(-50%) scaleX(1.1); */
    background-color: #AAA;
  }
`
export const StyledTable = withStyles((theme) => ({
}))((props) => <Table {...props} />);

export const StyledTableHead = withStyles((theme) => ({
  root: {
    '& tr': {
      backgroundColor: theme.palette.menu.strongBlue,
      height: "60px",
      borderWidth: `0 2px 2px 2px `,
      borderRadius: "5px",
      borderStyle: `solid`,
      '& th': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: "12px",
        textTransform: 'uppercase',
        color: theme.palette.menu.white,
        '&:hover': {
          fontWeight: 700,
          cursor: 'pointer',
        }
      }
    }
  },
}))((props) => <TableHead {...props} />);

export const StyledTableBody = withStyles((theme) => ({
  root: {
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.menu.white,
    },
    '& tr': {
      backgroundColor: theme.palette.menu.white,
      cursor: 'pointer',
      border: `2px solid ${theme.palette.menu.mediumGrey}`,
      '&:hover': {
        backgroundColor: theme.palette.background.selected,
      },
      '& td': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        fontSize: "12px",
      }
    }
  }
}))((props) => <TableBody {...props} />);

export const StyledTableRow = withStyles((theme) => ({
}))((props) => <TableRow {...props} />);

export const StyledTableCell = withStyles((theme) => ({
}))((props) => <TableCell {...props} />);

/*export const Button = styled.button`
    min-width: 40px;
    height: 40px;
    background-color: ${props => props.selected ? colors.button.selected : 'transparent'};
    padding: 0;
    cursor: pointer;
    color: black;
    transition: background-color 0.4s ease;
    &:hover{
      background-color: ${colors.button.selected};
    }
    animation: ${blinkAnimation} 0.8s linear ${(props) => (
      typeof props.loading === 'undefined'
        ? '0'
        : props.loading ? 'infinite' : '1')};
    animation-play-state: running;

    &:disabled{
      background-color: transparent;

      &:hover{
        background-color: transparent;
      }
    }
`;*/

export const ControlPanel = styled.div`
  position: absolute;
  top: 100%;
  right: 60%;

  font-size: 12px;
  width: 300px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  background-color: ${colors.white};
  border-radius: 13px;
  font-family: 'Roboto';


  /* & > div > div {
    border-bottom: 1px solid ${colors.dark};
    padding-bottom: 12px;
  } */
`

export const ControlHeader = styled(Row)`
  background-color: ${colors.dark};
  border-radius: 13px 13px 0 0;
  padding: 12px 0;
  width: 100%;
  span{
    font-family: 'Roboto';
    color: ${colors.white};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
  }
`

export const CloseButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  transition: 0.2s ease;
    svg {
      font-size: 20px;
      path{
        transition: fill 0.2s ease;
        fill: white;
      }
    }

    &:hover{
      svg {
        path{
          fill: red;
        }
      }
    }
`

const loopAnimation = keyframes`
  0% { transform: rotateZ(0deg); }
  100% { transform: rotateZ(360deg);}
`

export const ReloadButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: scale 0.2s ease;
  padding: 4px;
  animation: ${loopAnimation} 0.6s linear ${(props) => (
      typeof props.loading === 'undefined'
        ? '0'
        : props.loading ? 'infinite' : '0')};
  animation-play-state: running;
  &:hover{
    transform: scale(1.1);
  }
`

export const PlayButton = styled.button`
  position: relative;
  margin: auto;
  background-color: transparent;
  width: 16px;
  height: 16px;
  border: none;
  margin: 4px;
  padding: 0;

  &:hover{
    &::before{
      border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid ${(props) => (props.isPlaying ? "red" : 'blue')};
    }
    &::after{
      border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid ${(props) => (props.isPlaying ? "red" : 'blue')};
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    transition: 0.3s ease;
    background-color: transparent;
    top: 0;
  }

  &::before{
    left: 0;
    border-top: ${(props) => (props.isPlaying ? "0" : "4px")} solid transparent;
    border-bottom: ${(props) => (props.isPlaying ? "0" : "4px")} solid transparent;
    border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid black;
  }


  &::after{
    right: 0;
    border-top: ${(props) => (props.isPlaying ? "0" : "8px")} solid transparent;
    border-bottom: ${(props) => (props.isPlaying ? "0" : "8px")} solid transparent;
    border-left: ${(props) => (props.isPlaying ? "4px" : "8px")} solid black;
    transform: ${(props) => (props.isPlaying ? "scaleX(0.5, 1)" : 'scale(1, 0.5)')};
  }
`

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${colors.dark};
  padding: 0;
  margin: 0;
`
