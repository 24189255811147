
/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* CSS */
import styles from "./tableContent-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material */
import Checkbox from '@material-ui/core/Checkbox';


const CustomCheckboxHead = (props) => {
  const { classes, column, handleClickCheckbox, allSelected } = props;
  const fonts = fontStyles();

  return(
    <>
      <Checkbox 
        checked={allSelected}
        value={allSelected}
        color="primary"
        onClick={(event) => handleClickCheckbox(0, 1, 3,"AllSelected")}
      />
    </>
  );

}
CustomCheckboxHead.propTypes = {
    classes: PropTypes.object.isRequired,
    column: PropTypes.string.isRequired,
    handleClickCheckbox: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired
   
  };
  export default withStyles(styles)(CustomCheckboxHead);