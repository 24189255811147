import { lambdaApi } from '../api';

export const getWrf = async () => {
    try {
        const response = await lambdaApi.get('wrf');
        return response.data;
    } catch (error) {
        console.error('Error fetching news:', error);
        return [];
    }
};

export const getWrfByDate = async (data_rodagem) => {
    try {
        const response = await lambdaApi.get(
            `wrf?data_rodagem=${data_rodagem}`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching wrf:', error);
        return [];
    }
};
