const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({
/* Template */

    titleContainer: {
       marginBottom: '10px'
    },

    gridContainer: {
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'flex-end',
       justifyContent: 'space-between',
       height: '45px',
    },

    levelField: {
       width: '11%',
       marginBottom: '27px',
    },

    riskField: {
       width: '23%',
       marginBottom: '27px',
    },

    toolsField: {
       width: '57%',
       marginBottom: '27px',
    },

    durationField: {
       width: '10%',
       marginBottom: '17px',
    },

    contactsField: {
       width: '84%',
       marginBottom: '44px',
    },

    commentsField: {
        width: '100%',
        marginBottom: '34px',
     },
     
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '20px',
    },

    lightningButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "11px 11px",
        gap: "10px",
        backgroundColor: "#B067E9",
        borderRadius: "5px",
        color: "#FFFFFF",
    },

    commentsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: "11px",
     },

    errorMessage: {
        fontSize: `11px`,
        color: "red",
        margin: "0 0 0 5px",
        fontFamily: fontFamily
    },
     
});

export default styles;