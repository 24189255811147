/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import { FaFilePdf } from "react-icons/fa";
/* Components */
import FieldsComponent from "./fieldsComponent";
import file_present_icon from 'images/file_present_icon.png'
import UploadButton from "components/UploadButton/index";
/* CSS */
import styles from "../forecast_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';
/* Context */
import { useModal } from 'Context/ModalContext';


const useStyles = makeStyles({
  divider: {
    background: "#848484",
    border: "1px solid",
    width: "100%",
  },
  inputLabel: {
    "&.label": {
      root: {
      marginBottom: "18px",}
    },
  }
});


const WeatherWarning = (props) => {
  const fonts = fontStyles();
  const dividerStyle = useStyles();
  const { classes, alertsData, setAlertsData, setReloadSwitchSelected, allSelected, setAllSelected, setReloadErrorMessage, setStatusTargetSelected, statusTargetSelected, setReloadStatusTargetSelected, reloadTargets, setReloadTargets, weatherAlertErrors, setWeatherAlertErrors  } = props;
  const { plans: planos, toShow } = useModal();
  const [targets, setTargets] = useState();
  const [reloadModal, setReloadModal] = useState(false);
  const [reloadCheckbox, setReloadCheckbox] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [weatherWarningList, setWeatherWarningList] = useState();
  const [titleCount, setTitleCount] = useState(0);
  const [subjectCount, setSubjectCount] = useState(0);
  const [subjectError, setSubjectError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reload, setReload] = useState();
  const dispatch = useDispatch();
  const [allSelectedAux, setAllSelectedAux] = useState(false);

  var aux = 0;

  var gridClasses = [
    classes.gridColumnsIndex,
    classes.gridMarginLeft,
  ]

  const [preset, setPreset] = useState({
    preset: false,
    titulo: "",
    assunto: "",
    filePresent: false,
    file: null,
    fileName: "",
  });

  const weatherWarning = {
    titulo: "",
    assunto: "",
    id_usuario: "",
    id_alvo: "",
  };

  const removeFileUploaded = () => {
    setPreset({...preset, file: null, filePresent: false, fileName: ""});
    aux = aux + 1;
    setReload(aux);
  };

  const handleFileUpload = (data) => {
    if(data){
      let reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = (e) => {
        setPreset({preset, file: e.target.result, filePresent: true, fileName: data.name});
      }
      aux = aux + 1;
      setReload(aux);
    }
  };

  const handlePdfViewer = () => {
    if(preset.filePresent) {
      dispatch(modalProvider(true, preset.file, null, null, "PdfViewer", null));
    }
  };

  useEffect(() => {
    setReloadModal(false);
  }, [reloadModal])
  
  useEffect(() => {
    if (preset.assunto && preset.assunto.length > 0) {
      setSubjectCount(preset.assunto.length)
    }
    if (preset.assunto && preset.assunto.length > 0) {
      if (preset.assunto[0] === " ") {
        setSubjectError("O campo não deve conter espaços em branco no começo/final!");
      }
      else if (preset.assunto[preset.assunto.length - 1] === " ") {
        setSubjectError("O campo não deve conter espaços em branco no começo/final!");
      }
      else {
        setSubjectError("");
      }
    }

    if (preset.titulo && preset.titulo.length > 0) {
      setTitleCount(preset.titulo.length)
      if (preset.titulo[0] === " ") {
        setTitleError("O campo não deve conter espaços em branco no começo/final!");
      }
      else if (preset.titulo[preset.titulo.length - 1] === " ") {
        setTitleError("O campo não deve conter espaços em branco no começo/final!");
      }
      else {
        setTitleError("");
      }
    }
  }, [preset, reload])

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 20px 10px 20px"}}>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
        {preset.filePresent &&
          <div className={classes.filePresentContainer}>
            <div className={classes.fileRemoveContainer} onClick={() => removeFileUploaded()}>
              <img src={file_present_icon} style={{width: "40px", height: "40px"}}/>
              {preset.fileName && <span className={classes.fileNameText}>{preset.fileName?.length > 38? preset.fileName?.substring(0, 38) + "..." : preset.fileName}</span>}
            </div>
          </div>
        }
        {!preset.filePresent &&
          <div>
            <UploadButton onChange={handleFileUpload} fileType={"pdf"} setFileName={setPreset} setErrorMessage={setErrorMessage} buttonName={"Anexar PDF"}/>
          </div>
        } 
          <div style={{display: "flex", flexDirection: "column", width: "75%", justifyContent: "center", alignItems: "flex-start"}}>
            <TextField
                id="title"
                label="Título"
                multiline
                shrink={true}
                value={preset?.titulo}
                disableClearable
                onChange={(e) => setPreset({ ...preset, titulo: e.target.value })}
                className={classes.textFieldWeatherIndex}
                variant="outlined"
                color="primary"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: fonts.formsFieldHeight4, maxLength: 50 }}
                InputProps={{
                  endAdornment:
                    <span className={classes.endAdornment}>{titleCount + "/50"}</span>
                }} />

            {titleError && <p className={classes.errorMessage}>{titleError}</p>}
          </div>
      </div>

      <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "15px"}}>
        <div className={!preset.filePresent? classes.disabledViewButton : classes.viewButton} style={{width: "144px"}} onClick={(handlePdfViewer)}>
          <span style={fonts.buttonDefault} className={classes.text}>VER PDF</span>
          <FaFilePdf style={{marginLeft: "6px", width: "18px", height: "18px"}} className={classes.icon}/>
        </div>
        <div style={{display: "flex", flexDirection: "column", width: "75%", justifyContent: "center", alignItems: "flex-start"}}>
          <TextField
            id="subject"
            label="Assunto"
            multiline
            rows={2}
            value={preset?.assunto}
            onChange={(e) => setPreset({ ...preset, assunto: e.target.value })}
            className={classes.textFieldWeatherIndex}
            margin="normal"
            variant="outlined"
            inputProps={{style: fonts.formsField, maxLength: 150}}
            InputLabelProps={{style: fonts.formsLabel}}
            InputProps={{
              endAdornment:
                <span className={classes.endAdornment} style={{marginTop: "5%"}}>{subjectCount + "/150"}</span>
            }}
          />

          {subjectError && <p className={classes.errorMessage}>{subjectError}</p>}
        </div>
      </div>

      <div style={{display: "flex", alignItems: "flex-end", width: "100%", marginTop: "10px", flexDirection: "column"}}>
        <Button style={fonts.buttonDefault} variant="contained" className={classes.wheaterButton} onClick={() => setPreset({ ...preset, preset: !preset?.preset })}>
          Alterar todos
        </Button>
      </div>

      <div style={{display: "flex", marginTop: "20px", width: "100%"}}>
        <Divider classes={{ root: dividerStyle.divider }}/>
      </div>

      {planos?.map(company => company.planos.map((plan) => {
        return plan.alvos.map((target) => {
          if (!toShow.get(company.id, plan.id, target.id)) return null
          return (
            <div key={target.id} style={{width: "100%"}}>
              <FieldsComponent target={target} preset={preset} alertsData={alertsData} setAlertsData={setAlertsData} plano={plan} setReloadModal={setReloadModal} setReloadSwitchSelected={setReloadSwitchSelected} allSelected={allSelected} setAllSelected={setAllSelected} allSelectedAux={allSelectedAux} setAllSelectedAux={setAllSelectedAux} setReloadErrorMessage={setReloadErrorMessage} setStatusTargetSelected={setStatusTargetSelected} statusTargetSelected={statusTargetSelected} setReloadStatusTargetSelected={setReloadStatusTargetSelected} reloadTargets={reloadTargets} setReloadTargets={setReloadTargets} weatherAlertErrors={weatherAlertErrors} setWeatherAlertErrors={setWeatherAlertErrors} />
            </div>
          )
        })
      }))}
      
    </div>
  )
}

WeatherWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func.isRequired,
  setReloadPostData: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  setReloadSelected: PropTypes.func.isRequired,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  loadingLeftSide: PropTypes.any.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
  weatherAlertErrors: PropTypes.any.isRequired,
  setWeatherAlertErrors: PropTypes.func.isRequired,
 
};
export default withStyles(styles)(WeatherWarning);