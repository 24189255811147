export default function (state = [], action){
    let priorities = state;

    switch(action.type){
        case "PRIORITIES_PROVIDER":
            if(state.length === 0){
                priorities.push(action.payload);
            }
            else{
                let push = true;
                let index;
                for(let i = 0; i < priorities.length; i++){
                    let aux = priorities[i]
                    if(aux[0] === action.payload[0]){
                        push = false;
                        index = i;
                    }
                    else{
                        push;
                    }
                 }
                if(!push){
                    priorities.splice(index, 1);
                    priorities.push(action.payload);
                }
                else{
                    priorities.push(action.payload);
                }
            }
            return priorities     
    default: return state
    }
}




