import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import FileUpload from '@material-ui/icons/CloudUpload';
import fontStyles from 'styles/fonts-jss';
import { withStyles } from '@material-ui/core/styles';
import styles from './button-jss';
import DateFnsUtils from "@date-io/date-fns";
import { forEach } from 'lodash';

const Input = styled.input`
    display: none;
`;

export const ButtonForFile = styled(Button)`
    cursor: "pointer",
    border: "2px solid",
    borderColor: "#FFB703",
    backgroundColor: "#FFB703",
    borderRadius: "5px",
    padding: "6px 14px 6px 14px",
        '& $text': {
            color: theme.palette.menu.strongYellow,
        },
        '&:hover': {
            background: theme.palette.menu.strongYellow,
        '& $text': {
            color: theme.palette.menu.strongBlue,
        },
    },
`;

const CsvUploadButton = (props) => {
    const inputRef = useRef();
    const fonts = fontStyles();
    const { classes, fileType, buttonName, list, setList, setErrorsMessage, setReloadList, setDependenciesErrors } = props;
    var strings = ["id_empresa", "nome", "cargo", "email", "telefone", "username", "sms_datafunc_hora_inicio", "sms_datafunc_hora_fim"];
    var numbers = ["id_permissao"];
    var booleans = ["ativo_alerta", "notificacao_sms", "notificacao_telefone", "notificacao_whatsapp", "notificacao_telegram", "notificacao_email", "aplicativo_web", "aplicativo_mobile", "consolidacao"];
    var array_of_numbers = ["usuarios_alvos"];
    var requiredFields = ["id_empresa", "nome", "id_permissao"];
    const handleClick = () => {
        inputRef.current.click();
    };

    const handleChange = (e) => {
        const fileReader = new FileReader();
        var type = e.target.files[0].name.split(".");
        var len = type.length;

        if(fileType == "csv"){
            if(type[len - 1] == "csv"){
                fileReader.readAsText(e.target.files[0], 'UTF-8');
                fileReader.onload = (e) => {
                    parseCSV(e.target.result);
                };
            }
            else {
                setErrorsMessage("Verifique o formato do arquivo e tente novamente!");
                setTimeout(() => {
                    setErrorsMessage("");
                  }, 500);
            }   
        }
        else {
            setErrorsMessage("Verifique o formato do arquivo e tente novamente!");
            e = null
            setTimeout(() => {
                setErrorsMessage("");
              }, 500);
        }
    };
    
    const parseCSV = (data) => {
        var splitedCsv = data.split("\n");
        var splitedCsvList = [];
        splitedCsvList.push(splitedCsv.map((line) => {return line.split(";")}));
        var columns = splitedCsvList[0][0].length;
        splitedCsvList = splitedCsvList[0].filter(data => data[1] != "" && data.length == columns);
        var listAux = list;
        var lineErrors = [];

        if(splitedCsvList?.length > 0){       
            for(let i = 1; i < splitedCsvList.length; i++){
                var requiredError = false;
                var dependentFields = [];
                const jsonAux = { 
                    nome: "",
                    email: "",
                    telefone: "",
                    sms_datafunc_hora_inicio: null,
                    sms_datafunc_hora_fim: null,
                    aplicativo_web: false,
                    aplicativo_mobile: false,
                    cargo: "",
                    ativo_alerta: true,
                    usuario_ativo: [],
                    id_empresa: "",
                    nome_empresa: "",
                    id_permissao: "",
                    username: "",
                    usuario_alvos: null,
                    notificacao_sms: false,
                    notificacao_telefone: false,
                    notificacao_whatsapp: false,
                    notificacao_telegram: false,
                    notificacao_email: false,
                    consolidacao: false,
                    lista_usuarios : [],
                    nome_plano: null,
                    lista_alvos: [],
                    lista_servicos: [],
                    checked: true
                
                };

                if(splitedCsvList[0].length > 1){
                    let row = splitedCsvList[i];
                    let column = splitedCsvList[0];

                    for(let j = 0; j < splitedCsvList[0].length; j++) {
                        column[j] = column[j].indexOf("\r") != -1? column[j].slice(0, column[j].indexOf("\r")): column[j];
                        column[j] = column[j].replace(/["\\]/g, '')

                        if(booleans.some((data) => data == column[j])){
                            let aux = column[j].split(" ");
                            if((requiredFields.some((data) => data == aux[0]) && (row[j] != null && row[j] != "")) || !requiredFields.some((data) => data == aux[0])){
                                jsonAux[aux[0]] = (row[j] == "sim" || row[j] == "Sim")? true : false;
                                (row[j] == "sim" || row[j] == "Sim")? dependentFields.push(aux[0]) : "";
                            }
                            else{
                                requiredError = true;
                                break;
                            }
                        }
                        else if(array_of_numbers.some((data) => data == column[j])){
                            var aux = column[j].split(" ");
                            var targetsSplited = row[j].split(",");
                            var employeesTargets = [];
                            var targets = [];

                            if((requiredFields.some((data) => data == aux[0]) && (row[j] != null && row[j] != "")) || !requiredFields.some((data) => data == aux[0])){
                                targetsSplited.map((data) => employeesTargets.push({id_alvo: parseInt(data.trim()), id_usuario: "", notificacoes: null, prioridade: null}));                             
                                targetsSplited.map((data) => targets.push(parseInt(data.trim())));                             
                                jsonAux[aux[0]] = employeesTargets;
                                jsonAux["lista_alvos"] = targets;
                            }
                            else{
                                requiredError = true;
                                break;
                            }
                        }
                        else if(strings.some((data) => data == column[j])){
                            let aux = column[j].split(" ");
                            if((requiredFields.some((data) => data == aux[0]) && (row[j] != null && row[j] != "")) ||
                                !requiredFields.some((data) => data == aux[0])){
                                jsonAux[aux[0]] = (row[j] != null && row[j] != "")? row[j].replace(/["\\]/g, '').trim() : null;
                            }
                            else{
                                requiredError = true;
                                break;
                            }
                        }
                        else if(numbers.some((data) => data == column[j])){
                            let aux = column[j].split(" ");
                            if((requiredFields.some((data) => data == aux[0]) && (row[j] != null && row[j] != "")) || !requiredFields.some((data) => data == aux[0])){
                                jsonAux[aux[0]] = (row[j] != null && row[j] != "")? parseInt(row[j]) : null;
                            }
                            else{
                                requiredError = true;
                                break;
                            }
                        }  
                    }
                    if(!requiredError){
                        if(!jsonAux.email && dependentFields.some((data) => data == "notificacao_email" || data == "aplicativo_web" || data == "aplicativo_mobile" || data == "consolidacao")){
                            requiredError = true;
                        }
                        else if((!jsonAux.sms_datafunc_hora_inicio || !jsonAux.sms_datafunc_hora_fim) && dependentFields.some((data) => data =="notificacao_sms")){
                            requiredError = true;
                        }
                        else if(!jsonAux.telefone && dependentFields.some((data) => data == "notificacao_sms" || data == "notificacao_telefone" || data == "notificacao_whatsapp")){
                            requiredError = true;
                        }
                        else if(!jsonAux.username && dependentFields.some((data) => data == "notificacao_telegram")){
                            requiredError = true;
                        }

                        if(!requiredError){
                            listAux.push(jsonAux);
                        }
                        else{
                            lineErrors.push(i + 1);
                        }
                    }
                    else{
                        lineErrors.push(i + 1);
                    }
                    
                }
            }
            setDependenciesErrors(lineErrors);
            setList(listAux);
            setReloadList(true);   
        }
    }

    return (
        <>
            <div className={classes.uploadButton} onClick={handleClick} >
                <span style={fonts.buttonDefault} className={classes.text}>{buttonName.toUpperCase()}</span>
                <FileUpload style={{marginLeft: "6px"}} className={classes.icon}/>
            </div>
            <Input
                ref={inputRef}
                type="file"
                onChange={handleChange}
                id="fileInput"
            />
        </>
    );
};

CsvUploadButton.propTypes = {
    onChange: PropTypes.func,
    setFileName: PropTypes.string,
    setErrorsMessage: PropTypes.string,
    buttonName: PropTypes.string,
    fileType: PropTypes.string,
    classes: PropTypes.object,
    setJson: PropTypes.func,
    list: PropTypes.array,
    setList: PropTypes.func,
    setReloadList: PropTypes.func,
    setDependenciesErrors: PropTypes.func,
};

export default withStyles(styles) (CsvUploadButton);
