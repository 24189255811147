import { djangoApi } from '../api';

export const postServices = (servicesData) => {
    return djangoApi.post(`servicos/`, servicesData);
}

export const updateService = (id_service, serviceData) => {
    return djangoApi.put(`servicos/${id_service}/`, serviceData);
}

export const getServices = async (id_service) => {
    try {
        const response = await djangoApi.get(`servicos/${id_service}/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const deleteService = async (id_service) => {
    try {
        const response = await djangoApi.delete(`servicos/${id_service}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
}

export const patchService = (id_service, serviceData) => {
    return djangoApi.put(`servicos/${id_service}/`, serviceData);
};
