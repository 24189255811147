export default function persistRegister (newStep, reset) {
    if(reset === true) {
        let stepList = [0]
        sessionStorage.setItem("zANEK0scRd", JSON.stringify(stepList)); 
    }
    else {
        let stepList = JSON.parse(sessionStorage.getItem("zANEK0scRd")) || [];
        stepList = newStep;
        sessionStorage.setItem("zANEK0scRd", JSON.stringify(stepList)); 
    }  
}   
