import React, { memo, useContext, useState } from 'react';
import ReactMapGl, { Layer, Source } from 'react-map-gl';
import { ToolsContext } from 'Context/ToolsContext';
import turfLength from '@turf/length';
import './style.css';

const ToolComponent = (props) => {
    const { toolInUse, measureLine, pointHelper } = useContext(ToolsContext);
    let coordinatesArray;
    if (toolInUse === 'measure' && pointHelper.length > 0) {
        coordinatesArray = [...measureLine, pointHelper];
    } else {
        coordinatesArray = [...measureLine];
    }
    const points = coordinatesArray.map((point) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: point,
        },
    }));
    const measurePointsGeo = { type: 'FeatureCollection', features: points };
    const measureLineGeo = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordinatesArray,
        },
    };
    return (
        <>
            <Source
                id="toolPoint"
                type="geojson"
                data={measurePointsGeo}
                generateId={true}
            >
                <Layer
                    id="toolPoint"
                    source="measure-points"
                    type="circle"
                    paint={{
                        'circle-color': '#08F',
                        'circle-radius': 6,
                    }}
                />
            </Source>
            <Source
                id="toolLine"
                type="geojson"
                data={measureLineGeo}
                generateId={true}
            >
                <Layer
                    id="toolLine"
                    source="measure-points"
                    type="line"
                    paint={{
                        'line-color': '#08F',
                        'line-width': 1,
                    }}
                />
            </Source>
            {measureLine.length && (
                <div className="measureDiv">
                    <span>
                        {Math.floor(turfLength(measureLineGeo) * 1000) / 1000}{' '}
                        KM
                    </span>
                </div>
            )}
        </>
    );
};

export const Tool = memo(ToolComponent);
