import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import fontStyles from 'styles/fonts-jss';
import styles from './charts-jss';

import React, { PureComponent } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
} from 'recharts';
import moment from 'moment';
import Loading from 'components/Loading';
import { Typography } from '@material-ui/core';

const SimpleLineChartComponent = (props) => {
    const fonts = fontStyles();
    const { classes } = props;

    let formattedData;

    if (props.chartType === 'temperature') {
        formattedData = props.chartData
            ? props.chartData.map((item) => ({
                  name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                  C: item.media_temperatura,
              }))
            : [];
    }

    if (props.chartType === 'moisture') {
        formattedData = props.chartData
            ? props.chartData.map((item) => ({
                  name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                  '%': item.umidade_relativa,
              }))
            : [];
    }

    if (props.chartType === 'pressure') {
        formattedData = props.chartData
            ? props.chartData.map((item) => ({
                  name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                  hPa: item.pressao_ar,
              }))
            : [];
    }

    const limitedData = formattedData ? formattedData.slice(0, 73) : '';

    if (limitedData && limitedData.length > 0) {
        return (
            <div className={classes.container}>
                <Typography
                    variant="h6"
                    align="center"
                    style={{
                        fontSize: '15px',
                        margin: '0',
                        backgroundColor: '#8ECAE6',
                        padding: '7px 0 ',
                        borderRadius: '7px 7px 0 0',
                    }}
                    gutterBottom
                >
                    {props.title}
                </Typography>
                <div className={classes.grafico}>
                    <ResponsiveContainer width={'100%'} height={300}>
                        <LineChart
                            width={700}
                            height={300}
                            data={limitedData}
                            margin={{
                                top: 20,
                                right: 50,
                                left: 5,
                                bottom: 20,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="name"
                                interval={11}
                                tick={{ fontSize: 12 }}
                            >
                                <Label
                                    value="Dia/Horário"
                                    offset={-15}
                                    position="insideBottom"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                allowDecimals={false}
                                domain={
                                    props.chartType === 'pressure'
                                        ? [
                                              (dataMin) => Math.floor(dataMin),
                                              (dataMax) => Math.ceil(dataMax),
                                          ]
                                        : [0, 'auto']
                                }
                                tickFormatter={(value) => value.toFixed(0)}
                            >
                                <Label
                                    value={props.legend}
                                    angle="-90"
                                    position="center"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                            </YAxis>
                            <Tooltip />
                            <Line
                                type="monotone"
                                dataKey={props.dataKey}
                                stroke={props.color}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    } else {
        <Loading />;
    }
};

SimpleLineChartComponent.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    title: PropTypes.string,
    legend: PropTypes.string,
    color: PropTypes.string,
    chartType: PropTypes.string,
    dataKey: PropTypes.string,
};
export default withStyles(styles)(SimpleLineChartComponent);
