import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import fontStyles from 'styles/fonts-jss';
import styles from './charts-jss';

import React, { PureComponent } from 'react';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
} from 'recharts';
import Loading from 'components/Loading';
import moment from 'moment';
import { Typography } from '@material-ui/core';

const ComposedChartComponent = (props) => {
    const fonts = fontStyles();
    const { classes } = props;

    const calculateAccumulatedDifferences = (data) => {
        let accumulated = 0; // Inicializar o acumulado com 0 em vez de 1

        return data.map((item) => {
            const diff = Math.abs(item.media_chuva - accumulated);
            accumulated = item.media_chuva;
            return diff.toFixed(3);
        });
    };

    const formatChartData = (data) => {
        const accumulatedDifferences = calculateAccumulatedDifferences(data);

        return data.map((item, index) => ({
            name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
            mm: item.media_chuva,
            accumulated: accumulatedDifferences[index],
        }));
    };

    const formattedData = props.chartData
        ? formatChartData(props.chartData)
        : [];

    const limitedData = formattedData.slice(0, 73);

    if (limitedData && limitedData.length > 0) {
        return (
            <div className={classes.container}>
                <Typography
                    variant="h6"
                    align="center"
                    style={{
                        fontSize: '15px',
                        margin: '0',
                        backgroundColor: '#8ECAE6',
                        padding: '7px 0 ',
                        borderRadius: '7px 7px 0 0',
                    }}
                    gutterBottom
                >
                    PRECIPITAÇÃO ACUMULADA (mm)
                </Typography>
                <div className={classes.grafico}>
                    <ResponsiveContainer width={'100%'} height={300}>
                        <ComposedChart
                            width={800}
                            height={300}
                            data={limitedData}
                            margin={{
                                top: 20,
                                right: 50,
                                bottom: 20,
                                left: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3" />
                            <XAxis dataKey="name" interval={11} tick={true}>
                                <Label
                                    value="Dia/Horário"
                                    offset={-15}
                                    position="insideBottom"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                allowDecimals={false}
                                label={{
                                    value: 'Precipitação Acumulada (mm)',
                                    angle: -90,
                                    style: {
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        height: '20px',
                                    },
                                }}
                            />
                            <Tooltip />
                            <Bar
                                dataKey="accumulated"
                                barSize={20}
                                fill="#27B624"
                            />
                            <Line
                                type="monotone"
                                dataKey="mm"
                                stroke="#27B624"
                                dot={false}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    } else {
        <Loading />;
    }
};

ComposedChartComponent.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
};
export default withStyles(styles)(ComposedChartComponent);
