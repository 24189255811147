
import { useEffect, useState, useCallback } from 'react'

const useTogglePlans = (dataIn, initState = true) => {

  const [data, setData] = useState({
    sectionValue: initState,
    sectionState: dataIn?.map((empresa) => ({
      sectionValue: initState,
      sectionState: empresa?.planos?.map((plano) => ({
        sectionValue: initState,
        sectionState: plano.alvos ? plano.alvos.map((data) => data.status) : [initState]
      }))
    }))
  });

  const newData = (dataIn, initState) => {
    setData({
      sectionValue: initState,
      sectionState: dataIn?.map((empresa) => ({
        sectionValue: initState,
        sectionState: empresa.planos.map((plano) => ({
          sectionValue: initState,
          sectionState: plano.alvos ? plano.alvos.map((data) => data.status) : [initState]
        }))
      }))
    })
  }

  useEffect(() => {
    setData(prev => {
      const company = {...prev}.sectionState;
      const newSectionState = company.map(c => ({
        ...c, sectionValue: c.sectionState.every(value => value.sectionValue)
      }))
      return {
        sectionState: newSectionState,
        sectionValue: newSectionState.every(value => value.sectionValue)
      }
    })
  }, [JSON.stringify(data)])

  const toggleCompany = useCallback(
    (index) => {
      try{
        const newData = {...data}
        const ref = newData.sectionState[index]
        const state = !ref.sectionValue
        ref.sectionValue = state
        ref.sectionState = ref.sectionState.map((plan) => ({
          sectionValue: state,
          sectionState: plan.sectionState.map(() => state)
        }))
        newData.sectionValue = newData.sectionState.every(
          ({sectionValue}) => sectionValue
        )
        setData(newData)
      }
      catch{
        return false;
      }
    }, [JSON.stringify(data)])

  const togglePlan = (companyIndex, planIndex) => {
    try{
      const newData = {...data}
      const company = newData.sectionState[companyIndex]
      const ref = company.sectionState[planIndex]
      const state = !ref.sectionValue
      ref.sectionValue = state
      ref.sectionState = ref.sectionState.map(() => state);
      company.sectionValue = company.sectionState.every(({sectionValue}) => sectionValue)
      setData(newData)
    }catch{
      return false;
    }
  }

  const toggleTarget = (companyIndex, planIndex, targetIndex) => {
    try{
      const newData = {...data}
      const ref = newData.sectionState[companyIndex].sectionState[planIndex]
      ref.sectionState[targetIndex] = !ref.sectionState[targetIndex]
      ref.sectionValue = ref.sectionState.every(value => value)
      setData(newData)
    }catch{
      return false;
    }
  }

  function toggle(companyIndex, planIndex, targetIndex){
    switch (arguments.length){
      case 3:
        toggleTarget(companyIndex, planIndex, targetIndex)
        break;
      case 2:
        togglePlan(companyIndex, planIndex)
        break;
      case 1:
        toggleCompany(companyIndex)
        break;
      default:
        throw new Error('Use setAll to set the state of all checkboxes')
    }
  }

  function get(companyIndex, planIndex, targetIndex) {
    switch (arguments.length){
      case 3:
        try{
          return data.sectionState[companyIndex].sectionState[planIndex].sectionState[targetIndex]
        } catch(e){
          return false
        }
      case 2:
        try{
          return data.sectionState[companyIndex].sectionState[planIndex].sectionValue
        } catch(e){
          return false
        }
      case 1:
        try{
          return data.sectionState[companyIndex].sectionValue
        } catch(e){
          return false
        }
      default:
        throw new Error('Use getAll to get the state of all checkboxes')
    }
  }

  const toggleAll = () => {
      setData(prev => ({
        sectionValue: !prev.sectionValue,
        sectionState: prev.sectionState.map((empresa) => ({
          sectionValue: !prev.sectionValue,
          sectionState: empresa.sectionState.map((plano) => ({
            sectionValue: !prev.sectionValue,
            sectionState: plano.sectionState.map(() => !prev.sectionValue)
          }))
        }))
      }))
    }

    const getAll = () => data.sectionValue

  return { toggle, get, toggleAll, getAll, newData, data};
}

export default useTogglePlans
