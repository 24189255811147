/* Libraries */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
/* Material UI */
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import {KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt-BR';
/* Components */
import FieldsComponent from './fieldsComponent';
import { LineContainer, ItemContainer } from '../styled';
/* CSS */
import styles from '../../forecast_forms-jss';
import fontStyles from 'styles/fonts-jss';
/* Context */
import { useModal } from 'Context/ModalContext';
import { getSpecialReportCard } from 'services/mapsPortal/alerts';
import Loading from 'components/Loading';
import { useToast } from 'Context/ToastContext';
import 'moment/dist/locale/pt-br'
import { postReportCard, deleteReportCard } from 'services/mapsPortal/alerts';
import { getUserAuthenticated } from 'services/login/login';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    divider: {
        background: '#848484',
        border: '1px solid',
    },
});

const SpecialReportCard = (props) => {
    const fonts = fontStyles();
    const dividerStyle = useStyles();
    const moment = require('moment');
    moment.locale('pt-br');
    const {
        classes,
        alertsData,
        setAlertsData,
        setReloadSwitchSelected,
        allSelected,
        setAllSelected,
        setReloadErrorMessage,
        setStatusTargetSelected,
        statusTargetSelected,
        setReloadStatusTargetSelected,
        reloadTargets,
        setReloadTargets,
        reportCardErrors,
        setReportCardErrors
    } = props;
    const [targets, setTargets] = useState();
    const [reloadModal, setReloadModal] = useState(false);
    const [loadingDailyReportCard, setLoadingDailyReportCard] = useState(true);
    const [targetReportList, setTargetReportList] = useState();
    const [reloadData, setReloadData] = useState(false);
    const [reloadCheckbox, setReloadCheckbox] = useState(false);
    const [caractersRemaning, setCaractersRemaning] = useState(0);
    const { plans: planos, toShow } = useModal();
    const [allSelectedAux, setAllSelectedAux] = useState(false);
    const [dailyReportCard, setDailyReportCard] = useState([]);
    const [loadedSpecialReportCards, setLoadedSpecialReportCards] = useState(false);
    const [reloadReportCards, setReloadReportCards] = useState(false);
    const [selectedHorizon, setSelectedHorizon] = useState('');
    const [firstShipment, setFirstShipment] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState(moment());
    const [selectedEndDate, setSelectedEndDate] = useState(moment());
    const [selectedStartDateErrors, setSelectedStartDateErrors] = useState('');
    const [selectedEndDateErrors, setSelectedEndDateErrors] = useState('');
    const ref = useRef(null);
    const reportCardContext = useSelector((state) => state.reportCardContextProvider);
    const openToast = useToast();
    const [selectedStatus, setSelectedStatus] = useState(reportCardContext === null? "Não aprovados" :  reportCardContext[2][0] === 0? "Não aprovados" : "Aprovados");
    const [reloadStatus, setReloadStatus] = useState(false);
    const [statusValue, setStatusValue] = useState(reportCardContext === null? 0 :  reportCardContext[2][0] === 0? 0 : 1);

    const [weatherCondition, setWeatherCondition] = useState([
        "Em branco",
        "Sem previsão de chuva",
        "Chuva no período da manhã",
        "Chuva no período da tarde",
        "Chuva no período da noite",
        "Chuva ao longo do dia",
        "Pancadas de chuva ao longo do dia"
    ]);
    const [windDirection, setWindDirection] = useState([
        'Em branco',
        'De Sul',
        'De Leste',
        'De Norte',
        'De Oeste',
        'De Sudeste',
        'De Sudoeste',
        'De Nordeste',
        'De Noroeste',
    ]); 
    const [windSprint, setWindSprint] = useState([
        'Em branco',
        'Sem informação',
        'Calmo, rajadas entre 0.01 km/h e 1.0 km/h',
        'Aragem, rajadas entre 1.01 km/h e 6.0 km/h ',
        'Brisa leve, rajadas entre 6.01 km/h e 12.0 km/h',
        'Brisa fraca, rajadas entre 12.01 km/h e 20.0 km/h',
        'Brisa moderada, rajadas entre 20.01 km/h e 29.0 km/h',
        'Brisa forte, rajadas entre 29.01 km/h e 39.0 km/h',
        'Vento fresco, rajadas entre 39.01 km/h e 50.0 km/h',
        'Vento forte, rajadas entre 50.01 km/h e 62.0 km/h',
        'Ventania, rajadas entre 62.01 km/h e 75.0 km/h',
        'Ventania forte, rajadas entre 75.01 km/h e 89.0 km/h',
        'Tempestade, rajadas entre 89.01 km/h e 103.0 km/h',
        'Tempestade violenta, rajadas entre 103.01 km/h e 117.0 km/h',
        'Tornado/Furacão, rajadas entre 117.01 km/h e 999.9 km/h',
    ]); 

    var gridClasses = [
        classes.gridColumnsIndexRain,
        classes.gridMarginLeftRain,
    ];

    const [preset, setPreset] = useState({
        preset: false,
        acumulado: '',
        intensidade: '',
        condicao_tempo: '',
        probabilidade: '',
        nivel: '',
        rajada_vento: '',
        direcao_vento: '',
        velocidade_vento: '',
        temperatura_min: '',
        temperatura_max: '',
        observacao: null,
        velocidade_vento_original: '',
        rajada_vento_original: '',
    });

    const handleHistoricalData = (date, type) => {
        const data1 = new Date(date);
        let month1 = '' + (data1.getMonth() + 1);
        month1 = month1.padStart(2, '0');
        let day1 = '' + data1.getDate();
        day1 = day1.padStart(2, '0');
        let year1 = '' + data1.getFullYear();
        const formattedDate = year1 + month1 + day1;
        const today = moment().format('YYYYMMDD')

        if(moment(formattedDate, "YYYYMMDD").isBefore(moment(today).format('YYYYMMDD'))){
            openToast('error', 'Não é possível selecionar um dia anterior ao dia atual!');
            if(type === "startDate"){
                setSelectedStartDateErrors("Não é possível selecionar um dia anterior ao dia atual para a data inicial!")
            }
            else{
                setSelectedEndDateErrors("Não é possível selecionar um dia anterior ao dia atual para a data final!")
            }
        }
        else if(type === "endDate" && moment(formattedDate, "YYYYMMDD").isBefore(moment(selectedStartDate).format('YYYYMMDD'))){
            openToast('error', 'Não é possível selecionar um dia anterior ao primeiro dia escolhido!');
            setSelectedEndDateErrors("Não é possível selecionar um dia anterior ao primeiro dia escolhido!")
        }
        else if(type === "startDate"){
            setSelectedStartDate(date);
            setSelectedStartDateErrors('');
            if(moment(selectedEndDate, "YYYYMMDD").isBefore(moment(date).format('YYYYMMDD'))){
                setSelectedEndDate(date);
                setSelectedEndDateErrors('');
            }
        }
        else if(type === "endDate"){
            setSelectedEndDate(date);
            setSelectedEndDateErrors('');
        }  
    };

    const loadUser = async (reportCardPostData) => {
        var loginToken = JSON.parse(localStorage.getItem('reeWAL57qe'));

        await getUserAuthenticated(loginToken).then((response) => {
            if (response.status === 200) {
                for (var i = 0; i < reportCardPostData.length; i++) {
                    reportCardPostData[i].meteorologista = response.data.usuario.id;
                    reportCardPostData[i].nome_meteorologista = response.data.usuario.first_name + ' ' + response.data.usuario.last_name;
                    reportCardPostData[i].assinatura = response.data.usuario.assinatura;
                }
                postReportCards(reportCardPostData);
            }
            else{
                openToast("error", `Problema em obter nome e assinatura do meteorologista`);
            }
        }).catch((error) => {
            openToast("error", `Problema em obter nome e assinatura do meteorologista`);
        });
    };

    const createReportCards = (quantidy) => {
        var reportCardPostData = [];
        var index = quantidy[1]? 0 : 1;
        var cicles = quantidy[1]? quantidy[0] - 1 : quantidy[0];

        if(typeof quantidy[0] === "number" && quantidy[0] > 0){
            planos?.map((company) => {
                company.planos.map((plan) => {
                    plan.alvos.map((target) => {
                        if (toShow.get(company.id, plan.id, target.id)){
                            for(let i = index; i <= cicles; i++){
                                let date = `${moment(selectedStartDate).format("YYYY-MM-DD")}T${firstShipment}`;
                                let formatedDate = moment(date).utcOffset('-0300').format();
                                let horizonMinutes = parseInt(selectedHorizon.split(":")[1]) === 0? 60 : parseInt(selectedHorizon.split(":")[1]);
                                let horizonMinutesFraction = 60/horizonMinutes;
                                let horizonMinutesDecimal = 1/horizonMinutesFraction;
                                let horizonDecimal = horizonMinutesDecimal != 1? parseInt(selectedHorizon.split(":")[0]) + "." + JSON.stringify(horizonMinutesDecimal).split(".")[1] : parseInt(selectedHorizon.split(":")[0]);
                                let horizonAdd = i * horizonDecimal;
                                let validityFat1 = moment(formatedDate).add(horizonAdd, 'hours').format("DD/MM/YYYY HH:mm:ss");
                                let validityFat2 = moment(formatedDate).add((i + 1) * horizonDecimal, 'hours').format("DD/MM/YYYY HH:mm:ss");
                                const postData = {
                                    intensidade: null,
                                    nivel: null,
                                    probabilidade: null,
                                    temperatura_min: null,
                                    temperatura_max: null,
                                    condicao_tempo: null,
                                    acumulado: null,
                                    rajada_vento: null,
                                    direcao_vento: null,
                                    velocidade_vento: null,
                                    observacao: null,
                                    datahora_modificacao: null,
                                    campos_modificados: null,
                                    modificacao: false,
                                    conteudo_modificacao: null,
                                    nome_empresa: company.nome,
                                    nome_plano: plan.nome,
                                    nome_alvo: target.nome,
                                    horizontes: horizonDecimal,
                                    vigencia: validityFat1.slice(6, 10) + "-" + validityFat1.slice(3, 5) + "-" + validityFat1.slice(0, 2) + " " + validityFat1.slice(11, 19)
                                    + " - " + validityFat2.slice(6, 10) + "-" + validityFat2.slice(3, 5) + "-" + validityFat2.slice(0, 2) + " " + validityFat2.slice(11, 19),
                                    status: 0,
                                    id_alvo: target.id_alvo,
                                    especial: true,
                                }

                                reportCardPostData.push(postData)
                            }
                        }
                    })
                })    
            })
            loadUser(reportCardPostData);
        }
    }

    const postReportCards = async (data) => {
        let idReportCards = [];
        let error = [];
        for(let i = 0; i < data.length; i++) {
            await postReportCard(data[i]).then((response) => {
                if(response.status === 201){
                    idReportCards.push(response.data.id_boletim)
                    error.push(false);
                }
                else{
                    error.push(true);
                }   
            }).catch(() => {
                error.push(true);
            })
        }
        if(!error.includes(true)){
            setLoadingDailyReportCard(true);
        }
        else{
            deleteReportCards(idReportCards);
        }
        setSelectedEndDate(moment());
        setSelectedStartDate(moment());
        setSelectedHorizon("");
        setFirstShipment("");
    }

    const deleteReportCards = async (reportCards) => {
        for(let i = 0; i < reportCards.length; i++) {
            await deleteReportCard(reportCards[i]);
        }
        loadReportCard();
    }

    const deleteCreatedReportCards = async () => {
        setReloadReportCards(true);
        openToast('warning', 'Limpando os boletins...');
        setLoadedSpecialReportCards(false);
        planos?.map((company) =>
            company.planos.map((plan) => {
                 plan.alvos.map((target) => {
                    if (toShow.get(company.id, plan.id, target.id)){
                        const aux = dailyReportCard.filter((report) => target.id_alvo === report.id_alvo).map((report) => report.id_boletim);
                        if(aux?.length > 0){
                            deleteReportCards(aux)
                        }
                    }
                })
            })
        );

        setPreset({
            preset: false,
            acumulado: '',
            intensidade: '',
            condicao_tempo: '',
            probabilidade: '',
            nivel: '',
            rajada_vento: '',
            direcao_vento: '',
            velocidade_vento: '',
            temperatura_min: '',
            temperatura_max: '',
            observacao: null,
            velocidade_vento_original: '',
            rajada_vento_original: '',
        });
    }

    const defineNumberOfReportCards = () => {
        function reportCardCalc(starDate, endDate, missingHours) {
            function isFloat(n){
                return Number(n) === n && n % 1 !== 0;
            }
            if(
                moment(starDate, "YYYY/MM/DD").isSame(moment(moment().format("YYYY/MM/DD"),"YYYY/MM/DD")) 
                && moment(endDate, "YYYY/MM/DD").isSame(moment(moment().format("YYYY/MM/DD"),"YYYY/MM/DD"))
                &&  moment(moment().format('LT'), "HH:mm:sss").isAfter(moment(moment(firstShipment, "HH:mm:sss")))
            )
            {
                openToast('error', 'Não é possível criar um boletim especial para um dia e horário que já passou!');
            }
            else{
                const msPerDay = 1000 * 60 * 60 * 24;
                // Discard the time and time-zone information.
                const utc1 = Date.UTC(starDate.getFullYear(), starDate.getMonth(), starDate.getDate());
                const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                let now = moment();
                let timeDiff = moment(now).endOf('day') - now;
                let duration = moment.duration(timeDiff);
                let durationMinutes = duration._data.minutes;
                let durationMinutesFraction = 60/durationMinutes;
                let durationMinutesDecimal = 1/durationMinutesFraction;
                let formatedDuration = durationMinutesDecimal != 1? duration._data.hours + "." + JSON.stringify(durationMinutesDecimal).split(".")[1] : duration._data.hours;
                const differenceInHours = Math.floor((utc2 - utc1)) === 0? parseFloat(formatedDuration) : (((Math.floor((utc2 - utc1) / msPerDay)) * 24) + parseFloat(formatedDuration));
                let horizonMinutes = parseInt(selectedHorizon.split(":")[1]) === 0? 60 : parseInt(selectedHorizon.split(":")[1]);
                let horizonMinutesFraction = 60/horizonMinutes;
                let horizonMinutesDecimal = 1/horizonMinutesFraction;
                let horizonDecimal = horizonMinutesDecimal != 1? parseInt(selectedHorizon.split(":")[0]) + "." + JSON.stringify(horizonMinutesDecimal).split(".")[1] : parseInt(selectedHorizon.split(":")[0]);
                let numberOfReportCards = (differenceInHours / parseFloat(horizonDecimal));
                let numberOfReportCardsAvailable = missingHours === true? numberOfReportCards < 1? 1 : isFloat(numberOfReportCards)? numberOfReportCards + 1 : numberOfReportCards : numberOfReportCards - 1;
                openToast('success', numberOfReportCardsAvailable == 1? 'Gerando boletim especial' : 'Gerando boletins especiais');

                return [Math.floor(numberOfReportCardsAvailable, -10), missingHours]
            }
        }
          
        const starDate = new Date(`${parseInt(moment(selectedStartDate).format('YYYY'))}/${parseInt(moment(selectedStartDate).format('MM'))}/${parseInt(moment(selectedStartDate).format('DD'))}`);
        const endDate = new Date(`${parseInt(moment(selectedEndDate).format('YYYY'))}/${parseInt(moment(selectedEndDate).format('MM'))}/${parseInt(moment(selectedEndDate).format('DD'))}`);
        const missingHours = moment(firstShipment, "HH:mm").isAfter(moment())? true : false;

        if(selectedHorizon === '' || selectedHorizon?.length != 5){
            openToast('error', 'O campo Horizonte deve ser preenchido corretamente antes de continuar!');
        }
        else if(firstShipment === '' || firstShipment != moment(firstShipment, "HH:mm").format("HH:mm")){
            openToast('error', 'O campo 1º envio deve ser preenchido corretamente antes de continuar!');
        }
        else if(selectedStartDateErrors){
            openToast('error', selectedStartDateErrors); 
        }
        else if(selectedEndDateErrors){
            openToast('error', selectedEndDateErrors); 
        }
        else{
           createReportCards(reportCardCalc(starDate, endDate, missingHours));
        }
    }

    const loadReportCard = async () => {
        let aux = [];
        await getSpecialReportCard().then((response) => {
            if(response){
                setDailyReportCard(response);
            }
            setLoadingDailyReportCard(false);
            setReloadReportCards(false);
        }).catch(() => {
            openToast('error', 'Não foi possível obter os Boletins Diários! Recarregue a página e tente novamente.')
        });
    };

    function sortData( a, b ) {
        if ( a.vigencia < b.vigencia ){
          return -1;
        }
        if ( a.vigencia > b.vigencia ){
          return 1;
        }
        return 0;
    }

    useEffect(() => {
        loadReportCard();
    }, []);

    useEffect(() => {
        if(loadingDailyReportCard){
            loadReportCard();
        }
    }, [loadingDailyReportCard]);

    useEffect(() => {
        setReloadModal(false);
    }, [reloadModal]);

    useEffect(() => {
        setReloadStatus(false);
    }, [reloadStatus]);

    // Atualiza a contagem de caracteres restantes com base no campo de observações
    useEffect(() => {
        setCaractersRemaning(preset.observacao != null? preset.observacao.length : 0);
    }, [preset.observacao]);

    function isInt(n) {
        return n % 1 === 0;
    }

    function isHundred(n) {
        return n >= 100;
    }

    if(loadingDailyReportCard) { return ( <Loading />) }
    return (
        <div ref={ref} className={classes.container}>
            <div className={classes.allChangeContainer}>
                <div className={classes.inputLine} style={{}}>
                    <LineContainer
                        style={{
                            width: '100%',
                            margin: '0',
                            height: "80px", display: "flex", align: "center"
                        }}
                    >
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                        >
                            <KeyboardDatePicker
                               format="dd/MM/yyyy"
                               value={selectedStartDate}
                               onChange={(date) => handleHistoricalData(date, "startDate")}
                               animateYearScrolling={false}
                               label="Data inicial"
                               className={classes.dateField}
                               inputProps={{
                                   style: fonts.formsField,
                               }}
                               InputLabelProps={{
                                   style: fonts.formsLabel,
                               }}
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                        >
                            <KeyboardDatePicker
                               format="dd/MM/yyyy"
                               value={selectedEndDate}
                               onChange={(date) => handleHistoricalData(date, "endDate")}
                               animateYearScrolling={false}
                               label="Data final"
                               margin="none"
                               className={classes.dateField}
                               inputProps={{
                                   style: fonts.formsField,
                               }}
                               InputLabelProps={{
                                   style: fonts.formsLabel,
                               }}
                            />
                        </MuiPickersUtilsProvider>

                        <InputMask
                            mask="99:99"
                            value={selectedHorizon}
                            onChange={(e) => setSelectedHorizon(e.target.value)}
                            disabled={false}
                            maskChar=" "
                        >{() =>
                            <TextField
                              id="Horizonte"
                              className={classes.selectFieldSmall}
                              margin="normal"
                              variant="outlined"
                              label="Horizonte"
                              style={{display: "flex", marginTop: "18px"}}
                              inputProps={{style: fonts.formsField}}
                              InputLabelProps={{style: fonts.formsLabel, shrink: true}}
                            />}
                        </InputMask>

                        <InputMask
                            mask="99:99"
                            value={firstShipment}
                            onChange={(e) => setFirstShipment(e.target.value)}
                            disabled={false}
                            maskChar=" "
                        >{() =>
                            <TextField
                              id="1º envio"
                              className={classes.selectFieldSmall}
                              margin="normal"
                              variant="outlined"
                              label="1º envio"
                              style={{display: "flex", marginTop: "18px"}}
                              inputProps={{style: fonts.formsField}}
                              InputLabelProps={{style: fonts.formsLabel, shrink: true}}
                            />}
                        </InputMask>

                        <Button
                            style={fonts.buttonDefault}
                            variant="contained"
                            className={classes.reportButtonSpecial}
                            onClick={() => defineNumberOfReportCards()}
                        >
                            GERAR BOLETINS
                        </Button>
                    </LineContainer>
                </div>

                <Divider
                    classes={{ root: dividerStyle.divider }}
                    style={{ marginTop: '30px', padding: '0' }}
                />

                {loadedSpecialReportCards &&
                    <div ref={ref} className={classes.container} style={{marginTop: '20px'}}>
                        <div className={classes.allChangeContainerSpecial}>
                            <div className={classes.inputLine}>
                                <LineContainer
                                    style={{
                                        width: '100%',
                                        margin: '0',
                                        height: 'fit-content',
                                    }}
                                >
                                    <Autocomplete
                                        id="condition"
                                        freeSolo
                                        value={preset?.condicao_tempo}
                                        inputValue={preset?.condicao_tempo}
                                        disableClearable
                                        onInputChange={(_, newValue) => setPreset({ ...preset, condicao_tempo: newValue })}
                                        onChange={(_, newValue) => setPreset({ ...preset, condicao_tempo: newValue })}
                                        className={classes.inputOne}
                                        style={fonts.formsFieldHeight1}
                                        size="small"
                                        options={weatherCondition}
                                        renderInput={(params) =>
                                          <TextField
                                            {...params}
                                            label="Condição do tempo"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            onKeyDown={(event) => {
                                              if (event.key === 'Backspace') {
                                                event.stopPropagation();
                                              }
                                            }} />}
                                    />

                                    <Autocomplete
                                        id="intensity"
                                        freeSolo={false}
                                        value={preset?.intensidade}
                                        inputValue={preset?.intensidade}
                                        disableClearable
                                        onChange={(_, newValue) =>
                                            setPreset({ ...preset, intensidade: newValue })
                                        }
                                        className={classes.inputTwo}
                                        size="small"
                                        options={[
                                            'Em branco',
                                            'Fraca',
                                            'Fraca a moderada',
                                            'Moderada',
                                            'Moderada a forte',
                                            'Forte',
                                            'Muito forte',
                                            'Extrema',
                                        ]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Intensidade da chuva"
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Backspace') {
                                                        event.stopPropagation();
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </LineContainer>
                            </div>
                                    
                            <div className={classes.inputLine}>
                                <InputMask
                                    mask="999 - 999 mm"
                                    value={preset?.acumulado}
                                    onChange={(e) =>
                                        setPreset({
                                            ...preset,
                                            acumulado: e.target.value,
                                        })
                                    }
                                    disabled={false}
                                    maskChar=" "
                                >
                                    {() => (
                                        <TextField
                                            className={classes.inputThree}
                                            id="acumuladoInit"
                                            variant="outlined"
                                            label="Acumulado"
                                            inputProps={{
                                                style: fonts.formsFieldHeight1,
                                            }}
                                            InputLabelProps={{
                                                style: fonts.formsLabel,
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                </InputMask>
                                
                                <Autocomplete
                                    id="lightning_prob"
                                    freeSolo={false}
                                    value={preset?.probabilidade}
                                    inputValue={preset?.probabilidade}
                                    disableClearable
                                    onChange={(_, newValue) =>
                                        setPreset({
                                            ...preset,
                                            probabilidade: newValue,
                                        })
                                    }
                                    className={classes.inputFour}
                                    style={fonts.formsFieldHeight1}
                                    size="small"
                                    options={['Em branco', 'Sem tempestades', 'Baixa', 'Média', 'Alta']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Prob. Tempestade"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Backspace') {
                                                    event.stopPropagation();
                                                }
                                            }}
                                        />
                                    )}
                                />

                                <Autocomplete
                                    id="level"
                                    freeSolo={false}
                                    value={preset?.nivel}
                                    inputValue={preset?.nivel}
                                    disableClearable
                                    onChange={(_, newValue) =>
                                        setPreset({ ...preset, nivel: newValue })
                                    }
                                    className={classes.inputFive}
                                    style={fonts.formsFieldHeight1}
                                    size="small"
                                    options={['T1', 'T2', 'T3', 'T4', 'Em branco']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Nível"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Backspace') {
                                                    event.stopPropagation();
                                                }
                                            }}
                                        />
                                    )}
                                />

                                <TextField
                                    id="temp_min"
                                    className={classes.inputSix}
                                    value={preset?.temperatura_min}
                                    onChange={(e) =>
                                        setPreset({
                                            ...preset,
                                            temperatura_min: e.target.value,
                                        })
                                    }
                                    variant="outlined"
                                    type="tel"
                                    label="Temp. Mínima"
                                    inputProps={{ style: fonts.formsFieldHeight1 }}
                                    InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{ alignSelf: 'end' }}
                                            >
                                                °C
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    id="temperatura_max"
                                    className={classes.inputSeven}
                                    variant="outlined"
                                    value={preset?.temperatura_max}
                                    onChange={(e) =>
                                        setPreset({
                                            ...preset,
                                            temperatura_max: e.target.value,
                                        })
                                    }
                                    label="Temp. Máxima"
                                    type="tel"
                                    inputProps={{ style: fonts.formsFieldHeight1 }}
                                    InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{ alignSelf: 'end' }}
                                            >
                                                °C
                                            </InputAdornment>
                                        ),
                                    }}
                                />  
                            </div>
                                
                            <div className={classes.inputLine}>
                                <Autocomplete
                                    id="wind_sprint"
                                    freeSolo
                                    value={preset?.rajada_vento}
                                    inputValue={preset?.rajada_vento}
                                    disableClearable
                                    onInputChange={(_, newValue) => setPreset({ ...preset, rajada_vento: newValue })}
                                    onChange={(_, newValue) => setPreset({ ...preset, rajada_vento: newValue })}
                                    className={classes.inputEight}
                                    style={fonts.formsFieldHeight1}
                                    size="small"
                                    options={windSprint}
                                    renderInput={(params) =>
                                      <TextField
                                        {...params}
                                        label="Rajada de vento"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onKeyDown={(event) => {
                                          if (event.key === 'Backspace') {
                                            event.stopPropagation();
                                          }
                                        }} />}
                                />

                                <InputMask
                                    mask={isInt(preset?.velocidade_vento)? 
                                        isHundred(preset?.velocidade_vento)? 
                                            "999 km/h" :
                                            "99 km/h" :
                                                isHundred(preset?.velocidade_vento)?
                                                    "999.9 km/h" :
                                                    "99.9 km/h"
                                    }
                                    value={preset?.velocidade_vento}
                                    onChange={(e) =>
                                        setPreset({
                                            ...preset,
                                            velocidade_vento: e.target.value,
                                        })
                                    }
                                    disabled={false}
                                    maskChar=" "
                                >
                                    {() => (
                                        <TextField
                                            id="velocidade_vento"
                                            className={classes.inputNine}
                                            variant="outlined"
                                            label="Vel. do vento"
                                            inputProps={{ style: fonts.formsFieldHeight1 }}
                                            InputLabelProps={{
                                                style: fonts.formsLabel,
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                </InputMask>
                                
                                <Autocomplete
                                    id="wind"
                                    freeSolo
                                    value={preset?.direcao_vento}
                                    inputValue={preset?.direcao_vento}
                                    disableClearable
                                    onInputChange={(_, newValue) => setPreset({ ...preset, direcao_vento: newValue })}
                                    onChange={(_, newValue) => setPreset({ ...preset, direcao_vento: newValue })}
                                    className={classes.inputTen}
                                    style={fonts.formsFieldHeight1}
                                    size="small"
                                    options={windDirection}
                                    renderInput={(params) =>
                                      <TextField
                                        {...params}
                                        label="Direção do vento"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onKeyDown={(event) => {
                                          if (event.key === 'Backspace') {
                                            event.stopPropagation();
                                          }
                                        }} />}
                                />
                            </div>
                                    
                            <div className={classes.inputLine}>
                                <TextField
                                    id="comments"
                                    label="Observações"
                                    multiline
                                    rows={2}
                                    value={preset?.observacao}
                                    onChange={(e) =>
                                        setPreset({ ...preset, observacao: e.target.value })
                                    }
                                    className={classes.inputElevenMargin}
                                    shrink={true}
                                    variant="outlined"
                                    color="primary"
                                    inputProps={{
                                        style: fonts.formsFieldHeight2,
                                        maxLength: 200,
                                    }}
                                    InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                                    InputProps={{
                                        endAdornment:
                                          <span className={classes.endAdornment}>{caractersRemaning + "/200"}</span>
                                    }}
                                />

                                <Button
                                    style={fonts.buttonDefault}
                                    variant="contained"
                                    className={classes.reportButton}
                                    onClick={() =>
                                        setPreset({ ...preset, preset: !preset?.preset })
                                    }
                                >
                                    Alterar todos
                                </Button>
                            </div>
                                
                                
                                
                        </div>
                    </div>
                }

                {loadedSpecialReportCards &&
                    <Divider
                        classes={{ root: dividerStyle.divider }}
                        style={{ padding: '0' }}
                    />
                }

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    <Autocomplete
                        id="Status"
                        freeSolo={false}
                        value={selectedStatus}
                        inputValue={selectedStatus}
                        disableClearable
                        onChange={(_, newValue) => {
                            if(newValue === "Não aprovados"){
                                setSelectedStatus(newValue);
                                setStatusValue(0);
                                setReloadStatus(true);
                            }
                            else{
                                setSelectedStatus(newValue);
                                setStatusValue(1);
                                setReloadStatus(true);
                            }
                        }}
                        style={{marginTop: "25px"}}
                        className={classes.inputFour}
                        size="small"
                        options={
                            reportCardContext == null?
                            ["Não aprovados", "Aprovados"] :
                            reportCardContext[2]?.length > 1?
                                ["Não aprovados", "Aprovados"] :
                                reportCardContext[2]?.includes(0)?
                                    ["Não aprovados"] :
                                    ["Aprovados"]
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Status"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
                    {loadedSpecialReportCards && 
                        <Button
                            style={fonts.buttonDefault}
                            variant="contained"
                            className={classes.reportButtonSpecial}
                            onClick={() => deleteCreatedReportCards()}
                        >
                            LIMPAR BOLETINS
                        </Button>
                    }
                </div>
            </div>
            
            {!reloadReportCards && planos?.map((company) =>
                company.planos.map((plan) => {
                    return plan.alvos.map((target) => {
                        if (!toShow.get(company.id, plan.id, target.id)){
                            return null;
                        }
                        else{
                            const aux = dailyReportCard.filter((report) => target.id_alvo === report.id_alvo);
                            if(aux.filter((reportCard) => reportCard.especial)?.length > 0 
                            && aux.filter((reportCard) => reportCard.status == statusValue)?.length > 0){
                                if(!loadedSpecialReportCards){
                                    setLoadedSpecialReportCards(true);
                                }
                            }
                          
                            return aux.sort(sortData).map((report) => {
                                if(report.especial && (report.status === statusValue)){
                                    return (
                                        <div key={target.id}>
                                            <FieldsComponent
                                                target={target}
                                                preset={preset}
                                                idBoletim={report.id_boletim}
                                                alertsData={alertsData}
                                                setAlertsData={setAlertsData}
                                                planoId={plan.id_plano}
                                                empresaId={company.id_empresa_id}
                                                empresaNome={company.nome}
                                                planoNome={plan.nome}
                                                alvoNome={target.nome}
                                                setReloadModal={setReloadModal}
                                                setReloadSwitchSelected={
                                                    setReloadSwitchSelected
                                                }
                                                allSelected={allSelected}
                                                setAllSelected={setAllSelected}
                                                allSelectedAux={allSelectedAux}
                                                setAllSelectedAux={setAllSelectedAux}
                                                setReloadErrorMessage={
                                                    setReloadErrorMessage
                                                }
                                                setStatusTargetSelected={
                                                    setStatusTargetSelected
                                                }
                                                statusTargetSelected={statusTargetSelected}
                                                setReloadStatusTargetSelected={
                                                    setReloadStatusTargetSelected
                                                }
                                                reloadTargets={reloadTargets}
                                                setReloadTargets={setReloadTargets}
                                                reportCardErrors={reportCardErrors}
                                                setReportCardErrors={setReportCardErrors}
                                                reportCardData={report}
                                            />
                                        </div>
                                    )
                                }
                                else{
                                  return null
                                };
                            })
                        }
                    });
                }),
            )}
        </div>
    );
};
SpecialReportCard.propTypes = {
    classes: PropTypes.object.isRequired,
    alertsData: PropTypes.any,
    setAlertsData: PropTypes.func.isRequired,
    setReloadPostData: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    setReloadSelected: PropTypes.func.isRequired,
    setCheckboxSelected: PropTypes.func.isRequired,
    checkboxSelected: PropTypes.object.isRequired,
    setReloadSwitchSelected: PropTypes.func.isRequired,
    allSelected: PropTypes.any,
    setAllSelected: PropTypes.func.isRequired,
    statusTargetSelected: PropTypes.any.isRequired,
    setStatusTargetSelected: PropTypes.func.isRequired,
    setReloadStatusTargetSelected: PropTypes.func.isRequired,
    loadingLeftSide: PropTypes.any.isRequired,
    reloadTargets: PropTypes.any.isRequired,
    setReloadTargets: PropTypes.func.isRequired,
    reportCardErrors: PropTypes.any.isRequired,
    setReportCardErrors: PropTypes.func.isRequired,
    setReloadErrorMessage: PropTypes.func.isRequired
}

export default withStyles(styles)(SpecialReportCard);
