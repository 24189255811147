import { useState, useRef, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';

export const ControlPanel = createContext();

export const ClientControlPanelProvider = (props) => {
  const [showSidebar, toggleShowSidebar] = useToggle(false);
  const [showSatellitePanel, toggleSatellite] = useToggle(false);
  const [showInfosPanel, toggleInfos] = useToggle(true);
  const [showAlerts, toggleShowAlerts] = useToggle(false);

  const toggleSatellitePanel = () => {
    toggleSatellite();
    toggleInfos(false);
  }

  const toggleInfosPanel = () => {
    toggleSatellite(false);
    toggleInfos();
  }

    return (
        <ControlPanel.Provider
            value={{
              showSidebar,
              toggleShowSidebar,
              showSatellitePanel,
              showInfosPanel,
              toggleSatellitePanel,
              toggleInfosPanel,
              showAlerts,
              toggleShowAlerts,
            }}
        >
            {props.children}
        </ControlPanel.Provider>
    );
};

ClientControlPanelProvider.propTypes = {
    children: PropTypes.node,
};

export default ClientControlPanelProvider;

export const useControlPanel = () => {
  const context = useContext(ControlPanel);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ControlPanelProviderdasdasdasd />')
  }

  return context;
}
