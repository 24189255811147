
/* Libraries */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material UI */
import Button from '@material-ui/core/Button';
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';
/* Components */
import { deleteData as deleteDataFunction} from "./DeleteFunctions";
/* Hooks */
import { useToast } from 'Context/ToastContext';


const DeleteConfirmationContent = (props) => {
    const { classes, deleteData, setOpenModalError, setOpenModalErrorData } = props;
    const fonts = fontStyles();
    const dispatch = useDispatch();
    const openToast = useToast();
    const [tableName, setTableName ] = useState(deleteData[3] === "UsuáriosAlvos"? "Usuários" : deleteData[3]);

    const onCloseModal = () => {
        dispatch(modalProvider(false, null, null, null, null, null));
    }

    const handleDeleteData = async () => {
        const response = await deleteDataFunction(deleteData);

        if(response[0] === "error"){
            setOpenModalError(true);
            setOpenModalErrorData(response[1])
        }
        else{
            dispatch(modalProvider(false, null, null, null, null, null));
            openToast('success', `${response[1]} ${response[1].length > 1? "deletados" : "deletado"} com sucesso!`);
            /*window.location.reload();*/
        }

    } 

    return(
        <div className={classes.deleteContainerDiv}>
            <div className={classes.deleteContainerHeader}>
                <span style={fonts.deleteModalTitle}>Confirmar {deleteData[3] === "UsuáriosAlvos"? "remoção" : "deleção"}</span> 
            </div>
            <div className={classes.deleteContainerBody}>
                <div className={classes.deleteContainerBodyTitle}>
                    <span style={fonts.warningMessage}>{`Você realmente deseja ${deleteData[3] === "UsuáriosAlvos"? "remover" : "deletar"} ${deleteData[3] === "Empresa"? deleteData[2]?.length > 1? "as" : "a" : deleteData[2]?.length > 1? "os" : "o"} ${deleteData[2]?.length > 1? "seguintes" : "seguinte"} ${deleteData[2]?.length > 1? tableName.toLowerCase() : tableName.toLowerCase().slice(0, tableName.length - 1)}?`}</span>
                </div>
                {deleteData[2]?.length <= 16?
                    deleteData[2]?.length >= 9?
                        <div className={classes.deleteContainerBodyBody}>
                            <div className={classes.deleteContainerBodyBodyColumn}>
                                {deleteData[2] && deleteData[2].length > 0 && deleteData[2].map((data, index) => (deleteData[2].length < 9  || index < 9) && 
                                    <span style={fonts.item} key={index}>
                                        <div className={classes.deleteContainerBodyDot}>
                                            <div className={classes.deleteDot} />
                                            {data}
                                        </div>
                                    </span>)}
                            </div>
                            {deleteData[2] && deleteData[2].length >= 9 &&
                                <div className={classes.deleteContainerBodyBodyColumn}>
                                    {deleteData[2] && deleteData[2].length > 0 && deleteData[2].map((data, index) => index >= 9 && 
                                    <span style={fonts.item} key={index}>
                                        <div className={classes.deleteContainerBodyDot}>
                                            <div className={classes.deleteDot} />
                                            {data}
                                        </div>
                                    </span>)}
                                </div>
                            }
                        </div> :
                        <div className={classes.deleteContainerBodyBody}>
                            <div className={classes.deleteContainerBodyBodyColumn}>
                                {deleteData[2] && deleteData[2].length > 0 && deleteData[2].map((data, index) => (deleteData[2].length < 9  || index < 9) && 
                                    <span style={fonts.item} key={index}>
                                        <div className={classes.deleteContainerBodyDot}>
                                            <div className={classes.deleteDot} />
                                            {data}
                                        </div>
                                    </span>)}
                            </div>
                        </div>
                    :
                    <span style={{marginTop: "30px", fontWeight: "bold"}}>Visualização indisponível!</span>
                }
            </div>
            <div className={classes.deleteContainerFooter}>
              <Button style={fonts.buttonDefault} variant="contained" className={classes.confirmartionButton} onClick={() => handleDeleteData()}>
                  Confirmar {deleteData[3] === "UsuáriosAlvos"? "remoção" : "deleção"}
              </Button>
              <Button style={fonts.buttonDefault} variant="contained" className={classes.goBackButton} onClick={onCloseModal}>
                  Voltar
              </Button>
            </div>
        </div>
    );
}

DeleteConfirmationContent.propTypes = {
    classes: PropTypes.object.isRequired,
    deleteData: PropTypes.array.isRequired,
    setOpenModalError: PropTypes.func.isRequired,
    setOpenModalErrorData: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeleteConfirmationContent);