import 'mapbox-gl/dist/mapbox-gl.css';
import { useState, useEffect } from 'react';
import ReactMapGl from 'react-map-gl';
import { DischargesLayer } from './DischargesLayer';
import SatelitesLayers from './SatelitesLayers.jsx/index.jsx';
import MapPopup from './MapPopup';
import { TargetLayer } from './TargetLayer';
import Alerts from '../Alerts/index';
// import { DrawPolygon } from './PolygonLayer';
import { useMapClientPortal } from 'hooks/useMapClientPortal';
import Loading from 'components/Loading';
import {useMapContext} from 'Context/MapContext';
import {useTools} from 'Context/ToolsContext';
import useBound from 'hooks/useBound';

const Map = () => {
    const [loading, setLoading] = useState(true);
    const {mapRef, popup, showPopup, setShowPopup} = useMapContext();
    const { goTo, setGoTo, mapStyle } = useMapContext();
    const { polygon } = useTools();
    const [maxBoundsState, centerCoordinate] = useBound();
    
    const [viewport, setViewport] = useState({
      longitude: -59.95,
      latitude: -12.9,
      zoom: 3.5,
      maxZoom: 6,
      minZoom: 2,
    });
  
    const { mapFunctions} =
      useMapClientPortal(mapRef, viewport, setViewport, loading, setLoading, );

    useEffect(() => {
      if (!goTo.length) return;
      mapRef.current.fitBounds(
        goTo,
        {padding: 40, duration: 1000}
      )
      setGoTo([]);
    }, [goTo, mapRef, setGoTo]);

    const onClose = () => {
      setShowPopup(false)
      polygon.resetPolygon();
    }

      useEffect(() => {
        if(maxBoundsState != null && centerCoordinate != null && mapRef?.current){
          const map = mapRef.current;
          const zoom = map?.getZoom() + 1;
          map?.flyTo({
            center: [centerCoordinate[0], centerCoordinate[1]],
            zoom: zoom,
            essential: true
          });    
        }
      }, [centerCoordinate, maxBoundsState, mapRef.current])

    if(!maxBoundsState || !centerCoordinate){
      return(
        <Loading />
      )
    }
    
    return (
      <>
        {loading  && <Loading />}
        <ReactMapGl
            // reuseMaps
            ref={mapRef}
            {...viewport}
            {...mapFunctions}
            dragRotate={false}
            doubleClickZoom={false}
            width="100%"
            height="100%"
            mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
            maxBounds={[
              [maxBoundsState[2], maxBoundsState[3]],
              [maxBoundsState[0], maxBoundsState[1]]
            ]}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
            <TargetLayer />
            <DischargesLayer />
            <SatelitesLayers />
            <Alerts />
            {/*<ActiveLayer />*/}

            {showPopup && (
                <MapPopup onClose={onClose} {...popup} />
            )}
        </ReactMapGl>
      </>
    );
};

export default Map;
