import React, { memo, useEffect, useState } from 'react';
import ReactMapGl, { Layer, Source } from 'react-map-gl';
import { useTools } from 'Context/ToolsContext';
import { useDispatch } from 'react-redux';
import { polygonProvider } from 'redux/actions/polygonAction';

const PolygonLayer = (props) => {
    const { toolInUse, polygon, setDischargesHistory } = useTools();
    const polygonLine = polygon.polygonLine;
    const pointHelper = polygon.pointHelper;
    let coordinatesArray;
    const dispatch = useDispatch();

    if (toolInUse !== 'selection' && !polygon.finishPolygon) return null
    
    const getMaxBounds = () => {
        var minLat = coordinatesArray && coordinatesArray.length > 0? 
            coordinatesArray.reduce((min, value) => {
                return value[0] < min[0]? value : min;
            }) : null;
        var maxLat = coordinatesArray && coordinatesArray.length > 0? 
            coordinatesArray.reduce((max, value) => {
                return value[0] > max[0]? value : max;
            }) : null;
        var minLon = coordinatesArray && coordinatesArray.length > 0? 
            coordinatesArray.reduce((min, value) => {
                return value[1] < min[1]? value : min;
            }) : null;
        var maxLon = coordinatesArray && coordinatesArray.length > 0? 
            coordinatesArray.reduce((max, value) => {
                return value[1] > max[1]? value : max;
            }) : null;

        let maxBounds = minLat && maxLat && minLon && maxLon? [[minLat[0], minLon[1]], [maxLat[0], maxLon[1]]] : null;

        dispatch(polygonProvider(maxBounds));
        setDischargesHistory(true);
    };

    if (toolInUse === 'selection' && polygonLine.length > 0 && pointHelper.length > 0) {
        coordinatesArray = [...polygonLine, pointHelper];
        getMaxBounds();
    } else {
        coordinatesArray = [...polygonLine];
        getMaxBounds();
    }

    const points = coordinatesArray.map((point) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: point,
        },
    }));
    
    const measurePointsGeo = { type: 'FeatureCollection', features: points };
    const measureLineGeo = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: coordinatesArray,
        },
    };
    
    return (
        <>
            <Source
                id="polygonPoint"
                type="geojson"
                data={measurePointsGeo}
                generateId={true}
            >
                <Layer
                    id="polygonPoint"
                    source="polygon-points"
                    type="circle"
                    paint={{
                        'circle-color': '#4fb9c4',
                        'circle-radius': 6,
                    }}
                />
            </Source>
            <Source
                id="toolPolygon"
                type="geojson"
                data={measureLineGeo}
                generateId={true}
            >
                <Layer
                    id="toolPolygon"
                    source="polygon-points"
                    type="line"
                    paint={{
                        'line-color': '#4fb9c4',
                        'line-width': 1,
                    }}
                />
            </Source>
        </>
    );
};

export default memo(PolygonLayer);
