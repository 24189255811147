/* Libraries */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/* Material UI */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
/* Components */
import { useToast } from 'Context/ToastContext';
import { FaNewspaper, FaCloudSun, FaPen } from 'react-icons/fa'
/* CSS */
import styles from "./confirmation_modal-jss";
import fontStyles from "styles/fonts-jss";
import styled from 'styled-components';
/* Services */
import { postReportCard } from "services/mapsPortal/alerts"
/* Context */
import { useModal } from 'Context/ModalContext';

const Body = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 75%;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.6);
`
const HeaderDiv = styled.div`
  height: 16%;
  width: 100%;
  border-radius: 13px 13px 0 0;
  align-items: center;
  justify-content: center;
  display: flex;
  `
const ContentDiv = styled.div`
  height: 72%;
  width: 100%;
  background-color: white;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  `

const WarningContentDiv = styled.div`
  height: 15%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid;
  `

const TargetContentDiv = styled.div`
  height: 15%;
  width: 80%;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 3%
  `

const FooterDiv = styled.div`
  height: 12%;
  width: 100%;
  background-color: white;
  border-radius: 0 0 13px 13px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
  border-top: 1px solid;
  `

const useStyles = makeStyles({
  divider: {
    background: "#848484",
    border: "0.7px solid",
    width: "100%",
    marginTop: "1.5%"
  },
});


const ConfirmationModal = (props) => {
  const { classes, rightSideTabs, openConfirmationModal, setOpenConfirmationModal, statusTargetSelected, alertsData } = props;
  const fonts = fontStyles();
  const dividerStyle = useStyles();
  const [targetsList, setTargetsList] = useState();
  const [loading, setLoading] = useState(true);
  const openToast = useToast();
  const { plans: planos, toShow } = useModal();

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  const getInfos = async (targets) => {
    if (planos && planos.length > 0) {
      let aux = [];
      aux.push(planos.map((plan) => plan.planos.map((target) => target.alvos.map((item) => { statusTargetSelected.includes(item.nome) ? aux.push({ empresa: plan.nome, plano: target.nome, alvo: item.nome }) : null }))))
      for (let i = 0; i < aux.length; i++) {
        if (!aux[i].nome) {
          aux.pop(aux[i]);
        }
      }
      setTargetsList(aux);
      setLoading(false);
    }
  }

  const postAlert = async () => {
    if (rightSideTabs === 0) {
      /*var status = false;
      for (let i = 0; i < alertsData.length; i++) {
        if (alertsData[i]) {
          if(alertsData[i].intensidade != ""){
            await postReportCard(alertsData[i]).then((response) => {
              if (response.status === 201) {
                status = true;
              }
            })
          }
        }
      }
      if (status) {*/
      openToast("success", "Serviço não implentado!");
      /*}
      else {
        openToast("error", "Problema ao enviar Boletim Meteorológico!");
      } */
    }
    else {
      var status = false;
      for (let i = 0; i < alertsData.length; i++) {
        if (alertsData[i]) {
          if (alertsData[i].intensidade != "") {
            await postReportCard(alertsData[i]).then((response) => {
              if (response.status === 201) {
                status = true;
              }
            })
          }
        }
      }
      if (status) {
        openToast("success", "Boletim Meteorológico enviado com sucesso!");
      }
      else {
        openToast("error", "Problema ao enviar Boletim Meteorológico!");
      }
    }
  }

  useEffect(() => {
    if (statusTargetSelected && statusTargetSelected.length > 0) {
      getInfos();
    }
  }, []);


  if (!loading) {
    return (
      <Dialog
        open={openConfirmationModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
        <Body>
          <HeaderDiv style={rightSideTabs === 0 ? fonts.backgroundWeatherWarning : fonts.backgroundReportCard}>
            {rightSideTabs === 0 ? <span style={fonts.confirmationModalTitle}>ENVIAR AVISO</span> : <span style={fonts.confirmationModalTitle}>ENVIAR BOLETIM</span>}
          </HeaderDiv>

          <ContentDiv>
            <WarningContentDiv>
              <span style={fonts.warningMessage}>Atenção! Os seguinte alvos estão fora do horário de monitoramento:</span>
            </WarningContentDiv>

            {targetsList && targetsList.length > 0 && targetsList.map((target, index) => (
              <TargetContentDiv key={index}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span style={fonts.topic}>EMPRESA: </span><span style={fonts.item}>{target.empresa}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span style={fonts.topic}>PLANO: </span><span style={fonts.item}>{target.plano}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span style={fonts.topic}>ALVO: </span><span style={fonts.item}>{target.alvo}</span>
                </div>

                <Divider classes={{ root: dividerStyle.divider }} />
              </TargetContentDiv>
            ))}
          </ContentDiv>

          <FooterDiv>
            <div style={{ display: "flex", flexDirection: "row", marginRight: "5%", width: "48%" }} onClick={handleClose}>
              <Button style={fonts.buttonDefault} variant="contained" className={classes.button}>
                Alterar informações
                <div className={classes.iconContainer}>
                  <FaPen style={{ width: "16px", height: "16px", color: "#1C1B1F" }} />
                </div>
              </Button>

              <Button style={fonts.buttonDefault} variant="contained" className={rightSideTabs === 0 ? classes.buttonWeather : classes.buttonReport} onClick={postAlert}>
                Confirmar envio
                <div className={classes.iconContainer}>
                  {rightSideTabs === 0 ? <FaCloudSun style={{ width: "16px", height: "16px", color: "#000000" }} /> : <FaNewspaper style={{ width: "16px", height: "16px", color: "#C0A514" }} />}
                </div>
              </Button>
            </div>
          </FooterDiv>
        </Body>
      </Dialog>
    );
  }
}

ConfirmationModal.propTypes = {
  classes: PropTypes.object.isRequired,
  rightSideTabs: PropTypes.any.isRequired,
  openConfirmationModal: PropTypes.any.isRequired,
  setOpenConfirmationModal: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  alertsData: PropTypes.any.isRequired,
};

export default withStyles(styles)(ConfirmationModal);



