import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  getGroupDischarges10,
  getGroupDischarges20,
  getGroupDischarges30,
  getGroupDischarges40,
  getGroupDischarges50,
  getGroupDischarges60,
  getFlashesDischarges10,
  getFlashesDischarges20,
  getFlashesDischarges30,
  getFlashesDischarges40,
  getFlashesDischarges50,
  getFlashesDischarges60
} from 'services/mapsPortal/discharges'
import { getHistoryDischarges } from 'services/historyDischarges';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';
import useToggle from 'hooks/useToggle';
import { useSelector } from 'react-redux';
import {useTools} from 'Context/ToolsContext';

export const DischargesContext = createContext();

export const DischargesContextProvider = (props) => {

  // const{ data: descargas, loading: loadingDescargas, update: updateDescargas} = useLoad(getDischargeList, {});
  const [showDischarges, toggleDischarges] = useToggle(true);
  const { polygon } = useTools();
  const [dischargesToShow, setDischargesToShow] = useState([
    true,
    true,
    true,
    false,
    false,
    false,
  ]);
  const [dischargesTypeToShow, setDischargesTypeToShow] = useState([false, true]);
  const polygonSelected = useSelector(state => state.polygonProvider);
  const polygonData = polygonSelected && polygon.finishPolygon && polygon.polygonLine.length > 3? {
    "geometria": {
      "coordinates": [
        [
          [polygonSelected[0][0], polygonSelected[0][1]],
          [polygonSelected[1][0], polygonSelected[0][1]],
          [polygonSelected[1][0], polygonSelected[1][1]],
          [polygonSelected[0][0], polygonSelected[1][1]],
          [polygonSelected[0][0], polygonSelected[0][1]]
        ]
      ],
    "type": "Polygon"
    }
  } : null
  
  const moment = require('moment');
  moment.locale('pt-br');
  const [groups10RequestTime, setGroups10RequestTime] = useState(null);
  const [groups20RequestTime, setGroups20RequestTime] = useState(null);
  const [groups30RequestTime, setGroups30RequestTime] = useState(null);
  const [groups40RequestTime, setGroups40RequestTime] = useState(null);
  const [groups50RequestTime, setGroups50RequestTime] = useState(null);
  const [groups60RequestTime, setGroups60RequestTime] = useState(null);
  const [flashes10RequestTime, setFlashes10RequestTime] = useState(null);
  const [flashes20RequestTime, setFlashes20RequestTime] = useState(null);
  const [flashes30RequestTime, setFlashes30RequestTime] = useState(null);
  const [flashes40RequestTime, setFlashes40RequestTime] = useState(null);
  const [flashes50RequestTime, setFlashes50RequestTime] = useState(null);
  const [flashes60RequestTime, setFlashes60RequestTime] = useState(null);

  const{ data: groups10, loading: loadingGroups10} = useLoadWhenDischargesActive(getGroupDischarges10, (dischargesToShow[0] && dischargesTypeToShow[0] && !polygonData));
  const{ data: groups20, loading: loadingGroups20} = useLoadWhenDischargesActive(getGroupDischarges20, (dischargesToShow[1] && dischargesTypeToShow[0] && !polygonData));
  const{ data: groups30, loading: loadingGroups30} = useLoadWhenDischargesActive(getGroupDischarges30, (dischargesToShow[2] && dischargesTypeToShow[0] && !polygonData));
  const{ data: groups40, loading: loadingGroups40} = useLoadWhenDischargesActive(getGroupDischarges40, (dischargesToShow[3] && dischargesTypeToShow[0] && !polygonData));
  const{ data: groups50, loading: loadingGroups50} = useLoadWhenDischargesActive(getGroupDischarges50, (dischargesToShow[4] && dischargesTypeToShow[0] && !polygonData));
  const{ data: groups60, loading: loadingGroups60} = useLoadWhenDischargesActive(getGroupDischarges60, (dischargesToShow[5] && dischargesTypeToShow[0] && !polygonData));
  const{ data: flashes10, loading: loadingFlashes10} = useLoadWhenDischargesActive(getFlashesDischarges10, (dischargesToShow[0] && dischargesTypeToShow[1] && !polygonData));
  const{ data: flashes20, loading: loadingFlashes20} = useLoadWhenDischargesActive(getFlashesDischarges20, (dischargesToShow[1] && dischargesTypeToShow[1] && !polygonData));
  const{ data: flashes30, loading: loadingFlashes30} = useLoadWhenDischargesActive(getFlashesDischarges30, (dischargesToShow[2] && dischargesTypeToShow[1] && !polygonData));
  const{ data: flashes40, loading: loadingFlashes40} = useLoadWhenDischargesActive(getFlashesDischarges40, (dischargesToShow[3] && dischargesTypeToShow[1] && !polygonData));
  const{ data: flashes50, loading: loadingFlashes50} = useLoadWhenDischargesActive(getFlashesDischarges50, (dischargesToShow[4] && dischargesTypeToShow[1] && !polygonData));
  const{ data: flashes60, loading: loadingFlashes60} = useLoadWhenDischargesActive(getFlashesDischarges60, (dischargesToShow[5] && dischargesTypeToShow[1] && !polygonData));
  const{ data: historyGroups10, loading: loadingHistoryGroups10} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 10,  "Groups", polygonData), (dischargesToShow[0] && dischargesTypeToShow[0] && polygonData != null));
  const{ data: historyGroups20, loading: loadingHistoryGroups20} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 20,  "Groups", polygonData), (dischargesToShow[1] && dischargesTypeToShow[0] && polygonData != null));
  const{ data: historyGroups30, loading: loadingHistoryGroups30} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 30,  "Groups", polygonData), (dischargesToShow[2] && dischargesTypeToShow[0] && polygonData != null));
  const{ data: historyGroups40, loading: loadingHistoryGroups40} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 40,  "Groups", polygonData), (dischargesToShow[3] && dischargesTypeToShow[0] && polygonData != null));
  const{ data: historyGroups50, loading: loadingHistoryGroups50} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 50,  "Groups", polygonData), (dischargesToShow[4] && dischargesTypeToShow[0] && polygonData != null));
  const{ data: historyGroups60, loading: loadingHistoryGroups60} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 60,  "Groups", polygonData), (dischargesToShow[5] && dischargesTypeToShow[0] && polygonData != null));
  const{ data: historyFlashes10, loading: loadingHistoryFlashes10} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 10,  "Flashes", polygonData), (dischargesToShow[0] && dischargesTypeToShow[1] && polygonData != null));
  const{ data: historyFlashes20, loading: loadingHistoryFlashes20} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 20,  "Flashes", polygonData), (dischargesToShow[1] && dischargesTypeToShow[1] && polygonData != null));
  const{ data: historyFlashes30, loading: loadingHistoryFlashes30} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 30,  "Flashes", polygonData), (dischargesToShow[2] && dischargesTypeToShow[1] && polygonData != null));
  const{ data: historyFlashes40, loading: loadingHistoryFlashes40} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 40,  "Flashes", polygonData), (dischargesToShow[3] && dischargesTypeToShow[1] && polygonData != null));
  const{ data: historyFlashes50, loading: loadingHistoryFlashes50} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 50,  "Flashes", polygonData), (dischargesToShow[4] && dischargesTypeToShow[1] && polygonData != null));
  const{ data: historyFlashes60, loading: loadingHistoryFlashes60} = useLoadWhenDischargesActive(() => getHistoryDischarges(121, 60,  "Flashes", polygonData), (dischargesToShow[5] && dischargesTypeToShow[1] && polygonData != null));
  
  const toggleDischargeToShow = (index) => {
      setDischargesToShow((prev) => {
          const newDischarges = [...prev];
          newDischarges[index] = !newDischarges[index];
          return newDischarges;
      });
    };

    const toggleDischargeTypeToShow = (index) => {
      setDischargesTypeToShow((prev) => {
        const newDischarges = [...prev];
        newDischarges[index] = !newDischarges[index];
        return newDischarges;
      });
    };

    useEffect(() => {
      if(dischargesToShow[0] && dischargesTypeToShow[0] && !polygonData){
        setGroups10RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setGroups10RequestTime(null);
      }
    }, [groups10])
  
    useEffect(() => {
      if(dischargesToShow[1] && dischargesTypeToShow[0] && !polygonData){
        setGroups20RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setGroups20RequestTime(null);
      }
    }, [groups20])
  
    useEffect(() => {
      if(dischargesToShow[2] && dischargesTypeToShow[0] && !polygonData){
        setGroups30RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setGroups30RequestTime(null);
      }
    }, [groups30]) 
  
    useEffect(() => {
      if(dischargesToShow[3] && dischargesTypeToShow[0] && !polygonData){
        setGroups40RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setGroups40RequestTime(null);
      }
    }, [groups40]) 
  
    useEffect(() => {
      if(dischargesToShow[4] && dischargesTypeToShow[0] && !polygonData){
        setGroups50RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setGroups50RequestTime(null);
      }
    }, [groups50]) 
  
    useEffect(() => {
      if(dischargesToShow[5] && dischargesTypeToShow[0] && !polygonData){
        setGroups60RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setGroups60RequestTime(null);
      }
    }, [groups60])
  
    useEffect(() => {
      if(dischargesToShow[0] && dischargesTypeToShow[1] && !polygonData){
        setFlashes10RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
    }, [flashes10])
  
    useEffect(() => {
      if(dischargesToShow[1] && dischargesTypeToShow[1] && !polygonData){
        setFlashes20RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
    }, [flashes20])
  
    useEffect(() => {
      if(dischargesToShow[2] && dischargesTypeToShow[1] && !polygonData){
        setFlashes30RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
    }, [flashes30]) 
  
    useEffect(() => {
      if(dischargesToShow[3] && dischargesTypeToShow[1] && !polygonData){
        setFlashes40RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setFlashes40RequestTime(null);
      }
    }, [flashes40]) 
  
    useEffect(() => {
      if(dischargesToShow[4] && dischargesTypeToShow[1] && !polygonData){
        setFlashes50RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setFlashes50RequestTime(null);
      }
    }, [flashes50]) 
  
    useEffect(() => {
      if(dischargesToShow[5] && dischargesTypeToShow[1] && !polygonData){
        setFlashes60RequestTime(moment().format('DD/MM/YYYY, HH:mm:ss'));
      }
      else{
        setFlashes60RequestTime(null);
      }
    }, [flashes60])

    const loadingDescargas = loadingGroups10 || loadingGroups20 || loadingGroups30 || loadingFlashes10 || loadingFlashes20 || loadingFlashes30 ||
                              loadingHistoryGroups10 || loadingHistoryGroups20 || loadingHistoryGroups30 || loadingHistoryFlashes10 || loadingHistoryFlashes20 || loadingHistoryFlashes30
    return (
        <DischargesContext.Provider
            value={{
                historyGroups10,
                historyGroups20,
                historyGroups30,
                historyGroups40,
                historyGroups50,
                historyGroups60,
                historyFlashes10,
                historyFlashes20,
                historyFlashes30,
                historyFlashes40,
                historyFlashes50,
                historyFlashes60,
                groups10,
                groups20,
                groups30,
                groups40,
                groups50,
                groups60,
                flashes10,
                flashes20,
                flashes30,
                flashes40,
                flashes50,
                flashes60,
                loadingDescargas,
                showDischarges,
                toggleDischarges,
                dischargesToShow,
                toggleDischargeToShow,
                dischargesTypeToShow,
                toggleDischargeTypeToShow,
                groups10RequestTime,
                groups20RequestTime,
                groups30RequestTime,
                groups40RequestTime,
                groups50RequestTime,
                groups60RequestTime,
                flashes10RequestTime,
                flashes20RequestTime,
                flashes30RequestTime,
                flashes40RequestTime,
                flashes50RequestTime,
                flashes60RequestTime,
            }}
        >
            {props.children}
        </DischargesContext.Provider>
    );
};

DischargesContextProvider.propTypes = {
    children: PropTypes.node,
};

export default DischargesContextProvider;

const useLoadWhenDischargesActive = (func, active) => {
    const callback = useCallback(() => ({}), [])
    const{ data, loading, update, set} = useLoad(callback, {});
    const polygonSelected = useSelector(state => state.polygonProvider);
    const { polygon } = useTools();
    const polygonData = polygonSelected && polygon.finishPolygon && polygon.polygonLine.length > 3;

    useEffect(()=> {
      if(active) set(func)
        
      else set(callback)
    }, [active])

    useInterval(update, active ? 60000 : null);

    return {data, loading, update, set}
}

export const useDischarges = () => {
  const context = useContext(DischargesContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
