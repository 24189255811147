/* Libraries */
import React from 'react';
/* CSS */
import fontStyles from 'styles/fonts-jss';
import { withStyles } from '@material-ui/core/styles';
/* Material UI */
import { Button, Icon, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from 'prop-types';
import { getCompanies } from 'services/adminDashboard/company';
import { getAlertsReport } from 'services/historyDischarges';
import DateFnsUtils from "@date-io/date-fns";
import { useToast } from 'Context/ToastContext';
import { useEffect } from 'react';
import Loading from 'components/Loading';
import deLocale from "date-fns/locale/pt-BR";
import styles from './reports-jss';
import 'moment/locale/pt-br';
import ExportExcelFile from 'components/Tables/ExportExcelFile';


const AlertsReport = (props) => {
    const { classes } = props;
    const fonts = fontStyles();
    const moment = require('moment');
    moment.locale('pt-br');
    const [company, setCompany] = useState();
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [companyList, setCompanyList] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [companyError, setCompanyError] = useState();
    const [initialDateError, setInitialDateError] = useState();
    const [finalDateError, setFinalDateError] = useState();
    const [loadingCompany, setLoadingCompany] = useState(true);
    const [loadingReportData, setLoadingReportData] = useState(true);
    const openToast = useToast();

    //Pegando os dados das Empresas
    const loadCompany = async () => {
        let aux = [];

        await getCompanies()
            .then((response) => {
                if (response) {
                    aux = response;
                }
                if (
                    response.nome != '' &&
                    response.funcionamento &&
                    response.funcionamento.length > 0
                ) {
                    aux.push({ nome: response.nome, id_empresa: '' });
                }

                setLoadingCompany(false);
                setCompanyList(aux);
            })
            .catch((error) => {
                openToast(
                    'error',
                    'Problema ao carregar empresas, recarregue a página e tente novamente!',
                );
                setLoadingCompany(false);
            });
    };

    const loadAlertsReport = async () => {
        await getAlertsReport(companyList.filter((data) => data.nome === company)[0]?.id_empresa, moment(initialDate).format("YYYYMMDD"), moment(finalDate).format("YYYYMMDD")).then((response) => {
            if(response.status === 200){
                setReportData(response.data);
            }
            else{
                openToast(
                    'error',
                    'Problema ao carregar relatório, recarregue a página e tente novamente!',
                ); 
            }
            setLoadingReportData(false);
        }).catch(() => {
            openToast(
                'error',
                'Problema ao carregar relatório, recarregue a página e tente novamente!',
            ); 
            setLoadingReportData(false);
        })
    }

    useEffect(() => {
        let formatedInitialDate = moment(initialDate).format('YYYY-MM-DD')
        let formatedFinalDate = moment(finalDate).format('YYYY-MM-DD')
        setLoadingReportData(true);

        if (!company) {
            setCompanyError('Campo obrigatório!');
        } else {
            setCompanyError('');
            sessionStorage.setItem("cLFfX97S5j", JSON.stringify([0, company]))
        }

        if(initialDate != "Invalid Date" && company){
            if(!moment(formatedInitialDate, "YYYY-MM-DD").isSameOrBefore(moment(formatedFinalDate).format('YYYY-MM-DD'))){
                openToast('error', 'O Ínicio do período não pode ser posterior ao Final do período!');
                setInitialDateError("Não é possível selecionar um dia posterior ao último dia escolhido!")
            }
            else{
                setInitialDateError("");
            }
        }
        else if(company){
            openToast('error', 'A data inicial precisa estar no formato correto!');
            setInitialDateError("A data inicial precisa estar no formato correto!")
        }

        if(finalDate != "Invalid Date" && company){
            if(moment(formatedFinalDate, "YYYY-MM-DD").isBefore(moment(formatedInitialDate).format('YYYY-MM-DD'))){
                openToast('error', 'O Final do período não pode ser anterior ao Ínicio do período!');
                setFinalDateError("Não é possível selecionar um dia anterior ao primeiro dia escolhido!")
            }
            else{
                setFinalDateError("");
            }
        }
        else if(company){
            openToast('error', 'A data final precisa estar no formato correto!');
            setFinalDateError('error', 'A data final precisa estar no formato correto!')
        }
        
    }, [company, initialDate, finalDate]);

    useEffect(() => {
        if (loadingCompany) {
            loadCompany();
        }
    }, []);

    useEffect(() => {
        if ((company && initialDate && finalDate) && (!companyError && !initialDateError && !finalDateError)) {
            loadAlertsReport();
        }
    }, [company, initialDate, finalDate]);

    function sortData(a, b) {
        if (a.nome < b.nome) {
            return -1;
        }
        if (a.nome > b.nome) {
            return 1;
        }
        return 0;
    }


    if (loadingCompany) {
        return <Loading />;
    } else {
        return (
            <div>
                <div className={classes.registerContainer}>
                    <Typography
                        variant="subtitle1"
                        color="primary"
                        style={fonts.formsSubTitle}
                    >
                        Selecionar Alvo
                    </Typography>

                    <div className={classes.registerContentContainer}>
                        <div className={classes.dataContainer}>
                            {!loadingCompany && (
                                <div className={classes.registerItemContainer}>
                                    <Autocomplete
                                        id="company"
                                        value={company}
                                        disableClearable
                                        onChange={(_, newValue) =>
                                            setCompany(newValue)
                                        }
                                        className={
                                            classes.selectFieldExtraLarge
                                        }
                                        style={fonts.formsFieldHeight5}
                                        size="small"
                                        options={companyList
                                            .sort(sortData)
                                            .map((option) => option.nome)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecionar empresa"
                                                margin="normal"
                                                style={{ marginBottom: '0px' }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    {companyError && (
                                        <p style={fonts.formsErrorMessage}>
                                            {companyError}
                                        </p>
                                    )}
                                </div>
                            )}

                            {!loadingCompany && company && (
                                <>
                                    <div className={classes.registerItemContainer}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                            <KeyboardDatePicker
                                              format="dd/MM/yyyy"
                                              value={initialDate}
                                              style={{marginTop: "0px"}}
                                              onChange={(date) => setInitialDate(date)}
                                              animateYearScrolling={false}
                                              label="Início do período"
                                              className={classes.dateField}
                                              inputProps={{style: fonts.formsField}}
                                              InputLabelProps={{style: fonts.formsLabel}} />
                                            </MuiPickersUtilsProvider>
                                    </div>

                                    <div className={classes.registerItemContainer}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                            <KeyboardDatePicker
                                              format="dd/MM/yyyy"
                                              value={finalDate}
                                              style={{marginTop: "0px"}}
                                              onChange={(date) => setFinalDate(date)}
                                              animateYearScrolling={false}
                                              label="Final do período"
                                              className={classes.dateField}
                                              inputProps={{style: fonts.formsField}}
                                              InputLabelProps={{style: fonts.formsLabel}} />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </>
                            )}
                        
                            {!loadingReportData && (!companyError && !initialDateError && !finalDateError) && 
                                <div className={classes.registerItemContainer}>
                                    <ExportExcelFile
                                        dataSet={reportData}
                                        type={"Relatorio de alertas"}
                                        csvButtonName={"Baixar relatório"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

AlertsReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlertsReport);
