/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
/* Components */
import FieldsComponent from "./fieldsComponent";
/* CSS */
import styles from "./rain-alerts_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';


const useStyles = makeStyles({
  divider: {
    background: "#848484",
    border: "1px solid",
    width: "100%",
  },
});


const RainAlert = (props) => {
  const fonts = fontStyles();
  const dividerStyle = useStyles();
  const { classes, alertsData, setAlertsData, setReloadSwitchSelected, allSelected, setAllSelected, setReloadErrorMessage, setStatusTargetSelected, statusTargetSelected, setReloadStatusTargetSelected, rainAlertErrors, setRainAlertErrors, reloadTargets, setReloadTargets, } = props;
  const [reloadModal, setReloadModal] = useState(false);
  const [caractersRemaning, setCaractersRemaning] = useState();
  const { plans: planos, toShow } = useModal();
  const [allSelectedAux, setAllSelectedAux] = useState(false);
  const [loadingRightSide, setLoadingRightSide] = useState([false]);
  const [reloadRightSide, setReloadRightSide] = useState(false);

  const [preset, setPreset] = useState({
    preset: false,
    acumuladoInicial: "",
    acumuladoFinal: "",
    intensidade: "",
    duracao: "",
    contato: "",
    observacao: null,
    meteorologista: "",
    conteudo_modificacao: null,
    modificacao: null,
    campos_modificados: null,
    id_alvo: "",
  });


  useEffect(() => {
    setReloadModal(false);
  }, [reloadModal])

  useEffect(() => {
    if (preset.observacao && preset.observacao.length > 1 && preset.observacao.length < 50) {
      setCaractersRemaning(50 - preset.observacao.length);
    }
    else {
      setCaractersRemaning("");
    }
  }, [preset])

  useEffect(() => {
    setReloadRightSide(false);
  }, [reloadRightSide])

  return (
    <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "5px", padding:'10px' }}>
      <Grid container className={classes.gridContainer}>
        <div className={classes.acumulatedContainer}>
          <span style={fonts.formsFieldHeight6}>Acumulado</span>
          <div className={classes.acumulatedFieldsContainer}>
            <TextField
               id="comments"
               multiline
               shrink={true}
               value={preset?.acumuladoInicial}
               onChange={(e) => setPreset({ ...preset, acumuladoInicial: e.target.value })}
               className={classes.acumulatedField}
               variant="outlined"
               color="primary"
               size="small"
               InputLabelProps={{ shrink: true }}
               inputProps={{ style: fonts.formsFieldHeight2, maxLength: 3 }}
            />
            <span style={fonts.formsFieldHeight2}>-</span>
            <TextField
              id="comments"
              multiline
              shrink={true}
              value={preset?.acumuladoFinal}
              onChange={(e) => setPreset({ ...preset, acumuladoFinal: e.target.value })}
              className={classes.acumulatedField}
              variant="outlined"
              color="primary"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: fonts.formsFieldHeight2, maxLength: 3 }}
            />
            <span style={fonts.formsFieldHeight2}>mm</span>
          </div>
        </div>

        <InputMask
          mask="99:99"
          value={preset?.duracao}
          onChange={(e) => setPreset({ ...preset, duracao: e.target.value })}
          disabled={false}
          maskChar=" "
        >{() =>
          <TextField
            id="duration"
            className={classes.durationField}
            margin="normal"
            variant="outlined"
            label="Duração"
            inputProps={{ style: fonts.formsFieldHeight1 }}
            InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
          />}
        </InputMask>
          
        <Autocomplete
          id="contacts"
          freeSolo
          value={preset?.contato}
          inputValue={preset?.contato}
          disableClearable
          onInputChange={(_, newValue) => setPreset({ ...preset, contato: newValue })}
          onChange={(_, newValue) => setPreset({ ...preset, contato: newValue })}
          className={classes.contactsField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["Não foi possível o contato telefônico.",  "Não foi realizado o contato telefônico.", "Não há contato telefônico das xx:xx até às xx:xx." ]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Contato"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }}
            />}
        />
      </Grid>

      <Grid container className={classes.gridContainer} style={{marginTop: '10px'}}>
        <Autocomplete
          id="intensity"
          freeSolo={false}
          value={preset?.intensidade}
          inputValue={preset?.intensidade}
          disableClearable
          onChange={(_, newValue) => setPreset({ ...preset, intensidade: newValue })}
          className={classes.intensityField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["Fraca", "Fraca a moderada", "Moderada", "Moderada a forte", "Forte", "Muito forte", "Extrema"]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Intensidade"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }}
            />}
        />

        <TextField
          id="comments"
          label="Observações"
          multiline
          shrink={true}
          value={preset?.observacao}
          onChange={(e) => setPreset({ ...preset, observacao: e.target.value })}
          className={classes.commentsField}
          variant="outlined"
          color="primary"
          size="small"
          InputLabelProps={{ shrink: true }}
          inputProps={{ style: fonts.formsFieldHeight2, maxLength: 50 }}
        />
      </Grid>

      <Grid container className={classes.commentsContainer}>
        {caractersRemaning && <p className={classes.errorMessage}>{caractersRemaning + " caracteres restantes"}</p>}
      </Grid>

      <Grid container className={classes.buttonContainer}>
        <Button style={fonts.buttonDefault} variant="contained" className={classes.rainButton} onClick={() => setPreset({ ...preset, preset: !preset?.preset })}>
          Alterar todos
        </Button>
      </Grid>

      <Grid container className={classes.gridContainer} style={{marginTop: '10px'}}>
        <Divider classes={{ root: dividerStyle.divider }} />
      </Grid>

      {loadingRightSide.length != 0 &&
        <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" />
      }

      {planos?.map(company => company.planos.map((plan) => {
        return plan.alvos.map((target) => {
          if (!toShow.get(company.id, plan.id, target.id)) return null
          return (
            <div key={target.id}>
              <FieldsComponent target={target} preset={preset} alertsData={alertsData} setAlertsData={setAlertsData} plano={plan} setReloadModal={setReloadModal} setReloadSwitchSelected={setReloadSwitchSelected} allSelected={allSelected} setAllSelected={setAllSelected} allSelectedAux={allSelectedAux} setAllSelectedAux={setAllSelectedAux} setReloadErrorMessage={setReloadErrorMessage} setStatusTargetSelected={setStatusTargetSelected} statusTargetSelected={statusTargetSelected} setReloadStatusTargetSelected={setReloadStatusTargetSelected} setLoadingRightSide={setLoadingRightSide} loadingRightSide={loadingRightSide} setReloadRightSide={setReloadRightSide} rainAlertErrors={rainAlertErrors} setRainAlertErrors={setRainAlertErrors} reloadTargets={reloadTargets} setReloadTargets={setReloadTargets}/>
            </div>
          )
        })
      }))}
    </Grid>
  )
}

RainAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func.isRequired,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  rainAlertErrors: PropTypes.any.isRequired,
  setRainAlertErrors: PropTypes.func.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
};

export default withStyles(styles)(RainAlert);