export default function (state = false, action){
    switch(action.type){
        case "HISTORY_DISCHARGES_DATA_PROVIDER":
            return [action.payload[0], action.payload[1]]
            
    default: return state
    }
}



