export default function (state = [false, false], action){
    switch(action.type){
        case "HISTORY_DISCHARGES_CONTROL_PROVIDER":
            return [action.payload[0], action.payload[1]]
            
    default: return state
    }
}



