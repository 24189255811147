/* Libraries */
import PropTypes from 'prop-types';
import { useState, useStyles, useEffect, useRef, React, PureComponent } from 'react';

/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";

/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import html2canvas from 'html2canvas';
import ArrowLeft from '../ArrowLeft';

/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { getGraphicsData } from 'services/mapsPortal/alerts';

//MODAL
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { Column, Row } from 'components/Flex';
import Collapsible from 'components/Collapsible';
import TargetInfo from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab/TargetInfo';

// Hooks
import { useModal } from 'Context/ModalContext';
import { useTargetState } from 'Context/TargetStateContext';
import {postModifiedAlerts} from 'services/mapsPortal/alerts'
import Company from 'pages/AdminDashboard/Edit/Company';

const GraphicType = styled.div`
background-color: white;
border-radius: 13px 0 0 13px;
margin-top:50px;
margin-bottom:30px;
height:40%;
`

const GraphicOptionsGeneral = (props) => {
  const { classes, infos, graphicTypes} = props;

  const [loadingData, setLoadingData] = useState(true)
  const [sumMonths, setSumMonths] = useState({"1": 0, "2": 0, "3": 0, "4":0, "5":0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11":0, "12":0})
  const [uniqueYears, setUniqueYears] = useState([])

  const [graphicDataVals1, setGraphicDataVals1] = useState([]);
  const [graphicDataVals2, setGraphicDataVals2] = useState([]);
  const [graphicDataVals3, setGraphicDataVals3] = useState([]);
  const [graphicDataVals4, setGraphicDataVals4] = useState([]);
  const [graphicDataVals5, setGraphicDataVals5] = useState([]);

  const getValuesYearMonth = (value) => {
    let counter = 0
    for (let i = 1; i <= 12; i++) {
      if(infos.alertsYearMonth[value+"/"+i]!=null && infos.alertsYearMonth[value+"/"+i]!=0 ){
        counter+=parseInt(infos.alertsYearMonth[value+"/"+i])
        sumMonths[String(i)] +=parseInt(infos.alertsYearMonth[value+"/"+i])
      }
    }
        return(
          <>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/01"]==0? "" : infos.alertsYearMonth[value+"/01"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/01"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/01"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/01"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/01"]!=null? infos.alertsYearMonth[value+"/01"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/02"]==0? "" : infos.alertsYearMonth[value+"/02"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/02"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/02"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/02"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/02"]!=null? infos.alertsYearMonth[value+"/02"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/03"]==0? "" : infos.alertsYearMonth[value+"/03"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/03"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/03"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/03"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/03"]!=null? infos.alertsYearMonth[value+"/03"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/04"]==0? "" : infos.alertsYearMonth[value+"/04"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/04"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/04"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/04"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/04"]!=null? infos.alertsYearMonth[value+"/04"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/05"]==0? "" : infos.alertsYearMonth[value+"/05"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/05"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/05"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/05"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/05"]!=null? infos.alertsYearMonth[value+"/05"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/06"]==0? "" : infos.alertsYearMonth[value+"/06"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/06"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/06"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/06"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/06"]!=null? infos.alertsYearMonth[value+"/06"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/07"]==0? "" : infos.alertsYearMonth[value+"/07"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/07"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/07"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/07"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/07"]!=null? infos.alertsYearMonth[value+"/07"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/08"]==0? "" : infos.alertsYearMonth[value+"/08"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/08"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/08"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/08"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/08"]!=null? infos.alertsYearMonth[value+"/08"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/09"]==0? "" : infos.alertsYearMonth[value+"/09"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/09"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/09"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/09"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/09"]!=null? infos.alertsYearMonth[value+"/09"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/10"]==0? "" : infos.alertsYearMonth[value+"/10"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/10"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/10"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/10"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/10"]!=null? infos.alertsYearMonth[value+"/10"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/11"]==0? "" : infos.alertsYearMonth[value+"/11"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/11"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/11"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/11"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/11"]!=null? infos.alertsYearMonth[value+"/11"] : 0}</td>
            <td style={{backgroundColor:infos.alertsYearMonth[value+"/12"]==0? "" : infos.alertsYearMonth[value+"/12"]==1 ? "#D5D5D5" :  infos.alertsYearMonth[value+"/12"]==2 ? "#AAAAAA" :  infos.alertsYearMonth[value+"/12"]==3 ? "#7F7F7F" : infos.alertsYearMonth[value+"/12"]==4? "#555555" : "", textAlign:"center", width:"40px"}}>{infos.alertsYearMonth[value+"/12"]!=null? infos.alertsYearMonth[value+"/12"] : 0}</td>
            <td style={{color:"#AC595B", textAlign:"center"}}>{counter}</td>
          </>
        )
  }


  const loadGraphicsData = () => {
    let dataDayMonth = infos.alertsDayMonth
    let dataHours = infos.durationAlerts
    let yearMonths = infos.alertsYearMonth
    let alertsMonths = infos.alertMonth
    let buffersMonth = infos.buffers
    let graphicDataVals1 = []
    let graphicDataVals3 = []
    let graphicDataVals4 = []
    let graphicDataVals5 = []
    let graphicDataVals6 = []

    Object.keys(dataDayMonth).forEach(function(key, index) {
      graphicDataVals1.push({name: key, uv: dataDayMonth[key]})
    });

    Object.keys(yearMonths).forEach(function(key, index) {
      if(!uniqueYears.includes(key.split("/")[0])){
        let aux = uniqueYears
        aux.push(key.split("/")[0])
        aux.sort()
        setUniqueYears(aux)
      }
    });


    Object.keys(dataHours).forEach(function(key, index) {
      for (let i = 0; i < 24; i++) {
        if(parseInt(key)==i){
          if(i>0 && i<10){
            graphicDataVals3.push({name: '0'+String(i)+':00-0'+String(i)+':59', uv: dataHours[key], fill: '#023047'}) 
          }else{
            graphicDataVals3.push({name: String(i)+':00-'+String(i)+':59', uv: dataHours[key], fill: '#023047'}) 
          }
          
        }
      }
    });

    Object.keys(alertsMonths).forEach(function(key, index) {
      graphicDataVals4.push({name: key, uv: alertsMonths[key], fill: '#023047'})
    });

    for (let i = 1; i < 13; i++) {
      let month = i==1? "Jan" : i==2? "Fev" : i==3? "Mar" : i==4? "Abr" : i==5? "Mai" : i==6? "Jun" : i==7? "Jul" : i==8? "Ago" : i==9? "Set" : i==10? "Out" : i==11? "Nov" : i==12? "Dez" : ""
      if(buffersMonth['4km'][i] && buffersMonth['10km'][i] && buffersMonth['20km'][i]){
        graphicDataVals5.push({name: i, uv: buffersMonth['4km'][i], va: buffersMonth['10km'][i], dp: buffersMonth['20km'][i]})
      }
      
    }

    for (let i = 0; i < 24; i++) {
      if(buffersMonth['4km']["H"+String(i)] && buffersMonth['10km']["H"+String(i)] && buffersMonth['20km']["H"+String(i)]){
        graphicDataVals6.push({name: i<10? "0"+String(i)+":00-0"+String(i)+":59" : String(i)+":00-"+String(i)+":59", uv: buffersMonth['4km']["H"+String(i)], va: buffersMonth['10km']["H"+String(i)], dp: buffersMonth['20km']["H"+String(i)]})
      }
      
    }

    

    setGraphicDataVals1(graphicDataVals1)
    setGraphicDataVals2(graphicDataVals3)
    setGraphicDataVals3(graphicDataVals4)
    setGraphicDataVals4(graphicDataVals5)
    setGraphicDataVals5(graphicDataVals6)
    
    setLoadingData(false)
  }


  useEffect(() => {
    if(infos!=null){
      loadGraphicsData()
    }
  }, []);


  if(!loadingData){
    return(
      graphicTypes.map((graphic, index)=>{
        return(
          <>
              {graphic=="Histórico dos Alertas"?
                <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                  <GraphicType>
                    <h4 style={{textAlign:"center"}}>ALERTAS - GERAL</h4>
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        layout="vertical"
                        height={300}
                        data={graphicDataVals1}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 40,
                          bottom: 10,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number">
                          <Label value="Nº de Alertas" offset={-10} position="insideBottom" />
                        </XAxis>
                        <YAxis dataKey="name" type="category" label={{ value: 'Dia/Mês', angle: -90, position: 'insideLeft', offset: -10 }}/>
                        <Tooltip />
                        <Bar dataKey="uv" fill="#023047" />
                      </BarChart>
                    </ResponsiveContainer>
                  
                    <h4 style={{textAlign:"center", marginTop:"30px"}}>HISTÓRICO DOS ALERTAS - GERAL</h4>
                      <table style={{marginBottom:"50px"}}>
                          
                          {uniqueYears.map((element, index)=>{
                            return(
                              <tr key={index}>
                                <td style={{textAlign:"center", width:"40px", fontWeight:"bold"}}>{element}</td>
                                  {getValuesYearMonth(element)}
                              </tr>
                            )
                          })}
                        <tr>
                          <td style={{textAlign:"center", width:"40px", fontWeight:"bold"}}>Total</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[1]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[2]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[3]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[4]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[5]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[6]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[7]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[8]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[9]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[10]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[11]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[12]}</td>
                          <td style={{color:"#AC595B", textAlign:"center"}}>{sumMonths[1]+sumMonths[2]+sumMonths[3]+sumMonths[4]+sumMonths[5]+sumMonths[6]+sumMonths[7]+sumMonths[8]+sumMonths[9]+sumMonths[10]+sumMonths[11]+sumMonths[12]}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{fontWeight:"bold"}}>JAN</td>
                          <td style={{fontWeight:"bold"}}>FEV</td>
                          <td style={{fontWeight:"bold"}}>MAR</td>
                          <td style={{fontWeight:"bold"}}>ABR</td>
                          <td style={{fontWeight:"bold"}}>MAI</td>
                          <td style={{fontWeight:"bold"}}>JUN</td>
                          <td style={{fontWeight:"bold"}}>JUL</td>
                          <td style={{fontWeight:"bold"}}>AGO</td>
                          <td style={{fontWeight:"bold"}}>SET</td>
                          <td style={{fontWeight:"bold"}}>OUT</td>
                          <td style={{fontWeight:"bold"}}>NOV</td>
                          <td style={{fontWeight:"bold"}}>DEZ</td>
                          <td style={{fontWeight:"bold"}}>Total</td>
                        </tr>
                      </table>
                    </GraphicType>
                </div>
                : graphic=="Histórico da Duração"?
                    graphicTypes.includes("Histórico da Variação Diurna dos Alertas")?
                      <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>HISTÓRICO DA DURAÇÃO DOS ALERTAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              layout="vertical"
                              height={300}
                              data={graphicDataVals2}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis type="number">
                                <Label value="Nº de Alertas" offset={-10} position="insideBottom" />
                              </XAxis>
                              <YAxis dataKey="name" type="category" label={{ value: 'Horário', angle: -90, position: 'insideLeft', offset: -50 }} scale="band"/>
                              <Tooltip />
                              <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>HISTÓRICO DA VARIAÇÃO DIURNA DOS ALERTAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              layout="vertical"
                              height={300}
                              data={graphicDataVals2}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis type="number">
                                <Label value="Nº de Alertas" offset={-10} position="insideBottom" />
                              </XAxis>
                              <YAxis dataKey="name" type="category" label={{ value: 'Horário', angle: -90, position: 'insideLeft', offset: -50 }} scale="band"/>
                              <Tooltip />
                              <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                      </div>
                    :
                      <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>HISTÓRICO DO PERÍODO DOS ALERTAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              layout="vertical"
                              height={300}
                              data={graphicDataVals2}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis type="number">
                                <Label value="Nº de Alertas" offset={-10} position="insideBottom" />
                              </XAxis>
                              <YAxis dataKey="name" type="category" label={{ value: 'Horário', angle: -90, position: 'insideLeft', offset: -50 }} scale="band"/>
                              <Tooltip />
                              <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>     
                      </div>  
                : graphic=="Histórico da Variação Diurna dos Alertas" && !graphicTypes.includes("Histórico da Duração")?
                  <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                      <GraphicType>
                        <h4 style={{textAlign:"center"}}>HISTÓRICO DA VARIAÇÃO DIURNA DOS ALERTAS - GERAL</h4>
                        <ResponsiveContainer>
                          <BarChart
                            width={500}
                            layout="vertical"
                            height={300}
                            data={graphicDataVals2}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 60,
                              bottom: 10,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number">
                              <Label value="Nº de Alertas" offset={-10} position="insideBottom" />
                            </XAxis>
                            <YAxis dataKey="name" type="category" label={{ value: 'Horário', angle: -90, position: 'insideLeft', offset: -50 }} scale="band"/>
                            <Tooltip />
                            <Bar dataKey="uv" fill="#82ca9d" />
                          </BarChart>
                        </ResponsiveContainer>
                      </GraphicType> 
                  </div>
                : graphic=="Acumulado Mensal dos Alertas"?
                    graphicTypes.includes("Acumulado Mensal das Descargas Atmosféricas")?
                      <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>ACUMULADO MENSAL DOS ALERTAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              height={300}
                              data={graphicDataVals3}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                                <Label value="Mês" offset={-10} position="insideBottom" />
                              </XAxis>
                              <YAxis  label={{ value: 'Nº de Alertas', angle: -90, position: 'insideLeft', offset: -5 }} />
                              <Tooltip />
                              <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>ACUMULADO MENSAL DAS DESCARGAS ATMOSFÉRICAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              height={300}
                              data={graphicDataVals4}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                              </XAxis>
                              <YAxis  label={{ value: 'Nº de Alertas', angle: -90, position: 'insideLeft', offset: -5 }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="uv" barSize={33} stackId="a" fill="#4A7B11" />
                              <Bar dataKey="dp" barSize={33} stackId="a" fill="#F5AA2D" />
                              <Bar dataKey="va" barSize={33} stackId="a" fill="#D0021B" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                      </div>
                    :
                      <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>ACUMULADO MENSAL DOS ALERTAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              height={300}
                              data={graphicDataVals3}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                                <Label value="Mês" offset={-10} position="insideBottom" />
                              </XAxis>
                              <YAxis  label={{ value: 'Nº de Alertas', angle: -90, position: 'insideLeft', offset: -5 }} />
                              <Tooltip />
                              <Bar dataKey="uv" fill="#82ca9d" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                      </div>

                : graphic=="Acumulado Mensal das Descargas Atmosféricas" && !graphicTypes.includes("Acumulado Mensal dos Alertas") ?
                  
                    graphicTypes.includes("Histórico da Variação Diurna das Descargas Atmosféricas")?
                      <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>ACUMULADO MENSAL DAS DESCARGAS ATMOSFÉRICAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              height={300}
                              data={graphicDataVals4}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                              </XAxis>
                              <YAxis  label={{ value: 'Nº de Alertas', angle: -90, position: 'insideLeft', offset: -5 }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="uv" barSize={33} stackId="a" fill="#4A7B11" />
                              <Bar dataKey="dp" barSize={33} stackId="a" fill="#F5AA2D" />
                              <Bar dataKey="va" barSize={33} stackId="a" fill="#D0021B" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>HISTÓRICO DA VARIAÇÃO DIURNA DAS DESCARGAS ATMOSFÉRICAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              layout="vertical"
                              height={300}
                              barCategoryGap={20}
                              data={graphicDataVals5}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis type="number"/>
                              <YAxis dataKey="name" type="category" label={{ value: 'Horário', angle: -90, position: 'insideLeft', offset: -50 }} scale="band"/>
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="uv" barSize={33} stackId="a" fill="#4A7B11" />
                              <Bar dataKey="dp" barSize={33} stackId="a" fill="#F5AA2D" />
                              <Bar dataKey="va" barSize={33} stackId="a" fill="#D0021B" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                      </div>
                    :
                      <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                        <GraphicType>
                          <h4 style={{textAlign:"center"}}>ACUMULADO MENSAL DAS DESCARGAS ATMOSFÉRICAS - GERAL</h4>
                          <ResponsiveContainer>
                            <BarChart
                              width={500}
                              height={300}
                              data={graphicDataVals4}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 60,
                                bottom: 10,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                              </XAxis>
                              <YAxis  label={{ value: 'Nº de Alertas', angle: -90, position: 'insideLeft', offset: -5 }} />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="uv" barSize={33} stackId="a" fill="#4A7B11" />
                              <Bar dataKey="dp" barSize={33} stackId="a" fill="#F5AA2D" />
                              <Bar dataKey="va" barSize={33} stackId="a" fill="#D0021B" />
                            </BarChart>
                          </ResponsiveContainer>
                        </GraphicType>
                      </div>
                : graphic=="Histórico da Variação Diurna das Descargas Atmosféricas"?
                  !graphicTypes.includes("Acumulado Mensal das Descargas Atmosféricas")&&
                    <div key={index} className={"graphicsGeneral_div_"+String(index)} id={"graphicsGeneral_div_"+String(index)} style={{width:"1200px", height:"800px", paddingTop:"20px"}}>
                      <GraphicType>
                        <h4 style={{textAlign:"center"}}>HISTÓRICO DA VARIAÇÃO DIURNA DAS DESCARGAS ATMOSFÉRICAS - GERAL</h4>
                        <ResponsiveContainer>
                          <BarChart
                            width={500}
                            layout="vertical"
                            height={300}
                            barCategoryGap={20}
                            data={graphicDataVals5}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 60,
                              bottom: 10,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number"/>
                            <YAxis dataKey="name" type="category" label={{ value: 'Horário', angle: -90, position: 'insideLeft', offset: -50 }} scale="band"/>
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="uv" barSize={33} stackId="a" fill="#4A7B11" />
                            <Bar dataKey="dp" barSize={33} stackId="a" fill="#F5AA2D" />
                            <Bar dataKey="va" barSize={33} stackId="a" fill="#D0021B" />
                          </BarChart>
                        </ResponsiveContainer>
                      </GraphicType>
                    </div>
                : ""
              }

            </>

        )
      })
    )
  }
} 

GraphicOptionsGeneral.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  setOpenGraphics: PropTypes.func,
  openGraphics: PropTypes.bool,
  infos: PropTypes.array.isRequired,
  graphicTypes: PropTypes.string.isRequired,
  anchorEl: PropTypes.bool,
  setReloadMod: PropTypes.func,  
};
  
export default withStyles(styles)(GraphicOptionsGeneral);