import React, {memo} from 'react'
import P from 'prop-types';
import {Layer, Source} from 'react-map-gl';


const TargetComponent = ({target, targetState, onTime, type, index}) => {

  return (
    <>
    {(type === "discharges" && target?.coordenadas?.features[0]?.geometry?.type === "MultiPoint") || (type === "historyTargets" && target?.geometry?.type === "MultiPoint")?
      <Source
        key={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
        id={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
        type="geojson"
        data={type === "discharges"? target.coordenadas.features[0] : target}
        generateId={true}
        >
        <Layer
        key={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
        source={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
          type="circle"
          layout={{
            visibility: 'visible',
            // visibility: !showTargets
              // ? 'none'
              // : 'visible',
          }}
          paint={{
            'circle-color': '#808080',
            'circle-radius': {
              stops: [[5,2],[12,10], [16,30]]
            },
          }}
        />
      </Source> :
      <Source
        key={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
        id={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
        type="geojson"
        data={type === "discharges"? target.coordenadas.features[0] : target}
        generateId={true}
        >
        <Layer
            id={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
            source={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
            type="line"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
                'line-color': [
                  'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#08F',
                    targetState
                    ?
                    [
                      'match',
                      ['get', 'buffer'],
                      '0',
                      '#808080',
                      '1',
                      '#223b53',
                      '4',
                      '#B067E9',
                      '10',
                      'rgba(0,0,0,0)',
                      '20',
                      'rgba(0,0,0,0)',
                      '#ccc',
                    ]
                    : !onTime
                      ?
                      '#999'
                      :
                        [
                            'match',
                            ['get', 'buffer'],
                            '0',
                            '#808080',
                            '1',
                            '#223b53',
                            '4',
                            'rgba(0,0,0,0)',
                            '10',
                            'rgba(0,0,0,0)',
                            '20',
                            'rgba(0,0,0,0)',
                            '#ccc',
                        ],
                ],
                'line-width': 1,
            }}
        />
    </Source>
    }
    {(type === "discharges" && target.coordenadas.features[0].geometry.type === "Point") || (type === "historyTargets" && target.geometry.type === "Point") &&
      (
        <Source
          key={type === "discharges"? `${target.nome}-${target.id_alvo}-points` : `${target.properties.nome}-${target.properties.id_alvo}-points`}
          id={type === "discharges"? `${target.nome}-${target.id_alvo}-points` : `${target.properties.nome}-${target.properties.id_alvo}-points`}
          type="geojson"
          data={type === "discharges"? target.coordenadas.features[0] : target}
          generateId={true}
          >
          <Layer
            id={type === "discharges"? `${target.nome}-${target.id_alvo}-points` : `${target.properties.nome}-${target.properties.id_alvo}-points`}
            source={type === "discharges"? `${target.nome}-${target.id_alvo}` : `${target.properties.nome}-${target.properties.id_alvo}`}
            type="circle"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
              'circle-color': '#808080',
              'circle-radius': {
                stops: [[5,2],[12,10], [16,30]]
              },
            }}
          />
        </Source>
      )
    }
  </>
)}

TargetComponent.propTypes = {
  target: P.any.isRequired,
  targetState: P.any,
  onTime: P.bool.isRequired,
  type: P.any,
  index: P.any
}

export const Target = memo(TargetComponent);
