import React, { useContext, useState } from 'react';
import { ToolsContext } from 'Context/ToolsContext';
import IconButton from '@material-ui/core/IconButton';
import TooltipComponent from 'components/Modal/Tooltip.js';
import { useTarget } from 'Context/TargetContext';
import { Divider } from '@material-ui/core';

import PropTypes from 'prop-types';
import {
    faDrawPolygon,
    faEraser,
    faHandPaper,
    faRulerHorizontal,
    faSatellite,
    faSatelliteDish,
    faBoltLightning,
    faBullseye,
    faRad
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    ExtraOptions,
    ExtraOptionsButton,
    Icon,
    MenuLineWrapper,
    HorizontalMenuLineWrapper,
    HorizontalExtraOptions,
    RightMenu,
} from './styled.js';
import {useDischarges} from 'Context/DischargesContext.js';
import {useSatellite} from 'Context/SatelliteContext.js';
import {useRadar} from 'Context/RadarContext.js';
import MaterialIcon from '@material-ui/core/Icon';
import {useMapContext} from 'Context/MapContext.js';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  divider: {
    background: "#000",
    width: "80%",
    height: "1px",
  },
}));

const OptionsTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <TooltipComponent
      enterDelay={1000}
      leaveDelay={0}
      onClose={() => handleTooltipClose()}
      onOpen={() => handleTooltipOpen()}
      open={open}
      placement="top-end"
      title={props.title}
    >
      {props.children}
      </TooltipComponent>
    )
}
const RightMapMenu = () => {
    const { showSatellite, loadingSatellite, toggleSatellite } = useSatellite();
    const {
      toolInUse,
      setToolInUse,
      setMeasureLine,
      setPointHelper,
    } = useContext(ToolsContext);
    const {
      descargas,
      showDischarges,
      loadingDescargas,
      toggleDischarges,
      dischargesToShow,
      toggleDischargeToShow,
      dischargesTypeToShow,
      toggleDischargeTypeToShow,
    } = useDischarges();

    const styles = useStyles();
    const { resetMapPosition } = useMapContext();
    const { showTargets, toggleTargets, mergeTargetsOption, toggleTargetMerge } = useTarget();
    const { showRadar, loadingRadar, toggleRadar, radarInUse, setRadarInUse } = useRadar();


    return (
        <RightMenu>
          <OptionsTooltip title='Ferramenta de interação'>
            <Button
                onClick={() => {
                    setToolInUse('default');
                }}
                selected={toolInUse === 'default'}
            >
                <Icon icon={faHandPaper}  />
            </Button>
          </OptionsTooltip>
          <MenuLineWrapper>
            <OptionsTooltip title={showTargets ? 'Esconder alvos' : 'Mostrar alvos'}>
              <Button onClick={toggleTargets} selected={showTargets}>
                  <Icon icon={faBullseye} />
              </Button>
            </OptionsTooltip>
            <ExtraOptions>
              <ExtraOptionsButton
                selected={mergeTargetsOption}
                onClick={toggleTargetMerge}
              >
                <svg style={{width: '24px', height: '24px', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M640 640v170.666667a85.333333 85.333333 0 0 1-85.333333 85.333333H213.333333a85.333333 85.333333 0 0 1-85.333333-85.333333v-341.333334a85.333333 85.333333 0 0 1 85.333333-85.333333h170.666667V213.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h341.333334a85.333333 85.333333 0 0 1 85.333333 85.333333v341.333334a85.333333 85.333333 0 0 1-85.333333 85.333333h-170.666667z m-85.333333-85.333333h256V213.333333h-341.333334v256H213.333333v341.333334h341.333334v-256z" /></svg>
              </ExtraOptionsButton>
            </ExtraOptions>
          </MenuLineWrapper>
          <OptionsTooltip title={'Ferramenta de seleção'}>
            <Button
                onClick={() => {
                    setToolInUse('selection');
                }}
                selected={toolInUse === 'selection'}
            >
                <Icon
                    icon={faDrawPolygon}
                />
            </Button>
          </OptionsTooltip>
          <OptionsTooltip title={'Ferramenta de medida'}>
            <Button
                onClick={() => {
                    setToolInUse('measure');
                }}
                selected={toolInUse === 'measure'}
            >
                <Icon
                    icon={faRulerHorizontal}
                />
            </Button>
          </OptionsTooltip>
          <OptionsTooltip title={'Apagar medida no mapa'}>
            <Button
                onClick={() => {
                    setPointHelper([]);
                    setMeasureLine([]);
                }}
            >
                <Icon icon={faEraser} hovercolor="#F00" />
            </Button>
          </OptionsTooltip>
        </RightMenu>
    );
};


OptionsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

export default RightMapMenu;
