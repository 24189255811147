/* Libraries */
import PropTypes, { element } from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import Edit_icon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import "./modalReports.css"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StyledTableRow,
  StyledCellView,
  StyledNoBorderCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
  StyledTableRowLightBorder,
  StyledTableRowNoBorder,
  StyledTableRowVeryLightBorder,
} from 'containers/Pages/Table/Table-jss';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';

/* Components */
import { useToast } from 'Context/ToastContext';
import GeneralConsol  from "../../components/Modal/GeneralConsol"
import EspecificConsol  from "../../components/Modal/EspecificConsol"

/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft, FaFileExcel } from 'react-icons/fa';
import { FaFilePdf } from "react-icons/fa";
import { SiMicrosoftexcel } from 'react-icons/si';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import { getAllUsersNucleo } from 'services/mapsPortal/alerts';
import InternalConsol from './InternalConsol';
import ModalGraphics from './ModalGraphics';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));


const ModalReports = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo, clientUser} = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [usersR, setUsersR] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [multipleChecksInit, setMultipleChecksInit] = useState([]);
  const [multiplePeriods, setMultiplePeriods] = useState(false);
  const [periodAllEspecific, setPeriodAllEspecific] = useState([]);
  const [targets, setTargets] = useState(tableData.targets[0]!=null? tableData.targets.map(
    (option) => option.nome
  ) : "");
  const [targetsGeneral, setTargetsGeneral] = useState(tableData.targets[0]!=null? tableData.targets.map(
    (option) => option.nome
  ) : "");

  const [allTargets, setAllTargets] = useState(targets);
  const [initDate, setInitialDate] = useState(String(new Date(tableData.contract_init)));
  const [initDateGeneral, setInitialDateGeneral] = useState(tableData.contract_init);
  const [initialDateSpecific, setInitialDateSpecific] = useState([]);
  const [initialDateGeneralGraphics, setInitialDateGeneralGraphics] = useState([]);
  const [initialMonitoringDate, setInitialMonitoringDate] = useState(false);
  const [initialMonitoringGeneralGraphics, setInitialMonitoringGeneralGraphics] = useState(false);
  const [finalDateSpecific, setFinalDateSpecific] = useState([]);
  const [finalDateGeneralGraphics, setFinalDateGeneralGraphics] = useState([]);
  const [finalDate, setFinalDate] = useState(String(new Date(tableData.contract_fin)));
  const [finalDateGeneral, setFinalDateGeneral] = useState(tableData.contract_fin);
  const [responsible, setResponsible] = useState("Herbert Richers");
  const [checkBoxGeneral, setCheckBoxGeneral] = useState(false);
  const [periodInternalGeneralGraphics, setPeriodInternalGeneralGraphics] = useState(false);
  const [specificChecks, setSpecificChecks] = useState([]);
  const [generalGraphicsTime, setGeneralGraphicsTime] = useState([]);
  const [graphicsSpecific, setGraphicsSpecific] = useState([]);
  const [generalGraphics, setGeneralGraphics] = useState([]);
  const [periodInternal, setPeriodInternal] = useState([]);
  const [graphicsGeneral, setGraphicsGeneral] = useState([]);
  const [networkTargets, setNetworkTargets] = useState([]);
  const [networkTargetsGeneral, setNetworkTargetsGeneral] = useState([]);
  const [graphicOptions, setGraphicOptions] = useState(['Histórico dos Alertas', 'Histórico da Duração', 'Histórico da Variação Diurna dos Alertas', 'Acumulado Mensal dos Alertas', 'Acumulado Mensal das Descargas Atmosféricas', 'Histórico da Variação Diurna das Descargas Atmosféricas'])
  const [optionsNetwork, setOptionsNetwork] = useState(['Por alvo', 'Rede satélite', 'Rede solo', 'Todos'])
  const [networksAllGeneralGraphics, setNetworksAllGeneralGraphics] = useState([]);
  const [networkGeneralGraphics, setNetworkGeneral] = useState(optionsNetwork[0]);
  const [targetsSpecific, setTargetsSpecific] = useState([]);
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();
  var aux = 0;

  const [initialMonitoringInternal, setInitialMonitoringInternal] = useState([])

  const [reloadGraphic, setReloadGraphic] = useState(false);
  const [graphicsOpen, setGraphicsOpen] = useState(null);
  const [modalData, setModalData] = useState([""]);


  const handleInitialMonitoringInternal = (target) =>{
    let aux = initialMonitoringInternal
    aux[target] = !aux[target]
    setInitialMonitoringInternal(aux)
    setReload(true)
  }

  const handlePeriodAllEspecific = (targetValue, date_type, date) =>{
    let aux = periodAllEspecific
    
    if(date_type=="final"){
      aux[targetValue] = {initial: periodAllEspecific[targetValue].initial, final: date}
    }else{
      aux[targetValue] = {final: periodAllEspecific[targetValue].final, initial: date}
    }
    
    setPeriodAllEspecific(aux)
    setReload(true)
  }


  const handlePeriod = (target) =>{
    let aux = periodInternal
    let aux2 = periodAllEspecific
    aux[target] = !aux[target]

    aux2[target] = {init: moment().format("YYYY-MM-DD").toString(), final: moment().format("YYYY-MM-DD").toString()}

    setPeriodAllEspecific(aux2)
    setPeriodInternal(aux)
    setReload(true)
    
  }
  

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick"){
      return;
    }
    setOpen(null);
    setReloadMod(true);
  }
  console.log(tableData);
  const openGraphics = (event, element) => {
    if(graphicsSpecific.length<1 && graphicsGeneral.length<1){
      openToast("error", "Selecione ao menos um tipo de Alvo com gráfico!");
    }else{
      setGraphicsOpen(event.currentTarget);
      setModalData({initDate: initDate,
         finalDate: finalDate,
         idEmpresa: tableData.id_empresa, 
         alvosEspecificos: targetsSpecific, 
         alvosGerais: targetsGeneral, 
         graficosEspecificos: graphicsSpecific, 
         graficosGerais: graphicsGeneral,
         datasGraficosEspecificos: {finalDate: finalDateSpecific, initialDate: initialDateSpecific},
         datasGraficosGerais: {finalDate: finalDateGeneralGraphics, initialDate: initialDateGeneralGraphics},
         inicioMonitEspecificos: specificChecks,
         inicioMonitGeral: checkBoxGeneral,
         inicioMonitGeralAlvosEspecificos: initialMonitoringInternal,
         inicioMonitGeralAlvosGeral: initialMonitoringDate,
         inicioMonitGeralGraficos: initialMonitoringGeneralGraphics,
         inicioMonitGraficosGeraisEspecificos: generalGraphicsTime,
         inicioMonitTabela: tableData.contract_init,
         periodoAlvo: periodInternal,
         periodoGeralAlvos: multiplePeriods,
         periodoGraficosGerais: periodInternalGeneralGraphics,
      });
    }
    
  }

  useEffect(() => {
    if(reloadGraphic){
      setReloadGraphic(false);
      setGraphicsOpen(null);
    }
  }, [reloadGraphic])


  const checkSpecificCheck = (specificheck) => {
    const finder = multipleChecksInit.find((filteredButton) => filteredButton === specificheck)
    if(finder != undefined){
        const auxVar = multipleChecksInit.filter((element)=>{
            return element != finder
        })
        setMultipleChecksInit(auxVar)
    }else{
      const auxVar = multipleChecksInit.filter((element)=>{
        return element
      })
      auxVar.push(specificheck)
      setMultipleChecksInit(auxVar)
    }
  };
  console.log(targetsSpecific);
  const manipulateGraphicsSpecific = (graphicData) => {
    let finder2 = false
    let valAct = []
    if(graphicData.split("#")[1]!= undefined && graphicData.split("#")[1]!=null && graphicData.split("#")[1]!= ""){
      graphicsSpecific.filter((actualValue)=>{
        finder2 = actualValue.includes(graphicData.split("#")[0])
        if(finder2==false){
          valAct.push(actualValue)
        }

      })
      
      if(valAct.length >= 0 && valAct[0]!=null){
        valAct.push(graphicData)
        setGraphicsSpecific(valAct)
      }else if(finder2 == false){
        valAct = graphicsSpecific
        valAct.push(graphicData)
        
      }else{
        valAct.push(graphicData)
      }



    }else{
      graphicsSpecific.filter((actualValue)=>{
        finder2 = actualValue.includes(graphicData.split("#")[0])
        if(finder2==false){
          valAct.push(actualValue)
        }

      })

    }
     setGraphicsSpecific(valAct)
     setReload(true)
     
  };

  const manipulateGraphicsGeneral = (graphicData) => {
    
    if(graphicData.length>graphicsGeneral.length){
      setGraphicsGeneral(graphicData)
      setReload(true)
    }else{
      let aux1 = []
      let aux2 = []
      let dif = graphicData.filter(x => !graphicsGeneral.includes(x)).concat(graphicsGeneral.filter(x=> !graphicData.includes(x)))
        if(dif!=null){
          initialDateGeneralGraphics.map((element1)=>{
            if(!element1.includes(dif)){
              aux1.push(element1)
            }
          })
    
          finalDateGeneralGraphics.map((element2)=>{
            if(!element2.includes(dif)){
              aux2.push(element2)
            }
          })
        }

      setInitialDateGeneralGraphics(aux1)
      setFinalDateGeneralGraphics(aux2)
      setGraphicsGeneral(graphicData)
      setReload(true)
    }
     
  };

  const manipulateGraphicsTargets = (targetData) => {
    if(targetData.length>targetsSpecific.length){
      let aux = []
      aux = targetData
      setTargetsSpecific(aux)
      addNetworkTarget(targetData[targetData.length-1]+"#"+optionsNetwork[optionsNetwork.length-1])
      initialMonitoringInternal[targetData[targetData.length-1]] = false
      periodInternal[targetData[targetData.length-1]] = false
      setReload(true)
    }else{
      let aux1 = targetData
      let aux2 = []
      let aux3 = []
      let dif = targetData.filter(x => !targetsSpecific.includes(x)).concat(targetsSpecific.filter(x=> !targetData.includes(x)))
      if(dif!=null){
        graphicsSpecific.map((element)=> {
          if(element.split("#")[0]!=dif){
            aux2.push(element)
          }
        })
        networkTargets.map((element)=> {
          if(element.split("#")[0]!=dif){
            aux3.push(element)
          }
        })
      }

      setTargetsSpecific(aux1)
      setGraphicsSpecific(aux2)
      setNetworkTargets(aux3)
      setReload(true)
    }
  };

  const manipulateTargetsSpecific = (targetData) => {
    let aux = []
    let val = targetData.split("#")
    
    graphicsSpecific.map((element)=> {
      if(element.split("#")[0]!=val[0]){
        aux.push(element)
      }
    })
    if(val[1]!=""){
      aux.push(targetData)
    }
    setGraphicsSpecific(aux)
    setReload(true)
     
  };


  const checkAllBoxesInit = (element) => {
    const finder = multipleChecksInit.find((filteredButton) => filteredButton === element)
    let aux = ""
    if(finder!= undefined && finder != ""){
      aux = multipleChecksInit.filter((values)=>{
        return values != finder
      })
    }else{
      aux = multipleChecksInit.filter((values)=>{
        if(!values.includes(element.split("#")[1])){
          return(values)
        }
      })
      aux.push(element)
    }
    setMultipleChecksInit(aux);
    setReload(true);
      
  };

  const checkAllPeriodsBoxesInit = () => {
    setMultiplePeriods(!multiplePeriods);
    setReload(true);
  };

  const getGraphics = (element) => {
    return "Val"
  };
  

  const invertDate = (date) =>{ 
    var aux = date.split('-').reverse()
    return aux[0]+"/"+aux[1]+"/"+aux[2]
  };

  
  const handleInitialDate = (date) => {
    let aux = new Date(date)
    setInitialDate(aux);
  }

  const handleFinalDate = (date) => {
    let aux = new Date(date)
    setFinalDate(aux);
  }

  const handleInitialDateSpecific = (value) => {
    let aux = []
    let remove = false;
    let splitted = value.split("#")
    
    initialDateSpecific.map((element) => {
      if(!element.includes(splitted[0]+"#"+splitted[1])){
        aux.push(element)
      }else{
        remove = true
      }
    })

    if(remove == false){
      aux.push(value)
    }else{
      aux.push(value)
    }
    
    setInitialDateSpecific(aux);
    setReload(true)
  };

  const handleInitialDateGeneralGraphics =  (value) => {
    let aux = []
    let splitted = value.split("#")
    
    initialDateGeneralGraphics.map((element) => {
      if(element.split("#")[0] != splitted[0]){
        aux.push(element)
      }
    })
    aux.push(value)
    setInitialDateGeneralGraphics(aux);
  };

  const handleFinalDateSpecific = (value) => {
    let aux = []
    let remove = false;
    let splitted = value.split("#")

    finalDateSpecific.map((element) => {
      if(!element.includes(splitted[0]+"#"+splitted[1])){
        aux.push(element)
      }else{
        remove = true
      }
    })

    if(remove == false){
      aux.push(value)
    }else{
      aux.push(value)
    }

    setFinalDateSpecific(aux);
    setReload(true)
  };


  const handleFinalDateGeneralGraphics = (value) => {
    let aux = []
    let splitted = value.split("#")

    finalDateGeneralGraphics.map((element) => {
      if(element.split("#")[0] != splitted[0]){
        aux.push(element)
      }
    })

    aux.push(value)
    setFinalDateGeneralGraphics(aux);
    setReload(true);
  };

  const changeGeneralTargets = (target) => {
    let aux1 = []
    let aux2 = []
    console.log(target)
    if(target==""){
      
      setTargetsSpecific([]);
      setGraphicsSpecific([]);
      
    }else{
      if(targets!=""){
        target.map((element) => {

          targetsSpecific.map((val1)=>{
            console.log(val1);
            if(!val1.includes(element)){
              aux1.push(element);
            }
              
          })

          graphicsSpecific.map((val2)=>{
            if(!val2.includes(element)){
              aux2.push(val2);
            }

          })

        })
    
        setTargetsSpecific(aux1);
        setGraphicsSpecific(aux2);
      }
      
    }

    setTargets(target);
    setReload(true);
  };

  const getInitialDateSpecific = (value) => {
    let aux = null

    initialDateSpecific.map((element) => {
      if(element.includes(value)){
        aux = element.split("#")[2]
      }
    })

    if(aux == null){
      aux = new Date()
      handleInitialDateSpecific(value+"#"+aux)
    }
    
    return(aux)
  };


  const getInitialDateGeneralGraphics = (value) => {
    let aux = null

    initialDateGeneralGraphics.map((element) => {
      if(element.includes(value)){
        aux = element.split("#")[1]
      }
      
    })

    if(aux == null){
      aux = new Date
      handleInitialDateGeneralGraphics(value+"#"+aux)
    }


    return(aux)
  };

  const getFinalDateSpecific = (value) => {
    let aux = null
    finalDateSpecific.map((element) => {
      if(element.includes(value)){
        aux = element.split("#")[2]
      }
    })

    if(aux == null){
      aux = new Date
      aux.setDate(aux.getDate() + 7)
      handleFinalDateSpecific(value+"#"+aux)
    }

    return(aux)
  };

  const getFinalDateGeneralGraphics = (value) => {
    let aux = null
    console.log(value)
    finalDateGeneralGraphics.map((element) => {
      if(element.includes(value)){
        aux = element.split("#")[1]
      }
    })

    if(aux == null){
      aux = new Date
      aux.setDate(aux.getDate() + 7)
      handleFinalDateGeneralGraphics(value+"#"+aux)
    }

    return(aux)
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  const handleAllSelected = () => {
    if(!allSelected){
      let aux = [];
      tableData.map((cam, index) => aux.push(index));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
    else{
      let aux = [];
      tableData.map((cam1, index1) => selected.some((id) => index1 === id)? aux : aux.push(index1));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
  }

  const toggleGeneralCheckBox = (e) => {
    if(!checkBoxGeneral==true){
      setInitialDate(new Date('1920-12-25'))
      setFinalDate(new Date())
    }else{
      let aux = new Date()
      let aux2 = new Date()
      aux2.setDate(aux.getDate()+7)
      setInitialDate(aux)
      setFinalDate(aux2)
    }
    setCheckBoxGeneral(!checkBoxGeneral);
  };

  const addNetworkGeneral = (element) => {
    let aux = []
    if(networkGeneralGraphics.includes(element)){
      aux = networkGeneralGraphics.filter((val)=>{
        return val != element
        
      });
    }else{
      aux = networkGeneralGraphics
      aux.push(element)
    }

    setNetworksAllGeneralGraphics(aux)
    setReload(true)

  };

  const addGraphicsTime = (element) => {
    let aux = []
    if(generalGraphicsTime.includes(element)){
      aux = generalGraphicsTime.filter((val)=>{
        return val != element
        
      });
    }else{
      aux = generalGraphicsTime
      aux.push(element)
    }

    setGeneralGraphicsTime(aux)
    setReload(true)

  };

  const getNetworkGeneral = (element) =>{
    let aux = null
      networksAllGeneralGraphics.map((value) =>{
        if(value.includes(element)){
          aux = value
        }
      })

      if(aux == null){
        aux = optionsNetwork[0]
      }
    return aux
  };

  const addNetworkTarget = (element) => {
    console.log(element)
    let aux = []
    networkTargets.map((value) =>{
      if(value.split("#")[0] == element.split("#")[0]){
        aux = networkTargets.filter((val)=>{
          return val != value
        });
      }
    })
    if(aux[0]==null || aux[0]==""){
      if(networkTargets.length<1){
        aux.push(element)
      }else{
        aux = networkTargets
        aux.push(element)
      }
      
    }
    setNetworkTargets(aux)

  };

  const getNetworkTarget = (element) => {
    let aux = null
      networkTargets.map((value) =>{
        if(value.includes(element)){
          aux = value.split("#")[1]
        }
      })
    return aux
  };

  const addSpecificCheck = (element) => {
    let aux = [];
    const finder = specificChecks.find((filteredButton) => filteredButton == element);
    if(finder === undefined || finder == ""){
      aux = specificChecks;
      aux.push(element);
    }else{
      aux = specificChecks.filter((val)=>{
        return val != finder
        
      });
    }
    setSpecificChecks(aux);
    setReload(true);
  };

  const splitMod = (modif) => {
    var aux = "";
    modif? modif.map((value) => aux+= value+" ") : ""
    return aux

  }
  const handleClickCheckbox = (index) => {
    let aux = selected;
    if(selected.indexOf(index) !== -1){
      aux = aux?.filter((id) => id != index);
      setSelected(aux);
      setAllSelected(false);
    }
    else{
      aux.push(index);
      setSelected(aux);
      if(selected.length === tableData.length){
        setAllSelected(true);
      }
    }
    setReload(true);
  }

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);


  const loadUsersR = async () => {
    var aux = [];
        await getAllUsersNucleo().then((response) => {
          if(response){
            if(Array.isArray(response)){
              response?.map(users=>{
                aux.push(users.first_name? users.first_name+" "+users.last_name : "");
              })
              setResponsible(aux[0])
            }else{
              aux.push(response.first_name? response.first_name+" "+response.last_name : "");
              setResponsible(aux[0])
            }
          }
          else{
            openToast("error", "Problema ao carregar os Usuários, recarregue a página e tente novamente!");
            setLoadingUsers(false);
          }
        })
        .catch(() => {
          openToast("error", "Problema ao carregar os Usuários, recarregue a página e tente novamente!");
          setLoadingUsers(false);
        })
      
      setUsersR(aux);
      setLoadingUsers(false);

  };

  useEffect(() => {
    loadUsersR();
  }, []);

  return(
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorReference="anchorPosition"
      onClose={handleClose}
      anchorPosition={{ top: 100, left: windowSize[0]/2}}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    > 
    
    <Table style={{margin:"0px"}}>
      <TableHead>
        <div style={{width: "100%", height:"100%", padding: "2px 8px 2px 8px", boxShadow: "0 8px 8px -4px DarkGrey"}} >
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{color:"#787878", paddingTop:"10px", paddingLeft:"15px", fontSize:"20px"}}>
              <h2>{tableData && tableData.plan_name? tableData.plan_name : ""}</h2>
            </div>
            <div style={{paddingTop:"10px", paddingLeft:"15px", fontSize:"20px", color:"#787878", justifyContent: "flex-end"}}>
              <h2>{tableData && tableData.contract_init? "Inicio do Monitoramento: "+invertDate(tableData.contract_init) : ""}</h2>
            </div>
          </div>
        </div>

      </TableHead>
      <TableBody>
        {tableData && tableData.targets.length > 0 && tableData.targets[0]!=null ? 
          <StyledTableRow>
            <StyledCellView padding="normal" style={fonts.cellContent}>
              <GeneralConsol clientUser={clientUser!=undefined&&clientUser!=null? clientUser : false} targets={targets} changeGeneralTargets={changeGeneralTargets} allTargets={allTargets} checkBoxGeneral={checkBoxGeneral} initDate={initDate} handleInitialDate={handleInitialDate} finalDate={finalDate} handleFinalDate={handleFinalDate} toggleGeneralCheckBox={toggleGeneralCheckBox} responsible={responsible} setResponsible={setResponsible} usersR={usersR}/>
            </StyledCellView>
          </StyledTableRow>
        : ""}
        {tableData && tableData.targets.length > 0 && tableData.targets[0]!=null ? 
          <div>
              <div style={{marginLeft:"30px"}}>
                <StyledTableRowNoBorder>
                  <EspecificConsol data={targetsSpecific} setNetworkTargetsGeneral={setNetworkTargetsGeneral} networkTargetsGeneral={networkTargetsGeneral} manipulateGraphicsTargets={manipulateGraphicsTargets} initialMonitoringDate={initialMonitoringDate} handleInitialMonitoring={setInitialMonitoringDate} allTargets={allTargets} getNetworkTarget={getNetworkTarget} addNetworkTarget={addNetworkTarget} optionsNetwork={optionsNetwork} multiplePeriods={multiplePeriods} checkAllPeriodsBoxesInit={checkAllPeriodsBoxesInit} multipleChecksInit={multipleChecksInit} checkAllBoxesInit={checkAllBoxesInit} />
                </StyledTableRowNoBorder>
              </div>
              <div style={{marginTop:"5px", marginLeft:"90px"}}>
                {targetsSpecific!=null && targetsSpecific.map((targetValue, index) => {
                  return(
                    <div key={index}>
                    <StyledTableRowNoBorder>
                        <InternalConsol data={targetsSpecific} addNetworkTarget={addNetworkTarget} handlePeriodAllEspecific={handlePeriodAllEspecific} periodAllEspecific={periodAllEspecific} initialMonitoringInternal={initialMonitoringInternal} periodInternal={periodInternal} handlePeriod={handlePeriod} handleInitialMonitoringInternal={handleInitialMonitoringInternal} getNetworkTarget={getNetworkTarget} setNetworkTargetsGeneral={setNetworkTargetsGeneral} initialMonitoringDate={initialMonitoringDate} networkTargetsGeneral={networkTargetsGeneral} manipulateGraphicsSpecific={manipulateGraphicsSpecific} manipulateTargetsSpecific={manipulateTargetsSpecific} checkBoxGeneral={checkBoxGeneral} targetValue={targetValue} targetsSpecific={targetsSpecific} multipleChecksInit={multipleChecksInit} graphicsSpecific={graphicsSpecific}  multiplePeriods={multiplePeriods} responsible={responsible} setResponsible={setResponsible} graphicOptions={graphicOptions} addSpecificCheck={addSpecificCheck} specificChecks={specificChecks} handleInitialDateSpecific={handleInitialDateSpecific} handleFinalDateSpecific={handleFinalDateSpecific} getInitialDateSpecific={getInitialDateSpecific} getFinalDateSpecific={getFinalDateSpecific}/>
                    </StyledTableRowNoBorder>
                    </div>
                  )
                  
                })}
              </div>
          </div>
          :
          <div style={{textAlign:"center", marginTop:"5dvh", marginBottom:"5dvh"}}>
            <span style={{fontSize:"40px"}}>Sem dados para consolidação!</span>
          </div>
        }
      {tableData && tableData.targets.length > 0 && tableData.targets[0]!=null ? 
        <div>
          <hr style={{borderTop: "2px solid black"}}></hr>
          <div style={{marginLeft:"30px", marginTop:"-30px"}}>
            <StyledTableRow>
              <StyledCellView padding="normal" style={fonts.cellContent}>
                <div style={{fontWeight: "normal", marginBottom:"10px"}}>
                  <label htmlFor="selectTargets2">Gráficos Gerais</label>
                </div>
                <Autocomplete
                  id={"graphics_"+data.nome_+"_general"}
                  multiple={true}
                  disableClearable
                  limitTags={1}
                  value={graphicsGeneral}
                  onChange={(event, newValue) => {manipulateGraphicsGeneral(newValue)}}
                  className={classes.selectTargetsBox}
                  style={fonts.formsFieldHeight1}
                  size="small"
                  options={graphicOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gráficos"
                      id={"selectGraphicGeneral_"+data.nome}
                      variant="outlined"
                      className={classes.textFieldNormal}
                      style={{ marginBottom: '0px' }}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onKeyDown={(event) => {
                          if (
                              event.key ===
                              'Backspace'
                          ) {
                              event.stopPropagation();
                          }
                      }}
                    />
                  )}
                />
              </StyledCellView>
              <StyledCellView padding="normal" style={fonts.cellContent}>
                {!initialMonitoringGeneralGraphics &&
                  <FormControlLabel
                      control={(
                        <Checkbox
                          checked={periodInternalGeneralGraphics}
                          onChange={()=>setPeriodInternalGeneralGraphics(!periodInternalGeneralGraphics)}
                          value={"samePeriodGeneralGraphics_"+periodInternalGeneralGraphics}
                          color="primary"
                        /> )}
                        label="Gerar gráficos para o mesmo período"
                        style={fonts.formsField}
                        className={classes.checkboxModalDown}
                  />
                }
              </StyledCellView>
              <StyledCellView padding="normal" style={fonts.cellContent}>
                {!periodInternalGeneralGraphics &&
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={initialMonitoringGeneralGraphics}
                        onChange={()=>setInitialMonitoringGeneralGraphics(!initialMonitoringGeneralGraphics)}
                        value={"InitialMonitoringGeneralGraphics_"+initialMonitoringGeneralGraphics}
                        color="primary"
                      /> )}
                      label="Gerar desde o início do monitoramento"
                      style={fonts.formsField}
                      className={classes.checkboxModalDown}
                  />
                }
              
              </StyledCellView>
              <StyledCellView>
              </StyledCellView>
              <StyledCellView>
              </StyledCellView>
              <StyledCellView>
              </StyledCellView>
            </StyledTableRow>

              {graphicsGeneral.map((option, index) => {
                    return(
                      <div key={index}>
                        <div style={{marginTop:"20px", marginLeft:"90px"}}>{option}</div>
                        <div style={{marginLeft:"60px"}}>
                          <StyledTableRowNoBorder>
                            <StyledNoBorderCellView>
                              {!generalGraphicsTime.includes(option) && !periodInternalGeneralGraphics && !initialMonitoringGeneralGraphics?
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                  <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    style={{marginLeft:"30px", marginBottom:"15px"}}
                                    animateYearScrolling={false}
                                    value={getInitialDateGeneralGraphics(option)}
                                    label="Data Inicial"
                                    onChange={(date) => handleInitialDateGeneralGraphics(option+"#"+date)}
                                    className={classes.dateField}
                                    inputProps={{style: fonts.formsField}}
                                    InputLabelProps={{style: fonts.formsLabel}} 
                                  />
                                </MuiPickersUtilsProvider>
                              :
                              ""
                              }
                              {!generalGraphicsTime.includes(option) && !periodInternalGeneralGraphics  && !initialMonitoringGeneralGraphics?
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                <KeyboardDatePicker
                                  format="dd/MM/yyyy"
                                  style={{marginLeft:"20px", marginBottom:"15px"}}
                                  animateYearScrolling={false}
                                  value={getFinalDateGeneralGraphics(option)}
                                  label="Data Final"
                                  onChange={(date) => handleFinalDateGeneralGraphics(option+"#"+date)}
                                  className={classes.dateField}
                                  inputProps={{style: fonts.formsField}}
                                  InputLabelProps={{style: fonts.formsLabel}} 
                                />
                              </MuiPickersUtilsProvider>
                              :
                              ""
                              }
                              </StyledNoBorderCellView>
                              <StyledNoBorderCellView>
                              {!periodInternalGeneralGraphics  && !initialMonitoringGeneralGraphics?
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      value={"GraphicsInitMonitoring"+option}
                                      checked={generalGraphicsTime.includes(option)}
                                      onChange={() => {addGraphicsTime(option)}}
                                      color="primary"
                                    /> )}
                                    label="Gerar desde o início do monitoramento"
                                    style={fonts.formsField}
                                    className={classes.checkboxModalDown2}
                                />
                                :
                                ""
                              }
                              </StyledNoBorderCellView>
                          </StyledTableRowNoBorder>
                        </div>
                      </div>
                      )
                    })
                  }
          </div>
        </div>
        

      :""}
      </TableBody>
    </Table>
    {graphicsOpen != null && <ModalGraphics anchorEl={graphicsOpen} openGraphics={Boolean(graphicsOpen)} setOpenGraphics={setGraphicsOpen} setAnchorEl={setGraphicsOpen} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadMod} data={modalData} putData={modalData} idAlvo={1} />}
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
      <div style={{width: "95%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px"}}>
        <div className={classes.noBorderButton} onClick={() => handleClose()}>
          <ArrowLeft color='black'></ArrowLeft>
        </div>
        {tableData && tableData.targets.length > 0 && tableData.targets[0]!=null ? 
          <div style={{display:"flex", marginLeft: "auto"}}>
            {/*<div  className={classes.outlineButton2} onClick={() => handleClose()}>
              <span span style={fonts.mapSidebarCamtDescripitionItem}><FaFileExcel></FaFileExcel></span>
            </div>
            <div style={{marginLeft:"20px"}} className={classes.outlineButton2} onClick={() => handleClose()}>
              <span span style={fonts.mapSidebarCamtDescripitionItem}><FaFilePdf></FaFilePdf></span>
            </div>
            */}
            <div style={{marginLeft:"20px", padding:"5px 20px 5px 20px"}} className={classes.outlineButton3} onClick={(e) => openGraphics(e)}>
              <span span style={fonts.mapSidebarCamtDescripitionItem}>EXPORTAR</span>
            </div>
            {!clientUser?
              <div style={{marginLeft:"20px",padding:"5px 20px 5px 20px", marginRight:"20px"}} className={classes.outlineButton3} onClick={() => handleClose()}>
                <span span style={fonts.mapSidebarCamtDescripitionItem}>ENVIAR</span>
              </div>
              :""
            }
          </div>
        :""}
      </div>
    </div>
    </Popover>
  )
} 

ModalReports.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.object,
  clientUser: PropTypes.bool,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalReports);