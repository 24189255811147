
/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* CSS */
import styles from "./tableContent-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material */
import { FaSms, FaTelegram } from 'react-icons/fa';
import { ImMail4 } from 'react-icons/im';
import { IoLogoWhatsapp } from 'react-icons/io';
import Tooltip from "components/Modal/Tooltip"
import TooltipComponent from 'components/Modal/Tooltip.js';

const PermissionsIcons = (props) => {
  const { classes, column } = props;
  const fonts = fontStyles();

  return(
    <>
      {column === "SMS" &&
        <TooltipComponent text={"SMS"} yAxis={"-110%"}>
          <div className={classes.servicesCard} style={{borderColor: "#DF7600"}}><FaSms style={{color: "#DF7600", width: "18px", height: "18px"}} /></div>
        </TooltipComponent>
      }
      {column === "Email" &&
        <TooltipComponent text={"Email"} yAxis={"-110%"}>
          <div className={classes.servicesCard} style={{borderColor: "#DF7600"}}><ImMail4 style={{color: "#DF7600", width: "18px", height: "18px"}} /></div>
        </TooltipComponent>
      }
      {column === "Wpp" &&
        <TooltipComponent text={"WhatsApp"} yAxis={"-110%"}>
          <div className={classes.servicesCard} style={{borderColor: "#DF7600"}}><IoLogoWhatsapp style={{color: "#DF7600", width: "18px", height: "18px"}} /></div>
        </TooltipComponent>
      }
      {column === "Telegram" &&
        <TooltipComponent text={"Telegram"} yAxis={"-110%"}>
          <div className={classes.servicesCard} style={{borderColor: "#DF7600"}}><FaTelegram style={{color: "#DF7600", width: "18px", height: "18px"}} /></div>
        </TooltipComponent>
      }
    </>
  );

}
PermissionsIcons.propTypes = {
    classes: PropTypes.object.isRequired,
    column: PropTypes.string.isRequired
   
  };
  export default withStyles(styles)(PermissionsIcons);