/* Libraries */
import React from 'react';
import axios from 'axios';
/* CSS */
import fontStyles from 'styles/fonts-jss';
import { withStyles } from '@material-ui/core/styles';
/* Material UI */
import { Button, Icon, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';

import PropTypes from 'prop-types';

import { getEmployeesCompanyInfosByEmployeeID } from 'services/adminDashboard/employees';

import { useToast } from 'Context/ToastContext';
import { useEffect } from 'react';
import Loading from 'components/Loading';

import styles from './register-jss';
import ComposedChartComponent from 'components/Charts/ComposedChartComponent';
import deLocale from 'date-fns/locale/pt-BR';
import { useAuth } from 'Context/AuthContext';

import SimpleLineChartComponent from 'components/Charts/SimpleLineChartComponent';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getMeteograms } from 'services/mapsPortal/meteogram';

import html2canvas from 'html2canvas';
import BarChartComponent from 'components/Charts/BarChartComponent';
import moment from 'moment';

import nucleologo from '../../../../../images/nucleologo.svg';

const Meteogram = (props) => {
    const { classes } = props;
    const fonts = fontStyles();
    const Auth = useAuth();

    const [target, setTarget] = useState(null);
    const [targets, setTargets] = useState([]);
    const [companyLogo, setCompanyLogo] = useState();
    const [company, setCompany] = useState();
    const [plans, setPlans] = useState();
    const [plan, setPlan] = useState();

    const [targetError, setTargetError] = useState();

    const [loadingTargets, setLoadingTargets] = useState(true);

    const [showChart, setShowChart] = useState(false);

    const [initialDate, setInitialDate] = useState(moment());
    const [initialDateErrors, setInitialDateErrors] = useState();
    const [finalDate, setFinalDate] = useState();
    const [finalDateErrors, setFinalDateErrors] = useState();

    const [dataInput, setDataInput] = useState();

    const [accumulated, setAccumulated] = useState();
    const [temperature, setTemperature] = useState();
    const [moisture, setMoisture] = useState();
    const [pressure, setPressure] = useState();
    const [wind, setWind] = useState();

    const [foundtargetData, setFoundTargetData] = useState();

    const openToast = useToast();

    useEffect(() => {
        if (!target) {
            setTargetError('Campo obrigatório!');
        } else {
            setTargetError('');
        }
    }, [target]);

    useEffect(() => {
        if (!target) {
            setTargetError('Campo obrigatório!');
        } else {
            setTargetError('');
        }
    }, [target]);

    function sortData(a, b) {
        if (a.nome < b.nome) {
            return -1;
        }
        if (a.nome > b.nome) {
            return 1;
        }
        return 0;
    }

    const foundTarget = targets.find((item) => item.nome === target);

    const currentDate = moment();
    const currentHour = currentDate.hours();

    useEffect(() => {
        if (initialDate.isAfter(currentDate)) {
            setInitialDate(currentDate);
            setFinalDate(null);
            return;
        }

        let dateToUse = moment(initialDate);

        if (dateToUse.isSame(currentDate, 'day') && currentHour < 20) {
            dateToUse.subtract(1, 'day');
        }

        const formattedDate = dateToUse.format('YYYYMMDD');
        setFinalDate(formattedDate);
    }, [initialDate]);

    useEffect(() => {
        if (foundTarget && finalDate) {
            setDataInput({
                id_alvo: foundTarget.id_alvo,
                data: finalDate,
            });
        }
    }, [finalDate, foundTarget]);
    const handleChangeTarget = (value, newValue) => {
        setTarget(newValue);
        setPlan(plans[targets.map((data, index) => {if(data.nome === target) return index}).filter((data) => data != undefined)[0]])
    }

    const userAuthenticated = async () => {
        const idEmployee = Auth.user.id_usuario_empresa;
        let targetList = [];
        let planList = [];

        await getEmployeesCompanyInfosByEmployeeID(idEmployee).then((response) => {
            if(response && response[0] && response[0][0] && response[0][0][0] && response[0][0][0].info?.length > 0){
                for(let i = 0; i < response[0][0][0].info.length; i++){
                    if(response[0][0][0].info[i].alvos && response[0][0][0].info[i].alvos.length > 0){
                        for(let j = 0; j < response[0][0][0].info[i].alvos.length; j++){
                            if(response[0][0][0].info[i].alvos[j]){
                                targetList.push(response[0][0][0].info[i].alvos[j]);
                                planList.push(response[0][0][0].info[i].plano.nome)
                            }
                        }
                    }
                    
                }
                setCompanyLogo(response[0][0][0].empresa[0].caminho_imagem);
                setCompany(response[0][0][0].empresa[0].nome);
                setTargets(targetList);
                setPlans(planList);
            }
            setLoadingTargets(false);
        }).catch(() => {
            openToast('error', `Problema ao autenticar usuário! Recarregue a página e tente novamente.`);
            setLoadingTargets(false);
        })  
      };

    useEffect(() => {
        if (loadingTargets) {
            userAuthenticated();
        }
    }, []);

    const handleShowChart = () => {
        setShowChart(true);
        setFoundTargetData(
            foundTarget.medida !== null ? foundTarget.medida : 1,
        );
        const fetchData = async () => {
            if (dataInput) {
                try {
                    const response = await getMeteograms(
                        dataInput.id_alvo,
                        dataInput.data,
                    );
                    if (response) {
                        setPressure(response[0].meteograma_alvo.pressao_ar);
                        setAccumulated(response[0].meteograma_alvo.chuvas);
                        setTemperature(response[0].meteograma_alvo.temperatura);
                        setMoisture(response[0].meteograma_alvo.umidade);
                        setWind(response[0].meteograma_alvo.vento);
                    }
                } catch (error) {
                    console.error('Error fetching meteogram data:', error);
                }
            }
        };

        fetchData();
    };

    const clearCache = async () => {
        await axios.get(companyLogo).then(() => {
            exportChartsAsImage();
        }).catch(() => {
            exportChartsAsImage();
        });
    }

    const exportChartsAsImage = async () => {
        const chartContainer = document.getElementById('chart-container');
        const exportedFigure = document.getElementById('exported-figure');
        const companyName = document.getElementById('companyName');

        exportedFigure.style.display = 'flex';
        companyName.style.display = 'flex';

        const scale = 2;
        const dpi = 300;
        
        if (chartContainer) {
            try {
                const canvas = await html2canvas(chartContainer, {
                    scale,
                    dpi,
                    useCORS: true,
                })
                
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = `${company} ${plan} ${target} ${moment(initialDate).format('DD/MM/YYYY')} `;
                link.click();
            } catch (error) {
                openToast(
                    'error',
                    'Problema ao exportar meteograma! Recarregue a página e tente novamente.',
                );
            }
        }

        exportedFigure.style.display = 'none';
        companyName.style.display = 'none';
    };

    if (loadingTargets) {
        return <Loading />;
    } else {
        return (
            <div>
                <div className={classes.registerContainer}>
                    <Typography
                        variant="subtitle1"
                        color="primary"
                        style={fonts.formsSubTitle}
                    >
                        Selecionar Alvo
                    </Typography>

                    <div className={classes.registerContentContainer}>
                        <div className={classes.dataContainer}>
                            {loadingTargets ? (
                                <CircularProgress
                                    style={{ marginLeft: 'calc(1%)' }}
                                    size={50}
                                    thickness={1}
                                    color="primary"
                                />
                            ) : (
                                <div className={classes.registerItemContainer}>
                                    <Autocomplete
                                        id="target"
                                        value={target}
                                        disableClearable
                                        onChange={(_, newValue) =>
                                            handleChangeTarget(_, newValue)
                                        }
                                        className={
                                            classes.selectFieldExtraLarge
                                        }
                                        style={fonts.formsFieldHeight5}
                                        size="small"
                                        options={targets
                                            .sort(sortData)
                                            .map((option) => option.nome)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecionar alvo"
                                                margin="normal"
                                                style={{
                                                    marginBottom: '-5px',
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    {targetError && (
                                        <p style={fonts.formsErrorMessage}>
                                            {targetError}
                                        </p>
                                    )}
                                </div>
                            )}

                            {loadingTargets? (
                                <CircularProgress
                                    style={{ marginLeft: 'calc(1%)' }}
                                    size={50}
                                    thickness={1}
                                    color="primary"
                                />
                            ) : (
                                <div className={classes.planContainer}>
                                    <div
                                        className={
                                            classes.registerItemContainer
                                        }
                                    >
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            locale={deLocale}
                                        >
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                value={initialDate}
                                                style={{ marginTop: '0px' }}
                                                onChange={(date) =>
                                                    setInitialDate(moment(date))
                                                }
                                                animateYearScrolling={false}
                                                label="Data"
                                                className={classes.dateField}
                                                inputProps={{
                                                    style: fonts.formsField,
                                                }}
                                                InputLabelProps={{
                                                    style: fonts.formsLabel,
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {initialDateErrors && (
                                            <p style={fonts.formsErrorMessage}>
                                                {initialDateErrors}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={classes.showChartButtonContainer}>
                            <Button
                                className={classes.showChartButton}
                                onClick={handleShowChart}
                                disabled={!target || loadingTargets}
                            >
                                Gerar Gráfico
                            </Button>

                            <Button
                                className={classes.showChartButton}
                                onClick={clearCache}
                                disabled={!target || loadingTargets}
                                endIcon={<Icon>download</Icon>}
                            >
                                Exportar
                            </Button>
                        </div>
                    </div>
                </div>
                {showChart && (
                    <div id="chart-container">
                        <div id="exported-figure" style={{ display: 'none' }}>
                            <div className={classes.imageLogo}>
                                <figure>
                                    <img
                                        src={nucleologo}
                                        alt="Núcleo Monitoramento"
                                        style={{ width: '400px' }}
                                    />
                                </figure>
                                
                                <figure>
                                    <img
                                        src={companyLogo + "?not-from-cache-please"}
                                        alt={'Logo' +  company}
                                        style={{ width: '400px' }}
                                    />
                                </figure>
                            </div>
                        </div>

                        <div id="companyName" style={{ display: 'none', justifyContent: "center", alignItems: "center", fontSize: "25px", fontWeight: "bold"}}>
                            <span>{company + " - " + plan + " - " + target + " (" + moment(initialDate).format('DD/MM/YYYY') + ")"}</span>
                        </div>

                        <ComposedChartComponent chartData={accumulated} />
                        <SimpleLineChartComponent
                            chartData={temperature}
                            title={'TEMPERATURA (°C)'}
                            legend={'Temperatura (°C)'}
                            color={'#FF0000'}
                            chartType={'temperature'}
                            dataKey={'C'}
                        />
                        <SimpleLineChartComponent
                            chartData={moisture}
                            title={'UMIDADE RELATIVA (%)'}
                            legend={'Umidade Relativa (%)'}
                            color={'#0509BC'}
                            chartType={'moisture'}
                            dataKey={'%'}
                        />
                        <SimpleLineChartComponent
                            chartData={pressure}
                            title={'PRESSÃO ATMOSFÉRICA (hPa)'}
                            legend={'Pressão Atmosférica (hPa)'}
                            color={'#020202'}
                            chartType={'pressure'}
                            dataKey={'hPa'}
                        />
                        <BarChartComponent
                            chartData={wind}
                            mesuare={foundtargetData}
                            type="médio"
                        />
                        <BarChartComponent
                            chartData={wind}
                            mesuare={foundtargetData}
                            type="máximo"
                        />
                    </div>
                )}
            </div>
        );
    }
};

Meteogram.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Meteogram);
