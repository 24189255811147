import React, { memo } from 'react';
import ReactMapGl, { Layer, Source } from 'react-map-gl';
import { useDischarges } from 'Context/DischargesContext';
import { useSelector } from 'react-redux';
import {useTools} from 'Context/ToolsContext';

function sortNumbers(a, b) {
    return b.properties.timeDiff - a.properties.timeDiff;
}

const DischargesLayerComponent = (props) => {
    const {
        groups10,
        groups20,
        groups30,
        groups40,
        groups50,
        groups60,
        flashes10,
        flashes20,
        flashes30,
        flashes40,
        flashes50,
        flashes60,
        historyGroups10,
        historyGroups20,
        historyGroups30,
        historyGroups40,
        historyGroups50,
        historyGroups60,
        historyFlashes10,
        historyFlashes20,
        historyFlashes30,
        historyFlashes40,
        historyFlashes50,
        historyFlashes60,
        showDischarges,
        dischargesToShow,
        dischargesTypeToShow
    } = useDischarges();
    const { polygon } = useTools();
        
    return (
        <>
            {(groups10.type || historyGroups10.type) && (
                <Source
                    id="groups-10"
                    type="geojson"
                    data={historyGroups10.type? historyGroups10 : groups10}
                    generateId={true}
                >
                    <Layer
                        id="groups-10"
                        source="groups-10"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#A8050E',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#A8050E',
                            ],
                        }}
                    />
                </Source>
            )}
            {(groups20.type || historyGroups20.type) && (
                <Source
                    id="groups-20"
                    type="geojson"
                    data={historyGroups20.type? historyGroups20 : groups20}
                    generateId={true}
                >
                    <Layer
                        id="groups-20"
                        source="groups-20"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#ED5A35',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#ED5A35',
                            ],
                        }}
                    />
                </Source>
            )}
            {(groups30.type || historyGroups30.type) && (
                <Source
                    id="groups-30"
                    type="geojson"
                    data={historyGroups30.type? historyGroups30 : groups30}
                    generateId={true}
                >
                    <Layer
                        id="groups-30"
                        source="groups-30"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#18A91C',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#18A91C',
                            ],
                        }}
                    />
                </Source>
            )}
            {(groups40.type || historyGroups40.type) && (
                <Source
                    id="groups-40"
                    type="geojson"
                    data={historyGroups40.type? historyGroups40 : groups40}
                    generateId={true}
                >
                    <Layer
                        id="groups-40"
                        source="groups-40"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#0FF',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#000',
                            ],
                        }}
                    />
                </Source>
            )}
            {(groups50.type || historyGroups50.type) && (
                <Source
                    id="groups-50"
                    type="geojson"
                    data={historyGroups50.type? historyGroups50 : groups50}
                    generateId={true}
                >
                    <Layer
                        id="groups-50"
                        source="groups-50"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#00F',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#000',
                            ],
                        }}
                    />
                </Source>
            )}
            {(groups60.type || historyGroups60.type) && (
                <Source
                    id="groups-60"
                    type="geojson"
                    data={historyGroups60.type? historyGroups60 : groups60}
                    generateId={true}
                >
                    <Layer
                        id="groups-60"
                        source="groups-60"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#AAA',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#000',
                            ],
                        }}
                    />
                </Source>
            )}
            {(flashes10.type || historyFlashes10.type) && (
                <Source
                    id="flashes-10"
                    type="geojson"
                    data={historyFlashes10.type? historyFlashes10: flashes10}
                    generateId={true}
                >
                    <Layer
                        id="flashes-10"
                        source="flashes-10"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#A8050E',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#A8050E',
                            ],
                        }}
                    />
                </Source>
            )}
            {(flashes20.type || historyFlashes20.type) && (
                <Source
                    id="flashes-20"
                    type="geojson"
                    data={historyFlashes20.type? historyFlashes20: flashes20}
                    generateId={true}
                >
                    <Layer
                        id="flashes-20"
                        source="flashes-20"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#ED5A35',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#ED5A35',
                            ],
                        }}
                    />
                </Source>
            )}
            {(flashes30.type || historyFlashes30.type) && (
                <Source
                    id="flashes-30"
                    type="geojson"
                    data={historyFlashes30.type? historyFlashes30: flashes30}
                    generateId={true}
                >
                    <Layer
                        id="flashes-30"
                        source="flashes-30"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#18A91C',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#18A91C',
                            ],
                        }}
                    />
                </Source>
            )}
            {(flashes40.type || historyFlashes40.type) && (
                <Source
                    id="flashes-40"
                    type="geojson"
                    data={historyFlashes40.type? historyFlashes40: flashes40}
                    generateId={true}
                >
                    <Layer
                        id="flashes-40"
                        source="flashes-40"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#0FF',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#000',
                            ],
                        }}
                    />
                </Source>
            )}
            {(flashes50.type || historyFlashes50.type) && (
                <Source
                    id="flashes-50"
                    type="geojson"
                    data={historyFlashes50.type? historyFlashes50: flashes50}
                    generateId={true}
                >
                    <Layer
                        id="flashes-50"
                        source="flashes-50"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#00F',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#000',
                            ],
                        }}
                    />
                </Source>
            )}
            {(flashes60.type || historyFlashes60.type) && (
                <Source
                    id="flashes-60"
                    type="geojson"
                    data={historyFlashes60.type? historyFlashes60: flashes60}
                    generateId={true}
                >
                    <Layer
                        id="flashes-60"
                        source="flashes-60"
                        type="circle"
                        paint={{
                            'circle-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#FFF',
                                '#AAA',
                            ],
                            'circle-radius': {
                                stops: [
                                    [5, 2],
                                    [8, 3],
                                    [10, 4],
                                    [12, 5],
                                ],
                            },
                            'circle-stroke-width': 0,
                            'circle-stroke-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                '#000',
                                '#000',
                            ],
                        }}
                    />
                </Source>
            )}
        </>
    );
};

export const DischargesLayer = memo(DischargesLayerComponent);
