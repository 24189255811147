import { djangoApi } from '../api';


export const login = (credentials) => {
  delete djangoApi.defaults.headers.common['Authorization'];
  delete djangoApi.defaults.headers['Authorization'];

  return djangoApi.post(`usuario/login/`, credentials);
}

export const logout = (token) => {
  if(token){
    djangoApi.defaults.headers.common['Authorization'] = token;
  }
  else{
    djangoApi.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem("reeWAL57qe"));
  }
  return djangoApi.post(`usuario/logout/`);
}

export const getUserAuthenticated = () => {
    return djangoApi.get(`usuario/get/`);
}

export const isUnique = (email) => {

  const data = {
      "email": email,
  };

  return djangoApi.post(`usuario/existente/`, data);
}

export const refreshToken = (key) => {
  const data = {
    "token": key,
  };
  return djangoApi.post('usuario/refresh_token/', data);
}

export const sendEmailReset = (email) => {

  return djangoApi.post(`usuario/reset-password/`, email);
}

export const resetPassword = (link, password) => {

  const strLink = link.toString()

  return djangoApi.patch(strLink , password);
}