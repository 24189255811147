import {useState, useCallback} from 'react';

// Libs
import moment from 'moment';

// Components
import {Close} from '@material-ui/icons';
import {Column, Row} from 'components/Flex';
import {CloseButton, ControlHeader, ControlPanel, Divider, PlayButton, ReloadButton} from './styled';

// Icons
import { MdOpacity } from 'react-icons/md';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRotateRight} from '@fortawesome/free-solid-svg-icons';
import satelliteImg from 'images/monitoring/Satelite.png'

// Hooks
import {useControlPanel} from 'Context/ControlPanelContext';
import {useSatellite} from 'Context/SatelliteContext';
import useInterval from 'hooks/useInterval';

const SatellitePanel = () => {
  const {
      satelliteInfo,
      loadingSatellite,
      updateSatellite,
      currentImage,
      setCurrentImage,
      opacidadeControl,
      setOpacidadeControl
  } = useSatellite();

  const { showSatellitePanel, toggleSatellitePanel } = useControlPanel();
  const [playing, setPlaying] = useState(false);
  const [segundos, setSegundos] = useState(300);


  const restart = useCallback(() => {
    setSegundos(300);
    updateSatellite();
  }, [])

  useInterval(() => {
    if (segundos > 0){
      setSegundos(s => s-1);
    } else {
      restart()
    }
  }, 1000);

  useInterval(() => {
    setCurrentImage(
        currentImage >= satelliteInfo.imageQuantity - 1 ? 0 : currentImage + 1,
    );
  }, playing ? 1000 : null)

  return !showSatellitePanel ? undefined : (
    <ControlPanel>
      <ControlHeader>
        <div style={{width: "85%", justifyContent: "center", display: "flex"}}>
          <span>IMAGENS SATÉLITE</span>
        </div>
        <CloseButton onClick={toggleSatellitePanel}><Close /></CloseButton>
      </ControlHeader>
      <Column w='90%' gap='12px' margin='0 0 8px 0'>
        <Row w='100%' gap='16px' justify='space-between'>
          <ReloadButton onClick={restart} loading={loadingSatellite}>
            <FontAwesomeIcon icon={faArrowRotateRight} />
          </ReloadButton>
          <label className="horario">
                    Tempo até a próxima atualização: {'0' +
                        Math.floor(segundos/60) +
                        ':' +
                        ((segundos % 60) < 10 ? '0' + segundos%60 : segundos%60)}
          </label>
        </Row>
        <Divider />
        <Row w='100%' gap='16px' justify='space-between'>
          <PlayButton isPlaying={playing} onClick={()=>setPlaying(p=>!p)} />
          <input
              id="sliderImagens"
              type="range"
              value={currentImage}
              min={0}
              max={satelliteInfo.imageQuantity - 1}
              step={1}
              onChange={(e) => {
                  setCurrentImage(Number(e.target.value));
                  setPlaying(false);
              }}
          />
          <span style={{width: '120px', textAlign: 'end'}}>{
            moment(new Date(
              satelliteInfo.satelliteData[currentImage].imageDate))
              .format('hh:mm - DD/MM/YYYY')
          }
          </span>
        </Row>
        <Divider />
        <Row w='100%' gap='16px' justify='space-between'>
          <MdOpacity style={{fontSize: '24px'}} />
          <input
            type="range"
            min={0}
            max={10}
            step={0.25}
            defaultValue={5}
            onChange={(e) =>
              setOpacidadeControl(e.target.value / 10)
            }
          />
          <span  style={{width: '120px', textAlign: 'end'}}>
            {Math.floor(opacidadeControl*1000)/10}%
          </span>
        </Row>
        <Divider />
        <Column>
            <img src={satelliteImg} alt="" style={{width: '100%'}} />
            <span>Temperatura(ºC)</span>
        </Column>
      </Column>
    </ControlPanel>
  )
}

export default SatellitePanel;
