const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({
/* Template */

    titleContainer: {
       marginBottom: '10px',
       display: 'flex',
       flexDirection: 'row'
    },

    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
     },

    acumulatedField: {
        width: '35%',
    },

    acumulatedFieldsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '3px',
        width: 'fit-content',
    },

    acumulatedContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        width: '25%',
     },

    contactsField: {
        width: '55%',
        marginBottom: '26px',
    },

    durationField: {
        width: '10%',
        marginBottom: '-1px',
    },

    intensityField: {
        width: '40.5%',
        marginBottom: '25px',
    },

    commentsField: {
        width: '55%', 
    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '10px',
    },

    rainButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "11px 11px",
        gap: "10px",
        backgroundColor: "#046DCA",
        borderRadius: "5px",
        color: "#FFFFFF",
    },

    commentsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
     },

    errorMessage: {
        fontSize: `11px`,
        color: "red",
        margin: "0 0 0 5px",
        fontFamily: fontFamily
    },
     
});

export default styles;