/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';

import AlertsElement from "./AlertsElement";
import AlertsElementLiberations from "./AlertsElementLiberations";
import AlertsElementRain from "./AlertsElementRain";
import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import LightningAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import FieldsComponent from "../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert/fieldsComponent";
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../services/mapsPortal/alerts"
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'

const useStyles = makeStyles({
    lightningAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#B067E9",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#B067E9'
      }
    },
    rainAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#046DCA",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#046DCA'
      }
    },
    meteorologicalAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    },
    releases: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#27B624",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#27B624'
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
  })

const AlertsRetification = (props) => {
    const {classes, rightSideTabs, value, setReloadRightSide, typeVal, setAllValues, checkboxes, allValues} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const dividerStyle = useStyles();
    var gridClasses = [
        classes.gridColumnsComponent,
        classes.gridMarginLeft,
    ]
    const [companyValues, setCompanyValues] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
      if(reload){
        setReload(false);
      }
    }, [reload, checkboxes]);
       
    const changeCompanyValues = (element) => {
      let aux = []
      const finderAux = companyValues.map((value) => {
        return value.includes(element.split("_")[1]+"_"+element.split("_")[2])?
          value
        : 
        ""
      });

    if(finderAux != undefined && finderAux[0] != "" && finderAux[0] != null){
        companyValues.filter((value) => { 
          if(value.includes([element.split("_")[1]+"_"+element.split("_")[2]])){
            aux = companyValues.filter((val)=>{
              return val != value
              
            }); 
          }
          aux.push(element)

        });
        
      }else{
        aux = companyValues
        aux.push(element)
      }
      setCompanyValues(aux)
      setReload(true)
    };

    const checkVerify = (valueCheck)=>{
      let aux = ""
      companyValues.filter((value) => {
        value.includes(valueCheck)? 
          aux = value.split("_")[3] 
        : 
          aux = ""
      })
      return(aux)
    };

    return(
      value[0]!=null && rightSideTabs==typeVal? value.map((company,index) => {
        if(checkboxes.includes(company.nome+"_company_Alvo_"+company.alvo+"_"+company.id)){
          if(typeVal==0){
            return(
              <AlertsElement key={company.index} setReloadRightSide={setReloadRightSide} allValues={allValues} setAllValues={setAllValues} value={company} />
            )
          }else if(typeVal==1){
            return(
              <AlertsElementLiberations key={company.index} setReloadRightSide={setReloadRightSide} allValues={allValues} setAllValues={setAllValues} value={company} />
            )
          }else if(typeVal==2){
            return(
              <AlertsElementRain key={company.index} setReloadRightSide={setReloadRightSide} allValues={allValues} setAllValues={setAllValues} value={company} />
            )
          }
        }
      }) 
      :
      "SEM DADOS"
    )

};

AlertsRetification.propTypes = {
    classes: PropTypes.object.isRequired,
    typeVal: PropTypes.number,
    indexPage: PropTypes.any,
    checkboxes: PropTypes.array.isRequired,
    allValues: PropTypes.array.isRequired,
    setReloadRightSide: PropTypes.func,
    setAllValues: PropTypes.func,
    value: PropTypes.any,
    rightSideTabs: PropTypes.number.isRequired,
  };
  
  export default withStyles(styles)(AlertsRetification);