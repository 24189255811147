import post from "../../images/custom_icons/post_icon.png";
import thunderstorm_add from "../../images/custom_icons/thunderstorm_add_icon.png";
import gps_fixed_add from "../../images/custom_icons/gps_fixed_add_icon.png";
import register_all from "../../images/custom_icons/register_all_icon.png";
import register_all_active from "../../images/custom_icons/register_all_active_icon.png";
import view_plans from "../../images/custom_icons/view_plans_icon.png";
import view_plans_active from "../../images/custom_icons/view_plans_active_icon.png";


const menuClientContext = [
  {
    key: 'home',
    name: 'Início',
    icon: 'home',
    link: '/cliente',
    child: []
  },
  {
    key: 'nucleoUser',
    name: 'Usuários',
    icon: 'person',
    link: '/cliente/usuarios',
    child: []
  },
  {
    key: 'user',
    name: 'Cadastrar Usuario',
    icon: 'person_add',
    link: '/cliente/registrar/usuario',
    child: []
  },
];

export default menuClientContext;

