import styled, {keyframes} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {colors} from 'pages/MapsPortal/styled'
const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5;}
  100%{ opacity: 1;}
`

export const RightMenu = styled.div`
    position: fixed;
    bottom: 0px;
    right: 50%;
    transform: translateX(50%);
    padding: 13px;
    border-radius: 13px 13px 0 0;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.4);
    color: black;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const Button = styled.button`
    min-width: 40px;
    height: 40px;
    background-color: ${props => props.selected ? props.type === "Discharges10"? "#A8050E" :
        props.type === "Discharges20"? "#ED5A35" : props.type === "Discharges30"? "#18A91C" : 
        props.type === "Discharges40"? "#0FF": props.type === "Discharges50"? "#00F" : 
        props.type === "Discharges60"? "#AAA" : colors.button.selected : "trasparent"};
    padding: 0;
    cursor: pointer;
    color: black;
    transition: background-color 0.4s ease;
    &:hover{
      background-color: ${props => props.type === "Discharges10"? "#A8050E" : 
      props.type === "Discharges20"? "#ED5A35" : props.type === "Discharges30"? "#18A91C" : 
      props.type === "Discharges40"? "#0FF": props.type === "Discharges50"? "#00F" : 
      props.type === "Discharges60"? "#AAA": colors.button.selected};
    }
    animation: ${blinkAnimation} 0.8s linear ${(props) => (
      typeof props.loading === 'undefined'
        ? '0'
        : props.loading ? 'infinite' : '1')};
    animation-play-state: running;
`;

export const ExtraOptions = styled.div`
    position: absolute;
    padding: 12px 0;
    z-index: -10;
    border-radius: 13px 13px 0 0;
    bottom: 53px;
    right: 50%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 72px;
    gap: 12px;
    box-shadow: 0px -6px 6px 2px rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 255, 255, 0.9);
    transform-origin: bottom;
    transition: 0.5s ease transform;
`;

export const HorizontalExtraOptions = styled.div`
    position: absolute;
    padding: 12px 0;
    z-index: -30;
    border-radius: 0 13px 13px 0;
    left: 72px;
    bottom:  ${props => props.bottom};
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 40px;
    gap: 12px;
    box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    transform-origin: left;
    transition: 0.5s ease transform;
`;

export const ExtraOptionsButton = styled(Button)`
    min-width: 48px;
    padding: 4px;
    color: #000;
`;

export const MenuLineWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 40px;
    ${ExtraOptions} {
        transform: translateX(50%) scaleY(0);
    }

    &:hover > ${ExtraOptions} {
        transform: translateX(50%) scaleY(1);
    }
`;

export const HorizontalMenuLineWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: 40px;
    ${HorizontalExtraOptions} {
        transform: translateY(50%) scaleY(0);
    }

    &:hover > ${HorizontalExtraOptions} {
        transform: translateY(50%) scaleY(1);
    }
`;

export const Icon = styled(FontAwesomeIcon)`
    font-size: 18px;
    color: #000;
    transition: 0.4s ease;
    border-radius: 3px;
    /* &:hover {
        transform: scale(1);
        background-color: #D7CEF1;
        /* color: ${(props) =>
            props.selected
                ? '#FF0'
                : props.hovercolor
                ? props.hovercolor
                : '#FA0'}; */
    }

    &.selected {
        color: yellow;
        &:hover {
            color: yellow;
        }
    } */
`;
