/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import { postLightningModifiedAlerts, postModifiedLightningRelease, postModifiedRainAlert, postModifiedReport } from 'services/mapsPortal/alerts'
import ModalContextProvider from 'Context/ModalContext';


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const ModalErrata = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo } = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();
  const refEdit = useRef();
  const refAdd = useRef();
  var aux = 0;
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);

  const handleClose = () => {
    setOpen(null);
    setReloadMod(true);
  }

  const handleAllSelected = () => {
    if(!allSelected){
      let aux = [];
      tableData.map((cam, index) => aux.push(index));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
    else{
      let aux = [];
      tableData.map((cam1, index1) => selected.some((id) => index1 === id)? aux : aux.push(index1));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
  }
  const splitMod = (modif) => {
    var aux = "";
    modif? modif.map((value) => aux+= value+" ") : ""
    return aux

  }
  const handleClickCheckbox = (index) => {
    let aux = selected;
    if(selected.indexOf(index) !== -1){
      aux = aux?.filter((id) => id != index);
      setSelected(aux);
      setAllSelected(false);
    }
    else{
      aux.push(index);
      setSelected(aux);
      if(selected.length === tableData.length){
        setAllSelected(true);
      }
    }
    setReload(true);
  }

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);
  
  const getToolsArray = (tools) =>{
    let aux = []
    if(tools.includes(",")){
      aux = tools.split(",")
    }else{
      aux.push(tools)
    }

    return (aux)
  };

  const postErratas = () => {
    console.log("ENTROU AQ")
    console.log(tableData)
      let aux = []
      let error = []
      if(tableData.errataInfos?.length>0){
        tableData.errataInfos.map((option)=>{
        let val_splitted = option.split("_")
        val_splitted = val_splitted.map(x => x !== "" ? x : null);
        if(val_splitted[3] == "Alerta Raios"){
          aux = ({nivel: val_splitted[6], observacao: val_splitted[12], ferramenta: getToolsArray(val_splitted[11]), duracao: val_splitted[7], risco:  val_splitted[9], liberado: val_splitted[15]=="true"? true : false, contato: val_splitted[10], datahora_modificacao: null, datahora_liberacao: val_splitted[16]=="null"? null : val_splitted[16], campos_modificados:  ["Errata"], errata: true, meteorologista: parseInt(val_splitted[18]), id_alerta_raios:  val_splitted[4]})
          console.log(aux)
          postLightningModifiedAlerts(aux).then((response)=>{
            if(response.status != 201){
              error.push("Alerta Raios: "+val_splitted[4])
            }
          }).catch(() => {
            error.push("Alerta Raios: "+val_splitted[4])
          })

        }else if(val_splitted[3] == "Alertas Liberados"){
          aux = ({nivel: val_splitted[6], observacao: val_splitted[12], risco:  val_splitted[9], contato: val_splitted[10], datahora_modificacao: null, campos_modificados:  ["Errata"], errata: true, meteorologista: val_splitted[19], id_alerta_liberado:  val_splitted[20]})
          postModifiedLightningRelease(aux).then((response)=>{
            if(response.status != 201){
              error.push("Alertas Liberados: "+val_splitted[20])
            }
          }).catch(() => {
            error.push("Alertas Liberados: "+val_splitted[20])
          })

        }else if(val_splitted[3] == "Avisos Chuva"){
          aux = ({observacao: val_splitted[12], contato: val_splitted[10], duracao: val_splitted[13], datahora_modificacao: null, campos_modificados:  ["Errata"], errata: true, meteorologista: val_splitted[19], id_avisos_chuva:  val_splitted[20], intensidade: val_splitted[21], acumulado: val_splitted[22]})
          postModifiedRainAlert(aux).then((response)=>{
            if(response.status != 201){
              error.push("Avisos Chuva: "+ val_splitted[20])
            }
          }).catch(() => {
            error.push("Avisos Chuva: "+ val_splitted[20])
          })
          
        }

      })
      }else{
        tableData.values?.map((option,index)=> {
          let splitted = option.split("_")
          let aux = []
          if(splitted[15]=="Boletins"){
            aux = ({id_boletim_modificado:null, intensidade:splitted[1]!=""? splitted[1] : null, nivel:splitted[8]!=""? splitted[8] : null, probabilidade:splitted[7]!=""? splitted[7] : null, temperatura_min: splitted[12]!=""? splitted[12] : null,temperatura_max: splitted[13]!=""? splitted[13] : null, condicao_tempo: splitted[2]!=""? splitted[2] : null, acumulado: splitted[6]!=""? splitted[6] : null, rajada_vento: splitted[9]!=""? splitted[9] : null, direcao_vento: splitted[10]!=""? splitted[10] : null, velocidade_vento: splitted[11]!=""? splitted[11] : null, observacao: splitted[14]!=""? splitted[14] : null, nome_empresa: splitted[5]!=""? splitted[5] : null, nome_plano: splitted[3]!=""? splitted[3] : null, nome_alvo: splitted[4]!=""? splitted[4] : null, nome_meteorologista: splitted[17]!=""? splitted[17] : null, horizontes: splitted[19]!=""? parseInt(splitted[19]) : null , status: splitted[22]!=""? parseInt(splitted[22]) : null, vigencia: splitted[20]!=""? splitted[20] : null, especial: splitted[21]=="true"? true: false, datahora_modificacao: null, campos_modificados: ["Errata"], id_boletim_original: splitted[30]!=""? splitted[30] : null, meteorologista: splitted[18]!=""? parseInt(splitted[18]) : null})
            postModifiedReport(aux).then((response)=>{
              if(response.status != 201){
                error.push("Boletins: "+splitted[0])
              }
            }).catch(() => {
              error.push("Boletins: "+splitted[0])
            })
          }
        })
      }
   
      if(error.length > 0){
        openToast('error', `Problema ao executar a errata para ${error}`)
      }else{
        openToast('success', 'Errata realizada com sucesso!')
        handleClose()
      }

      tableData.reloadHistory(true)
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  return(
    <ModalContextProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 300, left: windowSize[0]/2}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      > 
      <Table style={{margin:"0px"}}>
        <TableHead>
          <div style={{width: "100%", height:"100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
            <div style={{color:"white", paddingTop:"10px", paddingLeft:"15px", fontSize:"20px"}}>
                <h2>Enviar Errata</h2>
            </div>
          </div>
        </TableHead>
        <TableBody>


          {tableData.errataInfos? tableData.errataInfos?.map((option,index)=> {
                return (
                  <StyledTableRow key={index}>
                    <StyledCellView
                          padding="normal"
                          style={fonts.cellContent}
                      >
                        <div style={{alignItems: "center"}}>
                          <p><strong>EMPRESA:</strong> {option.split("_")[0]}</p>
                          <p><strong>PLANO DO ALVO:</strong> {option.split("_")[1]}</p>
                          <p><strong>NOME DO ALVO:</strong> {option.split("_")[2]}</p>
                          {option.split("_")[3]=="Avisos Chuva"? <p style={{color:"#046DCA"}}><strong>ERRATA PARA:</strong> Aviso de Chuva</p> : option.split("_")[3]=="Alerta Raios"? <p style={{color:"#B067E9"}}><strong>ERRATA PARA:</strong> Alerta Raio</p> : option.split("_")[3]=="Alertas Liberados"? <p style={{color:"#9FC9A6"}}><strong>ERRATA PARA:</strong> Alerta Liberado</p> : ""}
                        </div>
                    </StyledCellView>
                  </StyledTableRow>
                )
          }) : 
            tableData.values?.map((option,index)=> {
              let splitted = option.split("_")
              return (
                <StyledTableRow key={index}>
                  <StyledCellView
                        padding="normal"
                        style={fonts.cellContent}
                    >
                      <div style={{alignItems: "center"}}>
                        <p><strong>EMPRESA:</strong> {splitted[5]}</p>
                        <p><strong>PLANO DO ALVO:</strong> {splitted[3]}</p>
                        <p><strong>NOME DO ALVO:</strong> {splitted[4]}</p>
                        {splitted[15]=="Boletins"? <p style={{color:"#E6DBA1"}}><strong>ERRATA PARA:</strong> Boletins</p> : splitted[15]=="Avisos Meteorológicos"? <p style={{color:"#B6888F"}}><strong>ERRATA PARA:</strong>Aviso Meteorológico</p>  : ""}
                      </div>
                  </StyledCellView>
                </StyledTableRow>
              )
            })
          }


        </TableBody>
      </Table>
      <div style={{width:"1000px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
        <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px", justifyContent:"space-between"}}>
            <div style={{marginLeft:"20px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1} onClick={() => handleClose()}>
              <span className={classes.textButtonCancel}>CANCELAR</span>
            </div>
            <div>
              <div style={{padding:"10px 20px 10px 20px", marginRight:"20px"}} className={classes.outlineButton4} onClick={() => postErratas()}>
                <span className={classes.textButton}>ENVIAR <FaPaperPlane></FaPaperPlane></span>
              </div>
            </div>
        </div>
      </div>
      </Popover>
    </ModalContextProvider>
  )
} 

ModalErrata.propTypes = {
  classes: PropTypes.object,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.object,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalErrata);