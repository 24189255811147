
/* Libraries */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material UI */
import Button from '@material-ui/core/Button';
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';
/* Components */
import ExportExcelFile from 'components/Tables/ExportExcelFile';


const TableInfoSummary = (props) => {
    const { classes, summaryData,  } = props;
    const fonts = fontStyles();
    const dispatch = useDispatch();
    const ref = useRef(null);

    const onCloseModal = () => {
        dispatch(modalProvider(false, null, null, null, null, null));
    }

    const setCSV = (data) => {
        var aux = [];

        data.map((item, index) => aux.push({usuarios: data[index]}));
        return aux;
    }

    const getDimensionsHeight = () => {
        return ref.current? ((ref.current.getBoundingClientRect().height) - 150) + "px": null;
    }

    const getDimensionsWidth = (percent) => {
        return ref.current? ((ref.current.getBoundingClientRect().width) * percent) + "px": null;
      }

 
    return(
        <div className={classes.infosContainerDiv} ref={ref}>
            <div className={classes.infosContainerHeader}>
                <span style={fonts.infosModalTitle}>{"Lista de " + summaryData[1].toLowerCase() + " do " + (summaryData[3] != "TodosPlanos"? summaryData[3].toLowerCase() : "planos") + ": "}</span>
                <div style={{maxWidth: `${getDimensionsWidth(0.5)}`, textOverflow: "ellipsis", overflow: "hidden", marginLeft: "10px"}}> <span style={fonts.infosModalTitle}>{summaryData[2]}</span></div>
            </div>
            <div className={classes.infosContainerBody}>
                <div className={classes.infosContainerBodyTitle}>
                    <div style={{marginRight: "8%"}}>
                        <ExportExcelFile dataSet={setCSV(summaryData[5])} type={`${summaryData[2]} - ${summaryData[1]}`} csvButtonName={`Exportar ${summaryData[1]}`} />
                    </div>
                </div>
                <div className={classes.infosContainerBodyBody} style={{height: getDimensionsHeight()}}>
                    {summaryData[5]?.length > 0 && summaryData[5]?.map((data, index) =>
                        <span style={fonts.item} key={index}>{data != null? typeof data === "object"? `${index + 1} - ${data.nome}` : `${index + 1} - ${data}` : ""}</span>      
                    )}
                </div>
            </div>
            <div className={classes.infosContainerFooter}>
                <Button style={fonts.buttonDefault} variant="contained" className={classes.goBackButton} onClick={onCloseModal}>
                    Voltar
                </Button>
            </div>
        </div>
    );
}

TableInfoSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    summaryData: PropTypes.array.isRequired,
};

export default withStyles(styles)(TableInfoSummary);