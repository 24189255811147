/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import ModalContextProvider from 'Context/ModalContext';


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const ModalDeleteTicket = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo } = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();
  const refEdit = useRef();
  const refAdd = useRef();
  var aux = 0;
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);

  const handleClose = () => {
    setOpen(null);
    setReloadMod(true);
  }

  const handleAddCam = () => {
    setAddState(!addState);
  }

  const handleEditCam = () => {
    setEditState(!editState);
  }

  const handleDeleteCam = () => {
    setDeleteState(!deleteState);
  }


  const handleAllSelected = () => {
    if(!allSelected){
      let aux = [];
      tableData.map((cam, index) => aux.push(index));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
    else{
      let aux = [];
      tableData.map((cam1, index1) => selected.some((id) => index1 === id)? aux : aux.push(index1));
      setSelected(aux);
      setAllSelected(!allSelected)
    }
  }
  const splitMod = (modif) => {
    var aux = "";
    modif? modif.map((value) => aux+= value+" ") : ""
    return aux

  }
  const handleClickCheckbox = (index) => {
    let aux = selected;
    if(selected.indexOf(index) !== -1){
      aux = aux?.filter((id) => id != index);
      setSelected(aux);
      setAllSelected(false);
    }
    else{
      aux.push(index);
      setSelected(aux);
      if(selected.length === tableData.length){
        setAllSelected(true);
      }
    }
    setReload(true);
  }

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);
  

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return(
    <ModalContextProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 300, left: windowSize[0]/2}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      > 

      <Table style={{margin:"0px"}}>
        <TableHead>
          <div style={{width: "100%", height:"100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
            <div style={{color:"white", paddingTop:"10px", paddingLeft:"15px", fontSize:"20px"}}>
                <h2>APROVAR BOLETINS</h2>
            </div>
          </div>
        </TableHead>
        <TableBody>
          {tableData.values.map((option)=> {
            let aux = String(option).split("_")
                return (
                  <StyledTableRow key={aux[6]}>
                    <StyledCellView
                          padding="normal"
                          style={fonts.cellContent}
                      >
                        <div style={{alignItems: "center"}}>
                          <p><strong>ALVO:</strong> {aux[0]}</p>
                          <p><strong>TIPO DE BOLETIM:</strong> {aux[4]}</p>
                          <p><strong>RESPONSÁVEL:</strong> {aux[3]}</p>
                          <p><strong>VIGÊNCIA:</strong> {aux[5]}</p>
                        </div>
                    </StyledCellView>
                  </StyledTableRow>
                )
          })}
        </TableBody>
      </Table>
      <div style={{width:"1000px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
        <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px", justifyContent:"space-between"}}>
            <div style={{marginLeft:"20px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1} onClick={() => handleClose()}>
              <span span style={fonts.mapSidebarCamtDescripitionItem}>CANCELAR</span>
            </div>
            <div>
              <div style={{padding:"10px 40px 10px 40px", marginRight:"20px"}} className={classes.outlineButton7} onClick={() => handleClose()}>
                <span span>APROVAR</span>
              </div>
            </div>
        </div>
      </div>
      </Popover>
    </ModalContextProvider>
  )
} 

ModalDeleteTicket.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.bool,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalDeleteTicket);