/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';

import AlertsElementReports from "./AlertsElementReports";
import AlertsElementMetereologic from "./AlertsElementMetereologic";

import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import LightningAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import FieldsComponent from "../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert/fieldsComponent";
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../services/mapsPortal/alerts"
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'
import ReportTabs from '../../pages/MapsPortal/components/ForecastPanel/component/MonitoringForms/ReportCard/reportTabs.js';

const AlertsRetification = (props) => {
    const {classes, rightSideTabs, value, setReloadRightSide, typeVal, setAllValues, checkboxes, allValues} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const [reportTabsIndex, setReportTabsIndex] = useState(value.especial=="true"? 2 : 0);
    var gridClasses = [
        classes.gridColumnsComponent,
        classes.gridMarginLeft,
    ]
    const [companyValues, setCompanyValues] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
      if(reload){
        setReload(false);
      }
    }, [reload, checkboxes]);
    
    const defaultProps = {
      options: ["T1", "T2", "T3", "T4"],
    };

    
    const changeCompanyValues = (element) => {
      let aux = []
      const finderAux = companyValues.map((value) => {
        return value.includes(element.split("_")[1]+"_"+element.split("_")[2])?
          value
        : 
        ""
      });

    if(finderAux != undefined && finderAux[0] != "" && finderAux[0] != null){
        companyValues.filter((value) => { 
          if(value.includes([element.split("_")[1]+"_"+element.split("_")[2]])){
            aux = companyValues.filter((val)=>{
              return val != value
              
            }); 
          }
          aux.push(element)

        });
        
      }else{
        aux = companyValues
        aux.push(element)
      }
      setCompanyValues(aux)
      setReload(true)
    };

    const handleChangeIndexTabs = (index) => {
      setReportTabsIndex(index);
    };
    
    const checkVerify = (valueCheck)=>{
      let aux = ""
      companyValues.filter((value) => {
        value.includes(valueCheck)? 
          aux = value.split("_")[3] 
        : 
          aux = ""
      })
      return(aux)
    };

    return(
      value[0]!=null && rightSideTabs==typeVal? value.map((company,index) => {
        if(checkboxes.includes(company.empresa+"_company_Alvo_"+company.alvo+"_"+company.id)){
          if(typeVal==0){
            
            return(
              <>
                <ReportTabs
                  reportTabsIndex={reportTabsIndex}
                  setReportTabsIndex={setReportTabsIndex}
                />
                <SwipeableViews
                  axis={
                      theme.direction === 'rtl'
                          ? 'x-reverse'
                          : 'x'
                  }
                  index={reportTabsIndex}
                  onChangeIndex={handleChangeIndexTabs}
                >
                  <TabPanel
                    value={reportTabsIndex}
                    index={0}
                    dir={theme.direction}
                  >
                    <AlertsElementReports setReloadRightSide={setReloadRightSide} reportType={"Daily"} allValues={allValues} setAllValues={setAllValues} value={company} />
                  </TabPanel>
                  <TabPanel
                    value={reportTabsIndex}
                    index={1}
                    dir={theme.direction}
                  >
                    <AlertsElementReports setReloadRightSide={setReloadRightSide} reportType={"Nowcasting"} allValues={allValues} setAllValues={setAllValues} value={company} />
                  </TabPanel>
                  <TabPanel
                    value={reportTabsIndex}
                    index={2}
                    dir={theme.direction}
                  >
                    <AlertsElementReports setReloadRightSide={setReloadRightSide} reportType={"Especial"} allValues={allValues} setAllValues={setAllValues} value={company} />
                  </TabPanel>
                </SwipeableViews>
              </>
            )
          }else if(typeVal==1){
            return(
              <AlertsElementMetereologic key={company.index} setReloadRightSide={setReloadRightSide} allValues={allValues} setAllValues={setAllValues} value={company} />
            )
          }
        }
      }) 
      :
      "SEM DADOS"
    )

};

AlertsRetification.propTypes = {
    classes: PropTypes.object.isRequired,
    typeVal: PropTypes.number,
    indexPage: PropTypes.any,
    checkboxes: PropTypes.array.isRequired,
    allValues: PropTypes.array.isRequired,
    setReloadRightSide: PropTypes.func,
    setAllValues: PropTypes.func,
    value: PropTypes.any,
    rightSideTabs: PropTypes.number.isRequired,
  };
  
  export default withStyles(styles)(AlertsRetification);