import { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { getTargetList, getClientTargetList } from 'services/mapsPortal/initialLoad';
import useLoad from 'hooks/useLoad';
import { union } from '@turf/turf';
import useTogglePlans from 'hooks/useTogglePlans';
import useToggle from 'hooks/useToggle';

export const TargetContext = createContext();

export const TargetContextProvider = (props) => {

    const { data: planos } = useLoad(getTargetList, []);
    const { data: planosCliente } = useLoad(getClientTargetList, []);
    //const { data: ativos } = useLoad(getActives, []);
    const toShow = useTogglePlans(planos, false);
    const [ mergeTargetsOption, toggleTargetMerge ] = useToggle(false);
    const [ showTargets, toggleTargets ] = useToggle(true)
    const [intersectionLayers, setIntersectionLayers] = useState([]);

    useEffect(() => {
      toShow.newData(planos, true)
    }, [JSON.stringify(planos)])

    return (
        <TargetContext.Provider
            value={{
                planos,
                planosCliente,
                toShow,
                mergeTargetsOption,
                toggleTargetMerge,
                showTargets,
                toggleTargets,
                intersectionLayers,
                setIntersectionLayers
            }}
        >
            {props.children}
        </TargetContext.Provider>
    );
};

TargetContextProvider.propTypes = {
    children: PropTypes.node,
};

export default TargetContextProvider;

export const useTarget = () => {
  const context = useContext(TargetContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
