/* eslint-disable react/no-unknown-property */
/* Libraries */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, NavLink} from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
/* Material UI */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ImExit } from 'react-icons/im';
/* Components */
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { openMenuAction, closeMenuAction } from 'redux/actions/uiActions';
import TooltipComponent from "components/Modal/Tooltip";
import IconButton from '@material-ui/core/IconButton';
/* CSS */
import styles from './sidebarBig-jss';
import fontStyles from '../../styles/fonts-jss';
/* Utils */
import { persistViewPageNavigation } from '../../utils/persistViewPageNavigation';
import { persistTabMenu } from '../../utils/persistTabMenu';
import { tabMenuProvider } from 'redux/actions/tabMenuAction';
import { sidebarMenuProvider } from 'redux/actions/sidebarMenuAction';
import { useToast } from 'Context/ToastContext';
import {useAuth} from 'Context/AuthContext';

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

const useStyles = makeStyles({
  iconInactive: {
    color: "white"
  },
  iconActive: {
    color: "red"
  },
});


function MainMenuBig(props) {
  const history =  useHistory();
  const dispatch = useDispatch();
  const sideBarMenuKey = useSelector(state => state.sidebarMenuProvider);
  const [hover, setHover] = useState(null);
  const Auth = useAuth();
  const openToast = useToast();
  
  const {
    classes,
    open,
    closeDrawer,
    dataMenu,
    drawerPaper,
    openSubMenu
  } = props;

  useEffect(() =>{
    activeMenu(sideBarMenuKey);
  }, [sideBarMenuKey]);

  const handleLogout = async () => {
    dispatch(tabMenuProvider(null));
    await Auth.logout();
    openToast('success', 'Logout feito com sucesso!')
  };

  const handleLoadPage = (link, menuIndex, key) => {
    const { loadTransition, toggleDrawerOpen } = props;
    dispatch(sidebarMenuProvider(key));
    toggleDrawerOpen();

      history.push(link);
      dispatch(tabMenuProvider(0));
      persistViewPageNavigation(null, null, null, null, null, null, null, null, null, null);
      persistTabMenu(" ");
 
    /*
    if(link === "/administracao/registro/empresa"){
      dispatch(tabMenuProvider(0));
      persistViewPageNavigation(null, null, null, null, null, null, null, null, null, null);
      persistRegister([0], true);
      if(window.location.pathname != '/administracao/registrar/todos'){
        history.push('/administracao/registrar/todos');
      }
    }
    if(link === "/administracao/registro/planos"){
      dispatch(tabMenuProvider(1));
      persistTabMenu(1);
      persistRegister([1], false);
      if(window.location.pathname != '/administracao/registrar/todos'){
        history.push('/administracao/registrar/todos');
      }
    }
    if(link === "/administracao/registro/serviço"){
      dispatch(tabMenuProvider(2));
      persistTabMenu(2);
      persistRegister([1, 2], false);
      if(window.location.pathname != '/administracao/registrar/todos'){
        history.push('/administracao/registrar/todos');
      }
    }
    if(link === "/administracao/registro/alvos"){
      dispatch(tabMenuProvider(3));
      persistRegister([1, 2, 3, 4], false);
      if(window.location.pathname != '/administracao/registrar/todos'){
        history.push('/administracao/registrar/todos');
      }
    }
    if(link === "/administracao/registro/usuário"){
      dispatch(tabMenuProvider(4));
      persistRegister([1, 2, 3], false);
      if(window.location.pathname != '/administracao/registrar/todos'){
        history.push('/administracao/registrar/todos');
      }
    }
    if(link === "/administracao/lista/usuárionucleo"){
      dispatch(tabMenuProvider(null));
      persistViewPageNavigation(null, null, null, null, null, null, null, null, null, null);
      persistTabMenu(" ");
      persistRegister([0], true);
      history.push('/administracao/lista/usuárionucleo');
    }
    /*
    if(link === "/administracao/registro/horaextra"){
      persistPlan(" ");
      persistTabMenu(" ");
    }/*
    if(menuIndex === 1) {
      persistTabMenu(1);
    }
    if(menuIndex === 2) {
      persistTabMenu(2);
    }*/
  };

  const handlePortalDashboard = () => {
    history.push('/portais');
    dispatch(tabMenuProvider(null));
  };
  
  const activeMenu = (key) => {
    if (sideBarMenuKey === key){
      return true;
    }
    else{
      return false;
    }
  };

  const handleActiveHover = (key) => {
    setHover(key);
  };

  const handleInactiveHover = () => {
    setHover(null);
  };


  //sidebar buttons list
  const getMenus = menuArray => menuArray.map((item, index) => {
    if (item.key === 'menu_levels') {
      return false;
    }
    if (item.child) {

      return (
        <ButtonBase
          key={index.toString()}
          focusRipple
          onClick={() => handleLoadPage(item.link, item.menuIndex, item.key)}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width:"80%",  overflow: 'visible',whiteSpace: 'pre-wrap',}}
        >
          <div className={classNames(classes.menuHeadIcon, activeMenu(item.key) ? classes.active : '')} onMouseEnter={() => handleActiveHover(item.key)} onMouseLeave={handleInactiveHover}>
            {hover === item.key && item.customIcon?
              <img src={item.customIconActive} className={classes.customIcon}></img>
              :
              item.customIcon? activeMenu(item.key)? <img src={item.customIconActive} className={classes.customIcon}></img> : <img src={item.customIcon} className={classes.customIcon}></img> : <Icon className={classNames(classes.icon, item.icon)} >{item.icon}</Icon> 
            }
          </div>
          <span className={classNames(classes.text, classes.menuHeadText, activeMenu(item.key) ? classes.active : '', hover === item.key? classes.active : '')} style={activeMenu(item.key) || hover === item.key? {color: "#FFB703", fontSize: '10px', fontFamily: ['Segoe UI', 'Roboto', 'sans-serif'], display: 'block', lineHeight: '20px', fontWeight: '700'} : {color: "#FFFFFF", fontSize: '10px', fontFamily: ['Segoe UI', 'Roboto', 'sans-serif'], display: 'block', lineHeight: '20px', fontWeight: '700'}}>
            { item.name }
          </span>
        </ButtonBase>
      );
    }
    return (
      <ButtonBase
        key={index.toString()}
        focusRipple
        className={classes.menuHead}
        component={LinkBtn}
        activeClassName={classes.active}
        to={item.linkParent}
      >
        <i className={classNames(classes.icon, item.icon)} />
        <span className={classes.text}>
          { item.name }
        </span>
      </ButtonBase>
    );
  });

  return (
    <div>
      <aside className={classes.bigSidebar}>
        <nav className={classes.category}>
          <div className={classNames(classes.bgMain, classes.fixedWrap)}>
            <div style={{width:"130px", height: "60px", justifyContent:"center", display:"flex", position:"fixed", top: 7}}>
              <svg width="56" height="56" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8367 2.47783L1 14.9901L11.1195 6.51724L8.88845 11.3448L13.6693 9.47291L8.88845 21L21 3.56158L13.1116 7.69951L17.1753 1L11.8367 2.47783Z" fill="#F1F1F1" stroke="black" stroke-opacity="0.11"/>
              </svg>
            </div> 
            <div style={{display:"flex", height: screen.height - 370, justifyContent:"flex-start", flexDirection:"column", alignItems:"center", position: "fixed", top: "110px", overflowY: "auto"}} className={classes.scrollBar}>
              {getMenus(dataMenu)}
            </div>
          </div>
        </nav>
        <div style={{position: "fixed", width: 130, display: "flex", justifyContent:"center", top: screen.height/1.33}}>
          <TooltipComponent text={`Realizar logout.`} position={'right'}>
            <IconButton className={classes.button}>
              <ImExit style={{width: "26px", height: "26px", cursor:"pointer", color:"white"}} onClick={() => handleLogout()}/>
            </IconButton>
          </TooltipComponent>
        </div>
      </aside>
    </div>
  );
}

MainMenuBig.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.array.isRequired,
  dataMenu: PropTypes.array.isRequired,
  openDrawer: PropTypes.func.isRequired,
  openSubMenu: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  toggleDrawerOpen: PropTypes.func,
};

MainMenuBig.defaultProps = {
  toggleDrawerOpen: () => {},
  mobile: false
};

const openAction = key => ({ type: 'OPEN_SUBMENU', key });

const mapStateToProps = state => ({
  open: state.ui.subMenuOpen
});

const mapDispatchToProps = dispatch => ({
  openDrawer: () => dispatch(openMenuAction),
  closeDrawer: () => dispatch(closeMenuAction),
  openSubMenu: bindActionCreators(openAction, dispatch)
});

const MainMenuBigMapped = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMenuBig);

export default withStyles(styles)(MainMenuBigMapped);
