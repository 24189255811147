const styles = (theme) => ({
    container: { width: '80%', margin: '25px auto' },

    tituloGrafico: {
        width: '100%',
        backgroundColor: '#8ECAE6',
        borderRadius: '7px 7px 0 0',
        fontSize: '12px',
        padding: '7px 0 ',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    tituloGraficoTexto: {
        margin: '0',
    },

    grafico: {
        width: '100%',
        borderRight: '1px solid #000',
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        fontSize: '12px',
        display: 'flex',
    },

    gráficoContainer: {},

    legendaLateral: {
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        textAlign: 'center',
        marginLeft: '5px',
    },

    legendaBaixo: {
        textAlign: 'center',
    },
});

export default styles;
