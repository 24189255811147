import styled, {keyframes} from 'styled-components';

const width = '280px'
const height= 124
const colors = {
  'liberacao_raio': 'rgba(39, 182, 36, 1)',
  'raio': 'rgba(176, 103, 233, 1)',
  'aviso_chuva': 'rgba(4, 109, 202, 1)'
}

const animationEnter = (id) => keyframes`
  0% { transform: translateY(-100%); opacity: 0;}
  100% { transform: translateY(${id*(height + 12)}); opacity: 1;}
`

export const SentNotifContainer = styled.div`
  position: absolute;
  left: ${props =>  props.showSidebar || props.width? props.width : 0};
  top: 0;
  z-index: 2500;
  width: ${width};
  display: flex;
`

export const Container = styled.div`
  --y-distance: ${props => props.id < 3
    ? `translateY(${props.id*(height+12)}px)`
    : `translateY(${3*(height+12)}px)`
  };
  --x-distance: ${props => props.close ? `translateX(100%)` : 'translateX(0)'};
  z-index: 2500;
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.4);
  width: ${width};
  height: ${height}px;
  background-color: ${props => colors[props.type]};
  animation: ${props => animationEnter(props.id)} 500ms 0s 1 ease-out;
  font-style: 'Roboto';
  font-size: 14px;
  transition: transform 500ms ease-out, opacity 500ms ease-in;
  transform: var(--y-distance) var(--x-distance);
  opacity: ${props => props.close ? '0' : '1'};
  color: ${props => props.type==='aviso_chuva' ? '#fff' : '#000'};

`
