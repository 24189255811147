/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import {KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt-BR';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';
import { LineContainer, ItemContainer } from '../../pages/MapsPortal/components/ForecastPanel/component/MonitoringForms/ReportCard/styled.js';

import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useToast } from 'Context/ToastContext';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import 'moment/locale/pt-br'
import LightningAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import FieldsComponent from "../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert/fieldsComponent";
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../services/mapsPortal/alerts"
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'
import { SiAlpinelinux } from "react-icons/si";

const useStyles = makeStyles({
    lightningAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#B067E9",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#B067E9'
      }
    },
    rainAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#046DCA",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#046DCA'
      }
    },
    meteorologicalAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    },
    releases: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#27B624",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#27B624'
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
  })
  

const AlertsElementReports = (props) => {
    const {classes, rightSideTabs, indexPage, company, value, setReloadRightSide, setAllValues, allValues, reportType} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const dividerStyle = useStyles();
    const openToast = useToast();
    const moment = require('moment');
    moment.locale('pt-br');
    var gridClasses = [
        classes.gridColumnsComponent,
        classes.gridMarginLeft,
    ]
    const [companyValues, setCompanyValues] = useState(null);
    const [caractersRemaning, setCaractersRemaning] = useState(0);
    const [reload, setReload] = useState(false);
    const [invitePrincipal, setInvitePrincipal] = useState(false);
    const { plans: planos, toShow } = useModal();
    const [selectedStartDateErrors, setSelectedStartDateErrors] = useState('');
    const [selectedEndDateErrors, setSelectedEndDateErrors] = useState('');
    const [postSwitchs, setPostSwitchs] = useState([]);
    const defaultProps = {
      options: ["T1", "T2", "T3", "T4"],
    };
    const [weatherCondition, setWeatherCondition] = useState([
      "Em branco",
      "Sem previsão de chuva",
      "Chuva no período da manhã",
      "Chuva no período da tarde",
      "Chuva no período da noite",
      "Chuva ao longo do dia",
      "Pancadas de chuva ao longo do dia"
  ]);
  const [windDirection, setWindDirection] = useState([
      'Em branco',
      'De Sul',
      'De Leste',
      'De Norte',
      'De Oeste',
      'De Sudeste',
      'De Sudoeste',
      'De Nordeste',
      'De Noroeste',
  ]); 
  const [windSprint, setWindSprint] = useState([
      'Em branco',
      'Sem informação',
      'Calmo, rajadas entre 0.01 km/h e 1.0 km/h',
      'Aragem, rajadas entre 1.01 km/h e 6.0 km/h ',
      'Brisa leve, rajadas entre 6.01 km/h e 12.0 km/h',
      'Brisa fraca, rajadas entre 12.01 km/h e 20.0 km/h',
      'Brisa moderada, rajadas entre 20.01 km/h e 29.0 km/h',
      'Brisa forte, rajadas entre 29.01 km/h e 39.0 km/h',
      'Vento fresco, rajadas entre 39.01 km/h e 50.0 km/h',
      'Vento forte, rajadas entre 50.01 km/h e 62.0 km/h',
      'Ventania, rajadas entre 62.01 km/h e 75.0 km/h',
      'Ventania forte, rajadas entre 75.01 km/h e 89.0 km/h',
      'Tempestade, rajadas entre 89.01 km/h e 103.0 km/h',
      'Tempestade violenta, rajadas entre 103.01 km/h e 117.0 km/h',
      'Tornado/Furacão, rajadas entre 117.01 km/h e 999.9 km/h',
  ]); 


    const tools = ["Descargas", "Radar", "Satélite", "TSC"]

    const checkVerify = (valueCheck)=>{
      let aux = ""
      companyValues.filter((value) => {
        value.includes(valueCheck)? 
          aux = value.split("_")[3] 
        : 
          aux = ""
      })
      return(aux)
    };

    const createReportCards = (quantidy) => {
      var reportCardPostData = [];
      var index = quantidy[1]? 0 : 1;
      var cicles = quantidy[1]? quantidy[0] - 1 : quantidy[0];

      if(typeof quantidy[0] === "number" && quantidy[0] > 0){
          planos?.map((company) => {
              company.planos.map((plan) => {
                  plan.alvos.map((target) => {
                      if (toShow.get(company.id, plan.id, target.id)){
                          for(let i = index; i <= cicles; i++){
                              let date = `${moment(companyValues?.startDate).format("YYYY-MM-DD")}T${companyValues?.shipment}`;
                              let formatedDate = moment(date).utcOffset('-0300').format();
                              let horizonMinutes = parseInt(companyValues?.horizonte.split(":")[1]) === 0? 60 : parseInt(companyValues?.horizonte.split(":")[1]);
                              let horizonMinutesFraction = 60/horizonMinutes;
                              let horizonMinutesDecimal = 1/horizonMinutesFraction;
                              let horizonDecimal = horizonMinutesDecimal != 1? parseInt(companyValues?.horizonte.split(":")[0]) + "." + JSON.stringify(horizonMinutesDecimal).split(".")[1] : parseInt(companyValues?.horizonte.split(":")[0]);
                              let horizonAdd = i * horizonDecimal;
                              let validityFat1 = moment(formatedDate).add(horizonAdd, 'hours').format("DD/MM/YYYY HH:mm:ss");
                              let validityFat2 = moment(formatedDate).add((i + 1) * horizonDecimal, 'hours').format("DD/MM/YYYY HH:mm:ss");
                              
                              const postData = {
                                  intensidade: null,
                                  nivel: null,
                                  probabilidade: null,
                                  temperatura_min: null,
                                  temperatura_max: null,
                                  condicao_tempo: null,
                                  acumulado: null,
                                  rajada_vento: null,
                                  direcao_vento: null,
                                  velocidade_vento: null,
                                  observacao: null,
                                  datahora_modificacao: null,
                                  campos_modificados: null,
                                  modificacao: null,
                                  conteudo_modificacao: null,
                                  nome_meteorologista: null,
                                  nome_empresa: company.nome,
                                  nome_plano: plan.nome,
                                  nome_alvo: target.nome,
                                  horizontes: horizonDecimal,
                                  vigencia: validityFat1.slice(6, 10) + "-" + validityFat1.slice(3, 5) + "-" + validityFat1.slice(0, 2) + "T" + validityFat1.slice(11, 19) + "+00:00"
                                  + " - " + validityFat2.slice(6, 10) + "-" + validityFat2.slice(3, 5) + "-" + validityFat2.slice(0, 2) + "T" + validityFat2.slice(11, 19) + "+00:00",
                                  status: 0,
                                  meteorologista: null,
                                  id_alvo: target.id_alvo,
                                  especial: true,
                              }

                              setAllValues(postData)
                          }
                      }
                  })
              })    
          })
      }
  }

    const defineNumberOfReportCards = () => {
      function reportCardCalc(starDate, endDate, missingHours) {
          const msPerDay = 1000 * 60 * 60 * 24;
          // Discard the time and time-zone information.
          const utc1 = Date.UTC(starDate.getFullYear(), starDate.getMonth(), starDate.getDate());
          const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
          let now = moment();
          let timeDiff = moment(now).endOf('day') - now;
          let duration = moment.duration(timeDiff);
          let durationMinutes = duration._data.minutes;
          let durationMinutesFraction = 60/durationMinutes;
          let durationMinutesDecimal = 1/durationMinutesFraction;
          let formatedDuration = durationMinutesDecimal != 1? duration._data.hours + "." + JSON.stringify(durationMinutesDecimal).split(".")[1] : duration._data.hours;
          const differenceInHours = Math.floor((utc2 - utc1)) === 0? formatedDuration : ((Math.floor((utc2 - utc1) / msPerDay)) * 24);
          let horizonMinutes = parseInt(companyValues?.horizonte.split(":")[1]) === 0? 60 : parseInt(companyValues?.horizonte.split(":")[1]);
          let horizonMinutesFraction = 60/horizonMinutes;
          let horizonMinutesDecimal = 1/horizonMinutesFraction;
          let horizonDecimal = horizonMinutesDecimal != 1? parseInt(companyValues?.horizonte.split(":")[0]) + "." + JSON.stringify(horizonMinutesDecimal).split(".")[1] : parseInt(companyValues?.horizonte.split(":")[0]);
          let numberOfReportCards = (differenceInHours / parseFloat(horizonDecimal));
          let numberOfReportCardsAvailable = missingHours === true? numberOfReportCards < 1? 1 : numberOfReportCards : numberOfReportCards - 1;
                
          return [Math.floor(numberOfReportCardsAvailable, -10), missingHours]
      }
        
      const starDate = new Date(`${parseInt(moment(companyValues?.startDate).format('YYYY'))}-${parseInt(moment(companyValues?.startDate).format('MM'))}-${parseInt(moment(companyValues?.startDate).format('DD'))}`);
      const endDate = new Date(`${parseInt(moment(companyValues?.endDate).format('YYYY'))}-${parseInt(moment(companyValues?.endDate).format('MM'))}-${parseInt(moment(companyValues?.endDate).format('DD'))}`);
      const missingHours = moment(companyValues?.shipment, "HH:mm").isAfter(moment())? true : false;
      
      if(companyValues?.horizonte === '' || companyValues?.horizonte != moment(companyValues?.horizonte, "HH:mm").format("HH:mm")){
          openToast('error', 'O campo Horizonte deve ser preenchido corretamente antes de continuar!');
      }
      else if(companyValues?.shipment === '' || companyValues?.shipment != moment(companyValues?.shipment, "HH:mm").format("HH:mm")){
          openToast('error', 'O campo 1º envio deve ser preenchido corretamente antes de continuar!');
      }
      else if(selectedStartDateErrors){
          openToast('error', selectedStartDateErrors); 
      }
      else if(selectedEndDateErrors){
          openToast('error', selectedEndDateErrors); 
      }
      else{
         createReportCards(reportCardCalc(starDate, endDate, missingHours));
      }
  }
    
    useEffect(() => {
      setReload(false);
    }, [reload])

    console.log(value)
    const initialSets = () => {
      let aux = []
      aux = {
        acumulado: value.acumulado, 
        alvo: value.alvo, 
        condicao_tempo: value.condicao_tempo!="null"? value.condicao_tempo : "Em branco", 
        direcao_vento: value.direcao_vento, 
        empresa: value.empresa, 
        id_og: value.id, 
        intensidade: value.intensidade, 
        nivel: value.nivel!="null"? value.nivel : "Em branco", 
        observacao: value.obs!="null"? value.obs : "", 
        plano: value.planos, 
        probabilidade: value.probabilidade!="null"? value.probabilidade : "Em branco", 
        rajada_vento: value.rajada_vento, 
        temperatura_max: value.temperatura_max, 
        temperatura_min: value.temperatura_min, 
        status: value.status, 
        errata: value.errata, 
        nome_meteorologista: value.nome_meteorologista,
        meteorologista: value.meteorologista,
        campos_modificados: [], 
        velocidade_vento: value.velocidade_vento,
        especial: value.especial,
        vigencia: value.vigencia,
        endDate: null,
        startDate: null, 
        shipment: null, 
        horizontes: value.horizontes,
      }
      setCompanyValues(aux)
    };

    console.log(companyValues)
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
      setAllValues({
        acumulado: companyValues.acumulado, 
        alvo: companyValues.alvo, 
        condicao_tempo: companyValues.condicao_tempo, 
        direcao_vento: companyValues.direcao_vento, 
        empresa: companyValues.empresa, 
        id_og: companyValues.id_og, 
        intensidade: companyValues.intensidade, 
        nivel: companyValues.nivel, 
        observacao: companyValues.observacao, 
        plano: companyValues.plano, 
        probabilidade: companyValues.probabilidade, 
        rajada_vento: companyValues.rajada_vento, 
        temperatura_max: companyValues.temperatura_max, 
        temperatura_min: companyValues.temperatura_min, 
        errata: companyValues.errata, 
        nome_meteorologista: companyValues.nome_meteorologista,
        meteorologista: companyValues.meteorologista,
        campos_modificados: companyValues.campos_modificados, 
        velocidade_vento: companyValues.velocidade_vento,
        status: companyValues.status,
        vigencia: companyValues.vigencia,
        especial: companyValues.especial,
        endDate: companyValues.endDate,
        startDate: companyValues.startDate, 
        shipment: companyValues.shipment, 
        horizontes: companyValues.horizontes,
      });
      setChecked(!checked);
      setReloadRightSide(true);
    };

    const modifyAllValues = () => {
      setAllValues(companyValues);
      setReloadRightSide(true);
    };
    
    const getPostSwitchs = (element) => {
      const finder = postSwitchs.find((filteredButton) => filteredButton == element);
      let aux = false
      if(finder === undefined || finder == ""){
        aux = false
      }else{
        aux = true
      }
      return aux
    };
    
    useEffect(() => {
      initialSets();
    }, [])

    const addModifiedValue = (input, newValue) =>{
      let aux = companyValues

      if(aux.campos_modificados.length > 0){
        if(!aux.campos_modificados.includes(input)){
          aux.campos_modificados.push(input)
        }
      }else{
        aux.campos_modificados.push(input)
      }

      if(input=="temperatura_min"){
        aux.temperatura_min = newValue
      }
      if(input=="temperatura_max"){
        aux.temperatura_max = newValue
      }
      if(input=="nivel"){
        aux.nivel = newValue
      }
      if(input=="condicao_tempo"){
        aux.condicao_tempo = newValue
      }
      if(input=="intensidade"){
        aux.intensidade = newValue
      }
      if(input=="acumulado"){
        aux.acumulado = newValue
      }
      if(input=="probabilidade"){
        aux.probabilidade = newValue
      }
      if(input=="rajada_vento"){
        aux.rajada_vento = newValue
      }
      if(input=="direcao_vento"){
        aux.direcao_vento = newValue
      }
      if(input=="velocidade_vento"){
        aux.velocidade_vento = newValue
      }
      if(input=="observacao"){
        aux.observacao = newValue
      }
      if(input=="horizonte"){
        aux.horizonte = newValue=="12 horas"? "12" : newValue=="24 horas"? "24" : newValue=="48 horas"? "48" : newValue=="72 horas"? "72" : "0"
      }
      if(input=="status"){
        aux.status = newValue=="Não aprovados"? "0" : "1"
      }
      if(input=="endDate"){
        aux.endDate = newValue
      }
      if(input=="startDate"){
        aux.startDate = newValue
      }
      if(input=="shipment"){
        aux.shipment = newValue
      }
      if(input=="horizontes"){
        aux.horizontes = newValue
      }



      setCompanyValues(aux);
      setReload(true);
      modifyAllValues();
    };
console.log(companyValues?.startDate!=null? new Date(companyValues?.startDate.split('/').reverse().join('-')) : "")
    if(companyValues!=null) {
      if(reportType=="Daily" || reportType=="Nowcasting"){
        return (
            <>
              <div className={classes.container}>
                <div className={classes.allChangeContainer}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft:"20px", marginBottom:"10px"}}>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        size="small"
                        color={"primary"} />
                      <div style={{ marginLeft: "15px" }}>
                        <Typography variant="subtitle1">
                        {value.empresa+" - "+value.alvo}
                        </Typography>
                      </div>
                  </div>
                  <div className={classes.inputLine}>
                      <LineContainer
                          style={{
                              width: '100%',
                              margin: '0',
                              height: 'fit-content',
                          }}
                      >
                          <Autocomplete
                              id="condition"
                              freeSolo
                              value={companyValues?.condicao_tempo}
                              inputValue={companyValues?.condicao_tempo}
                              disableClearable
                              onChange={(_, newValue) => addModifiedValue("condicao_tempo", newValue)}
                              className={classes.inputOne}
                              style={fonts.formsFieldHeight1}
                              size="small"
                              options={weatherCondition}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  label="Condição do tempo"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                      event.stopPropagation();
                                    }
                                  }} />}
                          />

                          <Autocomplete
                              id="intensity"
                              freeSolo={false}
                              value={companyValues?.intensidade}
                              inputValue={companyValues?.intensidade}
                              disableClearable
                              onChange={(_, newValue) => addModifiedValue("intensidade", newValue)}
                              className={classes.inputTwo}
                              size="small"
                              options={[
                                  'Em branco',
                                  'Fraca',
                                  'Fraca a moderada',
                                  'Moderada',
                                  'Moderada a forte',
                                  'Forte',
                                  'Muito forte',
                                  'Extrema',
                              ]}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      label="Intensidade da chuva"
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      onKeyDown={(event) => {
                                          if (event.key === 'Backspace') {
                                              event.stopPropagation();
                                          }
                                      }}
                                  />
                              )}
                          />
                      </LineContainer>
                  </div>
                  <div className={classes.inputLine}>
                      <InputMask
                          mask="999 - 999 mm"
                          value={companyValues?.acumulado}
                          onChange={(e) => addModifiedValue("acumulado", e.target.value)}
                          disabled={false}
                          maskChar=" "
                      >
                          {() => (
                              <TextField
                                  className={classes.inputThree}
                                  id="acumuladoInit"
                                  variant="outlined"
                                  label="Acumulado"
                                  inputProps={{
                                      style: fonts.formsFieldHeight1,
                                  }}
                                  InputLabelProps={{
                                      style: fonts.formsLabel,
                                      shrink: true,
                                  }}
                              />
                          )}
                      </InputMask>

                      <Autocomplete
                          id="lightning_prob"
                          freeSolo={false}
                          value={companyValues?.probabilidade}
                          inputValue={companyValues?.probabilidade}
                          disableClearable
                          onChange={(_, newValue) => addModifiedValue("probabilidade", newValue)}
                          className={classes.inputFour}
                          style={fonts.formsFieldHeight1}
                          size="small"
                          options={['Em branco', 'Sem tempestades', 'Baixa', 'Média', 'Alta']}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Prob. Tempestade"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  onKeyDown={(event) => {
                                      if (event.key === 'Backspace') {
                                          event.stopPropagation();
                                      }
                                  }}
                              />
                          )}
                      />

                      <Autocomplete
                          id="level"
                          freeSolo={false}
                          value={companyValues?.nivel}
                          inputValue={companyValues?.nivel}
                          disableClearable
                          onChange={(_, newValue) => addModifiedValue("nivel", newValue)}
                          className={classes.inputFive}
                          style={fonts.formsFieldHeight1}
                          size="small"
                          options={['T1', 'T2', 'T3', 'T4', 'Em branco']}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Nível"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  onKeyDown={(event) => {
                                      if (event.key === 'Backspace') {
                                          event.stopPropagation();
                                      }
                                  }}
                              />
                          )}
                      />

                      <TextField
                          id="temp_min"
                          className={classes.inputSix}
                          value={companyValues?.temperatura_min}
                          onChange={(e) => addModifiedValue("temperatura_min", e.target.value)}
                          variant="outlined"
                          type="tel"
                          label="Temp. Mínima"
                          inputProps={{ style: fonts.formsFieldHeight1 }}
                          InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                          InputProps={{
                              endAdornment: (
                                  <InputAdornment
                                      position="end"
                                      style={{ alignSelf: 'end' }}
                                  >
                                      °C
                                  </InputAdornment>
                              ),
                          }}
                      />

                      <TextField
                          id="temperatura_max"
                          className={classes.inputSeven}
                          variant="outlined"
                          value={companyValues?.temperatura_max}
                          onChange={(e) => addModifiedValue("temperatura_max", e.target.value)}
                          label="Temp. Máxima"
                          type="tel"
                          inputProps={{ style: fonts.formsFieldHeight1 }}
                          InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                          InputProps={{
                              endAdornment: (
                                  <InputAdornment
                                      position="end"
                                      style={{ alignSelf: 'end' }}
                                  >
                                      °C
                                  </InputAdornment>
                              ),
                          }}
                      />  
                  </div>
                  <div className={classes.inputLine}>
                      <Autocomplete
                          id="wind_sprint"
                          freeSolo
                          value={companyValues?.rajada_vento}
                          inputValue={companyValues?.rajada_vento}
                          disableClearable
                          onInputChange={(_, newValue) => addModifiedValue("rajada_vento", newValue)}
                          onChange={(_, newValue) => addModifiedValue("rajada_vento", newValue)}
                          className={classes.inputEight}
                          style={fonts.formsFieldHeight1}
                          size="small"
                          options={windSprint}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label="Rajada de vento"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                  event.stopPropagation();
                                }
                              }} />}
                      />

                      <InputMask
                          mask="999"
                          value={companyValues?.velocidade_vento}
                          onChange={(e) => addModifiedValue("velocidade_vento", e.target.value)}
                          disabled={false}
                          maskChar=" "
                      >
                          {() => (
                              <TextField
                                  id="velocidade_vento"
                                  className={classes.inputNine}
                                  variant="outlined"
                                  label="Vel. do vento"
                                  inputProps={{ style: fonts.formsFieldHeight1 }}
                                  InputLabelProps={{
                                      style: fonts.formsLabel,
                                      shrink: true,
                                  }}
                              />
                          )}
                      </InputMask>

                      <Autocomplete
                          id="wind"
                          freeSolo
                          value={companyValues?.direcao_vento}
                          inputValue={companyValues?.direcao_vento}
                          disableClearable
                          onInputChange={(_, newValue) => addModifiedValue("direcao_vento", newValue)}
                          onChange={(_, newValue) => addModifiedValue("direcao_vento", newValue)}
                          className={classes.inputTen}
                          style={fonts.formsFieldHeight1}
                          size="small"
                          options={windDirection}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label="Direção do vento"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onKeyDown={(event) => {
                                if (event.key === 'Backspace') {
                                  event.stopPropagation();
                                }
                              }} />}
                      />
                  </div>   
                  <div className={classes.inputLine}>
                      <TextField
                          id="comments"
                          label="Observações"
                          multiline
                          rows={2}
                          value={companyValues?.observacao}
                          onChange={(_, newValue) => addModifiedValue("observacao", newValue)}
                          className={classes.inputElevenMargin}
                          shrink={true}
                          variant="outlined"
                          color="primary"
                          inputProps={{
                              style: fonts.formsFieldHeight2,
                              maxLength: 200,
                          }}
                          InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                          InputProps={{
                              endAdornment:
                                <span className={classes.endAdornment}>{caractersRemaning + "/200"}</span>
                          }}
                      />
                  </div>
                  <Divider
                    classes={{ root: dividerStyle.divider }}
                    style={{ margin: '0', padding: '0' }}
                  />
                  <div className={classes.inputLine}>
                      <Autocomplete
                          id="Horizontes"
                          freeSolo={false}
                          value={companyValues?.horizontes}
                          inputValue={companyValues?.horizontes}
                          disableClearable
                          onChange={(_, newValue) => addModifiedValue("horizontes", newValue)}
                          style={{marginTop: "25px"}}
                          className={classes.inputFour}
                          size="small"
                          options={moment().isBefore(moment("16:00", "HH:mm"))?
                              ["24 horas", "48 horas", "72 horas"] : ["12 horas"]}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Horizonte"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  onKeyDown={(event) => {
                                      if (event.key === 'Backspace') {
                                          event.stopPropagation();
                                      }
                                  }}
                              />
                          )}
                      />

                      <Autocomplete
                          id="Status"
                          freeSolo={false}
                          value={companyValues?.status=="0"? "Não aprovados" : "Aprovados"}
                          inputValue={companyValues?.status=="0"? "Não aprovados" : "Aprovados"}
                          disableClearable
                          onChange={(_, newValue) => addModifiedValue("status", newValue)}
                          style={{marginTop: "25px"}}
                          className={classes.inputFour}
                          size="small"
                          options={["Não aprovados", "Aprovados"]}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Status"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  onKeyDown={(event) => {
                                      if (event.key === 'Backspace') {
                                          event.stopPropagation();
                                      }
                                  }}
                              />
                          )}
                      />
                  </div>
                </div>
                <Grid container spacing={1} direction="row" justifyContent="flex-start" style={{ marginTop: "45px", marginBottom:"20px" }}>
                    <Grid item xs={12}>
                        <Divider classes={{ root: dividerStyle.divider }} />
                    </Grid>
                </Grid>
              </div>
            </>
        )
      }else if(reportType=="Especial"){
        return(
          <div className={classes.container}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft:"20px"}}>
              <Switch
                  checked={checked}
                  onChange={handleChange}
                  size="small"
                  color={"primary"} />
                <div style={{ marginLeft: "15px" }}>
                  <Typography variant="subtitle1">
                  {value.empresa+" - "+value.alvo}
                  </Typography>
                </div>
            </div>
            <div className={classes.allChangeContainer}>
                <div className={classes.inputLine} style={{}}>
                    <LineContainer
                        style={{
                            width: '100%',
                            margin: '0',
                            height: "80px", display: "flex", align: "center"
                        }}
                    >
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                        >
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              value={companyValues?.startDate!=null? new Date(companyValues?.startDate.split('/').reverse().join('-')).toLocaleString().toString() : new Date()}
                              onChange={(_, newValue) => addModifiedValue("startDate", newValue)}
                              animateYearScrolling={false}
                              label="Data inicial"
                              className={classes.dateField}
                              inputProps={{
                                  style: fonts.formsField,
                              }}
                              InputLabelProps={{
                                  style: fonts.formsLabel,
                              }}
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                        >
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              value={companyValues.endDate!=null? new Date(companyValues?.endDate.split('/').reverse().join('-')).toString() : moment().add(7, 'days').toDate()}
                              onChange={(_, newValue) => addModifiedValue("endDate", newValue)}
                              animateYearScrolling={false}
                              label="Data final"
                              margin="none"
                              className={classes.dateField}
                              inputProps={{
                                  style: fonts.formsField,
                              }}
                              InputLabelProps={{
                                  style: fonts.formsLabel,
                              }}
                            />
                        </MuiPickersUtilsProvider>

                        <InputMask
                            mask="99:99"
                            value={companyValues?.horizonte}
                            onChange={(e) => addModifiedValue("horizonte", e.target.value)}
                            disabled={false}
                            maskChar=" "
                        >{() =>
                            <TextField
                              id="Horizonte"
                              className={classes.selectFieldSmall}
                              margin="normal"
                              variant="outlined"
                              label="Horizonte"
                              style={{display: "flex", marginTop: "18px"}}
                              inputProps={{style: fonts.formsField}}
                              InputLabelProps={{style: fonts.formsLabel, shrink: true}}
                            />}
                        </InputMask>

                        <InputMask
                            mask="99:99"
                            value={companyValues?.shipment}
                            onChange={(e) => addModifiedValue("shipment", e.target.value)}
                            disabled={false}
                            maskChar=" "
                        >{() =>
                            <TextField
                              id="1º envio"
                              className={classes.selectFieldSmall}
                              margin="normal"
                              variant="outlined"
                              label="1º envio"
                              style={{display: "flex", marginTop: "18px"}}
                              inputProps={{style: fonts.formsField}}
                              InputLabelProps={{style: fonts.formsLabel, shrink: true}}
                            />}
                        </InputMask>

                        <Button
                            style={fonts.buttonDefault}
                            variant="contained"
                            className={classes.reportButtonSpecial}
                            onClick={() => defineNumberOfReportCards()}
                        >
                            GERAR BOLETINS
                        </Button>
                    </LineContainer>
                </div>

                <Divider
                    classes={{ root: dividerStyle.divider }}
                    style={{ marginTop: '30px', padding: '0' }}
                />

                <Autocomplete
                        id="Status"
                        freeSolo={false}
                        value={companyValues?.status=="0"? "Não aprovados" : "Aprovados"}
                        inputValue={companyValues?.status=="0"? "Não aprovados" : "Aprovados"}
                        disableClearable
                        onChange={(_, newValue) => addModifiedValue("status", newValue)}
                        style={{marginTop: "25px"}}
                        className={classes.inputFour}
                        size="small"
                        options={["Não aprovados", "Aprovados"]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Status"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                            />
                        )}
                    />
              </div>
            </div>
          )
      }
    
  }







};

AlertsElementReports.propTypes = {
    classes: PropTypes.object.isRequired,
    setReloadRightSide: PropTypes.func,
    reportType: PropTypes.string,
    setAllValues: PropTypes.func,
    allValues: PropTypes.array.isRequired,
    indexPage: PropTypes.any,
    company: PropTypes.object.isRequired,
    value: PropTypes.any,
    rightSideTabs: PropTypes.number.isRequired,
  };
  
  export default withStyles(styles)(AlertsElementReports);