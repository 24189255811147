export default function (state = null, action){
    switch(action.type){
        case "REPORT_CARD_CONTEXT":
            return action.payload
            
    default: return state
    }
}




