import { djangoApi } from '../api';


export const getPlan_TargetByPlanID = async (id_plano) => {
    try {
        const response = await djangoApi.get(`planosalvos/?id_plano=${id_plano}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getPlan_TargetByTargetID = async (id_alvo) => {
    try {
        const response = await djangoApi.get(`planosalvos/?id_alvo=${id_alvo}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const postPlanTarget = (planTargetData) => {
    return djangoApi.post(`planosalvos/`, planTargetData);
}


