import { lambdaApi } from 'services/api';

export const getMeteograms = async (id_alvo, data_rodagem) => {
    try {
        const response = await lambdaApi.get(
            `meteograma?id_alvo=${id_alvo}&data_rodagem=${data_rodagem}`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching meteograms:', error);
        return [];
    }
};
