const styles = theme => ({

/* General */
textFieldSmallYellowAlert: {
    width: `75px`,
},

textFieldSmall: {
  width: `75px`,
},

textFieldMedium: {
    width: `80px`,
},

textFieldYellowAlert: {
    width: `100%`,
    fontSize: '25px',
    '@media (max-width: 400px)': {
        width: '230px',
    },
},

selectFieldExtraLarge: {
  width: `200px`,
  marginTop: `10px`,
},

textFieldDefault: {
  width: `316px`,
  fontSize: '25px',
  '@media (max-width: 400px)': {
      width: '230px',
  },
},

textFieldComments: {
  width: `100%`,
  fontSize: '25px',
  marginTop: '0px',
},

modalComponent: {
    width: "100vw",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
},

dateHourContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent:"center"
},
dialogContentTextContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent:"center",
    flexDirection: "column"
},
dialogContentTextContainerYellowAlert: {
  display: "flex",
  alignItems: "center",
  justifyContent:"flex-start",
  flexDirection: "row"
},
dateHourContainerStart: {
    display: "flex",
    alignItems: "center",
},

hourText: {
    marginLeft: `7px`,
    marginRight: `7px`,
},

errorMessage: {
    fontSize: `12px`,
    color: "red",
},

errorMessageMgLf: {
    fontSize: `12px`,
    color: "red",
    marginLeft: `10px`,
},

errorContainer: {
    display: "flex",
    justifyContent:"center"
},

toggleGroup: {
    marginTop: `10px`,
},

buttonContainer: {
    marginRight: `18px`,
    marginBottom: `18px`,
},

balloonContainer: {
    padding: `20px`,
    border: `1px solid`,
    borderColor: theme.palette.menu.mediumGrey,
    backgroundColor: "white",
    borderRadius: `45px`,
    position: 'absolute',
    width: '220px',
    height: '165px',
    display: "flex",
    flexDirection: "column",
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
},

ballonDetail: {
    content: '""',
    width: `25px`,
    height: `25px`,
    display: "block",
    position: 'absolute',
    left: `80px`,
    border: `1px solid`,
    borderColor: `transparent ${theme.palette.menu.mediumGrey} ${theme.palette.menu.mediumGrey} transparent`,
    backgroundColor: 'white',
    transform: `rotate(45deg)`,
},

textContainer: {
    alignSelf: "center",
},

tableIcon: {
    color: "black",
    padding: "2px",
},

servicesTitleDiv:{
    width: "100%",
    height: "14%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    borderBottom: "1px solid #848484",
    boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.15)",
},

servicesBottomDiv:{
    width: "100%",
    height: "14%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    borderTop: "1px solid #848484",
    boxShadow: "4px 0px 6px 3px rgba(0, 0, 0, 0.15)",
},

servicesTitleDivBigger:{
    width: "100%",
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
},

servicesTitle:{
   marginLeft: "5%",
},

outlineButtonText: {
    color: theme.palette.text.strongBlue,
    textTransform: "uppercase",
},

outlineButtonIcon: {
    color: theme.palette.text.strongBlue,
},

outlineButton:{
  cursor: "pointer",
  border: "2px solid",
  borderColor: theme.palette.menu.strongBlue,
  borderRadius: "5px",
  padding: "6px 14px 6px 14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "48px",
  '& $outlineButtonText': {
    color: theme.palette.menu.strongBlue,
  },
  '& $outlineButtonIcon': {
    color: theme.palette.menu.strongBlue,
  },
  '&:hover': {
    background: theme.palette.menu.strongBlue,
    '& $outlineButtonText': {
      color: theme.palette.menu.white,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.white,
    },
  },
},

outlineButton1:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    fontFamily: "Segoe UI",
    fontWeight: "700",
    fontSize: "14px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: theme.palette.menu.strongBlue,
      color: "white",
      '& $outlineButtonText': {
        color: "white",
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },
  
  outlineButton2:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: "#008000",
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: theme.palette.menu.strongBlue,
      '& $outlineButtonText': {
        color: theme.palette.menu.white,
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },

  outlineButton3:{
    cursor: "pointer",
    border: "2px solid",
    backgroundColor:"#DF7600",
    borderColor: "#DF7600",
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: "#AD5C00",
      borderColor: "#AD5C00",
      '& $outlineButtonText': {
        color: theme.palette.menu.white,
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },

  outlineButton4:{
    cursor: "pointer",
    border: "2px solid",
    backgroundColor:"#023047",
    color: "white",
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      fontFamily: "Segoe UI",
      color: "white",
      fontWeight: "700",
      fontSize: "14px",
    },
    '& $outlineButtonIcon': {
      color: "white",
    },
  },

  button5:{
    cursor: "pointer",
    border: "2px solid",
    backgroundColor:"#023047",
    color: "white",
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    '& $outlineButtonText': {
      fontFamily: "Segoe UI",
      color: "white",
      fontWeight: "700",
      fontSize: "14px",
    },
    '& $outlineButtonIcon': {
      color: "white",
    },
    '&:hover': {
      background: "#136994",
      borderColor: "#136994",
      color: "white",
      '& $outlineButtonText': {
        color: theme.palette.menu.white,
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },

  outlineButton5:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#023047",
    fontSize: "14px",
    fontFamily: "Segoe UI",
    fontWeight: "700",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: theme.palette.menu.strongBlue,
      color:"white",
      '& $outlineButtonText': {
        color: "white",
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },

  outlineButton6:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor:"#FF0000",
    fontSize: "14px",
    fontFamily: "Segoe UI",
    fontWeight: "700",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: "white",
      color:"#FF0000",
      '& $outlineButtonText': {
        color: "white",
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },


  outlineButton7:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.strongBlue,
    borderRadius: "5px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.menu.strongBlue,
    backgroundColor:"#68CC66",
    fontSize: "14px",
    fontFamily: "Segoe UI",
    fontWeight: "700",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: theme.palette.menu.strongBlue,
      color:"white",
      '& $outlineButtonText': {
        color: "white",
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },
  
  textButton:{
    fontFamily: "Segoe UI",
    color: "white",
    fontWeight: "700",
    fontSize: "14px",
    '&:hover': {
      color: "#FFB703",
    },
  },

  textButton2:{
    fontFamily: "Segoe UI",
    color: "black",
    fontWeight: "700",
    fontSize: "14px",
    '&:hover': {
      color: "white",
    },
  },

  textButtonCancel:{
    fontFamily: "Segoe UI",
    color: "black",
    fontWeight: "700",
    fontSize: "14px",
    '&:hover': {
      color: "white",
    },
  },

  noBorderButton:{
    cursor: "pointer",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: "gray",
      borderRadius:"40px",
      '& $outlineButtonText': {
        color: theme.palette.menu.white,
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },

  noBorderButton2:{
    cursor: "pointer",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      color: theme.palette.menu.strongBlue,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.strongBlue,
    },
    '&:hover': {
      background: "gray",
      borderRadius:"0px",
      '& $outlineButtonText': {
        color: theme.palette.menu.white,
      },
      '& $outlineButtonIcon': {
        color: theme.palette.menu.white,
      },
    },
  },

tooltip: {
    // position: 'absolute',
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "10px",
    borderRadius: "4px",
    zIndex: 1,
    minWidth: "200px",
    border: "1px solid red"
},

/*tooltipContain: {
    position: "relative",
    display: "inline-block",
},*/

registerContainer: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '15px',
},
checkboxModal:{
  marginLeft:"5px",
  width:"200px",


},
registerContentContainer: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
},

textDown: { 
  marginTop:"27px",
},

checkboxModalDown:{
  marginLeft:"5px",
  width:"200px",
  marginTop:"27px",
},

checkboxModalDown2:{
  marginLeft:"5px",
  width:"200px",
  marginTop:"10px",
},

textFieldNormal: {
  minWidth:"200px",
  maxWidth:"250px",
  maxHeigth:"80px",


},

comportableSelects: {
  marginBottom:"100px"
  
},

registerItemContainer: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginRight: '5%',
  minHeight: '100px',
  marginTop: '1%',
  '@media (max-width: 400px)': {
      minHeight: '50px',
  },
},


greenChip: {
  backgroundColor: '#67E16480',
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

greenChipS: {
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

redChip: {
  backgroundColor: '#FF000080',
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

redChipS: {
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

yellowChip: {
  backgroundColor: '#FFB703B2',
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

yellowChipS: {
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

greyChip: {
  backgroundColor: '#E3E3E3',
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

greyChipS: {
  color: '#023047',
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px, 15px, 8px, 15px',
  fontSize: '12px',
},

iconContainer: {
  marginLeft: '8px',
  width: "22px",
  height: "22px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
},

buttonLightning: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "11px 11px",
  gap: "5px",
  backgroundColor: "#B067E9",
  borderRadius: "5px",
  color: "#FFFFFF",
},

buttonNewspaper: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "11px 11px",
  gap: "5px",
  backgroundColor: "#F0D138",
  borderRadius: "5px",
  color: "#FFFFFF",
},

buttonRain: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "11px 11px",
  gap: "10px",
  backgroundColor: "#046DCA",
  borderRadius: "5px",
  color: "#FFFFFF",
},

buttonRelease: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "11px 11px",
  gap: "10px",
  backgroundColor: "#27B624",
  borderRadius: "5px",
  color: "#FFFFFF",
},

buttonMeteorologicAlert: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "11px 11px",
  gap: "10px",
  backgroundColor: "#B6888F",
  borderRadius: "5px",
  color: "#FFFFFF",
},

buttonBottom:{
    cursor: "pointer",
    width: '35px',
    height: '35px',
    borderRadius: '5px',
    borderColor:"#023047",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    padding: "8px, 15px, 8px, 15px",
    border: "2px solid",
    marginRight: "-3px"
},

selectTargetsBox: {
  marginBottom:"15px",
},


container: {
  width: '100%',
},

allChangeContainer: {
  backgroundColor: '#f5f2f2',
  width: '100%',
  padding: "8px 8px 18px 8px",
  borderRadius: "8px"
},

inputLine: {
  display: 'flex',
  width: '100%',
  height: '50px',
  marginBottom: '5px'
},

inputOne: {
  width: '70%',
  marginRight: '10px',
},

inputTwo: {
  width: '30%',
},

inputThree: {
  width: '22%',
  marginRight: '10px',
},

inputFour: {
  width: '27%',
  marginRight: '10px',
},

inputFive: {
  width: '15%',
  marginRight: '10px',
},

inputSix: {
  width: '18%',
  marginRight: '10px',
},

inputSeven: {
  width: '18%',
},

inputEight: {
  width: '65%',
  marginRight: '10px',
},

inputNine: {
  width: '15%',
  marginRight: '10px',
},

inputTen: {
  width: '20%',
},

inputEleven: {
  width: '100%',
},

inputElevenMargin: {
  width: '100%',
  marginRight: '10px',
},


reportButtonSpecial: {
  display: 'flex',
  flexDirection: 'row',
  height: "40px",
  alignItems: 'center',
  gap: '10px',
  backgroundColor: '#C0A514',
  borderRadius: '5px',
  color: '#FFFFFF',
  marginTop: "20px"
},


});

export default styles;