import { djangoApi } from '../api';

export const getEmployees_TargetByEmployeeID = async (id_employees) => {
    try {
        const response = await djangoApi.get(`usuariosalvos/?id_usuario=${id_employees}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getEmployees_Target = async () => {
    try {
        const response = await djangoApi.get(`usuariosalvos/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getEmployees_TargetByTargetID = async (id_target) => {
    try {
        const response = await djangoApi.get(`usuariosalvos/?id_alvo=${id_target}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const postEmployees_Target = (employeesTargetData) => {
    return djangoApi.post(`usuariosalvos/`, employeesTargetData);
};

export const updateEmployees_Target = (id_employeesTarget, employeesTargetData) => {
    return djangoApi.put(`usuariosalvos/${id_employeesTarget}/`, employeesTargetData);
};

export const patchEmployees_Targets = (id_employeesTarget, employeesTargetData) => {
    return djangoApi.put(`usuariosalvos/${id_employeesTarget}/`, employeesTargetData);
};

export const getEmployeesInfoByEmployeeID = async (id_employee) => {
    try {
        const response = await djangoApi.get(`usuarios_infos/?id_user=${id_employee}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const deleteEmployees_Targets = async (id_employeesTargets) => {
    try {
        const response = await djangoApi.delete(`usuariosalvos/${id_employeesTargets}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
};
