/* Libraries */
import React, {useEffect, useState, useRef} from 'react'
import P from 'prop-types';
import {Head, Title, BigTab} from '../styled'
import { useDispatch } from 'react-redux';

/* Components */
import ComplexTable from 'containers/Pages/Table';
import { useToast } from 'Context/ToastContext';
import { PapperBlock } from 'components';
import {MdClose} from 'react-icons/md';
import {Row, Column} from 'components/Flex'
import ModalReports from "components/Modal/ModalReports";
import ModalTargetsView from "components/Modal/ModalTargetsView";

/* CSS */
import styles from './reports-jss';
import fontStyles from 'styles/fonts-jss';

/* Material UI */
import { withStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Box  from '@material-ui/core/Box';
import Button  from '@material-ui/core/Button';
import Typography  from '@material-ui/core/Typography';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { MdOutlineTaskAlt, MdOutlineUnpublished, MdWarningAmber } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
/* Services */
import {getPlansInfo} from 'services/mapsPortal/alerts'

import useLoad from 'hooks/useLoad'

/* Utils */ 
import { persistViewPageNavigation } from 'utils/persistViewPageNavigation';

/* Redux */
import { tabMenuProvider } from 'redux/actions/tabMenuAction';


const Consolidation = (props) => {
  const {classes, onClose} = props;
  const [reports, setReports] = useState(null);
  const [alldatas, setDatas] = useState();
  const [loadingReports, setLoadingReports] = useState(true);
  const fonts = fontStyles();
  const openToast = useToast();
  const dispatch =  useDispatch();
  const ref = useRef(null);
  const [reloadMod, setReloadMod] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reloadModTargets, setReloadModTargets] = useState(false);
  const [anchorElTargets, setAnchorElTargets] = useState(null);
  const columns = ['Status', 'Empresa', 'Planos', 'Alvos'];
  const [filterType, setFilterType] = useState(["Alerta Raio", "Aviso Chuva", "Alerta Liberado"]);
  const [modalData, setModalData] = useState([""]);
  const [modalTargetsData, setModalTargetsData] = useState([""]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const loadReports = async () => {
    var aux = [];
        await getPlansInfo().then((response) => {
          if(response){
            if(response[0] && response[0][0] && response[0][0]){
              var temp = response[0]
              aux.push(temp)
            }

          }
          else{
            openToast("error", "Problema ao carregar os Alertas, recarregue a página e tente novamente!");
            setLoadingReports(false);
          }
        })
        .catch(() => {
          openToast("error", "Problema ao carregar os Alertas, recarregue a página e tente novamente!");
          setLoadingReports(false);
        })
      
      setReports(aux);
      setLoadingReports(false);

  };
 
  useEffect(() => {
    loadReports();
  }, []);

  
  const goToReportsInfo = (event, targets, plan_name, contract_init, contract_final, id_empresa) => {  
    if(anchorElTargets==null){
      setAnchorEl(event.currentTarget);
      setModalData({targets: targets, plan_name: plan_name, contract_init: contract_init, contract_fin: contract_final, id_empresa: id_empresa})
    }
  };

  const showTargets = (event, targets, id_empresa) => {  
    event.stopPropagation();
    setAnchorElTargets(event.currentTarget);
    setModalTargetsData({targets: targets, id_empresa: id_empresa})
  };

  console.log(anchorEl)

  useEffect(() => {
    if(reloadMod){
      setReloadMod(false);
      setAnchorEl(null);
    }
  }, [reloadMod]);

  useEffect(() => {
    if(reloadModTargets){
      setReloadModTargets(false);
      setAnchorElTargets(null);
    }
  }, [reloadModTargets]);

 
  const data = reports != null && !loadingReports? reports[0]?.map((dataP, index) => {
    return {
      index: index,
      onClick: (e) => goToReportsInfo(e, dataP[0].alvos? dataP[0].alvos : "", dataP[0].plano? dataP[0].plano.nome : "", dataP[0].plano? dataP[0].plano.inicio_contrato : "", dataP[0].plano? dataP[0].plano.fim_contrato : "", dataP[0].plano? dataP[0].plano?.id_empresa : ""),
      [columns[0]]: dataP[0].plano ? dataP[0].plano.status === 1?
      { node: <div className={classes.greenChip} style={{marginLeft: "2px"}}>
                   <span>Ativo</span>
                  <MdOutlineTaskAlt style={{color: "#023047", width:"18px", height:"18px"}} />
              </div>, content: 'Ativo'}
    : dataP[0].plano.status === 0?
      { node: <div className={classes.redChip} style={{marginLeft: "2px"}}>
                <span>Inativo</span>
                <MdOutlineUnpublished style={{color: "#023047", width:"18px", height:"18px"}} />
              </div>, content: 'Inativo'} 
      : dataP[0].plano.status === 2?
        { node: <div className={classes.yellowChip} style={{marginLeft: "2px"}}>
                   <span>Suspenso</span>
                    <MdWarningAmber style={{color: "#023047", width:"18px", height:"18px"}} />
                </div>, content: 'Suspenso'}
        : { node: <div className={classes.greyChip} style={{marginLeft: "2px"}}>
                    <span>Vencido</span>
                    <AiOutlineCloseCircle style={{color: "#023047", width:"18px", height:"18px"}} />
                  </div>, content: 'Vencido'}  : "",
      [columns[1]]: dataP[0].plano? dataP[0].plano.nome_empresa : "",
      [columns[2]]: dataP[0].plano? dataP[0].plano.nome : "",
      [columns[3]]: {node : <div style={{display:"flex", justifyContent:"center"}}><button style={{padding:"10px 20px 10px 20px",display: "flex", flexDirection: "row",  alignItems: "center"}} className={classes.outlineButton1} onClick={(e) => showTargets(e, dataP[0].alvos? dataP[0].alvos : "", dataP[0].plano? dataP[0].plano?.id_empresa : "")}>
                      <span>VER ALVOS</span>
                    </button></div>},
      id: dataP[0].plano? dataP[0].plano.id_plano  : "",
      nome: dataP[0].plano? dataP[0].plano.nome : "" ,
    } 
  }) : [];

  if(!loadingReports && data?.length > 0) {
    return (
        <PapperBlock goBack={true} title={""} icon="ion-ios-paper-outline">    
          {anchorEl != null && <ModalReports anchorEl={anchorEl} setAnchorEl={setAnchorEl} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadMod} data={modalData} putData={modalData} idAlvo={1} />}
          {anchorElTargets!= null && <ModalTargetsView anchorEl={anchorElTargets} setAnchorEl={setAnchorElTargets} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadModTargets={setReloadModTargets} data={modalTargetsData} putData={modalTargetsData} idAlvo={2} />}
          <ComplexTable 
            columns={columns} 
            data={data}  
            type={"Reports"}  
            dataSet={data}
            width={['5%', '20%', '30%', '20%']}
            createButton={false}
            csvButton={false}
            
          />
        </PapperBlock>
    )
  } else if(!loadingReports && data?.length === 0) {
    return(
      <h1 style={fonts.noDataText}>SEM DADOS</h1>
    )
  }
  else {
    return(
        <CircularProgress style={{ marginLeft: "calc(48%)" }} size={50} thickness={1} color="primary" />
    )
  }
}

Consolidation.propTypes = {
  classes: P.object.isRequired,
  onClose: P.func.isRequired,
}

export default withStyles(styles)(Consolidation);
