const styles = theme => ({

    tableHeaderContainerSearch: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: "65px",
        backgroundColor: theme.palette.menu.lightBlue,
        borderRadius: "8px 8px 0 0",
        boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.4)",
        padding: "10px 25px 10px 25px",
    },
    tableHeaderContainerNotSearch: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:"flex-end",
        width: '100%',
        height: "65px",
        backgroundColor: theme.palette.menu.lightBlue,
        borderRadius: "8px 8px 8px 8px",
        boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.4)",
        padding: "10px 25px 10px 25px",
        marginBottom: "40px"
    },
    tableFooterContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: "65px",
        backgroundColor: theme.palette.menu.lightBlue,
        backgroundOpacity: 0.5,
        borderRadius: "0 0 8px 8px",
        padding: "10px 25px 10px 25px",
    },
    leftSideHeadContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '40%',
        height: "100%",
        left:0,   
    },
    leftSideFooterContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '33.3%',
        height: "100%",
        left:0,   
    },
    centerFooterContainer: {
        width: '33.3%',
        height: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightSideHeadContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '60%',
        height: "100%",
        right:0,
    },
    rightSideFooterContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '33.3%',
        height: "100%",
        right:0,

    },
    tableCell:{
        textAlign: 'center',
        alignItems: 'center',
    },
    tableCellAlignRight:{
        textAlign: 'right',
        alignItems: 'center',
    },
    searchHead: {
        width: '90%',
        height: "85%",
        backgroundColor: "#FFFFFF"
    },
    searchFooter: {
        width: '15%',
        height: "85%",
        backgroundColor: "#FFFFFF",
        marginLeft: "10px",
    },
    pagination:{
        marginLeft: "25px",
        marginRight: "25px",
    },
    createButtonText:{
        color: theme.palette.menu.strongBlue,
        textTransform: "uppercase",
    },
    createButtonContainer:{
        cursor: "pointer",
        border: "2px solid",
        height: "90%",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "5px",
        padding: "6px 14px 6px 14px",
        '& $createButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            '& $createButtonText': {
            color: theme.palette.menu.lightBlue,
            },
        },
    },

    contentButtonContainer:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "5px",
        padding: "6px 14px 6px 14px",
        '& $createButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            '& $createButtonText': {
            color: theme.palette.menu.white,
            },
        },
    },
    csvButtonContainer:{
        marginLeft: "5%",
        height: "90%",
    },

    csvBusttonContainer:{
        marginLeft: "5%",
        height: "90%",
        alignItems:"center",
        display: "flex",
    },
    deleteContainer:{
        display: "flex",
        alignItems: "center",
    },
    dateTimePicker:{
        paddingBottom:"15px",
        marginRight:"30px",

    },
    filterbuttons_bolt:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px"
    },
    filterbuttons_bolt_a:{
        backgroundColor:"#B067E9!important",
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px",
        borderColor:"#B067E9",
    },
    filterbuttons_signout:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px"
    },
    filterbuttons_signout_a:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px",
        borderColor:"#27B624",
        backgroundColor:"#27B624!important",
    },

    filterbuttons_cloudshowers:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px"
    },

    filterbuttons_cloudshowers_a:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px",
        borderColor:"#046DCA",
        backgroundColor:"#046DCA!important",
    },



    filterbuttons_cloudsun:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px"
    },

    filterbuttons_cloudsun_a:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px",
        borderColor:"#B6888F",
        backgroundColor:"#B6888F!important",
    },

    filterbuttons_newspaper:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px"
    },
    filterbuttons_newspaper_a:{
        width: '35px',
        height: '35px',
        borderRadius: '5px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        padding: "8px, 15px, 8px, 15px",
        border: "2px solid",
        marginRight: "-3px",

        borderColor:"#F0D138",
        backgroundColor:"#F0D138!important",
    },

    outlineButtonText: {
        color: theme.palette.text.strongBlue,
        textTransform: "uppercase",
    },
    outlineButtonIcon: {
        color: theme.palette.text.strongBlue,
    },

    outlineButton1:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "5px",
        padding: "2px 8px 2px 8px",
        fontSize: "14px",
        fontFamily: "Segoe UI",
        fontWeight: "700",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& $outlineButtonText': {
            color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
            color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
            background: theme.palette.menu.strongBlue,
            color: theme.palette.menu.white,
            '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
            },
        },
    },

    iconButton1:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "20px",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        backgroundColor:"#FF0000",
        fontSize: "20px",
        fontFamily: "Segoe UI",
        fontWeight: "700",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: "white",
          color:"#FF0000",
          '& $outlineButtonText': {
            color: "white",
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
      },

    

    iconButton2:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "20px",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.menu.strongBlue,
        backgroundColor:"#68CC66",
        fontSize: "14px",
        fontFamily: "Segoe UI",
        fontWeight: "700",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: theme.palette.menu.strongBlue,
          color:"white",
          '& $outlineButtonText': {
            color: "white",
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
    },

    iconButton3:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "20px",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        background:"transparent",
        fontSize: "14px",
        fontFamily: "Segoe UI",
        fontWeight: "700",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: theme.palette.menu.strongBlue,
          color:"white",
          '& $outlineButtonText': {
            color: "white",
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
    },

    iconButton4:{
        cursor: "pointer",
        border: "2px solid",
        borderColor: theme.palette.menu.strongBlue,
        borderRadius: "20px",
        padding: "2px 8px 2px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.menu.strongBlue,
        background:"transparent",
        fontSize: "14px",
        fontFamily: "Segoe UI",
        fontWeight: "700",
        '& $outlineButtonText': {
          color: theme.palette.menu.strongBlue,
        },
        '& $outlineButtonIcon': {
          color: theme.palette.menu.strongBlue,
        },
        '&:hover': {
          background: theme.palette.menu.strongBlue,
          color:"white",
          '& $outlineButtonText': {
            color: "white",
          },
          '& $outlineButtonIcon': {
            color: theme.palette.menu.white,
          },
        },
    },
});


export default styles;