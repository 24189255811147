// Main Components
import HeaderI from './Header/Header';
import HeaderMenuI from './Header/HeaderMenu';
import SidebarI from './Sidebar';
import SidebarBigI from './SidebarBig';
import BreadCrumbI from './BreadCrumb/BreadCrumb';
import SourceReaderI from './SourceReader/SourceReader';
import PapperBlockI from './PapperBlock/PapperBlock';
import SearchUiI from './Search/SearchUi';
// Guide
import GuideSliderI from './GuideSlider';
// Form
import LoginFormI from '../pages/Login/Login';
import RegisterFormI from './Forms/RegisterForm';
import ResetFormI from './Forms/ResetForm';
import NotificationI from './Notification/Notification';
// Table
import EmptyDataI from './Tables/EmptyData';
// Error
import ErrorWrapI from './Error/ErrorWrap';

// Main Components
export const Header = HeaderI
export const HeaderMenu = HeaderMenuI
export const Sidebar = SidebarI
export const SidebarBig = SidebarBigI
export const BreadCrumb = BreadCrumbI
export const SourceReader = SourceReaderI
export const PapperBlock = PapperBlockI
export const SearchUi = SearchUiI
// Guide
export const GuideSlider = GuideSliderI
// Form
export const LoginForm = LoginFormI
export const RegisterForm = RegisterFormI
export const ResetForm = ResetFormI
export const Notification = NotificationI
// Table
export const EmptyData = EmptyDataI
// Error
export const ErrorWrap = ErrorWrapI