import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Input from '@material-ui/core/Input';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";


import IconButton from '@material-ui/core/IconButton';

export const StyledInputDefault = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.main,
    border: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    paddingLeft: '8px'
  }
}))(props => <Input {...props} />)

export const StyledTableDefault = withStyles((theme) => ({
  root: {
    marginTop: "0px",
    marginBottom: "0px",
  },
}))((props) => <Table {...props} />);

export const StyledHeadDefault = withStyles((theme) => ({
  root: {
    '& tr': {
      backgroundColor: theme.palette.menu.white,
      height: "60px",
      borderWidth: `0 2px 2px 2px `,
      borderColor: theme.palette.menu.mediumGrey,
      borderStyle: `solid`,
      '& th': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        textTransform: 'uppercase',
        color: theme.palette.menu.orange,
        '&:hover': {
          fontWeight: 700,
          cursor: 'pointer',
        }
      }
    }
  },
}))((props) => <TableHead {...props} />);

export const StyledBodyDefault = withStyles((theme) => ({
  root: {
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.menu.white,
    },
    '& tr': {
      backgroundColor: theme.palette.menu.white,
      cursor: 'pointer',
      border: `2px solid ${theme.palette.menu.mediumGrey}`,
      '&:hover': {
        backgroundColor: theme.palette.background.selected,
      },
      '& td': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400
      }
    },
  }
}))(props => <TableBody {...props} />)

export const StyledIconButtonDefault = withStyles(theme => ({
  root: {
    boxShadow: `0 0 3px 1px #4a4a4a`
  }
}))(props => <IconButton {...props} />)

export const StyledCellDefault = withStyles((theme) => ({
  root: {
    padding: "8px",
    display: "flex",
  }
}))((props) => <TableCell {...props} />);

export const StyledSmallCellDefault = withStyles((theme) => ({
  root: {
    padding: "8px",
    width: "120px",
    textAlign: "center",
    justifyContent: "center",
  }
}))((props) => <TableCell {...props} />);

export const StyledIconCellDefault = withStyles((theme) => ({
  root: {
    padding: "8px",
    width: "10px",
  }
}))((props) => <TableCell {...props} />);

export const StyledCellDefault1 = withStyles((theme) => ({
  root: {
    padding: "8px",
    display:"flex",
    flexDirection:"row",
  }
}))((props) => <TableCell {...props} />);

export const StyledTableMini = withStyles((theme) => ({
  root: {
    marginTop: "0px",
    marginBottom: "0px",
  },
}))((props) => <Table {...props} />);

export const StyledHeadMini = withStyles((theme) => ({
  root: {
    '& tr': {
      backgroundColor: theme.palette.menu.lightBlue,
      height: "45px",
      border: "1px solid",
      borderColor: theme.palette.menu.mediumGrey,
      overflow: 'hidden',
      borderRadius:"8px",
      '& th': {
        fontFamily: theme.typography.fontFamily,
        textTransform: 'uppercase',
        justifyContent: 'center',
        fontWeight: 500,
        color: theme.palette.menu.orange,
        '&:hover': {
          fontWeight: 700,
          cursor: 'pointer',
        }
      },
    }
  },
}))((props) => <TableHead {...props} />);

export const StyledBodyMini = withStyles((theme) => ({
  root: {
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.menu.white,
    },
    '& tr': {
      backgroundColor: theme.palette.menu.white,
      cursor: 'pointer',
      border: `2px solid ${theme.palette.menu.mediumGrey}`,
      '&:hover': {
        backgroundColor: theme.palette.background.selected,
      },
      '& td': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
      }
    },
  }
}))(props => <TableBody {...props} />)

export const StyledCellMini = withStyles((theme) => ({
  root: {
    padding: "8px",
    display: "flex",
    borderBottom: "0px",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center"
  }
}))((props) => <TableCell {...props} />);

export const StyledSmallCellMini= withStyles((theme) => ({
  root: {
    padding: "8px",
    textAlign: "center",
    justifyContent: "center",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
}))((props) => <TableCell {...props} />);

export const StyledIconCellMini = withStyles((theme) => ({
  root: {
    padding: "8px",
  }
}))((props) => <TableCell {...props} />);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    borderBottom: "2px solid #848484",
  },
}))((props) => <TableRow {...props} />);

export const StyledTableRowLightBorder = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #848484",
  },
}))((props) => <TableRow {...props} />);

export const StyledTableRowVeryLightBorder = withStyles((theme) => ({
  root: {
    borderBottom: "1px groove #CFCFCF",
  },
}))((props) => <TableRow {...props} />);

export const StyledTableRowNoBorder = withStyles((theme) => ({
  root: {
    borderBottom: "0px",
  },
}))((props) => <TableRow {...props} />);

export const StyledCellView = withStyles((theme) => ({
  root: {
    padding: "20px 20px 20px 30px",
  }
}))((props) => <TableCell {...props} />);

export const StyledNoBorderCellView = withStyles((theme) => ({
  root: {
    padding: "20px 20px 20px 30px",
    border: "0"
  }
}))((props) => <TableCell {...props} />);

export const StyledTableRowDual = withStyles((theme) => ({
  root: {
    borderBottom: "2px solid #848484",
    justifyContent: "space-between",
    display: "flex",
  },
}))((props) => <TableRow {...props} />);

export const StyledCellDual = withStyles((theme) => ({
  root: {
    padding: "20px 20px 20px 30px",
    width: "50%",
  }
}))((props) => <TableCell {...props} />);

export const StyledCellDualBorder = withStyles((theme) => ({
  root: {
    padding: "20px 20px 20px 30px",
    width: "50%",
    borderLeft: "2px solid #848484",
  }
}))((props) => <TableCell {...props} />);