/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { useDispatch } from 'react-redux';
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FaFilePdf } from "react-icons/fa";
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';

import file_present_icon from 'images/file_present_icon.png'
import UploadButton from "components/UploadButton/index";
import { modalProvider } from 'redux/actions/modalAction';
import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from "@material-ui/core/Switch";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import TabPanel from 'components/TabPanel';
import LightningAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert';
import RainAlert from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/RainAlert';
import Releases from '../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/Releases';
import FieldsComponent from "../../pages/MapsPortal/components/AlertsPanel/component/AlertsForm/LightningAlert/fieldsComponent";
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../services/mapsPortal/alerts"
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'
import { SiAlpinelinux } from "react-icons/si";

const useStyles = makeStyles({
    lightningAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#B067E9",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#B067E9'
      }
    },
    rainAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#046DCA",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#046DCA'
      }
    },
    meteorologicalAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    },
    releases: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#27B624",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#27B624'
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
  })
  

const AlertsElement = (props) => {
    const {classes, rightSideTabs, indexPage, company, value, setReloadRightSide, setAllValues, allValues} = props;
    const fonts = fontStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const dividerStyle = useStyles();
    var gridClasses = [
        classes.gridColumnsComponent,
        classes.gridMarginLeft,
    ]
    const [companyValues, setCompanyValues] = useState(null);
    const [reload, setReload] = useState(false);
    const [subjectCount, setSubjectCount] = useState(0);
    const [subjectError, setSubjectError] = useState("");
    const [titleError, setTitleError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [invitePrincipal, setInvitePrincipal] = useState(false);
    const [postSwitchs, setPostSwitchs] = useState([]);
    const [titleCount, setTitleCount] = useState(0);
    const defaultProps = {
      options: ["T1", "T2", "T3", "T4"],
    };

    
    var aux = 0;

    const tools = ["Descargas", "Radar", "Satélite", "TSC"]

    const addModifiedValue = (input, newValue) =>{
      let aux = companyValues

      if(aux.campos_modificados.length > 0){
        if(!aux.campos_modificados.includes(input)){
          aux.campos_modificados.push(input)
        }
      }else{
        aux.campos_modificados.push(input)
      }

      if(input=="risco"){
        aux.risco = newValue
      }
      if(input=="nivel"){
        aux.nivel = newValue
      }
      if(input=="contato"){
        aux.contato = newValue
      }
      if(input=="duracao"){
        aux.duracao = newValue
      }
      if(input=="ferramenta"){
        aux.ferramenta = newValue
      }
      if(input=="observacao"){
        console.log(newValue)
        aux.observacao = newValue
      }

      setCompanyValues(aux);
      setReload(true);
      modifyAllValues();
    };

    const checkVerify = (valueCheck)=>{
      let aux = ""
      companyValues.filter((value) => {
        value.includes(valueCheck)? 
          aux = value.split("_")[3] 
        : 
          aux = ""
      })
      return(aux)
    };

    useEffect(() => {
      setReload(false);
    }, [reload])

    const handleFileUpload = (data) => {
      if(data){
        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = (e) => {
          addModifiedValue("upload", [e.target.result, data.name])
          
        }
        aux = aux + 1;
        setReload(aux);
      }
    };
  
    const handlePdfViewer = () => {
      if(companyValues.filePresent) {
        dispatch(modalProvider(true, companyValues.file, null, null, "PdfViewer", null));
      }
    };

    const initialSets = () => {
      let aux = []
      aux = {nome: value.nome, nivel: value.nivel, duracao: value.dur_est, risco: value.risco, id_empresa_id: value.id_empresa_id, contato: value.contato, observacao: value.observacao, ferramenta: value.ferramenta?.includes(",")? value.ferramenta?.split(",")  : value.ferramenta==""? [] : [value.ferramenta], datahora_liberacao: value.datahora_liberacao, liberado: value.liberado, id_alerta: value.id, meteorologista: value.meteorologista, errata: value.errata, campos_modificados: [], intensidade: value.intensidade, acumulado: value.acumulado, id_og: value.id_og}
      setCompanyValues(aux)
    };

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
      setAllValues({nivel: companyValues.nivel, observacao: companyValues.observacao, ferramenta: companyValues.ferramenta, duracao: companyValues.duracao, risco: companyValues.risco, liberado: companyValues.liberado!=null&&companyValues.liberado!=""? companyValues.liberado : false, contato: companyValues.contato, datahora_liberacao: companyValues.datahora_liberacao, meteorologista: companyValues.meteorologista, id_alerta: companyValues.id_alerta, errata: companyValues.errata, campos_modificados: companyValues.campos_modificados, intensidade: companyValues.intensidade, acumulado: companyValues.acumulado, id_og: companyValues.id_og});
      setChecked(!checked);
      setReloadRightSide(true);
    };

    const removeFileUploaded = () => {
      addModifiedValue("removeFileUp")
      aux = aux + 1;
      setReload(aux);
    };

    const modifyAllValues = () => {
      setAllValues(companyValues);
      setReloadRightSide(true);
    };
    
    const getPostSwitchs = (element) => {
      const finder = postSwitchs.find((filteredButton) => filteredButton == element);
      let aux = false
      if(finder === undefined || finder == ""){
        aux = false
      }else{
        aux = true
      }
      return aux
    };
    
    useEffect(() => {
      initialSets();
    }, [])



    if(companyValues!=null) {
      return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 20px 10px 20px"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
            {companyValues.filePresent &&
              <div className={classes.filePresentContainer}>
                <div className={classes.fileRemoveContainer} onClick={() => removeFileUploaded()}>
                  <img src={file_present_icon} style={{width: "40px", height: "40px"}}/>
                  {companyValues.fileName && <span className={classes.fileNameText}>{companyValues.fileName?.length > 38? companyValues.fileName?.substring(0, 38) + "..." : companyValues.fileName}</span>}
                </div>
              </div>
            }
            {!companyValues.filePresent &&
              <div>
                <UploadButton onChange={handleFileUpload} fileType={"pdf"} setFileName={companyValues} setErrorMessage={setErrorMessage} buttonName={"Anexar PDF"}/>
              </div>
            } 
              <div style={{display: "flex", flexDirection: "column", width: "75%", justifyContent: "center", alignItems: "flex-start"}}>
                <TextField
                  id="title"
                  label="Título"
                  multiline
                  shrink={true}
                  value={companyValues?.titulo}
                  disableClearable
                  onChange={(e) => addModifiedValue("titulo", e.target.value)}
                  className={classes.textFieldWeatherIndex}
                  variant="outlined"
                  color="primary"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: fonts.formsFieldHeight4, maxLength: 50 }}
                  InputProps={{
                    endAdornment:
                      <span className={classes.endAdornment}>{titleCount + "/50"}</span>
                  }} />
    
                {titleError && <p className={classes.errorMessage}>{titleError}</p>}
              </div>
          </div>
    
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "15px"}}>
            <div className={!companyValues.filePresent? classes.disabledViewButton : classes.viewButton} style={{width: "144px"}} onClick={(handlePdfViewer)}>
              <span style={fonts.buttonDefault} className={classes.text}>VER PDF</span>
              <FaFilePdf style={{marginLeft: "6px", width: "18px", height: "18px"}} className={classes.icon}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "75%", justifyContent: "center", alignItems: "flex-start"}}>
              <TextField
                id="subject"
                label="Assunto"
                multiline
                rows={2}
                value={companyValues?.assunto}
                onChange={(e) => addModifiedValue("assunto", e.target.value)}
                className={classes.textFieldWeatherIndex}
                margin="normal"
                variant="outlined"
                inputProps={{style: fonts.formsField, maxLength: 150}}
                InputLabelProps={{style: fonts.formsLabel}}
                InputProps={{
                  endAdornment:
                    <span className={classes.endAdornment} style={{marginTop: "5%"}}>{subjectCount + "/150"}</span>
                }}
              />
    
              {subjectError && <p className={classes.errorMessage}>{subjectError}</p>}
            </div>
          </div>
    
          <div style={{display: "flex", alignItems: "flex-end", width: "100%", marginTop: "10px", flexDirection: "column"}}>
            <Button style={fonts.buttonDefault} variant="contained" className={classes.wheaterButton} onClick={() => addModifiedValue("changeAll", null)}>
              Alterar todos
            </Button>
          </div>
    
          <div style={{display: "flex", marginTop: "20px", width: "100%"}}>
            <Divider classes={{ root: dividerStyle.divider }}/>
          </div>
         
        </div>
      )
  }
};

AlertsElement.propTypes = {
    classes: PropTypes.object.isRequired,
    setReloadRightSide: PropTypes.func,
    setAllValues: PropTypes.func,
    allValues: PropTypes.array.isRequired,
    indexPage: PropTypes.any,
    company: PropTypes.object.isRequired,
    value: PropTypes.any,
    rightSideTabs: PropTypes.number.isRequired,
  };
  
  export default withStyles(styles)(AlertsElement);