import 'mapbox-gl/dist/mapbox-gl.css';
import { useState, useEffect } from 'react';
import ReactMapGl from 'react-map-gl';
import { DischargesLayer } from './DischargesLayer';
import MapPopup from './MapPopup';
import SatelitesLayers from './SatelitesLayers.jsx/index.jsx';
import { TargetLayer } from './TargetLayer';
import { ActiveLayer } from './ActiveLayer';
import RadarLayer  from './RadarLayer';
import { CamerasLayer } from './CamerasLayer';
import PolygonLayer from './PolygonLayer';
// import { DrawPolygon } from './PolygonLayer';
import { useMap } from 'hooks/useMap';
import { Tool } from './MeasureLayer';
import Loading from 'components/Loading';
import {useMapContext} from 'Context/MapContext';
import {useTools} from 'Context/ToolsContext';
import Alerts from '../Alerts';
import Logo from 'images/file_present_icon.png'

const Map = () => {
    const [loading, setLoading] = useState(true);
    const {mapRef, popup, showPopup, setShowPopup} = useMapContext();
    const { goTo, setGoTo, mapStyle } = useMapContext();
    const { polygon } = useTools();

    const [viewport, setViewport] = useState({
      longitude: -59.95,
      latitude: -4.9,
      zoom: 4,
      maxZoom: 18,
      minZoom: 1,
    });
    const { mapFunctions, interactiveLayers } =
        useMap(mapRef, viewport, setViewport, loading, setLoading);

    useEffect(() => {
      if (!goTo.length) return;
      mapRef.current.fitBounds(
        goTo,
        {padding: 40, duration: 1000}
      )
      setGoTo([]);
    }, [goTo, mapRef, setGoTo]);

    const onClose = () => {
      setShowPopup(false)
      polygon.resetPolygon();
    }

    
    if(!mapRef.current?.hasImage('camera_marker')){
      mapRef.current?.on('load', () => {
        mapRef.current.loadImage(
          'https://bucket-imagens-boletim-nucleo.s3.us-east-2.amazonaws.com/portal_dos_mapas/camera_marker',
          (error, image) => {
            if (error) throw error;
            // If has no image, add the image to the map style.
            if(!mapRef.current.hasImage('camera_marker')){
              mapRef.current.addImage('camera_marker', image);
            }
          }
        )
    })}
      

    return (
      <>
        {(loading || !mapRef.current?.hasImage('camera_marker')) && <Loading />}
        <ReactMapGl
            // reuseMaps
            ref={mapRef}
            {...viewport}
            {...mapFunctions}
            // interactiveLayerIds={interactiveLayers}
            dragRotate={false}
            doubleClickZoom={false}
            width="100%"
            height="100%"
            mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
            maxBounds={[
              [-118, -40],
              [-9, 11.5]
            ]}
         
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
            <PolygonLayer />
            <TargetLayer />
            <DischargesLayer />
            <SatelitesLayers />
            <RadarLayer />
            <Tool />
            <Alerts />
            <ActiveLayer />
            <CamerasLayer />

            {showPopup && (
                <MapPopup onClose={onClose} {...popup} />
            )}
        </ReactMapGl>
      </>
    );
};

export default Map;
