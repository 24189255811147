import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';

// Import root app
import App from 'routes/index';
import 'styles/layout/base.scss';
import configureStore from './redux/configureStore';
import { createBrowserHistory } from 'history';
import ToastContextProvider from 'Context/ToastContext';
import AuthContextProvider from 'Context/AuthContext';

const history = createBrowserHistory();
const initialState = {};
const { store, persistor } = configureStore(initialState, history);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <ToastContextProvider>
                <AuthContextProvider>
                  <App history={history} />
                </AuthContextProvider>
              </ToastContextProvider>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
);
