/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import 'moment/locale/pt-br';
/* Material UI */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ReportProblem from "@material-ui/icons/ReportProblem";
import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
/* CSS */
import styles from "../alerts_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Services */
import { getUserAuthenticated } from "services/login/login";
import { getEmployees_TargetByTargetID } from "services/adminDashboard/employeesTarget";
import { getEmployees } from "services/adminDashboard/employees";
import { getLightningActiveAlertByTargetID } from "services/mapsPortal/alerts";
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';
/* Hooks */
import { useToast } from 'Context/ToastContext';


const useStyles = makeStyles({
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
  switch_secondary: {
    "&.Mui-checked": {
      color: "red",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "red",
    },
  },
  divider: {
    background: "#A5A5A5",
  },
})


const FieldsComponent = (props) => {
  const { classes, target, preset, alertsData, setAlertsData, setReloadSwitchSelected, setReloadModal, allSelected, setAllSelected, allSelectedAux, setAllSelectedAux, setReloadErrorMessage, reloadTargets, setReloadTargets, setLoadingRightSide, loadingRightSide, setReloadRightSide, releaseAlertErrors, setReleaseAlertErrors, servicesLightningAlert } = props;
  const fonts = fontStyles();
  const switchStyle = useStyles();
  const openToast = useToast();
  const [servicesStatus, setServicesStatus] = useState(true);
  const [contacts, setContacts] = useState();
  const [targetData, setTargetData] = useState([]);
  const [loadingTargetData, setLoadingTargetData] = useState(0);
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [targetIsActive, setTargetIsActive] = useState(false);
  const moment = require('moment');
  const [caractersRemaning, setCaractersRemaning] = useState();
  moment.locale('pt-br');
  const [switchSelected, setSwitchSelected] = useState(false);
  var alertsDataAux = alertsData;

  const [releaseAlertErrorsAux, setReleaseAlertErrorsAux] = useState({
    nivel: null,
    risk: null,
    contact: null,
    id_alvo: null
  });

  const [releaseAlert, setReleaseAlert] = useState(
    {
      nivel: "",
      campos_modificados: null,
      modificacao: null,
      conteudo_modificacao: null,
      risco: "",
      contato: "",
      observacao: null,
      meteorologista: "",
      id_alvo: "",
    }
  );

  var gridClasses = [
    classes.gridColumnsIndex,
    classes.gridMarginLeftComponentRelease,
  ]

  const toggleSwitchSelected = () => {

    if(targetIsActive){
      if (!switchSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(!switchSelected);
          alertsDataAux.splice(index, 1);
          alertsDataAux.push(releaseAlert);
          setAlertsData(alertsDataAux);
        }
        else {
          alertsDataAux.push(releaseAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(!switchSelected);
        }
        let aux = releaseAlertErrors;
        let indexError = aux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (indexError != -1) {
          aux.splice(indexError, 1);
          aux.push(releaseAlertErrorsAux);
          setReleaseAlertErrors(aux);
        }
        else {
          aux.push(releaseAlertErrorsAux);
          setReleaseAlertErrors(aux);
        }
      }
      else {
        setAllSelected(false);
        setAllSelectedAux(true);
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(!switchSelected);
        let aux = releaseAlertErrors;
        aux = aux.filter((item) => target.id_alvo != item.id_alvo);
        setReleaseAlertErrors(aux);
      }
      setReloadSwitchSelected(true);
    }
  }

  const loadServiceStatus = async () => {
    if (target) {
      await getLightningActiveAlertByTargetID(target.id_alvo).then((response) => {
        var aux = response[0];
        if (response && response.length > 0) {
          releaseAlert.nivel = aux[0].nivel;
          releaseAlert.risco = (aux[0].risco[0].toUpperCase() + aux[0].risco.slice(1, aux[0].risco.length));
          releaseAlert.id_alerta = aux[0].id_alerta;
          setTargetIsActive(true);
          loadTargetData();
        }
      })
    }
  }

  const loadTargetData = async () => {
    if(target.id_alvo){
      await getTargetInfosByTargetID(target.id_alvo).then((response) => {
        if(response[0].length > 0){
          setTargetData(response[0][0][0]);
          setLoadingTargetData(1);
          loadContacts(response[0][0][0].usuarios);
          loadUserAuthenticated();
        }
        else{
          setLoadingTargetData(2);
        }
      }).catch((error) => {
        setLoadingTargetData(2);
      });
    }
  }

  const loadUserAuthenticated = async () => {
    var loginToken = JSON.parse(localStorage.getItem("reeWAL57qe"));

    await getUserAuthenticated(loginToken).then((response) => {
      if (response.status === 200) {
        releaseAlert.meteorologista = response.data.usuario.id;
      }
    }).catch((error) => {
      openToast('error', 'Problema ao buscar usuário autenticado, recarregue a página e tente novamente!')
    });
  }

  const loadContacts = (data) => {
    var contactsList = [{ nome: "Não foi possível o contato telefônico.", prioridade: [""] }, { nome: "Não foi realizado o contato telefônico.", prioridade: [""] }, { nome: "Não há contato telefônico das xx:xx até às xx:xx.", prioridade: [""] }];

    if(data && data[0]){
      var contactData = [];
      data.map((user) => user.prioridade? contactData.push(user) : contactData);

      const sortData = ( a, b ) => {
        if ( a.prioridade.prioridade < b.prioridade.prioridade ){
          return -1;
        }
        if ( a.prioridade.prioridade > b.prioridade.prioridade ){
          return 1;
        }
        return 0;
      }

      contactData.sort(sortData);

      for (let i = 0; i < contactData.length; i++) {
        contactsList.push({ nome: contactData[i].nome, prioridade: contactData[i].prioridade.prioridade})
      } 
      setContacts(contactsList);
      setLoadingContacts(false); 
    }
    else{
      setContacts(contactsList);
      setLoadingContacts(false);
    }
  }

  useEffect(() => {
    releaseAlert.nivel = preset.nivel;
    releaseAlert.risco = preset.risco;
    releaseAlert.contato = preset.contato;
    releaseAlert.observacao = preset.observacao;
    releaseAlert.datahora_criacao = preset.datahora_criacao;
    setReloadModal(true);
  }, [preset.preset])

  useEffect(() => {
    setReloadTargets(false);
    setTargetIsActive(true);
  }, [reloadTargets])

  useEffect(() => {
    if(!targetIsActive){
      if (switchSelected) {
        if (releaseAlert.nivel === "") {
          releaseAlertErrorsAux.level = "Campo Nível é obrigatório!";
        }
        else {
          releaseAlertErrorsAux.level = null;
        }
        if (releaseAlert.risco === "") {
          releaseAlertErrorsAux.risk = "Campo Risco é obrigatório!";
       
        }
        else {
          releaseAlertErrorsAux.risk = null;
        }
        if (releaseAlert.contato === "") {
          releaseAlertErrorsAux.contact = "Campo Contato é obrigatório!";
        }
        else {
          if (releaseAlert.contato && releaseAlert.contato.length < 10) {
            releaseAlertErrorsAux.contact = "Campo Contato deve ter mais que 10 caracteres!";
          }
          else if (releaseAlert.contato && releaseAlert.contato.length > 50) {
            releaseAlertErrorsAux.contact = "Campo Contato deve ter menos que 50 caracteres!";
          }
          else {
            releaseAlertErrorsAux.contact = null;
          }
        }
        if (releaseAlert.observacao && releaseAlert.observacao.length > 1 && releaseAlert.observacao.length < 50) {
          setCaractersRemaning(50 - releaseAlert.observacao.length);
        }
        else {
          setCaractersRemaning("");
        }

        var index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          let aux = alertsDataAux;
          aux.splice(index, 1);
          aux.push(releaseAlert);
          setAlertsData(aux);
          let aux2 = releaseAlertErrors;
          aux2.splice(index, 1);
          aux2.push(releaseAlertErrorsAux);
          setReleaseAlertErrors(aux2);
        }
        else {
          let aux = alertsDataAux;
          aux.push(releaseAlert);
          setAlertsData(aux);
          let aux2 = releaseAlertErrors;
          aux2.push(releaseAlertErrorsAux);
          setReleaseAlertErrors(aux2);
        }
      }
      setReloadErrorMessage(true);
    } 
  }, [releaseAlert?.nivel, releaseAlert?.risco, releaseAlert?.contato, releaseAlert?.observacao, preset.preset, switchSelected])

  useEffect(() => {
    if (target) {
      releaseAlert.id_alvo = target.id_alvo;
      releaseAlertErrorsAux.id_alvo = target.id_alvo;
      loadServiceStatus();
    }
  }, []);

  useEffect(() => {
    if(!targetIsActive){
      if (allSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(allSelected);
        }
        else {
          alertsDataAux.push(releaseAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(allSelected);
          let aux = releaseAlertErrors;
          aux.push(releaseAlertErrorsAux);
          setReleaseAlertErrors(aux);
        }
      }
      else if (!allSelected && !allSelectedAux) {
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(allSelected);
        let aux = releaseAlertErrors;
        aux = aux.filter((item) => target.id_alvo!= item.id_alvo);
        setReleaseAlertErrors(aux);
      }
    }
  }, [allSelected])

  useEffect(() => {
    if(targetIsActive){
      if(loadingContacts){
        let aux = loadingRightSide;
        aux.push(true);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
      else{
        let aux = loadingRightSide;
        aux.pop(true);
        aux.pop(false);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
    }
    else{
      let aux = loadingRightSide;
      aux.pop(false);
      setLoadingRightSide(aux);
      setReloadRightSide(true);
    }
  }, [loadingContacts])


  if (target && !loadingContacts && targetIsActive && loadingTargetData === 1 && loadingRightSide.length === 0) {
    return (
      <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "15px" }}>
        <Grid item xs={12} className={classes.gridContainerComponentRelease}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={switchSelected}
              onChange={() => toggleSwitchSelected()}
              value={switchSelected}
              size="small"
              classes={{
                colorPrimary: servicesStatus ? switchStyle.switch_primary : switchStyle.switch_secondary
              }}
              color={"primary"} />

            <div style={{ marginLeft: "15px" }}>
              <Typography variant="subtitle1" style={!servicesStatus ? fonts.targetTitleWarning : fonts.targetTitle}>
                {target.nome}
              </Typography>
            </div>

            {!servicesStatus && <ReportProblem style={{ marginLeft: "8px", width: "20px", height: "20px", color: "red" }} />}
          </div>
        </Grid>

        <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "15px" }}>
          <Grid item xs={1.5} className={classes.gridContainerComponentRelease}>
            <div className={classes.selectContainer}>
              <Autocomplete
                id="level"
                freeSolo={false}
                value={releaseAlert?.nivel}
                inputValue={releaseAlert?.nivel}
                disableClearable
                onChange={(_, newValue) => setReleaseAlert({ ...releaseAlert, nivel: newValue })}
                className={classes.selectFieldSmallLightAlert}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["T1", "T2", "T3", "T4"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Nível"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={1.5} className={gridClasses}>
            <div className={classes.selectContainer} style={{ marginLeft: "5px" }}>
              <Autocomplete
                id="risk"
                freeSolo={false}
                value={releaseAlert?.risco}
                inputValue={releaseAlert?.risco}
                disableClearable
                onChange={(_, newValue) => setReleaseAlert({ ...releaseAlert, risco: newValue })}
                className={classes.selectFieldLargeReleases}
                style={fonts.formsFieldHeight1}
                size="small"
                options={["Médio", "Alto", "Muito alto"]}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Risco"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />
            </div>
          </Grid>

          <Grid item xs={2} className={classes.gridColumnsComponentRelease}>
            <div className={classes.selectContainer}>

              <Autocomplete
                id="contacts"
                freeSolo
                value={releaseAlert?.contato}
                inputValue={releaseAlert?.contato}
                disableClearable
                onInputChange={(_, newValue) => setReleaseAlert({ ...releaseAlert, contato: newValue })}
                onChange={(_, newValue) => setReleaseAlert({ ...releaseAlert, contato: newValue })}
                className={classes.selectFieldReleases2}
                style={fonts.formsFieldHeight1}
                size="small"
                options={contacts.map((option) => option.prioridade != "" ? option.prioridade + " - " + option.nome : option.nome)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Contato"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }} />} />


            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "20px" }}>
          <Grid item xs={12} className={classes.gridContainerComponentRelease}>
            <TextField
              id="comments"
              label="Observações"
              multiline
              rows={2}
              value={releaseAlert?.observacao}
              onChange={(e) => setReleaseAlert({ ...releaseAlert, observacao: e.target.value })}
              className={classes.textFieldInputReleasesComponent}
              margin="normal"
              shrink={true}
              variant="outlined"
              color="primary"
              inputProps={{ style: fonts.formsFieldHeight2, maxLength: 50 }}
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }} />
            {caractersRemaning && <p className={classes.errorMessage3}>{caractersRemaning + " caracteres restantes"}</p>}
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" justifyContent="flex-start" style={{ marginTop: "14px", width: "52vw" }}>
          <Grid item xs={12}>
              <Divider classes={{ root: switchStyle.divider }} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

FieldsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  preset: PropTypes.any,
  target: PropTypes.any,
  planoId: PropTypes.any,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  setReloadModal: PropTypes.string.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  allSelectedAux: PropTypes.any,
  setAllSelectedAux: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
  setLoadingRightSide: PropTypes.func.isRequired,
  setReloadRightSide: PropTypes.func.isRequired,
  loadingRightSide: PropTypes.any.isRequired,
  releaseAlertErrors: PropTypes.any.isRequired,
  setReleaseAlertErrors: PropTypes.func.isRequired,
  servicesLightningAlert: PropTypes.any.isRequired,
};

export default withStyles(styles)(FieldsComponent);