import { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import {OpenTopMenu, TopMenuStyled, Footer, Button, Divider} from './styled';
import './ControlPanel.css';
import TooltipComponent from 'components/Modal/Tooltip.js';
import FiltersPage from './FiltersPage';
import StatisticsPage from './StatisticsPage';
/* Material */
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core/styles";
// Icons
import { FaHistory } from 'react-icons/fa';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Hooks
import {useRadar} from 'Context/RadarContext';
import {useSatellite} from 'Context/SatelliteContext.js';
import useToggle from 'hooks/useToggle';
import {useControlPanel} from 'Context/ControlPanelContext';
import { polygonProvider } from 'redux/actions/polygonAction';
import { ToolsContext } from 'Context/ToolsContext';
import { useTarget } from 'Context/TargetContext';

const useStyles = makeStyles({
  styledTab: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#023047",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#023047'
    }
  },
})

const TopMenu = () => {
  const { showRadarPanel, toggleRadarPanel, showSatellitePanel, toggleSatellitePanel, showAlerts } = useControlPanel();
  const {showSatellite} = useSatellite();
  const { toggleTargets } = useTarget();
  const tabStyle = useStyles();
  const width = screen.width;
  const height = screen.height;
  const {showRadar} = useRadar();
  const [open, toggleOpen] = useToggle(false);
  const [tab, setTab] = useState(0);
  const theme = useTheme();
  const [statistics, setStatistics] = useState(false);
  const [dataTargets, setDataTargets] = useState([]);
  const dispatch = useDispatch();
  const polygonSelected = useSelector(state => state.polygonProvider);

  const {
    polygon
  } = useContext(ToolsContext);

  const toggleChangeTabs = (event, newValue) => {
    setTab(newValue);
  };

  const toggleQuit = () => {
    toggleOpen();
    dispatch(polygonProvider(null));
    polygon.resetPolygon();
    toggleTargets(true);
  };


  return (
    <TopMenuStyled className={`${!open && 'closed'} ${showAlerts && 'notifOpen'}`} width={width? width * 0.35 : 530} height={height? height * 0.8 : 690} open={open}>
      <Tabs
        value={tab}
        onChange={toggleChangeTabs}
        className={tabStyle.styledTab}  
        variant="fullWidth"
        aria-label="full width tabs example"
        style={{display: "flex", alignItems: "center" }}
        centered={true}
      >
        <Tab label="Filtros" />
        {statistics && <Tab label="Estatísticas" />}
      </Tabs>

        {tab === 0 && <FiltersPage width={width} open={open} setDataTargets={setDataTargets} setStatistics={setStatistics} toggleChangeTabs={toggleChangeTabs} />}
        {tab === 1 && statistics && <StatisticsPage width={width} open={open} dataTargets={dataTargets}/> }

      <Footer height={height? height * 0.8 * 0.12 : 50}>
        <ArrowBackIcon onClick={toggleOpen} style={{width: "24px", height: "24px", cursor: "pointer"}} />
        <Button onClick={toggleQuit}>Sair da seleção</Button>
      </Footer>

      {!open && 
        <TooltipComponent text={polygonSelected? "Filtrar dados para área selecionada" : "Selecione uma área para continuar"}  yAxis={"1500%"}>
          <OpenTopMenu onClick={toggleOpen} disabled={polygonSelected != null? false : true}><FaHistory /></OpenTopMenu>
        </TooltipComponent>
      }
    </TopMenuStyled>
  )
}

export default TopMenu;
/*{tab === 0 && <FiltersPage width={width} open={open} />}
      {tab === 1 && <StatisticsPage width={width} />}*/