import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleAction, playTransitionAction } from 'redux/actions/uiActions';
import DashboardComponent from 'containers/Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import {
  ClientDashboard,
  ClientTargetInfo,
  ClientEmployeeInfo,
  AllEmployees,
  ClientEmployeeRegister,
  NotFound,
  ClientEditEmployee,
  ClientEditContact
} from './pageListAsync';
import styles from 'containers/Templates/appStyles-jss';

function Application(props) {
  const changeMode = useContext(ThemeContext);
  const {
    history, gradient, mode, loadTransition, sidebarOpen, toggleDrawer
  } = props;
  
  return (
    <DashboardComponent history={history} changeMode={changeMode}>
      <Switch>
        {/* <Route exact path="/cliente/registro/usuario" component={EmployeeRegister} /> */}
        <Route exact path="/cliente" component={ClientDashboard} />
        <Route exact path="/cliente/alvo/:target"component={ClientTargetInfo} />
        <Route exact path="/cliente/alvo/:target/usuario/:employee" component={ClientEmployeeInfo} />
        <Route exact path="/cliente/usuarios" component={AllEmployees} />
        <Route exact path="/cliente/usuario/:employee" component={ClientEmployeeInfo} />
        <Route exact path="/cliente/registrar/usuario" component={ClientEmployeeRegister} />
        <Route exact path="/cliente/editar/usuario/:employee" component={ClientEditEmployee} />
        <Route exact path="/cliente/editar/contato/:contact" component={ClientEditContact} />
      </Switch>
    </DashboardComponent>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  gradient: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  sidebarOpen: state.ui.sidebarOpen,
  mode: state.ui.type,
  gradient: state.ui.gradient,
});

const mapDispatchToProps = dispatch => ({
  toggleDrawer: () => dispatch(toggleAction),
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const ApplicationMaped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);

export default (withStyles(styles)(ApplicationMaped));
