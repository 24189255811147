import React, { useState, useEffect } from 'react';
// Libs
import PropTypes from "prop-types";
// Components
import {
    PlansTabStyled,
    SectionTitle,
    PlanItem,
    TargetList,
    ItemContent,
    CompanyItem,
    Span,
} from './styled';
import { Checkbox } from '@material-ui/core';
import { Column, Row } from 'components/Flex';
import Collapsible from 'components/Collapsible';
import TargetInfo from './TargetInfo';
// Icons
import { MdExpandMore } from 'react-icons/md';
// Hooks
import { useModal } from 'Context/ModalContext';
import { useTargetState } from 'Context/TargetStateContext';


const TargetTab = (props) => {
    const { plans: planos, toShow: checkboxes } = useModal();
    const { tab, loadingLeftSide, setLoadingLeftSide, setReloadLeftSide } = props;
    const [sortAscending, setSortAscending] = useState(true);
    const [collapse, setCollapse] = useState([]);
    
    useEffect(() => {
        const p = () => planos.map(company => ({
            value: true,
            plans: company.planos.map(plans => true)
        }))
        setCollapse(p())
    }, [JSON.stringify(planos)])

    const collapseCompany = (id) => {
        setCollapse(p => {
            const a = [...p];

            a[id].value = !a[id].value;
            return a;
        })
    }

    const collapsePlan = (cId, pId) => {
        setCollapse(p => {
            const a = [...p];
            a[cId].plans[pId] = !a[cId].plans[pId]
            return a;
        })
    }

    return (
        <PlansTabStyled>
            <TargetList>
                {!planos.length
                    ? <SectionTitle>Nenhum Plano ou Alvo encontrado.</SectionTitle>
                    : (

                        <>
                            {planos.sort((a, b) => sortAscending
                                ? a.nome.localeCompare(b.nome)
                                : b.nome.localeCompare(a.nome))
                                .map((company) => {
                                    return (
                                            <Collapsible
                                                key={`sidebarempresa-${company.id}`}
                                                isOpen={collapse.length ? collapse[company.id].value : false}
                                                main={(
                                                    !loadingLeftSide.length &&

                                                    <CompanyItem
                                                        key={company.id + '-sidebarEmpresa-check'}
                                                        // isOpen={true}
                                                        //checked={filterCompanyInAlert(company)}
                                                        isOpen={collapse.length ? collapse[company.id].value : false}
                                                    >
                                                        <Checkbox
                                                            label={company.nome}
                                                            onChange={() => checkboxes.toggle(company.id)}
                                                            checked={checkboxes.get(company.id)}
                                                            style={{color: "#1B1B1B"}}
                                                        />
                                                        <ItemContent onClick={() => collapseCompany(company.id)}>
                                                            <Span style={{ fontSize: '17px' }}>
                                                                {company.nome}
                                                            </Span>
                                                            <MdExpandMore style={{ fontSize: '22px' }} />
                                                        </ItemContent>
                                                    </CompanyItem>
                                                )}
                                            >
                                            {company.planos
                                                .map((plan) => {
                                                    return (
                                                    //loadingLeftSide.length &&
                                                    
                                                    <Collapsible
                                                        key={`sidebarplano-${plan.id}`}
                                                        // isOpen={true}
                                                        isOpen={collapse.length ? collapse[company.id].plans[plan.id] : false}
                                                        main={(
                                                            !loadingLeftSide.length &&

                                                            <PlanItem
                                                                ml='16px'
                                                                key={plan.id + '-sidebarPlano-check'}
                                                                //isOpen={true}
                                                                //checked={filterPlanInAlert(plan)}
                                                                isOpen={collapse.length ? collapse[company.id].plans[plan.id] : false}
                                                            >
                                                                <Checkbox
                                                                    label={plan.nome}
                                                                    onChange={() => checkboxes.toggle(company.id, plan.id)}
                                                                    checked={checkboxes.get(company.id, plan.id)}
                                                                    style={{color: "#1B1B1B"}}
                                                                />
                                                                <ItemContent onClick={() => collapsePlan(company.id, plan.id)}>
                                                                    <Span style={{ fontSize: '17px'}} ml='16px'>
                                                                        {plan.nome}
                                                                    </Span>
                                                                    <MdExpandMore style={{ fontSize: '22px' }} />
                                                                </ItemContent>
                                                            </PlanItem>
                                                        )}
                                                    >
                                                        {plan.alvos
                                    
                                                            .sort((a, b) => sortAscending
                                                                ? a.nome.localeCompare(b.nome)
                                                                : b.nome.localeCompare(a.nome))
                                                            .map((target) => {  
                                                                return (
                                                                <Column key={`sidebarAlvo-${plan.id}-${target.id}`}>
                                                                    <TargetInfo
                                                                        target={target}
                                                                        plan={plan}
                                                                        tab={tab}
                                                                        company={company}
                                                                        checkboxes={checkboxes}
                                                                        loadingLeftSide={loadingLeftSide}
                                                                        setLoadingLeftSide={setLoadingLeftSide}
                                                                        setReloadLeftSide={setReloadLeftSide}
                                                                        //checked={targetState[target.id_alvo]} //Need Change
                                                                    />
                                                                </Column>
                                                            )})}
                                                    </Collapsible>
                                                )})}
                                        </Collapsible>
                                    ) 
                                })}
                        </>
                    )}
            </TargetList>
        </PlansTabStyled>
    );
};

TargetTab.propTypes = {
    data: PropTypes.object,
    tab: PropTypes.any,
    loadingLeftSide: PropTypes.any,
    setLoadingLeftSide: PropTypes.func,
    setReloadLeftSide: PropTypes.func
};

export default TargetTab;
