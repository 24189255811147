import { djangoApi } from '../api';

export const postPlan = (planData) => {
    return djangoApi.post(`planos/`, planData);
}

export const getPlanByCompanyID = async (id_empresa) => {
    try {
        const response = await djangoApi.get(`planos/?id_empresa=${id_empresa}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getPlan = async (id_plano) => {
    try {
        const response = await djangoApi.get(`planos/${id_plano}/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getPlans = async () => {
    try {
        const response = await djangoApi.get(`planos/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const updatePlan = (id_plan, planData) => {


    return djangoApi.put(`planos/${id_plan}/`, planData);
}

export const getPlansInfos = async () => {
    try {
        const response = await djangoApi.get(`planos_infos/?id_plano=`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const getPlansInfosByPlanID = async (id_plan) => {
    try {
        const response = await djangoApi.get(`planos_infos/?id_plano=${id_plan}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const deletePlan = async (id_plan) => {
    try {
        const response = await djangoApi.delete(`planos/${id_plan}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
};

export const patchPlan = (id_plan, planData) => {
    return djangoApi.put(`planos/${id_plan}/`, planData);
};

