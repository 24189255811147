import news from '../../images/custom_icons/news_icon.png';
import newsActive from '../../images/custom_icons/news_active_icon.png';
import forecast from '../../images/custom_icons/forecast_icon.png';
import forecastActive from '../../images/custom_icons/forecast_active_icon.png';

const menuSite = [
    {
        key: 'news',
        name: 'Notícia',
        customIcon: news,
        customIconActive: newsActive,
        link: '/site/noticias',
        child: [],
    },
    {
        key: 'weatherForecast',
        name: 'Previsão',
        customIcon: forecast,
        customIconActive: forecastActive,
        link: '/site/previsoes',
        child: [],
    },
];

export default menuSite;
