/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import 'moment/locale/pt-br';
import { useDispatch } from 'react-redux';
/* Hooks */
import { useToast } from 'Context/ToastContext';
/* Material UI */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ReportProblem from "@material-ui/icons/ReportProblem";
import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import file_present_icon from 'images/file_present_icon.png';
import { FaFilePdf } from "react-icons/fa";
/* CSS */
import styles from "../forecast_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Services */
import { getUserAuthenticated } from "services/login/login";
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';
import { getLightningActiveAlertByTargetID } from "services/mapsPortal/alerts";
import { getHolidays } from "services/adminDashboard/holidays";
/* Components */
import UploadButton from "components/UploadButton/index";
/* Redux CSS */
import { modalProvider } from 'redux/actions/modalAction';

const useStyles = makeStyles({
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
  switch_secondary: {
    "&.Mui-checked": {
      color: "red",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "red",
    },
  },
  autocomplete: {
    "&.MuiInputBase-input": {
      height: "30px",
    },
  },
  divider: {
    background: "#A5A5A5",
  },
})


const FieldsComponent = (props) => {
  const moment = require('moment');
  moment.locale('pt-br');
  const fonts = fontStyles();
  const { classes, target, preset, alertsData, setAlertsData, plano, setReloadSwitchSelected, setReloadModal, allSelected, setAllSelected, allSelectedAux, setAllSelectedAux, setReloadErrorMessage, statusTargetSelected, setStatusTargetSelected, setReloadStatusTargetSelected, reloadTargets, setReloadTargets, weatherAlertErrors, setWeatherAlertErrors} = props;
  const switchStyle = useStyles();
  const [servicesStatus, setServicesStatus] = useState(true);
  const [hasTargetAlert, setHasTargetAlert] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [caractersRemaning, setCaractersRemaning] = useState();
  const [switchSelected, setSwitchSelected] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [targetIsActive, setTargetIsActive] = useState(false);
  const [loadingTargetData, setLoadingTargetData] = useState(0);
  const [targetData, setTargetData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const openToast = useToast();
  const [reload, setReload] = useState();
  var aux = 0;
  const dispatch = useDispatch();

  const [lightningAlertErrorsAux, setLightningAlertErrorsAux] = useState({
    titulo: "",
    assunto: "",
    file: "",
  });

  const [targetAlert, setTargetAlert] = useState(
    {
      titulo: "",
      assunto: "",
      filePresent: false,
      file: null,
      fileName: "",
      id_alvo: "",
      nome: "",
    }
  );

  var alertsDataAux = alertsData;

  const tools = ["Descargas", "Radar", "Satélite", "TSC"]

  var gridClasses = [
    classes.gridColumnsComponent,
    classes.gridMarginLeft,
  ]

  const toggleSwitchSelected = () => {
    if (hasTargetAlert) {
      if (!switchSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(!switchSelected);
          alertsDataAux.splice(index, 1);
          alertsDataAux.push(targetAlert);
          setAlertsData(alertsDataAux);
        }
        else {
          alertsDataAux.push(targetAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(!switchSelected);
        }
        let aux = weatherAlertErrors;
        let indexError = aux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (indexError != -1) {
          aux.splice(indexError, 1);
          aux.push(lightningAlertErrorsAux);
          setWeatherAlertErrors(aux);
        }
        else {
          aux.push(lightningAlertErrorsAux);
          setWeatherAlertErrors(aux);
        }
      }
      else {
        setAllSelected(false);
        setAllSelectedAux(true);
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        let aux = weatherAlertErrors;
        aux = aux.filter((item) => target.id_alvo != item.id_alvo);
        setWeatherAlertErrors(aux);
        setAlertsData(alertsDataAux);
        setSwitchSelected(!switchSelected);
      }
    }
    setReloadSwitchSelected(true);
  }


  const loadTargetData = async () => {
    if(target.id_alvo){
      await getTargetInfosByTargetID(target.id_alvo).then((response) => {
        if(response[0].length > 0){
          setTargetData(response[0][0][0]);
          setLoadingTargetData(1);
          loadServiceStatus(response[0][0][0].servicos);
        }
        else{
          setLoadingTargetData(2);
        }
      }).catch((error) => {
        setLoadingTargetData(2);
      });
    }
  }

  const loadServiceStatus = (servicesData) => {
    var stringfyLightningAlert = servicesData?.filter((alert) => alert.tipo === "Aviso Meteorológico" && alert.status === true);

    if(servicesData && servicesData[0]){
        stringfyLightningAlert.length > 0? setServicesStatus(true) : setServicesStatus(false);
        loadUserAuthenticated()
    }
  }

  const loadUserAuthenticated = async () => {
    var loginToken = JSON.parse(localStorage.getItem("reeWAL57qe"));

    await getUserAuthenticated(loginToken).then((response) => {
      if (response.status === 200) {
        targetAlert.meteorologista = response.data.usuario.id;
      }
    }).catch((error) => {
      openToast('error', 'Problema ao buscar usuário autenticado, recarregue a página e tente novamente!')
    });
  }

  const removeFileUploaded = () => {
    setTargetAlert({...targetAlert, file: null, filePresent: false, fileName: ""});
    aux = aux + 1;
    setReload(aux);
  };

  const handleFileUpload = (data) => {
    if(data){
      let reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = (e) => {
        setTargetAlert({...targetAlert, file: e.target.result, filePresent: true, fileName: data.name});
      }
      aux = aux + 1;
      setReload(aux);
    }
  };

  
  const handlePdfViewer = () => {
    if(targetAlert.filePresent) {
      dispatch(modalProvider(true, targetAlert.file, null, null, "PdfViewer", null));
    }
  };
  

  useEffect(() => {
    targetAlert.titulo = preset.titulo;
    targetAlert.assunto = preset.assunto;
    targetAlert.filePresent = preset.filePresent;
    targetAlert.file = preset.file;
    targetAlert.fileName = preset.fileName;
    setReloadModal(true);
  }, [preset.preset]);
  
 
  useEffect(() => {
    if (target) {
      targetAlert.id_alvo = target.id_alvo;
      targetAlert.nome = target.nome;
      lightningAlertErrorsAux.id_alvo = target.id_alvo;
      loadTargetData();
    }
  }, []);

  useEffect(() => {
    if (servicesStatus) {
      if (allSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(allSelected);
        }
        else {
          alertsDataAux.push(targetAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(allSelected);
          let aux = weatherAlertErrors;
          aux.push(lightningAlertErrorsAux);
          setWeatherAlertErrors(aux);
        }
      }
      else if (!allSelected && !allSelectedAux) {
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(allSelected);
        let aux = weatherAlertErrors;
        aux = aux.filter((item) => target.id_alvo!= item.id_alvo);
        setWeatherAlertErrors(aux);
      }
    }
  }, [allSelected])

  useEffect(() => {
    if (!servicesStatus) {
      let aux = statusTargetSelected;
      if (!aux.includes(target.nome)) {
        aux.push(target.nome);
        setStatusTargetSelected(aux);
        setReloadStatusTargetSelected(true);
      }
      else{
        if(!switchSelected){
          aux.pop(target.nome);
        }
      }
    }
  }, [switchSelected])

  useEffect(() => {
    setReloadTargets(false);
    loadTargetData();
  }, [reloadTargets])

  useEffect(() => {
    if (servicesStatus) {
      if (switchSelected) {
        if (targetAlert.titulo === "") {
          lightningAlertErrorsAux.titulo = "Campo Título é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.titulo =null;
        }
        if (targetAlert.assunto === "") {
          lightningAlertErrorsAux.assunto = "Campo Assunto é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.assunto = null;

        }
        if (targetAlert.file === null) {
          lightningAlertErrorsAux.file = "Campo PDF é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.file = null;
        }

        var index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          let aux = alertsDataAux;
          aux.splice(index, 1);
          aux.push(targetAlert);
          setAlertsData(aux);
          let aux2 = weatherAlertErrors;
          aux2.splice(index, 1);
          aux2.push(lightningAlertErrorsAux);
          setWeatherAlertErrors(aux2);
        }
        else {
          let aux = alertsDataAux;
          aux.push(targetAlert);
          setAlertsData(aux);
          let aux2 = weatherAlertErrors;
          aux2.push(lightningAlertErrorsAux);
          setWeatherAlertErrors(aux2);
        }
      }
      setReloadErrorMessage(true);
    }
   
  }, [targetAlert?.titulo, targetAlert?.assunto, targetAlert?.file, preset.preset, switchSelected])

  /*useEffect(() => {
    
      if(loadingContacts){
        let aux = loadingRightSide;
        aux.push(0);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
      else{
        let aux = loadingRightSide;
        aux.pop(0);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
    
    /*else{
      let aux = loadingRightSide;
      aux.pop(false);
      setLoadingRightSide(aux);
      setReloadRightSide(true);
    }*/
  /*}, [loadingContacts])*/


  if (target && loadingTargetData === 1 && servicesStatus) {
    return (
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
          {targetAlert.filePresent &&
            <div className={classes.filePresentContainer}>
              <div className={classes.fileRemoveContainer} onClick={() => removeFileUploaded()}>
                <img src={file_present_icon} style={{width: "40px", height: "40px"}}/>
                {targetAlert.fileName && <span className={classes.fileNameText}>{targetAlert.fileName?.length > 38? targetAlert.fileName?.substring(0, 38) + "..." : preset.fileName}</span>}
              </div>
            </div>
          }
          {!targetAlert.filePresent &&
            <div>
              <UploadButton onChange={handleFileUpload} fileType={"pdf"} setFileName={setTargetAlert} setErrorMessage={setErrorMessage} buttonName={"Anexar PDF"}/>
            </div>
          } 
            <div style={{display: "flex", flexDirection: "column", width: "75%", justifyContent: "center", alignItems: "flex-start"}}>
              <TextField
                id="title"
                label="Título"
                multiline
                shrink={true}
                value={targetAlert?.titulo}
                disableClearable
                onChange={(e) => setTargetAlert({ ...targetAlert, titulo: e.target.value })}
                className={classes.textFieldWeatherIndex}
                variant="outlined"
                color="primary"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: fonts.formsFieldHeight4, maxLength: 50 }} />

              {/*titleError && <p className={classes.errorMessage}>{titleError}</p>*/}
            </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "15px"}}>
          <div className={!preset.filePresent? classes.disabledViewButton : classes.viewButton} style={{width: "144px"}} onClick={(handlePdfViewer)}>
            <span style={fonts.buttonDefault} className={classes.text}>VER PDF</span>
            <FaFilePdf style={{marginLeft: "6px", width: "18px", height: "18px"}} className={classes.icon}/>
          </div>
          <div style={{display: "flex", flexDirection: "column", width: "75%", justifyContent: "center", alignItems: "flex-start"}}>
            <TextField
              id="subject"
              label="Assunto"
              multiline
              rows={2}
              value={targetAlert?.assunto}
              onChange={(e) => setTargetAlert({ ...targetAlert, assunto: e.target.value })}
              className={classes.textFieldWeatherIndex}
              margin="normal"
              variant="outlined"
              inputProps={{style: fonts.formsField, maxLength: 150}}
              InputLabelProps={{style: fonts.formsLabel}}
              InputProps={{
                endAdornment:
                  <span className={classes.endAdornment} style={{marginTop: "5%"}}>{"/150"}</span>
              }}
            />
  
            {/*subjectError && <p className={classes.errorMessage}>{subjectError}</p> SUBJECT COUNT ACIMAAA*/}
          </div>
        </div>
            
        <div style={{display: "flex", marginTop: "20px", width: "100%"}}>
          <Divider />
        </div>
      </div>
    ) 
  } 
  /*else if(loadingTargetData === 0){
    return (
        <div style={{justifyContent: "center"}}>
          <CircularProgress style={{ marginLeft: "calc(80%)" }} size={50} thickness={1} color="primary"/>
      </div>
    )
  }*/
  else if(loadingTargetData === 2){
    return (
      <div style={{justifyContent: "center", width: "100%", marginTop: "20px", backgroundColor: "lightblue"}}>
        <span span style={fonts.mapSidebarTargetDescripitionItem}>{`Problema ao carregar informações do alvo ${target.nome}, recarregue a página e tente novamente!`}</span>
      </div>
    )
  }
}


FieldsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  preset: PropTypes.any,
  target: PropTypes.any,
  plano: PropTypes.any,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  setReloadModal: PropTypes.string.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  allSelectedAux: PropTypes.any,
  setAllSelectedAux: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
  setLoadingRightSide: PropTypes.func.isRequired,
  setReloadRightSide: PropTypes.func.isRequired,
  loadingRightSide: PropTypes.any.isRequired,
  weatherAlertErrors: PropTypes.func.isRequired,
  setWeatherAlertErrors: PropTypes.func.isRequired,
};

export default withStyles(styles)(FieldsComponent);