import React, {memo, useEffect, useState} from 'react'
import {Layer, Source} from 'react-map-gl';
import { useActivesStatus } from 'Context/ActivesContext';

const ActiveComponent = () => {
  const { activesDataStatus, toggleAllActives, changeActivesStatus } = useActivesStatus();

  const [layerKey, setLayerKey] = useState(Date.now());

  useEffect(() => {
    toggleAllActives();
  }, [changeActivesStatus]);

  useEffect(() => {
    if (activesDataStatus) {
      setLayerKey(Date.now());
    }
  }, [activesDataStatus]);


  
  if(!activesDataStatus || activesDataStatus?.features?.length === 0 ) return null;
  return (
    <>
      <Source
        key={`active-${layerKey}`} // Força recriação
        id={`active`}
        type="geojson"
        data={activesDataStatus}
        generateId={true}
        >
        <Layer
            id={`active-line`}
            source={`active-line`}
            type="line"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            filter={['==', ['get', 'status'], true]}
            paint={{
              'line-color': "#000000",
              'line-width': 1,
            }}
        />
       <Layer
          id={`active-point`}
          source={`active-point`}
          type="circle"
          layout={{
            visibility: 'visible',
            // visibility: !showTargets
              // ? 'none'
              // : 'visible',
          }}
          filter={['==', ['get', 'status'], true]}
          paint={{
            'circle-color': '#000000',
            'circle-radius': {
              'base': 1.75,
              'stops': [
                [12, 2],
                [22, 180]
              ]
            },
          }}
        />
      </Source> 
    </>
  )
}  


ActiveComponent.propTypes = {
}

export const ActiveLayer = memo(ActiveComponent);
