export default function (state = null, action){

    switch(action.type){
        case "POLYGON_PROVIDER":
            return action.payload
    default: return state
    }
}




