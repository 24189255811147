import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './papperStyle-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import fontStyles from 'styles/fonts-jss';
import { FaArrowLeft } from 'react-icons/fa';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTheme } from '@material-ui/core/styles';
import { FaClipboard } from 'react-icons/fa';
/* Redux */
import { tabMenuProvider } from 'redux/actions/tabMenuAction';
import { sidebarMenuProvider } from 'redux/actions/sidebarMenuAction';



const useStyles = makeStyles({
    tabs: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#FFB703',
        },
        '& .MuiTabs-root': {
            backgroundColor: '#FFFFFF',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#FFB703',
        },
    },
});

function PapperBlock(props) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();
    const fonts = fontStyles();
    const tabStyle = useStyles();
    const tabMenuValue = useSelector((state) => state.tabMenuProvider);
    const [tabsValue, setTabsValue] = useState(tabMenuValue);
    const navigationContext = JSON.parse(sessionStorage.getItem('cLFfX97S5j'));
    const siteNavigationContext = JSON.parse(
        sessionStorage.getItem('news_information'),
    );
    const [pageLinks, setPageLinks] = useState(null);
    const [pageNames, setPageNames] = useState(null);
    const [activeHover, setActiveHover] = useState(false);
    const location = useLocation();

    const [windowSize, setWindowSize] = useState([
      window.innerWidth,
      window.innerHeight,
    ]);
    
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
      };
    
      window.addEventListener('resize', handleWindowResize);
    
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const {
        classes,
        title,
        desc,
        children,
        whiteBg,
        noMargin,
        colorMode,
        overflowX,
        icon,
        indexMenu,
        tabMenu,
        tabsList,
        fronttabs,
        disabledYouAreHere,
        noPageLink,
    } = props;

    const handleChangeTabs = (event, newValue) => {
        setTabsValue(newValue);
        /*dispatch(tabMenuProvider(newValue));*/
    };

    useEffect(() => {
        if (tabMenuValue || tabMenuValue === 0) {
            setTabsValue(tabMenuValue);
        }
    }, [tabMenuValue]);

    useEffect(() => {
        if(!disabledYouAreHere){
            setYouAreHere();
        }
    }, []);

    const goBack = () => {
        if(!noPageLink){
            if (pageNames[pageNames?.length - 1] === 'planos') {
                dispatch(tabMenuProvider(1));
            } else if (pageNames[pageNames?.length - 1] === 'usuarios') {
                dispatch(tabMenuProvider(2));
            } else if (pageNames[pageNames?.length - 1] === 'alvos') {
                dispatch(tabMenuProvider(1));
            }
            history.goBack();
        }
        history.goBack();
    };

    const navigation = (data, pageNames, pageIdsToNames, pageIds) => {
        if (location.state?.path === 'planosGeral') {
            if (data === 'planos') {
                history.replace({
                    pathname: '/administracao/planos',
                    state: { planData: null, path: null, targetData: null },
                });
            } else if (data === 'empresas') {
                history.replace({
                    pathname: '/administracao',
                    state: { planData: null, path: null, targetData: null },
                });
                dispatch(sidebarMenuProvider('home'));
            }
        } else if (pageNames.includes(data)) {
            if (data === 'empresas') {
                history.replace({
                    pathname: window.location.pathname.slice(
                        0,
                        window.location.pathname.indexOf(data),
                    ),
                    state: { planData: null, path: null, targetData: null },
                });
            } else {
                history.replace({
                    pathname: window.location.pathname.slice(
                        0,
                        window.location.pathname.indexOf(data) - 1,
                    ),
                    state: {
                        planData: location.state.planData
                            ? location.state.planData
                            : null,
                        path: data,
                        targetData: location.state.targetData
                            ? location.state.targetData
                            : null,
                    },
                });
            }
        } else {
            if (
                window.location.pathname !=
                window.location.pathname.slice(
                    0,
                    window.location.pathname.indexOf(
                        pageIds[pageIdsToNames.indexOf(data)],
                    ) + pageIds[pageIdsToNames.indexOf(data)].length,
                )
            ) {
                history.replace({
                    pathname: window.location.pathname.slice(
                        0,
                        window.location.pathname.indexOf(
                            pageIds[pageIdsToNames.indexOf(data)],
                        ) + pageIds[pageIdsToNames.indexOf(data)].length,
                    ),
                    state: {
                        planData: location.state.planData
                            ? location.state.planData
                            : null,
                        path: null,
                        targetData: location.state.targetData
                            ? location.state.targetData
                            : null,
                    },
                });
            }
        }
    };

    const setYouAreHere = () => {
        var pageUrl = window.location.pathname
            .split('/')
            .filter((data) => data != '' && data != 'painel_do_administrador');
        var pageNames = window.location.pathname
            .split('/')
            .filter(
                (data) =>
                    data != '' &&
                    data != 'painel_do_administrador' &&
                    !/\d/.test(data),
            );
        var pageIds = window.location.pathname
            .split('/')
            .filter((data) => /\d/.test(data));

        let pageIdsToNames = '';

        if (navigationContext && navigationContext[0] === null && siteNavigationContext) {
            pageIdsToNames = pageIds.map(
                (id) =>
                    siteNavigationContext[
                        siteNavigationContext.indexOf(id) + 2
                    ],
            );
        } else if(navigationContext && navigationContext[0]){
            pageIdsToNames = pageIds.map(
                (id) => navigationContext[navigationContext.indexOf(id) + 1],
            );
        }
        else{
            return;
        }

        var pageLinkArray = pageUrl.map((data) =>
            pageNames.includes(data)
                ? data
                : pageIds.includes(data)
                ? pageIdsToNames[pageIds.indexOf(data)]
                : '',
        );
        var pageLinkComponents = pageLinkArray.map((data, index) => (
            <span
                key={index}
                className={classes.progressMenuButton}
                onClick={() =>
                    navigation(data, pageNames, pageIdsToNames, pageIds)
                }
            >
                {'/' + data[0].toUpperCase() + data.substr(1) + ' '}
            </span>
        ));

        setPageNames(pageNames);
        setPageLinks(pageLinkComponents);
    };

    return (
        <div>

            <Paper
                className={classNames(
                    classes.root,
                    noMargin && classes.noMargin,
                    colorMode && classes.colorMode,
                )}
                elevation={0}
                disabled
            >
                <div className={classes.descBlock}>
                    <div className={classes.contentContainer}>
                        <div className={classes.titleContainer}>
                            {props.goBack && (
                                <Button
                                    color="primary"
                                    onClick={() => goBack()}
                                >
                                    {' '}
                                    <FaArrowLeft
                                        className={classes.arrowBackIcon}
                                    />
                                </Button>
                            )}
                            <div className={classes.titleSubContainer}>
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    className={classes.title}
                                >
                                    {title}
                                </Typography>
                                {desc && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                        }}
                                    >
                                        <FaClipboard
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                color: '#023047',
                                            }}
                                        />
                                        <Typography
                                            component="p"
                                            className={classes.description}
                                        >
                                            {desc}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                        {pageLinks && pageLinks.length > 0 && (
                            <div
                                id="align_loc"
                                className={classes.progressMenuContainer}
                            >
                                <span
                                    style={fonts.progressMenuText}
                                    className={classes.progressMenuText}
                                >
                                    Você está aqui:{' '}
                                    {pageLinks.map((data) => data)}
                                </span>
                            </div>
                        )}
                        {/*<div style={{display:"flex", flexDirection: "row", position: "relative", marginRight: "15%"}}>
              {props.progressBar && ( 
              <StepProgressMenu />
              )}
            </div>*/}
                    </div>
                </div>
                {tabsList && tabsList.length > 0 && windowSize[0] > 400  ? ( 
                        <div className={classes.tabsMainContainer}>
                            <div className={classes.tabsContainer}>
                                    <Tabs
                                        value={tabsValue}
                                        onChange={handleChangeTabs}
                                        className={tabStyle.tabs}
                                        variant="fullWidth"
                                        aria-label="tabs"
                                        selectionFollowsFocus={false}
                                        style={{
                                            height: '80%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#FFFFFF',
                                        }}
                                        centered={true}
                                    >
                                        {tabsList.map((tab, index) => (
                                            <Tab key={index} label={tab.label} />
                                        ))}
                                    </Tabs>
                            </div>
                        </div>

                    ):("")
                }  

                {tabsList && tabsList.length > 0 && windowSize[0] < 400  ? ( 
                    <div style={{height:"288px"}} className={classes.tabsMainContainer}>
                        <div style={{height:"revert", width:"100%"}}className={classes.tabsContainer}>
                            <Tabs
                            value={tabsValue}
                            onChange={handleChangeTabs}
                            className={tabStyle.tabs}
                            variant="fullWidth"
                            orientation="vertical"
                            aria-label="tabs"
                            style={{
                                height: '80%',
                                paddingLeft:"18%",
                                display: 'flex',
                                alignItems: 'center',
                                color: '#FFFFFF',
                            }}
                            centered={true}
                            >
                            {tabsList.map((tab, index) => (
                                <Tab key={index} label={tab.label} />
                            ))}
                            </Tabs>
                        </div>
                    </div>
                ):("")
                }            
                <section
                    className={classNames(
                        classes.content,
                        whiteBg && classes.whiteBg,
                        overflowX && classes.overflowX,
                    )}
                >
                    {tabsList && tabsList.length > 0
                        ? tabsList.map(
                              (tab, index) =>
                                  tabsValue === index && tab.component,
                          )
                        : children}
                </section>
            </Paper>
        </div>
    );
}

PapperBlock.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string,
    goBack: PropTypes.bool,
    icon: PropTypes.string,
    children: PropTypes.node.isRequired,
    whiteBg: PropTypes.bool,
    colorMode: PropTypes.bool,
    noMargin: PropTypes.bool,
    overflowX: PropTypes.bool,
    customIcon: PropTypes.object,
    progressBar: PropTypes.bool,
    indexMenu: PropTypes.number,
    tabMenu: PropTypes.bool,
    fronttabs: PropTypes.bool,
    tabsList: PropTypes.array,
    disabledYouAreHere: PropTypes.any,
    noPageLink: PropTypes.any,
};

PapperBlock.defaultProps = {
    whiteBg: false,
    noMargin: false,
    colorMode: false,
    overflowX: false,
    icon: 'ion-ios-bookmark-outline',
};

export default withStyles(styles)(PapperBlock);