import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getTargetList } from 'services/mapsPortal/initialLoad';
import useLoad from 'hooks/useLoad';
import useTogglePlans from 'hooks/useTogglePlans';
import useToggle from 'hooks/useToggle';
import { getTargets } from 'services/historyDischarges';
import { useSelector } from 'react-redux';
import {useTools} from 'Context/ToolsContext';
import useInterval from 'hooks/useInterval';

export const HistoryTargetContext = createContext();

export const HistoryTargetContextProvider = (props) => {
    const { polygon } = useTools();
    const polygonSelected = useSelector(state => state.polygonProvider);
    const polygonData = polygonSelected && polygon.finishPolygon && polygon.polygonLine.length > 3? {
    "geometria": {
      "coordinates": [
        [
          [polygonSelected[0][0], polygonSelected[0][1]],
          [polygonSelected[1][0], polygonSelected[0][1]],
          [polygonSelected[1][0], polygonSelected[1][1]],
          [polygonSelected[0][0], polygonSelected[1][1]],
          [polygonSelected[0][0], polygonSelected[0][1]]
        ]
      ],
    "type": "Polygon"
    }
  } : null 

    const{ data: historyTargets, loading: setHistoryTargets} = useLoadWhenTargetIsActive(() => getTargets(polygonData), polygonData != null);

    return (
        <HistoryTargetContext.Provider
            value={{
              historyTargets,
            }}
        >
            {props.children}
        </HistoryTargetContext.Provider>
    );
};

HistoryTargetContextProvider.propTypes = {
    children: PropTypes.node,
};

export default HistoryTargetContextProvider;

const useLoadWhenTargetIsActive = (func, active) => {
  const callback = useCallback(() => ({}), [])
  const{ data, loading, update, set} = useLoad(callback, []);

  useEffect(()=> {
    if(active) set(func)
      
    else set(callback)
  }, [active])

  useInterval(update, active ? 60000 : null);

  return {data, loading, update, set}
}

export const useHistoryTarget = () => {
  const context = useContext(HistoryTargetContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}
