/* Libraries */
import React from 'react';
/* Components */
import Outer from '../../containers/Templates/Outer';
import LoginBanner from './LoginBanner';

function LoginDedicated() {
  return (
    <Outer>
      <LoginBanner />
    </Outer>
  );
}

export default LoginDedicated;
