import { javaApi, lambdaApi } from '../api';


export const getHistoryDischarges = async (id_alvo, tempo, fonte, polygon) => {
    try {
        const response = await javaApi.post(`descargas/mobile/?id_alvo=${id_alvo}&tempo=${tempo}&fonte=${fonte}`, polygon);
        return {type: 'FeatureCollection', features: response.data}
    } catch (error) {
      throw new Error(`Não foi possível obter as descargas.`);
    }
  };

  export const getHistoryDischargesInterval = async (polygon) => {
    try {
        const response = await javaApi.get(`descargas/historico/?data_inicial=202308271100&data_final=202308271200`, polygon);
        return {type: 'FeatureCollection', features: response.data}
    } catch (error) {
      throw new Error(`Não foi possível obter as descargas.`);
    }
  };

  export const getTargets = async (polygon) => {
    try {
        const response = await lambdaApi.post(`alvos`, polygon);
        return {type: 'FeatureCollection', features: response.data[0].alvos_por_poligono}
    } catch (error) {
      throw new Error(`Não foi possível obter os alvos.`);
    }
  };

  export const getAlertsReport = async (idCompany, initalDate, finalDate) => {
    try {
        const response = await lambdaApi.get(`historico/alertas?data_inicial=${initalDate}&data_final=${finalDate}&id_empresa=${idCompany}`);
        return response;
    } catch (error) {
      throw new Error(`Não foi possível obter o relátorio de alertas.`);
    }
  };

  export const getHistoryActives = async (id_alvo, polygon) => {
    try {
        const response = await lambdaApi.post(`ativos/mobile?id_alvo=${id_alvo}`, polygon);
        return {type: 'FeatureCollection', features: response.data}
    } catch (error) {
      throw new Error(`Não foi possível obter os alvos.`);
    }
  };

  export const getActives = async () => {
    try {
        const response = await lambdaApi.get(`ativos`);
        /*var dataResponse;
        if(response){
          var parsedData = JSON.parse(response);
          var featuresData = parsedData[0].ativos_agrupados;
          if(featuresData){
            dataResponse = {
              type: "FeatureCollection",
              features: featuresData
            }
          }
        }*/
        return response.data;
    }
    catch (error) {
        return [];
    }
  };
  