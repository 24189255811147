import React, { useEffect, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyDischargesControlProvider } from 'redux/actions/historyDischargesControlAction';
import { historyDischargesBuffersProvider } from 'redux/actions/historyDischargesBuffersAction';
import { buffer } from '@turf/turf';
import { Buffer } from './Buffer';

const createBuffer = (feature, bufferValue, bufferColor) => {
  const a = {...feature}
  const x = buffer(a, bufferValue);
  x.properties = {
    ...feature.properties,
    buffer: bufferValue.toString(),
    color: bufferColor
  }
  return x
}

const BuffersLayerComponent = () => {
  const data = useSelector(state => state.historyDischargesBuffersProvider);
  const dataControl = useSelector(state => state.historyDischargesControlProvider);
  const activedBuffer = useSelector(state => state.historyDischargesBuffersControlProvider);
  const [buffersData, setBuffersData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if(data.length > 0){
      let temp = [];
      data?.map((target) => temp.push([]));
      data?.map((target, index1) => target.buffers?.map((buff, index2) => {
        temp[index1].push(createBuffer(target.features, buff, target.colors[index2]));
      }));
      setBuffersData(temp);
      setReload(true);
    }
  }, [data, dataControl[1]])

  useEffect(() => {
    setReload(true);
  }, [])

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload])

  return(
    <>
      {
       dataControl && dataControl[0] && activedBuffer && activedBuffer[0]?.length > 0 && buffersData?.length > 0 && buffersData?.map((target, index1) => {
          if(dataControl[0][index1]){
            return target.map((buffer, index2) => {
              if(activedBuffer[0][index1][index2]){
                return(
                  <div key={index2}>
                    <Buffer buffers={buffer} indexTarget={index1} indexBuffer={index2}/>
                  </div>
                )
              }
            })
          }
        })
      }
    </>
  )
};

export const BuffersLayer = memo(BuffersLayerComponent);
