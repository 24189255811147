/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../utils/history';
import login from './modules/login';
import uiReducer from './modules/ui';
import initval from './modules/initForm';
import modalEmployeesProvider from './modules/formsReducer_Modal';
import accessGroupProvider from './modules/accessGroupReducer';
import prioritiesProvider from './modules/prioritiesReducer';
import tabMenuProvider from './modules/tabMenuReducer';
import sidebarMenuProvider from './modules/sidebarMenuReducer';
import navigationProvider from './modules/navigationReducer';
import modalProvider from './modules/modalReducer';
import mapSidebarMenuProvider from './modules/mapSidebarMenuReducer';
import polygonProvider from './modules/polygonReducer';
import printScreenProvider from './modules/printScreenReducer';
import historyDischargesDataProvider from './modules/historyDischargesDataReducer';
import historyDischargesControlProvider from './modules/historyDischargesControlReducer';
import historyDischargesTargetsControlProvider from './modules/historyDischargesTargetsControlReducer';
import historyDischargesBuffersProvider from './modules/historyDischargesBuffersReducer';
import historyDischargesBuffersControlProvider from './modules/historyDischargesBuffersControlReducer';
import mapBuffersStateProvider from './modules/mapBuffersStateReducer';
import mapCamerasStateProvider from './modules/mapCamerasStateReducer';
import reportCardContextProvider from './modules/reportCardContextReducer';
import openMapSidebarProvider from './modules/openMapSidebarReducer';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {

  const rootReducer = combineReducers({
    form,
    login,
    modalEmployeesProvider,
    accessGroupProvider,
    prioritiesProvider,
    tabMenuProvider,
    modalProvider,
    sidebarMenuProvider,
    navigationProvider,
    mapSidebarMenuProvider,
    polygonProvider,
    printScreenProvider,
    historyDischargesControlProvider,
    historyDischargesDataProvider,
    historyDischargesTargetsControlProvider,
    historyDischargesBuffersProvider,
    historyDischargesBuffersControlProvider,
    mapBuffersStateProvider,
    mapCamerasStateProvider,
    reportCardContextProvider,
    openMapSidebarProvider,
    ui: uiReducer,
    initval,
    router: connectRouter(history),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
