import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from './modal-jss';
import { withStyles } from '@material-ui/core/styles';


const Background = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
`

const Modal = ({ id= 'modal', onClose = () => {}, children, height, center }) => {

    const handleOutsideClick = (e) => {
        if(e.target.id === id) onClose();
    };

    return (
        <Background id={id} onClick={handleOutsideClick} style={{height: typeof height === "string" && height?.includes('vh')? height : (height+250)+"px", alignItems: center? "center" : "flex-start"}}>
            <div> {children} </div>
        </Background>
    )
}

Modal.propTypes = {
    classes: PropTypes.object, 
    id: PropTypes.any,
    onClose: PropTypes.func,
    children: PropTypes.any,
    height: PropTypes.number,
    center: PropTypes.bool,
  };

  export default withStyles(styles)(Modal);