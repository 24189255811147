import { useState, useEffect, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getFormatedActives } from 'services/adminDashboard/actives';


export const ActivesContext = createContext();

export const ActivesContextProvider = (props) => {

  const [loadingActives, setLoadingActives] = useState(false);
  const [activesDataStatus, setActivesDataStatus] = useState([]);
  const [reloadActives, setReloadActives] = useState(true);
  const [changeActivesStatus, setChangeActivesStatus] = useState(false);
  const [controlActivesStatus , setControlActivesStatus] = useState(true);


  const toggleSpecificActives = (id) => {
    if (activesDataStatus?.features?.length > 0) {
      const updatedFeatures = activesDataStatus.features.map((item) => {
        if (item.properties.id_ativo === id) {
          return {
            ...item,
            properties: {
              ...item.properties,
              status: !item.properties.status,
            },
          };
        }

        return item;
      });
      setActivesDataStatus({
        ...activesDataStatus, // Garante nova referência
        features: updatedFeatures,
      });

      setReloadActives(!reloadActives);
    }
  };

  const toggleAllActives = () => {
    if (activesDataStatus?.features?.length > 0) {
      setControlActivesStatus(!controlActivesStatus);
      const updatedFeatures = activesDataStatus.features.map((item) => {
          return {
            ...item,
            properties: {
              ...item.properties,
              status: controlActivesStatus,
            },
          };
      });

      setActivesDataStatus({
        ...activesDataStatus, // Garante nova referência
        features: updatedFeatures,
      });

      setReloadActives(!reloadActives);
    }
  };


  const loadActive = async () => {
    await getFormatedActives().then((response) => {
      if(response){
        var featuresData = response[0].ativos_agrupados;
        if(featuresData){

          setActivesDataStatus({
            type: "FeatureCollection",
            features: featuresData
          });

          setLoadingActives(false); 
        }
      }
    })
  }


  useEffect(() => {
    loadActive();
  }, []);

  if(!loadingActives)

  return (
    <ActivesContext.Provider
        value={{
          activesDataStatus,
          toggleSpecificActives,
          toggleAllActives,
          reloadActives, 
          setReloadActives,
          setActivesDataStatus,
          loadingActives, 
          setLoadingActives,
          changeActivesStatus, 
          setChangeActivesStatus
        }}
    >
        {props.children}
    </ActivesContext.Provider>
  );
};

ActivesContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ActivesContextProvider;

export const useActivesStatus = () => {
  const context = useContext(ActivesContext);

  if(typeof context === 'undefined'){
    throw new Error('useToast need to be used inside a <ToastContextProvider />')
  }

  return context;
}