import React, { useContext, useEffect, useState, useRef} from 'react';
import { useTools } from 'Context/ToolsContext';
import { useDischarges } from 'Context/DischargesContext';
import { useTarget } from 'Context/TargetContext';
import {useMapContext} from 'Context/MapContext';
import * as turf from '@turf/turf'
import {useSatellite} from 'Context/SatelliteContext';
import {useRadar} from 'Context/RadarContext';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';
// import { mapTools } from '../helpers/MapFunctions';
import {useAuth} from 'Context/AuthContext';

let layerID;

export const useMapClientPortal = (mapRef, viewport, setViewport, loading, setLoading) => {
    const { setPopup,  setShowPopup } = useMapContext();
    const { clientTargets } = useClientTargetsState();
    const { polygon } = useTools();
    const interactiveLayers = useInteractive();
    useLayerOrder(mapRef, loading, interactiveLayers);

    const {
        toolInUse,
        setToolInUse,
        measureLine,
        setMeasureLine,
        setPointHelper,
        dischargesHistory
    } = useTools();

    const [mapFunctions, setMapFunctions] = useState({
        onLoad: (event) => {
            setLoading(false);
        },
        onMove: (event) => {
            setViewport(event.viewState);
        },
    });

    useEffect(() => {
        layerID = null;
    }, []);

    useEffect(() => {
      if(!dischargesHistory && polygon.finishPolygon && polygon.polygonLine.length > 3){
        const processedTargets = clientTargets.reduce((popupTargets, company) => {
          const insidePlans = company.planos.reduce((popupTargets, plan) => {
            const insideTargets = plan.alvos.filter(
              (target) =>
              turf.booleanContains(turf.polygon([polygon.polygonLine]), target.coordenadas.features[1])
            )
            ?.map((target) => ({
              ...target,
              id_plano: plan.id_plano,
              "planId": plan.id,
              "targetId": target.id
            }))

            return [...popupTargets, ...insideTargets];
          }, [])
          return [...popupTargets, ...insidePlans];
        }, [])

        setPopup({
          longitude: polygon.polygonLine[0][0],
          latitude: polygon.polygonLine[0][1],
          dataDischarges: [],
          dataTargets: processedTargets,
          dataActives: [],
        })
        setShowPopup(processedTargets.length > 0)
      }
    }, [polygon.finishPolygon, polygon.polygonLine, clientTargets]);


    useEffect(() => {
        if(loading || mapRef === undefined) return;
        const map = mapRef.current;

        const defaultTool = {
            handleMapMouseMove: (event) => {
                let id = layerID;
                const opt = { layers: interactiveLayers };

                const feature = map.queryRenderedFeatures(event.point, opt);

                map.getCanvas().style.cursor = feature.length ? 'pointer' : '';
                // Caso já existam camadas com o efeito de hover, retirar o efeito deles.
                if (id) {
                    id.forEach((layer) => {
                        map.removeFeatureState({
                            source: layer.source,
                            id: layer.id,
                        });
                    });
                }
                // Atualiza quais as camadas com o mouse sobreposto.
                id = feature;
                // Novas camadas com o efeito de hover.

                id?.forEach((layer) => {
                    map.setFeatureState(
                        {
                            source: layer.source,
                            id: layer.id,
                        },
                        {
                            hover: true,
                        },
                    );
                });
               
                // Retorna o estado das camadas.
                layerID = id;
            },
            // Recebe o evento de click no mapa, uma referência ao mapa e as
            // camadas interativas.
            handleMapMouseClick: (event) => {
              setShowPopup(false);
              const opt = { layers: interactiveLayers };

              const layers = map.queryRenderedFeatures(event.point, opt);
                // Filtra as descargas selecionadas no click.
                // E mapeia o retorno das informações para mostrar no POPUP.
                const processedDischarges = layers
                    .filter((layer) => layer.layer.type === 'circle' && (layer.source.includes("groups") || layer.source.includes("flashes")))
                    .map((discharge) => {
                        return {
                            coordinates: discharge.geometry.coordinates,
                            ...discharge.properties,
                        };
                    });

                // Filtra as informações dos alvos.
                const seen = {};

                const processedTargets = layers
                    .filter((layer) => !layer.source.includes("groups") && !layer.source.includes("flashes") && !layer.layer.id.includes('active') && !layer.layer.id.includes('cameras'))
                    .map((target) => {
                        return {
                            ...target.properties,
                        };
                    })
                    .filter(target => {
                      if (seen[target.id_alvo]) return false
                      seen[target.id_alvo] = true;
                      return true
                    });
                    
                    const processedActiveTargets = layers
                      .filter((layer) => !layer.source.includes("groups") && !layer.source.includes("flashes") && !layer.layer.id.includes('active') && !layer.layer.id.includes('cameras'))
                      .map((target) => {
                        return(
                        target?.properties?.ativos && JSON.parse(target?.properties?.ativos)?.features?.length > 0 &&
                        JSON.parse(target?.properties?.ativos).features.map(
                          (active, index) => {
                            return {
                              ...active.properties,
                              "nome_alvo": target.properties.nome,
                              "grupo": index <= 20? "grupo 1" : "grupo 2"
                          }}
                        ))
                      })
                      /*.filter(target => {
                        if (seen[target.id_alvo]) return false
                        seen[target.id_alvo] = true;
                        return true
                      });*/
                    
                const processedActives = layers
                    .filter((layer) => layer.layer.id.includes('active'))
                    .map((active) => {
                        return {
                            ...active.properties,
                        };
                    })

                const processedCameras = layers
                    .filter((layer) => layer.layer.id.includes('cameras'))
                    .map((active) => {
                        return {
                            ...active.properties,
                        };
                    })

                    const popupInfo = {
                    longitude: event.lngLat.lng,
                    latitude: event.lngLat.lat,
                    dataDischarges: processedDischarges,
                    dataTargets: processedTargets,
                    dataActives: processedActives.length > 0? processedActives : processedActiveTargets[0]?.length > 0? processedActiveTargets[0] : [],
                    dataCameras: processedCameras,
                };
                setPopup(popupInfo);

                if (
                    popupInfo?.dataDischarges?.length > 0 ||
                    popupInfo?.dataTargets?.length > 0 ||
                    popupInfo?.dataActives?.length > 0 ||
                    popupInfo?.dataCameras?.length > 0
                ) {
                    setShowPopup(true);
                  }
            },
            // Zoom on double click;
            handleDblClick: (event) => {
              const map = mapRef.current;
              const zoom = map?.getZoom() + 1;
              map?.flyTo({
                center: [event.lngLat.lng, event.lngLat.lat],
                zoom: zoom,
                essential: true
              })
            },
            // Lida com o evento em que o mouse sai de qualquer camada interativa.
            handleMapMouseLeave: () => {
              // Retira os efeitos de hover.
              const id = layerID;
                if (id) {
                  id.forEach((layer) => {
                        map.setFeatureState(
                            {
                                source: layer.source,
                                id: layer.id,
                            },
                            {
                                hover: false,
                              },
                              );
                            });
                }

                map.getCanvas().style.cursor = '';
                layerID = null;
            },
        };

        const measureTool = {
            handleMapMouseMove: (event) => {
              const opt = { layers: ['toolPoint'] };
              const layers = map.queryRenderedFeatures(event.point, opt);
              map.getCanvas().style.cursor = layers.length > 1 ? 'pointer' : 'crosshair';

                if (measureLine.length > 0) {
                    setPointHelper([event.lngLat.lng, event.lngLat.lat]);
                }
            },
            handleMapMouseClick: (event) => {
              const opt = { layers: ['toolPoint'] };
              const layers = map.queryRenderedFeatures(event.point, opt);
              if(layers.length > 1) {
                setMeasureLine((prevMeasureLine) => {
                  const newMeasureLine = [...prevMeasureLine]
                  newMeasureLine.splice(layers[1].id, 1)
                  return newMeasureLine
                });
              }else{
                setMeasureLine((prevMeasureLine) => [
                    ...prevMeasureLine,
                    [event.lngLat.lng, event.lngLat.lat],
                ]);
              }
            },
            // Finish on double click;
            handleDblClick: (event) => {
              setToolInUse('default');
            },
            handleMapMouseLeave: (event) => {
                return;
            },
        };

        const mapTools = {
            default: defaultTool,
            measure: measureTool,
            selection: polygon.data.functions(),
        };

        setMapFunctions({
            ...mapFunctions,
            onMouseMove: (event) => {
                mapTools[toolInUse].handleMapMouseMove(event);
            },
            onMouseLeave: (event) => {
                mapTools[toolInUse].handleMapMouseLeave(event);
            },
            onClick: (event) => {
                mapTools[toolInUse].handleMapMouseClick(event);
            },
            onDblClick: (event) => {
                mapTools[toolInUse].handleDblClick(event);
            }
        });
    }, [mapRef, loading, interactiveLayers, toolInUse, measureLine, clientTargets]);
    return { mapFunctions, interactiveLayers };
};

const useInteractive = () => {
  const [interactiveLayers, setInteractiveLayers] = useState([]);
  const { groups10, groups20, groups30, groups40, groups50, groups60, flashes10, flashes20, flashes30, flashes40, flashes50, flashes60, showDischarges, dischargesToShow, dischargesTypeToShow } = useDischarges();
  const { planos: empresas, toShow, showTargets} = useTarget();
  const { clientTargets } = useClientTargetsState();
  const { polygon } = useTools();

  useEffect(() => {
    var newInteractiveLayers = [];
      if (groups60.type && dischargesToShow[5] && dischargesTypeToShow[0] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`groups-60`);
      if (groups50.type && dischargesToShow[4] && dischargesTypeToShow[0] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`groups-50`);
      if (groups40.type && dischargesToShow[3] && dischargesTypeToShow[0] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`groups-40`);
      if (groups30.type && dischargesToShow[2] && dischargesTypeToShow[0] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`groups-30`);
      if (groups20.type && dischargesToShow[1] && dischargesTypeToShow[0] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`groups-20`);
      if (groups10.type && dischargesToShow[0] && dischargesTypeToShow[0] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`groups-10`);
      if (flashes60.type && dischargesToShow[5] && dischargesTypeToShow[1] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`flashes-60`);
      if (flashes50.type && dischargesToShow[4] && dischargesTypeToShow[1] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`flashes-50`);
      if (flashes40.type && dischargesToShow[3] && dischargesTypeToShow[1] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`flashes-40`);
      if (flashes30.type && dischargesToShow[2] && dischargesTypeToShow[1] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`flashes-30`);
      if (flashes20.type && dischargesToShow[1] && dischargesTypeToShow[1] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`flashes-20`);
      if (flashes10.type && dischargesToShow[0] && dischargesTypeToShow[1] && !polygon.finishPolygon && polygon.polygonLine < 4) newInteractiveLayers.push(`flashes-10`);
      //if (historyFlashes10.type && dischargesToShow[0] && dischargesTypeToShow[1] && polygon.finishPolygon && polygon.polygonLine > 3) newInteractiveLayers.push(`flashes-10`);

    if (showTargets && clientTargets?.length > 0){
        clientTargets[0].planos.forEach((plan) => {
          plan.alvos.forEach((target) => {
            //toShow.get(userData.id, plan.id, target.id) && 
            if(target.status){
              newInteractiveLayers.push(`${target.nome}-${target.id_alvo}`);
              /*if(target.ativos_ativo && target.ativos){
                target.ativos.features.map((ativo, index) => {
                  newInteractiveLayers.push(`${ativo.properties.Nome}-${target.id_alvo}-${index}-active${ativo.geometry.type === "Point"? "-points" : ""}`);
                })
              }*/
            }
          })
        })
     

      if(!window.location.href.includes('cliente')){
        newInteractiveLayers.push("active-line");
        newInteractiveLayers.push("active-point");
        newInteractiveLayers.push("cameras");
      }
    }
    setInteractiveLayers(newInteractiveLayers)

  }, [showDischarges, JSON.stringify(dischargesToShow), dischargesTypeToShow, clientTargets, showTargets, JSON.stringify(toShow.data), groups10, groups20, groups30, groups40, groups50, groups60, flashes10, flashes20, flashes30, flashes40, flashes50, flashes60, clientTargets])
  return interactiveLayers;
}

const useLayerOrder = (mapRef, loading, interactiveLayers) => {
  const { showSatellite} = useSatellite();
  const { radar, showRadar } = useRadar();
  const { showTargets } = useTarget();

  useEffect(() => {
    if(loading) return;
    setTimeout(()=>{
      if(showSatellite) mapRef.current.moveLayer('goes')
      if(showRadar) {
        radar.forEach(radar => mapRef.current.moveLayer(`radar-${radar[0].nome}`))
      }
      if(showTargets){
        interactiveLayers?.forEach(layer => {
          mapRef.current?.moveLayer(layer);
        })
      }
    }, 5000)
            // mapRef.current.moveLayer('descargas');
  }, [mapRef, loading, JSON.stringify(interactiveLayers), showTargets, showSatellite, showRadar])
}
