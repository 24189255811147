import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import fontStyles from 'styles/fonts-jss';
import styles from './charts-jss';

import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LabelList,
    Label,
} from 'recharts';

import Loading from 'components/Loading';
import moment from 'moment';

import { Typography } from '@material-ui/core';

const BarChartComponent = (props) => {
    const fonts = fontStyles();
    const { classes } = props;

    let formattedData;

    if (props.type === 'máximo') {
        if (props.mesuare === null || props.mesuare === 1) {
            formattedData = props.chartData
                ? props.chartData.map((item) => ({
                      name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                      'm/s': item.velocidade_maxima,
                      vento: item.direcao_velocidade_maxima,
                  }))
                : [];
        } else {
            formattedData = props.chartData
                ? props.chartData.map((item) => ({
                      name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                      'km/h': item.velocidade_maxima * 3.6,
                      vento: item.direcao_velocidade_maxima,
                  }))
                : [];
        }
    } else {
        if (props.mesuare === null || props.mesuare === 1) {
            formattedData = props.chartData
                ? props.chartData.map((item) => ({
                      name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                      'm/s': item.velocidade_vento_medio,
                      vento: item.direcao_vento_medio,
                  }))
                : [];
        } else {
            formattedData = props.chartData
                ? props.chartData.map((item) => ({
                      name: moment(item.intervalo_tempo).format('DD/MM HH[h]'),
                      'km/h': item.velocidade_vento_medio * 3.6,
                      vento: item.direcao_vento_medio,
                  }))
                : [];
        }
    }

    const limitedData = formattedData.slice(0, 73);

    if (limitedData && limitedData.length > 0) {
        return (
            <div className={classes.container}>
                <Typography
                    variant="h6"
                    align="center"
                    style={{
                        fontSize: '15px',
                        margin: '0',
                        backgroundColor: '#8ECAE6',
                        padding: '7px 0 ',
                        borderRadius: '7px 7px 0 0',
                    }}
                    gutterBottom
                >
                    {props.mesuare === null || props.mesuare === 1
                        ? `Vento ${props.type
                              .charAt(0)
                              .toUpperCase()}${props.type.slice(1)} m/s`
                        : `Vento ${props.type
                              .charAt(0)
                              .toUpperCase()}${props.type.slice(1)} km/h`}
                </Typography>

                <div className={classes.grafico}>
                    <ResponsiveContainer width={'100%'} height={300}>
                        <BarChart
                            width={800}
                            height={300}
                            data={limitedData}
                            margin={{
                                top: 20,
                                right: 50,
                                left: 5,
                                bottom: 20,
                            }}
                        >
                            <CartesianGrid />
                            <XAxis
                                dataKey="name"
                                interval={11}
                                tick={{ fontSize: 12 }}
                            >
                                <Label
                                    value="Dia/Horário"
                                    offset={-15}
                                    position="insideBottom"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                                <Label
                                    value="Dia/Horário"
                                    offset={-15}
                                    position="insideBottom"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                            </XAxis>
                            <YAxis
                                allowDecimals={false}
                                label={{
                                    value: `Vento ${
                                        props.mesuare === null ||
                                        props.mesuare === 1
                                            ? 'm/s'
                                            : 'km/h'
                                    }`,
                                    angle: -90,
                                    style: {
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        height: '20px',
                                    },
                                }}
                            />
                            <Tooltip />
                            <Bar
                                dataKey={
                                    props.mesuare === null ||
                                    props.mesuare === 1
                                        ? 'm/s'
                                        : 'km/h'
                                }
                                fill="#BFDFF1"
                            >
                                <LabelList
                                    dataKey="vento"
                                    position={'insideTop'}
                                    style={{
                                        fontSize: 5,
                                        fontWeight: 'bold',
                                    }}
                                />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    } else {
        <Loading />;
    }
};

BarChartComponent.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    mesuare: PropTypes.number,
    type: PropTypes.string,
};
export default withStyles(styles)(BarChartComponent);
