import React, { useEffect, memo } from 'react';

// Components
import { Source, Layer } from 'react-map-gl';

// Hooks
import {useSatellite} from 'Context/SatelliteContext.js';


const SatelitesLayersComponent = () => {
    const {
      satelliteInfo,
      showSatellite,
      currentImage,
      setCurrentImage,
      opacidadeControl
    } = useSatellite();

    useEffect(() => {
      if (satelliteInfo === undefined || !satelliteInfo.satelliteData || !satelliteInfo.satelliteData.length) return
      setCurrentImage(satelliteInfo.satelliteData.length-1)
    }, [satelliteInfo])
    
    if (satelliteInfo === undefined || !satelliteInfo.satelliteData || !satelliteInfo.satelliteData.length) return <></>;
    return (
      <Source
          id="satelite"
          type="image"
          // Substituir essa URL pela URL contida no array ImagePath
          // junto com o indice vindo do componente pai (App.js)
          url={satelliteInfo.satelliteData[currentImage].imagePath}
          coordinates={satelliteInfo.position}
      >
          <Layer
              id="goes"
              source={'satelite'}
              type="raster"
              paint={{
                  'raster-fade-duration': 0,
                  'raster-opacity': opacidadeControl,
              }}
              layout={{
                  visibility: showSatellite ? 'visible' : 'none',
              }}
          />
      </Source>
    );
};

const SatelitesLayers = memo(SatelitesLayersComponent)
export default SatelitesLayers;
