import React, {memo, useEffect, useState} from 'react'
import P, { object } from 'prop-types';
import {Layer, Source} from 'react-map-gl';
import {getCameras} from 'services/mapsPortal/cameras'
import {useControlPanel} from 'Context/ControlPanelContext';
import { useCamera } from 'Context/CameraContext';
import { useSelector } from 'react-redux';

const CamerasComponent = () => {
  const [camerasData, setCamerasData] = useState();
  const [loading, setLoading] = useState(true);
  const { reloadCamera } = useControlPanel();
  const camerasState = useSelector(state => state.mapCamerasStateProvider);
  
  const loadCamera = async () => {
    await getCameras().then((response) => {
      if(response) {
        setCamerasData({
          type: "FeatureCollection",
          features: response
        });
        setLoading(false);
      }
    });
  }

  useEffect(() => {
      loadCamera();
  }, [reloadCamera]);

  useEffect(() => {
    if(loading) {
      loadCamera();
    }
  }, []);

  if(loading) return null;
  return (
    <>
      <Source
        key={`cameras`}
        id={`cameras`}
        type="geojson"
        data={camerasData}
        generateId={true}
        >
        <Layer
            id={`cameras`}
            source={`cameras`}
            type="symbol"
            layout={{
              'icon-image': 'camera_marker',
              'icon-size': camerasState? 0.05 : 0
            }}
        />
      </Source> 
    </>
  )
}  


CamerasComponent.propTypes = {
}

export const CamerasLayer = memo(CamerasComponent);
