/* Libraries */
import React, {useEffect, useState} from 'react'
import P from 'prop-types';
import {Head, Title, BigTab} from '../styled'
import { useDispatch } from 'react-redux';


/* Components */
import ComplexTable from 'containers/Pages/Table';
import { useToast } from 'Context/ToastContext';
import { PapperBlock } from 'components';
import {MdClose} from 'react-icons/md';
import {Row, Column} from 'components/Flex'

/* CSS */
import fontStyles from 'styles/fonts-jss';

/* Material UI */
import { withStyles } from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineTaskAlt, MdOutlineUnpublished, MdWarningAmber } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
/* Services */
import {getConsolidations } from 'services/mapsPortal/alerts'

import useLoad from 'hooks/useLoad'

/* Utils */ 
import { persistViewPageNavigation } from 'utils/persistViewPageNavigation';

/* Redux */
import { tabMenuProvider } from 'redux/actions/tabMenuAction';

const HistoryTab = (props) => {
  const {classes} = props;
  const invertDate = (date) =>{ 
    
    date.split('-').reverse()
    return date
  };

  const constDate = (date) =>{
    const val_temp = date.split(':');
    const timedays = val_temp[0].split('T');
    const real_date = timedays[0].split('-').reverse().join('/');
    const hours = timedays[1]+":"+val_temp[1]

    return(real_date+" "+hours+" h")

  };

  const calcDur = (date1,date2) =>{
    const date_init = new Date(date1);
    const date_fin = new Date(date2);
    const sub_dates_min = Math.round((date_init-date_fin)/(1000*60))
    const sub_dates_hour = Math.round(sub_dates_min/60)
    const sub_dates_days = Math.round(sub_dates_hour/24)
    const sub_dates_months = Math.round(sub_dates_days/30)
    var out = ""
    if(sub_dates_days >= 1){

      if(sub_dates_days == 1){
        out = sub_dates_days + " Dia"
      }else{
        out = sub_dates_days + " Dias"
      }
      
    }else if(sub_dates_hour < 24){
      if(sub_dates_hour == 1){
        out = sub_dates_hour + " Hora"
      }else{
        out = sub_dates_hour + " Horas"
      }

    }else if(sub_dates_min < 60){
      if(sub_dates_min == 1){
        out = sub_dates_min + " Minuto"
      }else{
        out = sub_dates_min + " Minutos"
      }

    }
    return out;


  };

  const [dateSearch, setDateSearch] = useState();
  const [reload, setReload] = useState(false);
  const [prevision, setPrevision] = useState(null);
  const [alldatas, setDatas] = useState();
  const [loadingPrevision, setLoadingPrevision] = useState(true);
  const fonts = fontStyles();
  const openToast = useToast();
  const dispatch =  useDispatch();
  const columns = ['Checkbox', 'Enviado em', 'Empresa', 'Plano', 'Alvos', 'Tipo', 'Modificações', 'Modificado em', 'Status'];
  const [tabsList, setTabsList] = useState(null);

  useEffect(() => {
    setReload(false);
  }, [reload]);


  const goToHistInfo = (event, idAlerta, idAlvo, nivelAlerta, dataP) => {
    const navigationContext = JSON.parse(sessionStorage.getItem("cLFfX97S5j"));
    if(event.target.id != "Button"){
      if(event.target.name != "Checkbox"){
        persistViewPageNavigation(navigationContext[0], navigationContext[1], idAlerta, idAlvo,  navigationContext[4], navigationContext[5], navigationContext[6], navigationContext[7],  navigationContext[8],  navigationContext[9]);
        //history.push({pathname: `/administracao/empresas/${idAlvo}/historico/${idAlerta}`, state: {planData: dataP.plano, path: null, targetData: dataP.alvos, serviceData: dataP.servicos}});
        dispatch(tabMenuProvider(0));
      }
    }
  };
     
  const getModifications = (modif)=>{
    var modification = "";
    if(modif!=null && modif.length>0){
      const values = modif.map((item)=>{
        modification += item+" "
      });
    }
    return modification
  }

  const getTimeNow = () => {
    let aux1 = ""
    let aux2 = ""
    aux1 = new Date(new Date() - 1000 * 60 * 60 * 24 * 7)
    aux1 = String(aux1.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
  }))

    aux2 = new Date()
    aux2 = String(aux2.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
  }))
    let date1 = aux1.split(",")
    let date2 = aux2.split(",")
    let data = {
      data_inicio: String(date1[0].split("/")[2]+"-"+date1[0].split("/")[1]+"-"+date1[0].split("/")[0]),
      data_final: String(date2[0].split("/")[2]+"-"+date2[0].split("/")[1]+"-"+date2[0].split("/")[0]),
      hora_inicio: String(date1[1].replace(/ /g, '')),
      hora_final: String(date2[1].replace(/ /g, ''))
    }
    setDateSearch(data)
    setReload(true)
  };


  const setString = (data) => {
    let output = "";

    for(let i = 0; i < data.length; i++) {
      if(i != 0) {
        output = output + ", " + data[i].nome
      }
      else {
        output = output + data[i].nome
      }
    }
    return output;
  }

  const loadPrevision = async () => {
    var aux = [];
    if(dateSearch==undefined){getTimeNow()}else{
      await getConsolidations().then((response) => {
        if(response){
            var temp = response
            temp.map(consols=>aux.push(consols))
        }
        else{
          openToast("error", "Problema ao carregar os Alertas, recarregue a página e tente novamente!");
          setLoadingPrevision(false);
        }
      })
      .catch(() => {
        openToast("error", "Problema ao carregar os Alertas, recarregue a página e tente novamente!");
        setLoadingPrevision(false);
      })
    
      setPrevision(aux);
      setLoadingPrevision(false);
    }
  };
 
  useEffect(() => {
    loadPrevision();
  }, []);

    
  useEffect(() => {
    loadPrevision();
  }, [dateSearch]);
  
  const data = prevision != null && !loadingPrevision? prevision?.map((dataP, index) => {
        return {
          index: index,
          //onClick: (e) => {goToHistInfo(e, dataP.alerta_raios? dataP.alerta_raios?.id_alerta: dataP.alertas_liberados? dataP.alertas_liberados?.id_alerta : dataP.avisos_chuva? dataP.avisos_chuva?.id_alerta : "", dataP.alerta_raios? dataP.alerta_raios?.id_alvo_id: dataP.alertas_liberados? dataP.alertas_liberados?.id_alvo_id : dataP.avisos_chuva? dataP.avisos_chuva?.id_alvo_id : "", dataP.alerta_raios?.nivel, dataP)},
          [columns[0]]: "Checkbox",
          [columns[1]]: dataP.boletins?

          dataP.boletins?.modificacao === null? constDate(dataP.boletins?.datahora_criacao) : { node: <div className={"CCC"} style={{marginLeft: "12px", color:"red"}}>
          <p>{dataP.boletins?.datahora_modificacao? constDate(dataP.boletins?.datahora_modificacao): ""}</p>
        </div>, content: 'Em alerta'}:
        
        dataP.avisos_meteorologicos?.modificacao === null? constDate(dataP.avisos_meteorologicos?.datahora_criacao) : { node: <div className={"CCC"} style={{marginLeft: "12px", color:"red"}}>
        <span>{dataP.avisos_meteorologicos?.datahora_modificacao? constDate(dataP.avisos_meteorologicos?.datahora_modificacao): ""}</span>
         </div>, content: 'Em alerta'},
          [columns[2]]: dataP.nome_empresa? dataP.nome_empresa : "",
          [columns[3]]: dataP.nome_plano? dataP.nome_plano : "",
          [columns[4]]: dataP.nome_alvo? dataP.nome_alvo : "",
          [columns[5]]: dataP.boletins? dataP.boletins.horizontes===null? "Boletim Nowcasting" : "Boletim Diário" : dataP.avisos_meteorologicos&& "Aviso Meteorológico",
          [columns[6]]: dataP.boletins? dataP.boletins.horizontes!=null ? dataP.boletins.horizontes+"h" : "" : "",                       
          [columns[7]]: dataP.boletins? dataP.boletins.modificacao!=null ? {node: <p style={{color:"red"}}>{getModifications(dataP.boletins.modificacao)}</p> }: "" : dataP.avisos_meteorologicos? dataP.avisos_meteorologicos.modificacao!=null? {node: <p style={{color:"red"}}>{getModifications(dataP.avisos_meteorologicos.modificacao)}</p> }: "" : "",
          [columns[8]]: dataP.boletins? dataP.alerta_raios?.liberado == 1? { node: <div className="SSS" style={{marginLeft: "12px"}}><span>FINALIZADO</span></div>, content: 'Finalizado'} :{ node: <div className={"CCC"} style={{marginLeft: "12px"}}><span>EM ALERTA</span></div>, content: 'Em alerta'} : 
                        dataP.avisos_meteorologicos && dataP.alertas_liberados?.liberado == 1? { node: <div className="SSS" style={{marginLeft: "12px"}}><span>FINALIZADO</span></div>, content: 'Finalizado'} :{ node: <div className={"CCC"} style={{marginLeft: "12px"}}><span>EM ALERTA</span></div>, content: 'Em alerta'},
          ["gt"]: dataP.boletins?  "#E6DBA1" :  "#D2D1D2",
          id: dataP.boletins? dataP.boletins.id_boletim : dataP.avisos_meteorologicos? dataP.avisos_meteorologicos.id : "",
          tipo: dataP.boletins ? "Boletins" : dataP.avisos_meteorologicos? "Avisos Meteorologicos" :  "",
        } 
  }) : [];

if(!loadingPrevision) {
  return (
    <ComplexTable 
      columns={columns} 
      data={data}  
      doReloadComponent={reload} 
      dateTimeChoice={true}
      setDateSearch={setDateSearch}
      type={"Previsão"}
      width={['5%', '5%', '10%', '10%','10%', '10%', '5%', '5%', '10%']} 
      dataSet={data}
      createButton={false}
      buttonsPrevision={true}
      csvButton={false}
    />
  )
}
else {
  return(
    <CircularProgress style={{ marginLeft: "calc(48%)" }} size={50} thickness={1} color="primary" />
  )
}



}

HistoryTab.propTypes = {
  onClose: P.func.isRequired,
  classes: P.object.isRequired
}

export default HistoryTab;
