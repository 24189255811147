export default function (state = null, action){

    switch(action.type){
        case "PRINT_SCREEN_PROVIDER":
            return action.payload
    default: return state
    }
}




