/* Libraries */
import React, {useEffect, useState, useRef} from 'react'
import P from 'prop-types';
import { useDispatch } from 'react-redux';
/* Components */
import ComplexTable from 'containers/Pages/Table';
import { useToast } from 'Context/ToastContext';
import { useModal } from 'Context/ModalContext';
import ModalHistory from "components/Modal/ModalHistory";
/* CSS */
import fontStyles from 'styles/fonts-jss';
/* Material UI */
import CircularProgress from '@material-ui/core/CircularProgress';
/* Services */
import { getDailyReportCard, get12hReportCard, getNowcastingReportCard, getSpecialReportCard } from 'services/mapsPortal/alerts';
/* Redux */
import { reportCardContextProvider } from 'redux/actions/reportCardContext';
import 'moment/locale/pt-br';
import {useTarget} from 'Context/TargetContext';


const NotApprovedTable = (props) => {
  const {classes} = props;
  const moment = require('moment');
  moment.locale('pt-br');
  const [reportCard, setReportCard] = useState([]);
  const [loadingReportCard, setLoadingReportCard] = useState(true);
  const fonts = fontStyles();
  const openToast = useToast();
  const dispatch =  useDispatch();
  const ref = useRef(null);
  const [reloadMod, setReloadMod] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalData, setModalData] = useState([""]);
  const columns = ['Checkbox', 'Empresa', 'Alvo', 'Tipo de Boletim', 'Responsável', 'Vigência(BRT)', 'Status'];
  const { openPredictionModal } = useModal();
  const { planos } = useTarget();


  const goToPredictionModal = (event, data) => {   
    if(event.target.id != "Button"){
      if(event.target.name != "Checkbox"){
        const filteredCompanies = planos.map((company) => {
          const filteredPlanos = company.planos.map((plan) => ({
            ...plan,
            alvos: plan.alvos.filter((target) =>
              data.id_alvo == target.id_alvo
            ),
          }));
  
          return {
            ...company,
            planos: filteredPlanos.filter((plan) => plan.alvos.length > 0),
          };
        }).filter((company) => company.planos.length > 0)
        .map((company, indexCompany) => ({
          ...company,
          id: indexCompany,
          planos: company.planos.map((plan, indexPlan) => ({
            ...plan,
            id: indexPlan, 
          })),
        }));
        
        let tab = [data.horizontes].filter((horizon) => [24, 48, 72].includes(horizon))?.length > 0? 0 : [data.horizontes].includes(3)? 1 : [data.horizontes].length > 0? 2 : 0;
        
        dispatch(reportCardContextProvider([[data.id_boletim], [data.horizontes == 3? data.vigencia : data.horizontes], [data.status]]));
        openPredictionModal(filteredCompanies, tab);
      }
    }
  };

  useEffect(() => {
    if(reloadMod){
      setReloadMod(false);
      setAnchorEl(null);
    }
  }, [reloadMod])

  const calcVigenc = (date, horizontes, especial, vigencia) => {
    let newDate = new Date(date);
    return !especial && horizontes === 24? '06:00 de ' + moment(newDate).format('DD/MM/YYYY') + ' até ' + '06:00 de ' + moment(newDate).add(1, 'days').format('DD/MM/YYYY') :
          !especial && horizontes === 48? '06:00 de ' + moment(newDate).add(1, 'days').format('DD/MM/YYYY') + ' até ' + '06:00 de ' + moment(newDate).add(2, 'days').format('DD/MM/YYYY') :
          !especial && horizontes === 72? '06:00 de ' + moment(newDate).add(2, 'days').format('DD/MM/YYYY') + ' até ' + '06:00 de ' +  moment(newDate).add(3, 'days').format('DD/MM/YYYY') :
          !especial && horizontes === 12? '18:00 de ' + moment(newDate).format('DD/MM/YYYY') + ' até ' + '6:00 de ' + moment(newDate).add(1, 'days').format('DD/MM/YYYY') : 
          !especial && horizontes === 3? vigencia.split(' - ')[0].split(' ')[1].slice(0, 5) + ' de ' + moment(vigencia.split(' - ')[0].split(' ')[0]).format('DD/MM/YYYY') + ' até ' + vigencia.split(' - ')[1].split(' ')[1].slice(0, 5) + ' de ' + moment(vigencia.split(' - ')[1].split(' ')[0]).format('DD/MM/YYYY') :
          especial? vigencia?.split(' - ')[0]?.split(' ')[1]?.slice(0, 5) + ' de ' + moment(vigencia?.split(' - ')[0]?.split(' ')[0]).format('DD/MM/YYYY') + ' até ' + vigencia?.split(' - ')[1].split(' ')[1]?.slice(0, 5) + ' de ' + moment(vigencia?.split(' - ')[1]?.split(' ')[0]).format('DD/MM/YYYY') : "";
  }

  const loadDailyReportCard = async () => {
    await getDailyReportCard().then((response) => {
      if(response){
        let dailyReportCard = reportCard.concat(response.filter((data) => data.status === 0));
        load12hReportCard(dailyReportCard);
      }
      else{
        openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
      }
    })
    .catch(() => {
      openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
    })
  };

  const load12hReportCard = async (dailyReportCard) => {
    await get12hReportCard().then((response) => {
      if(response){
        let allReportCard = dailyReportCard.concat(response.filter((data) => data.status === 0));
        loadNowcastingReportCard(allReportCard)
      }
      else{
        openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
        setLoadingReportCard(false);
      }
    })
    .catch(() => {
      openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
      setLoadingReportCard(false);
    })
  };

  const loadNowcastingReportCard = async (reportCards) => {
    await getNowcastingReportCard().then((response) => {
      if(response){
        let allReportCard = reportCards.concat(response.filter((data) => data.status === 0));
        loadSpecialReportCard(allReportCard)
      }
      else{
        openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
        setLoadingReportCard(false);
      }
    })
    .catch(() => {
      openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
      setLoadingReportCard(false);
    })
  };

  const loadSpecialReportCard = async (reportCards) => {
    await getSpecialReportCard().then((response) => {
      if(response){
        let allReportCard = reportCards.concat(response.filter((data) => data.status === 0));
        setReportCard(allReportCard)
      }
      else{
        openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
      }
    })
    .catch(() => {
      openToast("error", "Problema ao carregar os Boletins, recarregue a página e tente novamente!");
    })
    setLoadingReportCard(false);
  };


  const getStatus = (horizontes, especial, vigencia) => { 
    let aux = "";
    let time = new Date();

    if((horizontes == 72 || horizontes == 48  || horizontes == 24) && !especial){
      if(time.getHours() >= 0 && time.getHours() < 5){
          aux = "No Prazo"
      }
      else if(time.getHours() == 5){
        if(time.getMinutes() < 30){
          aux = "No Prazo"
        }
        else if(time.getMinutes() >= 30 && time.getMinutes() <= 44 && time.getSeconds() >= 1 && time.getSeconds() <= 59){
          aux = "30 min para o envio"
        }
        else if(time.getMinutes() >= 45 && time.getMinutes() <= 59 && time.getSeconds() >= 0 && time.getSeconds() <= 59){
          aux = "15 min para o envio"
        }
      }
      else if(time.getHours() == 6){
        if(time.getMinutes() >= 0 && time.getMinutes() <= 5){
          aux = "Enviar"
        }
        else if(time.getMinutes() > 5 && time.getMinutes() <= 15){
          aux = "15 min atrasado"
        }
        else if(time.getMinutes() > 15 && time.getMinutes() <= 30){
          aux = "30 min atrasado"
        }
        else{
          aux = "Atrasado" 
        }
      }
      else if(time.getHours() > 6){
        aux = "Atrasado"
      }
    }

    else if(horizontes == 12 && !especial){
      if(time.getHours() >= 0 && time.getHours() < 17){
          aux = "No Prazo"
      }
      else if(time.getHours() == 17){
        if(time.getMinutes() < 30 ){
          aux = "No Prazo"
        }
        if(time.getMinutes() >= 30 && time.getMinutes() <= 44 && time.getSeconds() >= 1 && time.getSeconds() <= 59){
          aux = "30 min para o envio"
        }
        else if(time.getMinutes() >= 45 && time.getMinutes() <= 59){
          aux = "15 min para o envio"
  
        }
      }
      else if(time.getHours() == 18){
        if(time.getMinutes() >= 0 && time.getMinutes() <= 5){
          aux = "Enviar"
        }
        else if(time.getMinutes() > 5 && time.getMinutes() <= 15){
          aux = "15 min atrasado"
        }
        else if(time.getMinutes() > 15 && time.getMinutes() <= 30){
          aux = "30 min atrasado"
        }
        else{
          aux = "Atrasado" 
        }
      }
      else if(time.getHours() > 18){
        aux = "Atrasado"
      }
    }
    
    else if(horizontes == 3 && !especial){
      let validityDateFat1 = moment(vigencia.split(' - ')[0], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      let now = moment(time).format('YYYY-MM-DD HH:mm:ss');
      let remainingMinutes = moment(validityDateFat1).diff(now, 'minutes');

      if(remainingMinutes > 30){
        aux = "No prazo";
      }
      else if(remainingMinutes < 30 && remainingMinutes > 15){
        aux = "30 min para o envio";
      }
      else if(remainingMinutes < 15 && remainingMinutes > 5){
        aux = "15 min para o envio";
      }
      else if(remainingMinutes < 5 && remainingMinutes > 0){
        aux = "Enviar";
      }
      else if(remainingMinutes < 0 && remainingMinutes > -15){
        aux = "15 min atrasado";
      }
      else if(remainingMinutes < 0 && remainingMinutes > -30){
        aux = "30 min atrasado";
      } 
      else if(remainingMinutes < -30){
        aux = "Atrasado";
      }       
    }

    else if(especial){
      let validityDateFat1 = moment(vigencia.split(' - ')[0], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
      let now = moment(time).format('YYYY-MM-DD HH:mm:ss');
      let remainingMinutes = moment(validityDateFat1).diff(now, 'minutes');

      if(remainingMinutes > 30){
        aux = "No prazo";
      }
      else if(remainingMinutes < 30 && remainingMinutes > 15){
        aux = "30 min para o envio";
      }
      else if(remainingMinutes < 15 && remainingMinutes > 5){
        aux = "15 min para o envio";
      }
      else if(remainingMinutes < 5 && remainingMinutes > 0){
        aux = "Enviar";
      }
      else if(remainingMinutes < 0 && remainingMinutes > -15){
        aux = "15 min atrasado";
      }
      else if(remainingMinutes < 0 && remainingMinutes > -30){
        aux = "30 min atrasado";
      } 
      else if(remainingMinutes < -30){
        aux = "Atrasado";
      }   
    }
    return aux
  };
 
 
  useEffect(() => {
    loadDailyReportCard();
  }, []);

  const data = reportCard.length > 0 && !loadingReportCard? reportCard?.map((dataP, index) => {
        return {
          index: index,
          onClick: (e) => {goToPredictionModal(e, dataP)},
          [columns[0]]: "Checkbox",
          [columns[1]]: dataP.nome_empresa != null? dataP.nome_empresa : "",
          [columns[2]]: dataP.nome_alvo != null? dataP.nome_alvo : "",
          [columns[3]]: dataP.horizontes != null? !dataP.especial && dataP.horizontes == 3? "Nowcasting" : dataP.especial? "Especial - " + dataP.horizontes + "h" : "Diário - " + dataP.horizontes + "h" : "",
          [columns[4]]: dataP.nome_meteorologista != null? dataP.nome_meteorologista : "",
          [columns[5]]: dataP.datahora_criacao && dataP.horizontes? calcVigenc(dataP.datahora_criacao, dataP.horizontes, dataP.especial, dataP.vigencia) : "",
          [columns[6]]: dataP.horizontes != null? getStatus(dataP.horizontes, dataP.especial, dataP.vigencia) : "" ,
          id: dataP.id_boletim? dataP.id_boletim : "",
          id_alvo: dataP.id_alvo? dataP.id_alvo : ""
        } 
  }) : [];

  if(!loadingReportCard && data?.length > 0) {
    return (
      <>
        {anchorEl != null && <ModalHistory anchorEl={anchorEl} setAnchorEl={setAnchorEl} width={ref.current? ref.current.getBoundingClientRect().width : "600px"} setReloadMod={setReloadMod} data={modalData} putData={modalData} idAlvo={1} />}
        <ComplexTable 
          columns={columns} 
          data={data}  
          dateTimeChoice={false}
          type={"Boletim"}  
          dataSet={reportCard}
          width={['5%', '17.5%', '26%', '7%', '17.5%','20%', '7%']}
          reportCard={true}
          createButton={false}
          reportCardButtons={true}
          buttonsWarnings={false}
          csvButton={false}
        />
      </>
    )
  }
  else if(!loadingReportCard && data?.length == 0 || data===undefined) {
    return(
      <h1 style={fonts.noDataText}>SEM DADOS</h1>
    )
  }
  else {
    return(
      <CircularProgress style={{ marginLeft: "calc(48%)" }} size={50} thickness={1} color="primary" />
    )
  }
}

NotApprovedTable.propTypes = {
  onClose: P.func.isRequired,
  classes: P.object.isRequired,
  openModal: P.object.isRequired,
  handleCloseModal: P.object.isRequired,
}

export default NotApprovedTable;
