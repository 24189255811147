import { lambdaApi, djangoApi } from '../api';

export const getFormatedActives = async () => {
    try {
        const response = await lambdaApi.get(`ativos`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const postActives = (activeData) => {
    return djangoApi.post(`ativos/`, activeData);
}


export const patchActive = (id_active, activeData) => {
    return djangoApi.put(`ativos/${id_active}/`, activeData);
};

export const deleteActive = (id_active) => {
    return djangoApi.delete(`ativos/${id_active}/`);
};