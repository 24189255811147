export default function (state = [], action){
    switch(action.type){
        case "HISTORY_DISCHARGES_BUFFERS_PROVIDER":
            return action.payload
            
    default: return state
    }
}



