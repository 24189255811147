import { lambdaApi } from "services/api";

export const getActives = async () => {
  try {
      const response = await lambdaApi.get(`ativos`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};

export const getSatelliteImages = async () => {
  try {
      const response = await lambdaApi.get('imagens');
      var parsedData;

      if(typeof response.data === 'string'){
        parsedData = JSON.parse(response.data)
      }
      else{
        parsedData = response.data
      }

      const satellitePosition = [
          [Number(-86), Number(13.5)],
          [Number(-25), Number(13.5)],
          [Number(-25), Number(-41.5)],
          [Number(-86), Number(-41.5)],
      ];

      let imageData = [];
      for (let i in parsedData) {
          let auxObj = {};
          auxObj.imagePath = parsedData[i].caminho;
          auxObj.imageDate = parsedData[i].data_imagem;
          imageData.push(auxObj);
      }
      return {
          satelliteData: imageData,
          position: satellitePosition,
          imageQuantity: imageData.length,
      }
  } catch {
      throw new Error('Não foi possível obter as imagens de satélite.');
      // return undefined;
  }
};
