import React from 'react'
import P from 'prop-types';
import Collapse from '@material-ui/core/Collapse';

const Collapsible = (props) => {
  return (
    <>
      {props.main}
      <Collapse
        in={props.isOpen}
        timeout="auto"
        unmountOnExit
        style={{width: '100%', ...props.style}}
      >
        {props.children}
      </Collapse>
    </>
  )
}

Collapsible.propTypes = {
  isOpen: P.bool.isRequired,
  children: P.node,
  main: P.node.isRequired,
  style: P.object,
}

export default Collapsible
