/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import { postLightningModifiedAlerts, postModifiedLightningRelease, postModifiedRainAlert, postModifiedReport } from 'services/mapsPortal/alerts'
import ModalContextProvider from 'Context/ModalContext';


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const ModalTargetsView = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadModTargets, data, putData, idAlvo } = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();
  const refEdit = useRef();
  const refAdd = useRef();
  var aux = 0;
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);

  const handleClose = () => {
    setOpen(null);
    setReloadModTargets(true);
  }

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  return(
    <ModalContextProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 300, left: windowSize[0]/2}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      > 
      <Table style={{margin:"0px"}}>
        <TableHead>
          <div style={{width: "100%", height:"100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
            <div style={{color:"white", paddingTop:"10px", paddingLeft:"15px", fontSize:"20px"}}>
                <h2>Visualizar Alvos</h2>
            </div>
          </div>
        </TableHead>
        <TableBody>

          {console.log(tableData)}
          {tableData.targets? tableData.targets?.map((option,index)=> {
                return (
                  <StyledTableRow key={index}>
                    <StyledCellView
                          padding="normal"
                          style={fonts.cellContent}
                      >
                        <div style={{alignItems: "center"}}>
                          <p><strong>NOME:</strong> {option.nome} - <strong>APELIDO: </strong> {option.apelido}</p>
                        </div>
                    </StyledCellView>
                  </StyledTableRow>
                )
          }) : 
              <StyledTableRow>
                <StyledCellView
                      padding="normal"
                      style={fonts.cellContent}
                  >
                    <div style={{alignItems: "center"}}>
                      <p><strong>SEM ALVOS!</strong></p>
                    </div>
                </StyledCellView>
              </StyledTableRow>
              
          }


        </TableBody>
      </Table>
      <div style={{width:"1000px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
        <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px", justifyContent:"space-between"}}>
            <div style={{marginLeft:"20px", padding:"10px 20px 10px 20px"}} className={classes.outlineButton1} onClick={() => handleClose()}>
              <span>FECHAR</span>
            </div>
        </div>
      </div>
      </Popover>
    </ModalContextProvider>
  )
} 

ModalTargetsView.propTypes = {
  classes: PropTypes.object,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.object,
  width: PropTypes.string,
  setReloadModTargets: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalTargetsView);