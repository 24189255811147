
/* Libraries */
import React from "react";
import PropTypes from "prop-types";
/* CSS */
import styles from "./tableContent-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material */
import Checkbox from '@material-ui/core/Checkbox';


const CustomCheckboxBody = (props) => {
  const { classes, content, handleClickCheckbox, selected, reportCard } = props
  
  return(
    <>
      <Checkbox 
        name="Checkbox"
        color="primary"
        onClick={(event) => handleClickCheckbox(event, content.id, content.nome, "NotAllSelected", content.index, content.id_alvo)}
        checked={selected.indexOf(content.id) !== -1}
        value={selected.indexOf(content.id) !== -1}
      />
    </>
  );

}
CustomCheckboxBody.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.any.isRequired,
    handleClickCheckbox: PropTypes.func.isRequired,
    selected: PropTypes.any.isRequired,
    reportCard: PropTypes.func.isRequired,
  };
  export default withStyles(styles)(CustomCheckboxBody);