import { djangoApi } from '../api';

export const postCameras = (cameraData) => {
  return djangoApi.post(`cameras/`, cameraData);
}

export const getCameras = async () => {
  try {
      const response = await djangoApi.get(`cameras/`);
      return response.data;
  }
  catch (error) {
      return [];
  }
};

export const deleteCameras = (cameraID) => {
  return djangoApi.delete(`cameras/${cameraID}/`);
}

export const patchCamera = (cameraID, cameraData) => {
  return djangoApi.patch(`cameras/${cameraID}/` , cameraData);
}
