import 'mapbox-gl/dist/mapbox-gl.css';
import { useState, useEffect, useRef } from 'react';
import ReactMapGl from 'react-map-gl';
import { DischargesLayer } from './DischargesLayer';
import MapPopup from './MapPopup';
import SatelitesLayers from './SatelitesLayers.jsx/index.jsx';
import { TargetLayer } from './TargetLayer';
import { ActiveLayer } from './ActiveLayer';
import PolygonLayer from './PolygonLayer';
// import { DrawPolygon } from './PolygonLayer';
import { useMap } from 'hooks/useMap';
import { Tool } from './MeasureLayer';
import Loading from 'components/Loading';
import {useMapContext} from 'Context/MapContext';
import RadarLayer from './RadarLayer';
import {useTools} from 'Context/ToolsContext';
import Alerts from '../Alerts';
import { useSelector, useDispatch } from 'react-redux';
import { useTarget } from 'Context/TargetContext';
import html2canvas from 'html2canvas';
import { printScreenProvider } from 'redux/actions/printScreenAction';
import { BuffersLayer } from './BuffersLayer';

const Map = () => {
    const [loading, setLoading] = useState(true);
    const {mapRef, popup, showPopup, setShowPopup} = useMapContext();
    const { goTo, setGoTo, mapStyle } = useMapContext();
    const { polygon } = useTools();
    const newMaxBounds = useSelector(state => state.polygonProvider);
    const printScreen = useSelector(state => state.printScreenProvider);
    const { toggleTargets } = useTarget();
    const dispatch = useDispatch();
  
    const [viewport, setViewport] = useState({
        longitude: -59.95,
        latitude: -12.9,
        zoom: 3.5,
        maxZoom: 18,
        minZoom: 2,
    });

    const { mapFunctions, interactiveLayers } =
        useMap(mapRef, viewport, setViewport, loading, setLoading);

    useEffect(() => {
      if (!goTo.length) return;
        mapRef.current.fitBounds(
          goTo,
          {padding: 40, duration: 1000}
        )
        setGoTo([]);
    }, [goTo, mapRef, setGoTo]);

    const onClose = () => {
      setShowPopup(false)
      polygon.resetPolygon();
    }

    useEffect(() => {
      if (polygon.finishPolygon && polygon.polygonLine.length > 3 && newMaxBounds){
        toggleTargets(false);
      }
    }, [newMaxBounds]);


    const exportChartsAsImage = async () => {
      const chartContainer = document.getElementById('chart-container');
      const scale = 2;
      const dpi = 300;

      if (chartContainer) {
          try {
              const canvas = await html2canvas(chartContainer, {
                  scale,
                  dpi,
                  useCORS: true,
              });

              const link = document.createElement('a');
              link.href = canvas.toDataURL('image/png');
              link.download = `${printScreen}.png`;
              link.click();
              dispatch(printScreenProvider(null))
          } catch (error) {
              console.error('Error exporting charts:', error);
          }
      }
  };

  useEffect(() => {
      if (printScreen){
        exportChartsAsImage()
      }
    }, [printScreen]);


    return (
      <>
        {loading && <Loading />}
        <ReactMapGl
            // reuseMaps
            ref={mapRef}
            {...viewport}
            {...mapFunctions}
            preserveDrawingBuffer={false}
            id="chart-container"
            // interactiveLayerIds={interactiveLayers}
            dragRotate={false}
            doubleClickZoom={false}
            width="100%"
            height="100%"
            mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
            maxBounds={
              polygon.finishPolygon && polygon.polygonLine.length > 3 && newMaxBounds?
              [[newMaxBounds[0][0] - 2, newMaxBounds[0][1] - 2], [newMaxBounds[1][0] + 8, newMaxBounds[1][1] + 2]] :
              [[-90, -38], [5, 11.5]]
            }
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
            <PolygonLayer />
            <RadarLayer />
            <TargetLayer />
            <BuffersLayer />
            <DischargesLayer />
            <SatelitesLayers />
            {/*<Tool />*/}
            {/*<Alerts />*/}
            <ActiveLayer />
        </ReactMapGl>
      </>
    );
};

export default Map;
