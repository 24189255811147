import styled from 'styled-components';
import LogoNucleo from 'components/LogoNucleo';
import { colors } from '../../styled';
import {Row, Column} from 'components/Flex';


export const SidebarStyled = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #FFF;
  transition: transform 0.4s cubic-bezier(.22,0,.2,1);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &.closed{
    transform: translateX(-100%);
  }
`

export const OpenButton = styled.button`
  position: absolute;
  padding: 0;
  right: 0px;
  top: 50%;
  height: 75px;
  width: 29px;
  color: black;
  background: #FFFFFF;
  box-shadow: 6px 4px 5px 3px rgba(0, 0, 0, 0.4);
  border-radius: 0 13px 13px 0px;
  transform: translateY(-50%) translateX(100%);
  transition: 0.3s ease;
  z-index: 1;
  &:hover{
    /* transform: translateY(-50%) scaleX(1.1); */
    background-color: #AAA;
  }
`

export const TabBar = styled.div`
  width: 116px;
  height: 100%;
  background-color: ${colors.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
export const Logo = () => (
  <div style={{padding: "32px 0 0 0"}}>
    <LogoNucleo fill={colors.white} height={56} width= {64} />
  </div>
)

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  gap: 8px;
  padding: 0;

  div{
    background-color: ${props => props.selected ? '#A185F8' : 'transparent'};
    transition: background-color 0.3s ease;
    color: white;
    font-size: 26px;
    width: 40px;
    height: 40px;
    border-radius: 7px;
  }
  span{
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: ${props => props.selected ? '#A185F8' : 'white'};
  }

  &:hover{
    div{
      background-color: #A185F8A0;
    }
  }
  &:disabled{
    &:hover{
      background-color: transparent;
      div{
        background-color: transparent;
      }
    }
  }
`

export const Tab = styled.div`
  height: 100%;
  background: ${colors.white};
  color: ${colors.dark};
  box-shadow: 6px 4px 5px 3px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const BigTab = styled.div`
  height: 100%;
  width: calc(100vw - 116px);
  background: ${colors.white};
  color: ${colors.dark};
  box-shadow: 6px 4px 5px 3px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const Head = styled(Row)`
position: relative;
justify-content: space-between;
width: 100%;
margin: 0 auto;
background-color: ${colors.dark};
  span{
    font-family: 'Roboto';
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.115em;
    background: linear-gradient(142.57deg, #42369A 3.19%, #B2A0EA 53.85%, #42369A 102.45%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-blend-mode: darken, normal;
    color: transparent;
  }

  button{
    padding: 0;
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 24px;
    transition: 0.3s ease;
    &:hover{ color: red;}
  }
`

export const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -24px;
  top: -8px;
  padding: 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  background-color: ${colors.blue.dark};

  &:hover{
    background-color: ${colors.blue.light};
  }
`

export const Title = styled.h1`
  width: 100%;
  font-family: "Roboto","Philosopher", sans-serif;
  color: ${colors.dark};
  text-align: center;
  padding: 32px 0;
  margin: 0;
  border-bottom: 1px solid #848484;
`

export const MapStyleOption = styled(Column)`
  position: relative;
  input{
    position: absolute;
    top: -9999px;

    &:checked + label {
      background-color: ${colors.dark};
      color: ${colors.white};
    }
    &:checked:hover + label{
      background-color: ${colors.dark};
      color: ${colors.white};
    }
  }

  label {
    padding: 16px 24px;
    border: 1px solid ${colors.dark};
    border-radius: 13px;
    background-color: ${colors.white};
    text-align: center;
    width: 128px;
    transition: 0.3s ease;
    color: ${colors.dark};
    font-size: 20px;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-family: "Roboto","Philosopher", sans-serif;

    &:hover{
      background-color: ${colors.dark};
      color: ${colors.white};
    }
  }
`

export const InfoDiv = styled(Column)`
  width: calc(100%-32px);
  margin-left: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${(props) => (props.checked ? colors.selected : colors.dark)};
`

export const Property = styled.div`
  display: flex;
  color: inherit;
  gap: 4px;
  &>span{
    font-style: 'Roboto';
    font-weight: 700;
    font-size: 12px;
  }
  &>div{
    display: flex;
    flex-direction: column;
    span{
      font-style: 'Roboto';
      font-weight: 400;
      font-size: 12px;
    }
  }
`
