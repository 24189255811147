
/* Libraries */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
/* CSS */
import styles from "./tableContent-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material */
import Button from '@material-ui/core/Button';
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';


const SummaryInfo = (props) => {
  const { classes, tableName, index, data, columnName, type} = props;
  const fonts = fontStyles();
  const dispatch = useDispatch();

  const handleClickButton = () => {
    dispatch(modalProvider(true, tableName, columnName[index].Nome, type, "Infos", data));
  };

  return(
    <>
      <Button id="Button" onClick={() => handleClickButton()} className={classes.contentButtonContainer}>
        <span id="Button" style={fonts.buttonText} className={classes.createButtonText}>{tableName}</span>
      </Button>
    </>
  );

}
SummaryInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    tableName: PropTypes.any.isRequired,
    index: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    columnName: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
};

export default withStyles(styles)(SummaryInfo);