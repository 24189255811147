
/* Libraries */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { withStyles } from "@material-ui/core/styles";
/* Material UI */
import Button from '@material-ui/core/Button';
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';
    
const DeleteConfirmationContent = (props) => {
    const { classes, deleteData, errorData, setOpenModalError } = props;
    const fonts = fontStyles();
    const dispatch = useDispatch();
    const onCloseModalError = () => {
        dispatch(modalProvider(false, null, null, null, null, null));
        setOpenModalError(false);
    }

    return(
        <div className={classes.deleteContainerDiv}>
            <div className={classes.deleteContainerHeader}>
                <span style={fonts.deleteModalTitle}>Problema ao deletar {errorData?.length > 1? deleteData[3]?.toLowerCase() + "s" : deleteData[3]?.toLowerCase()}</span>
            </div>
            <div className={classes.deleteContainerBody}>
                <div className={classes.deleteContainerBodyTitle}>
                    <span style={fonts.warningMessage}>{`Problema ao deletar ${deleteData[3] === "Empresas"? errorData?.length > 1? "as" : "a" : errorData?.length > 1? "os" : "o"} ${errorData?.length > 1? "seguintes" : "seguinte"} ${errorData.length > 1? deleteData[3]?.toLowerCase() : deleteData[3]?.toLowerCase().slice(0, deleteData[3].length - 1)}:`}</span>
                </div>
                {errorData?.length <= 9?
                    <div className={classes.deleteContainerBodyBody}>
                        <div className={classes.deleteContainerBodyBodyAll}>
                            {errorData && errorData.length > 0 && errorData.map((data, index) =>
                                <span style={fonts.item} key={index} className={classes.warningItem}>
                                    <div className={classes.deleteContainerBodyDot}>
                                        <div className={classes.deleteDot} />
                                        {data.dataName}, delete os registros {errorData[index].error.length > 1? "das tabelas " + data.error : "da tabela " + data.error} vinculados a {deleteData[3] === "Empresas"? "essa " + deleteData[3]?.toLowerCase().slice(0, deleteData[3]?.length - 1) + " " : "esse " + deleteData[3]?.toLowerCase().slice(0, deleteData[3].length - 1) + " "}antes de continuar!
                                    </div>
                                </span>)}
                        </div>
                    </div> :
                    <span style={{marginTop: "30px", fontWeight: "bold"}}>Visualização indisponível!</span>
                }

            </div>
            <div className={classes.deleteContainerFooter}>
                <Button style={fonts.buttonDefault} variant="contained" className={classes.goBackButton} onClick={onCloseModalError}>
                    Voltar
                </Button>
            </div>
        </div>
    );
}

DeleteConfirmationContent.propTypes = {
    classes: PropTypes.object.isRequired,
    deleteData: PropTypes.array.isRequired, 
    errorData: PropTypes.array.isRequired,
    setOpenModalError: PropTypes.func.isRequired
};

export default withStyles(styles)(DeleteConfirmationContent);