import { djangoApi, lambdaApi } from '../api';

export const postTarget = (targetData) => {
    return djangoApi.post(`alvos/`, targetData);
}

export const postTarget_Plan = (targetPlanData) => {
    return djangoApi.post(`planosalvos/`, targetPlanData);
}

export const getTargetByID = async (id_alvo) => {
    try {
        const response = await djangoApi.get(`alvos/${id_alvo}/`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};


export const getMessagesStatus = async (fonte, id) => {
    try {
        const response = await lambdaApi.get(`mensagens?fonte=${fonte}&id=${id}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
  }

export const updateTarget = (id_target, targetData) => {
    return djangoApi.put(`alvos/${id_target}/`, targetData);
}

export const getTargetInfosByTargetID = async (id_target) => {
    try {
        const response = await djangoApi.get(`alvos_infos/?id_alvo=${id_target}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};

export const deleteTarget = async (id_target) => {
    try {
        const response = await djangoApi.delete(`alvos/${id_target}/`);
        return response.data;
    }
    catch (error) {
        return error.response;
    }
};

export const patchTarget = (id_target, targetData) => {
    return djangoApi.put(`alvos/${id_target}/`, targetData);
};

export const getTargetInfosByPlanID = async (id_plano) => {
    try {
        const response = await djangoApi.get(`planos_usuariosalvos/?id_plano=${id_plano}`);
        return response.data;
    }
    catch (error) {
        return [];
    }
};









