const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({
buttonWeather: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "5px",
    backgroundColor: "#000000",
    borderRadius: "5px",
    color: "#FFFFFF",
},

buttonReport: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "10px",
    backgroundColor: "#C0A514",
    borderRadius: "5px",
    color: "#FFFFFF",
},

gridButton: {
    ['@media (min-width:768px)']: { 
        marginLeft: '9.3%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '17.6%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '27.7%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '27%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '27.8%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '27.7%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '30.2%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '34.4%',
    },
},

gridContainer: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.2%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '9.7%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '4.8%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '6.4%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '6.3%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '9.4%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '9%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '11%',
    },
},

iconContainer: {
    marginLeft: '8px',
    width: "22px",
    height: "22px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
},
});

export default styles;