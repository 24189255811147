import { useState } from 'react'
import p from 'prop-types'

import {Head, Title, BigTab} from '../styled'
import {StyledTabs, StyledTab, Grid} from './styled'
import {Row, Column} from 'components/Flex'

import {MdClose} from 'react-icons/md';
import {useTheme} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import {AppBar, Switch} from '@material-ui/core';

import useLoad from 'hooks/useLoad'
import {getAutoAlerts, postAutoAlerts} from 'services/mapsPortal/alerts'
import preferences from './hotkeys'
import HotkeysDescription from './HotkeyDescription'

const PreferencesTab = ({onClose}) => {
  const [tab, setTab] = useState(0);
  const theme = useTheme();
  //const {data, update} = useLoad(getAutoAlerts, {alerta: false, responsavel: 'Carregando'})
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const [teste1, setTeste1] = useState(true);
  const [teste2, setTeste2] = useState(true);
  const [teste3, setTeste3] = useState(true);


  const handleChangeIndex = (index) => {
    setTab(index);
  };

  /*const handleChangeAutoAlerts = async () => {
    await postAutoAlerts(!data.alerta);
    await update();
  }*/

  const handleChangeTeste1 = async () => {
    setTeste1(!teste1)
  }

  const handleChangeTeste2 = async () => {
    setTeste2(!teste2)
  }

  const handleChangeTeste3 = async () => {
    setTeste3(!teste3)
  }

  return (
    <BigTab>
      <Head>
        <h1>MONITORAMENTO</h1>
        <button onClick={onClose}><MdClose /></button>
      </Head>
      <Title>PREFERÊNCIAS</Title>

      <AppBar position="static" color="default">
        <StyledTabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ children: <span /> }}
          aria-label="full width tabs example"
        >
          <StyledTab label="ATALHOS" />
          {/*<StyledTab label="SONS" />*/}
        </StyledTabs>
      </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tab}
          onChangeIndex={handleChangeIndex}
        >
          <Grid style={{marginLeft: "20px"}}>
            {preferences.map((pref, index) => <HotkeysDescription {...pref} key={'hotkey'+index} />)}
          </Grid>

          <Column align='flex-start' margin='32px 48px'>
            <Row w='200px' justify='space-between'>
              <span>Alerta Raio</span>
              <Switch
                checked={teste1}
                onChange={handleChangeTeste1}
                value="Som alerta raio"
                color="primary" />
            </Row>
            <Row w='200px' justify='space-between'>
              <span>Liberação</span>
              <Switch
                checked={teste2}
                onChange={handleChangeTeste2}
                value="Som liberação"
                color="primary" />
            </Row>
            <Row w='200px' justify='space-between'>
              <span>Alerta Automático</span>
              <Switch
                checked={teste3}
                onChange={handleChangeTeste3}
                value="Som alerta automático"
                color="primary" />
            </Row>
          </Column>
        </SwipeableViews>
    </BigTab>
  )
}

PreferencesTab.propTypes = {
  onClose: p.func.isRequired
}

export default PreferencesTab
