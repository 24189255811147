/* Libraries */
import PropTypes from 'prop-types';
import { useState, useStyles, useEffect, useRef, React, PureComponent } from 'react';

/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";

/* Components */
import { useToast } from 'Context/ToastContext';
import GraphicOptions  from './GraphicOptions';
import GraphicOptionsGeneral  from './GraphicOptionsGeneral';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
//import { jsPDF } from "jspdf";
import ArrowLeft from '../ArrowLeft';

/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { getGraphicsData } from 'services/mapsPortal/alerts';
//import { jsPDF } from "jspdf";
import generatePDF, {Margin, Resolution} from 'react-to-pdf';
//MODAL
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { Column, Row } from 'components/Flex';
import Collapsible from 'components/Collapsible';
import TargetInfo from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab/TargetInfo';

// Hooks
import { useModal } from 'Context/ModalContext';
import { useTargetState } from 'Context/TargetStateContext';
import {postModifiedAlerts} from 'services/mapsPortal/alerts'
import Company from 'pages/AdminDashboard/Edit/Company';
import moment from 'moment';


const Body = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 90%;
height: 90%;
background-color: white;
border-radius: 13px;
display: flex;
overflow:'scroll';
align-items: stretch;
`
const GraphicType = styled.div`
background-color: white;
border-radius: 13px 0 0 13px;
margin-top:50px;
margin-bottom:30px;
height:40%;
`

const ModalGraphics = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo, openGraphics, setOpenGraphics} = props;
  const [addState, setAddState] = useState(false);
  const [obtainingData, setObtainingData] = useState(true);
  const [arrangingData, setArrangingData] = useState(true);
  const [indexesList, setIndexesList] = useState([]);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [alertsMonths, setAlertsMonths] = useState([]);
  const id = open ? 'simple-popover' : undefined
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [noGraphics, setNoGraphics] = useState(false)

  const [selectedData, setSelectedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const openToast = useToast();
  const [generalData, setGeneralData] = useState({})
  const [maxGraphics, setMaxGraphics] = useState(0);
  const [targetsData, setTargetsData] = useState([]);
  var gridClasses = [
    classes.gridColumnsComponent,
    classes.gridMarginLeft,
  ]

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);

  const handleCloseGraphics = () => {
    setOpenGraphics(null);
    setAnchorEl(null)
    
  }

  const loadGraphicsData = async () => {
    let aux = []
    let auxDateIn = new Date(data.initDate);
    let auxDateFin= new Date(data.finalDate);
    let dayInit = auxDateIn.getDate() > 9? String(auxDateIn.getDate()) : "0"+String(auxDateIn.getDate())
    let dayFin = auxDateFin.getDate()> 9? String(auxDateFin.getDate()) : "0"+String(auxDateFin.getDate())
    let monthInit = auxDateIn.getMonth()+1 > 9? String(auxDateIn.getMonth()+1) : "0"+String(auxDateIn.getMonth()+1)
    let monthFin = auxDateFin.getMonth()+1 > 9? String(auxDateFin.getMonth()+1) : "0"+String(auxDateFin.getMonth()+1)
    
    await getGraphicsData(data.idEmpresa, String(auxDateIn.getFullYear())+monthInit+dayInit, String(auxDateFin.getFullYear())+monthFin+dayFin).then((response) => {
      response.map((elementResponse)=>{
        if(data.alvosEspecificos.length>=1){
          if(data.alvosEspecificos.includes(elementResponse.nome_alvo)){
            aux.push(elementResponse)
            targetsData[elementResponse.nome_alvo] = {alertsDayMonth: {}, graphTypes: [], alertsMonth: {Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0, Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0}, durationAlerts: {}, heatMap: [], accumulatedMonth: [], alertsYearMonth:{}, buffers:{"4km": {}, "10km": {}, "20km": {}}}

          }
        }else if(data.alvosGerais.length>=1){
          if(data.alvosGerais.includes(elementResponse.nome_alvo)){
            aux.push(elementResponse)
            targetsData[elementResponse.nome_alvo] = {alertsDayMonth: {}, graphTypes: [], alertsMonth: {Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0, Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0}, durationAlerts: {}, heatMap: [], accumulatedMonth: [], alertsYearMonth:{}, buffers:{"4km": {}, "10km": {}, "20km": {}}}

          }
        }
      })
      setAllData(response)
    })
    setSelectedData(aux);
    setObtainingData(false);
  }

  const arrangeValues = async () =>{
    let indexes = []
    let counterNoGraph = 0
  
    let counterNoGraphGeneral = 0
    console.log(selectedData);
    await selectedData.map((element)=> {

      let dateAlert = new Date(element.data_alerta)
      let dateLiberation = new Date(element.data_liberacao)
      let dayMonth = dateAlert.getDate() + "/" + String(dateAlert.getMonth()+1);
      let yearMonth = dateAlert.getFullYear()+"/"+String(dateAlert.getMonth()+1);
      let especificGraphicsMake = []

      data.graficosEspecificos.map((graphEspecif) => {
        if(graphEspecif.includes(element.nome_alvo)){
          if(graphEspecif.includes(",")){
            let aux = []
            aux = graphEspecif.split("#")[1]
            aux = aux.split(",")
            especificGraphicsMake = aux
          }else{
            let aux = []
            aux = graphEspecif.split("#")[1]
            especificGraphicsMake.push(aux)
          }
        }
      })

      if(!indexes.includes(element.nome_alvo)){
        indexes.push(element.nome_alvo)
      }
      if(especificGraphicsMake!=null){
        let someInitMonit = false
        let initMonitGeneral = false
        console.log(especificGraphicsMake.length);
        especificGraphicsMake.map((graphicElement) => {
          
          let initialDateGraphic = null
          let finalDateGraphic = null
          if(data.periodoGeralAlvos == false){
            if(data.periodoAlvo[element.nome_alvo] == false){
              if(data.inicioMonitGeralAlvosGeral == false){
                if(data.inicioMonitGeralAlvosEspecificos[element.nome_alvo] == false){
                    if(data.inicioMonitEspecificos.length>0){
                      data.inicioMonitEspecificos.map((alvEspec) => {
                        if(alvEspec.includes(element.nome_alvo+"#"+graphicElement)){
                          someInitMonit = true
                          initialDateGraphic = new Date(moment(data.inicioMonitTabela).format("YYYY-MM-DD").toString())
                          finalDateGraphic = new Date()
                        }
                      })
                    }
                }else{
                  initMonitGeneral = true
                  initialDateGraphic = new Date(moment(data.inicioMonitTabela).format("YYYY-MM-DD").toString())
                  finalDateGraphic = new Date()
                }
              }else{
                initMonitGeneral = true
                initialDateGraphic = new Date(moment(data.inicioMonitTabela).format("YYYY-MM-DD").toString())
                finalDateGraphic = new Date()
              }
            }else{
              initialDateGraphic = new Date(moment(data.initDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
              finalDateGraphic = new Date(moment(data.finalDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
            }
          }else{
            initMonitGeneral = true
            initialDateGraphic = new Date(moment(data.initDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
            finalDateGraphic = new Date(moment(data.finalDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
          }

          if(initialDateGraphic==null && finalDateGraphic==null){
            data.datasGraficosEspecificos.initialDate.map((inDate)=>{
              if(inDate.includes(element.nome_alvo+"#"+graphicElement)){
                initialDateGraphic = new Date(moment(inDate.split("#")[2]).format("YYYY-MM-DD").toString())
              }
            })

            data.datasGraficosEspecificos.finalDate.map((finDate)=>{
              if(finDate.includes(element.nome_alvo+"#"+graphicElement)){
                finalDateGraphic = new Date(moment(finDate.split("#")[2]).format("YYYY-MM-DD").toString())
              }
            })
          }


          initialDateGraphic = new Date(initialDateGraphic)
          finalDateGraphic = new Date(finalDateGraphic)
          
          if(dateAlert >= initialDateGraphic && dateAlert <= finalDateGraphic){

            if(graphicElement == "Histórico dos Alertas"){
              if(targetsData[element.nome_alvo].alertsDayMonth[dayMonth]){
                targetsData[element.nome_alvo].alertsDayMonth[dayMonth] += 1
              }else{
                targetsData[element.nome_alvo].alertsDayMonth[dayMonth] = 1
              }
        
              if(targetsData[element.nome_alvo].alertsYearMonth[yearMonth]){
                targetsData[element.nome_alvo].alertsYearMonth[yearMonth] += 1
              }else{
                targetsData[element.nome_alvo].alertsYearMonth[yearMonth] = 1
              }
            }else if(graphicElement == "Histórico da Duração"){

              if(targetsData[element.nome_alvo].durationAlerts[String(dateAlert.getHours())]){
                targetsData[element.nome_alvo].durationAlerts[String(dateAlert.getHours())] += 1
              }else{
                targetsData[element.nome_alvo].durationAlerts[String(dateAlert.getHours())] = 1
              }


            }else if(graphicElement == "Histórico da Variação Diurna das Descargas Atmosféricas"){
      
              if(targetsData[element.nome_alvo].buffers["4km"]["H"+String(dateAlert.getHours())]){
                targetsData[element.nome_alvo].buffers["4km"]["H"+String(dateAlert.getHours())]+=parseInt(element.qtde_descarga_4km)
              }else{
                targetsData[element.nome_alvo].buffers["4km"]["H"+String(dateAlert.getHours())] =parseInt(element.qtde_descarga_4km)
              }
        
              if(targetsData[element.nome_alvo].buffers["10km"]["H"+String(dateAlert.getHours())]){
                targetsData[element.nome_alvo].buffers["10km"]["H"+String(dateAlert.getHours())]+=parseInt(element.qtde_descarga_10km)
              }else{
                targetsData[element.nome_alvo].buffers["10km"]["H"+String(dateAlert.getHours())] =parseInt(element.qtde_descarga_10km)
              }
        
              if(targetsData[element.nome_alvo].buffers["20km"]["H"+String(dateAlert.getHours())]){
                targetsData[element.nome_alvo].buffers["20km"]["H"+String(dateAlert.getHours())]+=parseInt(element.qtde_descarga_20km)
              }else{
                targetsData[element.nome_alvo].buffers["20km"]["H"+String(dateAlert.getHours())] =parseInt(element.qtde_descarga_20km)
              }

            }else if(graphicElement == "Acumulado Mensal das Descargas Atmosféricas"){

              if(targetsData[element.nome_alvo].buffers["4km"][dateAlert.getMonth()+1]){
                targetsData[element.nome_alvo].buffers["4km"][dateAlert.getMonth()+1]+=parseInt(element.qtde_descarga_4km)
              }else{
                targetsData[element.nome_alvo].buffers["4km"][dateAlert.getMonth()+1] =parseInt(element.qtde_descarga_4km)
              }
        
              if(targetsData[element.nome_alvo].buffers["10km"][dateAlert.getMonth()+1]){
                targetsData[element.nome_alvo].buffers["10km"][dateAlert.getMonth()+1]+=parseInt(element.qtde_descarga_10km)
              }else{
                targetsData[element.nome_alvo].buffers["10km"][dateAlert.getMonth()+1] =parseInt(element.qtde_descarga_10km)
              }
        
              if(targetsData[element.nome_alvo].buffers["20km"][dateAlert.getMonth()+1]){
                targetsData[element.nome_alvo].buffers["20km"][dateAlert.getMonth()+1]+=parseInt(element.qtde_descarga_20km)
              }else{
                targetsData[element.nome_alvo].buffers["20km"][dateAlert.getMonth()+1] =parseInt(element.qtde_descarga_20km)
              }
  
            }else if(graphicElement == "Acumulado Mensal dos Alertas"){
              if(parseInt(dateAlert.getMonth()+1)==1){
                targetsData[element.nome_alvo].alertsMonth["Jan"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==2){
                targetsData[element.nome_alvo].alertsMonth["Feb"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==3){
                targetsData[element.nome_alvo].alertsMonth["Mar"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==4){
                targetsData[element.nome_alvo].alertsMonth["Apr"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==5){
                targetsData[element.nome_alvo].alertsMonth["May"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==6){
                targetsData[element.nome_alvo].alertsMonth["Jun"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==7){
                targetsData[element.nome_alvo].alertsMonth["Jul"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==8){
                targetsData[element.nome_alvo].alertsMonth["Aug"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==9){
                targetsData[element.nome_alvo].alertsMonth["Sep"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==10){
                targetsData[element.nome_alvo].alertsMonth["Oct"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==11){
                targetsData[element.nome_alvo].alertsMonth["Nov"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==12){
                targetsData[element.nome_alvo].alertsMonth["Dec"] += 1
              }
        
            }
            if(!targetsData[element.nome_alvo].graphTypes.includes(graphicElement)){
              targetsData[element.nome_alvo].graphTypes.push(graphicElement)
            }
            
            setNoGraphics(false)
          }else{
            counterNoGraph+=1
          }
        })
      }
    })

    let valuesPerMonthGeneral = []
    let valuesPerYearMonthGeneral = [] 
    let alertMonthsGeneral = {Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0, Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0}
    let durationAlertsGeneral = {}
    let buffersGeneral = {"4km": {}, "10km": {}, "20km": {}}

   
    if(data.graficosGerais.length>0){

      allData.map((elementData)=>{
        data.graficosGerais.map((elementGraficos)=>{
          let dateInit = null
          let dateFin = null
          if(data.periodoGraficosGerais==false){
            if(data.inicioMonitGeralGraficos == false){
              if(!data.inicioMonitGraficosGeraisEspecificos.includes(elementGraficos)){

                data.datasGraficosGerais.initialDate.map((inDate)=>{
                  if(inDate.includes(elementGraficos)){
                    dateInit = new Date(moment(inDate.split("#")[1]).format("YYYY-MM-DD").toString())
                  }
                })

                data.datasGraficosGerais.finalDate.map((finDate)=>{
                  if(finDate.includes(elementGraficos)){
                    dateFin = new Date(moment(finDate.split("#")[1]).format("YYYY-MM-DD").toString())
                  }
                })

              }else{
                dateInit = new Date(moment(data.initDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
                dateFin = new Date(moment(data.finalDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())


              }
            }else{
              dateInit = new Date(moment("12-25-1920", "MM-DD-YYYY").format("YYYY-MM-DD").toString())
              dateFin = new Date()
            }
          }else{
            dateInit = new Date(moment(data.initDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
            dateFin = new Date(moment(data.finalDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString())
          }

          let dateAlert = new Date(elementData.data_alerta)

          if(dateAlert >= dateInit && dateAlert <= dateFin){
            let dayMonth = dateAlert.getDate() + "/" + String(dateAlert.getMonth()+1);
            let yearMonth = dateAlert.getFullYear()+"/"+String(dateAlert.getMonth()+1);

            if(elementGraficos == "Histórico dos Alertas"){
              if(valuesPerMonthGeneral[dayMonth]){
                valuesPerMonthGeneral[dayMonth] += 1
              }else{
                valuesPerMonthGeneral[dayMonth] = 1
              }

              
              if(valuesPerYearMonthGeneral[yearMonth]){
                valuesPerYearMonthGeneral[yearMonth] += 1
              }else{
                valuesPerYearMonthGeneral[yearMonth] = 1
              }
            }else if(elementGraficos == "Histórico da Duração"){

              if(durationAlertsGeneral[String(dateAlert.getHours())]){
                durationAlertsGeneral[String(dateAlert.getHours())] += 1
              }else{
                durationAlertsGeneral[String(dateAlert.getHours())] = 1
              }

            }else if(elementGraficos == "Histórico da Variação Diurna das Descargas Atmosféricas"){
              if(buffersGeneral["4km"]["H"+String(dateAlert.getHours())]){
                buffersGeneral["4km"]["H"+String(dateAlert.getHours())]+=parseInt(elementData.qtde_descarga_4km)
              }else{
                buffersGeneral["4km"]["H"+String(dateAlert.getHours())] =parseInt(elementData.qtde_descarga_4km)
              }
        
              if(buffersGeneral["10km"]["H"+String(dateAlert.getHours())]){
                buffersGeneral["10km"]["H"+String(dateAlert.getHours())]+=parseInt(elementData.qtde_descarga_10km)
              }else{
                buffersGeneral["10km"]["H"+String(dateAlert.getHours())] =parseInt(elementData.qtde_descarga_10km)
              }
        
              if(buffersGeneral["20km"]["H"+String(dateAlert.getHours())]){
                buffersGeneral["20km"]["H"+String(dateAlert.getHours())]+=parseInt(elementData.qtde_descarga_20km)
              }else{
                buffersGeneral["20km"]["H"+String(dateAlert.getHours())] =parseInt(elementData.qtde_descarga_20km)
              }

            }else if(elementGraficos == "Acumulado Mensal das Descargas Atmosféricas"){

              if(buffersGeneral["4km"][dateAlert.getMonth()]){
                buffersGeneral["4km"][dateAlert.getMonth()]+=parseInt(elementData.qtde_descarga_4km)
              }else{
                buffersGeneral["4km"][dateAlert.getMonth()] =parseInt(elementData.qtde_descarga_4km)
              }
        
              if(buffersGeneral["10km"][dateAlert.getMonth()]){
                buffersGeneral["10km"][dateAlert.getMonth()]+=parseInt(elementData.qtde_descarga_10km)
              }else{
                buffersGeneral["10km"][dateAlert.getMonth()] =parseInt(elementData.qtde_descarga_10km)
              }
        
              if(buffersGeneral["20km"][dateAlert.getMonth()]){
                buffersGeneral["20km"][dateAlert.getMonth()]+=parseInt(elementData.qtde_descarga_20km)
              }else{
                buffersGeneral["20km"][dateAlert.getMonth()] =parseInt(elementData.qtde_descarga_20km)
              }

            }else if(elementGraficos == "Acumulado Mensal dos Alertas"){
              if(parseInt(dateAlert.getMonth()+1)==1){
                alertMonthsGeneral["Jan"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==2){
                alertMonthsGeneral["Feb"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==3){
                alertMonthsGeneral["Mar"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==4){
                alertMonthsGeneral["Apr"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==5){
                alertMonthsGeneral["May"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==6){
                alertMonthsGeneral["Jun"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==7){
                alertMonthsGeneral["Jul"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==8){
                alertMonthsGeneral["Aug"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==9){
                alertMonthsGeneral["Sep"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==10){
                alertMonthsGeneral["Oct"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==11){
                alertMonthsGeneral["Nov"] += 1
              }else if(parseInt(dateAlert.getMonth()+1)==12){
                alertMonthsGeneral["Dec"] += 1
              }

            }
          }else{
            counterNoGraphGeneral+=1

          }
        })  
      })
    }
    if(counterNoGraphGeneral==data.graficosGerais.length && counterNoGraph==data.graficosEspecificos.length){
      setNoGraphics(true)

    }
    setGeneralData({alertsDayMonth: valuesPerMonthGeneral, durationAlerts: durationAlertsGeneral, alertMonth: alertMonthsGeneral, alertsYearMonth:valuesPerYearMonthGeneral, buffers:buffersGeneral})
    setIndexesList(indexes)
    setArrangingData(false)
  }
  

  useEffect(() => {
    if(obtainingData==false){
      arrangeValues()
    }
  }, [obtainingData]);

  useEffect(() => {
    if(data.idEmpresa!=null){
      loadGraphicsData()
    }
  }, []);

  const optionsPDF = {
    method: 'open',

    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'portrait',
   },
  }
  const getPDFElementAll = () => document.getElementById('all_grafics');
  const getPDFElementGeneral = () => document.getElementById('graficos_div_general');
  const getPDFElementEspecific = () => document.getElementById('graficos_div_especific');

  if(targetsData!=null && selectedData.length>0 && !obtainingData && !arrangingData && !noGraphics){
    
    return(
      <Modal
        open={openGraphics}
        onClose={() => setAnchorEl(null)}
        aria-labelledby="simple-modal-title2"
        aria-describedby="simple-modal-description2"
      >
        <Body>
          <div className="graphics_div" id="graficos_div" style={{marginLeft:"auto", marginRight:"auto", overflowY: "scroll"}}> 
            <div id="all_grafics">
              <div id="graficos_div_especific">
                {data.alvosEspecificos.map((element, index)=>{
                    console.log(targetsData[element].graphTypes);
                  return(
                    <div key={index}>
                      <GraphicOptions infos={targetsData} indexesList={indexesList} graphicTypes={targetsData[element].graphTypes} gCount={index} alvo={element}/>
                    </div>
                  )
                })}
              </div>
              <div id="graficos_div_general">
                <GraphicOptionsGeneral infos={generalData} graphicTypes={data.graficosGerais}/>
              </div>
            </div>
          </div>
          <div className="Buttons">
            <br/>
            <div style={{verticalAlign:"top", display:"grid"}}>
              <button onClick={() => generatePDF(getPDFElementAll, optionsPDF)}>Exportar PDF (Todos)</button>
              <br/>
              <button onClick={() => generatePDF(getPDFElementGeneral, optionsPDF)}>Exportar PDF (Gerais)</button>
              <br/>
              <button onClick={() => generatePDF(getPDFElementEspecific, optionsPDF)}>Exportar PDF (Específicos)</button>
            </div>
          </div>
          <div className={classes.noBorderButton2} data-dismiss="modal" onClick={() => handleCloseGraphics()}>
              <ArrowLeft color='black'></ArrowLeft>
          </div>
        </Body>
      </Modal>
    )
  }else{
    return(
      <Modal
      open={openGraphics}
      onClose={() => setAnchorEl(null)}
      aria-labelledby="simple-modal-title2"
      aria-describedby="simple-modal-description2"
    >
      <Body>
        <div className="graphics_div" id="graficos_div" style={{marginLeft:"auto", marginRight:"auto", display: "flex", alignItems:"center"}}> 
          <h1>
            Sem dados para os alvos selecionados
          </h1>
        </div>
        <div className={classes.noBorderButton2} data-dismiss="modal" onClick={() => handleCloseGraphics()}>
            <ArrowLeft color='black'></ArrowLeft>
        </div>
      </Body>
    </Modal>
    )
  }
} 

ModalGraphics.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  setOpenGraphics: PropTypes.func,
  openGraphics: PropTypes.bool,
  anchorEl: PropTypes.bool,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
  tab: PropTypes.object.isRequired,
  
};
  
export default withStyles(styles)(ModalGraphics);