import { lambdaApi } from 'services/api';

const divideByLocation = (radar) => {
  return radar?.reduce((location, rad, index) => {
    if (!location.length || location[location.length-1][0].nome !== rad.nome)
      location.push([rad])
    else location[location.length-1].push(rad)
    return location
  }, [])
}

export const getRadarMax = async () => {
  try {
      const response = await lambdaApi.get('radar/maxcappi');
      if(!Array.isArray(response.data)) throw new Error('Error na api');
      return divideByLocation(response.data)
  } catch (error) {
    throw new Error('Não foi possível obter os dados do radar MaxCappi');
  }
};

export const getRadar3 = async () => {
  try {
      const response = await lambdaApi.get('radar/03km');
      if(!Array.isArray(response.data)) throw new Error('Error na api');
      return divideByLocation(response.data)
  } catch (error) {
    throw new Error('Não foi possível obter os dados do radar 3km');
  }
};

export const getRadar5 = async () => {
  try {
      const response = await lambdaApi.get('radar/05km');
      if(!Array.isArray(response.data)) throw new Error('Error na api');
      return divideByLocation(response.data)
  } catch (error) {
    throw new Error('Não foi possível obter os dados do radar 5km');
  }
};

export const getRadar7 = async () => {
  try {
      const response = await lambdaApi.get('radar/07km');
      if(!Array.isArray(response.data)) throw new Error('Error na api');
      return divideByLocation(response.data)
  } catch (error) {
    throw new Error('Não foi possível obter os dados do radar 7km');
  }
};

export const getRadar10 = async () => {
  try {
      const response = await lambdaApi.get('radar/10km');
      if(!Array.isArray(response.data)) throw new Error('Error na api');
      return divideByLocation(response.data)
  } catch (error) {
    throw new Error('Não foi possível obter os dados do radar 10km');
  }
};
