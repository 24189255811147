/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef, createContext} from 'react';
import {Head, Title, BigTab} from '../../pages/MapsPortal/components/Sidebar/styled'
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import ArrowLeft from '../ArrowLeft';
import {MdClose} from 'react-icons/md';

import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import ComplexTable from 'containers/Pages/Table';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';

import { ImMail4 } from 'react-icons/im';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaTelegramPlane, FaPhoneAlt, FaWhatsapp} from 'react-icons/fa';

import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
import { MdOutlineTaskAlt, MdOutlineUnpublished, MdWarningAmber, MdOutlineTextsms, MdMailOutline, MdOutlinePhoneIphone } from 'react-icons/md';
import Modal from '@material-ui/core/Modal';
import ResendTable from './ResendTable';
import { PapperBlock } from 'components';
import styled from 'styled-components';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import {getTargetInfosByTargetID} from 'services/adminDashboard/target'


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const ModalReenvio = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo, clientUser} = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const [resend, setResend] = useState([]);
  const [loadingResend, setLoadingResend] = useState(true);

  const handleCloseFirst = () => {
    setOpen(null);
    setReloadMod(true);
  }

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);
  console.log(clientUser)
  const initiateInfos = () =>{
    if(tableData.resendInfos!=null){
      if(tableData.resendInfos.length>0){
        let aux = []
        tableData.resendInfos.map((element) => {
          let actualVal = element.split("_")[0]
          let marker = 0
          if(aux.length>0){
            aux.map((value) => {
              if(value.label == actualVal){
                marker = 1 
              }
            })
          }
          if(marker != 1){
            aux.push({label: actualVal, component:<ResendTable value={element} clientUser={clientUser!=undefined&&clientUser!=""? clientUser : false}  selectedUsers={tableData.resendExtraInfos} closeModal={handleCloseFirst}/>});
            
          }
  
        })
        setCompanyList(aux);
      }
    }else if(tableData.values!=null){
      if(tableData.values.length>0){
        let aux = []
        tableData.values.map((element) => {
          let actualVal = element.split("_")[5]
          let marker = 0
          if(aux.length>0){
            aux.map((value) => {
              if(value.label == actualVal){
                marker = 1 
              }
            })
          }
          if(marker != 1){
            aux.push({label: actualVal, component:<ResendTable value={element} clientUser={clientUser!=undefined&&clientUser!=""? clientUser : false} selectedUsers={tableData.resendExtraInfos} closeModal={handleCloseFirst}/>});
            
          }
  
        })
        setCompanyList(aux);



      }
    }
  }
   
    useEffect(() => {
      initiateInfos();
    }, []);

    return(
      <Modal
        open={open}
        onClose={handleCloseFirst}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <BigTab style={{maxHeight: '100%', overflow: 'auto', width:"100%"}} >
            <div style={{width: "100%", display: "flex",  alignItems: "center", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
              <div style={{width: "100%", display: "flex", alignSel: "center", fontSize:"20px", color:"white", justifyContent: "end", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
                  <h2>Reenvio</h2>   
              </div>
              <div style={{width: "100%", display: "flex", alignItems: "end", justifyContent: "end", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
                <button style={{textAlign:"right", padding:"0", border:"0", backgroundColor:"transparent", color:"white", fontSize:"24px", transition:"0.3s ease"}} onClick={() => {handleCloseFirst()}}><MdClose /></button>
              </div>
            </div>
              <PapperBlock fronttabs={true} tabsList={companyList} icon="ion-ios-paper-outline"/>
        </BigTab>
      </Modal>
    )
  }


ModalReenvio.propTypes = {
  classes: PropTypes.object,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.object,
  clientUser: PropTypes.bool,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalReenvio);