import React, { memo } from 'react';
import {IntersectionLayer} from './IntersectionLayer';
import {Target} from './Target';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';
import {useTargetState} from 'Context/TargetStateContext';

const TargetLayerComponent = () => {
    const { clientTargets, toShow, showTargets, mergeTargetsOption } = useClientTargetsState();
    const { targetState } = useTargetState();
    if (!showTargets) return <></>;
    if (clientTargets === undefined || !clientTargets || clientTargets.length === 0) return <></>; 

    return (
      <>
        <IntersectionLayer />
          {clientTargets[0]?.planos.map((plan) => {
            return plan.alvos.map((target) => {
              if(!toShow.get(clientTargets[0].id, plan.id, target.id)) return null
                if(mergeTargetsOption) return null
                return (
                  <div key={target.id}>
                    <Target target={target} targetState={targetState[target.id_alvo]} onTime={true} />
                  </div>
                )
              }
            )
          })}
      </>
    )
};

export const TargetLayer = memo(TargetLayerComponent);
