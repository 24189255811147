import React, { useContext, useState } from 'react';
import { ToolsContext } from 'Context/ToolsContext';
import IconButton from '@material-ui/core/IconButton';
import TooltipComponent from 'components/Modal/Tooltip.js';
import {useClientTargetsState} from 'Context/ClientTargetStateContext';
import { Divider } from '@material-ui/core';

import PropTypes from 'prop-types';
import {
    faDrawPolygon,
    faEraser,
    faHandPaper,
    faRulerHorizontal,
    faSatellite,
    faSatelliteDish,
    faBoltLightning,
    faBullseye,
    faRad
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    ExtraOptions,
    ExtraOptionsButton,
    Icon,
    MenuLineWrapper,
    RightMenu,
} from './styled.js';
import {useDischarges} from 'Context/DischargesContext.js';
import {useSatellite} from 'Context/SatelliteContext.js';
import { makeStyles } from "@material-ui/core/styles";
import useBound from 'hooks/useBound';

const useStyles = makeStyles(theme => ({
  divider: {
    background: "#000",
    width: "80%",
    height: "1px",
  },
}));

const OptionsTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <TooltipComponent
      enterDelay={1000}
      leaveDelay={0}
      onClose={() => handleTooltipClose()}
      onOpen={() => handleTooltipOpen()}
      open={open}
      position="top-end"
      text={props.title}
    >
      {props.children}
      </TooltipComponent>
    )
}

const RightMapMenu = () => {
    const { showSatellite, loadingSatellite, toggleSatellite } = useSatellite();
    const {
      toolInUse,
      setToolInUse,
      setMeasureLine,
      setPointHelper,
    } = useContext(ToolsContext);
    const {
      descargas,
      showDischarges,
      loadingDescargas,
      toggleDischarges,
      dischargesToShow,
      toggleDischargeToShow,
      dischargesTypeToShow,
      toggleDischargeTypeToShow,
    } = useDischarges();
    const { showTargets, toggleTargets, mergeTargetsOption, toggleTargetMerge } = useClientTargetsState();
    const [maxBoundsState, centerCoordinate] = useBound();
    
    if(maxBoundsState && centerCoordinate)
      return (
        <RightMenu>
          <OptionsTooltip title='Ferramenta de interação'>
            <Button
                onClick={() => {
                    setToolInUse('default');
                }}
                selected={toolInUse === 'default'}
            >
                <Icon icon={faHandPaper}  />
            </Button>
          </OptionsTooltip>
          <OptionsTooltip title={showSatellite ? 'Esconder imagens satélite' : 'Mostrar imagens satélite'}>
            <Button onClick={toggleSatellite} loading={loadingSatellite} selected={showSatellite}>
                <Icon icon={faSatellite} />
            </Button>
          </OptionsTooltip>
          <MenuLineWrapper>
            <OptionsTooltip title={showDischarges ? 'Esconder descargas' : 'Mostrar descargas'}>
              <Button onClick={toggleDischarges} loading={loadingDescargas} selected={showDischarges}>
                <Icon icon={faBoltLightning} />
              </Button>
            </OptionsTooltip>
            <ExtraOptions>
              {dischargesToShow.map((showBool, index) => (
                <ExtraOptionsButton
                  key={index}
                  selected={showBool}
                  type={`Discharges${(index + 1) * 10}`}
                  onClick={() => toggleDischargeToShow(index)}
                >
                  {(index + 1) * 10} min
                </ExtraOptionsButton>
              ))}
            </ExtraOptions>
          </MenuLineWrapper>
            <MenuLineWrapper>
              <OptionsTooltip title={showTargets ? 'Esconder alvos' : 'Mostrar alvos'}>
                <Button onClick={toggleTargets} selected={showTargets}>
                    <Icon icon={faBullseye} />
                </Button>
              </OptionsTooltip>
              <ExtraOptions>
                <OptionsTooltip title={mergeTargetsOption ? 'Habilitar intersecção' : 'Desabilitar intersecção'}>
                  <ExtraOptionsButton
                    selected={mergeTargetsOption}
                    onClick={toggleTargetMerge}
                  >
                    <svg style={{width: '24px', height: '24px', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M640 640v170.666667a85.333333 85.333333 0 0 1-85.333333 85.333333H213.333333a85.333333 85.333333 0 0 1-85.333333-85.333333v-341.333334a85.333333 85.333333 0 0 1 85.333333-85.333333h170.666667V213.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h341.333334a85.333333 85.333333 0 0 1 85.333333 85.333333v341.333334a85.333333 85.333333 0 0 1-85.333333 85.333333h-170.666667z m-85.333333-85.333333h256V213.333333h-341.333334v256H213.333333v341.333334h341.333334v-256z" /></svg>
                  </ExtraOptionsButton>
                </OptionsTooltip>
              </ExtraOptions>
            </MenuLineWrapper>
        </RightMenu>
    );
};


OptionsTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired
};

export default RightMapMenu;
