const styles = theme => ({
  deleteContainerDiv:{
    display: "flex",
    alignItems: "center",
    height: "60vh",
    width: "40vw",
    backgroundColor: "white",
    marginTop: "100px",
    borderRadius: "13px",
    flexDirection: "column",
  },
  infosContainerDiv:{
    display: "flex",
    alignItems: "center",
    minHeight: "45vh",
    maxHeight: "80vh",
    width: "40vw",
    backgroundColor: "white",
    marginTop: "100px",
    borderRadius: "13px",
    flexDirection: "column",
  },
  deleteContainerHeader:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16%",
    width: "100%",
    backgroundColor: "red",
    borderRadius: "13px 13px 0 0",
    boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.15)",
  },
  infosContainerHeader:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "50px",
    width: "100%",
    backgroundColor: theme.palette.menu.strongBlue,
    borderRadius: "13px 13px 0 0",
    boxShadow: "0px 4px 6px 3px rgba(0, 0, 0, 0.15)",
  },
  deleteContainerBody:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    height: "72%",
    width: "100%",
  },
  infosContainerBody:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    flex: 1
  },
  deleteContainerBodyTitle:{
    height: "18%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderBottom: "1px solid",
  },
  infosContainerBodyTitle:{
    height: "50px",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    borderBottom: "1px solid",
  },
  deleteContainerBodyBody:{
    height: "82%",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    padding: "15px 30px 10px 15px",
    gap: "30px",
  },
  infosContainerBodyBody:{
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    display: "flex",
    padding: "30px 50px 30px 50px",
    gap: "15px",
    overflowY: 'auto',
  },
  deleteContainerBodyBodyColumn:{
    height: "100%",
    width: "50%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    padding: "8px 0 0 8px",
  },
  deleteContainerBodyBodyAll:{
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    padding: "8px 0 0 8px"
  },
  warningItem: {
    marginTop: "10px"
  }, 
  deleteContainerBodyDot:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "1px",
    gap: "10px",
  },
  deleteDot:{
    minHeight: "5px",
    minWidth: "5px",
    backgroundColor:"black",
    borderRadius: "50%",
  },
  deleteContainerFooter:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "12%",
    width: "100%",
    borderRadius: "0 0 13px 13px",
    borderTop: "0.5px solid black",
    boxShadow: "4px 0px 6px 3px rgba(0, 0, 0, 0.15)",
  },
 infosContainerFooter:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "50px",
    width: "100%",
    borderRadius: "0 0 13px 13px",
    borderTop: "1px solid black",
    boxShadow: "4px 0px 6px 3px rgba(0, 0, 0, 0.15)",
  },
  confirmartionButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "5px",
    borderRadius: "5px",
    marginRight: "8%",
    backgroundColor: "red",
    color: "white"
  },
  goBackButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "5px",
    borderRadius: "5px",
    marginRight: "8%",
    backgroundColor: "white",
    border: "2px solid black",
  },
});

export default styles;