/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import 'moment/locale/pt-br';
/* Hooks */
import { useToast } from 'Context/ToastContext';
/* Material UI */
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ReportProblem from "@material-ui/icons/ReportProblem";
import { Divider } from '@material-ui/core';
/* CSS */
import styles from "./lightning-alerts_forms-jss";
import fontStyles from "styles/fonts-jss";
/* Services */
import { getUserAuthenticated } from "services/login/login";
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';
import { getLightningActiveAlertByTargetID } from "services/mapsPortal/alerts";
import { getHolidays } from "services/adminDashboard/holidays";


const useStyles = makeStyles({
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
  switch_secondary: {
    "&.Mui-checked": {
      color: "red",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "red",
    },
  },
  autocomplete: {
    "&.MuiInputBase-input": {
      height: "30px",
    },
  },
  divider: {
    background: "#A5A5A5",
    width: "100%"
  },
})


const FieldsComponent = (props) => {
  const moment = require('moment');
  moment.locale('pt-br');
  const fonts = fontStyles();
  const { classes, target, preset, alertsData, setAlertsData, plano, setReloadSwitchSelected, setReloadModal, allSelected, setAllSelected, allSelectedAux, setAllSelectedAux, setReloadErrorMessage, statusTargetSelected, setStatusTargetSelected, setReloadStatusTargetSelected, reloadTargets, setReloadTargets, setLoadingRightSide, loadingRightSide, setReloadRightSide, lightningAlertErrors, setLightningAlertErrors, setServicesLightningAlert } = props;
  const switchStyle = useStyles();
  const [servicesStatus, setServicesStatus] = useState(true);
  const [hasTargetAlert, setHasTargetAlert] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [caractersRemaning, setCaractersRemaning] = useState();
  const [switchSelected, setSwitchSelected] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [targetIsActive, setTargetIsActive] = useState(false);
  const [loadingTargetData, setLoadingTargetData] = useState(0);
  const [targetData, setTargetData] = useState([]);
  const openToast = useToast();

  const [lightningAlertErrorsAux, setLightningAlertErrorsAux] = useState({
    nivel: null,
    risk: null,
    contact: null,
    duration: null,
    tool: null,
    id_alvo: null
  });

  const [targetAlert, setTargetAlert] = useState(
    {
      nivel: "",
      risco: "",
      meteorologista: null,
      liberado: false,
      modificacao: null,
      contato: "",
      ferramenta: [],
      duracao: "",
      observacao: null,
      campos_modificados: null,
      data_hora_liberacao: null,
      id_alvo: "",
    }
  );

  var alertsDataAux = alertsData;

  const tools = ["Descargas", "Radar", "Satélite", "TSC"]

  var gridClasses = [
    classes.gridColumnsComponent,
    classes.gridMarginLeft,
  ]

  const toggleSwitchSelected = () => {
    if (hasTargetAlert) {
      if (!switchSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(!switchSelected);
          alertsDataAux.splice(index, 1);
          alertsDataAux.push(targetAlert);
          setAlertsData(alertsDataAux);
        }
        else {
          alertsDataAux.push(targetAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(!switchSelected);
        }
        let aux = lightningAlertErrors;
        let indexError = aux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (indexError != -1) {
          aux.splice(indexError, 1);
          aux.push(lightningAlertErrorsAux);
          setLightningAlertErrors(aux);
        }
        else {
          aux.push(lightningAlertErrorsAux);
          setLightningAlertErrors(aux);
        }
      }
      else {
        setAllSelected(false);
        setAllSelectedAux(true);
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        let aux = lightningAlertErrors;
        aux = aux.filter((item) => target.id_alvo != item.id_alvo);
        setLightningAlertErrors(aux);
        setAlertsData(alertsDataAux);
        setSwitchSelected(!switchSelected);
      }
    }
    setReloadSwitchSelected(true);
  }

  const loadServiceStatus = async () => {
    if (target) {
      await getLightningActiveAlertByTargetID(target.id_alvo).then((response) => {
        if (response && response.length > 0) {
          setTargetIsActive(true);
        }
        else{
          loadTargetData();
        }
      })
    }
  }

  const loadTargetData = async () => {
    if(target.id_alvo){
      await getTargetInfosByTargetID(target.id_alvo).then((response) => {
        if(response[0].length > 0){
          setTargetData(response[0][0][0]);
          setLoadingTargetData(1);
          loadServices(response[0][0][0].servicos, response[0][0][0].usuarios, response[0][0][0]);
        }
        else{
          setLoadingTargetData(2);
        }
      }).catch((error) => {
        setLoadingTargetData(2);
      });
    }
  }

  const loadServices = (servicesData, employeesData, targetData) => {
    var today = moment().format('dddd');
    today = today === "sábado"? "sabado" : today;
    var now = moment();
    var stringfyLightningAlert = servicesData?.filter((alert) => alert.tipo === "Alerta Raio" && alert.status === true);
    var parsedLightningAlert = [];
    stringfyLightningAlert?.length > 0? stringfyLightningAlert.map((alert) => parsedLightningAlert.push({id_service : alert.id_servico, monitoring: alert.monitoramento})) : "";
    parsedLightningAlert?.length > 0?  parsedLightningAlert.map((alert) => {
      alert.monitoring.map((days, index) => {
        var aux = [];
        if(typeof days[0] === "string"){
          aux.push(JSON.parse(days))
          alert.monitoring[index] = aux;
        }
      });
    }) : "";

    var test = parsedLightningAlert?.length > 0? parsedLightningAlert.map((alert) => 
      alert.monitoring.some((days) => 
        days[0].datafunc_dias.includes((today[0].toUpperCase() + today.slice(1, 3) + ", ")) && 
        now.isBetween(
          moment(days[0].datafunc_hora_inicio, 'hh:mm:ss'),
          moment(days[0].datafunc_hora_fim, 'hh:mm:ss')
        )
      )
    ) : [];

    var lightningAlert = test.length > 0 && test.includes(true)? 1 : parsedLightningAlert.length > 0? 2 : 0;

    if(servicesData && servicesData[0]){
      if(lightningAlert === 1){
        loadHolidays(1, employeesData, targetData)
      }
      else if(lightningAlert === 2){
        loadHolidays(2, employeesData, targetData)
      }
      else{
        setHasTargetAlert(false);
      }
    }
    else{
      setHasTargetAlert(false)
    }
  }

  const loadHolidays = async (serviceStatusAux, employeesData, targetData) => {
    var holidays = false;
    serviceStatusAux === 1? setServicesStatus(true) : setServicesStatus(false);

    await getHolidays(moment().format("YYYY")).then((response) => {
      if(response){

        for (let j = 0; j < response.length; j++) {
          if(moment(response[j].date).isSame(moment().format('YYYY-MM-DD'))){
            holidays = true;
          }
        }

        if(holidays && !targetData.plano[0].feriados){
          setServicesStatus(false);  
        }
        setHasTargetAlert(true);
        loadContacts(employeesData);
        loadUserAuthenticated();
      }
    }).catch((error) => {
      openToast('error', 'Problema ao buscar feriados nacionais, recarregue a página e tente novamente!')
    });
  }

  const loadUserAuthenticated = async () => {
    var loginToken = JSON.parse(localStorage.getItem("reeWAL57qe"));

    await getUserAuthenticated(loginToken).then((response) => {
      if (response.status === 200) {
        targetAlert.meteorologista = response.data.usuario.id;
      }
    }).catch((error) => {
      openToast('error', 'Problema ao buscar usuário autenticado, recarregue a página e tente novamente!')
    });
  }

  const loadContacts = (data) => {
    var contactsList = [{ nome: "Não foi possível o contato telefônico.", prioridade: [""] }, { nome: "Não foi realizado o contato telefônico.", prioridade: [""] }, { nome: "Não há contato telefônico das xx:xx até às xx:xx.", prioridade: [""] }];

    if(data && data[0]){
      var contactData = [];
      data.map((user) => user.prioridade? contactData.push(user) : contactData);

      const sortData = ( a, b ) => {
        if ( a.prioridade.prioridade < b.prioridade.prioridade ){
          return -1;
        }
        if ( a.prioridade.prioridade > b.prioridade.prioridade ){
          return 1;
        }
        return 0;
      }

      contactData.sort(sortData);

      for (let i = 0; i < contactData.length; i++) {
        contactsList.push({ nome: contactData[i].nome, prioridade: contactData[i].prioridade.prioridade})
      } 
        setContacts(contactsList);
        setLoadingContacts(false);
    }
    else{
      setContacts(contactsList);
      setLoadingContacts(false);
    }
  }

  useEffect(() => {
    targetAlert.nivel = preset.nivel;
    targetAlert.risco = preset.risco;
    targetAlert.contato = preset.contato;
    targetAlert.ferramenta = preset.ferramenta;
    targetAlert.duracao = preset.duracao;
    targetAlert.observacao = preset.observacao;
    setReloadModal(true);
  }, [preset.preset]);

  useEffect(() => {
    if (target) {
      targetAlert.id_alvo = target.id_alvo;
      targetAlert.nome = target.nome;
      lightningAlertErrorsAux.id_alvo = target.id_alvo;
      loadServiceStatus();
    }
  }, []);

  useEffect(() => {
    if (hasTargetAlert) {
      if (allSelected) {
        let index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          setAllSelectedAux(false);
          setSwitchSelected(allSelected);
        }
        else {
          alertsDataAux.push(targetAlert);
          setAllSelectedAux(false);
          setAlertsData(alertsDataAux);
          setSwitchSelected(allSelected);
          let aux = lightningAlertErrors;
          aux.push(lightningAlertErrorsAux);
          setLightningAlertErrors(aux);
        }
      }
      else if (!allSelected && !allSelectedAux) {
        alertsDataAux = alertsDataAux.filter((item) => target.id_alvo != item.id_alvo);
        setAlertsData(alertsDataAux);
        setSwitchSelected(allSelected);
        let aux = lightningAlertErrors;
        aux = aux.filter((item) => target.id_alvo!= item.id_alvo);
        setLightningAlertErrors(aux);
      }
    }
  }, [allSelected])

  useEffect(() => {
    if (!servicesStatus) {
      let aux = statusTargetSelected;
      if (!aux.includes(target.nome)) {
        aux.push(target.nome);
        setStatusTargetSelected(aux);
        setReloadStatusTargetSelected(true);
      }
      else{
        if(!switchSelected){
          aux.pop(target.nome);
        }
      }
    }
  }, [switchSelected])

  useEffect(() => {
    setReloadTargets(false);
    loadServiceStatus();
  }, [reloadTargets])

  useEffect(() => {
    if (hasTargetAlert) {
      if (switchSelected) {
        if (targetAlert.nivel === "") {
          lightningAlertErrorsAux.level = "Campo Nível é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.level = null;
        }
        if (targetAlert.risco === "") {
          lightningAlertErrorsAux.risk = "Campo Risco é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.risk = null;

        }
        if (targetAlert.contato === "") {
          lightningAlertErrorsAux.contact = "Campo Contato é obrigatório!";
        }
        else {
          if (targetAlert.contato && targetAlert.contato.length < 10) {
            lightningAlertErrorsAux.contact = "Campo Contato deve ter mais que 10 caracteres!";
          }
          else if (targetAlert.contato && targetAlert.contato.length > 50) {
            lightningAlertErrorsAux.contact = "Campo Contato deve ter menos que 50 caracteres!";
          }
          else {
            lightningAlertErrorsAux.contact = null;
          }
        }
        if (targetAlert.duracao === "") {
          lightningAlertErrorsAux.duration = "Campo Duração é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.duration = null;
        }
        if (targetAlert.ferramenta && targetAlert.ferramenta.length === 0) {
          lightningAlertErrorsAux.tool = "Campo Ferramenta é obrigatório!";
        }
        else {
          lightningAlertErrorsAux.tool = null;

        }
        if (targetAlert.observacao && targetAlert.observacao.length > 1 && targetAlert.observacao.length < 50) {
          setCaractersRemaning(50 - targetAlert.observacao.length);
        }
        else {
          setCaractersRemaning("");
        }

        var index = alertsDataAux.map((item) => item.id_alvo).indexOf(target.id_alvo);
        if (index != -1) {
          let aux = alertsDataAux;
          aux.splice(index, 1);
          aux.push(targetAlert);
          setAlertsData(aux);
          let aux2 = lightningAlertErrors;
          aux2.splice(index, 1);
          aux2.push(lightningAlertErrorsAux);
          setLightningAlertErrors(aux2);
        }
        else {
          let aux = alertsDataAux;
          aux.push(targetAlert);
          setAlertsData(aux);
          let aux2 = lightningAlertErrors;
          aux2.push(lightningAlertErrorsAux);
          setLightningAlertErrors(aux2);
        }
      }
      setReloadErrorMessage(true);
    }
  }, [targetAlert?.nivel, targetAlert?.risco, targetAlert?.ferramenta, targetAlert?.duracao, targetAlert?.contato, targetAlert?.observacao, preset.preset, switchSelected])

  useEffect(() => {
    
      if(loadingContacts){
        let aux = loadingRightSide;
        aux.push(0);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
      else{
        let aux = loadingRightSide;
        aux.pop(0);
        setLoadingRightSide(aux);
        setReloadRightSide(true);
      }
    
    /*else{
      let aux = loadingRightSide;
      aux.pop(false);
      setLoadingRightSide(aux);
      setReloadRightSide(true);
    }*/
  }, [loadingContacts])

  if (target && !loadingContacts && loadingTargetData === 1 && hasTargetAlert && !targetIsActive) {
    return (
      <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="center" style={{ marginTop: '15px', width: '49vw' }}>
        <Grid item xs={12} className={classes.titleContainer}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={switchSelected}
              onChange={() => toggleSwitchSelected()}
              value={switchSelected}
              size="small"
              classes={{
                colorPrimary: servicesStatus ? switchStyle.switch_primary : switchStyle.switch_secondary
              }}
              color={"primary"} />

            <div style={{ marginLeft: "15px" }}>
              <Typography variant="subtitle1" style={!servicesStatus ? fonts.targetTitleWarning : fonts.targetTitle}>
                {target.nome}
              </Typography>
            </div>

            {!servicesStatus && <ReportProblem style={{ marginLeft: "8px", width: "20px", height: "20px", color: "red" }} />}
          </div>
        </Grid>

        <Grid container className={classes.gridContainer}>
          <Autocomplete
            id="level"
            freeSolo={false}
            value={targetAlert?.nivel}
            inputValue={targetAlert?.nivel}
            disableClearable
            onChange={(_, newValue) => setTargetAlert({ ...targetAlert, nivel: newValue })}
            className={classes.levelField}
            style={fonts.formsFieldHeight1}
            size="small"
            options={["T1", "T2", "T3", "T4"]}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Nível"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }} 
              />}
          />

          <Autocomplete
            id="risk"
            freeSolo={false}
            value={targetAlert?.risco}
            inputValue={targetAlert?.risco}
            disableClearable
            onChange={(_, newValue) => setTargetAlert({ ...targetAlert, risco: newValue })}
            className={classes.riskField}
            style={fonts.formsFieldHeight1}
            size="small"
            options={["Médio", "Alto", "Muito alto"]}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Risco"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
              />}
          />

          <Autocomplete
            id="tool"
            multiple
            disableClearable
            getOptionLabel={(option) => option}
            value={targetAlert?.ferramenta}
            onChange={(_, newValue) => setTargetAlert({ ...targetAlert, ferramenta: newValue })}
            className={classes.toolsField}
            style={fonts.formsFieldHeight1}
            size="small"
            options={tools}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Ferramenta"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
              />}
          />
        </Grid>

        <Grid container className={classes.gridContainer} style={{marginTop: targetAlert?.ferramenta?.length > 3?  '20px' : '5px'}}>
          <InputMask
            mask="99:99"
            value={targetAlert?.duracao}
            onChange={(e) => setTargetAlert({ ...targetAlert, duracao: e.target.value })}
            disabled={false}
            maskChar=" "
          >{() =>
            <TextField
              id="duration"
              className={classes.durationField}
              margin="normal"
              label="Duração"
              variant="outlined"
              inputProps={{ style: fonts.formsFieldHeight1 }}
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
            />}
          </InputMask>

          <Autocomplete
            id="contacts"
            freeSolo
            value={targetAlert?.contato}
            inputValue={targetAlert?.contato}
            disableClearable
            onInputChange={(_, newValue) => setTargetAlert({ ...targetAlert, contato: newValue })}
            onChange={(_, newValue) => setTargetAlert({ ...targetAlert, contato: newValue })}
            className={classes.contactsField}
            style={fonts.formsFieldHeight1}
            size="small"
            options={contacts.map((option) => option.prioridade != "" ? option.prioridade + " - " + option.nome : option.nome)}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Contato"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
              />}
          />
        </Grid>

        <Grid container className={classes.gridContainer} style={{marginTop: '10px'}}>
          <Autocomplete
              id="comments"
              freeSolo={false}
              value={targetAlert?.observacao}
              inputValue={targetAlert?.observacao}
              disableClearable
              onInputChange={(_, newValue) => setTargetAlert({ ...targetAlert, observacao: newValue })}
              onChange={(_, newValue) => setTargetAlert({ ...targetAlert, observacao: newValue })}
              className={classes.commentsField}
              style={fonts.formsFieldHeight1}
              size="small"
              options={["Com possibilidade de um novo alerta nas próximas horas."]}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Observações"
                  margin="normal"
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace') {
                      event.stopPropagation();
                    }
                  }} 
                />
              }
            />
        </Grid>

        <Grid container className={classes.commentsContainer}>
          {caractersRemaning && <p className={classes.errorMessage}>{caractersRemaning + " caracteres restantes"}</p>}
        </Grid>

        <Grid container className={classes.gridContainer} style={{marginTop: '10px', height: '3px'}}>
          <Divider classes={{ root: switchStyle.divider }} />
        </Grid>
      </Grid>
    )
  }
  /*else if(loadingTargetData === 0){
    return (
      <div style={{justifyContent: "center"}}>
        <CircularProgress style={{ marginLeft: "calc(80%)" }} size={50} thickness={1} color="primary"/>
      </div>
    )
  }*/
  else if(loadingTargetData === 2){
    return (
      <div style={{justifyContent: "center", width: "100%", marginTop: "20px", backgroundColor: "lightblue"}}>
        <span span style={fonts.mapSidebarTargetDescripitionItem}>{`Problema ao carregar informações do alvo ${target.nome}, recarregue a página e tente novamente!`}</span>
      </div>
    )
  }
}


FieldsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  preset: PropTypes.any,
  target: PropTypes.any,
  plano: PropTypes.any,
  alertsData: PropTypes.any,
  setAlertsData: PropTypes.func,
  setReloadSwitchSelected: PropTypes.func.isRequired,
  setReloadModal: PropTypes.string.isRequired,
  allSelected: PropTypes.any,
  setAllSelected: PropTypes.func.isRequired,
  allSelectedAux: PropTypes.any,
  setAllSelectedAux: PropTypes.func.isRequired,
  setReloadErrorMessage: PropTypes.func.isRequired,
  statusTargetSelected: PropTypes.any.isRequired,
  setStatusTargetSelected: PropTypes.func.isRequired,
  setReloadStatusTargetSelected: PropTypes.func.isRequired,
  reloadTargets: PropTypes.any.isRequired,
  setReloadTargets: PropTypes.func.isRequired,
  setLoadingRightSide: PropTypes.func.isRequired,
  setReloadRightSide: PropTypes.func.isRequired,
  loadingRightSide: PropTypes.any.isRequired,
  lightningAlertErrors: PropTypes.func.isRequired,
  setLightningAlertErrors: PropTypes.func.isRequired,
  setServicesLightningAlert: PropTypes.func.isRequired,
};

export default withStyles(styles)(FieldsComponent);