
/* Libraries */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import { reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import Checkbox from "@material-ui/core/Checkbox";
/* Components */
import classNames from 'classnames';
import TextField from "@material-ui/core/TextField";
import LogoNucleo from 'components/LogoNucleo';
import LogoNucleoTexto from 'components/LogoNucleoTexto';
import ArrowLeft from 'components/ArrowLeft';
import { useToast } from 'Context/ToastContext';
import { accessGroupProvider } from "../../redux/actions/accessGroupActions";
/* CSS */
import styles from './login-jss';
import fontStyles from 'styles/fonts-jss';
import './login.css';
/* Utils */
import { useAuth } from 'Context/AuthContext'
import useToggle from 'hooks/useToggle';

const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "white!important",
      color:"white!important",

    },


  },

}));


function Login (props) {
  const Auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isConnected, setIsConnected] = useState(JSON.parse(localStorage.getItem("YYb4ue7pmM")) === null? false : JSON.parse(localStorage.getItem("YYb4ue7pmM")));
  const [stayConnected, toggleStayConnected] = useToggle(JSON.parse(localStorage.getItem("YYb4ue7pmM")) === null? false : JSON.parse(localStorage.getItem("YYb4ue7pmM")));
  const [disableButton, setDisableButton] = useState(false);
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleRecoverPassword = event => {
    history.push({pathname: '/recuperar_senha', state: true});
  };
  
  const handlePreventLogout = async (e) => {
    e.preventDefault()
    setDisableButton(true);
    openToast('warning', 'Verificando credenciais...');
    try{
      const response = await Auth.login(credentials, stayConnected);
      openToast("success", "Login realizado com sucesso!");
      if(response.data.tipo === 'usuario_empresa'){
        history.push('/cliente/monitoramento');
      }
      else{
        history.push('/portais');
      }
    }catch(error){
      openToast("error", "Problema ao realizar login, confira suas credenciais e tente novamente!");
      setDisableButton(false);
    }
  }

  const {
    classes,
    deco,
  } = props;

  useEffect(() => {
    if(isConnected === "true"){
      handlePreventLogout();
    }
    dispatch(accessGroupProvider(null))
  }, [])


  return (
    <Paper id="form_style" className={classNames(classes.sideWrap, deco && classes.petal)}>
      <form  className={classes.center} onSubmit={handlePreventLogout}>
        <section style={{marginBottom:"150px", marginTop:"80px"}}>
          <LogoNucleoTexto height={120} width={400} color='white' fill='white'/>
        </section>


        <section style={{color:"white"}} className={classes.pageFormSideWrap}>
          <TextField
            id="account-email"
            label="Email"
            className={classNames(classes.textFieldDefault, colors.textField, "size_text")}
            value={credentials?.email}
            onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
            margin="normal"
            fullWidth
            style={{size:"50"}}
            placeholder="Email"
            variant="outlined"
            inputProps={{style: fonts.formsField}}
            InputLabelProps={{id:"fontLabel", style: fonts.formsField}} />

          <TextField
            id="account-password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            className={classNames(classes.textFieldDefault, colors.textField, "size_text")}
            value={credentials?.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
            margin="normal"
            variant="outlined"
            style={{color:'white'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{color:"white"}}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ), style: fonts.formsField
            }}
            InputLabelProps={{id:"fontLabel", style: fonts.formsField}} />

            <div className={classes.centerV}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={stayConnected}
                    onChange={toggleStayConnected}
                    value={stayConnected}
                    style={{color:'white'}}
                    color="primary"
                  /> )}
                  label="Manter-me conectado"
                  style={{color:'white', paddingRight: "245px"}}
              />
            </div>

          <div style={{marginTop:"30px"}} className={classes.btnArea}>
            <Button variant="contained"  fullWidth size="large"  style={{backgroundColor:"#FFB703", borderRadius:"5px", width:"444px"}} type='submit' disabled={disableButton}
            // onClick={() => handlePreventLogout()}
            >
              Login
            </Button>
          </div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
            <Typography style={{color:"white"}} variant="subtitle1">
              Esqueceu sua senha ?
            </Typography>
            <div onClick={handleRecoverPassword}><span style={{color:"rgb(255, 183, 3)", cursor: "pointer"}}>&nbsp; Clique aqui</span></div>
          </div>
        </section>
      </form>
        <div style={{paddingTop:"120px", display:"none", paddingRight:"410px"}}>     
        <IconButton><ArrowLeft height={57} width={57} color='white' fill='white'/></IconButton>
        </div>     
    </Paper>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  deco: PropTypes.bool.isRequired,
};

const LoginFormReduxed = reduxForm({
  form: 'loginForm2',
  enableReinitialize: true,
})(Login);

const FormInit = connect(
  state => ({
    force: state,
    initialValues: state.login.usersLogin,
    deco: state.ui.decoration
  }),
)(LoginFormReduxed);

export default withStyles(styles)(FormInit);
