import React, {memo} from 'react'
import P from 'prop-types';
import {Layer, Source} from 'react-map-gl';


const BufferComponent = ({buffers, indexTarget, indexBuffer}) => {

  return (
    <>
      <Source
        key={`buffer-${indexTarget}-${indexBuffer}`}
        id={`buffer-${indexTarget}-${indexBuffer}`}
        type="geojson"
        data={buffers}
        generateId={true}
        >
        <Layer
            id={`buffer-${indexTarget}-${indexBuffer}`}
            source={`buffer-${indexTarget}-${indexBuffer}`}
            type="line"
            layout={{
              visibility: 'visible',
              // visibility: !showTargets
                // ? 'none'
                // : 'visible',
            }}
            paint={{
              'line-color':
                ['get', 'color'],
              'line-width': 1,
            }}
        />
    </Source>
  </>
)}

BufferComponent.propTypes = {
  buffers: P.any.isRequired,
  indexTarget: P.any.isRequired,
  indexBuffer: P.any.isRequired,
}

export const Buffer = memo(BufferComponent);
