/* Libraries */
import React, {useEffect, useState} from 'react'
import P from 'prop-types';
import {Head, Title, BigTab} from '../styled'
import { useDispatch } from 'react-redux';
/* Components */
import ComplexTable from 'containers/Pages/Table';
import { useToast } from 'Context/ToastContext';
import { PapperBlock } from 'components';
import {MdClose} from 'react-icons/md';
import {Row, Column} from 'components/Flex'
import MonitoringTable from './MonitoringTable';
import PrevisionTable from './PrevisionTable';
import RelatoryTable from './RelatoryTable';
/* CSS */
import fontStyles from 'styles/fonts-jss';

/* Material UI */
import { withStyles } from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineTaskAlt, MdOutlineUnpublished, MdWarningAmber } from 'react-icons/md';

/* Services */
import {getHistoryAlerts, getHistoryReleasedAlerts, getHistoryRainWarning} from 'services/mapsPortal/alerts'

import useLoad from 'hooks/useLoad'
import {getLightningAlerts, getLightningReleases} from 'services/mapsPortal/history';

/* Utils */ 
import { persistViewPageNavigation } from 'utils/persistViewPageNavigation';

/* Redux */
import { tabMenuProvider } from 'redux/actions/tabMenuAction';

const HistoryTab = (props) => {
  const [tabsList, setTabsList] = useState();

  useEffect(() => {
        setTabsList([
            {
                label: 'MONITORAMENTO',
                component: <MonitoringTable />,
            },
            {
                label: 'PREVISÃO',
                component: <PrevisionTable />,
            },
            {
                label: 'RELATÓRIOS',
                component: <RelatoryTable  />          
            },
        ]);
    
}, []);


  return (
    <BigTab style={{maxHeight: '100%', overflow: 'auto'}} >
      <Head>
        <h1>MONITORAMENTO</h1>
        <button onClick={props.onClose}><MdClose /></button>
      </Head>
      <Title>HISTÓRICO</Title>
      <PapperBlock  tabsList={tabsList} icon="ion-ios-paper-outline">
          
      </PapperBlock>
    </BigTab>
  )
}

HistoryTab.propTypes = {
  onClose: P.func.isRequired,
}

export default HistoryTab;
