/* Libraries */
import React, { Fragment, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
/* Material UI */
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
/* Components */
import classNames from 'classnames';
import Loading from 'components/Loading';
import { Header, SidebarBig } from 'components';
import Decoration from '../Decoration';
import Modal from 'components/Modal/index';
import { useToast } from 'Context/ToastContext';
import DeleteConfirmationContent from './ModalContent/DeleteConfirmationContent'
import DeleteWarningContent from './ModalContent/DeleteWarningContent'
import TableInfoSummary from './ModalContent/TableInfoSummary'
/* API */
import dataMenuAdmin from 'api/ui/menuAdmin';
import dataMenuWeatherman from 'api/ui/menuWeatherman';
import dataMenuSite from 'api/ui/menuSite';
import dataMenuClient from 'api/ui/menuClient';
/* CSS */
import styles from '../appStyles-jss';
import fontStyles from "styles/fonts-jss";
/* Redux */
import { modalProvider } from 'redux/actions/modalAction';


function LeftSidebarBigLayout(props) {
    const {
        classes,
        children,
        toggleDrawer,
        sidebarOpen,
        loadTransition,
        pageLoaded,
        mode,
        gradient,
        deco,
        history,
        bgPosition,
        changeMode,
        place,
        handleOpenGuide,
    } = props;
    const ref = useRef(null);
    const infosModalRef =  useRef(null);
    const sideBarInfo = JSON.parse(sessionStorage.getItem('cLFfX97S5j'));
    const group = JSON.parse(sessionStorage.getItem('UPKNFbYEHJ'));
    const sitePortal = JSON.parse(sessionStorage.getItem('yghhf45qd1'));
    const openModal = useSelector((state) => state.modalProvider);
    const fonts = fontStyles();
    const dispatch = useDispatch();
    const openToast = useToast();
    const [openModalError, setOpenModalError] = useState(false);
    const [openModalErrorData, setOpenModalErrorData] = useState([]);

    const onCloseModalError = () => {
        dispatch(modalProvider(false, null, null, null, null, null));
        setOpenModalError(false);
    }

    const onCloseModal = () => {
        dispatch(modalProvider(false, null, null, null, null, null));
    }

    
    return (
        <Fragment>
            {openModal[0] && openModal[4] === "Delete" &&
                <Modal height={ref? ref.current?.getBoundingClientRect().height : 857} onClose={onCloseModal}>
                    <DeleteConfirmationContent deleteData={openModal} setOpenModalError={setOpenModalError} setOpenModalErrorData={setOpenModalErrorData}/>
                </Modal>
            }
            
            {openModalError &&
                <Modal height={ref? ref.current?.getBoundingClientRect().height : 857} onClose={onCloseModalError}>
                    <DeleteWarningContent deleteData={openModal} errorData={openModalErrorData} setOpenModalError={setOpenModalError}/>
                </Modal>
            }

            {openModal[0] && openModal[4] === "Infos" &&
                <Modal height={ref? ref.current?.getBoundingClientRect().height : 857} onClose={onCloseModal}>
                    <TableInfoSummary summaryData={openModal} />
                </Modal>
            }
            
            <Header
                toggleDrawerOpen={toggleDrawer}
                margin={sidebarOpen}
                gradient={gradient}
                position="left-sidebar-big"
                changeMode={changeMode}
                mode={mode}
                title={place}
                history={history}
                openGuide={handleOpenGuide}
            />
            {sitePortal === 1 && (group === 0 || group === 1 ) ? (
                <SidebarBig
                    dataMenu={dataMenuSite}
                    loadTransition={loadTransition}
                    open={sidebarOpen}
                    toggleDrawerOpen={toggleDrawer}
                />
            ) : group === 0 ? (
                <SidebarBig
                    dataMenu={dataMenuAdmin}
                    loadTransition={loadTransition}
                    open={sidebarOpen}
                    toggleDrawerOpen={toggleDrawer}
                />
            ) : group === 1 ? (
                <SidebarBig
                    dataMenu={dataMenuWeatherman}
                    loadTransition={loadTransition}
                    open={sidebarOpen}
                    toggleDrawerOpen={toggleDrawer}
                />
            ) : 
            (
                <SidebarBig
                    dataMenu={dataMenuClient}
                    loadTransition={loadTransition}
                    open={sidebarOpen}
                    toggleDrawerOpen={toggleDrawer}
                />
            )}
            <main
                className={classNames(
                    classes.content,
                    !sidebarOpen ? classes.contentPaddingLeftBig : '',
                )}
                id="mainContent"
            >
                <Decoration
                    mode={mode}
                    gradient={gradient}
                    decoration={deco}
                    bgPosition={bgPosition}
                    horizontalMenu={false}
                />
                <section
                    className={classNames(
                        classes.mainWrap,
                        classes.sidebarLayout,
                    )}
                >
                    {/* {titleException.indexOf(history.location.pathname) < 0 && (
            <div className={classes.pageTitle}>
              <Typography component="h4" className={bgPosition === 'header' ? classes.darkTitle : classes.lightTitle} variant="h4">{place}</Typography>
              <BreadCrumb separator=" / " theme={bgPosition === 'header' ? 'dark' : 'light'} location={history.location} />
            </div>
          )} */}
                    {!pageLoaded && <Loading />}
                    <Fade
                        in={pageLoaded}
                        {...(pageLoaded ? { timeout: 700 } : {})}
                    >
                        <div className={!pageLoaded ? classes.hideApp : ''} ref={ref}>
                            {/* Application content will load here */}
                            {children}
                        </div>
                    </Fade>
                </section>
            </main>
        </Fragment>
    );
}

LeftSidebarBigLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    loadTransition: PropTypes.func.isRequired,
    changeMode: PropTypes.func.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    pageLoaded: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    gradient: PropTypes.bool.isRequired,
    deco: PropTypes.bool.isRequired,
    bgPosition: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    titleException: PropTypes.array.isRequired,
    handleOpenGuide: PropTypes.func.isRequired,
};

export default withStyles(styles)(LeftSidebarBigLayout);
