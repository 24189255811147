import { djangoApi } from '../api';

export const getNews = async () => {
    try {
        const response = await djangoApi.get('noticias/');
        return response.data;
    } catch (error) {
        console.error('Error fetching news:', error);
        return [];
    }
};

export const getNew = async (id) => {
    try {
        const response = await djangoApi.get(`noticias/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching new:', error);
        return [];
    }
};

export const postNew = (newData) => {
    return djangoApi.post(`noticias/`, newData);
};

export const updateNew = (id_new, newData) => {
    return djangoApi.put(`noticias/${id_new}/`, newData);
};

export const deleteNew = async (id_new) => {
    try {
        const response = await djangoApi.delete(`noticias/${id_new}/`);
        return response.data;
    } catch (error) {
        return error.response;
    }
};
