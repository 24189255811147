module.exports = [
  {
    key: 'homeNoContext',
    name: 'Início',
    icon: 'home',
    child: []
  },
  {
    key: 'companiesNoContext',
    name: 'Empresa',
    icon: 'domain',
    child: [
      {
        key: 'companies_pageNoContext',
        name: 'Empresa',
        title: true,
      },
      {
        key: 'companies_view_allNoContext',
        name: 'Ver todas',
        link: '/administracao',
        icon: 'domain',
      },
    ]
  },
  {
    key: 'overtimeContext',
    name: 'Horas Extras',
    icon: 'sell',
    child: [
      {
        key: 'overtime_pageContext',
        name: 'HORAS EXTRAS',
        title: true,
      },
      {
        key: 'overtime_plusHourContext',
        name: 'Cadastrar',
        link: '/administracao/registro/horaextra',
        icon: 'more_time',
      },
    ]
  },
];
