/* Libraries */
import React, {useEffect, useState} from 'react'
import P from 'prop-types';
import {Head, Title, BigTab} from '../styled'
/* Components */
import { PapperBlock } from 'components';
import {MdClose} from 'react-icons/md';
import NotApprovedTable from './NotApprovedTable';
import ApprovedTable from './ApprovedTable';
import { openMapSidebarProvider } from 'redux/actions/openMapSidebar';
import { useDispatch } from 'react-redux';

const ReportCard = (props) => {
  const onClose = props
  const [tabsList, setTabsList] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
        setTabsList([
            {
                label: 'Não aprovados',
                component: <NotApprovedTable />,
            },
            {
              label: 'Aprovados',
              component: <ApprovedTable />,
          },
        ]);
    
}, []);

const handleClose = () => {
  dispatch(openMapSidebarProvider(false));
  props.onClose();
}


  return (
    <BigTab style={{maxHeight: '100%', overflow: 'auto'}} >
      <Head>
        <h1>BOLETINS</h1>
        <button onClick={() => handleClose()}><MdClose /></button>
      </Head>
      <Title>AGENDAMENTO DE BOLETINS</Title>
        <PapperBlock  tabsList={tabsList} icon="ion-ios-paper-outline">
          
        </PapperBlock>
    </BigTab>
  )
}

ReportCard.propTypes = {
  onClose: P.func.isRequired,
}

export default ReportCard;
