const fontFamily = [
    'Philosopher',
    'Roboto',
    'sans-serif'
]


const styles = theme => ({

button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    gap: "5px",
    backgroundColor: "#1C1B1F",
    borderRadius: "5px",
    marginRight: "8%"
},

usersButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    backgroundColor: "#C0A514",
    borderRadius: "5px",
    color: "white",
    marginRight: "8%"
},

emailButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 11px",
    backgroundColor: "#C0A514",
    borderRadius: "5px",
    color: "white",
},

emailDiv: {
    width: '60%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
},

usersDiv: {
    display: "flex",
    flexDirection: "column",
},

emailInput: {
    width: '70%'
},

gridButton: {
    ['@media (min-width:768px)']: { 
        marginLeft: '9.3%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '13%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '33%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '32.3%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '34.5%',
    },
    ['@media (min-width:1536px)']: { 
        marginLeft: '35.5%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '32.8%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '34.6%',
    },
},

gridContainer: {
    ['@media (min-width:768px)']: { 
        marginLeft: '5.2%',
    },
    ['@media (min-width:1024px)']: { 
        marginLeft: '9.7%',
    },
    ['@media (min-width:1280px)']: { 
        marginLeft: '4.8%',
    },
    ['@media (min-width:1366px)']: { 
        marginLeft: '6.4%',
    },
    ['@media (min-width:1440px)']: { 
        marginLeft: '6.3%',
    },
    ['@media (min-width:1600px)']: { 
        marginLeft: '9%',
    },
    ['@media (min-width:1920px)']: { 
        marginLeft: '11%',
    },
},

iconContainer: {
    marginLeft: '8px',
    width: "22px",
    height: "22px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
},
});

export default styles;