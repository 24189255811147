/* eslint-disable react/no-unknown-property */
import React from 'react'
import PropTypes from 'prop-types';
const LogoNucleo = (props) => {
  const width = props.width ? props.width : 36;
  const height = props.height ? props.height : 36;
  const color = props.color ? props.color : 'black';
  const fill = props.fill ? props.fill : '#EBEBEB'
  const margin = props.margin ? props.margin : 'none'
  return (
    <svg width={width} height={height} padding={margin} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  padding={margin} d="M11.8367 2.47783L1 14.9901L11.1195 6.51724L8.88845 11.3448L13.6693 9.47291L8.88845 21L21 3.56158L13.1116 7.69951L17.1753 1L11.8367 2.47783Z" fill={fill} stroke={color}  strokeOpacity="0.11"/>
    
    </svg>
  )
}

LogoNucleo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  fill: PropTypes.string,
  margin: PropTypes.string,
};

export default LogoNucleo
