/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";
/* Material UI */
import { withStyles } from '@material-ui/core/styles';
/* CSS */
import fontStyles from 'styles/fonts-jss';
import styles from "./tableStyle-jss";
/* Images */
import { SiMicrosoftexcel } from 'react-icons/si';


const ExportExcelFile = (props) => {
    const { classes, dataSet, type, csvButtonName, otherCsvButtonName, otherDataSet} = props;
    const fonts = fontStyles();
    const companyName = JSON.parse(sessionStorage.getItem("cLFfX97S5j"));

    return (
        <>  
            <CSVLink 
                filename={companyName && companyName[1] && companyName[1] != null? otherDataSet? companyName[1] + " - " + "Consolidação" : companyName[1] + " - " + type : type}
                data={otherDataSet? otherDataSet : dataSet}
                separator={";"}
                style={fonts.exportText}
            >
                <div className={type === "Relatorio de alertas"? classes.showChartButton : classes.exportContainer}>
                    <SiMicrosoftexcel className={type === "Relatorio de alertas"? classes.exportIcon1 : classes.exportIcon} /><span className={type === "Relatorio de alertas"? classes.csvButtonText1 : classes.csvButtonText} style={fonts.buttonText}>{otherCsvButtonName? otherCsvButtonName : csvButtonName}</span>
                </div>
            </CSVLink>
        </>
    );
};


ExportExcelFile.propTypes = {
    classes: PropTypes.object.isRequired,
    dataSet: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
    csvButtonName: PropTypes.string,
    otherCsvButtonName: PropTypes.string,
    otherDataSet: PropTypes.arrayOf(PropTypes.object)
};


export default withStyles(styles)(ExportExcelFile);