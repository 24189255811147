import React from 'react';
import { PropTypes } from 'prop-types';
import { Router, Switch, Route } from 'react-router-dom';
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';
import SiteRoutes from './SiteRoutes';
import WeathermanRoutes from './WeathermanRoutes';
import ThemeWrapper from './ThemeWrapper';
import MonitoringPage from 'pages/MapsPortal';
import ClientMonitoringPage from 'pages/ClientPortal';
import DischargesHistory from 'pages/DischargesHistory';
import LoginDedicated from '../pages/Login/LoginDedicated';
import { PortalsDashboard, NotFound, RegisterPassword } from './pageListAsync';
import { useAuth } from 'Context/AuthContext';
import PrivateRoute from './PrivateRoutes';

function App(props) {
    const { history } = props;
    const { userGroup: group } = useAuth();

    return (
        <ThemeWrapper>
            <Router history={history}>
                <Switch>
                    <PrivateRoute
                        enable={[-1]}
                        path="/"
                        exact
                        component={LoginDedicated}
                    />
                    <PrivateRoute
                        enable={[-1]}
                        path="/recuperar_senha"
                        exact
                        component={RegisterPassword}
                    />
                    <PrivateRoute
                        enable={[0]}
                        path="/administracao"
                        component={AdminRoutes}
                    />
                       <PrivateRoute
                        enable={[2]}
                        path="/cliente/monitoramento"
                        exact
                        component={ClientMonitoringPage}
                    />
                    <PrivateRoute
                        enable={[2]}
                        path="/cliente"
                        component={ClientRoutes}
                    />
                    {/* {group === 0 && <PrivateRoute enable={[0]} path = "/administracao" component = { AdminRoutes } /> } */}
                    {/* {group === 1 && <PrivateRoute enable={[1]} path = "/administracao" component = { WeathermanRoutes } /> } */}
                    <PrivateRoute
                        history={history}
                        enable={[0, 1, 2]}
                        path="/portais"
                        component={PortalsDashboard}
                    />
                    <PrivateRoute
                        enable={[0, 1]}
                        path="/monitoramento"
                        exact
                        component={MonitoringPage}
                    />
                    <PrivateRoute
                        enable={[0, 1]}
                        path="/historico_de_descargas"
                        exact
                        component={DischargesHistory}
                    />
                    <PrivateRoute
                        enable={[0, 1]}
                        path="/site"
                        component={SiteRoutes}
                    />
                    <Route component={NotFound} />
                    <Route component={RegisterPassword} />
                </Switch>
            </Router>
        </ThemeWrapper>
    );
}

App.propTypes = {
    history: PropTypes.object.isRequired,
    group: PropTypes.number.isRequired,
};

export default App;
