const styles = theme => ({

/* General */
outlineButtonText: {
    color: theme.palette.text.white,
    textTransform: "uppercase",
},

outlineButtonIcon: {
    color: theme.palette.menu.white,
},

outlineButton:{
    cursor: "pointer",
    border: "2px solid",
    borderColor: theme.palette.menu.white,
    borderRadius: "5px",
    width: "40px",
    padding: "2px 8px 2px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& $outlineButtonText': {
      color: theme.palette.menu.white,
    },
    '& $outlineButtonIcon': {
      color: theme.palette.menu.white,
    },
    '&:hover': {
      background: theme.palette.menu.white,
      '& $outlineButtonText': {
        color: "black",
      },
      '& $outlineButtonIcon': {
        color: "black",
      },
    },
  },
});

export default styles;