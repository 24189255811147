/* Libraries */
import React from 'react';
import axios from 'axios';
/* CSS */
import fontStyles from 'styles/fonts-jss';
import { withStyles } from '@material-ui/core/styles';
/* Material UI */
import { Button, Icon, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';

import PropTypes from 'prop-types';

import { getCompanies } from 'services/adminDashboard/company';
import { getPlanByCompanyID } from 'services/adminDashboard/plan';
import { getTargetByID } from 'services/adminDashboard/target';
import { getPlan_TargetByPlanID } from 'services/adminDashboard/planTarget';

import { useToast } from 'Context/ToastContext';
import { useEffect } from 'react';
import Loading from 'components/Loading';

import styles from './register-jss';
import ComposedChartComponent from 'components/Charts/ComposedChartComponent';
import deLocale from 'date-fns/locale/pt-BR';

import SimpleLineChartComponent from 'components/Charts/SimpleLineChartComponent';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getMeteograms } from 'services/mapsPortal/meteogram';

import html2canvas from 'html2canvas';
import BarChartComponent from 'components/Charts/BarChartComponent';
import moment from 'moment';

import nucleologo from '../../../../../images/nucleologo.svg';

const Meteogram = (props) => {
    const { classes } = props;
    const fonts = fontStyles();

    const [company, setCompany] = useState();
    const [plan, setPlan] = useState();
    const [target, setTarget] = useState(null);

    const [companyLogo, setCompanyLogo] = useState();

    const [companyList, setCompanyList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [targetList, setTargetList] = useState([]);

    const [companyError, setCompanyError] = useState();
    const [planError, setPlanError] = useState();
    const [targetError, setTargetError] = useState();

    const [loadingCompany, setLoadingCompany] = useState(true);
    const [loadingPlansList, setLoadingPlansList] = useState(true);
    const [loadingTargetsList, setLoadingTargetsList] = useState(true);

    const [showChart, setShowChart] = useState(false);

    const [initialDate, setInitialDate] = useState(moment());
    const [initialDateErrors, setInitialDateErrors] = useState();
    const [finalDate, setFinalDate] = useState();
    const [finalDateErrors, setFinalDateErrors] = useState();

    const [dataInput, setDataInput] = useState();

    const [accumulated, setAccumulated] = useState();
    const [temperature, setTemperature] = useState();
    const [moisture, setMoisture] = useState();
    const [pressure, setPressure] = useState();
    const [wind, setWind] = useState();

    const [foundtargetData, setFoundTargetData] = useState();

    const openToast = useToast();

    //Pegando os dados das Empresas
    const loadCompany = async () => {
        let aux = [];

        await getCompanies()
            .then((response) => {
                setPlanList([]);
                setTargetList([]);
                setPlan(null);
                setTarget(null);
                if (response) {
                    aux = response;
                }
                if (
                    response.nome != '' &&
                    response.funcionamento &&
                    response.funcionamento.length > 0
                ) {
                    aux.push({ nome: response.nome, id_empresa: '' });
                }

                setLoadingCompany(false);
                setCompanyList(aux);
            })
            .catch((error) => {
                openToast(
                    'error',
                    'Problema ao carregar empresas, recarregue a página e tente novamente!',
                );
                setLoadingCompany(false);
            });
    };

    const loadPlans = async () => {
        setPlanList([]);
        setPlan(null);
        await getPlanByCompanyID(
            companyList.filter((data) => data.nome === company)[0].id_empresa,
        )
            .then((response) => {
                setTargetList([]);
                setTarget(null);
                if (response) {
                    if (response.length > 0) {
                        let aux1 = [];
                        response.map((plano) => aux1.push(plano));
                        setPlanList(aux1);
                        setLoadingPlansList(false);
                    } else {
                        setPlanList(response);
                        setLoadingPlansList(false);
                    }
                }
            })
            .catch((error) => {
                openToast(
                    'error',
                    'Problema ao carregar planos, recarregue a página e tente novamente!',
                );
                setLoadingPlansList(false);
            });
    };

    const loadPlansTargets = async () => {
        await getPlan_TargetByPlanID(
            planList.filter((data) => data.nome === plan)[0].id_plano,
        )
            .then((response) => {
                if (response) {
                    loadTargets(response);
                }
            })
            .catch((error) => {
                openToast(
                    'error',
                    'Problema ao carregar alvos, recarregue a página e tente novamente!',
                );
                setLoadingPlansList(false);
            });
    };

    const loadTargets = async (targetsId) => {
        let aux = [];
        let error = false;

        for (let i = 0; i < targetsId.length; i++) {
            await getTargetByID(targetsId[i].id_alvo)
                .then((response) => {
                    if (response) {
                        aux.push({
                            id_alvo: response.id_alvo,
                            nome: response.nome,
                            medida: response.unidade_medida,
                        });
                        setTargetList(aux);
                    }
                })
                .catch((error) => {
                    openToast(
                        'error',
                        'Problema ao carregar alvos, recarregue a página e tente novamente!',
                    );
                    error = true;
                    setLoadingTargetsList(false);
                });
        }
        if (!error) {
            if (targetList.length > 0) {
                var aux1 = [];
                targetList.map((alvo) =>
                    alvo.nome_plano === plan
                        ? aux1.push({ nome: alvo.nome, id_alvo: null })
                        : '',
                );
                setTargetList(aux1);
                setLoadingTargetsList(false);
            } else {
                setTargetList(aux);
                setLoadingTargetsList(false);
            }
        }
    };

    useEffect(() => {
        if (
            plan &&
            planList.filter((data) => data.nome === plan)[0]?.id_plano !=
                undefined
        ) {
            loadPlansTargets();
            setLoadingTargetsList(true);
        } else if (
            plan &&
            planList.filter((data) => data.nome === plan)[0]?.id_plano ===
                undefined
        ) {
            setTargetList(
                targetList.filter((data) => data.nome_plano === plan),
            );
            setLoadingTargetsList(false);
        } else if (!plan) {
            setTarget(null);
        }
    }, [plan]);

    useEffect(() => {
        if (!plan) {
            setPlanError('Campo obrigatório!');
        } else {
            setPlanError('');
        }

        if (!target) {
            setTargetError('Campo obrigatório!');
        } else {
            setTargetError('');
        }

        if (!company) {
            setCompanyError('Campo obrigatório!');
        } else {
            setCompanyError('');
        }
    }, [plan, company, target]);

    useEffect(() => {
        if (
            company &&
            companyList.filter((data) => data.nome === company)[0]
                ?.id_empresa != ''
        ) {
            loadPlans();
            setLoadingPlansList(true);
        } else if (
            company &&
            companyList.filter((data) => data.nome === company)[0]
                ?.id_empresa === ''
        ) {
            setPlanList(
                planList.filter((data) => data.nome_empresa === company),
            );
            setLoadingPlansList(false);
        } else if (!company) {
            setPlan(null);
            setTarget(null);
        }
    }, [company]);

    useEffect(() => {
        if (loadingCompany) {
            loadCompany();
        }
    }, []);

    function sortData(a, b) {
        if (a.nome < b.nome) {
            return -1;
        }
        if (a.nome > b.nome) {
            return 1;
        }
        return 0;
    }

    const foundTarget = targetList.find((item) => item.nome === target);
    const foundCompany = companyList.find((item) => item.nome === company);

    const currentDate = moment();
    const currentHour = currentDate.hours();

    useEffect(() => {
        if (initialDate.isAfter(currentDate)) {
            setInitialDate(currentDate);
            setFinalDate(null);
            return;
        }

        let dateToUse = moment(initialDate);

        if (dateToUse.isSame(currentDate, 'day') && currentHour < 20) {
            dateToUse.subtract(1, 'day');
        }

        const formattedDate = dateToUse.format('YYYYMMDD');
        setFinalDate(formattedDate);
    }, [initialDate]);

    useEffect(() => {
        if (foundTarget && finalDate) {
            setDataInput({
                id_alvo: foundTarget.id_alvo,
                data: finalDate,
            });
        }
    }, [finalDate, foundTarget]);

    useEffect(() => {
        if (company) {
            setCompanyLogo(foundCompany.caminho_imagem);
        }
    }, [company]);

    const handleShowChart = () => {
        setShowChart(true);
        setFoundTargetData(
            foundTarget.medida !== null ? foundTarget.medida : 1,
        );
        const fetchData = async () => {
            if (dataInput) {
                try {
                    const response = await getMeteograms(
                        dataInput.id_alvo,
                        dataInput.data,
                    );
                    
                    if (response) {
                        setPressure(response[0].meteograma_alvo.pressao_ar);
                        setAccumulated(response[0].meteograma_alvo.chuvas);
                        setTemperature(response[0].meteograma_alvo.temperatura);
                        setMoisture(response[0].meteograma_alvo.umidade);
                        setWind(response[0].meteograma_alvo.vento);
                    }
                } catch (error) {
                    console.error('Error fetching meteogram data:', error);
                }
            }
        };

        fetchData();
    };

    const clearCache = async () => {
        await axios.get(companyLogo).then((error) => {
            console.log(error)
            exportChartsAsImage();
        }).catch((error) => {
            console.log(error)
            exportChartsAsImage();
        });
    }

    const exportChartsAsImage = async () => {
        console.log("dca")
        const chartContainer = document.getElementById('chart-container');
        const exportedFigure = document.getElementById('exported-figure');
        const companyName = document.getElementById('companyName');

        exportedFigure.style.display = 'flex';
        companyName.style.display = 'flex';

        const scale = 2;
        const dpi = 300;
        
        if (chartContainer) {
            try {
                const canvas = await html2canvas(chartContainer, {
                    scale,
                    dpi,
                    useCORS: true,
                })

                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = `${company} ${plan} ${target} - ${moment(initialDate).format('DD/MM/YYYY')} `;
                link.click();
            } catch (error) {
                openToast(
                    'error',
                    'Problema ao exportar meteograma! Recarregue a página e tente novamente.',
                );
            }
        }

        exportedFigure.style.display = 'none';
        companyName.style.display = 'none';
    };

    if (loadingCompany) {
        return <Loading />;
    } else {
        return (
            <div>
                <div className={classes.registerContainer}>
                    <Typography
                        variant="subtitle1"
                        color="primary"
                        style={fonts.formsSubTitle}
                    >
                        Selecionar Alvo
                    </Typography>

                    <div className={classes.registerContentContainer}>
                        <div className={classes.dataContainer}>
                            {!loadingCompany && (
                                <div className={classes.registerItemContainer}>
                                    <Autocomplete
                                        id="company"
                                        value={company}
                                        disableClearable
                                        onChange={(_, newValue) =>
                                            setCompany(newValue)
                                        }
                                        className={
                                            classes.selectFieldExtraLarge
                                        }
                                        style={fonts.formsFieldHeight5}
                                        size="small"
                                        options={companyList
                                            .sort(sortData)
                                            .map((option) => option.nome)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecionar empresa"
                                                margin="normal"
                                                style={{ marginBottom: '0px' }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    {companyError && (
                                        <p style={fonts.formsErrorMessage}>
                                            {companyError}
                                        </p>
                                    )}
                                </div>
                            )}

                            {company && loadingPlansList ? (
                                <CircularProgress
                                    size={50}
                                    thickness={1}
                                    color="primary"
                                />
                            ) : (
                                company && (
                                    <div
                                        className={
                                            classes.registerItemContainer
                                        }
                                    >
                                        <Autocomplete
                                            id="plan"
                                            value={plan}
                                            disableClearable
                                            onChange={(_, newValue) =>
                                                setPlan(newValue)
                                            }
                                            className={
                                                classes.selectFieldExtraLarge
                                            }
                                            style={fonts.formsFieldHeight5}
                                            size="small"
                                            options={planList
                                                .sort(sortData)
                                                .map((option) => option.nome)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Selecionar plano"
                                                    margin="normal"
                                                    style={{
                                                        marginBottom: '-5px',
                                                    }}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />

                                        {planError && (
                                            <p style={fonts.formsErrorMessage}>
                                                {planError}
                                            </p>
                                        )}
                                    </div>
                                )
                            )}

                            {plan && loadingTargetsList ? (
                                <CircularProgress
                                    size={50}
                                    thickness={1}
                                    color="primary"
                                />
                            ) : plan ? (
                                <div className={classes.registerItemContainer}>
                                    <Autocomplete
                                        id="target"
                                        value={target}
                                        disableClearable
                                        onChange={(_, newValue) =>
                                            setTarget(newValue)
                                        }
                                        className={
                                            classes.selectFieldExtraLarge
                                        }
                                        style={fonts.formsFieldHeight5}
                                        size="small"
                                        options={targetList
                                            .sort(sortData)
                                            .map((option) => option.nome)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Selecionar alvo"
                                                margin="normal"
                                                style={{
                                                    marginBottom: '-5px',
                                                }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    {targetError && (
                                        <p style={fonts.formsErrorMessage}>
                                            {targetError}
                                        </p>
                                    )}
                                </div>
                            ) : (
                                ''
                            )}

                            {plan && loadingTargetsList ? (
                                <CircularProgress
                                    size={50}
                                    thickness={1}
                                    color="primary"
                                />
                            ) : plan ? (
                                <div className={classes.planContainer}>
                                    <div
                                        className={
                                            classes.registerItemContainer
                                        }
                                    >
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                            locale={deLocale}
                                        >
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                value={initialDate}
                                                style={{ marginTop: '0px' }}
                                                onChange={(date) =>
                                                    setInitialDate(moment(date))
                                                }
                                                animateYearScrolling={false}
                                                label="Data"
                                                className={classes.dateField}
                                                inputProps={{
                                                    style: fonts.formsField,
                                                }}
                                                InputLabelProps={{
                                                    style: fonts.formsLabel,
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {initialDateErrors && (
                                            <p style={fonts.formsErrorMessage}>
                                                {initialDateErrors}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className={classes.showChartButtonContainer}>
                            <Button
                                className={classes.showChartButton}
                                onClick={handleShowChart}
                                disabled={!target || loadingTargetsList}
                            >
                                Gerar Gráfico
                            </Button>

                            <Button
                                className={classes.showChartButton}
                                onClick={clearCache}
                                disabled={!target || loadingTargetsList}
                                endIcon={<Icon>download</Icon>}
                            >
                                Exportar
                            </Button>
                        </div>
                    </div>
                </div>
                {showChart && (
                    <div id="chart-container">
                        <div id="exported-figure" style={{ display: 'none' }}>
                            <div className={classes.imageLogo}>
                                <figure>
                                    <img
                                        src={nucleologo}
                                        alt="Núcleo Monitoramento"
                                        style={{ width: '400px' }}
                                    />
                                </figure>
                                
                                <figure>
                                    <img
                                        src={companyLogo + "?not-from-cache-please"}
                                        alt={'Logo' + company}
                                        style={{ width: '400px' }}
                                    />
                                </figure>
                            </div>
                        </div>

                        <div id="companyName" style={{ display: 'none', justifyContent: "center", alignItems: "center", fontSize: "25px", fontWeight: "bold"}}>
                            <span>{company + " - " + plan + " - " + target + " - " + moment(initialDate).format('DD/MM/YYYY')}</span>
                        </div>

                        <ComposedChartComponent chartData={accumulated} />
                        <SimpleLineChartComponent
                            chartData={temperature}
                            title={'TEMPERATURA (°C)'}
                            legend={'Temperatura (°C)'}
                            color={'#FF0000'}
                            chartType={'temperature'}
                            dataKey={'C'}
                        />
                        <SimpleLineChartComponent
                            chartData={moisture}
                            title={'UMIDADE RELATIVA (%)'}
                            legend={'Umidade Relativa (%)'}
                            color={'#0509BC'}
                            chartType={'moisture'}
                            dataKey={'%'}
                        />
                        <SimpleLineChartComponent
                            chartData={pressure}
                            title={'PRESSÃO ATMOSFÉRICA (hPa)'}
                            legend={'Pressão Atmosférica (hPa)'}
                            color={'#020202'}
                            chartType={'pressure'}
                            dataKey={'hPa'}
                        />
                        <BarChartComponent
                            chartData={wind}
                            mesuare={foundtargetData}
                            type="médio"
                        />
                        <BarChartComponent
                            chartData={wind}
                            mesuare={foundtargetData}
                            type="máximo"
                        />
                    </div>
                )}
            </div>
        );
    }
};

Meteogram.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Meteogram);
