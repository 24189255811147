import React, { useState, memo, useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';
import { union } from '@turf/turf';
import {useTarget} from 'Context/TargetContext';
import { useSelector } from 'react-redux';


const IntersectionLayerComponent = () => {
  const { planos, toShow, mergeTargetsOption, showTargets, setIntersectionLayers} = useTarget();
  const buffersState = useSelector(state => state.mapBuffersStateProvider);
  const [processedTargets, setProcessedTargets] = useState(null);
  const [layerKey, setLayerKey] = useState(Date.now());
  const [targetLayers, setTargetLayers] = useState(null);

  const mergeTargets = (feature, location2) => {
    const a = {...feature}
    const x = union(a, location2)
    x.properties = {
      nome: `${feature.properties.nome}/${location2.properties.nome}`,
      buffer: feature.properties.buffer,
      targetId: feature.properties.targetId,
      planId: feature.properties.planId,
    }
    return x
  }

  const processTargets = () => {
    let result = [];
    let targets = [];

    planos.forEach((company) => {
      const targetsFeatures = [];
      let mergedTargets = []
      let i = 0;
      let j
      let merged = {};
      let actualMerge;
      let mergingIndexes = {};

      company.planos.forEach(plan => {
        plan.alvos?.forEach((target) => {
          if(toShow.get(company.id, plan.id, target.id)){
            targetsFeatures.push(target);
            targets.push(target.coordenadas.features.filter((feature) => feature?.id)[0]);
          }
        })
      })

      for(i; i < targetsFeatures.length; i++){
        if (merged[i]) continue
        j = i + 1
        mergingIndexes = {}
        actualMerge = targetsFeatures[i].coordenadas.features
        for(j; j < targetsFeatures.length; j++){
          if (merged[j]) continue
          actualMerge = actualMerge.map((layer, index) => {
            if (index === 0 || !mergeTargetsOption) return layer;
            const x = mergeTargets(layer, targetsFeatures[j].coordenadas.features[index])
            if(x){
              mergingIndexes[i] = true
              mergingIndexes[j] = true
              return x
            }
            return layer
          })
        }
        mergedTargets.push(actualMerge)

        Object.keys(mergingIndexes).forEach(a =>
          merged[a] = true
          )
        }

        result = result.concat(
          mergedTargets.reduce((sum, target, key) => {
            return [...sum, ...target]
          }, [])
        )

     })

    setTargetLayers({type: 'FeatureCollection', features: targets})
    return { type: 'FeatureCollection', features: result }
  }

  useEffect(() => { 
    if(mergeTargetsOption){
      setProcessedTargets(processTargets());
      setIntersectionLayers(processTargets());
    }
    else{
      setProcessedTargets(null);
      setIntersectionLayers(null);
    }
  }, [mergeTargetsOption])

  if (planos === undefined || !planos || planos.length === 0) return <></>;
  return (
    <>
      <Source
        key={`nonInteractiveLayer`}
        id={`nonInteractiveLayer`}
        type="geojson"
        data={processedTargets}
        generateId={true}
        >
        <Layer
            id={`nonInteractiveLayer`}
            source={`nonInteractiveLayer`}
            type="line"
            layout={{
                visibility: !showTargets
                  ? 'none'
                  : 'visible',
            }}
            paint={{
              'line-color': [
                'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#FF0',
                  !buffersState?
                    [

                        'match',
                        ['get', 'buffer'],
                        '0',
                        '#808080',
                        '1',
                        '#223b53',
                        '4',
                        '#FB1C29',
                        '10',
                        '#FDA731',
                        '20',
                        '#118014',
                        'rgba(255, 0, 0, 0)',
                    //] 
                    ] :
                    [
                        'match',
                        ['get', 'buffer'],
                        '0',
                        '#808080',
                        '1',
                        '#223b53',
                        '4',
                        '#FB1C29',
                        '10',
                        '#FDA731',
                        '20',
                        '#118014',
                        ['get', 'color'],
                    ] 
              ],
              'line-width': 1,
          }}
        />
      </Source>
      
      {targetLayers?
        <Source
        key={`mergedTargets-${layerKey}`}
        id={`mergedTargets`}
        type="geojson"
        data={targetLayers}
        generateId={true}
        >
        <Layer
            id={`mergedTargets-lines`}
            source={`mergedTargets`}
            type="line"
            layout={{
                visibility: !showTargets
                  ? 'none'
                  : 'visible',
            }}
            filter={["in", "$type", "LineString", "Polygon"]}
            paint={{
              'line-color': [
                'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#FF0',
                  !buffersState?
                    [

                        'match',
                        ['get', 'buffer'],
                        '0',
                        '#808080',
                        '1',
                        '#223b53',
                        '4',
                        '#FB1C29',
                        '10',
                        '#FDA731',
                        '20',
                        '#118014',
                        'rgba(255, 0, 0, 0)',
                    //] 
                    ] :
                    [
                        'match',
                        ['get', 'buffer'],
                        '0',
                        '#808080',
                        '1',
                        '#223b53',
                        '4',
                        '#FB1C29',
                        '10',
                        '#FDA731',
                        '20',
                        '#118014',
                        ['get', 'color'],
                    ] 
              ],
              'line-width': 1,
          }}
        />
                <Layer
                    id={`mergedTargets-circles`}
                    source={`mergedTargets`}
                    type="circle"
                    layout={{
                      visibility: !showTargets
                        ? 'none'
                        : 'visible',
                    }}
                    filter={["==", "$type", "Point"]}
                    paint={{
                      'circle-color': '#808080',
                      'circle-radius': {
                        stops: [[5,2],[12,10], [16,30]]
                      },
                    }}
                  />
      </Source> :
        null
      }
    </>
  )
}

export const IntersectionLayer = memo(IntersectionLayerComponent);


/*'match',
                    ['get', 'bufferCheck'],
                    'checked',
                    'rgba(255, 0, 0, 0)',
                    [*/